.purchasetracker {
    padding-bottom: 38px;
}

.purchasetracker .tracker {
    padding: 20px;
}

.purchasetracker .tracker .left.table-cell,
.purchasetracker .tracker .right.table-cell {
   vertical-align: top; 
}

.purchasetracker .tracker .trackerqty {
    width: 60px;
    display: inline-block;
}

.purchasetracker .tracker .trackerqty-label {
    display: inline-block;
}

.purchasetracker .tracker .left {
    height: 175px;
    width: 175px;
    padding-right: 10px;
}

.purchasetracker .purchase-totals {
    font-size: 18px;
    padding-right: 20px;
    margin-bottom: -20px;
}

.purchasetracker .purchase-totals .total-price {
    font-weight: bold;
}

.purchasetracker .purchase-totals .line-item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.purchasetracker .tracker .tracker-description {
    margin-bottom: 40px;
}

.purchasetracker .delivery-address-form {
    width: 470px;
    padding-right: 70px;
}

.purchasetracker .section-label {
    padding-right: 30px;
    padding-left: 0px;
}

.purchasetracker .delivery-address-form .input-container {
    max-width: 500px;
    margin-bottom: 10px;
}

.purchasetracker .delivery-address-form .input-container input {
    display: block;
}

.purchasetracker section.data-section {
    padding: 8px 20px 15px 20px;
}

.purchasetracker section.choose-your-tracker {
    padding: 8px 20px 30px 20px;
}

.purchasetracker .saved-card {
    padding: 10px 15px 10px 15px;
    width: 257px;
}

.purchasetracker .saved-card input {
    float: left;
}

.purchasetracker .saved-card .card-number {
    float: left;
    padding-top: 3px;
    padding-left: 10px;
}

.purchasetracker .saved-card .card-type {
    font-size: 25px;
    float: left;
    padding-left: 10px;
}

.purchasetracker .saved-card .hidden-numbers {
    color: #ababab;
}

.purchasetracker .saved-card .last-four {
    padding-left: 3px;
}

.purchasetracker .delivery-details .table-cell {
    padding-top: 20px;
}

.purchasetracker .delivery-details .table-cell {
    vertical-align: top;
}

.purchasetracker-new-card-form .input-container {
    width: 330px;
    margin-left: 15px;
}

.purchasetracker-new-card-form .new-card-cvv {
    width: 55px;
}

.purchasetracker-new-card-form .new-card-expiry-month {
    width: 55px;
    float: left;
    margin-right: 10px;
}

.purchasetracker-new-card-form .new-card-expiry-year {
    width: 75px;
    float: left;
}

.purchasetracker-new-card-form .new-card-billing-same-as-shipping-label {
    padding-left: 5px;
}

.purchasetracker-new-card-form .new-card-billing-same-as-shipping {
    vertical-align: top;
}

.purchasetracker .remove-card {
    clear: both;
    margin-left: 22px;
    padding-top: 5px;
}

.purchasetracker {
    margin-bottom: 100px;
}

.content-placeholder.content-placeholder-large {
    width: 320px;
}

.content-placeholder.content-placeholder-image {
    width: 90px;
    height: 90px;
}

.css-table {
    display: table !important;
}

.table-cell {
    display: table-cell !important;
}

.table-row {
    display: table-row !important;
}

.invalid-field-error {
    color: #b94a48 !important;
    padding-top: 5px;
}

.invalid-field {
    border-color: #e9322d !important;
    box-shadow: 0px 0px 6px #f8b9b7 !important;
    -webkit-box-shadow: 0px 0px 6px #f8b9b7 !important;
    -moz-box-shadow: 0px 0px 6px #f8b9b7 !important;
}
