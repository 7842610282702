/*!
 * froala_editor v3.2.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2020 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-box .fr-counter {
  padding: 10px;
  float: right;
  color: #999999;
  content: attr(data-chars);
  font-size: 14px;
  font-family: sans-serif;
  z-index: 1;
  border-radius: 2px 0 0 0;
  -moz-border-radius: 2px 0 0 0;
  -webkit-border-radius: 2px 0 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }
.fr-box.fr-rtl .fr-counter {
  left: 0;
  right: auto;
  border-left: none;
  border-radius: 0 2px 0 0;
  -moz-border-radius: 0 2px 0 0;
  -webkit-border-radius: 0 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-box.fr-code-view .fr-counter {
  display: none; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

textarea.fr-code {
  display: none;
  width: 100%;
  resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  padding: 10px;
  margin: 0;
  font-family: "Courier New",monospace;
  font-size: 14px;
  background: #FFF;
  color: #000;
  outline: none; }

.fr-box.fr-rtl textarea.fr-code {
  direction: rtl; }

.fr-box .CodeMirror {
  display: none; }

.fr-box.fr-code-view textarea.fr-code {
  display: block; }
.fr-box.fr-code-view .fr-element, .fr-box.fr-code-view .fr-placeholder, .fr-box.fr-code-view .fr-iframe {
  display: none; }
.fr-box.fr-code-view .CodeMirror {
  display: block; }

.fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
  display: block; }
.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background: #FFF;
  color: #333333;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  padding: 8px 7px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch i {
    font-size: 24px;
    width: 24px;
    text-align: center; }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
    background: #ebebeb; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-popup .fr-layer.fr-color-hex-layer {
  margin: 0;
  padding: 20px;
  padding-top: 0;
  float: left; }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-input-line {
    float: left;
    width: calc(100% - 50px);
    padding: 15px 0 0; }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons {
    float: right;
    width: 38px;
    height: 40px;
    padding: 17px 0 0;
    margin: 0; }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons button.fr-command {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 13px;
    height: 40px;
    width: 38px; }
.fr-popup .fr-separator + .fr-colors-tabs {
  margin-left: 2px;
  margin-right: 2px; }
.fr-popup .fr-color-set {
  line-height: 0;
  display: none; }
  .fr-popup .fr-color-set.fr-selected-set {
    display: block;
    padding: 20px;
    padding-bottom: 0; }
  .fr-popup .fr-color-set > span {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1; }
    .fr-popup .fr-color-set > span > i, .fr-popup .fr-color-set > span > svg {
      text-align: center;
      line-height: 32px;
      height: 24px;
      width: 24px;
      margin: 4px;
      font-size: 13px;
      position: absolute;
      bottom: 0;
      cursor: default;
      left: 0; }
      .fr-popup .fr-color-set > span > i path, .fr-popup .fr-color-set > span > svg path {
        fill: #222222; }
    .fr-popup .fr-color-set > span .fr-selected-color {
      color: #FFF;
      font-family: FontAwesome;
      font-size: 13px;
      font-weight: 400;
      line-height: 32px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      text-align: center;
      cursor: default; }
    .fr-popup .fr-color-set > span:hover, .fr-popup .fr-color-set > span:focus {
      outline: 1px solid #222222;
      z-index: 2; }

.fr-rtl .fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1="text"] ~ [data-param1="background"]::after {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0); }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-drag-helper {
  background: #0098f7;
  height: 2px;
  margin-top: -1px;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  z-index: 2147483640;
  display: none; }
  .fr-drag-helper.fr-visible {
    display: block; }

.fr-dragging {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-popup .fr-emoticon {
  width: 24px;
  height: 24px;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols; }
  .fr-popup .fr-emoticon img {
    height: 24px;
    width: 24px; }
.fr-popup .fr-command.fr-btn.fr-tabs-unicode {
  padding: 0 0 0 14px; }
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .fr-popup .fr-icon-container.fr-emoticon-container {
    width: 368px; } }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-popup .fr-file-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  margin: 20px;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center; }
  .fr-popup .fr-file-upload-layer:hover {
    background: #ebebeb; }
  .fr-popup .fr-file-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7; }
  .fr-popup .fr-file-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important; }
    .fr-popup .fr-file-upload-layer .fr-form input {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 500%;
      height: 100%;
      margin: 0;
      font-size: 400px; }
.fr-popup .fr-file-progress-bar-layer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .fr-popup .fr-file-progress-bar-layer > h3 {
    font-size: 16px;
    margin: 10px 0;
    font-weight: normal; }
  .fr-popup .fr-file-progress-bar-layer > div.fr-action-buttons {
    display: none; }
  .fr-popup .fr-file-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
    height: 10px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    position: relative; }
    .fr-popup .fr-file-progress-bar-layer > div.fr-loader span {
      display: block;
      height: 100%;
      width: 0%;
      background: #0098f7;
      -webkit-transition: width 0.2s ease 0s;
      -moz-transition: width 0.2s ease 0s;
      -ms-transition: width 0.2s ease 0s;
      -o-transition: width 0.2s ease 0s; }
    .fr-popup .fr-file-progress-bar-layer > div.fr-loader.fr-indeterminate span {
      width: 30% !important;
      position: absolute;
      top: 0;
      -webkit-animation: loading 2s linear infinite;
      -moz-animation: loading 2s linear infinite;
      -o-animation: loading 2s linear infinite;
      animation: loading 2s linear infinite; }
  .fr-popup .fr-file-progress-bar-layer.fr-error > div.fr-loader {
    display: none; }
  .fr-popup .fr-file-progress-bar-layer.fr-error > div.fr-action-buttons {
    display: block; }

@keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-webkit-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-moz-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-o-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-element img {
  cursor: pointer;
  padding: 0 1px; }

.fr-height-auto {
  height: auto !important; }

.fr-file-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #53777a;
  display: inline-block !important;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  width: 20px;
  height: 20px;
  display: block !important;
  align-items: center; }

.fr-trim-button {
  margin-top: 5px;
  height: 36px;
  line-height: 1;
  color: #0098f7;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  border-radius: 5px;
  background-color: #eff5fa;
  outline: none; }
  .fr-trim-button:hover {
    background: #ebebeb; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
.fr-image-resizer {
  position: absolute;
  border: solid 1px #0098f7;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }
  .fr-image-resizer.fr-active {
    display: block; }
  .fr-image-resizer .fr-handler {
    display: block;
    position: absolute;
    background: #0098f7;
    border: solid 1px #FFF;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .fr-image-resizer .fr-handler.fr-hnw {
      cursor: nw-resize; }
    .fr-image-resizer .fr-handler.fr-hne {
      cursor: ne-resize; }
    .fr-image-resizer .fr-handler.fr-hsw {
      cursor: sw-resize; }
    .fr-image-resizer .fr-handler.fr-hse {
      cursor: se-resize; }
  .fr-image-resizer .fr-handler {
    width: 12px;
    height: 12px; }
    .fr-image-resizer .fr-handler.fr-hnw {
      left: -6px;
      top: -6px; }
    .fr-image-resizer .fr-handler.fr-hne {
      right: -6px;
      top: -6px; }
    .fr-image-resizer .fr-handler.fr-hsw {
      left: -6px;
      bottom: -6px; }
    .fr-image-resizer .fr-handler.fr-hse {
      right: -6px;
      bottom: -6px; }
  @media (min-width: 1200px) {
    .fr-image-resizer .fr-handler {
      width: 10px;
      height: 10px; }
      .fr-image-resizer .fr-handler.fr-hnw {
        left: -5px;
        top: -5px; }
      .fr-image-resizer .fr-handler.fr-hne {
        right: -5px;
        top: -5px; }
      .fr-image-resizer .fr-handler.fr-hsw {
        left: -5px;
        bottom: -5px; }
      .fr-image-resizer .fr-handler.fr-hse {
        right: -5px;
        bottom: -5px; } }

.fr-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  display: none; }

.fr-by-url-padding {
  padding: 11.5px 0; }

.fr-popup .fr-upload-progress-layer {
  border-radius: 0;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 15px;
  margin: auto !important;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100% !important;
  overscroll-behavior: contain; }
.fr-popup .fr-files-by-url-layer {
  width: calc(100% - 40px) !important; }
.fr-popup .fr-files-embed-layer {
  width: calc(100% - 40px) !important; }
.fr-popup .fr-files-upload-layer {
  border: dashed 2px #bdbdbd;
  margin: 20px;
  position: relative;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center;
  width: calc(100% - 40px) !important; }
  .fr-popup .fr-files-upload-layer:hover {
    background: #ebebeb; }
  .fr-popup .fr-files-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7; }
  .fr-popup .fr-files-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important; }
    .fr-popup .fr-files-upload-layer .fr-form input {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 500%;
      height: 100%;
      margin: 0;
      font-size: 400px; }
.fr-popup .fr-files-progress-bar-layer {
  width: calc(100% - 40px);
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available; }
  .fr-popup .fr-files-progress-bar-layer > h3 {
    font-size: 16px;
    margin: 10px 0;
    font-weight: normal; }
  .fr-popup .fr-files-progress-bar-layer > div.fr-action-buttons {
    display: none; }
  .fr-popup .fr-files-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
    height: 10px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    position: relative; }
    .fr-popup .fr-files-progress-bar-layer > div.fr-loader span {
      display: block;
      height: 100%;
      width: 0%;
      background: #0098f7;
      -webkit-transition: width 0.2s ease 0s;
      -moz-transition: width 0.2s ease 0s;
      -ms-transition: width 0.2s ease 0s;
      -o-transition: width 0.2s ease 0s; }
    .fr-popup .fr-files-progress-bar-layer > div.fr-loader.fr-indeterminate span {
      width: 30% !important;
      position: absolute;
      top: 0;
      -webkit-animation: loading 2s linear infinite;
      -moz-animation: loading 2s linear infinite;
      -o-animation: loading 2s linear infinite;
      animation: loading 2s linear infinite; }
  .fr-popup .fr-files-progress-bar-layer.fr-error > div.fr-loader {
    display: none; }
  .fr-popup .fr-files-progress-bar-layer.fr-error > div.fr-action-buttons {
    display: block; }

.fr-image-size-layer .fr-image-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block; }
  .fr-image-size-layer .fr-image-group .fr-input-line + .fr-input-line {
    margin-left: 10px; }

.fr-progress-bar-style {
  width: calc(100% - 40px); }

.fr-progress-bar {
  position: relative;
  background-color: #4CAF50;
  height: 8px;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available; }

.fr-uploading {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

@-webkit-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-moz-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-o-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
.fr-pull-left {
  float: left; }

.fr-pull-right {
  float: right; }

.fr-icons {
  padding: 10px 20px 0;
  display: inline; }

.fr-transform-135 {
  transform: rotate(135deg); }

.fr-icons i {
  margin: 4px;
  padding: 2px;
  cursor: pointer;
  color: #999588;
  font-size: 20px; }

.fr-blue-decorator {
  background: #4a7fba;
  color: white;
  display: grid;
  padding: 5px; }

.fr-upload-progress {
  padding: 1px; }

.fr-upload-section {
  position: relative;
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available; }

@media screen and (max-width: 399px) {
  .fr-upload-section {
    margin-left: 7px;
    margin-right: 7px; }

  .fr-files-upload-layer {
    padding-top: 7px;
    padding-bottom: 7px; } }
@media screen and (min-width: 400px) and (max-width: 469px) {
  .fr-upload-section {
    margin-left: 7px;
    margin-right: 7px; }

  .fr-files-upload-layer {
    padding-top: 7px;
    padding-bottom: 7px; } }
@media screen and (min-width: 470px) and (max-width: 680px) {
  .fr-upload-section {
    margin-left: 7px;
    margin-right: 7px; }

  .fr-files-upload-layer {
    padding-top: 7px;
    padding-bottom: 7px; } }
@media screen and (min-width: 681px) {
  .fr-upload-section {
    margin-left: 35px;
    margin-right: 35px; }

  .fr-files-upload-layer {
    padding-top: 20px;
    padding-bottom: 20px; } }
.half-circle {
  width: 60px;
  height: 25px;
  background-color: white;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  transform: rotate(180deg);
  border-bottom: 0;
  position: relative;
  left: 50%;
  z-index: 1;
  left: 50%;
  margin-top: -1px;
  margin-left: -25px; }

/* headlines with lines */
.decorated {
  overflow: hidden;
  text-align: center; }

.decorated > span {
  position: relative;
  display: inline-block; }

.decorated > span:before, .decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid #ffffff;
  width: 100%;
  /* half of limiter*/
  margin: 0 20px; }

.decorated > span:before {
  right: 100%; }

.decorated > span:after {
  left: 100%; }

.fr-align-right {
  float: right; }

/* The checkbox-container */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fr-files-checkbox-line {
  display: flex;
  align-items: center;
  font-size: 10px; }
  .fr-files-checkbox-line label {
    cursor: pointer;
    vertical-align: middle; }

.fr-insert-checkbox input {
  height: 13px !important;
  width: 13px !important; }

.fr-autoplay-checkbox input {
  height: 13px !important;
  width: 13px !important; }

button[data-cmd="insertFiles"] {
  padding-left: 2px !important;
  padding-top: 2.7px !important; }
button[data-cmd="autoplay"] svg {
  position: relative !important;
  top: 3.2px !important; }
button[data-cmd="insertAll"] {
  padding-left: 4px !important; }
  button[data-cmd="insertAll"] svg {
    position: relative !important;
    top: 3.2px !important; }

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee; }

.fr-file-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  flex-wrap: wrap;
  position: relative;
  top: -20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 7px; }

.fr-file-item-left {
  display: flex;
  flex-wrap: wrap; }

.fr-file-item-icon {
  display: flex;
  align-items: center;
  padding-left: 7px; }

.fr-file-item-description {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin-left: 5px; }

.fr-cloud-icon {
  height: 30px;
  width: 32px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  margin-top: -18px; }

.fr-files-checkbox {
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  padding-right: 5px;
  border-radius: 100%;
  line-height: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  vertical-align: middle; }
  .fr-files-checkbox svg {
    margin-left: 0px;
    margin-top: 0px;
    display: none;
    width: 10px;
    height: 10px; }
  .fr-files-checkbox span {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s; }
  .fr-files-checkbox input {
    position: absolute;
    z-index: 2;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border: 0 none;
    cursor: pointer;
    height: 18px;
    margin: 0;
    padding: 0;
    width: 18px; }
    .fr-files-checkbox input:not(:checked) + span {
      border: solid 2px #999999; }
    .fr-files-checkbox input:not(:checked):active + span {
      background-color: #f5f5f5; }
    .fr-files-checkbox input:not(:checked):focus + span, .fr-files-checkbox input:not(:checked):hover + span {
      border-color: #515151; }
    .fr-files-checkbox input:checked + span {
      background: #0098f7;
      border: solid 2px #0098f7; }
      .fr-files-checkbox input:checked + span svg {
        display: block; }
    .fr-files-checkbox input:checked:active + span {
      background-color: #EcF5Ff; }
    .fr-files-checkbox input:checked:focus + span, .fr-files-checkbox input:checked:hover + span {
      -webkit-opacity: 0.8;
      -moz-opacity: 0.8;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

.fr-checkbox-disabled {
  opacity: 0.4;
  position: relative;
  display: inline-block;
  width: 13px !important;
  height: 13px !important;
  padding-right: 5px;
  border-radius: 100%;
  line-height: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  vertical-align: middle; }
  .fr-checkbox-disabled svg {
    margin-left: 2px;
    margin-top: 2px;
    display: none;
    width: 10px;
    height: 10px; }
  .fr-checkbox-disabled span {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s; }
  .fr-checkbox-disabled input {
    position: absolute;
    z-index: 2;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border: 0 none;
    cursor: default;
    height: 13px !important;
    margin: 0;
    padding: 0;
    width: 13px !important;
    top: 7px;
    left: 7px; }
    .fr-checkbox-disabled input:not(:checked) + span {
      border: solid 2px #999999; }
    .fr-checkbox-disabled input:not(:checked):active + span {
      background-color: #f5f5f5; }
    .fr-checkbox-disabled input:checked + span {
      background: #0098f7;
      border: solid 2px #0098f7; }
      .fr-checkbox-disabled input:checked + span svg {
        display: block; }
    .fr-checkbox-disabled input:checked:active + span {
      background-color: #EcF5Ff; }

.fr-file-view {
  position: absolute;
  max-width: 60%;
  right: 10%;
  z-index: 2147483642; }

.file-item-thumbnail-hover {
  transition: transform .2s; }

.file-item-thumbnail-hover:hover {
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3); }

.fr-file-name {
  padding-top: 5px;
  font-size: 12px;
  font-weight: bold; }

.fr-margin-16 {
  margin-bottom: 16px; }

.fr-none {
  display: none; }

.fr-display-block {
  display: block; }

.fr-files-manager-tooltip {
  position: relative; }

.padding-top-15 {
  padding-top: 15px !important;
  padding-bottom: 0px !important; }

/* Tooltip text */
.fr-files-manager-tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  max-width: 250px;
  word-break: break-word;
  word-wrap: break-word;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 4px;
  border-radius: 2px;
  /* Position the tooltip text - see examples below! */
  font-weight: 100;
  font-size: 10px;
  position: absolute;
  top: 80%;
  left: 50%;
  z-index: 2147483647; }

/* Show the tooltip text when you mouse over the tooltip container */
.fr-files-manager-tooltip:hover .tooltiptext {
  visibility: visible; }

.fr-file-details {
  display: flex;
  flex-wrap: wrap;
  font-size: 10px; }

.fr-file-date {
  word-break: break-word;
  word-wrap: break-word;
  max-width: 110px;
  color: #444;
  padding-top: 2px;
  padding-bottom: 2px; }

.fr-file-size {
  color: #444;
  padding-top: 2px;
  padding-bottom: 2px; }

.dot {
  width: 20px;
  height: 20px;
  background-image: radial-gradient(circle, grey 1px, transparent 2px);
  background-size: 100% 33.33%;
  margin-left: 2px;
  margin-right: 2px; }

.fr-file-action-icons {
  margin-right: 5px;
  margin-left: 5px !important;
  color: #999;
  height: 25px !important;
  padding: 5px !important; }

@media screen and (max-width: 700px) {
  .fr-files-manager-tooltip .tooltiptext {
    max-width: 250px;
    visibility: hidden;
    word-break: break-word;
    word-wrap: break-word;
    top: 90%;
    left: 0%;
    font-size: 8px; } }
@media screen and (max-width: 500px) {
  .fr-files-manager-tooltip .tooltiptext {
    max-width: 100px;
    visibility: hidden;
    word-break: break-word;
    word-wrap: break-word;
    top: 90%;
    left: 0%; }

  .fr-file-item-description {
    width: 130px; }

  .fr-file-item-right {
    width: 100%; }

  .progress-circle {
    font-size: 6px; } }
@media screen and (max-width: 320px) {
  .fr-files-manager-tooltip .tooltiptext {
    max-width: 100px;
    visibility: hidden;
    word-break: break-word;
    word-wrap: break-word;
    top: 90%;
    left: -50%;
    font-size: 8px; }

  .dot {
    width: 20px;
    margin-left: 0px;
    margin-right: 3px; }

  .fr-file-item-description {
    width: 80px; }

  .fr-file-details {
    display: flex;
    flex-wrap: wrap;
    font-size: 9px; }

  .fr-file-name {
    font-size: 10px; }

  .fr-file-action-icons {
    padding: 0px !important; } }
@media screen and (min-width: 501px) {
  .progress-circle {
    font-size: 9px; } }
.fr-file-view-modal {
  display: block;
  position: fixed;
  position: -ms-page;
  /* For IE */
  z-index: 2147483641;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7); }

.fr-file-view-modal-content {
  display: inline-block;
  text-align: center; }

.fr-file-view-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  max-height: 70%; }

.fr-file-view-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s; }

.fr-file-view-close:hover,
.fr-file-view-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer; }

.fr-file-error {
  color: red;
  font-weight: 200;
  font-size: 12px;
  padding-bottom: 5px; }

.fr-file-error-h5 {
  margin: 0; }

.fr-file-item-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px; }

.fr-file-item-action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.fr-file-item-autoplay-checkbox {
  align-items: center;
  margin: 0px;
  padding: 0px;
  margin-right: 5px; }

.fr-autoplay-checkbox-label {
  margin: 0;
  cursor: default !important; }

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.file-item-details .file-name {
  font-weight: bold;
  display: inline; }

.file-item-details .file-description {
  font-weight: 300;
  font-size: 11px;
  display: inline; }

.fr-width-10 {
  width: 10%;
  display: inline-block; }

.fr-width-20 {
  width: 20%;
  display: inline-block; }

.fr-width-30 {
  width: 30%;
  display: inline-block; }

.fr-width-40 {
  width: 40%;
  display: inline-block; }

.fr-width-50 {
  width: 50%;
  display: inline-block; }

.fr-width-60 {
  width: 60%;
  display: inline-block; }

.fr-width-100 {
  width: 100%;
  display: inline-block; }

.fr-width-80 {
  width: 80%;
  display: inline-block; }

.fr-width-90 {
  width: 90%;
  display: inline-block; }

.fr-width-85 {
  width: 85%;
  display: inline-block; }

.fr-width-5 {
  width: 5%;
  display: inline-block; }

.fr-width-7 {
  width: 7%;
  display: inline-block; }

.fr-img-icon {
  color: #aaaaaa;
  font-size: 16px;
  padding: 0px 4px; }

.fr-position-content {
  position: relative;
  top: 10px; }

.file-error {
  color: red; }

#myprogressBar {
  width: 10%;
  height: 5px;
  background-color: #4CAF50;
  position: relative;
  top: -30px; }

.fr-files-manager {
  z-index: 2147483641 !important; }

.progress-circle {
  margin: 0px;
  margin-left: 10px;
  position: relative;
  padding: 0;
  width: 5em;
  height: 5.1em;
  background-color: #f2e9e1;
  border-radius: 50%;
  line-height: 5em; }
  .progress-circle:after {
    border: none;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 4.3em;
    height: 4.3em;
    background-color: white;
    content: " "; }
  .progress-circle span {
    position: absolute;
    line-height: 5em;
    width: 5em;
    text-align: center;
    display: block;
    color: #53777a;
    z-index: 2; }
  .progress-circle.over50 .left-half-clipper {
    clip: rect(auto, auto, auto, auto); }
  .progress-circle.over50 .first50-bar {
    position: absolute;
    clip: rect(0, 5em, 5em, 2.5em);
    background-color: #53777a;
    border-radius: 50%;
    width: 5em;
    height: 5em; }
  .progress-circle:not(.over50) .first50-bar {
    display: none; }
  .progress-circle.p0 .value-bar {
    display: none; }
  .progress-circle.p1 .value-bar {
    transform: rotate(4deg); }
  .progress-circle.p2 .value-bar {
    transform: rotate(7deg); }
  .progress-circle.p3 .value-bar {
    transform: rotate(11deg); }
  .progress-circle.p4 .value-bar {
    transform: rotate(14deg); }
  .progress-circle.p5 .value-bar {
    transform: rotate(18deg); }
  .progress-circle.p6 .value-bar {
    transform: rotate(22deg); }
  .progress-circle.p7 .value-bar {
    transform: rotate(25deg); }
  .progress-circle.p8 .value-bar {
    transform: rotate(29deg); }
  .progress-circle.p9 .value-bar {
    transform: rotate(32deg); }
  .progress-circle.p10 .value-bar {
    transform: rotate(36deg); }
  .progress-circle.p11 .value-bar {
    transform: rotate(40deg); }
  .progress-circle.p12 .value-bar {
    transform: rotate(43deg); }
  .progress-circle.p13 .value-bar {
    transform: rotate(47deg); }
  .progress-circle.p14 .value-bar {
    transform: rotate(50deg); }
  .progress-circle.p15 .value-bar {
    transform: rotate(54deg); }
  .progress-circle.p16 .value-bar {
    transform: rotate(58deg); }
  .progress-circle.p17 .value-bar {
    transform: rotate(61deg); }
  .progress-circle.p18 .value-bar {
    transform: rotate(65deg); }
  .progress-circle.p19 .value-bar {
    transform: rotate(68deg); }
  .progress-circle.p20 .value-bar {
    transform: rotate(72deg); }
  .progress-circle.p21 .value-bar {
    transform: rotate(76deg); }
  .progress-circle.p22 .value-bar {
    transform: rotate(79deg); }
  .progress-circle.p23 .value-bar {
    transform: rotate(83deg); }
  .progress-circle.p24 .value-bar {
    transform: rotate(86deg); }
  .progress-circle.p25 .value-bar {
    transform: rotate(90deg); }
  .progress-circle.p26 .value-bar {
    transform: rotate(94deg); }
  .progress-circle.p27 .value-bar {
    transform: rotate(97deg); }
  .progress-circle.p28 .value-bar {
    transform: rotate(101deg); }
  .progress-circle.p29 .value-bar {
    transform: rotate(104deg); }
  .progress-circle.p30 .value-bar {
    transform: rotate(108deg); }
  .progress-circle.p31 .value-bar {
    transform: rotate(112deg); }
  .progress-circle.p32 .value-bar {
    transform: rotate(115deg); }
  .progress-circle.p33 .value-bar {
    transform: rotate(119deg); }
  .progress-circle.p34 .value-bar {
    transform: rotate(122deg); }
  .progress-circle.p35 .value-bar {
    transform: rotate(126deg); }
  .progress-circle.p36 .value-bar {
    transform: rotate(130deg); }
  .progress-circle.p37 .value-bar {
    transform: rotate(133deg); }
  .progress-circle.p38 .value-bar {
    transform: rotate(137deg); }
  .progress-circle.p39 .value-bar {
    transform: rotate(140deg); }
  .progress-circle.p40 .value-bar {
    transform: rotate(144deg); }
  .progress-circle.p41 .value-bar {
    transform: rotate(148deg); }
  .progress-circle.p42 .value-bar {
    transform: rotate(151deg); }
  .progress-circle.p43 .value-bar {
    transform: rotate(155deg); }
  .progress-circle.p44 .value-bar {
    transform: rotate(158deg); }
  .progress-circle.p45 .value-bar {
    transform: rotate(162deg); }
  .progress-circle.p46 .value-bar {
    transform: rotate(166deg); }
  .progress-circle.p47 .value-bar {
    transform: rotate(169deg); }
  .progress-circle.p48 .value-bar {
    transform: rotate(173deg); }
  .progress-circle.p49 .value-bar {
    transform: rotate(176deg); }
  .progress-circle.p50 .value-bar {
    transform: rotate(180deg); }
  .progress-circle.p51 .value-bar {
    transform: rotate(184deg); }
  .progress-circle.p52 .value-bar {
    transform: rotate(187deg); }
  .progress-circle.p53 .value-bar {
    transform: rotate(191deg); }
  .progress-circle.p54 .value-bar {
    transform: rotate(194deg); }
  .progress-circle.p55 .value-bar {
    transform: rotate(198deg); }
  .progress-circle.p56 .value-bar {
    transform: rotate(202deg); }
  .progress-circle.p57 .value-bar {
    transform: rotate(205deg); }
  .progress-circle.p58 .value-bar {
    transform: rotate(209deg); }
  .progress-circle.p59 .value-bar {
    transform: rotate(212deg); }
  .progress-circle.p60 .value-bar {
    transform: rotate(216deg); }
  .progress-circle.p61 .value-bar {
    transform: rotate(220deg); }
  .progress-circle.p62 .value-bar {
    transform: rotate(223deg); }
  .progress-circle.p63 .value-bar {
    transform: rotate(227deg); }
  .progress-circle.p64 .value-bar {
    transform: rotate(230deg); }
  .progress-circle.p65 .value-bar {
    transform: rotate(234deg); }
  .progress-circle.p66 .value-bar {
    transform: rotate(238deg); }
  .progress-circle.p67 .value-bar {
    transform: rotate(241deg); }
  .progress-circle.p68 .value-bar {
    transform: rotate(245deg); }
  .progress-circle.p69 .value-bar {
    transform: rotate(248deg); }
  .progress-circle.p70 .value-bar {
    transform: rotate(252deg); }
  .progress-circle.p71 .value-bar {
    transform: rotate(256deg); }
  .progress-circle.p72 .value-bar {
    transform: rotate(259deg); }
  .progress-circle.p73 .value-bar {
    transform: rotate(263deg); }
  .progress-circle.p74 .value-bar {
    transform: rotate(266deg); }
  .progress-circle.p75 .value-bar {
    transform: rotate(270deg); }
  .progress-circle.p76 .value-bar {
    transform: rotate(274deg); }
  .progress-circle.p77 .value-bar {
    transform: rotate(277deg); }
  .progress-circle.p78 .value-bar {
    transform: rotate(281deg); }
  .progress-circle.p79 .value-bar {
    transform: rotate(284deg); }
  .progress-circle.p80 .value-bar {
    transform: rotate(288deg); }
  .progress-circle.p81 .value-bar {
    transform: rotate(292deg); }
  .progress-circle.p82 .value-bar {
    transform: rotate(295deg); }
  .progress-circle.p83 .value-bar {
    transform: rotate(299deg); }
  .progress-circle.p84 .value-bar {
    transform: rotate(302deg); }
  .progress-circle.p85 .value-bar {
    transform: rotate(306deg); }
  .progress-circle.p86 .value-bar {
    transform: rotate(310deg); }
  .progress-circle.p87 .value-bar {
    transform: rotate(313deg); }
  .progress-circle.p88 .value-bar {
    transform: rotate(317deg); }
  .progress-circle.p89 .value-bar {
    transform: rotate(320deg); }
  .progress-circle.p90 .value-bar {
    transform: rotate(324deg); }
  .progress-circle.p91 .value-bar {
    transform: rotate(328deg); }
  .progress-circle.p92 .value-bar {
    transform: rotate(331deg); }
  .progress-circle.p93 .value-bar {
    transform: rotate(335deg); }
  .progress-circle.p94 .value-bar {
    transform: rotate(338deg); }
  .progress-circle.p95 .value-bar {
    transform: rotate(342deg); }
  .progress-circle.p96 .value-bar {
    transform: rotate(346deg); }
  .progress-circle.p97 .value-bar {
    transform: rotate(349deg); }
  .progress-circle.p98 .value-bar {
    transform: rotate(353deg); }
  .progress-circle.p99 .value-bar {
    transform: rotate(356deg); }
  .progress-circle.p100 .value-bar {
    transform: rotate(360deg); }

.left-half-clipper {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: absolute;
  clip: rect(0, 5em, 5em, 2.5em); }

.value-bar {
  position: absolute;
  clip: rect(0, 2.5em, 5em, 0);
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.45em solid #53777a;
  box-sizing: border-box; }

.align-autoplay {
  align-items: flex-end;
  margin-right: 7px; }

.fr-hovered-over-file {
  background-color: #F0F0F0; }

.thumbnail-padding {
  padding-right: 4px; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

body.fr-fullscreen {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed; }

.fr-box.fr-fullscreen {
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483630 !important;
  width: auto !important; }
  .fr-box.fr-fullscreen.fr-basic.fr-top .fr-wrapper {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
  .fr-box.fr-fullscreen.fr-basic.fr-bottom .fr-wrapper {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
  .fr-box.fr-fullscreen .fr-toolbar {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
    .fr-box.fr-fullscreen .fr-toolbar.fr-top {
      top: 0 !important; }
    .fr-box.fr-fullscreen .fr-toolbar.fr-bottom {
      bottom: 0 !important; }
  .fr-box.fr-fullscreen .second-toolbar {
    margin-top: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }

.fr-fullscreen-wrapper {
  z-index: 2147483640 !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: visible !important; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal {
  text-align: left;
  padding: 20px 20px 10px; }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table {
    border-collapse: collapse;
    font-size: 14px;
    line-height: 1.5;
    width: 100%; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table + table {
      margin-top: 20px; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tr {
      border: 0; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table th {
      text-align: left; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table th, .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table td {
      padding: 6px 0 4px; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
      border-bottom: solid 1px #ebebeb; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
      width: 60%;
      color: #646464; }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:nth-child(n+2) {
      letter-spacing: 0.5px; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-element img {
  cursor: pointer;
  padding: 0 1px; }

.fr-image-resizer {
  position: absolute;
  border: solid 1px #0098f7;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }
  .fr-image-resizer.fr-active {
    display: block; }
  .fr-image-resizer .fr-handler {
    display: block;
    position: absolute;
    background: #0098f7;
    border: solid 1px #FFF;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .fr-image-resizer .fr-handler.fr-hnw {
      cursor: nw-resize; }
    .fr-image-resizer .fr-handler.fr-hne {
      cursor: ne-resize; }
    .fr-image-resizer .fr-handler.fr-hsw {
      cursor: sw-resize; }
    .fr-image-resizer .fr-handler.fr-hse {
      cursor: se-resize; }
  .fr-image-resizer .fr-handler {
    width: 12px;
    height: 12px; }
    .fr-image-resizer .fr-handler.fr-hnw {
      left: -6px;
      top: -6px; }
    .fr-image-resizer .fr-handler.fr-hne {
      right: -6px;
      top: -6px; }
    .fr-image-resizer .fr-handler.fr-hsw {
      left: -6px;
      bottom: -6px; }
    .fr-image-resizer .fr-handler.fr-hse {
      right: -6px;
      bottom: -6px; }
  @media (min-width: 1200px) {
    .fr-image-resizer .fr-handler {
      width: 10px;
      height: 10px; }
      .fr-image-resizer .fr-handler.fr-hnw {
        left: -5px;
        top: -5px; }
      .fr-image-resizer .fr-handler.fr-hne {
        right: -5px;
        top: -5px; }
      .fr-image-resizer .fr-handler.fr-hsw {
        left: -5px;
        bottom: -5px; }
      .fr-image-resizer .fr-handler.fr-hse {
        right: -5px;
        bottom: -5px; } }

.fr-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  display: none; }

.fr-popup .fr-image-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  margin: 20px;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center; }
  .fr-popup .fr-image-upload-layer:hover {
    background: #ebebeb; }
  .fr-popup .fr-image-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7; }
  .fr-popup .fr-image-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important; }
    .fr-popup .fr-image-upload-layer .fr-form input {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 500%;
      height: 100%;
      margin: 0;
      font-size: 400px; }
.fr-popup .fr-image-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: normal; }
.fr-popup .fr-image-progress-bar-layer > div.fr-action-buttons {
  display: none; }
.fr-popup .fr-image-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative; }
  .fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
    display: block;
    height: 100%;
    width: 0%;
    background: #0098f7;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s; }
  .fr-popup .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    width: 30% !important;
    position: absolute;
    top: 0;
    -webkit-animation: loading 2s linear infinite;
    -moz-animation: loading 2s linear infinite;
    -o-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite; }
.fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-loader {
  display: none; }
.fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block; }

.fr-image-size-layer .fr-image-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block; }
  .fr-image-size-layer .fr-image-group .fr-input-line + .fr-input-line {
    margin-left: 10px; }

.fr-uploading {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }

@-webkit-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-moz-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@-o-keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
@keyframes loading {
  from {
    left: -25%; }
  to {
    left: 100%; } }
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-modal .fr-modal-head .fr-modal-head-line::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }
.fr-modal .fr-modal-head .fr-modal-head-line .fr-modal-more {
  margin-top: 10px; }
  .fr-modal .fr-modal-head .fr-modal-head-line .fr-modal-more.fr-not-available {
    opacity: 0;
    width: 0;
    padding: 12px 0; }
.fr-modal .fr-modal-head .fr-modal-tags {
  padding: 0 20px;
  display: none;
  text-align: left; }
  .fr-modal .fr-modal-head .fr-modal-tags a {
    display: inline-block;
    opacity: 0;
    padding: 6px 12px;
    margin: 8px 0 8px 8px;
    text-decoration: none;
    border-radius: 32px;
    -moz-border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    cursor: pointer;
    background-color: #f5f5f5; }
    .fr-modal .fr-modal-head .fr-modal-tags a:focus {
      outline: none;
      background-color: #ebebeb; }
    .fr-modal .fr-modal-head .fr-modal-tags a:hover {
      background-color: #ebebeb; }
    .fr-modal .fr-modal-head .fr-modal-tags a:active {
      background-color: #d6d6d6; }
    .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag {
      background-color: #EcF5Ff;
      color: #0098f7; }
      .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:focus {
        outline: none;
        background-color: #ebebeb; }
      .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:hover {
        background-color: #ebebeb; }
      .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:active {
        background-color: #d6d6d6; }

.fr-show-tags .fr-modal-more svg path {
  fill: #0098f7; }

div.fr-modal-body {
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s; }
  div.fr-modal-body .fr-preloader {
    display: block;
    margin: 50px auto; }
  div.fr-modal-body div.fr-image-list {
    text-align: center;
    margin: 0 20px;
    padding: 0; }
    div.fr-modal-body div.fr-image-list .fr-list-column {
      float: left;
      width: calc((100% - 20px) / 2); }
      @media (min-width: 768px) and (max-width: 1199px) {
        div.fr-modal-body div.fr-image-list .fr-list-column {
          width: calc((100% - 40px) / 3); } }
      @media (min-width: 1200px) {
        div.fr-modal-body div.fr-image-list .fr-list-column {
          width: calc((100% - 60px) / 4); } }
      div.fr-modal-body div.fr-image-list .fr-list-column + .fr-list-column {
        margin-left: 20px; }
    div.fr-modal-body div.fr-image-list div.fr-image-container {
      position: relative;
      width: 100%;
      display: block;
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      overflow: hidden; }
      div.fr-modal-body div.fr-image-list div.fr-image-container:first-child {
        margin-top: 20px; }
      div.fr-modal-body div.fr-image-list div.fr-image-container + div {
        margin-top: 20px; }
      div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
        position: absolute;
        -webkit-opacity: 0.5;
        -moz-opacity: 0.5;
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: opacity 0.2s ease 0s;
        -moz-transition: opacity 0.2s ease 0s;
        -ms-transition: opacity 0.2s ease 0s;
        -o-transition: opacity 0.2s ease 0s;
        background: #000;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2; }
      div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
        content: attr(data-deleting);
        color: #FFF;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
        z-index: 3;
        font-size: 15px;
        height: 20px; }
      div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
        height: 95px;
        background: #CCCCCC;
        z-index: 1; }
        div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty::after {
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          content: attr(data-loading);
          display: inline-block;
          height: 20px; }
      div.fr-modal-body div.fr-image-list div.fr-image-container img {
        width: 100%;
        vertical-align: middle;
        position: relative;
        z-index: 2;
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
        -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
        -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
        -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0); }
      div.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected img {
        -webkit-opacity: 0.75;
        -moz-opacity: 0.75;
        opacity: 0.75;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
      div.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected .fr-delete-img, div.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected .fr-insert-img {
        display: inline-block; }
      div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img, div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
        display: none;
        top: 50%;
        border-radius: 100%;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
        -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
        -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
        -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        cursor: pointer;
        margin: 0;
        line-height: 40px;
        text-decoration: none;
        z-index: 3; }
      div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
        background: #B8312F;
        fill: #FFF;
        padding: 8px;
        left: 50%;
        -webkit-transform: translateY(-50%) translateX(25%);
        -moz-transform: translateY(-50%) translateX(25%);
        -ms-transform: translateY(-50%) translateX(25%);
        -o-transform: translateY(-50%) translateX(25%); }
      div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
        background: #FFF;
        fill: #0098f7;
        padding: 8px;
        left: 50%;
        -webkit-transform: translateY(-50%) translateX(-125%);
        -moz-transform: translateY(-50%) translateX(-125%);
        -ms-transform: translateY(-50%) translateX(-125%);
        -o-transform: translateY(-50%) translateX(-125%); }

.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover img {
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover .fr-delete-img, .fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover .fr-insert-img {
  display: inline-block;
  width: 40px;
  height: 40px; }
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img:hover {
  background: #bf4644;
  color: #FFF; }
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img:hover {
  background: #ebebeb; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-line-breaker {
  cursor: text;
  border-top: 1px solid #0098f7;
  position: fixed;
  z-index: 2;
  display: none; }
  .fr-line-breaker.fr-visible {
    display: block; }
  .fr-line-breaker a.fr-floating-btn {
    position: absolute;
    left: calc(50% - (40px / 2));
    top: -20px; }
    .fr-line-breaker a.fr-floating-btn svg {
      margin: 8px;
      height: 24px;
      width: 24px; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-quick-insert {
  position: absolute;
  z-index: 2147483639;
  white-space: nowrap;
  padding-right: 10px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }
  .fr-quick-insert a.fr-floating-btn svg {
    margin: 8px;
    width: 24px;
    height: 24px; }
  .fr-quick-insert.fr-on a.fr-floating-btn svg {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg); }
  .fr-quick-insert.fr-hidden {
    display: none; }

.fr-qi-helper {
  position: absolute;
  z-index: 3;
  padding-left: 20px;
  white-space: nowrap; }
  .fr-qi-helper a.fr-btn.fr-floating-btn {
    text-align: center;
    padding: 6px 10px 10px 10px;
    display: inline-block;
    color: #222222;
    background: #FFF;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0); }
    .fr-qi-helper a.fr-btn.fr-floating-btn svg {
      fill: #222222; }
    .fr-qi-helper a.fr-btn.fr-floating-btn.fr-size-1 {
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-popup .fr-buttons.fr-tabs .fr-special-character-category {
  padding: 10px 15px; }
  .fr-popup .fr-buttons.fr-tabs .fr-special-character-category span {
    font-weight: normal;
    font-size: 16px; }
.fr-popup .fr-special-character {
  width: 24px;
  height: 24px; }
@media screen and (-ms-high-contrast: active) and (min-width: 768px), (-ms-high-contrast: none) and (min-width: 768px) {
  .fr-popup .fr-icon-container.fr-sc-container {
    width: 368px; } }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-element table td.fr-selected-cell, .fr-element table th.fr-selected-cell {
  border: 1px double #0098f7; }
.fr-element table tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
.fr-element table td, .fr-element table th {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text; }
.fr-element .fr-no-selection table td, .fr-element .fr-no-selection table th {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-table-resizer {
  cursor: col-resize;
  position: absolute;
  z-index: 3;
  display: none; }
  .fr-table-resizer.fr-moving {
    z-index: 2; }
  .fr-table-resizer div {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-right: 1px solid #0098f7; }

.fr-no-selection {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-popup .fr-table-size {
  margin: 20px; }
  .fr-popup .fr-table-size .fr-table-size-info {
    text-align: center;
    font-size: 14px; }
  .fr-popup .fr-table-size .fr-select-table-size {
    line-height: 0;
    padding: 5px;
    white-space: nowrap; }
    .fr-popup .fr-table-size .fr-select-table-size > span {
      display: inline-block;
      padding: 0 4px 4px 0;
      background: transparent; }
      .fr-popup .fr-table-size .fr-select-table-size > span > span {
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid #DDD; }
      .fr-popup .fr-table-size .fr-select-table-size > span.hover {
        background: transparent; }
        .fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
          background: rgba(0, 152, 247, 0.3);
          border: solid 1px #0098f7; }
    .fr-popup .fr-table-size .fr-select-table-size .new-line::after {
      clear: both;
      display: block;
      content: "";
      height: 0; }
.fr-popup.fr-above .fr-table-size .fr-select-table-size > span {
  display: inline-block !important; }
.fr-popup .fr-table-colors {
  display: block;
  padding: 20px;
  padding-bottom: 0; }

.fr-popup.fr-desktop .fr-table-size .fr-select-table-size > span > span {
  width: 12px;
  height: 12px; }

.fr-insert-helper {
  position: absolute;
  z-index: 9999;
  white-space: nowrap; }

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-element .fr-video {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
  .fr-element .fr-video::after {
    position: absolute;
    content: '';
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    display: block;
    background: rgba(0, 0, 0, 0); }
  .fr-element .fr-video.fr-active > * {
    z-index: 2;
    position: relative; }
  .fr-element .fr-video > * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 100%;
    border: none; }

.fr-box .fr-video-resizer {
  position: absolute;
  border: solid 1px #0098f7;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
  .fr-box .fr-video-resizer.fr-active {
    display: block; }
  .fr-box .fr-video-resizer .fr-handler {
    display: block;
    position: absolute;
    background: #0098f7;
    border: solid 1px #FFF;
    z-index: 4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .fr-box .fr-video-resizer .fr-handler.fr-hnw {
      cursor: nw-resize; }
    .fr-box .fr-video-resizer .fr-handler.fr-hne {
      cursor: ne-resize; }
    .fr-box .fr-video-resizer .fr-handler.fr-hsw {
      cursor: sw-resize; }
    .fr-box .fr-video-resizer .fr-handler.fr-hse {
      cursor: se-resize; }
  .fr-box .fr-video-resizer .fr-handler {
    width: 12px;
    height: 12px; }
    .fr-box .fr-video-resizer .fr-handler.fr-hnw {
      left: -6px;
      top: -6px; }
    .fr-box .fr-video-resizer .fr-handler.fr-hne {
      right: -6px;
      top: -6px; }
    .fr-box .fr-video-resizer .fr-handler.fr-hsw {
      left: -6px;
      bottom: -6px; }
    .fr-box .fr-video-resizer .fr-handler.fr-hse {
      right: -6px;
      bottom: -6px; }
  @media (min-width: 1200px) {
    .fr-box .fr-video-resizer .fr-handler {
      width: 10px;
      height: 10px; }
      .fr-box .fr-video-resizer .fr-handler.fr-hnw {
        left: -5px;
        top: -5px; }
      .fr-box .fr-video-resizer .fr-handler.fr-hne {
        right: -5px;
        top: -5px; }
      .fr-box .fr-video-resizer .fr-handler.fr-hsw {
        left: -5px;
        bottom: -5px; }
      .fr-box .fr-video-resizer .fr-handler.fr-hse {
        right: -5px;
        bottom: -5px; } }

.fr-popup .fr-video-size-layer .fr-video-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block; }
  .fr-popup .fr-video-size-layer .fr-video-group .fr-input-line + .fr-input-line {
    margin-left: 10px; }
.fr-popup .fr-video-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  margin: 20px;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center; }
  .fr-popup .fr-video-upload-layer:hover {
    background: #ebebeb; }
  .fr-popup .fr-video-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7; }
  .fr-popup .fr-video-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important; }
    .fr-popup .fr-video-upload-layer .fr-form input {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 500%;
      height: 100%;
      margin: 0;
      font-size: 400px; }
.fr-popup .fr-video-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: normal; }
.fr-popup .fr-video-progress-bar-layer > div.fr-action-buttons {
  display: none; }
.fr-popup .fr-video-progress-bar-layer > div.fr-loader {
  background: #b3e0fd;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative; }
  .fr-popup .fr-video-progress-bar-layer > div.fr-loader span {
    display: block;
    height: 100%;
    width: 0%;
    background: #0098f7;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s; }
  .fr-popup .fr-video-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    width: 30% !important;
    position: absolute;
    top: 0;
    -webkit-animation: loading 2s linear infinite;
    -moz-animation: loading 2s linear infinite;
    -o-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite; }
.fr-popup .fr-video-progress-bar-layer.fr-error > div.fr-loader {
  display: none; }
.fr-popup .fr-video-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block; }

.fr-video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  display: none; }

.fr-autoplay-margin {
  margin-top: 0px !important; }
