.flexcontainer {
    display: flex;
    flex-direction: row;
}
ul.part-kits-list {
    margin:0;
    margin-bottom:10px;
}

.merge_and_transfer_main {
    margin-top: 0;
    padding-bottom:0;
}
.merge_and_transfer_main > p {
    padding-top:5px;
}

#convert-customerType-modal.modal {
    width: 900px;
    margin-left: -450px;
    margin-top: -250px;
}

#convert-customerType-modal .content-row.row-fluid {
    padding-left:0;
    padding-right: 0;
}

#convert-customerType-modal .span5 {
    width:46%;
    padding: 12px
}
#convert-customerType-modal .span2 {
    width:3%;
}

#convert-customerType-modal .modal-body.merge-contact-body {
    height: auto !important;
    padding-bottom: 15px;
}
#convert-customerType-modal .arrow-separator {
    padding-top: 30px;
}
#convert-customerType-modal .form-horizontal .control-label {
    text-align: left;
}
#convert-customerType-modal .form-horizontal .controls {
    margin-left: 140px;
}
#convert-customerType-modal .form-horizontal .controls.text-addr {
    padding-top: 5px;
    margin-bottom: 5px;
}
.modal-select.del-tax-item .delete_invoice_separate_sections {margin: 0 0 10px 0}

