@import 'https://fonts.googleapis.com/css?family=Nanum+Gothic';

/* Time Table Transition */
.jqTimespaceAnimated {
    transition: all 1.2s cubic-bezier(0.29, 0.575, 0.465, 1);
}

/* Error Display */
.jqTimespaceError {
    padding: 1rem;
    text-align: center;
    color: #ca0000;
    background: #ffffff;
}

/* Main and Table Containers */
.jqTimepsaceContainerWrapper {
    padding: 0 34px;
    overflow: hidden;
    border: 1px solid #b5b5b4;
}

.jqTimepsaceContainer, .jqTimepsaceContainer .jqTimespaceTableContainer {
    position: relative;
    box-sizing: border-box;
    overflow: visible;
    margin: 0 auto;
    padding: 0;
    color: #000000;
    font-family: 'Nanum Gothic', sans-serif;
}

.jqTimepsaceContainer .jqTimespaceTableContainer {
    border: none;
    background: #ffffff url('../images/timespace.line.light.png') bottom 0 left 0 repeat-x;
    -moz-user-select: none;
    user-select: none;

}

/* Navigation and Line Guide */
.jqTimepsaceContainer .jqTimespaceLeft,
.jqTimepsaceContainer .jqTimespaceRight {
    z-index: 9;
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0.1rem 0.5rem;
    color: #573e29;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
    background: #ffffff;
    opacity: 0.9;
    transition: background 0.4s ease-in, opacity 0.4s ease-in;
    height: 100%;
    box-shadow: 0px 0px 7px rgba(100, 100, 100, 1);
}

.jqTimepsaceContainer .jqTimespaceLeft span,
.jqTimepsaceContainer .jqTimespaceRight span {
    top: 44%;
    position: relative;
}

.jqTimepsaceContainer .jqTimespaceLeft {
    left: -34px;
}

.jqTimepsaceContainer .jqTimespaceRight {
    right: -34px;
}

.jqTimepsaceContainer .jqTimespaceLeft:hover,
.jqTimepsaceContainer .jqTimespaceRight:hover {
    opacity: 1;
}

.jqTimepsaceContainer .jqTimespaceLine {
    z-index: 9;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 50%;
    margin: 0;
    padding: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top, #444444 0%, #afafaf 15%, #ffffff 100%);
    opacity: 0.2;
    cursor: move;
}

/* Timeline Table */
.jqTimepsaceContainer table {
    position: relative;
    box-sizing: border-box;
    table-layout: fixed;
    left: 0;
    margin: 0;
    border-spacing: 0;
    padding: 0;
    line-break: strict;
    cursor: move;
}

.jqTimepsaceContainer tr {
    position: relative;
    height: 100%;
}

.jqTimepsaceContainer thead tr {
    z-index: 2;
    background: #ffffff;
}

.jqTimepsaceContainer thead th {
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
    padding: 0.5rem 0;
    text-align: center;
}

.jqTimepsaceContainer thead td {
    border-bottom: 1px solid #b4b4b4;
    border-left: 1px dashed #d8d8d8;
}

.jqTimepsaceContainer .jqTimespaceHeading {
    padding: 0 0.5rem;
}

.jqTimepsaceContainer thead th.jqTimespaceDummySpan {
    background: #ececec;
    opacity: 0.4;
}

.jqTimepsaceContainer .jqTimespaceTitleClamp {
    z-index: 3;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

.jqTimepsaceContainer tbody tr {
    top: 0;
    z-index: 1;
}

.jqTimepsaceContainer tbody td {
    border-right: 1px dashed #d8d8d8;
    border-collapse: collapse;
    height: 100%;
    min-height: 6.25rem;
    vertical-align: top;
}

.jqTimepsaceContainer tbody td .jqTimespaceEvent:last-of-type {
    margin-bottom: 20px;
}
.jqTimespaceTableRowLabel {
    position: absolute;
    left: 10px;
    width: auto;
    color: #666667;
    z-index: 9;
}


/* Event Box */
.jqTimepsaceContainer .jqTimespaceEvent {
    position: absolute;
    box-sizing: border-box;
    margin: 0;
    padding: 1rem 0 0 0;
    text-align: left;
}

.jqTimepsaceContainer .jqTimespaceEventRev {
    text-align: right;
}

.jqTimespaceEvent .jqTimespaceEventBorder {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    border-left: 1px dashed #d3d3d3;
}

.jqTimespaceEventRev .jqTimespaceEventBorder {
    right: 0;
    border-left: none;
    border-right: 1px dashed #d3d3d3;
}

.jqTimespaceEvent .jqTimespaceEventMarker:hover + .jqTimespaceEventBorder {
    border-left: 1px dashed #d3d3d3;
}

.jqTimespaceEventRev p:hover + .jqTimespaceEventBorder {
    border-left: none;
    border-right: 1px dashed #d3d3d3;
}

.jqTimespaceEvent .jqTimespaceEventMarker {
    z-index: 8;
    position: relative;
    overflow: visible;
    margin: 0;
    border: 1px solid #c0c0c0;
    border-radius: 50%;
    padding: 0.3rem 0.5rem;
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s ease-in;
    color: white;
}

.jqTimespaceEventRev p {
    border-radius: 0.6rem 0 0 0.6rem;
}

.jqTimespaceEvent .jqTimespaceEventMarker:hover, .jqTimespaceEvent .jqTimespaceEventSelected {
    box-shadow: 3px 8px 10px -10px #999999;
    z-index: 9;
}

.jqTimespaceEvent .jqTimespaceEventSelected {
    box-shadow: 3px 8px 10px -10px #999999;
    background: #2e4177;
}

.jqTimespaceNoDisplay p {
    box-shadow: none;
    cursor: move;
    background: #ebebeb;
    opacity: 0.7;
}

.jqTimespaceNoDisplay p:hover {
    box-shadow: none;
    background: #ebebeb;
    transform: none;
}

.jqTimespaceNoDisplay .jqTimespaceEventBorder {
    border: none;
}

.jqTimespaceNoDisplay p:hover + .jqTimespaceEventBorder {
    border: none;
}

/* Event Display */
.jqTimespaceDisplay {
    box-sizing: border-box;
    box-shadow: 0 6px 5px -5px #999999;
    margin: 1rem auto;
    border: 1px solid #cccccc;
    padding: 1.5rem;
    background: #ffffff;
}

.jqTimespaceDisplay h1 {
    margin: 0;
    padding: 0.5rem;
    text-align: left;
}

.jqTimespaceDisplay .jqTimespaceDisplayTime {
    display: flex;
    position: relative;
    align-items: center;
}

.jqTimespaceDisplay .jqTimespaceDisplayLeft, .jqTimespaceDisplay .jqTimespaceDisplayRight {
    width: 0.9rem;
    height: 1.3rem;
    background: #8a5541;
    cursor: pointer;
}

.jqTimespaceDisplay .jqTimespaceDisplayLeft {
    margin-left: 0.5rem;
    clip-path: polygon(100% 0, 100% 100%, 0% 50%);
}

.jqTimespaceDisplay .jqTimespaceDisplayRight {
    margin-left: 1.2rem;
    clip-path: polygon(0 0%, 0 100%, 100% 50%);
}

.jqTimespaceDisplay .jqTimespaceDisplayLeft:hover,
.jqTimespaceDisplay .jqTimespaceDisplayRight:hover {
    background: #c56a45;
}

.jqTimespaceDisplay .jqTimespaceTimeframe {
    display: inline-block;
    margin: 0.2rem 0 0.2rem 1.2rem;
    padding: 0 0.4rem;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    color: #cf3b00;
}

.jqTimespaceDisplay section {
    margin: 0;
    padding: 0.8rem 0.5rem 0.5rem 0.5rem;
    max-width: 70%;
}

@media (max-width: 50em) {
    .jqTimespaceDisplay section {
        max-width: 100%
    }
}
