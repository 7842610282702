/*!
 * Bootstrap v2.1.0
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#map_canvas img {
  max-width: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
.colours {
  text-align: center;
  padding-top: 20px;
}
.colour-block {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  border: solid 2px rgba(0, 0, 0, 0.5);
  width: 200px;
  height: 200px;
  display: inline-block;
  margin: 20px;
  border-radius: 100px;
  line-height: 200px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-size: 1.5em;
}
.colour-block:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
#commusoftBg {
  background: #f7faff;
}
#white {
  background: #ffffff;
}
#gray {
  background: #555555;
}
#grayDark {
  background: #dcdada;
}
#grayDarker {
  background: #5e6d7f;
}
#searchIcon {
  background: #5e6d7f;
}
#placeholderGrey {
  background: #999999;
}
#helpGreen {
  background: #60a369;
}
#activeborder {
  background: #505b65;
}
#blueGrey {
  background: #42585f;
}
#fieldsetGray {
  background: #f9f9f9;
}
#fieldsetBorder {
  background: #dadada;
}
#fieldsetLighterBorder {
  background: #dadada;
}
#uploadGray {
  background: #f7f7f7;
}
#uploadText {
  background: #7c868f;
}
#dataSectionBorder {
  background: #c9c9c9;
}
#blue {
  background: #4d7fa2;
}
#blueDark {
  background: #366dbd;
}
#blueDarker {
  background: #0c3055;
}
#blueLight {
  background: #c6dbe8;
}
#blueLighter {
  background: #d8e8f4;
}
#activeBlue {
  background: #34a2dc;
}
#permissionsGreen {
  background: #7eb419;
}
#permissionsRed {
  background: rgba(240, 61, 37, 0.9);
}
#turquoise {
  background: #50a69a;
}
#bodyBackground {
  background: #f7faff;
}
#todoWidgetGray {
  background: #eeeeee;
}
#headerColor {
  background: #5e6d7f;
}
#headingColor {
  background: #5e6d7f;
}
#editLinks {
  background: #dd2626;
}
#editLinksHover {
  background: #b41c1c;
}
#iconColor {
  background: #797979;
}
#timeline-blue {
  background: #677077;
}
#activeTableRow {
  background: #dafdd7;
}
#mapGreen {
  background: #30a20c;
}
#mapBlue {
  background: #5757e2;
}
#mapPink {
  background: #c520c5;
}
#black {
  background: #000000;
}
#grayLight {
  background: #999999;
}
#grayLighter {
  background: #eeeeee;
}
#green {
  background: #46a546;
}
#red {
  background: #d53847;
}
#yellow {
  background: #fff6d9;
}
#orange {
  background: #f89406;
}
#pink {
  background: #c3325f;
}
#purple {
  background: #7a43b6;
}
#textColor {
  background: #505b65;
}
#linkColor {
  background: #3883c1;
}
#linkColorHover {
  background: #3276ad;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ss,
.ss:before {
  font-family: "SSStandard";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga", "dlig";
  -moz-font-feature-settings: "liga=1, dlig=1";
  -moz-font-feature-settings: "liga", "dlig";
  -ms-font-feature-settings: "liga", "dlig";
  -o-font-feature-settings: "liga", "dlig";
  font-feature-settings: "liga", "dlig";
}
.chosen-overrights .chzn-single,
.chosen-overrights .chzn-choices {
  height: 30px !important;
  line-height: 30px !important;
  background-color: #ffffff !important;
  background-image: -moz-linear-gradient(left, #ffffff, #ffffff) !important;
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#ffffff), to(#ffffff)) !important;
  background-image: -webkit-linear-gradient(left, #ffffff, #ffffff) !important;
  background-image: -o-linear-gradient(left, #ffffff, #ffffff) !important;
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=1) !important;
  border-color: #cccccc !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}
.chosen-overrights .chzn-single span,
.chosen-overrights .chzn-choices span {
  color: #999999 !important;
  font-size: 14px !important;
}
.chosen-overrights .chzn-single div b {
  margin: 3px 0 0 0;
}
.chosen-overrights .chzn-choices .search-choice {
  margin-top: 6px !important;
}
.chosen-overrights .chzn-search {
  padding: 6px 4px !important;
}
.chosen-overrights input[type="text"] {
  margin-left: 2px !important;
  background: none !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  height: 30px !important;
  box-sizing: border-box;
}
.chosen-overrights input[type="text"]:focus {
  border: 1px solid rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}
.chosen-overrights .chzn-drop {
  top: 30px !important;
  border-color: #cccccc !important;
}
.chosen-overrights .chzn-drop .chzn-results {
  padding: 0 !important;
  margin: -1px 0 0 0 !important;
  border-top: 1px solid #d1d8e7 !important;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.chosen-overrights .chzn-drop .chzn-results li {
  height: 29px;
  line-height: 29px;
  padding: 0 0 0 24px!important;
  font-size: 14px !important;
  color: #505b65;
  border-bottom: 1px solid #d1d8e7;
}
.chosen-overrights .chzn-drop .chzn-results li.group {
  font-weight: bold;
  padding-left: 11px !important;
  background: #f4f6f8 !important;
}
.chosen-overrights .chzn-drop .chzn-results li.group:hover {
  background: #f4f6f8 !important;
}
.chosen-overrights .chzn-drop .chzn-results li:hover,
.chosen-overrights .chzn-drop .chzn-results li.highlighted {
  background: #fcfcfc !important;
  color: #505b65;
}
.white-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #ffffff;
  padding: 0 10px 10px;
  border: 1px solid #c9c9c9;
}
.panel-in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
.tag {
  color: #505b65;
  border: 1px solid #bcbcbc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  text-shadow: 0px -1px 1px rgba(255, 255, 255, 0.4);
  background: #f0f0f0;
}
.tagged-input {
  margin: 0;
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #cccccc;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  padding: 2px 5px;
}
.tagged-input .tag-input {
  padding: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #555555;
  width: 200px !important;
}
.tagged-input.focused {
  border: 1px solid rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}
.tagged-input .tag-item {
  color: #505b65;
  border: 1px solid #bcbcbc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  text-shadow: 0px -1px 1px rgba(255, 255, 255, 0.4);
  background: #f0f0f0;
}
.tagged-input .tag-item span {
  float: left;
  margin: 2px 0 0 2px;
  font-family: Arial, Helvetica, sans-serif;
}
.tagged-input .tag-item button:active {
  color: rgba(240, 61, 37, 0.9);
}
.tagged-input .tag-item.selected {
  background-color: #efefef;
  background-image: -moz-linear-gradient(top, #f9f9f9, #e1e1e1);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f9f9f9), to(#e1e1e1));
  background-image: -webkit-linear-gradient(top, #f9f9f9, #e1e1e1);
  background-image: -o-linear-gradient(top, #f9f9f9, #e1e1e1);
  background-image: linear-gradient(to bottom, #f9f9f9, #e1e1e1);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#ffe1e1e1', GradientType=0);
  border-color: rgba(240, 61, 37, 0.9);
}

.ui-autocomplete .ui-menu-item .checkbox-container {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    margin-top: 5px;
}

.ui-autocomplete .conditional-group-header {
    font-size: 13px;
    padding: 5px;
    font-weight: bold;
}

.ui-autocomplete .ui-menu-item .faux-checkbox {
    background-color: #fff;
}

.ui-autocomplete.ui-menu .ui-menu-item a {
    border-bottom: 1px solid #d2d2d2;
    padding: 6px 11px;
    color: #808080;
}

.ui-autocomplete.ui-menu .conditional-group {
    border-bottom: 1px solid #d2d2d2;
    float: left;
    width: 100%;
    list-style: none;
    margin: 0px;
}

.ui-autocomplete.ui-menu .conditional-group .ui-menu-item a {
    /*border: none;*/

}

.ui-autocomplete.ui-menu .ui-menu-item a:hover,
.ui-autocomplete.ui-menu .ui-menu-item a:focus,
.ui-autocomplete.ui-menu .ui-menu-item a:active {
    color: #fff;
}

.autocomplete {
  padding: 0;
  margin: 0;
  border-color: #c9c9c9;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.autocomplete .suggestion-list {
  overflow: hidden;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.autocomplete .suggestion-list li {
  font-family: Arial, Helvetica, sans-serif;
  height: 29px;
  line-height: 29px;
  padding: 0 0 0 24px;
  font-size: 14px;
  color: #505b65;
  border-bottom: 1px solid #d1d8e7;
}
.autocomplete .suggestion-list li:hover {
  background: #fcfcfc;
  color: #505b65;
  cursor: pointer;
}
.autocomplete .suggestion-item.selected {
  background: #fcfcfc;
  color: #505b65;
}
.autocomplete .suggestion-item em {
  background: #feffde;
}
.full-width {
  float: left;
  width: 100%;
}
.panel-sortable {
  overflow: auto;
  height: auto;
  margin: 20px 10px 0 10px;
  padding: 0 0 5px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border-top: 1px solid rgba(220, 220, 221, 0.6);
}
.panel-sortable li {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-left: 1px solid #dcdcdd;
  border-right: 1px solid #dcdcdd;
}
.panel-sortable li:first-of-type {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.panel-sortable li:last-of-type {
  border-bottom: none;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.panel-sortable label {
  margin: 0;
  padding: 10px 14px;
}
.panel-sortable input[type="radio"] {
  float: left;
  margin: 3px 6px 0 0;
}
body {
  margin: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #505b65;
  background: #f7faff;
}
.no_record{
    text-align: center;
}

body.add_dropzone_tint {
  background: rgba(0, 0, 0, 0.2);
}
a {
  color: #3883c1;
  text-decoration: none;
}
a:hover {
  color: #3276ad;
  text-decoration: none;
}
.img-rounded {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.img-circle {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}
.row {
  margin-left: -20px;
  *zoom: 1;
  clear: both;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
[class*="span"] {
  float: left;
  margin-left: 20px;
}
.container,
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.span12 {
  width: 940px;
}
.span11 {
  width: 860px;
}
.span10 {
  width: 780px;
}
.span9 {
  width: 700px;
}
.span8 {
  width: 620px;
}
.span7 {
  width: 540px;
}
.span6 {
  width: 460px;
}
.span5 {
  width: 380px;
}
.span4 {
  width: 300px;
}
.span3 {
  width: 220px;
}
.span2 {
  width: 140px;
}
.span1 {
  width: 60px;
}
.offset12 {
  margin-left: 980px;
}
.offset11 {
  margin-left: 900px;
}
.offset10 {
  margin-left: 820px;
}
.offset9 {
  margin-left: 740px;
}
.offset8 {
  margin-left: 660px;
}
.offset7 {
  margin-left: 580px;
}
.offset6 {
  margin-left: 500px;
}
.offset5 {
  margin-left: 420px;
}
.offset4 {
  margin-left: 340px;
}
.offset3 {
  margin-left: 260px;
}
.offset2 {
  margin-left: 180px;
}
.offset1 {
  margin-left: 100px;
}
.row-fluid {
  width: 100%;
  *zoom: 1;
  clear: both;
}
.row-fluid:before,
.row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.row-fluid:after {
  clear: both;
}
.row-fluid [class*="span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.127659574468085%;
  *margin-left: 2.074468085106383%;
}
.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
.row-fluid .span12 {
  width: 100%;
  *width: 99.94680851063829%;
}
.row-fluid .span11 {
  width: 91.48936170212765%;
  *width: 91.43617021276594%;
}
.row-fluid .span10 {
  width: 82.97872340425532%;
  *width: 82.92553191489361%;
}
.row-fluid .span9 {
  width: 74.46808510638297%;
  *width: 74.41489361702126%;
}
.row-fluid .span8 {
  width: 65.95744680851064%;
  *width: 65.90425531914893%;
}
.row-fluid .span7 {
  width: 57.44680851063829%;
  *width: 57.39361702127659%;
}
.row-fluid .span6 {
  width: 48.93617021276595%;
  *width: 48.88297872340425%;
}
.row-fluid .span5 {
  width: 40.42553191489362%;
  *width: 40.37234042553192%;
}
.row-fluid .span4 {
  width: 31.914893617021278%;
  *width: 31.861702127659576%;
}
.row-fluid .span3 {
  width: 23.404255319148934%;
  *width: 23.351063829787233%;
}
.row-fluid .span2 {
  width: 14.893617021276595%;
  *width: 14.840425531914894%;
}
.row-fluid .span1 {
  width: 6.382978723404255%;
  *width: 6.329787234042553%;
}
.row-fluid .offset12 {
  margin-left: 104.25531914893617%;
  *margin-left: 104.14893617021275%;
}
.row-fluid .offset12:first-child {
  margin-left: 102.12765957446808%;
  *margin-left: 102.02127659574467%;
}
.row-fluid .offset11 {
  margin-left: 95.74468085106382%;
  *margin-left: 95.6382978723404%;
}
.row-fluid .offset11:first-child {
  margin-left: 93.61702127659574%;
  *margin-left: 93.51063829787232%;
}
.row-fluid .offset10 {
  margin-left: 87.23404255319149%;
  *margin-left: 87.12765957446807%;
}
.row-fluid .offset10:first-child {
  margin-left: 85.1063829787234%;
  *margin-left: 84.99999999999999%;
}
.row-fluid .offset9 {
  margin-left: 78.72340425531914%;
  *margin-left: 78.61702127659572%;
}
.row-fluid .offset9:first-child {
  margin-left: 76.59574468085106%;
  *margin-left: 76.48936170212764%;
}
.row-fluid .offset8 {
  margin-left: 70.2127659574468%;
  *margin-left: 70.10638297872339%;
}
.row-fluid .offset8:first-child {
  margin-left: 68.08510638297872%;
  *margin-left: 67.9787234042553%;
}
.row-fluid .offset7 {
  margin-left: 61.70212765957446%;
  *margin-left: 61.59574468085106%;
}
.row-fluid .offset7:first-child {
  margin-left: 59.574468085106375%;
  *margin-left: 59.46808510638297%;
}
.row-fluid .offset6 {
  margin-left: 53.191489361702125%;
  *margin-left: 53.085106382978715%;
}
.row-fluid .offset6:first-child {
  margin-left: 51.063829787234035%;
  *margin-left: 50.95744680851063%;
}
.row-fluid .offset5 {
  margin-left: 44.68085106382979%;
  *margin-left: 44.57446808510638%;
}
.row-fluid .offset5:first-child {
  margin-left: 42.5531914893617%;
  *margin-left: 42.4468085106383%;
}
.row-fluid .offset4 {
  margin-left: 36.170212765957444%;
  *margin-left: 36.06382978723405%;
}
.row-fluid .offset4:first-child {
  margin-left: 34.04255319148936%;
  *margin-left: 33.93617021276596%;
}
.row-fluid .offset3 {
  margin-left: 27.659574468085104%;
  *margin-left: 27.5531914893617%;
}
.row-fluid .offset3:first-child {
  margin-left: 25.53191489361702%;
  *margin-left: 25.425531914893618%;
}
.row-fluid .offset2 {
  margin-left: 19.148936170212764%;
  *margin-left: 19.04255319148936%;
}
.row-fluid .offset2:first-child {
  margin-left: 17.02127659574468%;
  *margin-left: 16.914893617021278%;
}
.row-fluid .offset1 {
  margin-left: 10.638297872340425%;
  *margin-left: 10.53191489361702%;
}
.row-fluid .offset1:first-child {
  margin-left: 8.51063829787234%;
  *margin-left: 8.404255319148938%;
}
[class*="span"].hide,
.row-fluid [class*="span"].hide {
  display: none;
}
[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  float: right;
}
.container {
  margin-right: auto;
  margin-left: auto;
  *zoom: 1;
  clear: both;
  width: 1200px;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
  *zoom: 1;
  clear: both;
}
.container-fluid:before,
.container-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.container-fluid:after {
  clear: both;
}
p {
  margin: 0 0 10px;
}
p.preference{
    margin: 0 0 0px;
}

.lead {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}
.muted {
  color: #999999;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1;
  color: inherit;
  text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #999999;
}
h1 {
  font-size: 36px;
  line-height: 40px;
}
h2 {
  font-size: 30px;
  line-height: 40px;
}
h3 {
  font-size: 24px;
  line-height: 40px;
}
h4 {
  font-size: 18px;
  line-height: 20px;
}
h5 {
  font-size: 14px;
  line-height: 20px;
}
h6 {
  font-size: 12px;
  line-height: 20px;
}
h1 small {
  font-size: 24px;
}
h2 small {
  font-size: 18px;
}
h3 small {
  font-size: 14px;
}
h4 small {
  font-size: 14px;
}
.page-header {
  padding-bottom: 9px;
  margin: 20px 0 30px;
  border-bottom: 1px solid #eeeeee;
}
ul,
ol {
  padding: 0;
  margin: 0 0 10px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 20px;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}
dl {
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 20px;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 10px;
}
.dl-horizontal dt {
  float: left;
  width: 120px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dl-horizontal dd {
  margin-left: 130px;
}
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #ffffff;
}
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #999999;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  border-left: 5px solid #eeeeee;
}
blockquote p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}
blockquote small {
  display: block;
  line-height: 20px;
  color: #999999;
}
blockquote small:before {
  content: '\2014 \00A0';
}
blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}
blockquote.pull-right small:before {
  content: '';
}
blockquote.pull-right small:after {
  content: '\00A0 \2014';
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
address {
  display: block;
  margin-bottom: 15px;
  font-style: normal;
  line-height: 20px;
}
code,
pre {
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 12px;
  color: #dcdada;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
code {
  padding: 2px 4px;
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
pre.prettyprint {
  margin-bottom: 20px;
}
pre code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
form {
  margin: 0 0 20px;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 40px;
  color: #dcdada;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
legend small {
  font-size: 15px;
  color: #999999;
}
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
input,
button,
select,
textarea {
  font-family: Arial, Helvetica, sans-serif;
}
label {
  display: block;
  margin-bottom: 5px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  height: 20px;
  padding: 4px 6px;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input,
textarea {
  width: 210px;
}
textarea {
  height: auto;
}
.feedback{
    width: 100%;
    height:217px;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  *margin-top: 0;
  /* IE7 */

  margin-top: 1px \9;
  /* IE8-9 */

  line-height: normal;
  cursor: pointer;
}
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
select,
input[type="file"] {
  height: 30px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */

  *margin-top: 4px;
  /* For IE7, add top margin to align select with labels */

  line-height: 30px;
}
select {
  width: 220px;
  border: 1px solid #bbb;
  background-color: #ffffff;
}
select[multiple],
select[size] {
  height: auto;
}
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.uneditable-input,
.uneditable-textarea {
  color: #999999;
  background-color: #fcfcfc;
  border-color: #cccccc;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.uneditable-textarea {
  width: auto;
  height: auto;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #a2a2a2;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a2a2a2;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #a2a2a2;
}
.radio,
.checkbox {
  min-height: 18px;
  padding-left: 18px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"],
.row-fluid .input-prepend [class*="span"],
.row-fluid .input-append [class*="span"] {
  display: inline-block;
}
input,
textarea,
.uneditable-input {
  margin-left: 0;
}
.controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}
input.span12,
textarea.span12,
.uneditable-input.span12 {
  width: 926px;
}
input.span11,
textarea.span11,
.uneditable-input.span11 {
  width: 846px;
}
input.span10,
textarea.span10,
.uneditable-input.span10 {
  width: 766px;
}
input.span9,
textarea.span9,
.uneditable-input.span9 {
  width: 686px;
}
input.span8,
textarea.span8,
.uneditable-input.span8 {
  width: 606px;
}
input.span7,
textarea.span7,
.uneditable-input.span7 {
  width: 526px;
}
input.span6,
textarea.span6,
.uneditable-input.span6 {
  width: 446px;
}
input.span5,
textarea.span5,
.uneditable-input.span5 {
  width: 366px;
}
input.span4,
textarea.span4,
.uneditable-input.span4 {
  width: 286px;
}
input.span3,
textarea.span3,
.uneditable-input.span3 {
  width: 206px;
}
input.span2,
textarea.span2,
.uneditable-input.span2 {
  width: 126px;
}
input.span1,
textarea.span1,
.uneditable-input.span1 {
  width: 46px;
}
.controls-row {
  *zoom: 1;
  clear: both;
}
.controls-row:before,
.controls-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.controls-row:after {
  clear: both;
}
.controls-row [class*="span"] {
  float: left;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}
.control-group.warning > label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
  color: #c09853;
}
.control-group.warning .checkbox,
.control-group.warning .radio,
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  color: #c09853;
  border-color: #c09853;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.warning .checkbox:focus,
.control-group.warning .radio:focus,
.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
.control-group.error > label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #b94a48;
}
.control-group.error .checkbox,
.control-group.error .radio,
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  color: #b94a48;
  border-color: #b94a48;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.error .checkbox:focus,
.control-group.error .radio:focus,
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #953b39;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}
.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}
.control-group.success > label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: #468847;
}
.control-group.success .checkbox,
.control-group.success .radio,
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  color: #468847;
  border-color: #468847;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.success .checkbox:focus,
.control-group.success .radio:focus,
.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  border-color: #356635;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
input:focus:required:invalid,
textarea:focus:required:invalid,
select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}
input:focus:required:invalid:focus,
textarea:focus:required:invalid:focus,
select:focus:required:invalid:focus {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}
.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
  clear: both;
}
.form-actions:before,
.form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-actions:after {
  clear: both;
}
.help-block,
.help-inline {
  color: #72818f;
}
.help-block {
  display: block;
  margin-bottom: 10px;
}
.help-inline {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  vertical-align: middle;
  padding-left: 5px;
}
.input-append,
.input-prepend {
  margin-bottom: 5px;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  font-size: 14px;
  vertical-align: top;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 20px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #eeeeee;
  border: 1px solid #ccc;
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn {
  margin-left: -1px;
  vertical-align: top;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
input.search-query {
  padding-right: 14px;
  padding-right: 4px \9;
  padding-left: 14px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */

  margin-bottom: 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-search .input-append .search-query {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search .input-append .btn {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .btn {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.control-group {
  margin-bottom: 10px;
}
legend + .control-group {
  margin-top: 20px;
  -webkit-margin-top-collapse: separate;
}
.form-horizontal .control-group {
  margin-bottom: 10px;
  *zoom: 1;
  clear: both;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-horizontal .control-group:after {
  clear: both;
}
.form-horizontal .control-label {
  float: left;
  width: 140px;
  padding-top: 5px;
  text-align: right;
}

.form-horizontal .control-label-time {
    float: left;
    width: 60px;
    padding-top: 5px;
    text-align: right;
}

.form-horizontal .control-text {
    padding-top: 5px;
}


.form-horizontal .control-label.align-left {
  text-align: left;
}
.form-horizontal .controls {
  *display: inline-block;
  *padding-left: 20px;
  margin-left: 160px;
  *margin-left: 0;
}

.form-horizontal .controls.checkbox-spacing {
    margin-left: 10px;
}

.form-horizontal .controls-time {
    *display: inline-block;
    *padding-left: 20px;
    margin-left: 80px;
    *margin-left: 0;
}

.form-horizontal .controls:first-child {
  *padding-left: 160px;
}
.form-horizontal .controls.smart-filter,
.form-horizontal .controls.full-width-smart-filter {
  display: block;
}
.form-horizontal .controls span.inline {
  line-height: 30px;
}
.form-horizontal .controls.with-radio label:first-of-type {
  margin-top: 7px;
}
.form-horizontal .help-block {
  margin-top: 10px;
  margin-bottom: 0;
}
.form-horizontal .form-actions {
  padding-left: 160px;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.table {
  width: 100%;
  margin-bottom: 20px;
}
.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}
.table th {
  font-weight: bold;
}
.table thead th {
  vertical-align: bottom;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table tbody + tbody {
  border-top: 2px solid #dddddd;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}
.table-bordered thead:first-child tr:first-child th:last-child,
.table-bordered tbody:first-child tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}
.table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child,
.table-bordered tfoot:last-child tr:last-child td:first-child {
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}
.table-bordered thead:last-child tr:last-child th:last-child,
.table-bordered tbody:last-child tr:last-child td:last-child,
.table-bordered tfoot:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}
.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}
.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-right-topleft: 4px;
}
.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #f5f5f5;
}
table [class*=span],
.row-fluid table [class*=span] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
table .span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}
table .span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}
table .span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}
table .span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}
table .span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}
table .span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}
table .span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}
table .span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}
table .span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}
table .span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}
table .span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}
table .span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}
table .span13 {
  float: none;
  width: 1004px;
  margin-left: 0;
}
table .span14 {
  float: none;
  width: 1084px;
  margin-left: 0;
}
table .span15 {
  float: none;
  width: 1164px;
  margin-left: 0;
}
table .span16 {
  float: none;
  width: 1244px;
  margin-left: 0;
}
table .span17 {
  float: none;
  width: 1324px;
  margin-left: 0;
}
table .span18 {
  float: none;
  width: 1404px;
  margin-left: 0;
}
table .span19 {
  float: none;
  width: 1484px;
  margin-left: 0;
}
table .span20 {
  float: none;
  width: 1564px;
  margin-left: 0;
}
table .span21 {
  float: none;
  width: 1644px;
  margin-left: 0;
}
table .span22 {
  float: none;
  width: 1724px;
  margin-left: 0;
}
table .span23 {
  float: none;
  width: 1804px;
  margin-left: 0;
}
table .span24 {
  float: none;
  width: 1884px;
  margin-left: 0;
}
.table tbody tr.success td {
  background-color: #dff0d8;
}
.table tbody tr.error td {
  background-color: #f2dede;
}
.table tbody tr.info td {
  background-color: #d9edf7;
}
[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image:url("../images/glyphicons-halflings.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px;
}
/* White icons with optional class, or on hover/active states of certain elements */
.icon-white,
.nav > .active > a > [class^="icon-"],
.nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"] {
    background-image:url("../images/glyphicons-halflings-white.png");
}
.icon-glass {
  background-position: 0      0;
}
.icon-music {
  background-position: -24px 0;
}
.icon-search {
  background-position: -48px 0;
}
.icon-envelope {
  background-position: -72px 0;
}
.icon-heart {
  background-position: -96px 0;
}
.icon-star {
  background-position: -120px 0;
}
.icon-star-empty {
  background-position: -144px 0;
}
.icon-user {
  background-position: -168px 0;
}
.icon-film {
  background-position: -192px 0;
}
.icon-th-large {
  background-position: -216px 0;
}
.icon-th {
  background-position: -240px 0;
}
.icon-th-list {
  background-position: -264px 0;
}
.icon-ok {
  background-position: -288px 0;
}
.icon-remove {
  background-position: -312px 0;
}
.icon-zoom-in {
  background-position: -336px 0;
}
.icon-zoom-out {
  background-position: -360px 0;
}
.icon-off {
  background-position: -384px 0;
}
.icon-signal {
  background-position: -408px 0;
}
.icon-cog {
  background-position: -432px 0;
}
.icon-trash {
  background-position: -456px 0;
}
.icon-home {
  background-position: 0 -24px;
}
.icon-file {
  background-position: -24px -24px;
}
.icon-time {
  background-position: -48px -24px;
}
.icon-road {
  background-position: -72px -24px;
}
.icon-download-alt {
  background-position: -96px -24px;
}
.icon-download {
  background-position: -120px -24px;
}
.icon-upload {
  background-position: -144px -24px;
}
.icon-inbox {
  background-position: -168px -24px;
}
.icon-play-circle {
  background-position: -192px -24px;
}
.icon-repeat {
  background-position: -216px -24px;
}
.icon-refresh {
  background-position: -240px -24px;
}
.icon-list-alt {
  background-position: -264px -24px;
}
.icon-lock {
  background-position: -287px -24px;
}
.icon-flag {
  background-position: -312px -24px;
}
.icon-headphones {
  background-position: -336px -24px;
}
.icon-volume-off {
  background-position: -360px -24px;
}
.icon-volume-down {
  background-position: -384px -24px;
}
.icon-volume-up {
  background-position: -408px -24px;
}
.icon-qrcode {
  background-position: -432px -24px;
}
.icon-barcode {
  background-position: -456px -24px;
}
.icon-tag {
  background-position: 0 -48px;
}
.icon-tags {
  background-position: -25px -48px;
}
.icon-book {
  background-position: -48px -48px;
}
.icon-bookmark {
  background-position: -72px -48px;
}
.icon-print {
  background-position: -96px -48px;
}
.icon-camera {
  background-position: -120px -48px;
}
.icon-font {
  background-position: -144px -48px;
}
.icon-bold {
  background-position: -167px -48px;
}
.icon-italic {
  background-position: -192px -48px;
}
.icon-text-height {
  background-position: -216px -48px;
}
.icon-text-width {
  background-position: -240px -48px;
}
.icon-align-left {
  background-position: -264px -48px;
}
.icon-align-center {
  background-position: -288px -48px;
}
.icon-align-right {
  background-position: -312px -48px;
}
.icon-align-justify {
  background-position: -336px -48px;
}
.icon-list {
  background-position: -360px -48px;
}
.icon-indent-left {
  background-position: -384px -48px;
}
.icon-indent-right {
  background-position: -408px -48px;
}
.icon-facetime-video {
  background-position: -432px -48px;
}
.icon-picture {
  background-position: -456px -48px;
}
.icon-pencil {
  background-position: 0 -72px;
}
.icon-map-marker {
  background-position: -24px -72px;
}
.icon-adjust {
  background-position: -48px -72px;
}
.icon-tint {
  background-position: -72px -72px;
}
.icon-edit {
  background-position: -96px -72px;
}
.icon-share {
  background-position: -120px -72px;
}
.icon-check {
  background-position: -144px -72px;
}
.icon-move {
  background-position: -168px -72px;
}
.icon-step-backward {
  background-position: -192px -72px;
}
.icon-fast-backward {
  background-position: -216px -72px;
}
.icon-backward {
  background-position: -240px -72px;
}
.icon-play {
  background-position: -264px -72px;
}
.icon-pause {
  background-position: -288px -72px;
}
.icon-stop {
  background-position: -312px -72px;
}
.icon-forward {
  background-position: -336px -72px;
}
.icon-fast-forward {
  background-position: -360px -72px;
}
.icon-step-forward {
  background-position: -384px -72px;
}
.icon-eject {
  background-position: -408px -72px;
}
.icon-chevron-left {
  background-position: -432px -72px;
}
.icon-chevron-right {
  background-position: -456px -72px;
}
.icon-plus-sign {
  background-position: 0 -96px;
}
.icon-minus-sign {
  background-position: -24px -96px;
}
.icon-remove-sign {
  background-position: -48px -96px;
}
.icon-ok-sign {
  background-position: -72px -96px;
}
.icon-question-sign {
  background-position: -96px -96px;
}
.icon-info-sign {
  background-position: -120px -96px;
}
.icon-screenshot {
  background-position: -144px -96px;
}
.icon-remove-circle {
  background-position: -168px -96px;
}
.icon-ok-circle {
  background-position: -192px -96px;
}
.icon-ban-circle {
  background-position: -216px -96px;
}
.icon-arrow-left {
  background-position: -240px -96px;
}
.icon-arrow-right {
  background-position: -264px -96px;
}
.icon-arrow-up {
  background-position: -289px -96px;
}
.icon-arrow-down {
  background-position: -312px -96px;
}
.icon-share-alt {
  background-position: -336px -96px;
}
.icon-resize-full {
  background-position: -360px -96px;
}
.icon-resize-small {
  background-position: -384px -96px;
}
.icon-plus {
  background-position: -408px -96px;
}
.icon-minus {
  background-position: -433px -96px;
}
.icon-asterisk {
  background-position: -456px -96px;
}
.icon-exclamation-sign {
  background-position: 0 -120px;
}
.icon-gift {
  background-position: -24px -120px;
}
.icon-leaf {
  background-position: -48px -120px;
}
.icon-fire {
  background-position: -72px -120px;
}
.icon-eye-open {
  background-position: -96px -120px;
}
.icon-eye-close {
  background-position: -120px -120px;
}
.icon-warning-sign {
  background-position: -144px -120px;
}
.icon-plane {
  background-position: -168px -120px;
}
.icon-calendar {
  background-position: -192px -120px;
}
.icon-random {
  background-position: -216px -120px;
  width: 16px;
}
.icon-comment {
  background-position: -240px -120px;
}
.icon-magnet {
  background-position: -264px -120px;
}
.icon-chevron-up {
  background-position: -288px -120px;
}
.icon-chevron-down {
  background-position: -313px -119px;
}
.icon-retweet {
  background-position: -336px -120px;
}
.icon-shopping-cart {
  background-position: -360px -120px;
}
.icon-folder-close {
  background-position: -384px -120px;
}
.icon-folder-open {
  background-position: -408px -120px;
  width: 16px;
}
.icon-resize-vertical {
  background-position: -432px -119px;
}
.icon-resize-horizontal {
  background-position: -456px -118px;
}
.icon-hdd {
  background-position: 0 -144px;
}
.icon-bullhorn {
  background-position: -24px -144px;
}
.icon-bell {
  background-position: -48px -144px;
}
.icon-certificate {
  background-position: -72px -144px;
}
.icon-thumbs-up {
  background-position: -96px -144px;
}
.icon-thumbs-down {
  background-position: -120px -144px;
}
.icon-hand-right {
  background-position: -144px -144px;
}
.icon-hand-left {
  background-position: -168px -144px;
}
.icon-hand-up {
  background-position: -192px -144px;
}
.icon-hand-down {
  background-position: -216px -144px;
}
.icon-circle-arrow-right {
  background-position: -240px -144px;
}
.icon-circle-arrow-left {
  background-position: -264px -144px;
}
.icon-circle-arrow-up {
  background-position: -288px -144px;
}
.icon-circle-arrow-down {
  background-position: -312px -144px;
}
.icon-globe {
  background-position: -336px -144px;
}
.icon-wrench {
  background-position: -360px -144px;
}
.icon-tasks {
  background-position: -384px -144px;
}
.icon-filter {
  background-position: -408px -144px;
}
.icon-briefcase {
  background-position: -432px -144px;
}
.icon-fullscreen {
  background-position: -456px -144px;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.dropdown-menu a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #505b65;
  white-space: nowrap;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  text-decoration: none;
  color: #ffffff;
  outline: 0;
  background-color: #eeeeee;
  background-color: #e9e9e9;
  background-image: -moz-linear-gradient(top, #eeeeee, #e1e1e1);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#e1e1e1));
  background-image: -webkit-linear-gradient(top, #eeeeee, #e1e1e1);
  background-image: -o-linear-gradient(top, #eeeeee, #e1e1e1);
  background-image: linear-gradient(to bottom, #eeeeee, #e1e1e1);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffe1e1e1', GradientType=0);
}
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #eeeeee;
  background-color: #e9e9e9;
  background-image: -moz-linear-gradient(top, #eeeeee, #e1e1e1);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#e1e1e1));
  background-image: -webkit-linear-gradient(top, #eeeeee, #e1e1e1);
  background-image: -o-linear-gradient(top, #eeeeee, #e1e1e1);
  background-image: linear-gradient(to bottom, #eeeeee, #e1e1e1);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffe1e1e1', GradientType=0);
}
.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: #999999;
}
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.open {
  *z-index: 1000;
}
.open > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "\2191";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  margin-top: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #c9c9c9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.well blockquote {
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
  padding: 24px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.well-small {
  padding: 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  overflow: visible \9;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  color: #000000;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.close:hover {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  line-height: 20px;
  margin-left: 10px;
}
.btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  padding: 4px 14px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  *line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #787272;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: 1px solid #bbbbbb;
  *border: 0;
  border-bottom-color: #a2a2a2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *margin-left: .3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #787272;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.btn:active,
.btn.active {
  background-color: #cccccc \9;
}
.btn:first-child {
  *margin-left: 0;
}
.btn:hover {
  color: #5e6d7f;
  text-decoration: none;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
  /* Buttons in IE7 don't get borders, so darken on hover */

  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.active,
.btn:active {
  background-color: #e6e6e6;
  background-color: #d9d9d9 \9;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-color: #e6e6e6;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn.no-margin {
  margin: 0 !important;
}
.btn-large {
  padding: 9px 14px;
  font-size: 16px;
  line-height: normal;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.btn-large [class^="icon-"] {
  margin-top: 2px;
}
.btn-small {
  padding: 3px 9px;
  font-size: 12px;
  line-height: 18px;
}
.btn-small [class^="icon-"] {
  margin-top: 0;
}
.btn-mini {
  padding: 2px 6px;
  font-size: 11px;
  line-height: 16px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn {
  border-color: #c5c5c5;
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
}
.btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #4d74a2;
  background-image: -moz-linear-gradient(top, #4d7fa2, #4d63a2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d7fa2), to(#4d63a2));
  background-image: -webkit-linear-gradient(top, #4d7fa2, #4d63a2);
  background-image: -o-linear-gradient(top, #4d7fa2, #4d63a2);
  background-image: linear-gradient(to bottom, #4d7fa2, #4d63a2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4d7fa2', endColorstr='#ff4d63a2', GradientType=0);
  border-color: #4d63a2 #4d63a2 #34436e;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #4d63a2;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #ffffff;
  background-color: #4d63a2;
  *background-color: #455891;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #3d4e7f \9;
}
.btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f89406;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #ffffff;
  background-color: #f89406;
  *background-color: #df8505;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}
.btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffbd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #bd362f;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #ffffff;
  background-color: #bd362f;
  *background-color: #a9302a;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}
.btn-success,
.btn-edit {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #89b527;
  background-image: -moz-linear-gradient(top, #9ac431, #709f17);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9ac431), to(#709f17));
  background-image: -webkit-linear-gradient(top, #9ac431, #709f17);
  background-image: -o-linear-gradient(top, #9ac431, #709f17);
  background-image: linear-gradient(to bottom, #9ac431, #709f17);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9ac431', endColorstr='#ff709f17', GradientType=0);
  border-color: #709f17 #709f17 #415c0d;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #709f17;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-success:hover,
.btn-edit:hover,
.btn-success:active,
.btn-edit:active,
.btn-success.active,
.btn-edit.active,
.btn-success.disabled,
.btn-edit.disabled,
.btn-success[disabled],
.btn-edit[disabled] {
  color: #ffffff;
  background-color: #709f17;
  *background-color: #608814;
}
.btn-success:active,
.btn-edit:active,
.btn-success.active,
.btn-edit.active {
  background-color: #517210 \9;
}
.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f96b4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #ffffff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}
.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}
.btn-inverse {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #4e545c;
  background-image: -moz-linear-gradient(top, #444444, #5e6d7f);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444444), to(#5e6d7f));
  background-image: -webkit-linear-gradient(top, #444444, #5e6d7f);
  background-image: -o-linear-gradient(top, #444444, #5e6d7f);
  background-image: linear-gradient(to bottom, #444444, #5e6d7f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff5e6d7f', GradientType=0);
  border-color: #5e6d7f #5e6d7f #3d4753;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #5e6d7f;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #ffffff;
  background-color: #5e6d7f;
  *background-color: #536070;
}
.btn-inverse:active,
.btn-inverse.active {
  background-color: #485462 \9;
}
.btn-action {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #50799c;
  background-image: -moz-linear-gradient(top, #537ca1, #4d7495);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#537ca1), to(#4d7495));
  background-image: -webkit-linear-gradient(top, #537ca1, #4d7495);
  background-image: -o-linear-gradient(top, #537ca1, #4d7495);
  background-image: linear-gradient(to bottom, #537ca1, #4d7495);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff537ca1', endColorstr='#ff4d7495', GradientType=0);
  border-color: #4d7495 #4d7495 #334d62;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #4d7495;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: 1px solid #42627f;
  font-weight: normal;
  box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.7), inset 1px 1px 1px 0px #537ca1;
}
.btn-action:hover,
.btn-action:active,
.btn-action.active,
.btn-action.disabled,
.btn-action[disabled] {
  color: #ffffff;
  background-color: #4d7495;
  *background-color: #446784;
}
.btn-action:active,
.btn-action.active {
  background-color: #3b5a73 \9;
}
.btn-action:hover {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #4f779a;
  background-image: -moz-linear-gradient(top, #4d7495, #537ca1);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d7495), to(#537ca1));
  background-image: -webkit-linear-gradient(top, #4d7495, #537ca1);
  background-image: -o-linear-gradient(top, #4d7495, #537ca1);
  background-image: linear-gradient(to bottom, #4d7495, #537ca1);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4d7495', endColorstr='#ff537ca1', GradientType=0);
  border-color: #537ca1 #537ca1 #39556f;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #537ca1;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-action:hover:hover,
.btn-action:hover:active,
.btn-action:hover.active,
.btn-action:hover.disabled,
.btn-action:hover[disabled] {
  color: #ffffff;
  background-color: #537ca1;
  *background-color: #4a6f90;
}
.btn-action:hover:active,
.btn-action:hover.active {
  background-color: #42627f \9;
}
.action-btn {
  color: #d78430;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.action-btn:hover {
  color: #d78430;
}
button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.btn-link,
.btn-link:active {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #3883c1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-link:hover {
  color: #3276ad;
  text-decoration: underline;
  background-color: transparent;
}
.btn-group {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  *margin-left: .3em;
}
.btn-group:first-child {
  *margin-left: 0;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-toolbar .btn-group {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
}
.btn-toolbar .btn + .btn,
.btn-toolbar .btn-group + .btn,
.btn-toolbar .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu {
  font-size: 14px;
}
.btn-group > .btn-mini {
  font-size: 11px;
}
.btn-group > .btn-small {
  font-size: 12px;
}
.btn-group > .btn-large {
  font-size: 16px;
}
.btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #4d63a2;
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #bd362f;
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: #709f17;
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #5e6d7f;
}
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.btn-mini .caret,
.btn-small .caret,
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.dropup .btn-large .caret {
  border-bottom: 5px solid #000000;
  border-top: 0;
}
.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
}
.btn-group-vertical .btn {
  display: block;
  float: none;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group-vertical .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical .btn:first-child {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.btn-group-vertical .btn:last-child {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.btn-group-vertical .btn-large:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.btn-group-vertical .btn-large:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #c09853;
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.alert-danger,
.alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
.nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.nav > li > a {
  display: block;
}
.nav > li > a:hover {
  text-decoration: none;
  background-color: #eeeeee;
}
.nav > .pull-right {
  float: right;
}
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.nav li + .nav-header {
  margin-top: 9px;
}
.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #3883c1;
}
.nav-list [class^="icon-"] {
  margin-right: 2px;
}
.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.nav-tabs,
.nav-pills {
  *zoom: 1;
  clear: both;
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.nav-tabs:after,
.nav-pills:after {
  clear: both;
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #dddddd;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  color: #ffffff;
  background-color: #3883c1;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.nav-tabs.nav-stacked > li:last-child > a {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.nav-tabs.nav-stacked > li > a:hover {
  border-color: #ddd;
  z-index: 2;
}
.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.nav-pills .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.nav .dropdown-toggle .caret {
  border-top-color: #3883c1;
  border-bottom-color: #3883c1;
  margin-top: 6px;
}
.nav .dropdown-toggle:hover .caret {
  border-top-color: #3276ad;
  border-bottom-color: #3276ad;
}
/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555555;
  border-bottom-color: #555555;
}
.nav > .dropdown.active > a:hover {
  cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover {
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tabs-stacked .open > a:hover {
  border-color: #999999;
}
.tabbable {
  *zoom: 1;
  clear: both;
}
.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tabbable:after {
  clear: both;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover {
  border-color: transparent #ddd #ddd #ddd;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}
.nav > .disabled > a {
  color: #999999;
}
.nav > .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.top_bar_wrapper {
  height: 40px;
  background: #477596;
}
.top_bar_wrapper .search_bar {
  float: left;
  padding: 7px 0 0 30px;
}
.top_bar_wrapper .search_box {
  background-color: #f7faff;
}
.top_bar_wrapper li {
  list-style: none;
  float: left;
}
.top_bar_wrapper li:hover {
  text-decoration: none;
  text-decoration: underline;
}
.settings-wrapper {
  padding: 0 4px 0 0;
}
.user-wrapper {
  height: 26px;
  padding: 0 0 0 0px;
}
.user-wrapper .user img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: -2px 0 0 0;
}
.options {
    display: block;
    float: right;
    width: 225px;
    margin-right: 5px;
    margin-top: 7px;
}
.options ul {
  margin-left: 15px;
}
.options a.ss-chat,
.options a.ss-list,
.options a.ss-globe,
.options a.ss-settings,
.options a.ss-phone {
  color: #304f65;
  font-size: 19px;
  float: left;
  margin: 5px 4px 0 0;
}
.options a.ss-list.light-blue {
  color: #3b8ac9;
}
.options a.ss-list.white {
  color: #fff;
}
.options a.ss-chat:hover,
.options a.ss-list:hover,
.options a.ss-globe:hover,
.options a.ss-settings:hover {
  text-decoration: none;
  color: #36576f;
}
.options a.ss-list.light-blue:hover {
  color: #3b8ac9
}
.options a.ss-list.white:hover {
  color: #fff;
}
.options a.ss-chat.active,
.options a.ss-list.active,
.options a.ss-globe.active,
.options a.ss-settings.active {
  color: #eceef4;
}
.options a.ss-list.active.light-blue {
  color: #3b8ac9;
}
.options a.ss-list.active.white {
  color: #fff;
}
.options a.ss-globe {
  margin: 5px 0px 0 10px;
}
.options a.ss-settings {
  width: 22px;
  margin-left: 14px;
}
.options a.ss-list {
  margin-left: 8px;
}
.options .settings-caret {
  border-top: 6px solid #304f65;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: 10px 0 0 0;
}
.options .dropdown.active.user-wrapper:hover {
  text-decoration: none;
}
.options ul.dropdown-menu.notifications span.message {
  display: inline;
}
.options ul.dropdown-menu.notifications .title {
  height: auto;
}
.options ul.dropdown-menu.notifications li {
  padding: 0px;
}
.options ul.dropdown-menu.notifications li div.notification-content {
  padding: 8px 16px 0px 16px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-title,
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-date {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-title.notification-date,
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-date.notification-date {
  text-align: right;
  flex: initial;
  margin-left: 10px;
}
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-title h5.name,
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-date h5.name {
  margin: 0 8px 0 0;
}
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-title h5.time,
.options ul.dropdown-menu.notifications li div.notification-content h5.notification-date h5.time {
  padding: 4px 16px 0 0;
}
.options ul.dropdown-menu.notifications li span.notification-excerpt {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
}
.options ul.dropdown-menu.notifications li.bottom {
  padding: 0px !important;
}
.options ul.dropdown-menu.notifications:after {
  left: 300px;
}
.options ul.dropdown-menu.notifications:before {
  left: 300px;
}
ul.dropdown-menu {
  width: 330px;
  border: 1px solid #253f50;
  top: 34px;
  left: 0px;
  padding: 0;
  margin: 0;
  border-radius: 0 0 2px 2px;
}
ul.dropdown-menu.chat {
  left: -300px;
}
ul.dropdown-menu.chat:after {
  left: 300px;
}
ul.dropdown-menu.chat:before {
  left: 300px;
}
ul.dropdown-menu.notifications {
  left: -293px;
}
ul.dropdown-menu.small-menu {
  left: -165px;
  width: 220px;
}
ul.dropdown-menu.small-menu:after {
  left: 183px;
}
ul.dropdown-menu.small-menu:before {
  left: 183px;
}
ul.dropdown-menu.small-menu .links {
  height: 100%;
  padding: 0;
}
ul.dropdown-menu.small-menu .links:hover {
  background: #eceef4;
  cursor: default;
}
ul.dropdown-menu.small-menu .links a {
  color: #3f88cd;
  line-height: 24px;
  padding: 4px 16px;
}
ul.dropdown-menu.small-menu .links a:hover,
ul.dropdown-menu.small-menu .links a.active {
  color: #0077b3;
  background: #f2f4fa;
}
ul.dropdown-menu.small-menu .links a.active {
  border-top: 1px solid #d1d8e7;
  border-bottom: 1px solid #d1d8e7;
}
ul.dropdown-menu.small-menu .links .logout {
  background: #ffffff;
  border-top: 1px solid #d1d8e7;
  line-height: 24px;
}
ul.dropdown-menu.small-menu .links .logout:hover {
  background: #ffffff;
}
ul.dropdown-menu.small-menu .links label {
  margin: 0;
  padding: 5px 10px;
}
ul.dropdown-menu.small-menu.settings {
  left: -169px;
}
ul.dropdown-menu.button-menu {
  left: -175px;
  top: 37px;
  width: 210px;
}
ul.dropdown-menu::after {
  content: ' ';
  display: block;
  position: absolute;
  top: -7px;
  left: 10px;
  border-color: transparent transparent transparent #cad5e0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid #ffffff;
}
ul.dropdown-menu::before {
  content: ' ';
  display: block;
  position: absolute;
  top: -8px;
  left: 10px;
  border-color: transparent transparent transparent #cad5e0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid #253f50;
}
ul.dropdown-menu li {
  float: none;
  background: #eceef4;
  border-top: 1px solid #d1d8e7;
  color: #5e6d7f;
  font-size: 12px;
  padding: 8px 0 12px 16px;
}
ul.dropdown-menu li:hover {
  text-decoration: none;
  background: #f2f4fa;
  cursor: pointer;
}
ul.dropdown-menu li.active {
  background: #f3fccf;
}
ul.dropdown-menu li a {
  margin: 0;
  padding: 0 0 0 16px;
  color: #5e6d7f;
}
ul.dropdown-menu li a:hover {
  background: none;
  text-decoration: none;
}
ul.dropdown-menu li.title {
  background: #ffffff;
  height: 34px;
  line-height: 34px;
  padding: 0 0 0 16px;
  color: #333333;
}
ul.dropdown-menu li.title.top {
  border: none;
  border-radius: 2px;
  margin: 0;
}
ul.dropdown-menu li.title.bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
ul.dropdown-menu li.title h6 {
  display: inline;
  font-weight: bold;
}
ul.dropdown-menu li.title a {
  display: inline;
  height: 34px;
  line-height: 34px;
  padding: 0;
  color: #579cd7;
  font-size: 12px;
  float: right;
  margin: 0 16px 0 0;
}
ul.dropdown-menu li.title a:hover {
  text-decoration: underline;
  color: #3d78be;
}
ul.dropdown-menu li h5.name {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  display: inline;
  float: left;
}
ul.dropdown-menu li h5.time {
  margin: 0;
  padding: 0 16px 0 0;
  font-size: 12px;
  color: #979797;
  font-weight: bold;
  display: block;
  float: right;
  clear: right;
}
ul.dropdown-menu li span.message {
  display: inline-block;
}
.nav_wrapper.main-nav {
  float: left;
}
.nav_wrapper.without-search {
  margin-left: 30px;
}
.nav_wrapper .nav_bar {
  float: left;
}
.nav_wrapper .nav_bar ul {
  float: left;
  margin: 0;
  padding: 0;
}
.nav_wrapper .nav_bar ul span.ss-dropdown {
  padding: 2px 3px 0;
  text-align: center;
  border-radius: 4px;
}
.nav_wrapper .nav_bar ul span.ss-dropdown:hover {
  background: #cfcfff;
}
.nav_wrapper .nav_bar li {
  list-style: none;
  float: left;
  line-height: 40px;
  height: 40px;
  font-size: 13px;
  border-left: 1px solid #446985;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.nav_wrapper .nav_bar li.no-dropdown a {
  padding: 13px 25px 15px;
}
.nav_wrapper .nav_bar li:last-of-type {
  border-right: 1px solid #446985;
}
.nav_wrapper .nav_bar li:hover,
.nav_wrapper .nav_bar li.nav-active,
.nav_wrapper .nav_bar li.open {
  height: 40px;
  text-decoration: none;
}
.nav_wrapper .nav_bar li.nav-active {
  background: #eeeeee;
}
.nav_wrapper .nav_bar li.nav-active a {
  color: #5e6d7f;
}
.nav_wrapper .nav_bar li:hover {
  background: #6393b5;
}
.nav_wrapper .nav_bar li:hover.nav-active {
  background: #eeeeee;
}
.nav_wrapper .nav_bar li:hover.nav-active a {
  color: #5e6d7f;
}
.nav_wrapper .nav_bar li a {
  color: #d8e8f4;
  padding: 13px 2px 17px 15px;
}
.nav_wrapper .nav_bar li a span.ss-dropdown {
  font-size: 10px;
}
.nav_wrapper .nav_bar li a.dropdown-toggle {
  padding: 0 15px 0 0;
}
.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 20px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.breadcrumb li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  text-shadow: 0 1px 0 #ffffff;
}
.breadcrumb .divider {
  padding: 0 5px;
  color: #ccc;
}
.breadcrumb .active {
  color: #999999;
}
.pagination {
  height: 40px;
  margin: 10px 0;
}
.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */

  *zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.pagination li {
  display: inline;
}
.pagination a,
.pagination span {
  float: left;
  padding: 0 14px;
  line-height: 28px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
}
.pagination a:hover,
.pagination .active a,
.pagination .active span {
  background-color: #f5f5f5;
}
.pagination .active a,
.pagination .active span {
  color: #999999;
  cursor: default;
}
.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}
.pagination li:first-child a,
.pagination li:first-child span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.pagination li:last-child a,
.pagination li:last-child span {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
.pager {
  margin: 20px 0;
  list-style: none;
  text-align: center;
  *zoom: 1;
  clear: both;
}
.pager:before,
.pager:after {
  display: table;
  content: "";
  line-height: 0;
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager a {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}
.pager .next a {
  float: right;
}
.pager .previous a {
  float: left;
}
.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
  background-color: #fff;
  cursor: default;
}
.modal-open .dropdown-menu {
  z-index: 2050;
}
.modal-open .dropdown.open {
  *z-index: 2050;
}
.modal-open .popover {
  z-index: 2060;
}
.modal-open .tooltip {
  z-index: 2080;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1050;
  overflow: auto;
  width: 560px;
  margin: -250px 0 0 -280px;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  /* IE6-7 */

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}
.modal.fade {
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
  top: -25%;
}
.modal.fade.in {
  top: 50%;
}
.modal-header {
  background: #6393b5;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  padding: 9px 15px;
}
.modal-header .close {
  margin-top: 3px;
}
.modal-header h3,
.modal-header h4 {
  color: #ffffff;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
}
.modal-body {
  background: #ffffff;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
.modal-form {
  margin-bottom: 0;
}
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  *zoom: 1;
  clear: both;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.tooltip.top {
  margin-top: -3px;
}
.tooltip.right {
  margin-left: 3px;
}
.tooltip.bottom {
    margin-top: 3px !important;
}
.tooltip.left {
  margin-left: -3px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  width: 236px;
  padding: 1px;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
  margin-bottom: 10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-right: 10px;
}
.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.popover-content p,
.popover-content ul,
.popover-content ol {
  margin-bottom: 0;
}
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow:after {
  content: "";
  z-index: -1;
}
.popover.top .arrow {
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px 10px 0;
  border-top-color: #ffffff;
}
.popover.top .arrow:after {
  border-width: 11px 11px 0;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -1px;
  left: -11px;
}
.popover.right .arrow {
  top: 50%;
  left: -10px;
  margin-top: -10px;
  border-width: 10px 10px 10px 0;
  border-right-color: #ffffff;
}
.popover.right .arrow:after {
  border-width: 11px 11px 11px 0;
  border-right-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
  left: -1px;
}
.popover.bottom .arrow {
  top: -10px;
  left: 50%;
  margin-left: -10px;
  border-width: 0 10px 10px;
  border-bottom-color: #ffffff;
}
.popover.bottom .arrow:after {
  border-width: 0 11px 11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -1px;
  left: -11px;
}
.popover.left .arrow {
  top: 50%;
  right: -10px;
  margin-top: -10px;
  border-width: 10px 0 10px 10px;
  border-left-color: #ffffff;
}
.popover.left .arrow:after {
  border-width: 11px 0 11px 11px;
  border-left-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
  right: -1px;
}
.thumbnails {
  margin-left: -20px;
  list-style: none;
  *zoom: 1;
  clear: both;
}
.thumbnails:before,
.thumbnails:after {
  display: table;
  content: "";
  line-height: 0;
}
.thumbnails:after {
  clear: both;
}
.row-fluid .thumbnails {
  margin-left: 0;
}
.thumbnails > li {
  float: left;
  margin-bottom: 20px;
  margin-left: 20px;
}
.thumbnail {
  display: block;
  padding: 4px;
  line-height: 20px;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.thumbnail:hover {
  border-color: #3883c1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.thumbnail .caption {
  padding: 9px;
  color: #555555;
}
.label,
.badge {
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #ffffff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #999999;
}
.label {
  padding: 1px 4px 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.badge {
  padding: 1px 9px 2px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
a.label:hover,
a.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.label-important,
.badge-important {
  background-color: #b94a48;
}
.label-important[href],
.badge-important[href] {
  background-color: #953b39;
}
.label-warning,
.badge-warning {
  background-color: #f89406;
}
.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
.label-success,
.badge-success {
  background-color: #468847;
}
.label-success[href],
.badge-success[href] {
  background-color: #356635;
}
.label-info,
.badge-info {
  background-color: #3a87ad;
}
.label-info[href],
.badge-info[href] {
  background-color: #2d6987;
}
.label-inverse,
.badge-inverse {
  background-color: #dcdada;
}
.label-inverse[href],
.badge-inverse[href] {
  background-color: #c3c0c0;
}
.btn .label,
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-mini .label,
.btn-mini .badge {
  top: 0;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.progress .bar {
  width: 0%;
  height: 100%;
  color: #ffffff;
  float: left;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress .bar + .bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}
.progress-striped .bar {
  background-color: #149bdf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-danger .bar,
.progress .bar-danger {
  background-color: #dd514c;
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffc43c35', GradientType=0);
}
.progress-danger.progress-striped .bar,
.progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-success .bar,
.progress .bar-success {
  background-color: #5eb95e;
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
  background-image: -webkit-linear-gradient(top, #62c462, #57a957);
  background-image: -o-linear-gradient(top, #62c462, #57a957);
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff57a957', GradientType=0);
}
.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #62c462;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-info .bar,
.progress .bar-info {
  background-color: #4bb1cf;
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
  background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff339bb9', GradientType=0);
}
.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-warning .bar,
.progress .bar-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
}
.progress-warning.progress-striped .bar,
.progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.accordion {
  margin-bottom: 20px;
}
.accordion-group {
  margin-bottom: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
.carousel {
  position: relative;
  margin-bottom: 20px;
  line-height: 1;
}
.carousel-inner {
  overflow: auto;
  width: 100%;
  position: relative;
}
.carousel .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel .item > img {
  display: block;
  line-height: 1;
}
.carousel .active,
.carousel .next,
.carousel .prev {
  display: block;
}
.carousel .active {
  left: 0;
}
.carousel .next,
.carousel .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel .next {
  left: 100%;
}
.carousel .prev {
  left: -100%;
}
.carousel .next.left,
.carousel .prev.right {
  left: 0;
}
.carousel .active.left {
  left: -100%;
}
.carousel .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  background: #5e6d7f;
  border: 3px solid #ffffff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.carousel-control.right {
  left: auto;
  right: 15px;
}
.carousel-control:hover {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #dcdada;
  background: rgba(0, 0, 0, 0.75);
}
.carousel-caption h4,
.carousel-caption p {
  color: #ffffff;
  line-height: 20px;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}
.hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  background-color: #eeeeee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  color: inherit;
  letter-spacing: -1px;
}
.hero-unit p {
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  color: inherit;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}
.affix {
  position: fixed;
}
.title_bar {
  background: #eeeeee;
  clear: both;
  position: relative;
  border-bottom: 1px solid #d1d9dd;
}
.title_bar h3 {
  margin: 0px 30px 0 30px;
  color: #5e6d7f;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}
.title_bar h3 .landlord {
  display: block;
  margin-top: -6px;
  color: #5e6d7f;
  margin-bottom: 4px;
}
.title_bar h3 .btn {
  float: right;
  margin: 5px 0;
}
.avatar {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 4px;
  margin: 6px 10px 5px 28px;
  width: 60px;
  height: 60px;
}
.avatar .no-avatar {
  color: #505b65;
  font-size: 40px;
  box-sixing: border-box;
  line-height: 1.8;
  margin: 0 10px;
}
.section-title,
.title_bar {
  position: relative;
}
.section-title .label,
.title_bar .label {
  margin: 11px 10px;
}
.section-title .actions,
.title_bar .actions {
  position: absolute;
  top: 7px;
  right: 0;
}
.section-title.with-edit h3,
.title_bar.with-edit h3,
.section-title.with-title h3,
.title_bar.with-title h3 {
  float: left;
  color: #505b65;
  font-size: 18px;
  margin: 0;
}
.section-title.with-edit button,
.title_bar.with-edit button,
.section-title.with-edit a,
.title_bar.with-edit a {
  float: right;
  margin: 10px 0 0 0;
}
.section-title.with-edit button.btn-small,
.title_bar.with-edit button.btn-small,
.section-title.with-edit a.btn-small,
.title_bar.with-edit a.btn-small {
  margin: 7px 0 0 0;
}
.section-title.with-edit a.delete-link,
.title_bar.with-edit a.delete-link {
  margin: 10px 0 0 10px;
}
.section-title.with-edit a#cancel-panel-btn,
.title_bar.with-edit a#cancel-panel-btn {
  margin: 6px 20px 0 0;
}
.section-title.with-edit a.without-margin,
.title_bar.with-edit a.without-margin {
  margin: 0;
}
.section-title.with-edit a:not(.btn, .icon),
.title_bar.with-edit a:not(.btn, .icon) {
  color: #3883c1;
  font-size: 14px;
}
.section-title.with-edit a.with-left-margin,
.title_bar.with-edit a.with-left-margin {
  margin-left: 10px;
}
.section-title.with-edit.with-inline-helper .download_options,
.title_bar.with-edit.with-inline-helper .download_options {
  margin-top: 0;
}
.section-title.with-edit.with-inline-helper a,
.title_bar.with-edit.with-inline-helper a {
  margin-left: 20px;
}
.section-title.with-fixed-padding,
.title_bar.with-fixed-padding {
  padding: 10px 0px;
}
.section-title.with-fixed-padding .btn,
.title_bar.with-fixed-padding .btn {
  margin: 0px;
}
.section-title.with-inline-helper,
.title_bar.with-inline-helper {
  padding: 10px 0 0 0;
}
.section-title.with-inline-helper.with-bottom-margin,
.title_bar.with-inline-helper.with-bottom-margin {
  margin-bottom: 17px;
}
.section-title.with-inline-helper.without-top-padding,
.title_bar.with-inline-helper.without-top-padding {
  padding-top: 0px;
}
.section-title.with-inline-helper.without-top-padding a,
.title_bar.with-inline-helper.without-top-padding a {
  margin-right: 0px !important;
}
.section-title.with-inline-helper h3,
.title_bar.with-inline-helper h3 {
  line-height: 20px;
}
.section-title.with-inline-helper span:first-of-type,
.title_bar.with-inline-helper span:first-of-type {
  margin: 0 0 0 10px;
}
.section-title.with-inline-helper span span:first-of-type,
.title_bar.with-inline-helper span span:first-of-type {
  margin: 0;
}
.section-title.with-inline-helper .btn span,
.title_bar.with-inline-helper .btn span {
  margin-left: 0px;
}
.section-title.with-inline-helper .help-block,
.title_bar.with-inline-helper .help-block {
  padding-left: 0;
  color: #60a369;
  font-size: 14px;
  display: inline;
  font-style: italic;
}
.section-title.with-inline-helper .help-block.with-arrow,
.title_bar.with-inline-helper .help-block.with-arrow {
  position: relative;
  color: #60a369;
}
.section-title.with-inline-helper .help-block.with-arrow .helper-arrow,
.title_bar.with-inline-helper .help-block.with-arrow .helper-arrow {
  position: absolute;
  width: 105px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transform: rotate(148deg);
  -moz-transform: rotate(148deg);
  -ms-transform: rotate(148deg);
  -o-transform: rotate(148deg);
  transform: rotate(148deg);
  margin: 0;
  top: -21px;
}
.section-title.with-inline-helper .download_options,
.title_bar.with-inline-helper .download_options {
  float: right;
  margin: -11px 0 -2px 0;
}
.section-title.with-inline-helper .download_options .directive-link-tooltip,
.title_bar.with-inline-helper .download_options .directive-link-tooltip {
  float: right;
}
.section-title.with-inline-helper .download_options .icon,
.title_bar.with-inline-helper .download_options .icon {
  margin: 2px 0 -2px 8px;
}
.section-title.with-inline-helper .download_options .btn,
.title_bar.with-inline-helper .download_options .btn {
  font-size: 12px;
  float: right;
  margin: -4px 0 0 0;
}
.section-title.with-inline-helper hr,
.title_bar.with-inline-helper hr {
  margin-top: 10px;
}
.title_bar .actions {
  *zoom: 1;
  clear: both;
  right: 30px;
  top: 15px;
  width: 250px;
}
.title_bar .actions:before,
.title_bar .actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.title_bar .actions:after {
  clear: both;
}
.title_bar .actions .btn-edit {
  float: left;
  margin-right: 15px;
}
.title_bar .actions .btn-quick-links {
  float: left;
}
* {
  outline: none;
}
body {
  color: #505b65;
  -webkit-backface-visibility: visible !important;
}
a {
  color: #3883c1;
  -webkit-transition: color 0.1s ease-in;
  -moz-transition: color 0.1s ease-in;
  -o-transition: color 0.1s ease-in;
  transition: color 0.1s ease-in;
}
a:hover {
  color: #265882;
}
a.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
a.disabled:hover {
  color: #3883c1;
}
.container {
  width: 100%;
}
.row-fluid {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 30px;
}
.row-fluid.without-padding {
  padding: 0;
}
.row-fluid.small-padding {
    padding: 0 10px !important;
}
.row-fluid.no-top-spacing {
  padding-top: 0;
}
.row-fluid.bottom-margin {
  margin-bottom: 15px;
}
.row-fluid.with-top-margin {
  margin-top: 15px !important;
}
.row-fluid.with-top-padding {
  padding-top: 20px;
}
.row-fluid.without-configure-link {
  padding: 0px 10px;
}
.input-clearfix {
  clear: both;
}
.carousel-indicators li {
  list-style: none;
}
.input-append .add-on,
.input-prepend .add-on {
  text-shadow: none;
  line-height: 23px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
}
.top-margin {
  margin-top: 10px;
}
.data-section {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #ffffff;
  padding: 0 10px 10px;
  border: 1px solid #c9c9c9;
  margin-bottom: 10px;
}
.no-margin .data-section {
    margin: 0 !important;
}

.data-section.with-help-text {
  padding: 0 0 20px 0;
}
.data-section.with-help-text .section-title h3 {
  padding-left: 10px;
}
.data-section.with-only-help {
  padding: 0 14px 0;
}
.data-section.with-only-help .span12 {
  padding: 10px 0 0 0;
  color: #60a369;
}
.data-section.with-only-info {
  background: #f9f3de;
  border: 1px solid #e0cd96;
  padding: 0 14px 0;
}
.data-section.with-only-info .span12 {
  padding: 10px 0 0 0;
}
.data-section.with-only-info .ss-info {
  position: relative;
  top: 2px;
  font-size: 16px;
  margin-right: 2px;
  color: #697782;
}
.data-section.reminders-no-margin {
  margin-left: 0px;
}
.data-section.with-slider {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}
.data-section.with-slider.property-details {
  min-height: 229px;
}
.data-section.with-slider .carousel {
  float: left;
  width: 100%;
  height: 170px;
}
.data-section.with-slider .carousel .carousel-inner {
    height: 190px;
    overflow: visible;
}
.data-section.with-slider .carousel .item {
  height: 100%;
}
.data-section.with-slider .carousel .item .row-fluid {
  height: 100%;
  overflow: auto;
}
.data-section.with-slider .carousel .item .row-fluid .left-column,
.data-section.with-slider .carousel .item .row-fluid .right-column,
.data-section.with-slider .carousel .item .row-fluid .span6 {
    height: 190px;
}
.data-section.with-slider .carousel-indicators {
  margin: 0;
}
.data-section.with-slider.small-slider .carousel {
  height: 130px;
}
.data-section.with-slider.small-slider .carousel .carousel-inner {
  height: 125px;
}
.data-section.with-slider.small-slider .carousel .carousel-inner .span6 {
  height: 125px;
}
.data-section .section-separator {
  margin: 0 0 10px;
}
.data-section .data-section-help {
  clear: both;
  padding: 0 10px;
}
.data-section.with-filter {
  margin-top: -1px;
}
.data-section .button-collection a.btn-small {
  margin: 7px 5px 0 5px;
}
.data-section .button-collection a.btn-small:first-of-type {
  margin-right: 0;
}
.data-section.with-tooltips h3 {
  float: left;
}
.data-section.with-tooltips .section-btns {
  display: inline;
  float: right;
}
.data-section.with-tooltips .section-btns a.btn-small,
.data-section.with-tooltips .section-btns button.btn-small {
  margin: 7px 3px 0 3px;
  float: left;
}
.data-section.with-tooltips .section-btns a.btn-small:last-of-type,
.data-section.with-tooltips .section-btns button.btn-small:last-of-type {
  margin-right: 0;
}
.data-section.with-tooltips .download_options {
  float: right;
  margin: 12px 0 0 8px;
  position: relative;
}
.data-section.with-tooltips .download_options .download_options {
  margin: 0px;
}
.data-section.with-tooltips .download_options a {
  margin: 0 0 0 10px;
}
.data-section.with-tooltips .download_options .action-btn,
.data-section.with-tooltips .download_options .btn-primary {
  margin-top: -5px;
}
.data-section.with-tooltips .download_options .action-btn.btn-small,
.data-section.with-tooltips .download_options .btn-primary.btn-small {
  font-size: 12px;
}
.data-section.without-margin {
  margin-left: 0;
  margin-right: 0;
}
.data-section.with-small-title {
  padding: 0px;
}
.data-section.with-small-title.highlight {
  -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
}
.data-section.with-small-title .section-title {
  float: left;
  width: 100%;
  height: 37px;
  padding: 11px 10px 4px;
  margin-bottom: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px #fdfdfd inset;
  -moz-box-shadow: 0px 1px 0px #fdfdfd inset;
  box-shadow: 0px 1px 0px #fdfdfd inset;
  background: #fcfcfc;
  border-bottom: 1px solid #efefef;
}
.data-section.with-small-title .section-title .open-controls {
  float: left;
  font-size: 12px;
}
.data-section.with-small-title .section-title .open-controls a {
  margin-top: 0px;
}
.data-section.with-small-title .section-title .open-controls input {
  margin-top: -6px;
  margin-right: 7px;
}
.data-section.with-small-title .section-title .download_options {
  float: right;
  margin: 0;
}
.data-section.with-small-title .section-title .download_options .icon {
  font-size: 15px;
  width: 15px;
  height: 15px;
  margin: 0 2px 0 12px;
}
.data-section.with-small-title .section-title .download_options .icon:hover {
  cursor: pointer;
}
.data-section.with-small-title .section-title .quick-links-btn {
  margin-top: -5px;
  margin-right: 0px;
  background-color: white;
}
.data-section.with-small-title .section-title .icon {
  float: left;
  margin: 0 0 0 15px;
}
.data-section.with-small-title .section-title .text {
  float: left;
  margin: -2px 0 0 7px;
}
.data-section.with-small-title .section-title strong {
  color: #677077;
}
.data-section.with-small-title .section-title .with-edit h3 {
  margin: 0 0 0 10px;
  line-height: 16px;
}
.data-section.with-small-title .section-title .with-edit a {
  margin: -2px 0 0 10px;
}
.data-section.with-small-title .section-title h3 {
  font-size: 18px;
  line-height: 15px;
}
.data-section.with-small-title .section-title .btn-small {
  font-size: 12px;
  color: #ffffff;
  margin-top: -6px;
}
.data-section.with-small-title .section-title .label {
  margin-top: -2px;
  float: right;
}
.data-section.with-small-title .section-title .label.label-success {
  background: #60a369;
}
.data-section.with-small-title .section-title .label.label-important {
  background: #b95858;
}
.data-section.with-small-title .section-body {
  padding: 0 10px 10px;
}
.data-section.with-only-title .section-title {
  margin: 0px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  float: none;
  border: none;
}
.data-section-divider {
  margin: 0 0 15px -10px;
  padding: 0 20px 0 0;
  border-top: 1px solid #eeeeee;
}
.data-section .view-controls,
.transparent-section .view-controls {
  float: left;
  position: relative;
  margin: 9px 0 8px;
}
.data-section .view-controls span,
.transparent-section .view-controls span {
  float: left;
  margin: 3px 4px 0 0;
  font-size: 13px;
  color: #838d96;
}
.data-section .view-controls a,
.transparent-section .view-controls a {
  float: left;
  margin: 0 2px;
  width: 13px;
  height: 16px;
  font-size: 13px;
  padding: 2px 4px;
  color: #677077;
  border: 1px solid transparent;
  border-bottom-width: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.data-section .view-controls a:hover,
.transparent-section .view-controls a:hover,
.data-section .view-controls a.active,
.transparent-section .view-controls a.active {
  border-color: #a2a8ac;
}
.data-section .view-controls a.active,
.transparent-section .view-controls a.active {
  background: #ffffff;
}
.data-section .view-controls.next-to-title,
.transparent-section .view-controls.next-to-title {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #eeeeee;
}
.transparent-section .section-title {
  float: left;
  width: 100%;
  margin: 3px 0;
}
.transparent-section .section-title a.small-top-margin {
  margin-top: 5px;
}
.transparent-section .section-title a.add-category-btn {
  margin: -6px 0 0 20px;
}
.transparent-section .view-controls.next-to-title {
  border-color: #c9c9c9;
}
.data-section-slider .tooltip .tooltip-inner {
  padding: 5px 10px;
}
.data-section-slider .carousel {
  margin: 0;
}
.data-section-slider .field {
  line-height: 20px;
  min-height: 20px;
}
.data-section-slider .field.with-profile-image {
  min-height: 0;
}
.data-section-slider .circle-controller {
  position: absolute;
  right: 0;
  bottom: -30px;
  height: 16px;
}
.data-section-slider .circle-controller li {
  line-height: 0;
  list-style: none;
  display: inline-block;
}
.data-section-slider .circle-controller li:last-of-type {
  margin-left: 6px;
}
.data-section-slider .circle-controller button {
  border: none;
  display: inline;
  padding: 0;
  line-height: 0;
  background: #dad6d6;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.data-section-slider .circle-controller button:hover,
.data-section-slider .circle-controller button.active {
  background: #b6adad;
}
.data-section-slider .calendar-controller {
  position: absolute;
  left: 0;
  right: 0;
  top: -37px;
  margin: 0;
}
.show-more {
  border: 1px solid #c9c9c9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  right: 30px;
  bottom: -9px;
  background: white;
  padding: 0 5px;
}
.show-more .caret {
  margin-top: 8px;
}
.fields {
  margin-bottom: 30px;
}
.row-fluid [class*="span"] .field {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.row-fluid [class*="span"] .field.without-flex {
  display: block;
}
.row-fluid [class*="span"].flex-container {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.field {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.field.without-flex {
  display: block;
}
.field .field-label {
  float: left;
  width: 155px;
  background: none;
  font-weight: bold;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.field .field-label.next-to-input {
  margin-top: 5px;
}
.field .field-label.next-to-button {
  margin-top: 3px;
}
.field .field-label.large {
  width: 165px;
}
.field .field-label.larger {
  width: 235px;
}
.field span.auto-width {
  width: auto;
  max-width: none;
}
.field .right-aligned {
  display: block;
  overflow: hidden;
  padding-left: 20px;
}
.field address,
.field span {
  color: #505b65;
  display: inline-block;
  margin-left: 20px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 5px;
  min-height: 15px;
}
.field address ul,
.field span ul {
  list-style: none;
  margin-left: 0;
}
.field address.without-left-margin,
.field span.without-left-margin {
  margin-left: 0px;
}
.field address.label,
.field span.label {
  color: #ffffff;
}
.field address.without-width,
.field span.without-width {
  max-width: none;
}
.field address.full-width,
.field span.full-width {
  max-width: none;
  width: 100%;
}
.field address span,
.field span span {
  margin-left: 0px;
  max-width: auto;
  margin-bottom: 0px;
}
.field address span.inline-icon,
.field span span.inline-icon {
  position: relative;
  top: 2px;
}
.field address.label,
.field span.label,
.field address.diary-event-status,
.field span.diary-event-status,
.field address.color-square,
.field span.color-square {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.field address.diary-event-status,
.field span.diary-event-status {
  padding-right: 10px;
}
.field.larger-field-label {
  margin-left: 0px;
}
.field .help-text {
  color: #60a369;
}
.field .help-text strong {
  color: #60a369;
}
.field .help-text.with-top-margin {
  margin-top: 20px;
}
.field .with-image img {
  margin: 10px 0 0 0;
}
.field.with-image {
  margin-top: 3px;
  margin-bottom: 6px;
  float: left;
}
.field.with-image .field-label {
  margin-bottom: 0px;
  line-height: 30px;
}
.field.with-image .profile_image {
  margin-left: 20px;
  float: left;
  padding: 0px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.field.with-image .profile_image img {
  margin-left: 0px !important;
}
.field.with-image .person-name {
  margin-top: 4px;
  margin-left: -5px;
}
.field .existing-todos {
  margin: 0 0 15px 195px;
}
.field .existing-todos li {
  padding-left: 0;
  float: left;
  width: 100%;
}
.field .existing-todos span {
  margin: 0;
}
.field .existing-todos .existing-item-actions {
  margin: -19px 0 0 0;
}
.field.with-help .help-block {
  margin: 0 0 0 195px;
  width: 100%;
}
.field.fullwidth {
  float: left;
  width: 100%;
  margin: 0 0 15px 0;
}
.field.fullwidth .field-content {
  max-width: inherit;
  margin: 0 0 0 195px;
  display: inherit;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.field.with-profile-image {
  float: left;
}
.details-block {
  float: left;
}
.details-block .section-title h3 {
  line-height: 16px;
}
.details-block i {
  float: left;
  clear: both;
  margin: 9px 0 6px;
  font-size: 13px;
  color: #838d96;
}
.details-block .data-block {
  float: left;
  clear: both;
  margin: 3px 0 4px;
}
.details-block ul {
  list-style: none;
  color: #505b65;
}
.details-block .icon {
  float: left;
  margin: 2px 0 0 0;
  color: #505b65;
}
.details-block .icon.bottom {
  clear: both;
}
.inline-pagination .pagination {
  margin-bottom: 0;
}
.section-search-invoice .section-separator {
  margin: 10px 0;
}
.actions.with-bottom-margin {
  margin-bottom: 40px;
}
.actions.on-right a,
.actions.on-right button {
  float: right;
}
.actions.on-right .on-right {
  float: right;
  margin-left: 10px !important;
}
.actions.on-right .on-left {
  float: left;
}
.actions.on-right .with-left-margin {
  margin-left: 10px;
}
.actions input {
  margin-top: -2px;
  margin-right: 5px;
}
.actions input.btn-primary {
  margin-top: 1px;
}
.actions input.btn-small {
  margin-top: 4px;
}
.download_options.on-right {
  float: right;
}
.download_options.on-right a {
  margin-left: 10px;
}
.deferred-bootstrap-loading .commusoft-loading {
  z-index: 1000000;
  opacity: 1;
  -webkit-transition: opacity 0s ease-in-out 0s, z-index 0s 0s;
  -moz-transition: opacity 0s ease-in-out 0s, z-index 0s 0s;
  -o-transition: opacity 0s ease-in-out 0s, z-index 0s 0s;
  transition: opacity 0s ease-in-out 0s, z-index 0s 0s;
}
.commusoft-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0;
  text-align: center;
  background: #f7faff;
  -webkit-transition: opacity 0.3s ease-in-out 0.6s, z-index 0s 0.6s;
  -moz-transition: opacity 0.3s ease-in-out 0.6s, z-index 0s 0.6s;
  -o-transition: opacity 0.3s ease-in-out 0.6s, z-index 0s 0.6s;
  transition: opacity 0.3s ease-in-out 0.6s, z-index 0s 0.6s;
  z-index: -10000000;
}
.commusoft-loading .circle {
  width: 340px;
  height: 340px;
  left: 50%;
  top: 50%;
  margin: -170px 0 0 -170px;
  background: #32a6b3;
  -webkit-border-radius: 170px;
  -moz-border-radius: 170px;
  border-radius: 170px;
  position: absolute;
  text-align: center;
  font-size: 1.4em;
  color: #dce6e9;
}
.commusoft-loading .circle .loading-wrap {
  width: auto;
  display: inline-block;
  margin-top: 134px;
  margin-bottom: 20px;
}
.commusoft-loading .circle .loading-wrap span {
  width: 32px;
  height: 32px;
  background-color: #dce6e9;
}
.information-bar {
  position: relative;
  background: #f9f3de;
  border: 1px solid #e0cd96;
  padding: 0 10px 0;
}
.information-bar.with-title-bar {
  padding: 0px;
}
.information-bar .span12 {
  padding: 10px 0 0 0;
  color: #525d66;
  margin-left: 0px;
}
.information-bar .span12.with-bottom-border {
  border-bottom: solid 1px rgba(224, 205, 150, 0.55);
}
.information-bar .icon {
  position: relative;
  top: 3px;
  font-size: 16px;
  margin-right: 9px;
  color: #697782;
}
.information-bar .icon.ss-alert {
  color: #d25656;
}
.information-bar .icon.ss-check {
  color: #60a369;
}
.information-bar .icon.ss-write,
.information-bar .icon.ss-send {
  color: #6393b5;
}
.information-bar .date {
  margin-right: 10px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.information-bar .single-notice {
  position: relative;
  padding-bottom: 10px;
}
.information-bar .information_bar_actions {
  float: right;
  display: inline;
  position: absolute;
  top: 10px;
  right: 0;
}
.information-bar .information_bar_actions a {
  margin-left: 10px;
}
.information-bar .information_bar_actions.single-notice {
  right: 10px;
}
.information-bar p.edit {
  padding-left: 23px;
  padding-top: 5px;
}
.information-bar p.edit .icon {
  font-size: 12px;
  top: 1px;
  margin-right: 6px;
}
.information-bar p.edit input {
  position: relative;
  top: -1px;
  margin-right: 5px;
}
.information-bar p.edit .edit-done {
  padding-left: 22px;
  margin-top: 2px;
  display: inline-block;
  color: #697782;
}
.information-bar p.edit .edit-done.help-text {
  color: #4c8454;
}
.information-bar .with-left-margin {
  margin-left: 25px;
}
.information-bar .help-text {
  margin-bottom: 0px;
}
.information-bar .help-text.with-left-margin {
  padding-top: 5px;
}
.information-bar p {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 9px;
}
.information-bar p strong,
.information-bar p i {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.information-bar i {
  margin-right: 140px;
  margin-left: 5px;
}
.information-bar i.comment {
  margin-left: 10px;
  display: inline-block;
}
.information-bar span i {
  flex: 0 1 auto;
  margin-right: 0px;
  margin-left: 0px;
}
.information-bar .ss-quote {
  font-size: 9px;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  top: -4px;
  margin: 0px 2px;
  display: inline;
  line-height: 4px;
}
.information-bar .ss-quote.less-top {
  top: -10px;
}
.information-bar .ss-quote.rotated {
  display: inline-block;
  transform: rotate(180deg);
  top: -10px;
  right: -2px;
}
.information-bar .ss-quote.rotated.less-top {
  top: -8px;
}
.information-bar .ss-like,
.information-bar .ss-dislike {
  margin: 0px 0px 0px 5px;
}
.information-bar .ss-like {
  color: #60a369;
}
.information-bar .ss-dislike {
  color: #d25656;
}
.information-bar input[type="checkbox"] {
  margin: 0px;
  margin-top: -2px;
  margin-right: 10px;
  margin-left: 2px;
}
.information-bar .helper-arrow {
  position: absolute;
  top: 35px;
  left: 20px;
  width: 60px;
  height: 21px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(20deg) rotateX(180deg);
}
.information-bar .with-title-bar {
  padding-top: 40px;
}
.information-bar .notice-title {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px 12px;
  right: 0px;
  border-bottom: solid 1px #e0cd96;
  background: #fcfcfc;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
}
.information-bar .notice-title .open-controls {
  position: relative;
  top: 2px;
}
.information-bar .notice-title.information-bar-closed {
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.information-bar .notice-title .tooltip {
  margin-left: -5px;
}
.information-bar .information-wrapper {
  max-height: 300px;
  overflow: auto;
}
.information-bar .information-wrapper.with-padding {
  padding: 0px 10px;
}
.information-bar .information-wrapper p {
  padding-bottom: 0px;
}
.information-bar .single-notice p {
  padding-bottom: 0px;
}
.striped-background .information-bar {
  background: repeating-linear-gradient(-45deg, #fdf8f8, #fdf8f8 10px, #ffffff 10px, #ffffff 20px);
  border-color: #edbbbb;
}
.with-bottom-margin .information-bar {
  margin-bottom: 10px;
}
@-webkit-keyframes circle {
  0% {
    -webkit-transform: scale(0.1, 0.1);
  }
  40% {
    -webkit-transform: scale(1, 1);
  }
  70% {
    -webkit-transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
@-webkit-keyframes bouncein {
  0% {
    -webkit-transform: scale(1.025, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
#drag_helper {
  position: fixed;
  background-color: #f7f7f7;
  padding: 5px 10px;
  border-radius: 0px;
  z-index: 9000;
  border: solid 1px #c9c9c9;
  font-weight: bold;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
}
#drag_helper_top {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9001;
  background-color: red;
  height: 10%;
  width: 100%;
}
#drag_helper_bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 9002;
  background-color: red;
  height: 10%;
  width: 100%;
}
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */

  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.drag-handle {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
/* jquery.Jcrop.min.css v0.9.12 (build:20130126) */
.jcrop-holder {
  direction: ltr;
  text-align: left;
}
.jcrop-vline,
.jcrop-hline {
  background: #FFF;
  font-size: 0;
  position: absolute;
}
.jcrop-vline {
  height: 100%;
  width: 1px!important;
}
.jcrop-vline.right {
  right: 0;
}
.jcrop-hline {
  height: 1px!important;
  width: 100%;
}
.jcrop-hline.bottom {
  bottom: 0;
}
.jcrop-tracker {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  height: 100%;
  width: 100%;
}
.jcrop-handle {
  background-color: #333;
  border: 1px #EEE solid;
  font-size: 1px;
  height: 7px;
  width: 7px;
}
.jcrop-handle.ord-n {
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  top: 0;
}
.jcrop-handle.ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -4px;
  margin-left: -4px;
}
.jcrop-handle.ord-e {
  margin-right: -4px;
  margin-top: -4px;
  right: 0;
  top: 50%;
}
.jcrop-handle.ord-w {
  left: 0;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
}
.jcrop-handle.ord-nw {
  left: 0;
  margin-left: -4px;
  margin-top: -4px;
  top: 0;
}
.jcrop-handle.ord-ne {
  margin-right: -4px;
  margin-top: -4px;
  right: 0;
  top: 0;
}
.jcrop-handle.ord-se {
  bottom: 0;
  margin-bottom: -4px;
  margin-right: -4px;
  right: 0;
}
.jcrop-handle.ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -4px;
  margin-left: -4px;
}
.jcrop-dragbar.ord-n,
.jcrop-dragbar.ord-s {
  height: 7px;
  width: 100%;
}
.jcrop-dragbar.ord-e,
.jcrop-dragbar.ord-w {
  height: 100%;
  width: 7px;
}
.jcrop-dragbar.ord-n {
  margin-top: -4px;
}
.jcrop-dragbar.ord-s {
  bottom: 0;
  margin-bottom: -4px;
}
.jcrop-dragbar.ord-e {
  margin-right: -4px;
  right: 0;
}
.jcrop-dragbar.ord-w {
  margin-left: -4px;
}
.jcrop-light .jcrop-vline,
.jcrop-light .jcrop-hline {
  background: #FFF;
  filter: alpha(opacity=70) !important;
  opacity: .70!important;
}
.jcrop-light .jcrop-handle {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #000;
  border-color: #FFF;
  border-radius: 3px;
}
.jcrop-dark .jcrop-vline,
.jcrop-dark .jcrop-hline {
  background: #000;
  filter: alpha(opacity=70) !important;
  opacity: .7!important;
}
.jcrop-dark .jcrop-handle {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #FFF;
  border-color: #000;
  border-radius: 3px;
}
.solid-line .jcrop-vline,
.solid-line .jcrop-hline {
  background: #FFF;
}
.jcrop-holder img,
img.jcrop-preview {
  max-width: none;
}
.todo-title {
  display: block;
  margin-bottom: 20px;
}
.existing-items {
  float: left;
  width: 100%;
  margin: 0 0 -10px 0;
  list-style: none;
}
.existing-items li {
  float: left;
  width: 100%;
  margin: 0 0 5px 0;
}
.existing-items span {
  float: left;
  width: 30%;
}
.delete-existing-item,
.edit-existing-item {
  float: right;
}
.edit-existing-item {
  margin: 0 20px 0 0;
}
.helper-text {
  color: #76818f;
  font-weight: 100;
}
.item-name {
  color: #76818f;
  font-weight: 100;
}
.todo-widget {
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
  background: #ffffff;
  border: 1px solid #dadada;
}
.todo-widget .add-todo-view,
.todo-widget .edit-todo-view {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  float: left;
  width: 100%;
  background: #f9f9f9;
  padding: 14px 24px 20px 24px;
  margin: 20px 0;
}
.todo-widget .certificate-details .title {
  padding: 10px 0 0 0 !important;
}
.todo-widget .add-todo-btn {
  clear: both;
  float: left;
  margin: 0 0 20px 20px;
}
.todo-widget .add-todo-btn.with-top-margin {
  margin-top: 20px;
}
.todo-widget .complete-todos {
  float: right;
  margin: -10px 20px 10px 0;
}
.todo-widget .control-group .todo_description {
  width: 146px;
}
.existing-todos-wrap .add-todo-btn {
  float: right;
  margin: 6px 0px;
}
.existing-todos {
  list-style: none;
}
.existing-todos strong {
  float: left;
  clear: both;
  padding: 15px 20px 0;
  margin: 0 0 12px 0;
}
.existing-todos li {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 20px;
}
.existing-todos li:hover {
  background: #f9f9f9;
}
.existing-todos .ss-check,
.existing-todos .ss-delete {
  float: left;
  margin: 2px 0 -2px 0 !important;
  font-size: 13px;
}
.add-option-wrapper.in-edit-view {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.add-option-wrapper .option-wrapper {
  margin: 6px 0 0 0;
}
.add-option-wrapper .question-type {
  display: block;
}
.add-option-wrapper #addOptionInput {
  position: relative;
  float: left;
  z-index: 1;
}
.add-option-wrapper #editOptionInput {
  position: relative;
  float: left;
  z-index: 1;
}
.add-option-wrapper .edit-wrapper .angular-error-message {
  margin-left: -8px;
}
.add-option-wrapper .angular-error-message {
  clear: both;
  width: 270px;
}
.add-option-wrapper .add-option-btn {
  display: inline-block;
  margin: 4px 0 0 10px;
}
.calendar-wrapper {
  float: left;
  width: 270px;
}
.calendar-wrapper.right-side {
  margin: 0 0 0 20px;
}
.calendar-wrapper .selected-date {
  float: left;
  margin: 12px 0 0 6px;
  font-size: 13px;
  color: #3f6887;
}
.datepicker-control-group {
  position: relative;
}
.datepicker-control-group .ss-calendar {
  color: #606c7f;
  text-shadow: none;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.datepicker-control-group.disabled {
  cursor: not-allowed;
}
.datepicker-control-group.disabled input,
.datepicker-control-group.disabled .add-on {
  pointer-events: none;
}
.datepicker-control-group.disabled input {
  background: #EEEEEE;
}
.page-datepicker .custom-datepicker {
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 2;
  left: 160px;
  top: 29px;
}
.page-datepicker .custom-datepicker.show-above {
  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  top: -244px;
}
.custom-datepicker {
  width: 270px;
  clear: both;
}
.custom-datepicker .row-fluid {
  padding: 0 10px;
}
.custom-datepicker table {
  border: 1px solid #dadada;
}
.custom-datepicker table thead {
  background-color: #ffffff;
  background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
  background-image: -o-linear-gradient(top, #ffffff, #ffffff);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=0);
  border-bottom: 1px solid #e9e9e9;
}
.custom-datepicker table thead .month-tr {
  height: 40px;
}
.custom-datepicker table thead th {
  background: #ffffff;
  padding: 0;
  width: 35px;
  text-align: center;
  letter-spacing: 0px;
}
.custom-datepicker table thead a {
  color: #3f6884;
}
.custom-datepicker table thead a:hover {
  color: #375a73;
}
.custom-datepicker table thead .icon {
  font-size: 10px;
}
.custom-datepicker table thead .icon.ss-navigateleft {
  float: left;
  margin: 0 0 0 4px;
}
.custom-datepicker table thead .icon.ss-navigateright {
  float: right;
  margin: 0 6px 0 0;
}
.custom-datepicker table thead .month-text {
  display: inline-block;
  margin-bottom: 2px;
  border-bottom: 1px solid #e9e9e9;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.custom-datepicker table thead .month-text:hover {
  border-color: #375a73;
}
.custom-datepicker table thead .day-label {
  font-size: 12px;
  color: #7d8a92;
}
.custom-datepicker table tbody tr {
  border-bottom: 1px solid #f2f2f2;
  background: #f8f8f8;
}
.custom-datepicker table tbody tr:last-of-type {
  border-color: #dadada;
}
.custom-datepicker table tbody tr td:last-of-type {
  border-right: 1px solid #dadada;
}
.custom-datepicker table tbody tr:hover {
  background: #f8f8f8;
}
.custom-datepicker table tbody tr:nth-child(odd) {
  background: #f8f8f8;
}
.custom-datepicker table tbody tr:nth-child(odd):hover {
  background: #f8f8f8;
}
.custom-datepicker table tbody td {
  border-right: 1px solid #f2f2f2;
  padding: 0;
  text-align: center;
  background: #ffffff;
  font-size: 13px;
}
.custom-datepicker table tbody td span {
  float: left;
  width: 100%;
}
.custom-datepicker table tbody td a {
  float: left;
  width: 100%;
  line-height: 35px;
  margin-left: 0px !important;
}
.custom-datepicker table tbody td a.selected-day {
  background: #3f6884;
  color: #ffffff;
}
.custom-datepicker table tbody td a.selected-day:hover {
  background: #3f6884;
  color: #ffffff;
}
.custom-datepicker table tbody td a.selected-day .muted {
  background: #3f6884;
  color: #ffffff;
}
.custom-datepicker table tbody td a.disabled {
  color: #505b65;
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-datepicker table tbody td a.disabled:hover {
  background: transparent !important;
}
.custom-datepicker table tbody td:hover a {
  background: #f8f8f8;
}
.custom-datepicker table tbody td .muted {
  background: rgba(245, 245, 245, 0.4);
}
.commusoft-react-component #datepicker-input {
  cursor: pointer;
}
.commusoft-react-component .right-icon {
  cursor: pointer;
}
.nav-tabs.with-smart-filter {
  border: none !important;
  margin: 0 !important;
}
.nav-tabs.with-smart-filter .filter_title {
  color: #999999;
  display: block;
  margin: 4px 10px 0 10px;
}
.smart-filter {
  display: inline;
  height: 27px;
}
.smart-filter .chzn-single,
.smart-filter .chzn-choices {
  height: 30px !important;
  line-height: 30px !important;
  background-color: #ffffff !important;
  background-image: -moz-linear-gradient(left, #ffffff, #ffffff) !important;
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#ffffff), to(#ffffff)) !important;
  background-image: -webkit-linear-gradient(left, #ffffff, #ffffff) !important;
  background-image: -o-linear-gradient(left, #ffffff, #ffffff) !important;
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=1) !important;
  border-color: #cccccc !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}
.smart-filter .chzn-single span,
.smart-filter .chzn-choices span {
  color: #999999 !important;
  font-size: 14px !important;
}
.smart-filter .chzn-single div b {
  margin: 3px 0 0 0;
}
.smart-filter .chzn-choices .search-choice {
  margin-top: 6px !important;
}
.smart-filter .chzn-search {
  padding: 6px 4px !important;
}
.smart-filter input[type="text"] {
  margin-left: 2px !important;
  background: none !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  height: 30px !important;
  box-sizing: border-box;
}
.smart-filter input[type="text"]:focus {
  border: 1px solid rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}
.smart-filter .chzn-drop {
  top: 30px !important;
  border-color: #cccccc !important;
}
.smart-filter .chzn-drop .chzn-results {
  padding: 0 !important;
  margin: -1px 0 0 0 !important;
  border-top: 1px solid #d1d8e7 !important;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.smart-filter .chzn-drop .chzn-results li {
  height: 29px;
  line-height: 29px;
  padding: 0 0 0 24px!important;
  font-size: 14px !important;
  color: #505b65;
  border-bottom: 1px solid #d1d8e7;
}
.smart-filter .chzn-drop .chzn-results li.group {
  font-weight: bold;
  padding-left: 11px !important;
  background: #f4f6f8 !important;
}
.smart-filter .chzn-drop .chzn-results li.group:hover {
  background: #f4f6f8 !important;
}
.smart-filter .chzn-drop .chzn-results li:hover,
.smart-filter .chzn-drop .chzn-results li.highlighted {
  background: #fcfcfc !important;
  color: #505b65;
}
.static-width-filter select {
  width: 250px;
}
.full-width-smart-filter .chzn-container {
  width: 100% !important;
}
.full-width-smart-filter .chzn-drop {
  width: 99.5% !important;
}
.full-width-smart-filter .chzn-search input {
  width: 99% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
}
.full-width-smart-filter.inline {
  display: inline-block;
}
.full-width-smart-filter.with-width {
  width: 270px;
}
.chzn-inside-edit .chzn-container {
  width: 100% !important;
}
.chzn-inside-edit .chzn-drop {
  width: 99.5% !important;
}
#certifcateFilter {
  width: 270px;
}
.select2-container input {
  height: 30px !important;
  padding: 0 8px !important;
}
.select2-container .select2-choices {
  background-image: none !important;
  border-color: #cccccc !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  overflow: hidden !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  padding: 4px 0px 0px;
  box-sizing: border-box;
}
.select2-container .select2-choices .select2-search-choice {
  background: #f8f8f8 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #c9c9c9 !important;
  background-image: none !important;
  margin-top: 0px !important;
  margin-bottom: 4px !important;
}
.select2-container .select2-choices .select2-search-choice a {
  top: 3px !important;
}
.select2-container .select2-choices .select2-search-field input {
  height: auto !important;
  margin: 0px 0px 4px;
}
.select2-container .select2-choices .select2-input:focus {
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0s !important;
  -moz-transition: all 0s !important;
  -o-transition: all 0s !important;
  transition: all 0s !important;
}
.select2-container .select2-choice {
  background-image: none;
  background: #ffffff;
  border-color: #cccccc !important;
  padding: 1px 0 0 8px !important;
}
.select2-container .select2-choice .select2-arrow {
  border-left: solid 0px !important;
  padding-left: 2px;
  background-image: none;
  background: #ffffff;
}
.select2-drop-active {
  border-color: #cccccc !important;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  border-top: solid 1px rgba(204, 204, 204, 0.8) !important;
  margin-top: -1px !important;
}
.select2-drop-active .select2-search {
  border-bottom: solid 1px #cccccc;
  background: #f8f5f5;
  padding: 3px 15px;
}
.select2-drop-active .select2-search input {
  box-shadow: none;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  height: 25px !important;
  border-color: #b8bec8;
  padding: 2px 33px;
  background-position: -33px -22px;
}
.select2-drop-active .select2-results {
  margin: 0px !important;
  padding: 0px !important;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.select2-drop-active .select2-results .select2-result {
  padding: 2px 0px;
  color: #505b65 !important;
}
.select2-drop-active .select2-results .select2-result.select2-highlighted {
  background: #dafdd7 !important;
  color: #505b65 !important;
}
#more-details-panel .allocate-to-invoices .help_section {
  padding: 20px 90px 10px 40px;
  box-sizing: border-box;
  width: 100%;
  margin-left: -20px;
}
#more-details-panel .allocate-to-invoices #page-panel-main .history-section {
  padding: 0px 80px 0px 10px;
}
#more-details-panel .allocate-to-invoices #page-panel-main .history-section #page-panel-title-bar {
  padding: 0px 20px;
  box-sizing: content-box;
  margin-left: -40px;
}
#more-details-panel .allocate-to-invoices .data-section {
  margin: 15px 0px 0px;
  width: auto !important;
}
#more-details-panel .allocate-to-invoices .search-dropdown-widget {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
#more-details-panel .allocate-to-invoices .search-dropdown-widget select {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0px;
  margin-top: 8px;
}
#more-details-panel .allocate-to-invoices .search-dropdown-widget .btn {
  float: right;
  margin: 10px 0px 10px 20px !important;
}
#more-details-panel .allocate-to-invoices .pretty-list li {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#more-details-panel .allocate-to-invoices .pretty-list li .description {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#more-details-panel .allocate-to-invoices .pretty-list li .controls {
  margin-bottom: 0px;
  margin-left: 10px;
  width: 150px;
}
#more-details-panel .allocate-to-invoices .pretty-list li .controls input {
  width: 135px;
}
#more-details-panel .allocate-to-invoices .pretty-list li .controls:not(.with-error) input {
  margin-bottom: 0px;
}
#more-details-panel .allocate-to-invoices .pretty-list li .controls.with-error {
  position: relative;
  margin-right: 12px;
  margin-left: 20px;
}
#more-details-panel .allocate-to-invoices .pretty-list li .no-results-wrap {
  width: 100%;
}
#more-details-panel .allocate-to-invoices .pretty-list li .allocation {
  width: 150px;
  display: inline-block;
}
#more-details-panel .allocate-to-invoices .pretty-list li.list-headers .controls {
  width: 150px;
}
#more-details-panel .allocate-to-invoices .summary {
  border-top: 1px solid #dadada;
  padding: 10px;
  margin-top: 20px;
    padding-top: 12px !important;
}
#more-details-panel .allocate-to-invoices .summary .field-label {
  margin-left: 25px;
}
#more-details-panel .allocate-to-invoices input.box-sizing,
#more-details-panel .allocate-to-invoices select.box-sizing {
  box-sizing: border-box;
  height: 30px;
}
#contextual-menu-container {
  height: 270px;
}
.with-contextual-menu .system_settings_sortable {
  margin-top: 26px;
}
#contextual-menu-wrapper span,
#contextual-menu-wrapper .ss-dropdown {
  float: right;
}
#contextual-menu-wrapper .ss-dropdown {
  margin: 2px 0 0 5px;
  font-size: 8px;
}
#contextual-menu-wrapper a.contextual-toggle {
  color: #505b65;
}
#contextual-menu-wrapper a.contextual-toggle.in-data-section {
  float: right;
  margin: 10px 0 0 0;
}
#contextual-menu-wrapper li a {
  float: none;
  margin: 0;
}
.contextual-menu {
  width: 200px;
  margin-top: -10px;
  right: -6px;
  left: auto !important;
}
.contextual-menu .links {
  border-top: 0;
}
.contextual-menu .title {
  margin-bottom: 0;
  line-height: 32px;
  border-bottom: 1px solid #d1d8e7 !important;
}
.contextual-menu a {
  font-size: 13px;
}
#section-actions .dropdown-toggle {
  float: right;
  margin: 7px 20px 0 0;
}
#section-actions .dropdown-menu {
  left: auto;
  right: 142px;
}
#section-actions a {
  float: none;
  margin: 0;
}
.row-fluid.with-leftnav {
  padding-left: 60px;
}
.subnav-title {
  margin: 0;
  background: #ffffff;
  border-bottom: 1px solid #d1d8e7;
  font-size: 15px;
}
.leftnav-first-tier {
  position: absolute;
  z-index: 10;
  top: 73px;
  bottom: 0;
  left: -208px;
  background: #f3f3f3;
  border-right: 1px solid #d1d9dd;
  width: 242px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.leftnav-first-tier.top-aligned {
  top: 0px;
}
.leftnav-first-tier h4 {
  padding: 8px 40px 8px 70px;
}
.leftnav-first-tier .menu-burger {
  color: rgba(167, 173, 175, 0.74);
  font-size: 11px;
  position: absolute;
  padding: 8px 9px;
  top: 0;
  right: 0px;
}
.leftnav-first-tier .menu-burger:hover {
  background: #ffffff !important;
  color: rgba(167, 173, 175, 0.84);
}
.leftnav-first-tier .section-title {
  border-top: solid 1px #f8f5f5;
  border-bottom: solid 1px #f8f5f5;
  margin: 20px 0px;
  padding: 0px 10px;
  background: #fcfcfc;
}
.leftnav-first-tier ul {
  display: none;
  list-style: none;
  margin: 0;
  padding: 6px 0 0 40px;
}
.leftnav-first-tier ul a {
  float: left;
  width: 100%;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 20px 6px 36px;
  font-size: 13px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
}
.leftnav-first-tier ul a:hover,
.leftnav-first-tier ul a.active {
  cursor: pointer;
  background: #ececec;
}
.leftnav-first-tier ul a:focus {
  outline: none;
}
.leftnav-first-tier ul a.btn {
  width: auto;
  padding: 4px 12px;
  font-size: 12px;
  margin: 0px 10px;
  line-height: 18px;
}
.leftnav-first-tier ul a.active {
  z-index: 1;
  border-color: #d3d3d3;
  width: 202px;
  color: #0077b4;
}
.leftnav-first-tier ul a.active::after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 202px;
  border-top: 16px solid rgba(0, 0, 0, 0);
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid #ececec;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.1) 3px 1px 2px);
}
.leftnav-first-tier ul a.active::before {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  left: 202px;
  border-top: 17px solid rgba(0, 0, 0, 0);
  border-bottom: 17px solid rgba(0, 0, 0, 0);
  border-left: 17px solid rgba(189, 189, 189, 0.65);
}
.leftnav-first-tier ul a.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.leftnav-first-tier ul .on-right {
  float: right;
}
.leftnav-first-tier.open {
  left: -40px;
  -webkit-box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.1);
}
.leftnav-first-tier.open ul {
  display: block;
}
.leftnav-second-tier {
    position: absolute;
    z-index: 3;
    top: 73px;
    left: -300px;
    bottom: 0;
    background: #ffffff;
    border-right: 1px solid #d1d9dd;
    width: 240px;
    padding: 0 0 0 40px;
    -webkit-box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.06);
    box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.06);
    -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.leftnav-second-tier h4 {
  padding: 8px 20px;
}
.leftnav-second-tier ul {
  list-style: none;
  margin: 0;
  padding: 6px 0 0 0;
}
.leftnav-second-tier ul a {
  float: left;
  width: 100%;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 0px 6px 28px;
  font-size: 13px;
}
.leftnav-second-tier ul a:hover {
  background: #f8f8f8;
}
.leftnav-second-tier.open {
  left: 157px;
}
.static-shelf {
  position: fixed;
  top: 113px;
  left: 0;
  z-index: 1;
  width: 270px;
  background: #ffffff;
  -webkit-box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}
.static-shelf.shelf-hidden {
  left: -300px;
}
.static-shelf.shelf-showing {
  left: 0px;
}
.static-shelf.narrow {
  width: 270px;
}
.static-shelf.more-narrow {
  width: 220px;
}
.static-shelf.on-right {
  left: auto;
  right: 0px;
  -webkit-box-shadow: -4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -4px 0px 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 0px 5px -2px rgba(0, 0, 0, 0.1);
}
.static-shelf ul {
  display: block;
  padding-left: 0;
}
.static-shelf li {
  float: left;
  width: 100%;
  margin: 4px 0;
}
.static-shelf li a {
  padding: 7px 0 6px 0;
  line-height: 29px;
}
.static-shelf li a:hover {
  cursor: pointer;
}
.static-shelf li a.active {
  width: 270px;
  z-index: 0;
}
.static-shelf li a.active::after {
  left: 270px;
  border-left-width: 21px;
  border-top-width: 21px;
  border-bottom-width: 21px;
}
.static-shelf li a.active::before {
  left: 270px;
  border-left-width: 22px;
  border-top-width: 22px;
  border-bottom-width: 22px;
}
.static-shelf li a span.left-padding {
  padding-left: 30px;
}
.static-shelf li .small-count {
  margin: 0 10px 0 31px;
}
.static-shelf li .tooltip-inner {
  padding: 0 10px;
}
.static-shelf li .label,
.static-shelf li .invoice-schedule-icon {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.static-shelf li .label.showing,
.static-shelf li .invoice-schedule-icon.showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.static-shelf li .label {
  float: right;
  margin: 6px 10px 5px 0px;
}
.static-shelf li .invoice-schedule-icon {
  position: absolute;
  right: 0;
  top: 9px;
  font-size: 16px;
  color: #d25656;
}
.reporting-nav .leftnav-first-tier ul,
.reporting-nav .leftnav-second-tier ul {
  position: absolute;
  top: 37px;
  bottom: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0px;
  right: 0px;
}
.reporting-nav .leftnav-first-tier.leftnav-second-tier ul,
.reporting-nav .leftnav-second-tier.leftnav-second-tier ul {
  left: 45px;
}
.audit-help-section {
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.audit-help-section.compensate-for-first-tier {
  margin-left: 172px;
}
.audit-help-section.compensate-for-second-tier {
  margin-left: 407px;
}
.existing-surveys .btn-primary {
  margin: 20px 0 20px 20px;
}
.existing-surveys .todo-widget {
  padding: 0 0 20px 0;
}
.existing-surveys .with_helper a {
  font-size: 14px;
  float: right;
}
.existing-surveys .existing-items li:hover {
  background: #ffffff;
}
.existing-surveys .delete-survey-link {
  float: right;
  margin: 0 0 0 20px;
}
.hiddenSaveBtn {
  visibility: hidden;
}
.daterange-container {
  position: relative;
}
.daterange-container .daterange-row {
  padding: 0 20px;
}
.daterange-container .daterange-wrapper {
  overflow: visible;
}
.daterange-wrapper {
    position: absolute;
    z-index: 2;
    top: 42px;
    left: 0;
    width: 801px;
}
.daterange-sidebar {
  float: left;
  width: 200px;
  border-right: 1px solid #e2e5eb;
  background: #ffffff;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.daterange-sidebar ul {
  margin: 0;
  list-style: none;
}
.daterange-sidebar li:last-of-type a:hover {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.daterange-sidebar li:last-of-type a.active:hover {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.daterange-sidebar a {
  float: left;
  width: 100%;
  padding: 6px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.daterange-sidebar a:hover,
.daterange-sidebar a.active {
  background: #f8f8f8;
}
.daterange-sidebar a.active {
  position: relative;
  width: 201px;
  border-color: #dadada;
}
.daterange-sidebar a.active span {
  display: none;
}
.daterange-sidebar .ss-navigateright {
  color: #bebebe;
  font-size: 9px;
  float: right;
  margin: 2px 0 0 0;
  height: 0;
}
.daterange-content {
  float: left;
  width: 600px;
  padding: 0 0 23px 0;
  margin-left: -1px;
  background: #f8f8f8;
  border-left: 1px solid #e2e5eb;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.daterange-content .close-daterange {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 11px;
  color: #3f6884;
}
.daterange-content .close-daterange:hover {
  color: #375a73;
}
.daterange-content .apply-btn {
  float: right;
  margin: 10px 0 -10px;
}
.daterange-content .default-view {
  text-align: center;
}
.daterange-content .default-view .help-arrow {
    display: inline-block;
    background: url('../images/arrow-up.png') no-repeat;
    background-size: 100%;
    width: 200px;
    height: 100px;
    margin: 50px 0 20px;
}
.daterange-content .default-view p {
  color: #505b65;
  margin: 0 0 25px 0;
}
.calendar-title {
  float: left;
  margin: 11px 0;
  color: #999999;
  font-size: 14px;
}
.dropdown .cs-chevron {
  float: right;
  margin: 12px 0 0 6px;
}
.dropdown .cs-chevron.up {
  display: none;
}
.dropdown .cs-chevron.down {
  display: block;
}
.dropdown.open .cs-chevron.up {
  display: block;
}
.dropdown.open .cs-chevron.down {
  display: none;
}
.custom-dropdown {
  padding: 0;
  margin: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  left: 0;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  overflow: auto;
}
.custom-dropdown.small {
  width: 300px;
}
.result-collection ul {
  clear: both;
  margin: 0;
  list-style: none;
}
.result-collection strong {
  float: left;
  width: 100%;
  height: 29px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0 0 11px;
  line-height: 29px;
  background: #f4f6f8;
  color: #505b65;
}
.result-collection li {
  line-height: 29px;
  padding: 0 0 0 24px;
  font-size: 14px;
  color: #505b65;
  border-bottom: 1px solid #d1d8e7;
}
.result-collection li:last-of-type {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.result-collection li:hover {
  background: #fcfcfc;
  color: #505b65;
  cursor: pointer;
}
.result-collection .highlighted-match {
  background: #feffde;
}
.result-collection i {
  font-size: 13px;
  color: #838d96;
  display: block;
}
.user-result-collection li {
  height: auto;
  line-height: inherit;
  clear: both;
  float: left;
  width: 100%;
  padding: 5px 0 5px 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.user-result-collection .name {
  display: inline-block;
  margin: 5px 0 0 0;
}
.search-dropdown-widget {
  position: relative;
  float: left;
}
.search-dropdown-widget .custom-dropdown {
  z-index: 1;
  position: absolute;
  top: 42px;
  left: -1px;
  width: 600px;
  max-height: 285px;
  overflow: auto;
  visibility: hidden;
}
.search-dropdown-widget .custom-dropdown.open {
  visibility: visible;
}
.search-dropdown-widget .result-collection strong {
  border-bottom: 1px solid #d1d8e7;
}
/* In the main app so far there are 4 types of filter bars:
	(this does not include the search bars in settings screens)

	1) only search
	2) only date filter
	2) search and quick filter
	3) search and date filter
*/
.app-filter {
  display: inline-block;
  height: 42px;
  margin-bottom: -4px;
  background: #f8f5f5;
  border-top: 1px solid #c9c9c9;
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
}
.app-filter.pretty-list-filter {
  margin-left: 10px !important;
  margin-top: 15px !important;
}
.app-filter.detached {
  border-bottom: 1px solid #c9c9c9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.app-filter.detached.above-table {
  margin-bottom: 15px;
}
.app-filter.inside-section {
  margin-left: 10px;
  margin-bottom: -5px;
}
.app-filter .ss-search {
  float: left !important;
  margin: 3px 6px 0 12px !important;
}
.app-filter select {
  margin-bottom: 0px;
  margin-top: 6px;
}
.app-filter .date_range_include {
  float: left;
  height: 100%;
  border-left: solid 1px #e2e5eb;
}
.app-filter .filter-label,
.app-filter .clear-filter {
  float: left;
  margin: 11px 6px 0 0;
  color: #999999;
}
.app-filter .switch {
  margin: 0 !important;
  height: 100%;
}
.app-filter .switch .change-view {
  padding: 12px 15px 10px 15px;
  position: relative;
}
.app-filter .switch.active {
  margin: 0;
}
.app-filter .switch.active .change-view {
  border-left: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  background-color: white;
  border-bottom: solid 1px white;
  z-index: 1;
  color: #000000;
}
.app-filter .switch.active:nth-child(1) .change-view {
  border-right: 1px solid #c9c9c9;
  border-left: none !important;
  border-top-left-radius: 5px;
}
.app-filter .switch.active:nth-last-child(1) .change-view {
  border-left: 1px solid #c9c9c9;
  border-right: none !important;
  border-top-right-radius: 5px;
}
.app-filter .search_box {
  width: 322px;
  margin: 9px 15px 0;
  border: 1px solid #b8bec8;
}
.app-filter .search_box input {
  width: 270px;
}
.app-filter .search_box.small {
  width: 190px;
}
.app-filter .search_box.small input {
  width: 150px;
}
.app-filter .search_box.large {
  width: 360px;
}
.app-filter .search_box.large input {
  width: 320px;
}
.app-filter .filter-block {
  float: left;
  border: none;
  height: 100%;
  padding: 0px 15px;
  border-left: solid 1px #e2e5eb;
  position: relative;
}
.app-filter .filter-block:first-of-type {
  border-left: none;
}
.app-filter .filter-block select {
  width: 155px;
}
.app-filter .filter-block .search_box {
  margin: 9px 0px 0px;
}
.app-filter .daterange_filter {
  margin: 0 3px 0 0px;
  padding-bottom: 0;
  width: auto;
  padding-right: 22px;
}
.app-filter .daterange_filter .input-append {
  float: left;
  width: 127px;
  margin: 6px 0 0 0;
}
.app-filter .daterange_filter .input-append select {
  width: 100%;
}
.app-filter .daterange_filter .ss-calendar,
.app-filter .daterange_filter .ss-key {
  color: #505b65;
}
.app-filter .daterange_filter .ss-calendar:hover,
.app-filter .daterange_filter .ss-key:hover {
  cursor: pointer;
}
.app-filter .daterange_filter .clear-filter .ss-delete {
  opacity: 0;
  display: inline-block !important;
  cursor: pointer;
  color: #505b65;
  margin-left: 10px;
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.app-filter .daterange_filter .clear-filter.fade-in .ss-delete {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.app-filter .daterange_filter.with-clear .filter-label {
  height: 20px;
  overflow: hidden;
  padding-right: 0px;
}
.app-filter .daterange_filter.with-clear .filter-label .date-filter-view {
  width: 84px;
  display: inline-block;
}
.app-filter .daterange_filter.with-clear .filter-label .tooltip {
  margin-top: 5px;
}
.app-filter .daterange_filter.with-clear .ss-calendar,
.app-filter .daterange_filter.with-clear .ss-delete {
  position: relative;
  top: 2px;
  padding: 0px 5px;
  cursor: help;
  color: #797979;
}
.app-filter .daterange_filter.with-preset-filter {
  padding-right: 0px;
}
.app-filter .daterange_filter.with-date-range {
  padding-right: 0px;
}
.app-filter .daterange_filter .tooltip {
  margin-top: 0px;
}
.app-filter.filter-thin {
  width: auto;
  margin-right: 10px;
}
.app-filter.with-advanced-filter .advanced-filter {
  float: right;
  display: block;
  padding: 11px 15px;
  border-left: solid 1px #e2e5eb;
}
.app-filter.select-fix select {
  width: auto;
  margin: 0;
}
.app-filter.search-with-tabs {
  float: left;
  margin-bottom: 1px;
}
.app-filter.search-with-tabs.small {
  width: auto;
}
.app-filter.search-with-tabs .search_box {
  float: left;
}
.app-filter.search-with-tabs .filter-block a.tab {
  display: inline-block;
  margin: 8px 4px;
  padding: 2px 7px 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid transparent;
  font-size: 13px;
  line-height: 20px;
}
.app-filter.search-with-tabs .filter-block a.tab:hover {
  border: 1px solid #b8bec8;
}
.app-filter.search-with-tabs .filter-block a.tab.active {
  color: #555555;
  background: #ffffff;
  border: 1px solid #b8bec8;
}
.app-filter.search-with-tabs .filter-block a.tab.active .status-circle {
  opacity: 1;
}
.app-filter.search-with-tabs .filter-block a.tab .status-circle {
  position: relative;
  top: 5px;
  margin-right: 2px;
  opacity: 0.7;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.app-filter.no-width {
  width: auto;
}
.app-filter.search-with-daterange-and-type {
  display: inline-flex;
  float: left;
  margin-bottom: 1px;
}
.app-filter.search-with-daterange-and-type .app-filter {
  float: left;
  border: none;
  margin: 0 0 0 10px;
}
.app-filter.search-with-daterange-and-type .daterange_filter {
  margin: 0 3px 0 0;
}
.app-filter.search-with-daterange-and-type select {
  font-size: 13px;
  height: 31px;
  margin: 5px 5px 0 0;
}
@media screen and (max-width: 1200px) {
  .search-with-daterange .daterange-wrapper,
  .search-with-daterange-and-type .daterange-wrapper {
    left: -349px;
  }
}
.phone-widget-wrapper {
  position: relative;
}
.phone-widget-wrapper .selected-country-image {
  float: left;
  width: 23px;
  height: 17px;
  margin: 6px 0;
}
.phone-widget-wrapper .control-error-message {
  width: 256px;
  clear: both;
}
.phone-widget-wrapper .add-link {
  display: inline-block;
  margin: 6px 0 0 20px;
}
.existing-phone-numbers img {
  float: left;
  margin: 6px 0 0 7px;
    width: 23px;
}
.existing-phone-numbers .area-code {
  float: left;
  width: 36px;
  margin: 5px 0 0 20px;
  text-align: right;
  color: #999999;
}
.existing-phone-numbers .phone-number {
  float: left;
  margin: 5px 0 0 7px;
}
.existing-phone-numbers .edit-options {
  margin: 4px 0 0 0;
}
.phone-type-wrapper a {
  color: #505b65;
}
.phone-type-wrapper .custom-dropdown {
  text-align: left;
  width: 200px;
  top: 25px;
  overflow: auto;
}
.phone-type-wrapper .number-type-toggle {
  float: right;
  margin: -5px -6px -4px 0;
  padding: 0 6px;
  height: 30px;
  line-height: 31px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding-right: 22px;
  position: relative;
}
.phone-type-wrapper .number-type-toggle .cs-chevron {
  position: absolute;
  top: 1px;
  right: 5px;
}
.phone-type-wrapper.open .number-type-toggle {
  background: #ffffff;
  border: 1px solid #c9c9c9;
  border-bottom-color: transparent;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
}
.phone-number-wrapper {
  float: left;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}
.phone-number-wrapper + * {
  clear: both;
}
.phone-number-wrapper .dropdown-toggle {
  float: left;
  height: 30px;
  padding: 0 6px;
  background: #ffffff;
  border-right: 1px solid #cccccc;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
}
.phone-number-wrapper .cs-chevron {
  float: right;
  margin: 12px 0 0 5px;
}
.phone-number-wrapper .selected-area-code {
  float: left;
  line-height: 30px;
  height: 30px;
  padding: 0 0 0 6px;
  color: #999999;
  background: #ffffff;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
}
.phone-number-wrapper .phone-number-input {
  width: 150px;
  border: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.075) inset;
}
.phone-number-wrapper .phone-number-input:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.phone-number-wrapper .custom-dropdown {
  left: -1px;
  top: 29px;
  height: 196px;
  overflow: autol;
}
.phone-number-wrapper .result-collection img {
  float: left;
  display: inline-block;
  width: 20px;
  height: 17px;
  margin-top: 6px;
  margin-left: -5px;
}
.phone-number-wrapper .result-collection .country-name {
  width: 175px;
  display: inline-block;
  margin-left: 8px;
}
.phone-number-wrapper .result-collection .area-code {
  float: right;
  padding: 0 24px 0 0;
  color: #999999;
}
.phone-number-wrapper.open .dropdown-toggle {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.phone-number-wrapper.focussed {
  border-color: rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
}
.cs-chevron {
  display: inline-block;
  width: 9px;
  height: 9px;
}
.cs-chevron.up {
  background: url('../images/chevron-up-small.png') no-repeat;
}
.cs-chevron.down {
  background: url('../images/chevron-down-small.png') no-repeat;
}
@media screen and (max-width: 1070px) {
  .phone-widget-wrapper .control-error-message {
    width: 247px;
  }
  .phone-widget-wrapper .add-link {
    margin-left: 10px;
  }
  .phone-number-wrapper #phone-number-input {
    width: 141px !important;
  }
}
#page-overlay,
#smart-panel-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.75s;
  -moz-transition: all 0.75s;
  -o-transition: all 0.75s;
  transition: all 0.75s;
}
#page-overlay.in-view,
#smart-panel-overlay.in-view {
  opacity: 1;
}
#page-overlay.darker,
#smart-panel-overlay.darker {
  background: rgba(0, 0, 0, 0.8);
}
body.in-fullscreen-mode #page-overlay {
  transition-delay: 0.35s;
  -webkit-transition-delay: 0.35s;
  right: 700px;
}
body.in-fullscreen-mode .modal-backdrop {
  opacity: 0;
  z-index: 0;
}
#page-panel-title-bar {
  float: left;
  width: 100% !important;
  border-bottom: 1px solid #dadada;
  background: #ffffff;
}
#page-panel-title-bar h4 {
  margin: 0;
  color: #505b65;
  font-size: 18px;
  padding: 10px 0 10px 20px;
  float: left;
}
#page-panel-title-bar h4 .ss-navigateright,
#page-panel-title-bar h4 .ss-navigatedown {
  float: left;
  margin-right: 10px;
  color: #3883c1;
}
#page-panel-title-bar h4 .ss-navigateright:hover,
#page-panel-title-bar h4 .ss-navigatedown:hover {
  color: #265882;
}
#page-panel-title-bar h4.pr3 {
  padding-right: 1rem;
}
#page-panel-title-bar.no-wrap h4 {
  text-overflow: ellipis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}
#page-panel-title-bar.no-top-margin {
  margin-top: 0px;
}
#page-panel-title-bar .smart-table-sidepanel a,
#page-panel-title-bar .notifications-panel a,
#page-panel-title-bar .reminder-panel a {
  float: right;
  margin: 10px 20px 0 0;
}
#page-panel-title-bar .help-block {
  position: relative;
  top: 10px;
  left: 10px;
  font-style: italic;
}
#breadcrumb-title-bar {
  padding: 10px 0 9px 20px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #dadada;
  background: #ffffff;
  min-height: 41px;
  box-sizing: border-box;
}
#breadcrumb-title-bar .ss-navigateright {
  margin: 0 4px 0 6px;
  font-size: 12px;
  color: #bdc5cb;
}
#page-panel-main {
  position: absolute;
  top: 41px;
  left: 20px;
  right: 85px;
  bottom: 69px;
  overflow: auto;
  padding-bottom: 20px;
}
#page-panel-main #page-panel-title-bar {
  margin: 15px 0;
  border-top: 1px solid #dadada;
}
#page-panel-main #page-panel-title-bar .download_options {
  float: right;
  margin: 11px 87px 0 0;
}
#page-panel-main object,
#page-panel-main iframe {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
#page-panel-main .pdf-wrapper {
  position: relative;
  height: 100%;
}
#page-panel-main .pdf-wrapper.flex {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#page-panel-main .help_section {
  margin: 0;
  padding: 20px 90px 10px 30px;
}
#page-panel-main .history-section {
  padding: 15px 0 0 0;
}
#page-panel-main .history-section.no-top-padding {
  padding-top: 0px;
  margin-top: -1px;
}
#page-panel-main .history-section.without-top-padding {
  padding-top: 0px;
}
#page-panel-main .history-section.with-right-padding {
  padding-right: 45px;
}
#page-panel-main .history-section .checkboxes-with-disabled input {
  margin-left: 8px;
  margin-right: 10px;
  top: -4px;
}
#page-panel-main .data-section {
  margin: 15px 20px;
}
#page-panel-main table td,
#page-panel-main table th {
  white-space: normal;
}
#page-panel-main.with-tabs {
  margin: 10px 0px;
}
#page-panel-main.with-tabs .app-filter {
  margin: 15px 0px 0px 15px;
  position: relative;
  top: 5px;
}
#page-panel-main.with-tabs .data-section {
  margin: 0px;
  padding: 20px;
}
#page-panel-main .download-options .ss-download {
  color: inherit;
  padding: 0px 3px 0px 0px;
  font-size: 0.8em;
}
#page-panel-main.make-phone-call {
  margin-bottom: 0px;
  padding: 0;
  top: 41px;
  overflow-x: hidden;
}
#page-panel-main table {
  width: 662px;
  margin: 0 0 0 10px !important;
}
#page-panel-main table .table-download span {
  cursor: pointer;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
  height: 16px;
  line-height: 16px;
  margin-bottom: -20px;
  display: inline-block;
  padding: 1px 8px;
  text-shadow: none;
  font-weight: 100;
  font-size: 11px;
  background: #7eb419;
  color: #ffffff;
}
#page-panel-main table .table-download span .ss-download {
  color: inherit;
  padding: 0px 3px 0px 0px;
  font-size: 0.8em;
}
#page-panel-main .custom-datepicker table {
  width: 254px;
  margin: 0px !important;
}
.page-panel-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #dadada;
  background: #ffffff;
  padding: 20px;
}
.page-panel-actions a,
.page-panel-actions .btn {
  font-size: 14px !important;
}
.page-panel-actions.buttons-fixed-bottom {
  right: 70px;
}
.page-panel-actions.buttons-fixed-bottom .btn {
  float: right;
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.page-panel-actions.buttons-fixed-bottom .btn.without-top-margin {
  margin-top: 0;
}
.page-panel-actions.buttons-fixed-bottom span {
  position: relative;
  top: 4px;
}
.page-panel-actions.buttons-fixed-bottom span a {
  color: #999999;
}
.page-panel-actions.buttons-fixed-bottom #message {
  position: relative;
  margin-left: 10px;
  float: none;
  color: #46a546;
}
.page-panel-actions.buttons-fixed-bottom #message span,
.page-panel-actions.buttons-fixed-bottom #message strong {
  float: none;
  color: #46a546;
  top: 0px;
}
.page-panel-actions.buttons-fixed-bottom #message .ss-alert {
  position: relative;
  top: 2px;
}
.page-panel-actions.buttons-right * {
  float: right;
}
.page-panel-actions.buttons-right .copy-label {
  float: left;
  margin-left: 20px;
}
.page-panel-actions.buttons-right .copy-label input {
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.page-panel-actions.buttons-right .btn span {
  top: 0px;
  margin-bottom: 0px !important;
}
.page-panel-actions .button-spacer {
  padding-left: 10px;
  border-left: solid 1px #dadada;
  height: 68px;
  margin: -20px 10px -20px 10px;
  display: none;
}
.page-panel-actions.no-border {
  border-top: none;
}
.page-panel-actions a,
.page-panel-actions button.btn {
  float: right;
  margin: 0 20px 0 0;
}
.page-panel-actions button.btn:first-of-type {
    /*margin: 0;*/
}

.page-panel-actions a#cancel-panel-btn,
.page-panel-actions button.btn#cancel-panel-btn {
  margin-top: 4px;
  color: #999999;
}
.inline-add-link {
  margin: 0 0 0 10px;
}
.inline-add-link.fix {
  position: relative;
  top: -3px;
}
.panel-info-block {
  float: left;
  margin: 0;
  list-style: none;
}
.panel-info-block li {
  float: left;
  padding: 10px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.7);
}
.panel-info-block li:last-of-type {
  border: none;
}
.panel-info-block .panel-info-block-title {
  float: left;
  width: 100%;
}
.panel-info-block .panel-info-block-title img {
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.panel-info-block .panel-info-block-title strong {
  float: left;
  clear: none;
  margin: 1px 10px;
  padding: 0;
  color: #505b65;
  font-size: 14px;
}
.panel-info-block .panel-info-block-title.no-float strong {
  float: none;
  margin: 0;
}
.panel-info-block .panel-info-block-title .label,
.panel-info-block .panel-info-block-title .date {
  float: right;
}
.panel-info-block .panel-info-block-title .date {
  margin: 0 100px 0 0;
}
.panel-info-block .panel-info-block-content {
  float: right;
  color: #333333;
  font-size: 14px;
}
.notes-section {
  position: absolute;
  top: 63px;
  bottom: 20px;
  left: 30px;
  right: 30px;
}
.notes-section.in-jobs {
  bottom: 150px;
  padding-top: 0px;
}
.notes-section.below-information-bar {
  top: 116px;
}
.timeline-section {
  padding-bottom: 0;
}
.timeline {
  position: absolute;
  top: 41px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  overflow: overlay;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.timeline .label {
    position: relative;
    margin-left: 4px;
}
.timeline .label:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #677077;
    position: absolute;
    left: -4px;
    top: 3px;
}

.timeline .label,
.email-status .label{
    color: #fefefe !important;
    background-color: #677077;
    position:relative;
}
.email-status .label:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #677077;
    position: absolute;
    top: -4px;
}

.timeline .label.label-open,
.email-status .label.label-open{
    background-color: #65A16B;
}
.timeline .label.label-click,
.email-status .label.label-click{
    background-color: #a0647b;
}
.timeline .label.label-delivered,
.email-status .label.label-delivered{
    background-color: #d78430;
}
.email-status .label-open.label:before {
    border-bottom-color: #65A16B;
}
.email-status .label-delivered.label:before {
    border-bottom-color: #d78430;
}
.email-status .label-click.label:before {
    border-bottom-color: #a0647b;
}

.timeline .label.label-spamreport,
.timeline .label.label-bounce,
.timeline .label.label-dropped,
.timeline .label.label-deferred,
.timeline .label.label-unsubscribe,
.email-list .label.label-spamreport,
.email-list .label.label-bounce,
.email-list .label.label-unsubscribe,
.email-list .label.label-deferred,
.email-list .label.label-dropped{
    background-color: #B65959;
}

.email-list .label-spamreport.label:before,
.email-list .label-unsubscribe.label:before,
.email-list .label-deferred.label:before,
.email-list .label-bounce.label:before,
.email-list .label-dropped.label:before{
    border-bottom-color: #B65959;
}
.timeline .label-open.label:before {
    border-right-color: #65A16B;
}
.timeline .label-delivered.label:before {
    border-right-color: #d78430;
}
.timeline .label-click.label:before {
    border-right-color: #a0647b;
}
.timeline .label-spamreport.label:before,
.timeline .label-unsubscribe.label:before,
.timeline .label-deferred.label:before,
.timeline .label-bounce.label:before,
.timeline .label-dropped.label:before
{
    border-right-color: #B65959;
    border-bottom-color: transparent;
}

.timeline .line {
    position: fixed;
    top: 219px;
/*
    left: 50%;
*/
    left: 70px;
    margin-left: -2px;
    width: 2px;
    background: #677077;
}
.timeline .date {
  text-align: center;
}
.timeline .date span {
  display: block;
}
.timeline .item-icon {
  position: relative;
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 2px solid #677077;
  text-align: center;
  margin-bottom: -38px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
}
.timeline .item-icon span {
  display: inline-block;
  margin: 9px 0 0 0;
  width: 16px;
  font-size: 16px;
  color: #838d96;
}
.timeline .date-wrap {
/*
    text-align: center;
*/
    margin-left: 30px !important;
    padding: 30px 0;
    text-align: left;
    position: relative;
}
.timeline .date-wrap .date {
  display: inline-block;
  position: relative;
  padding: 4px 12px;
  background: #677077;
  color: #fefefe;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 13px;
  letter-spacing: 1px;
}
.timeline .date-wrap hr {
  margin: 0;
  position: absolute;
  top: 45px;
  width: auto;
  border-top: 1px dashed #cecfcf;
}
.timeline .item {
    clear: both;
/*
    width: 50%;
*/
    width: 100%;
    position: relative;
    font-size: 13px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.timeline .item:last-of-type {
  padding-bottom: 30px;
}

.timeline .item {
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.timeline .item .item-icon {
/*
    float: right;
*/
   float: left;
}

.timeline .item .item-icon {
    margin-right: -18px;
}

.timeline .item .item-inner {
/*
    margin-right: 36px;
*/
    margin-left: 60px;
    margin-bottom: 20px;
}

.timeline .item .item-inner:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 12px;
/*
    right: 28px;
*/
    left: 52px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
/*
    border-left: 9px solid #fcfcfc;
*/
    border-right: 9px solid #fcfcfc;
}

.timeline .item .item-inner:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 11px;
/*
    right: 27px;
*/
    left: 51px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
/*
    border-left: 10px solid #cac9c9;
*/
    border-right: 10px solid #cac9c9;
}

/*
.timeline .item:nth-of-type(odd) {
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.timeline .item:nth-of-type(odd) .item-icon {
  float: right;
}
.timeline .item:nth-of-type(odd) .item-icon {
  margin-right: -18px;
}
.timeline .item:nth-of-type(odd) .item-inner {
  margin-right: 36px;
}
.timeline .item:nth-of-type(odd) .item-inner:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 12px;
  right: 28px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid #fcfcfc;
}
.timeline .item:nth-of-type(odd) .item-inner:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 11px;
  right: 27px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #cac9c9;
}
*/
/*
>>>>>>> .merge-right.r24534
.timeline .item:nth-of-type(even) {
  right: -50%;
}
.timeline .item:nth-of-type(even) .item-icon {
  float: left;
}
.timeline .item:nth-of-type(even) .item-icon {
  margin-left: -20px;
}
.timeline .item:nth-of-type(even) .item-inner {
  margin-left: 36px;
}
.timeline .item:nth-of-type(even) .item-inner::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 12px;
  left: 28px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #fcfcfc;
}
.timeline .item:nth-of-type(even) .item-inner::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 11px;
  left: 27px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #cac9c9;
}
*/

.timeline .item-inner {
  background: #fcfcfc;
  border: 1px solid #dbdbdb;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.timeline .item-inner .title-bar {
  padding: 10px 0;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.timeline .item-inner .title-bar strong {
  color: #677077;
  flex: 0 0 auto;
  margin-right: 5px;
}
.timeline .item-inner .title-bar span {
  color: #838d96;
}
.timeline .item-inner .title-bar .email-list {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.timeline .item-inner .title-bar .email-list a:first-of-type {
  margin-right: 0px !important;
}
.timeline .item-inner .title-bar .left-side {
  margin: 0 0 0 6px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.timeline .item-inner .title-bar .left-side a:first-of-type {
  flex: 0 0 auto;
  margin-right: 10px;
}
.timeline .item-inner .title-bar .right-side {
  margin: 0 6px 0 0;
  white-space: nowrap;
}
.timeline .item-inner .title-bar .right-side .icon {
  float: left;
  margin-right: 8px;
}
.timeline .item-inner .title-bar .title-icon {
  float: left;
  margin: 2px 4px -2px 0;
  font-size: 15px;
}
.timeline .item-inner .title-bar .title-icon.error {
  color: rgba(240, 61, 37, 0.9);
}
.timeline .item-inner .title-bar .title-icon.success {
  color: #7eb419;
}
.timeline .item-inner .content {
  clear: both;
  overflow: hidden;
  min-height: inherit;
  padding: 10px;
  margin: 0 6px 6px;
  border: 1px solid #efefef;
  background: #ffffff;
}
.timeline .item-inner .content p:last-of-type {
  margin: 0;
}
.timeline .item-inner .content:last-of-type {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.timeline .item-inner .text-shadow {
  float: left;
  width: 100%;
  position: relative;
  height: 11px;
  margin-bottom: -11px;
  box-shadow: 0px -5px 11px #ffffff;
}
.item-email {
  min-height: 200px;
}
.item-email .subject strong {
  float: left;
  margin: 0 10px 0 0;
}
.item-email .subject p {
  display: block;
  overflow: hidden;
}
.item-email .email-content {
  min-height: 80px;
  max-height: 176px;
  float: left;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  margin: 7px 0 0 0;
  padding: 5px 0 0 0;
  border-top: 1px solid #eeeeee;
}
.item-email .email-content p {
  padding: 0 20px 0 0;
}
.item-email .email-content p:last-of-type {
  padding-bottom: 10px;
}
.item-email .attachments {
  padding: 0 0 6px 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  top: -3px;
}
.item-email .attachments span {
  margin: 6px 0 0 6px;
  display: inline-block;
  color: #838d96;
  padding: 3px 10px;
  border: solid 1px #c9c9c9;
  background: #ffffff;
}
.item-email .attachments span:first-of-type {
  border: 0px;
  background: transparent;
}
.item-note .actions {
  float: right;
}
.item-note .actions a:last-of-type {
  margin: 0 0 0 10px;
}
.item-note .note-content {
  max-height: 200px;
  min-height: 80px;
  overflow: auto;
  float: left;
  box-sizing: border-box;
  width: 100%;
  margin: 7px 0 0 0;
  padding: 5px 0 0 0;
  border-top: 1px solid #eeeeee;
}
.item-phone .duration {
  float: left;
  margin: 7px 0 0 0;
}
.item-phone .play-icon {
  float: right;
}
.important-status {
  float: left;
  width: 10px;
  height: 10px;
  margin: 4px 10px 0 0;
  border-radius: 5px;
  background: rgba(240, 61, 37, 0.9);
}
#quick-add-panel,
#side-panel.add-new-diary-event {
  padding: 0 50px 0 0;
  margin-right: -50px;
  background: #f9f9f9;
  position: fixed;
  z-index: 1001;
  top: 0px;
  right: -550px;
  bottom: 0;
  width: 500px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#quick-add-panel.in-view,
#side-panel.add-new-diary-event.in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
#quick-add-panel #page-panel-title-bar a,
#side-panel.add-new-diary-event #page-panel-title-bar a {
  float: right;
  margin: 10px;
}
#quick-add-panel form,
#side-panel.add-new-diary-event form {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#quick-add-panel form .panel-scrollable-inner,
#side-panel.add-new-diary-event form .panel-scrollable-inner {
  position: absolute;
  top: 60px;
  left: 10px;
  right: 60px;
  bottom: 90px;
  overflow: auto;
  padding: 0 10px;
  border: 1px solid transparent;
  transition: top 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow-x: hidden;
}
#quick-add-panel form .block-label,
#side-panel.add-new-diary-event form .block-label {
  margin: 0 0 4px;
  clear: both;
}
#quick-add-panel form .block-label span,
#side-panel.add-new-diary-event form .block-label span {
  display: inline-block;
  margin: 0 0 4px;
}
#quick-add-panel form input:not(.ng-invalid-required.ng-dirty),
#side-panel.add-new-diary-event form input:not(.ng-invalid-required.ng-dirty) {
  margin-bottom: 9px;
}
#quick-add-panel form .mce-tinymce .mce-edit-area,
#side-panel.add-new-diary-event form .mce-tinymce .mce-edit-area {
  margin-bottom: 0px;
}
#quick-add-panel form .select2-container,
#side-panel.add-new-diary-event form .select2-container {
  margin-bottom: 9px;
}
#quick-add-panel form textarea,
#side-panel.add-new-diary-event form textarea {
  height: 200px;
}
#quick-add-panel form textarea.short,
#side-panel.add-new-diary-event form textarea.short {
  height: 100px;
}
#quick-add-panel form textarea.tall,
#side-panel.add-new-diary-event form textarea.tall {
  height: 250px;
}
#quick-add-panel form .input-append,
#side-panel.add-new-diary-event form .input-append {
    margin-bottom: 0px;
}
#quick-add-panel form .required_field,
#side-panel.add-new-diary-event form .required_field {
  display: inline-block;
  margin: 0 0 4px;
}
#quick-add-panel form .checkbox-collection .checkboxes,
#side-panel.add-new-diary-event form .checkbox-collection .checkboxes {
  display: block;
  margin: 10px 15px;
}
#quick-add-panel form .checkbox-collection .checkboxes input[type="checkbox"],
#side-panel.add-new-diary-event form .checkbox-collection .checkboxes input[type="checkbox"] {
  margin: -2px 6px 0 0;
}
#quick-add-panel form .custom-datepicker,
#side-panel.add-new-diary-event form .custom-datepicker {
  left: 0;
}
#quick-add-panel form .select-with-adjacent-link select,
#side-panel.add-new-diary-event form .select-with-adjacent-link select,
#quick-add-panel form .select-with-adjacent-link input,
#side-panel.add-new-diary-event form .select-with-adjacent-link input {
  margin-bottom: 9px;
}
#quick-add-panel form .select-with-adjacent-link select,
#side-panel.add-new-diary-event form .select-with-adjacent-link select,
#quick-add-panel form .select-with-adjacent-link a,
#side-panel.add-new-diary-event form .select-with-adjacent-link a,
#quick-add-panel form .select-with-adjacent-link .adjacent-text,
#side-panel.add-new-diary-event form .select-with-adjacent-link .adjacent-text {
  float: left;
}
#quick-add-panel form .select-with-adjacent-link a,
#side-panel.add-new-diary-event form .select-with-adjacent-link a,
#quick-add-panel form .select-with-adjacent-link .adjacent-text,
#side-panel.add-new-diary-event form .select-with-adjacent-link .adjacent-text {
  margin: 4px 0 9px 15px;
}
#quick-add-panel form .select-with-adjacent-link .adjacent-text,
#side-panel.add-new-diary-event form .select-with-adjacent-link .adjacent-text {
  color: #7f8d94;
}
#quick-add-panel form .select-with-adjacent-link + div,
#side-panel.add-new-diary-event form .select-with-adjacent-link + div {
  float: left;
  clear: both;
}
#quick-add-panel .page-panel-actions,
#side-panel.add-new-diary-event .page-panel-actions {
  right: 0;
  padding-right: 50px;
}
#quick-add-panel span.add-on,
#side-panel.add-new-diary-event span.add-on {
  cursor: pointer;
  padding: 4px 10px;
}
#quick-add-panel button.add-on,
#side-panel.add-new-diary-event button.add-on {
  height: 30px;
  width: 38px;
  -webkit-transition: all linear 0.2s;
  -moz-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
}
#quick-add-panel button.add-on:disabled,
#side-panel.add-new-diary-event button.add-on:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
#quick-add-panel button.add-on:focus,
#side-panel.add-new-diary-event button.add-on:focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  border-color: rgba(82, 168, 236, 0.8);
}
#quick-add-panel button.add-on span,
#side-panel.add-new-diary-event button.add-on span {
  margin: 2px 0 0 6px;
}
#quick-add-panel .helper-with-icon,
#side-panel.add-new-diary-event .helper-with-icon,
#quick-add-panel .panel-loading,
#side-panel.add-new-diary-event .panel-loading {
  margin: 200px 0 0 0;
}
#quick-add-panel .helper-with-icon p,
#side-panel.add-new-diary-event .helper-with-icon p,
#quick-add-panel .panel-loading p,
#side-panel.add-new-diary-event .panel-loading p {
  margin: 25px 10px 10px 10px;
  color: #7e8d93;
}
#quick-add-panel form label [type="checkbox"] {
  margin: -2px 6px 0 0;
}
#file-preview-panel {
  padding: 0 4.5% 0 0;
  margin-right: -4.5%;
  background: #f9f9f9;
  position: fixed;
  z-index: 1001;
  top: 0px;
  right: -49.5%;
  bottom: 0;
  width: 45%;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#file-preview-panel.in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
#file-preview-panel #page-panel-title-bar {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
#file-preview-panel #page-panel-title-bar i {
  float: left;
  color: #72818f;
  font-size: 14px;
  margin: 10px 0 0 0;
  display: inline-block;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#file-preview-panel #page-panel-title-bar .right-side {
  float: right;
  margin: 0 20px 0 0;
}
#file-preview-panel #page-panel-title-bar .right-side .ss-download {
  margin-top: 10px;
}
#file-preview-panel #page-panel-title-bar strong {
  display: inline-block;
  margin: 10px 0 0 0;
  color: #677077;
}
#file-preview-panel #page-panel-title-bar a {
  font-size: 16px;
  color: #797979;
  float: right;
  margin: 11px 0 0 20px;
}
#file-preview-panel #page-panel-main {
  padding-bottom: 0px;
  top: 51px;
  bottom: 10px;
  right: 95px;
}
#page-panel-title-bar i {
  float: left;
  color: #72818f;
  font-size: 14px;
  margin: 10px 0 0 0;
}
#page-panel-title-bar .right-side {
  float: right;
  margin: 0 20px 0 0;
}
#page-panel-title-bar .right-side .normal-link {
  margin-top: 9px;
}
#page-panel-title-bar strong {
  display: inline-block;
  margin: 10px 0 0 0;
  color: #677077;
}
#page-panel-title-bar a {
  font-size: 16px;
  color: #797979;
  float: right;
  margin: 2px 0 0 10px;
}
#page-panel-title-bar .normal-link,
#page-panel-title-bar .breadcrumb-link {
  color: #3883c1;
}
#page-panel-title-bar .normal-link:hover,
#page-panel-title-bar .breadcrumb-link:hover {
  color: #265882;
}
#page-panel-title-bar .normal-link.normal-link,
#page-panel-title-bar .breadcrumb-link.normal-link {
  font-size: 14px;
}
.right-side .ss-download {
  margin-top: 10px !important;
}
#drop-area {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  pointer-events: none;
}
#drop-area.in-view {
  z-index: 4000;
  opacity: 1;
}
#drop-message {
  position: absolute;
  z-index: 4001;
  width: 340px;
  height: 340px;
  left: 50%;
  top: 50%;
  margin: -170px 0 0 -170px;
  background: #60a369;
  -webkit-border-radius: 170px;
  -moz-border-radius: 170px;
  border-radius: 170px;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
#drop-message.error-view {
  background: rgba(240, 61, 37, 0.9);
}
#drop-message .drop-message {
  height: 100%;
}
#drop-message .drop-icons {
  position: relative;
  top: 50%;
  margin-top: -40px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 80px;
  line-height: 80px;
}
#drop-message .drop-icons .left,
#drop-message .drop-icons .middle,
#drop-message .drop-icons .right {
  height: 80px;
  width: 80px;
  transform-origin: top center;
}
#drop-message .drop-icons span {
  opacity: 0;
  position: absolute;
}
#drop-message .drop-icons.success {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#drop-message .drop-icons.success.active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#drop-message .left {
  left: 50%;
  margin-left: -80px;
  top: 0px;
  transition: all 1s;
  transition-delay: .1s;
  transition-duration: .3s;
  transform: rotateZ(38deg);
  -webkit-transform: rotateZ(38deg);
}
#drop-message .middle {
  left: 50%;
  margin-left: -40px;
  top: 0px;
  color: #ffffff;
  transition-duration: .2s;
}
#drop-message .right {
  left: 50%;
  transition: all 1s;
  transition-delay: .1s;
  transition-duration: .3s;
  transform: rotateZ(-38deg);
  -webkit-transform: rotateZ(-38deg);
}
#drop-message .message {
  color: #ffffff;
  float: left;
  margin-top: 80px;
  text-align: center;
  width: 100%;
  position: relative;
  top: 50%;
}
#drop-message.in-view {
  opacity: 1;
  -webkit-animation: circle 0.4s ease-out;
}
#drop-message.in-view span {
  opacity: 1;
}
#drop-message.in-view .drop-icons .left,
#drop-message.in-view .drop-icons .right {
  top: 0px;
  transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
}
#drop-message.in-view .drop-icons.in-active .left {
  -webkit-transform: rotateZ(38deg);
}
#drop-message.in-view .drop-icons.in-active .right {
  -webkit-transform: rotateZ(-38deg);
}
#drop-area.in-view {
  border: 2px dashed #366dbd;
}
.drop-helper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 3500;
  pointer-events: none;
  overflow: hidden;
}
.drop-helper.small #drop-message {
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  -webkit-border-radius: 85px;
  -moz-border-radius: 85px;
  border-radius: 85px;
}
.drop-helper.small #drop-message .drop-icons {
  margin-top: -20px;
  font-size: 40px;
  line-height: 40px;
}
.drop-helper.small #drop-message .drop-icons .left,
.drop-helper.small #drop-message .drop-icons .middle,
.drop-helper.small #drop-message .drop-icons .right {
  height: 40px;
  width: 40px;
}
.drop-helper.small #drop-message .left {
  margin-left: -40px;
}
.drop-helper.small #drop-message .middle {
  margin-left: -20px;
}
.drop-helper.small #drop-message .message {
  margin-top: 40px;
}
.smart-table-fix-right-wrapper {
  position: relative;
}
.smart-table-fix-right-wrapper.with-top-margin {
  margin-top: 15px;
}
.smart-table-relative-wrapper {
  position: relative;
}
.smart-table-container {
  position: relative;
  overflow: auto;
  padding-top: 4px;
  margin-top: -4px;
}
.smart-table-container.fix-right {
  margin-right: 37px;
  position: static;
}
.smart-table-container.fix-right.no-actions-col {
    margin-right: 0px;
}

.smart-table-container.fix-right.fix-left {
  margin-left: 24px;
}
.smart-table-container.fix-right.fix-left-for-draggable-rows {
  margin-left: 9px;
}
.smart-table-container.fix-right.fix-left-for-draggable-rows #select-all-link {
  left: 16px !important;
}
.smart-table-container.without-top-padding {
  padding-top: 0px;
}
.smart-table-container.with-groups table tbody tr {
  background: #fbfbfb;
  border-bottom: 0px;
}
.smart-table-container.with-groups table tbody tr td {
  border-bottom: 1px solid #dddddd;
}
.smart-table-container.with-groups table tbody tr td:nth-of-type(3) {
  font-weight: bold;
  border-bottom: 0px;
}
.smart-table-container.with-groups table tbody tr td.with-bottom-border {
  border-bottom: 1px solid #dddddd;
}
.smart-table-container.with-groups table tbody tr.grouped-row-is-odd {
  background: #ffffff;
}
.smart-table-container.with-groups table tbody tr:hover {
  background: #fff6d9;
}
.smart-table-container.with-groups table tbody tr:hover .actions-toggle {
  background-color: #ffffff;
}
.smart-table-container #smart-table-wrapper {
  margin: 0 0 0 24px;
}
.smart-table-container.no-actions-col #smart-table-wrapper {
    margin-left: 0;
}
.smart-table-container.no-actions-col.st-with_configurable_columns #smart-table-wrapper {
    margin-left: 24px;
}

.smart-table-container #smart-table-wrapper .smart-actions-head {
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  padding: 0px;
  visibility: hidden;
}
.smart-table-container #smart-table-wrapper span.fixed-size {
  width: 270px;
  display: inline-block;
}
.smart-table-container #smart-table-wrapper th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.smart-table-container #smart-table-wrapper th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.smart-table-container #smart-table-wrapper.without-selectable-rows table {
  margin: 0;
}
.smart-table-container #smart-table-wrapper.without-selectable-rows.with-smart-actions table {
  margin: 0;
}
.smart-table-container #smart-table-wrapper.fix-right {
  margin: 0;
}
.smart-table-container #smart-table-wrapper.with-draggable-rows th:nth-child(2) {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.smart-table-container #smart-table-wrapper.with-smart-actions th:nth-last-child(2) {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.smart-table-container table {
  margin: 24px 0 0 0;
}
.smart-table-container table td,
.smart-table-container table th {
  white-space: nowrap;
}
.smart-table-container table .empty-checkbox-header {
  width: 10px;
}
.smart-table-container table .checkbox-cell {
  background: #ffffff;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.smart-table-container table .checkbox-cell input {
  float: left;
  margin: 0;
}
.smart-table-container table .checkbox-cell:hover {
  cursor: pointer;
}
.smart-table-container table .checkbox-cell.without-bottom-border {
  border-bottom: solid 1px white;
}
.smart-table-container table .checkbox-cell.without-left-border {
  border-left: solid 1px white;
}
.smart-table-container table .more-details-cell {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.smart-table-container table .more-details-cell .ss-view {
  float: left;
  margin: -8px 0 -10px 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.smart-table-container table tr.primary-row-has-associated-rows td {
  font-weight: bold;
}
.smart-table-container table tr.associated-row td {
  background: #f6f6f6;
}
.smart-table-container table tr.associated-row td.top-left {
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.08) inset;
}
.smart-table-container table tr.associated-row td.with-left-shadow {
    background-image: url("../images/null.png");
    background-repeat: repeat-y;
}
.smart-table-container table tr.associated-row td.with-top-shadow {
    background-image: url("../images/null.png");
    background-repeat: repeat-x;
}
.smart-table-container table tr.associated-row td.checkbox-cell,
.smart-table-container table tr.associated-row td.actions-dropdown {
  background-color: #ffffff;
}
.smart-table-container table tr.row-action-buttons-active {
  background-color: #d8e8f4 !important;
}
.smart-table-container table tr.row-action-buttons-active td {
  background-color: #d8e8f4 !important;
}
.smart-table-container table .drag-handle {
  position: absolute;
  width: 10px;
  left: -10px;
  top: auto;
  border: solid 1px #ddd;
  background-color: #fbfbfb !important;
  height: 20px;
  margin-top: -1px;
  overflow: hidden;
  padding-left: 3px;
  padding-right: 6px;
  border-right: solid 1px  #ddd;
}
.smart-table-container table .drag-handle .ss-grid {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 8px;
  font-size: 11px;
  width: 8px;
  overflow: hidden;
  height: 8px;
  margin-left: 3px;
  color: #888;
}
.smart-table-container table .drag-handle .ss-grid:first-of-type {
  margin-top: 2px;
}
.smart-table-container table .drag-handle.on-dragging {
  opacity: 0;
}
.smart-table-container table .drag-handle.hidden-drag-handle {
  visibility: hidden;
}
.smart-table-container table .drag-handle.hidden-drag-handle * {
  opacity: 0;
}
.smart-table-container table .drag-handle.without-top-border {
  border-top: solid 1px #ffffff;
}
.smart-table-container table .actions-dropdown {
  position: relative;
}
.smart-table-container table .actions-dropdown h3 {
  margin: 0px;
  font-size: 10px;
}
.smart-table-container table .actions-dropdown.fix-right {
  position: absolute;
  width: 16px;
  right: 0px;
  top: auto;
  border: solid 1px #ddd;
  height: 20px;
  margin-top: -1px;
  overflow: hidden;
  -webkit-transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out, padding-right 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out, padding-right 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out, padding-right 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out, padding-right 0.3s ease-in-out;
}
.smart-table-container table .actions-dropdown.fix-right .actions-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 30px;
  padding-top: 10px;
  width: 36px;
  text-align: center;
  font-size: 14px;
}
.smart-table-container table .actions-dropdown.fix-right .actions-toggle:hover {
  cursor: pointer;
}
.smart-table-container table .actions-dropdown.fix-right.active {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 3px #4d7fa2;
  padding-right: 45px;
  width: auto;
  z-index: 100;
}
.smart-table-container table .actions-dropdown.fix-right.active span.actions-toggle {
  background-color: #ffffff;
}
.smart-table-container table .actions-dropdown .actions_buttons {
  display: inline-block;
  position: relative;
  top: -3px;
  margin-left: 32px;
  opacity: 0;
}
.smart-table-container table .actions-dropdown .actions_buttons.active {
  margin-left: -5px;
  opacity: 1;
}
.smart-table-container table .actions-dropdown.hide-action {
  border: 0;
  border-left: solid 1px #dddddd;
  right: 1px;
  margin-top: 0px;
}
.smart-table-container table .actions-dropdown.hide-action .actions-toggle {
  display: none;
}
.smart-table-container table.category-notifications tbody tr {
  cursor: pointer;
}
.smart-table-container table.with-associated-rows .show-assicated-row-toggle {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  font-size: 12px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.smart-table-container table.with-associated-rows .show-assicated-row-toggle.toggle-showing {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-left: -2px;
  margin-right: 12px;
}
.smart-table-container table.with-associated-rows .associated-cells {
  padding-left: 30px;
}
#update-column-panel {
  padding: 0 60px 0 0;
  margin-right: -60px;
  background: #f9f9f9;
  position: fixed;
  z-index: 1001;
  top: 0px;
  right: -660px;
  bottom: 0;
  width: 600px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#update-column-panel.in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
#update-column-panel #page-panel-title-bar {
  background: #ffffff;
}
#update-column-panel .help_section {
  padding: 20px;
  margin: 0;
}
#update-column-panel #page-panel-main {
  left: 0;
  right: 60px;
  top: 41px;
  bottom: 71px;
  margin-bottom: 20px;
}
#update-column-panel form {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
}
#update-column-panel .system_settings_sortable {
  overflow: auto;
  height: auto;
  margin: 20px 10px 0 10px;
  padding: 0 0 5px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border-top: 1px solid rgba(220, 220, 221, 0.6);
}
#update-column-panel .system_settings_sortable li {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-left: 1px solid #dcdcdd;
  border-right: 1px solid #dcdcdd;
}
#update-column-panel .system_settings_sortable li:first-of-type {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
#update-column-panel .system_settings_sortable li:last-of-type {
  border-bottom: none;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
#update-column-panel .system_settings_sortable label {
  margin: 0;
  padding: 10px 14px;
}
#update-column-panel .system_settings_sortable input[type="radio"] {
  float: left;
  margin: 3px 6px 0 0;
}
#update-column-panel .system_settings_sortable label:hover {
  cursor: move;
}
#update-column-panel .page-panel-actions {
  right: 60px;
}
#more-details-panel {
  padding: 0 70px 0 0;
  margin-right: -70px;
  background: #f9f9f9;
  position: fixed;
  z-index: 1001;
  top: 0px;
  right: -770px;
  bottom: 0;
  width: 700px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#more-details-panel.in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
#more-details-panel #page-panel-main:not(.fix-left-and-right-spacing) {
  left: 0;
  right: 0;
}
#more-details-panel #page-panel-main.with-top-padding {
  padding-top: 20px;
}
#more-details-panel #page-panel-main .data-section {
  width: 663px;
}
#more-details-panel #page-panel-main .larger-field-label {
  margin-left: 0px;
}
#more-details-panel #page-panel-main .panel-extra-info-block .with-inline-helper span {
  margin: 0;
}
#more-details-panel #page-panel-main .panel-extra-info-block .field-label {
  margin-left: 0;
}
#more-details-panel #page-panel-main .panel-extra-info-block table {
  float: left;
  margin: 4px 0 0 0;
}
#more-details-panel #page-panel-main .panel-extra-info-block .data-section {
  clear: both;
  margin: 0 0 10px 0;
  padding: 0;
  width: 638px;
  border: none;
}
#more-details-panel #page-panel-main .panel-extra-info-block .data-section:last-of-type {
  margin: 0;
}
#more-details-panel #page-panel-main .panel-extra-info-block .data-section .section-title {
  background: #f9f9f9;
  padding: 5px 0;
  border-top: 1px solid #eeeeee;
  width: 649px;
  margin-left: -10px;
}
#more-details-panel #page-panel-main .panel-extra-info-block .data-section .section-title strong {
  margin-left: 10px;
}
#more-details-panel ul,
#more-details-panel .nested-details {
  margin: 0;
  list-style: none;
}
#more-details-panel strong {
  color: #505b65;
}
#more-details-panel .panel-info-block {
  width: 690px !important;
}
#more-details-panel .panel-info-block.with-max-height {
  max-height: 300px;
  overflow: auto;
}
#more-details-panel .panel-info-block li {
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
}
#more-details-panel .panel-info-block .panel-info-block-title .date {
  margin: 0 95px 0 0;
}
#more-details-panel .panel-info-block .panel-info-block-content {
  width: 620px;
}
#more-details-panel.with-fixed-bottom-buttons #page-panel-main {
  padding-bottom: 110px;
  right: 70px;
}
#more-details-panel #preview-file #page-panel-main {
  margin-right: 90px;
  margin-left: 20px;
  margin-top: 10px;
  bottom: -30px;
}
.counter-complete {
  display: block;
  margin: 45px;
}
.counter-complete.small-bottom-margin {
  margin-bottom: 4px;
}
.counter-complete .complete-icon,
.counter-complete .warning-icon,
.counter-complete .info-icon {
  display: inline-block;
  width: 70px;
  font-size: 70px;
  color: #639700;
}
.counter-complete .complete-icon.warning-icon,
.counter-complete .warning-icon.warning-icon,
.counter-complete .info-icon.warning-icon {
  color: #d25656;
}
.counter-complete .complete-icon.info-icon,
.counter-complete .warning-icon.info-icon,
.counter-complete .info-icon.info-icon {
  color: #bccfd6;
}
.counter-complete .total-number {
  display: block;
  margin: 0 0 -41px 0;
  font-size: 15px;
  color: #639700;
}
.notification-boxes .edit-link {
  float: right;
}
.email-wrapper .control-group {
  max-width: 890px;
}
.email-wrapper .message-wrapper {
  max-width: 1140px;
}
.email-wrapper .cc-link {
  position: relative;
  float: right;
  margin: -25px 10px 0 0;
}
.input-with-tags .tags {
  margin: 0;
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #cccccc;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  padding: 2px 5px;
}
.input-with-tags .tags .tag-input {
  padding: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #555555;
  width: 200px !important;
}
.input-with-tags .tags.focused {
  border: 1px solid rgba(82, 168, 236, 0.8);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}
.input-with-tags .tags .tag-item {
  color: #505b65;
  border: 1px solid #bcbcbc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  text-shadow: 0px -1px 1px rgba(255, 255, 255, 0.4);
  background: #f0f0f0;
}
.input-with-tags .tags .tag-item span {
  float: left;
  margin: 2px 0 0 2px;
  font-family: Arial, Helvetica, sans-serif;
}
.input-with-tags .tags .tag-item button:active {
  color: rgba(240, 61, 37, 0.9);
}
.input-with-tags .tags .tag-item.selected {
  background-color: #efefef;
  background-image: -moz-linear-gradient(top, #f9f9f9, #e1e1e1);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f9f9f9), to(#e1e1e1));
  background-image: -webkit-linear-gradient(top, #f9f9f9, #e1e1e1);
  background-image: -o-linear-gradient(top, #f9f9f9, #e1e1e1);
  background-image: linear-gradient(to bottom, #f9f9f9, #e1e1e1);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#ffe1e1e1', GradientType=0);
  border-color: rgba(240, 61, 37, 0.9);
}
.input-with-tags .autocomplete {
  padding: 0;
  margin: 0;
  border-color: #c9c9c9;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  max-width: 750px;
}
.input-with-tags .autocomplete .suggestion-list {
  overflow: hidden;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.input-with-tags .autocomplete .suggestion-list li {
  font-family: Arial, Helvetica, sans-serif;
  height: 29px;
  line-height: 29px;
  padding: 0 0 0 24px;
  font-size: 14px;
  color: #505b65;
  border-bottom: 1px solid #d1d8e7;
}
.input-with-tags .autocomplete .suggestion-list li:hover {
  background: #fcfcfc;
  color: #505b65;
  cursor: pointer;
}
.input-with-tags .autocomplete .suggestion-item.selected {
  background: #fcfcfc;
  color: #505b65;
}
.input-with-tags .autocomplete .suggestion-item em {
  background: #feffde;
}
.attachment-controls {
  position: relative;
}
.attachment-controls a {
  float: left;
  margin: 6px 0 0 0;
  color: #797979;
}
.attachment-controls .dropdown-menu.small-menu {
  left: -164px;
}
.attachment-controls .ss-plus,
.attachment-controls .ss-attach {
  position: absolute;
}
.attachment-controls .ss-plus + .tooltip,
.attachment-controls .ss-attach + .tooltip {
  float: left;
  width: 100px;
}
.attachment-controls .ss-attach {
  margin-left: 25px;
}
.attached-files {
  margin: 4px 0 0 0;
  float: left;
  clear: both;
  width: 500px;
  list-style: none;
}
.attached-files .ss-delete {
  float: right;
  margin: 2px 0 0 0;
  font-size: 11px;
  color: #797979;
}
.attached-files li {
  padding: 6px 4px 6px 10px;
  margin: 0 0 6px 0;
  background: rgba(255, 255, 255, 0.97);
  border: 1px solid #dadada;
}
.attached-files li:last-of-type {
  margin: 0;
}
.subnav {
    /*    background: rgba(1, 0, 0, 0.05);
    */
    background: #eaedf2;
    border-bottom: 1px solid #d1d9dd;
}
.subnav .nav_wrapper {
  padding: 10px 0 0 0;
  *zoom: 1;
  clear: both;
  border-top: 0;
  border-bottom: 0;
  height: auto;
}
.subnav .nav_wrapper:before,
.subnav .nav_wrapper:after {
  display: table;
  content: "";
  line-height: 0;
}
.subnav .nav_wrapper:after {
  clear: both;
}
.subnav .nav_wrapper .nav_bar {
  margin: 0 0 -1px 30px;
}
.subnav .nav_wrapper .nav_bar ul {
  padding: 0;
}
.subnav .nav_wrapper .nav_bar ul li {
  border: none;
  height: auto;
  line-height: 30px;
  border-bottom: none;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.subnav .nav_wrapper .nav_bar ul li a {
  padding-left: 15px;
  padding-right: 15px;
  color: #788790;
}
.subnav .nav_wrapper .nav_bar ul li a span {
  cursor: pointer;
}
.subnav .nav_wrapper .nav_bar ul li:hover {
  background: transparent;
}
.subnav .nav_wrapper .nav_bar ul li:hover a {
  color: #606d75;
}
.subnav .nav_wrapper .nav_bar ul li.active {
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
  -webkit-box-shadow: 0 1px 2px #ffffff inset;
  -moz-box-shadow: 0 1px 2px #ffffff inset;
  box-shadow: 0 1px 2px #ffffff inset;
  background: #f7faff !important;
  border-color: #c4cdd1 !important;
  border-right: 1px solid;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid #f7faff !important;
}
.subnav .nav_wrapper .nav_bar ul li.static-shelf-title {
  position: relative;
  z-index: 2;
  background: #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
}
.subnav .with-data .tooltip {
  line-height: 20px;
}
.subnav .with-data a:hover span {
  cursor: default;
}
.subnav .with-data span {
  margin: 0 0 0 4px;
}
.subnav.small ul li a,
.subnav.jobs-subnav ul li a {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.quick-links-wrap.open .quick-links-btn {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #fcfcfc !important;
}
.quick-links-wrap .dropdown-menu.quick-links {
  left: auto !important;
  right: 27px !important;
}
.quick-links-wrap .dropdown-menu.quick-links .links a {
  padding-left: 22px;
}
.quick-links-wrap .dropdown-menu.quick-links .links strong {
  float: left;
  width: 100%;
  padding: 4px 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #f4f6fb;
  color: #7c828a;
}
.quick-links-wrap .dropdown-menu.quick-links .links strong:first-of-type {
  border-top: none;
}
.quick-links-wrap .dropdown-menu.large-quick-links {
  width: 425px;
  background: #eceef4;
}
.quick-links-wrap .dropdown-menu.large-quick-links:before,
.quick-links-wrap .dropdown-menu.large-quick-links:after {
  left: 397px;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column {
  float: left;
  width: 50%;
  height: auto;
  padding-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column:hover {
  background: #eceef4;
  cursor: default;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column div {
  width: 90%;
  margin: 0 0 12px 0;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column div:last-of-type {
  border-bottom: none;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column strong {
  float: left;
  width: 100%;
  padding: 0 0 2px 0;
  font-size: 13px;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column a {
  color: #3883c1;
  padding: 0 0 0 5px;
  line-height: 28px;
}
.quick-links-wrap .dropdown-menu.large-quick-links .link-column a:hover {
  background: #f2f4fa;
}
.quick-links-wrap .dropdown-menu.with-single-column .link-column {
  width: 100%;
}
.quick-links-wrap .quick-links-btn {
  float: right;
  margin: -2px 30px 0 0;
}
.quick-links-wrap .quick-links-btn .caret {
  margin: 7px 0 0 2px;
}
.quick-links-wrap .quick-links-btn:hover {
  background: #f9f9f9;
  color: #79888f;
}
.quick-links-wrap .quick-links-btn:hover .caret {
  border-top-color: #a4aaaf;
}
.dropdown-menu.notifications .no_messages {
  height: 150px !important;
}
.dropdown-menu span.message {
  padding-left: 10px;
}
.proccessing-reminders .widget .stats-block {
  width: 100%;
  text-align: center;
}
.configure-send-reminders label {
  color: #505b65;
}
.notes-scrollable.below-help-text {
  top: 103px;
  border-top: solid 1px #eeeeee;
}
.help_section.with-smaller-padding {
  padding: 5px 20px;
  border-bottom: none;
}
.follow_up_advanced a {
  float: left;
  clear: both;
  margin: 10px 0 0 0;
}
.nested-message-area {
  padding: 0 !important;
}
.nested-message-area .top-section {
  margin-bottom: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dadada;
}
.message-name {
  margin: 20px 0 20px 0!important;
}
.inline-form-control {
  clear: both;
}
.inline-form-control p,
.inline-form-control span {
  float: left;
}
.inline-form-control span {
  margin: -5px 7px 0 10px;
}
.inline-form-control .btn {
  clear: both;
  float: left;
}
.inline-message-wrapper {
  margin: 20px 0 0 0;
  padding-top: 20px !important;
  border-top: 1px solid #dadada;
}
.inline-message-wrapper .job_confirm_steps .import_options_wrapper {
  top: 19px !important;
}
.modal-body.merge-contact-body {
  height: 400px !important;
  overflow-y: scroll;
  padding-bottom: 45px;
}
.merge-contact-data-section {
  background: #f9f9f9;
  border: 2px solid white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  padding-bottom: 4px;
  margin-bottom: 16px;
}
.merge-contact-data-section .title {
  border-bottom: 1px solid #dadada;
  color: #505b65;
  font-size: 18px;
  margin: 0px;
  padding: 10px 20px;
  -webkit-box-shadow: 0 1px 0 #ffffff;
  -moz-box-shadow: 0 1px 0 #ffffff;
  box-shadow: 0 1px 0 #ffffff;
}
.modal-body .merge-contact-data-section .help_section {
  margin: -20px 0 0 0;
  padding: 8px 30px 0;
}
.merge-contact-data-section textarea {
  width: 97%;
}
.merge-contact-arrow {
    display: inline-block;
    background: url('/images/null.png') no-repeat center;
    width: 100%;
    float: left;
    height: 48px;
    margin: -8px 0px 5px 0px;
}
#customer_view_fi {
  width: 250px;
}
#customer_view_se {
  width: 250px;
}
.merge_contact_list {
  width: auto;
  height: auto;
  margin: 0 0 4px 0;
  padding: 0px;
}
.merge_contact_list ul {
  margin: 0px;
  padding: 0px;
}
.merge_contact_list ul li {
  list-style: none;
  margin-bottom: 5px;
  padding: 0px;
}
.merge_contact_list ul li span {
  width: 34%;
  float: left;
}
.merge_contact_list ul li label {
  width: 65%;
  float: left;
  margin: 0px;
}
.merge_btn {
  margin-top: -1px;
}
.convert_btn {
  margin-top: -2px;
}
.merge_and_transfer_main {
  float: left;
  margin-top: 20px;
  padding-bottom: 24px;
}
.merge_and_transfer_select {
  width: auto;
  float: left;
  margin: 0 9px 0 0px;
}
.merge_and_transfer_select.with-left-margin {
  margin: 0 9px;
}
.merge_and_transfer_select.set-width {
  width: 150px;
}
.merge_and_transfer_select.fix-height {
  position: relative;
  top: -7px;
}
.merge_and_transfer_select.second {
  margin: 0 25px 0 0;
}
.merge_and_transfer_select.last {
  margin: 2px 0 0 0;
}
.merge_and_transfer_select label {
  padding-top: 3px;
}
.merge_and_transfer_select p {
  margin: 10px 0px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari only override */
  ::i-block-chrome,
  .second_contact {
    margin-top: -21px;
  }
  ::i-block-chrome.full-width-smart-filter,
  .second_contact.full-width-smart-filter {
    margin-top: 0px;
  }
}
.add-diary-event {
  float: right;
}
.engineer-field-wrapper {
  padding: 2px 0 0 0;
}
.engineer-field-wrapper .field-label {
  margin: 4px 0 0 20px;
}
.engineer-field-wrapper img {
  margin: -2px 10px 0 0;
}
.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.status-circle.not-complete {
  background: rgba(240, 61, 37, 0.9);
}
.status-circle.complete {
  background: #7eb419;
}
.diary-list-view {
  overflow: auto;
  position: relative;
}
.diary-list-view .line {
  position: absolute;
  top: 40px;
  bottom: 0 !important;
  left: 71px;
  width: 2px;
  background: #677077;
}
.diary-list-view .timeline .date-wrap {
  text-align: left;
}
.diary-list-view .timeline .item {
  width: auto;
  left: auto;
  right: auto;
}
.diary-list-view .timeline .item .item-icon {
  margin-top: 3px;
}
.diary-list-view .timeline .item .item-icon img {
  border-radius: 34px;
  width: 100%;
  height: 100%;
}
.diary-list-view .timeline .item .item-inner {
  margin-left: 36px;
  margin-right: 0px;
  position: relative;
}
.diary-list-view .timeline .item .item-inner .status-wrapper {
  display: inline-block;
}
.diary-list-view .timeline .item .item-inner .status-wrapper span.label {
  color: #ffffff;
}
.diary-list-view .timeline .item .item-inner .title-bar {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.diary-list-view .timeline .item .item-inner .title-bar .left-side {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  overflow: hidden;
}
.diary-list-view .timeline .item .item-inner .title-bar .left-side .engineer-name {
  margin-left: 10px;
  margin-right: 0px;
}
.diary-list-view .timeline .item .item-inner .title-bar .left-side .diary-event-time {
  padding-left: 10px;
  width: auto;
  border-left: solid 1px #dadada;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.diary-list-view .timeline .item .item-inner .title-bar .left-side .diary-event-time span {
  padding-right: 0px;
}
.diary-list-view .timeline .item .item-inner .title-bar .left-side .event-description {
  padding-left: 10px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: solid 1px #dadada;
}
.diary-list-view .timeline .item .item-inner .title-bar .label {
  margin-right: 5px;
  margin-left: 5px;
}
.diary-list-view .timeline .item .item-inner .title-bar span,
.diary-list-view .timeline .item .item-inner .title-bar strong {
  padding-right: 10px;
  display: inline-block;
}
.diary-list-view .timeline .item .item-inner .title-bar span.label,
.diary-list-view .timeline .item .item-inner .title-bar strong.label {
  padding-right: 8px;
  float: right;
  clear: both;
  position: relative;
  top: -14px;
}
.diary-list-view .timeline .item .item-inner .content {
  position: relative;
}
.diary-list-view .timeline .item .item-inner .content p {
  margin-left: 20px;
}
.diary-list-view .timeline .item .item-inner .engineer-feedback {
  min-height: 90px;
}
.diary-list-view .timeline .item .item-inner .engineer-feedback .large-profile-wrapper {
  position: absolute;
  left: 30px;
  top: 20px;
}
.diary-list-view .timeline .item .item-inner .engineer-feedback .speech-bubble {
  display: inline-block;
  width: aut0;
  margin-left: 110px;
  margin-top: 25px;
}
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon {
  float: left;
  margin-left: -20px;
  margin-right: 0px;
}
.diary-list-view .timeline .item.cancelled-event .item-inner {
  background: repeating-linear-gradient(-45deg, rgba(210, 86, 86, 0.04), rgba(210, 86, 86, 0.04) 10px, transparent 10px, transparent 20px);
}
.diary-list-view .timeline .item.cancelled-event .item-inner .content {
  background: rgba(255, 255, 255, 0.5);
}
.diary-list-view .timeline .item:nth-of-type(even),
.diary-list-view .timeline .item:nth-of-type(odd) {
  right: 0px;
  left: 0px;
  width: auto;
  margin-left: 41px;
  margin-bottom: 20px;
}
.diary-list-view .timeline .item:nth-of-type(even):last-of-type,
.diary-list-view .timeline .item:nth-of-type(odd):last-of-type {
  padding-bottom: 0px;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-inner,
.diary-list-view .timeline .item:nth-of-type(odd) .item-inner {
  margin-left: 46px;
  margin-right: 0px;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-inner:after,
.diary-list-view .timeline .item:nth-of-type(odd) .item-inner:after {
  display: none;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-inner:before,
.diary-list-view .timeline .item:nth-of-type(odd) .item-inner:before {
  display: none;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-inner::after,
.diary-list-view .timeline .item:nth-of-type(odd) .item-inner::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 12px;
  left: -9px;
  right: auto;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #fcfcfc;
  border-left: none;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-inner::before,
.diary-list-view .timeline .item:nth-of-type(odd) .item-inner::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 11px;
  left: -10px;
  right: auto;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #cac9c9;
  border-left: none;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-icon,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon {
  margin-left: -9px;
  margin-right: 0px;
  float: left;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-location,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-location,
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-navigate,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-navigate,
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-write,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-write,
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-camera,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-camera {
  color: #6393b5;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-check,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-check {
  color: #7eb419;
}
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-delete,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-delete,
.diary-list-view .timeline .item:nth-of-type(even) .item-icon .ss-alert,
.diary-list-view .timeline .item:nth-of-type(odd) .item-icon .ss-alert {
  color: #d25656;
}
.schedule-tab .diary-list-view {
  position: relative;
  top: 0px;
  left: 0px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.schedule-tab .diary-list-view.without-right-bar {
  right: 30px;
}
.schedule-tab.in-estimates .no-results-wrap .warning-message {
  width: 60%;
  margin-left: 20%;
}
.timeline-no-results .helper-arrow {
  right: auto;
  left: 235px;
}
.timeline-no-results .helper-text {
  right: auto;
  left: 25px;
}
.diary-event-feedback-popup .group-seporator {
  right: 20px !important;
}
.diary-event-feedback-popup .pop-up-header {
  background: rgba(0, 0, 0, 0.05);
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  padding: 5px 20px;
}
.diary-event-feedback-popup .cs-event-body {
  margin-top: 8px;
}
.diary-event-feedback-popup .cs-event-body-section-content {
  float: none !important;
  padding: 26px 20px 0 10px !important;
  margin: 0px !important;
}
.diary-event-feedback-popup .cs-event-body-section-content .question-wrapper {
  margin-bottom: 5px;
}
.diary-event-feedback-popup .cs-event-body-section-content .question-wrapper .question-name {
  font-style: normal;
}
.diary-event-feedback-popup .cs-event-body-section-content .question-wrapper .question-answer {
  padding-left: 13px;
  padding-top: 2px;
  display: inline-block;
}
.diary-event-feedback-popup .cs-event-body-section-content .question-wrapper .question-answer .ss-quote {
  top: -10px;
}
.diary-event-feedback-popup .cs-event-body-section-content .question-wrapper .question-answer .ss-quote.rotated {
  top: -12px;
}
.diary-event-feedback-popup .cs-event-body-section-content .question-wrapper .question-answer .answer {
  display: inline-block;
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#cs-calendar hr {
  padding: 0;
  border-bottom-width: 0;
}
#cs-calendar .fc-widget-header {
  border: 0;
}
#cs-calendar .fc-time-grid-container {
  float: left;
  max-height: 1058px;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed hr,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #ececec !important;
}
.fc-unthemed .fc-today {
  background: none;
}
#diary-wrapper {
  position: relative;
}
#diary-wrapper #diary-main {
  position: fixed;
  top: 73px;
  bottom: 0;
  left: 0;
  right: 240px;
  height: 100%;
}
#diary-wrapper #diary-main #diary-top-bar {
  height: 41px;
  width: 100%;
}
#diary-wrapper #diary-right-bar {
  position: fixed;
  width: 240px;
  top: 73px;
  bottom: 0;
  right: 0;
}
#diary-wrapper .no-users-on-diary-message {
  margin-top: 20px;
}
#diary-wrapper .no-users-on-diary-message .no-results-wrap {
  margin-left: -240px;
}
#diary-wrapper .no-users-on-diary-message .helper-arrow {
  top: 310px;
  right: 155px;
  -webkit-transform: rotate(-160deg);
  -moz-transform: rotate(-160deg);
  -ms-transform: rotate(-160deg);
  -o-transform: rotate(-160deg);
  transform: rotate(-160deg);
}
#diary-wrapper .no-users-on-diary-message .helper-text {
  top: 260px;
  right: 300px;
}
#diaryContent {
  padding: 0 30px;
  margin: 16px 0;
}
#user-group-suggestion {
    position: relative;
    top: 78px;
    left: 44px;
    z-index: 2;
    font-size: 16px;
    color: #d25656;
}
.fc-left {
  float: left;
  position: fixed;
  top: 72px;
}
.fc-button-group {
  position: fixed;
  top: 73px;
  right: 254px;
  height: 41px;
}
.fc-toolbar {
  margin: 0;
}
.fc-view-container {
  margin: -20px 0 0 0;
}
#cs-calendar {
  margin-top: 45px;
  background: #ffffff;
}
#cs-calendar.diary-in-daily-view.with-lots-of-users-showing .fc-view-container table thead .fc-widget-header th {
  font-size: 11px;
}
#cs-calendar.cs-diary-loading {
  opacity: 0.2;
}
#cs-calendar.cs-diary-loading.cs-diary-with-events {
  opacity: 0.5;
}
.fc-view-container {
  margin-top: -45px;
}
.fc-view-container table {
  background: transparent;
}
.fc-view-container table thead {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.fc-view-container table thead th:first-of-type,
.fc-view-container table thead th:last-of-type {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.fc-view-container table thead .fc-widget-header th {
    text-align: center !important;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0;
    color: #505b65;
    background: #fbfbfb;
    background: #f7faff;
}
.fc-view-container table tbody tr {
  border-bottom: 1px dashed #ececec;
}
.fc-view-container table tbody tr:nth-child(odd),
.fc-view-container table tbody tr:hover {
  background: transparent;
}
.fc-view-container table tbody .fc-axis {
    text-align: center !important;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    color: #505b65;
}
.fc-view.fc-agendaDay-view.fc-agenda-view .fc-resource-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fc-agendaDay-view .fc-widget-content:first-of-type,
.fc-agendaWeek-view .fc-widget-content:first-of-type {
  border-color: #dbdbdb !important;
}
.fc-agendaDay-view .fc-widget-header th:first-of-type,
.fc-agendaWeek-view .fc-widget-header th:first-of-type {
  background: #f7faff;
}
.fc-agendaDay-view .fc-day:nth-child(odd),
.fc-agendaWeek-view .fc-day:nth-child(odd) {
  background: #fefefe;
}
.fc-agendaDay-view .fc-week .fc-day,
.fc-agendaWeek-view .fc-week .fc-day {
  background: #fcfcfc !important;
}
.fc-agendaDay-view .fc-week td,
.fc-agendaWeek-view .fc-week td {
  border-color: #dbdbdb !important;
}
.fc-agendaDay-view .fc-day-grid .fc-row:nth-child(2),
.fc-agendaWeek-view .fc-day-grid .fc-row:nth-child(2) {
  border-bottom: 1px solid #dbdbdb !important;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}
.fc-twoWeek-view td.fc-day-number,
.fc-month-view td.fc-day-number {
  text-align: center !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #505b65;
  background: #fbfbfb;
  border-top: 1px solid #dddddd;
}
.fc-twoWeek-view tbody tr,
.fc-month-view tbody tr {
  border: 0px !important;
}
.fc-list-view {
  position: absolute;
  top: 70px;
  left: 30px;
  right: 30px;
  bottom: 100px;
  overflow: auto;
}
.fc-list-view .fc-list-table thead {
  border: none;
}
.fc-list-view .fc-list-table thead th {
  background: transparent;
}
.fc-list-view .fc-list-table thead span {
  float: left;
  text-align: center !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #505b65;
  background: #fbfbfb;
  background: transparent;
}
.fc-list-view .fc-list-table tbody tr td:nth-child(1),
.fc-list-view .fc-list-table tbody tr td:nth-child(3) {
  display: none;
}
.fc-list-view .fc-list-table tbody tr:first-of-type td:nth-child(1),
.fc-list-view .fc-list-table tbody tr:first-of-type td:nth-child(3) {
  display: block;
}
.fc-list-view .fc-list-table tbody tr td {
  border: none;
}
.fc-agendaWeek-view .fc-widget-header th.header-for-today {
    background: #ea9b70 !important;
    color: #fafafa;
    font-weight: 100;
}
.fc-twoWeek-view .fc-content-skeleton td.fc-today,
.fc-month-view .fc-content-skeleton td.fc-today {
    background: #ea9b70 !important;
    color: #fafafa;
    font-weight: 100;
}
.all-day-highlight-skeleton table tbody tr td:nth-child(1) {
  visibility: hidden;
}
#diary-top-bar {
  background: #eaedf2;
  border-bottom: 1px solid #d1d9dd;
}
.fc-left h2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 44px;
}
.fc-button-group .fc-button {
  float: left;
  height: 33px;
  padding: 4px 15px 4px 15px;
  margin: 9px 0 0 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  text-shadow: none;
  color: #788790;
  background: none;
  font-size: 13px;
  line-height: 12px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: none;
  -webkit-transition: color 0.1s ease-in;
  -moz-transition: color 0.1s ease-in;
  -o-transition: color 0.1s ease-in;
  transition: color 0.1s ease-in;
}
.fc-button-group .fc-button.fc-corner-left {
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-bottom-left-radius: 0px;
}
.fc-button-group .fc-button.fc-corner-right {
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-right-radius: 0px;
}
.fc-button-group .fc-button:hover {
  color: #606d75;
}
.fc-button-group .fc-button.fc-state-active {
  height: 32px;
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 2px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
  -webkit-box-shadow: 0 1px 2px #ffffff inset;
  -moz-box-shadow: 0 1px 2px #ffffff inset;
  box-shadow: 0 1px 2px #ffffff inset;
  background: #f7faff;
  border-color: #c4cdd1;
}
#diary-right-bar,
.static-shelf.jobs {
  border-left: 1px solid #d1d9dd;
  -webkit-box-shadow: -1px 43px 4px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: -1px 43px 4px rgba(0, 0, 0, 0.03);
  box-shadow: -1px 43px 4px rgba(0, 0, 0, 0.03);
}
#diary-right-bar .small-diay-wrap,
.static-shelf.jobs .small-diay-wrap {
  min-height: 274px;
  padding: 10px 0 0 0;
  background: #fdfdfd;
  border-bottom: 1px solid #efefef;
}
#diary-right-bar .custom-datepicker,
.static-shelf.jobs .custom-datepicker {
  position: static;
  float: left;
  width: 209px;
  margin: 0 0 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
}
#diary-right-bar .custom-datepicker table,
.static-shelf.jobs .custom-datepicker table {
  width: 209px;
  border: none;
}
#diary-right-bar .custom-datepicker table td,
.static-shelf.jobs .custom-datepicker table td {
  border: none;
  font-size: 13px;
}
#diary-right-bar .custom-datepicker table td a,
.static-shelf.jobs .custom-datepicker table td a {
  line-height: 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  font-weight: bold;
  color: rgba(87, 87, 87, 0.9);
}
#diary-right-bar .custom-datepicker table td a:last-child,
.static-shelf.jobs .custom-datepicker table td a:last-child {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border: 1px solid transparent;
}
#diary-right-bar .custom-datepicker table td a:last-child:hover,
.static-shelf.jobs .custom-datepicker table td a:last-child:hover {
  background: #fafafa;
  border-color: #50a69a;
}
#diary-right-bar .custom-datepicker table td a.muted,
.static-shelf.jobs .custom-datepicker table td a.muted {
  background: #f5f5f5;
}
#diary-right-bar .custom-datepicker table td a.selected-day,
.static-shelf.jobs .custom-datepicker table td a.selected-day {
  background: #50a69a;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 100;
}
#diary-right-bar .custom-datepicker table td a.selected-day:hover,
.static-shelf.jobs .custom-datepicker table td a.selected-day:hover {
  background: #50a69a;
  color: rgba(255, 255, 255, 0.9);
}
#diary-right-bar .custom-datepicker table tr,
.static-shelf.jobs .custom-datepicker table tr {
  border-bottom: none;
}
#diary-right-bar .custom-datepicker table tr:last-of-type,
.static-shelf.jobs .custom-datepicker table tr:last-of-type {
  border-bottom: none;
}
#diary-right-bar .custom-datepicker table tr td:last-of-type,
.static-shelf.jobs .custom-datepicker table tr td:last-of-type {
  border-right: none;
}

#diary-right-bar .custom-datepicker .day-label,
.static-shelf.jobs .custom-datepicker .day-label {
  color: #94979b;
}
#diary-right-bar .custom-datepicker .month-text,
.static-shelf.jobs .custom-datepicker .month-text {
  font-size: 13px;
  color: #606c7f;
}
#diary-right-bar .custom-datepicker.todays-date-selected .selected-day,
.static-shelf.jobs .custom-datepicker.todays-date-selected .selected-day {
  background: #ea9b70;
  color: #fafafa;
  font-weight: 100;
}
#diary-right-bar .custom-datepicker.todays-date-selected .selected-day:hover,
.static-shelf.jobs .custom-datepicker.todays-date-selected .selected-day:hover {
  background: #ea9b70;
  border-color: #ea9b70;
  color: #fafafa;
}
#diary-right-bar .diary-bar-sections,
.static-shelf.jobs .diary-bar-sections,
#diary-right-bar .helper-for-event-from-previous-page,
.static-shelf.jobs .helper-for-event-from-previous-page {
  position: absolute;
  width: 240px;
  top: 285px;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
}

.static-shelf.jobs .diary-bar-sections {
  padding: 34px 0 0 0;
  overflow: auto;
}
#diary-right-bar .diary-bar-sections,
.static-shelf.jobs .diary-bar-sections {
    padding: 34px 0 0 0;
    overflow-y: auto;
}

#diary-right-bar .diary-bar-sections.without-absolute,
.static-shelf.jobs .diary-bar-sections.without-absolute {
  position: static;
  padding: 0px;
  width: 100%;
}
#diary-right-bar .diary-bar-sections.without-scroll,
.static-shelf.jobs .diary-bar-sections.without-scroll {
  overflow: hidden;
}
#diary-right-bar .diary-bar-sections.compensate-for-event-from-previous-page,
.static-shelf.jobs .diary-bar-sections.compensate-for-event-from-previous-page {
  top: 445px;
  padding: 0px;
  margin: 34px 0 0 0;
}
#diary-right-bar .diary-bar-sections.compensate-for-event-from-previous-page .diary-bar-section,
.static-shelf.jobs .diary-bar-sections.compensate-for-event-from-previous-page .diary-bar-section {
  -webkit-transform: translate(0px, 0px) !important;
  -moz-transform: translate(0px, 0px) !important;
  -ms-transform: translate(0px, 0px) !important;
  -o-transform: translate(0px, 0px) !important;
  transform: translate(0px, 0px) !important;
}
#diary-right-bar .diary-bar-sections .diary-bar-section,
.static-shelf.jobs .diary-bar-sections .diary-bar-section {
  float: left;
  width: 100%;
  margin: 0 0 20px 0;
  list-style: none;
}
#diary-right-bar .diary-bar-sections .diary-bar-section.without-margin,
.static-shelf.jobs .diary-bar-sections .diary-bar-section.without-margin {
  margin: 0px;
}
#diary-right-bar .diary-bar-sections .group-title,
.static-shelf.jobs .diary-bar-sections .group-title {
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 14px 0 15px;
}
#diary-right-bar .diary-bar-sections .group-title strong,
.static-shelf.jobs .diary-bar-sections .group-title strong {
  float: left;
  position: relative;
  padding: 0 10px 0 0;
  background: #ffffff;
  font-size: 14px;
}
#diary-right-bar .diary-bar-sections .group-title .group-seporator,
.static-shelf.jobs .diary-bar-sections .group-title .group-seporator {
  float: left;
  width: 100%;
  height: 1px;
  margin: -9px 0 0 0;
  background: #e7e7e7;
}
#diary-right-bar .diary-bar-sections li > a,
.static-shelf.jobs .diary-bar-sections li > a {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  padding: 9px 15px 9px 25px;
  font-size: 13px;
  -webkit-transition: background 0.1s ease-in;
  -moz-transition: background 0.1s ease-in;
  -o-transition: background 0.1s ease-in;
  transition: background 0.1s ease-in;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
#diary-right-bar .diary-bar-sections li > a:hover,
.static-shelf.jobs .diary-bar-sections li > a:hover {
  background: #ececec;
}
#diary-right-bar .diary-bar-sections li > a .icon,
.static-shelf.jobs .diary-bar-sections li > a .icon {
  float: left;
  margin: 2px 6px 0 0;
  color: #959ba6;
  -webkit-transition: color 0.1s ease-in;
  -moz-transition: color 0.1s ease-in;
  -o-transition: color 0.1s ease-in;
  transition: color 0.1s ease-in;
}
#diary-right-bar .diary-bar-sections li > a .text,
.static-shelf.jobs .diary-bar-sections li > a .text {
  float: left;
  margin: 2px 0 0 0;
}
#diary-right-bar .diary-bar-sections li > a .filter-visual-indicator,
.static-shelf.jobs .diary-bar-sections li > a .filter-visual-indicator {
  float: right;
  margin: 2px 0 0 0;
  color: #959ba6;
  font-size: 12px;
  font-style: italic;
}
#diary-right-bar .diary-bar-sections li > a .label,
.static-shelf.jobs .diary-bar-sections li > a .label {
  position: absolute;
  right: 15px;
  top: 13px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#diary-right-bar .diary-bar-sections li > a .label.showing,
.static-shelf.jobs .diary-bar-sections li > a .label.showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#diary-right-bar .diary-bar-sections li > a .label.not-showing,
.static-shelf.jobs .diary-bar-sections li > a .label.not-showing {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#diary-right-bar .diary-bar-sections li > a.dropdown-open,
.static-shelf.jobs .diary-bar-sections li > a.dropdown-open {
  background: #efefef;
  border-top: 1px solid rgba(208, 208, 208, 0.7);
  border-bottom: 1px solid rgba(208, 208, 208, 0.7);
}
#diary-right-bar .diary-bar-sections li > a.dropdown-open .icon,
.static-shelf.jobs .diary-bar-sections li > a.dropdown-open .icon {
  color: #ea9b70;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown {
  display: none;
  clear: both;
  max-height: 150px;
  overflow: auto;
  padding: 12px 0;
  background: #fdfdfd;
  border-bottom: 1px solid #efefef;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown.flipped-in,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown.flipped-in {
  display: block;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown.without-bottom-border,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown.without-bottom-border {
  border-bottom: none;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown ul,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown ul {
  margin: 0 0 0 30px;
  padding: 0 0 0 10px;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown li,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown li {
  list-style: none;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown label,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown label {
  margin: 0;
  padding: 5px 0;
  cursor: pointer;
  font-size: 13px;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown label input[type="checkbox"],
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown label input[type="checkbox"],
#diary-right-bar .diary-bar-sections .right-bar-dropdown label input[type="radio"],
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown label input[type="radio"] {
  float: left;
  margin: 0 7px 0 0;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown label input[type="checkbox"],
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown label input[type="checkbox"] {
  margin-top: 4px;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown label input[type="radio"],
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown label input[type="radio"] {
  margin-top: 3px;
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown .top-scrollable-shadow,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown .top-scrollable-shadow {
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.01));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.01));
}
#diary-right-bar .diary-bar-sections .right-bar-dropdown .bottom-scrollable-shadow,
.static-shelf.jobs .diary-bar-sections .right-bar-dropdown .bottom-scrollable-shadow {
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.05));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.05));
}
.helper-for-event-from-previous-page {
  padding: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.helper-for-event-from-previous-page .helper-arrow {
  position: fixed;
  top: 500px;
  right: 160px;
  width: 120px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotateX(170deg) rotateZ(25deg);
}
#diary-right-bar .small-diary-controls,
.static-shelf.jobs .small-diary-controls,
#project-calendar-controls .small-diary-controls {
  margin: 0 0 10px 99px;
  text-align: center;
}
#diary-right-bar .small-diary-controls .icon,
.static-shelf.jobs .small-diary-controls .icon,
#project-calendar-controls .small-diary-controls .icon {
  font-size: 9px;
}
#diary-right-bar .small-diary-controls a,
.static-shelf.jobs .small-diary-controls a,
#project-calendar-controls .small-diary-controls a {
  width: auto;
  height: auto;
  font-size: 12px;
  background: #f0f0f0;
  color: rgba(143, 146, 150, 0.9);
  border: 1px solid #dadada;
  border-bottom-width: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  transition: color 0.1s ease-in, background 0.1s ease-in;
}
#diary-right-bar .small-diary-controls a:hover,
.static-shelf.jobs .small-diary-controls a:hover,
#project-calendar-controls .small-diary-controls a:hover {
  color: rgba(138, 141, 145, 0.9);
  background: #ebebeb;
}
#diary-right-bar .small-diary-controls a:nth-child(2),
.static-shelf.jobs .small-diary-controls a:nth-child(2),
#project-calendar-controls .small-diary-controls a:nth-child(2) {
  float: left;
  padding: 2px 10px 1px;
  margin: -3px 4px 0 0;
}
#diary-right-bar .small-diary-controls a:nth-child(1),
.static-shelf.jobs .small-diary-controls a:nth-child(1),
#project-calendar-controls .small-diary-controls a:nth-child(1),
#diary-right-bar .small-diary-controls a:nth-child(3),
.static-shelf.jobs .small-diary-controls a:nth-child(3),
#project-calendar-controls .small-diary-controls a:nth-child(3) {
  position: relative;
  top: -1px;
  margin: 0 4px 0 0;
  padding: 8px 7px 5px 7px;
  line-height: 13px;
}
#diary-right-bar .small-diary-controls a:nth-child(3),
.static-shelf.jobs .small-diary-controls a:nth-child(3),
#project-calendar-controls .small-diary-controls a:nth-child(3) {
  padding-left: 8px;
  padding-right: 8px;
}
#diary-right-bar .small-diary-controls a.for-today,
.static-shelf.jobs .small-diary-controls a.for-today,
#project-calendar-controls .small-diary-controls a.for-today {
  margin-left: -7px;
  background: none;
  border: none;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: bold;
  color: #ea9b70;
  cursor: default;
  pointer-events: none;
}
#diary-right-bar#project-calendar-controls .small-diary-controls,
.static-shelf.jobs#project-calendar-controls .small-diary-controls,
#project-calendar-controls#project-calendar-controls .small-diary-controls {
  margin: 0px 0 10px 0px;
  position: relative;
  top: 1px;
}
#diary-right-bar#project-calendar-controls .small-diary-controls a:nth-child(2),
.static-shelf.jobs#project-calendar-controls .small-diary-controls a:nth-child(2),
#project-calendar-controls#project-calendar-controls .small-diary-controls a:nth-child(2) {
  padding: 1px 10px 1px;
  margin: -1px 4px 0 0;
  float: right;
}
#diary-right-bar#project-calendar-controls .small-diary-controls a:nth-child(1),
.static-shelf.jobs#project-calendar-controls .small-diary-controls a:nth-child(1),
#project-calendar-controls#project-calendar-controls .small-diary-controls a:nth-child(1),
#diary-right-bar#project-calendar-controls .small-diary-controls a:nth-child(3),
.static-shelf.jobs#project-calendar-controls .small-diary-controls a:nth-child(3),
#project-calendar-controls#project-calendar-controls .small-diary-controls a:nth-child(3) {
  padding: 6px 7px 2px 7px;
  float: left;
}
.diary-bar-section {
  transition: transform 0.4s, opacity 0.4s;
}
.diary-bar-section.behind-oustanding-jobs {
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap {
  background: #ffffff;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar {
  float: left;
  width: 100%;
  margin: 13px 0 0 0;
  padding: 8px 0;
  background: #fdfdfd;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys {
  float: right;
  margin: 0 15px 0 0;
  padding: 0px;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li {
  width: auto;
  float: left;
  margin: 0 0 0 5px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border 0.1s ease-in;
  -moz-transition: border 0.1s ease-in;
  -o-transition: border 0.1s ease-in;
  transition: border 0.1s ease-in;
  cursor: pointer;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.no-access span {
  background: #d25656;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.parts-ready span {
  background: #6393b5;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.without-events span {
  background: #746ca6;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.with-todos span {
  background: #60a369;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li span {
  float: left;
  width: 8px;
  height: 8px;
  margin: 6px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li:hover.no-access {
  border-color: #f3d3d3;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li:hover.parts-ready {
  border-color: #d2e0ea;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li:hover.without-events {
  border-color: #dddbe9;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li:hover.with-todos {
  border-color: #d1e4d4;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.active.no-access {
  background: #faebeb;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.active.parts-ready {
  background: #eaf0f5;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.active.without-events {
  background: #efeef5;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys li.active.with-todos {
  background: #ebf3ec;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .color-keys .tooltip {
  margin: -36px 0 0 0;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap {
  float: right;
  margin: -9px 0 -9px;
  padding: 9px 0 10px;
  position: relative;
  z-index: 2;
  border-left: 1px solid #f4f4f4;
  border-top: 1px solid transparent;
  -webkit-transition: background 0.1s ease-in;
  -moz-transition: background 0.1s ease-in;
  -o-transition: background 0.1s ease-in;
  transition: background 0.1s ease-in;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap span {
  float: right;
  margin: 2px 0 -2px 15px;
  cursor: pointer;
  color: #bdbdbd;
  font-size: 13px;
  -webkit-transition: color 0.1s ease-in;
  -moz-transition: color 0.1s ease-in;
  -o-transition: color 0.1s ease-in;
  transition: color 0.1s ease-in;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap span.ss-search {
  margin-right: 14px;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap:hover {
  cursor: pointer;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap:hover span {
  color: #3276ad;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap.active {
  background: #efefef;
  border-color: rgba(208, 208, 208, 0.7);
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .outstanding-top-bar .search-icon-wrap.active span {
  color: #3276ad;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list ul#scrollable-outstanding-jobs,
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list ul#scrollable-nearby-events {
  position: absolute;
  overflow: auto;
  top: 74px;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 18px 15px 20px 15px;
  transition: box-shadow 0.2s ease-out, top 0.13s ease-in;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list ul#scrollable-outstanding-jobs.beneath-search-bar,
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list ul#scrollable-nearby-events.beneath-search-bar {
  top: 114px;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .helper-with-icon .with-info-icon {
  margin-top: 20px;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list .helper-with-icon p {
  margin-left: auto;
  margin-right: auto;
  padding: 0px 10px;
}
.diary-bar-section#outstanding-jobs-wrap a.ss-delete {
  position: absolute;
  right: 10px;
  margin: 2px 0 0 0;
  padding: 0 0 0 8px;
  color: #d25656;
  background: #ffffff;
  font-size: 12px;
  transition: transform 0.3s 0.38s, margin 0.4s;
}
.diary-bar-section#outstanding-jobs-wrap a.ss-delete.close-icon-hiding {
  margin-top: 35px;
}
.diary-bar-section#outstanding-jobs-wrap a.ss-delete:hover {
  background: #ffffff;
}
.diary-bar-section#outstanding-jobs-wrap .group-title {
  margin-bottom: 20px;
}
.diary-bar-section#outstanding-jobs-wrap .group-title .tooltip {
  margin: 0 0 0 2px;
  opacity: 0;
  -webkit-transition: opacity 0.05s ease-in;
  -moz-transition: opacity 0.05s ease-in;
  -o-transition: opacity 0.05s ease-in;
  transition: opacity 0.05s ease-in;
}
.diary-bar-section#outstanding-jobs-wrap li > a.on-move {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  background: transparent;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list {
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
  transition-delay: 0.18s;
  -webkit-transition-delay: 0.18s;
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list.showing-when-open {
  opacity: 1;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list.hidden-when-closing {
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap .outstanding-jobs-list li {
  list-style: none;
}
.diary-bar-section#outstanding-jobs-wrap .icon,
.diary-bar-section#outstanding-jobs-wrap .text {
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.diary-bar-section#outstanding-jobs-wrap .icon.hidden-when-opening,
.diary-bar-section#outstanding-jobs-wrap .text.hidden-when-opening {
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap #title-when-closed strong,
.diary-bar-section#outstanding-jobs-wrap #title-when-open strong {
  position: absolute;
  left: 15px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -ms-transform-origin: left;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
}
.diary-bar-section#outstanding-jobs-wrap #title-when-closed strong {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.diary-bar-section#outstanding-jobs-wrap #title-when-closed .group-seporator {
  margin: 10px 0 0 0;
}
.diary-bar-section#outstanding-jobs-wrap #title-when-open strong {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap #title-when-open .ss-delete {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.diary-bar-section#outstanding-jobs-wrap.full-height {
  -webkit-transform: translate(0, -296px);
  -moz-transform: translate(0, -296px);
  -ms-transform: translate(0, -296px);
  -o-transform: translate(0, -296px);
  transform: translate(0, -296px);
}
.diary-bar-section#outstanding-jobs-wrap.full-height .group-title .tooltip {
  opacity: 1;
}
.diary-bar-section#outstanding-jobs-wrap.full-height #title-when-closed strong {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap.full-height #title-when-open strong {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.diary-bar-section#outstanding-jobs-wrap.full-height #title-when-open .ss-delete {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.diary-bar-section#outstanding-jobs-wrap.full-height span.label {
  transition: margin 0.4s 0.1s, transform 0.3s 0.4s ease-in;
  margin-top: -31px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.diary-bar-section#outstanding-jobs-wrap.full-height li.hidden-when-opening {
  opacity: 0;
}
.diary-bar-section#outstanding-jobs-wrap.without-user-groups.full-height {
  -webkit-transform: translate(0, -253px);
  -moz-transform: translate(0, -253px);
  -ms-transform: translate(0, -253px);
  -o-transform: translate(0, -253px);
  transform: translate(0, -253px);
}
.diary-bar-section#outstanding-jobs-wrap #scrollable-nearby-events li:first-of-type .nested-list .small-section-title {
  margin-top: 0;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .search-bar-with-bg {
  margin: 13px 0 0 0;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .nested-list {
  margin: 0;
  padding: 0 0 0 15px;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .nested-list .small-section-title {
  margin: 15px 0 15px -30px;
  width: 210px;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .no-results-wrap {
  bottom: 0;
  overflow: auto;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .no-results-wrap .helper-arrow {
  top: 60px;
  left: auto;
  -webkit-transform: rotateX(-180deg) rotateY(180deg) rotateZ(-93deg);
  -moz-transform: rotateX(-180deg) rotateY(180deg) rotateZ(-93deg);
  -ms-transform: rotateX(-180deg) rotateY(180deg) rotateZ(-93deg);
  -o-transform: rotateX(-180deg) rotateY(180deg) rotateZ(-93deg);
  transform: rotateX(-180deg) rotateY(180deg) rotateZ(-93deg);
  background-size: 80%;
  right: -130px;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .no-results-wrap .help-block {
  margin: 0 15px;
  text-align: left;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event {
  position: relative;
  float: left;
  width: 100%;
  margin: -1px 0 0 0;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner {
  overflow: initial;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner:before,
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner:after {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-header .cs-event-header-upper .cs-event-header-event-type {
  margin: -1px 0 0 -20px;
  border-top-width: 1px;
  border-top-style: solid;
  border-left-width: 1px;
  border-left-style: solid;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-header .cs-event-header-upper .cs-event-header-time {
  left: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-header .cs-event-header-lower .cs-event-header-user img,
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-header .cs-event-header-lower .cs-event-header-user .ss-user {
  margin: -2px 6px 0 -20px;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-header .cs-event-header-lower .cs-event-header-user span:last-of-type {
  left: 6px;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-header hr {
  left: 8px;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event .cs-event-inner .cs-event-body {
  overflow: hidden;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event:hover {
  cursor: pointer;
  background: #fdfdfd;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event:hover .cs-event-header {
  background: #ffffff;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event:hover .cs-event-header .cs-event-header-time {
  background: #ffffff;
}
.diary-bar-section#outstanding-jobs-wrap .nearby-events-list .cs-event:hover .cs-event-body {
  background: #fdfdfd;
}
.small-section-title {
  float: left;
  width: 100%;
  text-align: center !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #505b65;
  background: #fbfbfb;
  padding: 3px 15px;
  text-align: left !important;
  border-top: 1px solid #c0c4c7;
  border-bottom: 1px solid #c0c4c7;
  background: -webkit-linear-gradient(rgba(189, 197, 203, 0.3), rgba(189, 197, 203, 0.4));
}
.small-section-title.header-for-today {
  background: #ea9b70;
  color: #fafafa;
  font-weight: 100;
  border-color: #ea9b70;
}
.ticket-wrapper {
  float: left;
  width: 210px;
  border-right: 1px solid rgba(208, 208, 208, 0.7);
  font-size: 13px;
  -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
}
.ticket-wrapper .ticket-inner {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ticket-wrapper .ticket-inner.selected {
  -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
}
.ticket-wrapper .ticket-header,
.ticket-wrapper .ticket-body,
.ticket-wrapper .previous-visit {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ticket-wrapper .ticket-header {
  padding: 6px 7px 3px 7px;
  border-top: 1px solid rgba(208, 208, 208, 0.7);
}
.ticket-wrapper .ticket-header input {
  float: left;
  margin-top: 2px;
  margin-right: 7px;
}
.ticket-wrapper .ticket-header p {
  float: left;
  line-height: 18px;
}
.ticket-wrapper .ticket-header a {
  float: left;
  margin: 0 5px 0 0;
  font-weight: bold;
  font-size: 12px;
}
.ticket-wrapper .ticket-header span {
  float: left;
  width: 154px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-wrapper .ticket-body,
.ticket-wrapper .previous-visit {
  padding: 7px 6px 6px 8px;
  background: #fdfdfd;
  border-bottom: 1px solid rgba(208, 208, 208, 0.7);
  line-height: 18px;
}
.ticket-wrapper p {
  margin: 0;
}
.ticket-wrapper .image_wrapper {
  margin: 0;
}
.ticket-wrapper.ui-draggable-dragging {
  opacity: 0.8;
}
#outstanding-jobs-wrap .ticket-wrapper {
  margin: 0 0 10px 0;
}
#outstanding-jobs-wrap .ticket-wrapper.being-dragged {
  opacity: .3;
}
#outstanding-jobs-wrap .ticket-header {
  cursor: move;
}
#outstanding-jobs-wrap .previous-visit {
  margin: -1px 0 0 0;
  border-top: 1px solid #f4f4f4;
  background: #fafafa;
}
#outstanding-jobs-wrap .previous-visit .image_wrapper {
  margin: 0 8px 0 0;
}
#outstanding-jobs-wrap .previous-visit .engineer-feedback {
  position: relative;
  margin: 6px 0 0 0;
}
#outstanding-jobs-wrap .previous-visit .engineer-feedback .feedback-text {
  padding: 0 0 0 14px;
  float: left;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
#outstanding-jobs-wrap .previous-visit .engineer-feedback .ss-quote {
  position: absolute;
  font-size: 9px;
  color: rgba(0, 0, 0, 0.3);
}
#outstanding-jobs-wrap .previous-visit .engineer-feedback .ss-quote.opening {
  top: -2px;
}
#outstanding-jobs-wrap .previous-visit .engineer-feedback .ss-quote.closing {
  bottom: -18px;
  right: 4px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ticket-inner {
  border-left: 3px solid transparent;
}
.ticket-inner .ticket-header {
  border-bottom: 1px solid transparent;
}
.ticket-inner.no_access {
  border-left-color: #d25656;
}
.ticket-inner.no_access .ticket-header {
  background: #fbefef;
  border-bottom-color: #f8e3e3;
}
.ticket-inner.parts_ready {
  border-left-color: #6393b5;
}
.ticket-inner.parts_ready .ticket-header {
  background: #edf3f7;
  border-bottom-color: #e0e9f0;
}
.ticket-inner.without_events {
  border-left-color: #746ca6;
}
.ticket-inner.without_events .ticket-header {
  background: #f2f1f7;
  border-bottom-color: #e9e8f1;
}
.ticket-inner.with_todos {
  border-left-color: #60a369;
}
.ticket-inner.with_todos .ticket-header {
  background: #f5f9f5;
  border-bottom-color: #e8f2e9;
}
.ticket-inner p.outstanding-address {
  margin: 2px 0 0 0;
}
#side-panel.add-new-diary-event.without-side-gutters #page-panel-main {
  padding: 0;
  overflow: hidden;
}
#side-panel.add-new-diary-event.with-help-text .help_section {
  width: 500px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#side-panel.add-new-diary-event .panel-loading {
  width: 100%;
  overflow: hidden;
}
#side-panel.add-new-diary-event .page-panel-actions {
  padding-right: 0px;
}
#side-panel.add-new-diary-event div.required-block .required_field {
  z-index: 1;
  position: relative;
}
#side-panel.add-new-diary-event .days-of-week-checkboxes label {
  float: left;
  padding: 0;
  margin: 0 12px 10px 22px;
  cursor: pointer;
}
#side-panel.add-new-diary-event .purple-select-btn-group {
  width: 500px;
  margin-left: 3px;
  padding: 20px;
  background: #f7faff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
#side-panel.add-new-diary-event .purple-select-btn-group .btn {
  width: 20%;
  padding: 8px 0;
}
#side-panel.add-new-diary-event .purple-select-btn-group .btn:nth-child(3) {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
#side-panel.add-new-diary-event .block-label.floated {
  float: left;
}
#side-panel.add-new-diary-event .phone-widget-wrapper {
  float: left;
  clear: both;
  margin-bottom: 10px;
}
#side-panel.add-new-diary-event .phone-widget-wrapper .phone-number-input,
#side-panel.add-new-diary-event .phone-widget-wrapper span {
  margin-bottom: 0;
}
#side-panel.add-new-diary-event .postcode-lookup-form {
  position: absolute;
  top: 41px;
  bottom: 69px;
  left: 0px;
  right: 50px;
  overflow: auto;
  padding: 0px 10px;
  background: #f9f9f9;
}
.diary-step {
  float: left;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background: #f9f9f9;
  z-index: 2;
}
.diary-step .step-title {
  height: 44px;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #c0c4c7;
  background: -webkit-linear-gradient(rgba(189, 197, 203, 0.3), rgba(189, 197, 203, 0.4));
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.diary-step .step-title .ss-navigateright {
  float: left;
  margin: 13px 6px 0 -12px;
}
.diary-step .step-title .small-count {
  margin: 7px 0 0 0;
  border-color: #6e7a8b;
}
.diary-step .step-title strong {
  float: left;
  margin: 11px 0 0 10px;
  color: rgba(0, 0, 0, 0.55);
}
.diary-step .step-content {
  position: absolute;
  z-index: 2;
  top: 44px;
  bottom: 0px;
  overflow: auto;
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  background: #f9f9f9;
}
.diary-step .step-content .information-bar {
  margin: 0 0 10px 0 !important;
}
.diary-step .step-content .information-bar span {
  margin-bottom: 0px !important;
}
.diary-step:nth-child(1) {
  top: 80px;
}
.diary-step:nth-child(1).in-normal-event-type {
  bottom: 44px;
}
.diary-step:nth-child(1).in-estimate-event-type,
.diary-step:nth-child(1).in-job-event-type, .diary-step:nth-child(1).in-opportunity-event-type {
  bottom: 132px;
}
.diary-step:nth-child(1).in-rebook-event-type,
.diary-step:nth-child(1).in-reserve_list-event-type {
  bottom: 88px;
}
.diary-step:nth-child(1) .property-under-step-title {
  margin: -11px 0 0 45px;
}
.diary-step:nth-child(1).with-selected-property {
  background: #ffffff;
}
.diary-step:nth-child(1) .selected-property {
  margin-top: -14px;
  margin-left: 35px;
}
.diary-step:nth-child(1) .pretty-list-with-search li.property-item {
  transition: opacity 0.15s ease-in;
}
.diary-step:nth-child(1) .pretty-list-with-search.blurred-out {
  pointer-events: none;
}
.diary-step:nth-child(1) .pretty-list-with-search.blurred-out .app-filter {
  opacity: .5;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}
.diary-step:nth-child(1) .pretty-list-with-search.blurred-out ul {
  background: #f9f9f9;
  border-color: rgba(218, 218, 218, 0.2);
}
.diary-step:nth-child(1) .pretty-list-with-search.blurred-out li {
  opacity: .15;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  transition: opacity 0.15s ease-out;
}
.diary-step:nth-child(1) .pretty-list-with-search.blurred-out li.selected-property {
  opacity: 1;
  filter: blur(0);
  -webkit-filter: blur(0);
}
.diary-step:nth-child(1) .pretty-list-with-search.blurred-out li.selected-property input[type="radio"] {
  -webkit-transition: transform 0.2s ease-in;
  -moz-transition: transform 0.2s ease-in;
  -o-transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.diary-step:nth-child(1) .pretty-list-with-search li.selected-property {
  position: fixed;
  top: 165px !important;
  z-index: 1;
  right: 18px;
  width: 462px;
  border: 1px solid #dadada !important;
  -webkit-transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.diary-step:nth-child(2),
.diary-step:nth-child(3),
.diary-step:nth-child(4) {
  top: 100%;
  -webkit-transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.diary-step:nth-child(2) {
  bottom: 88px;
  margin-top: -132px;
}
.diary-step:nth-child(2).step-open {
  top: 308px;
}
.diary-step:nth-child(2).step-open.below-work-address {
  top: 327px;
}
.diary-step:nth-child(2).close-step {
  top: 100% !important;
}
.diary-step:nth-child(3) {
  bottom: 44px;
  margin-top: -88px;
}
.diary-step:nth-child(3).step-open {
  top: 307px;
}
.diary-step:nth-child(3).step-open.below-work-address {
  top: 326px;
}
.diary-step:nth-child(4) {
  bottom: 0px;
  margin-top: -44px;
}
.diary-step:nth-child(4).step-open {
  top: 306px;
}
.diary-step:nth-child(4).step-open.below-work-address {
  top: 325px;
}
.diary-step.step-open .step-title {
  border-bottom: 1px solid #c0c4c7;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.06);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.06);
  opacity: 1;
}
.diary-step.step-open .step-content {
  opacity: 1;
}
.diary-step.close-step .step-title {
  border-bottom: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: opacity 0.3s 0s ease-out;
  -moz-transition: opacity 0.3s 0s ease-out;
  -o-transition: opacity 0.3s 0s ease-out;
  transition: opacity 0.3s 0s ease-out;
  opacity: .5;
}
.diary-step.close-step .step-content {
  -webkit-transition: opacity 0.3s 0s ease-out;
  -moz-transition: opacity 0.3s 0s ease-out;
  -o-transition: opacity 0.3s 0s ease-out;
  transition: opacity 0.3s 0s ease-out;
  opacity: 0;
}
.diary-step.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.diary-step.disabled .step-title {
  opacity: .5;
}
.diary-step.disabled .step-content {
  opacity: 0;
}
.diary-step.enabled {
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  cursor: default;
  pointer-events: initial;
}
.diary-step.enabled .step-title {
  opacity: 1;
}
.diary-step.enabled.step-open .step-content {
  opacity: 1;
}
.normal-steps .diary-step:nth-child(2) {
  bottom: 0px;
  margin-top: -44px;
}
.normal-steps .diary-step:nth-child(2).step-open {
  top: 167px;
}
.rebook-or-reserve-list-steps .diary-step:nth-child(2) {
  bottom: 44px;
  margin-top: -88px;
}
.rebook-or-reserve-list-steps .diary-step:nth-child(2).step-open {
  top: 263px;
}
.rebook-or-reserve-list-steps .diary-step:nth-child(2).step-open.below-work-address {
  top: 282px;
}
.rebook-or-reserve-list-steps .diary-step:nth-child(3) {
  bottom: 0px;
  margin-top: -44px;
}
.rebook-or-reserve-list-steps .diary-step:nth-child(3).step-open {
  top: 262px;
}
.rebook-or-reserve-list-steps .diary-step:nth-child(3).step-open.below-work-address {
  top: 281px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(1) {
  top: 0px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(1) .step-title {
  border-top: 0;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(2).step-open {
  top: 228px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(2).step-open.below-work-address {
  top: 247px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(3).step-open {
  top: 227px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(3).step-open.below-work-address {
  top: 246px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(4).step-open {
  top: 226px;
}
.add-new-diary-event.with-event-from-previous-page .diary-step:nth-child(4).step-open.below-work-address {
  top: 245px;
}
.cs-event {
  position: absolute;
  min-height: 19px;
  min-width: 19px;
  border: 1px solid #cccccc;
  font-size: 13px;
  white-space: nowrap;
  background: #fdfdfd;
  -webkit-box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.05);
  box-shadow: -1px 1px 1px 0px rgba(0, 0, 0, 0.05);
    border-radius: unset;
    overflow: visible !important;
}
.cs-event p {
  margin: 0;
}
.cs-event-position {
    position: absolute
}

.cs-event .cs-event-inner {
    float: left;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.cs-event .cs-event-inner:before {
  left: 0px;
}
.cs-event .cs-event-inner:after {
  top: 40px;
}

.cs-event .cs-event-body {
  float: left;
  width: 100%;
  padding: 6px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cs-event .cs-event-body > p {
  padding: 0 20px;
  white-space: normal;
}
.cs-event .cs-event-body .property-item .property-details {
  font-size: 12px;
}
.cs-event .cs-event-body .cs-event-body-section {
  clear: both;
}
.cs-event .cs-event-body .property-item .ss-buildings:hover {
  cursor: move;
}
.cs-event .cs-event-body .property-item .property-details {
  margin: 0 0 0 20px;
}
.cs-event .cs-event-feedback-section {
  clear: both;
  position: relative;
  padding: 12px;
  border-top: 1px solid #f9f9f9;
}
.cs-event .cs-event-feedback-section .ss-quote {
  position: absolute;
  font-size: 9px;
  color: rgba(0, 0, 0, 0.3);
}
.cs-event .cs-event-feedback-section .ss-quote.opening {
  top: 6px;
  left: 20px;
}
.cs-event .cs-event-feedback-section .ss-quote.closing {
  right: 20px;
  bottom: 6px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cs-event .cs-event-feedback-section p {
  margin: 0 26px;
  font-weight: bold;
  color: #636f76;
}
.cs-event .cs-event-resizer {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  -webkit-transform: translate(0px, 10px);
  -moz-transform: translate(0px, 10px);
  -ms-transform: translate(0px, 10px);
  -o-transform: translate(0px, 10px);
  transform: translate(0px, 10px);
  -webkit-transition: transform 0.12s ease-out;
  -moz-transition: transform 0.12s ease-out;
  -o-transition: transform 0.12s ease-out;
  transition: transform 0.12s ease-out;
}
.cs-event .cs-event-resizer:after {
  position: absolute;
  content: ' ';
  top: 7px;
  left: 50%;
  width: 20px;
  height: 3px;
  margin-left: -10px;
  background: rgba(0, 0, 0, 0.6);
}
.cs-event .cs-event-resizer:hover {
  cursor: s-resize;
}
.cs-event:hover {
  z-index: 999 !important;
  cursor: move;
  background: #f7f7f7;
}
.cs-event:hover .cs-event-inner:before,
.cs-event:hover .cs-event-inner:after {
  -webkit-box-shadow: 0px 0px 10px 3px #f7f7f7;
  -moz-box-shadow: 0px 0px 10px 3px #f7f7f7;
  box-shadow: 0px 0px 10px 3px #f7f7f7;
}
.cs-event:hover .cs-event-resizer {
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.cs-event.with-static-height {
  height: 19px;
  position: relative;
}
.cs-event.greyed-out {
  opacity: .5;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.cs-event  .highlight_event,section.cs-event.highlight_event {
  background: #fff6d9;
}
.cs-event .highlight_event .cs-event-header,section .cs-event.highlight_event .cs-event-header{
  background: #fff6d9 !important;
}
.cs-event .highlight_event .cs-event-header-time,section .cs-event.highlight_event .cs-event-header-time{
  background: #fff6d9 !important;
}
.cs-event .highlight_event:hover .cs-event-body,section .cs-event.highlight_event:hover .cs-event-body{
  background: #fff6d9 !important;
}
.cs-event-header {
  position: relative;
  height: 40px;
  float: left;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
}
/*.cs-event-header:before,
.cs-event-header:after {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  content: ' ';
  z-index: 2;
  -webkit-box-shadow: 0px 0px 10px 3px #ffffff;
  -moz-box-shadow: 0px 0px 10px 3px #ffffff;
  box-shadow: 0px 0px 10px 3px #ffffff;
}*/
.cs-event-header .cs-event-header-upper {
  float: left;
  width: 100%;
}
.cs-event-header .cs-event-header-upper .cs-event-header-event-type {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  text-align: center;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: bold;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cs-event-header .cs-event-header-upper .cs-event-header-event-type.selection-placeholder {
  background: #eeeeee;
  border-color: #c8c8c8;
}
.cs-event-header .cs-event-header-upper .cs-event-header-event-type.normal {
  background: #f7f2f1;
  color: #945f55;
  border-color: #dbc5c0;
}
.cs-event-header .cs-event-header-upper .cs-event-header-event-type.estimate {
  background: #eef4f7;
  color: #528fae;
  border-color: #bad2df;
}
.cs-event-header .cs-event-header-upper .cs-event-header-event-type.job {
  background: #f5f6f1;
  color: #6c744b;
  border-color: #c9ceb3;
}
.cs-event-header .cs-event-header-upper .cs-event-header-time {
  position: absolute;
  z-index: 1;
  left: 20px;
  padding: 0 0 0 6px;
  font-size: 11px;
  font-weight: bold;
}
.cs-event-header .cs-event-header-upper .cs-event-header-text {
  position: absolute;
  left: 98px;
  right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  font-weight: bold;
  color: rgba(79, 91, 100, 0.86);
}
.cs-event-header .cs-event-header-upper .diary-event-status {
    float: right;
    margin: 0px 1px -3px 0;
    position: relative;
    z-index: 4;
    padding: 1px 6px 0 0;
    height: 18px;
}
.cs-event-header .cs-event-header-lower {
  float: left;
  width: 100%;
}
.cs-event-header .cs-event-header-lower .cs-event-header-user .ss-user,
.cs-event-header .cs-event-header-lower .cs-event-header-user img {
  position: absolute;
  z-index: 2;
  top: 21px;
  left: 0;
  width: 20px;
  height: 20px;
  margin: -1px 6px 0 0;
  background: #e6e3e3;
}
.cs-event-header .cs-event-header-lower .cs-event-header-user .ss-user {
  float: left;
  width: 20px;
  height: 18px;
  padding: 2px 0 0 0;
  text-align: center;
  font-size: 12px;
}
.cs-event-header .cs-event-header-lower .cs-event-header-user span:last-of-type {
  position: absolute;
  top: 19px;
  left: 26px;
}
.cs-event-header .cs-event-header-lower .cs-event-header-status {
  position: absolute;
  top: 12px;
  left: 26px;
}
.cs-event-header hr {
  position: absolute;
  top: 40px;
  right: 6px;
  left: 27px;
  width: auto;
  margin: 0;
  border-top-color: #e7e7e7;
  border-bottom-color: transparent;
}
.estimate-status,
.job-status {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.estimate-status.with-abs-pos,
.job-status.with-abs-pos {
    position: absolute;
    z-index: 6;
    top: -3px;
    left: -3px;
}
.estimate-status.accepted,
.job-status.accepted {
  background: #60a369;
}
.estimate-status.free-of-charged,
.job-status.free-of-charged {
  background: #d4a93c;
}
.estimate-status.rejected,
.job-status.rejected {
  background: #d25656;
}
.cs-event-estimate-or-job-status {
  position: relative;
  padding: 0 0 0 12px;
  font-size: 12px;
}
.cs-event-estimate-or-job-status .estimate-status {
  position: absolute;
  top: 3px;
  left: 0;
}
.diary-in-daily-view .cs-event .cs-event-header {
  height: 20px;
}
.diary-in-daily-view .cs-event .cs-event-header .cs-event-header-lower {
  display: none;
}
.diary-in-daily-view .cs-event .cs-event-header hr {
  top: 20px;
}
.cs-expanded-view {
  width: 400px;
  white-space: initial;
  -webkit-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
}
.cs-expanded-view .cs-event-inner {
  overflow: visible;
    position: relative;
}
.cs-expanded-view .cs-event-inner:before,
.cs-expanded-view .cs-event-inner:after {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.cs-expanded-view .cs-event-header .cs-event-header-upper .cs-event-header-time {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.cs-expanded-view .cs-event-header .cs-event-header-upper i {
  position: absolute;
  right: 24px;
}
.cs-expanded-view .cs-event-body {
  padding: 8px 0 0;
}
.cs-expanded-view .cs-event-body > p:last-of-type {
  padding-bottom: 8px;
}
.cs-expanded-view .cs-event-body .property-item .image_wrapper {
  margin-left: 20px;
}
.cs-expanded-view .cs-event-body .property-item .property-details {
  margin: 0;
}
.cs-expanded-view .cs-event-body .property-item .property-details .ss-buildings:hover {
  cursor: help;
}
.cs-expanded-view .cs-event-body .cs-event-body-section {
  float: left;
  width: 100%;
  padding: 6px 0 0 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
}
.cs-expanded-view .cs-event-body .cs-event-body-section.with-top-border {
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid rgba(238, 238, 238, 0.3);
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-header {
  position: relative;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-header strong,
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-header .group-seporator {
  position: absolute;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-header strong {
  z-index: 2;
  background: #ffffff;
  padding: 0 6px 0 0;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-header .group-seporator {
  z-index: 1;
  top: 10px;
  left: 2px;
  right: 6px;
  height: 1px;
  background: #eeeeee;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-content {
  float: left;
  margin: 26px 0 0 0;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-content .field .field-label {
  margin: 0 0 0 6px;
  width: 110px;
  font-weight: normal;
  color: rgba(80, 91, 101, 0.76);
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-content .field .field-label + span {
  width: 234px;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-content .field .estimate-status,
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-content .field .job-status {
  float: left;
  min-height: 8px;
  margin: 5px 4px 0 0;
}
.cs-expanded-view .cs-event-body .cs-event-body-section .cs-event-body-section-content .field .priority-indicator {
  min-height: initial;
  margin: 5px 6px 0 0;
}
.cs-expanded-view:hover {
  cursor: default;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.cs-expanded-view:hover .cs-event-header {
  background: #ffffff;
}
.cs-expanded-view:hover .cs-event-header .cs-event-header-time {
  background: #ffffff;
}
.cs-expanded-view.greyed-out {
  opacity: 1;
}
.contact-number .ss-phone {
  color: #959ba6;
}
.contact-number.under-name {
  display: block;
  margin: 0 0 10px 136px;
}
.diary-event-status.ss-like,
.diary-event-status.ss-home,
.diary-event-status.ss-check {
  color: #60a369;
}
.diary-event-status.ss-like + i,
.diary-event-status.ss-home + i,
.diary-event-status.ss-check + i {
  color: #60a369;
}
.diary-event-status.ss-dislike,
.diary-event-status.ss-caution,
.diary-event-status.ss-delete {
  color: #b95858;
}
.diary-event-status.ss-dislike + i,
.diary-event-status.ss-caution + i,
.diary-event-status.ss-delete + i {
  color: #b95858;
}
.diary-event-status.ss-ban {
  color: #d4a93c;
}
.diary-event-status.ss-ban + i {
  color: #d4a93c;
}
.diary-event-status.ss-navigate {
  color: #6393b5;
}
.diary-event-status.ss-navigate + i {
  color: #6393b5;
}
.diary-event-label.accept,
.diary-event-label.arrive,
.diary-event-label.leave {
  background: #60a369;
}
.diary-event-label.reject,
.diary-event-label.abort,
.diary-event-label.cancel {
  background: #b95858;
}
.diary-event-label.no-access {
  background: #d4a93c;
}
.diary-event-label.travel {
  background: #6393b5;
}
.daily-event-customer-mobile{
    margin: 0px 15px 0px 136px !important;
}

.priority-indicator {
  float: left;
  width: 10px;
  height: 10px;
}
.priority-indicator.not-important {
  background: #f2e09f;
}
.priority-indicator.medium-importance {
  background: #ffb77e;
}
.priority-indicator.important {
  background: #ff8383;
}
.priority-indicator.urgent {
  background: #d25656;
}
@media screen and (max-width: 1350px) {
  .cs-event {
    font-size: 12px;
  }
  .cs-event:not(.cs-expanded-view) .cs-event-body > p {
    padding: 0 10px;
  }
  .cs-event:not(.cs-expanded-view) .cs-event-body .property-item .property-details {
    margin: 0 0 0 10px;
  }
  .cs-expanded-view .cs-event-body-section-content .field span {
    margin-bottom: 1px;
  }
}
.cs-event.cs-event-colour-1,
.view-controls .engineers li.cs-event-colour-1 {
  border-color: #184167;
  background: #eaf3fa;
}
.cs-event.cs-event-colour-1 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-1 .cs-event-header-event-type {
    background: #97c1e7 !important;
    color: #184167 !important;
    border-color: #184167 !important;
}
.cs-event.cs-event-colour-1 .cs-event-header,
.view-controls .engineers li.cs-event-colour-1 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-1 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-1 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #eaf3fa;
}
.cs-event.cs-event-colour-1 .image_wrapper,
.view-controls .engineers li.cs-event-colour-1 .image_wrapper,
.cs-event.cs-event-colour-1 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-1 .image_wrapper .avatar {
  background: #97c1e7;
}
.cs-event.cs-event-colour-2,
.view-controls .engineers li.cs-event-colour-2 {
  border-color: #673e18;
  background: #faf2ea;
}
.cs-event.cs-event-colour-2 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-2 .cs-event-header-event-type {
    background: #e7bd97 !important;
    color: #673e18 !important;
    border-color: #673e18 !important;
}
.cs-event.cs-event-colour-2 .cs-event-header,
.view-controls .engineers li.cs-event-colour-2 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-2 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-2 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #faf2ea;
}
.cs-event.cs-event-colour-2 .image_wrapper,
.view-controls .engineers li.cs-event-colour-2 .image_wrapper,
.cs-event.cs-event-colour-2 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-2 .image_wrapper .avatar {
  background: #e7bd97;
}
.cs-event.cs-event-colour-3,
.view-controls .engineers li.cs-event-colour-3 {
  border-color: #671841;
  background: #faeaf3;
}
.cs-event.cs-event-colour-3 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-3 .cs-event-header-event-type {
    background: #e797c1 !important;
    color: #671841 !important;
    border-color: #671841 !important;
}
.cs-event.cs-event-colour-3 .cs-event-header,
.view-controls .engineers li.cs-event-colour-3 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-3 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-3 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #faeaf3;
}
.cs-event.cs-event-colour-3 .image_wrapper,
.view-controls .engineers li.cs-event-colour-3 .image_wrapper,
.cs-event.cs-event-colour-3 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-3 .image_wrapper .avatar {
  background: #e797c1;
}
.cs-event.cs-event-colour-4,
.view-controls .engineers li.cs-event-colour-4 {
  border-color: #3e1867;
  background: #f2eafa;
}
.cs-event.cs-event-colour-4 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-4 .cs-event-header-event-type {
    background: #bd97e7 !important;
    color: #3e1867 !important;
    border-color: #3e1867 !important;
}
.cs-event.cs-event-colour-4 .cs-event-header,
.view-controls .engineers li.cs-event-colour-4 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-4 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-4 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f2eafa;
}
.cs-event.cs-event-colour-4 .image_wrapper,
.view-controls .engineers li.cs-event-colour-4 .image_wrapper,
.cs-event.cs-event-colour-4 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-4 .image_wrapper .avatar {
  background: #bd97e7;
}
.cs-event.cs-event-colour-5,
.view-controls .engineers li.cs-event-colour-5 {
  border-color: #416718;
  background: #f3faea;
}
.cs-event.cs-event-colour-5 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-5 .cs-event-header-event-type {
    background: #c1e797 !important;
    color: #416718 !important;
    border-color: #416718 !important;
}
.cs-event.cs-event-colour-5 .cs-event-header,
.view-controls .engineers li.cs-event-colour-5 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-5 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-5 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f3faea;
}
.cs-event.cs-event-colour-5 .image_wrapper,
.view-controls .engineers li.cs-event-colour-5 .image_wrapper,
.cs-event.cs-event-colour-5 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-5 .image_wrapper .avatar {
  background: #c1e797;
}
.cs-event.cs-event-colour-6,
.view-controls .engineers li.cs-event-colour-6 {
  border-color: #236199;
  background: #f4f9fc;
}
.cs-event.cs-event-colour-6 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-6 .cs-event-header-event-type {
    background: #c9dff2 !important;
    color: #236199 !important;
    border-color: #236199 !important;
}
.cs-event.cs-event-colour-6 .cs-event-header,
.view-controls .engineers li.cs-event-colour-6 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-6 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-6 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f4f9fc;
}
.cs-event.cs-event-colour-6 .image_wrapper,
.view-controls .engineers li.cs-event-colour-6 .image_wrapper,
.cs-event.cs-event-colour-6 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-6 .image_wrapper .avatar {
  background: #c9dff2;
}
.cs-event.cs-event-colour-7,
.view-controls .engineers li.cs-event-colour-7 {
  border-color: #995b23;
  background: #fcf8f4;
}
.cs-event.cs-event-colour-7 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-7 .cs-event-header-event-type {
    background: #f2ddc9 !important;
    color: #995b23 !important;
    border-color: #995b23 !important;
}
.cs-event.cs-event-colour-7 .cs-event-header,
.view-controls .engineers li.cs-event-colour-7 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-7 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-7 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fcf8f4;
}
.cs-event.cs-event-colour-7 .image_wrapper,
.view-controls .engineers li.cs-event-colour-7 .image_wrapper,
.cs-event.cs-event-colour-7 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-7 .image_wrapper .avatar {
  background: #f2ddc9;
}
.cs-event.cs-event-colour-8,
.view-controls .engineers li.cs-event-colour-8 {
  border-color: #992361;
  background: #fcf4f9;
}
.cs-event.cs-event-colour-8 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-8 .cs-event-header-event-type {
    background: #f2c9df !important;
    color: #992361 !important;
    border-color: #992361 !important;
}
.cs-event.cs-event-colour-8 .cs-event-header,
.view-controls .engineers li.cs-event-colour-8 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-8 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-8 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fcf4f9;
}
.cs-event.cs-event-colour-8 .image_wrapper,
.view-controls .engineers li.cs-event-colour-8 .image_wrapper,
.cs-event.cs-event-colour-8 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-8 .image_wrapper .avatar {
  background: #f2c9df;
}
.cs-event.cs-event-colour-9,
.view-controls .engineers li.cs-event-colour-9 {
  border-color: #5b2399;
  background: #f8f4fc;
}
.cs-event.cs-event-colour-9 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-9 .cs-event-header-event-type {
    background: #ddc9f2 !important;
    color: #5b2399 !important;
    border-color: #5b2399 !important;
}
.cs-event.cs-event-colour-9 .cs-event-header,
.view-controls .engineers li.cs-event-colour-9 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-9 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-9 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f8f4fc;
}
.cs-event.cs-event-colour-9 .image_wrapper,
.view-controls .engineers li.cs-event-colour-9 .image_wrapper,
.cs-event.cs-event-colour-9 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-9 .image_wrapper .avatar {
  background: #ddc9f2;
}
.cs-event.cs-event-colour-10,
.view-controls .engineers li.cs-event-colour-10 {
  border-color: #619923;
  background: #f9fcf4;
}
.cs-event.cs-event-colour-10 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-10 .cs-event-header-event-type {
    background: #dff2c9 !important;
    color: #619923 !important;
    border-color: #619923 !important;
}
.cs-event.cs-event-colour-10 .cs-event-header,
.view-controls .engineers li.cs-event-colour-10 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-10 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-10 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f9fcf4;
}
.cs-event.cs-event-colour-10 .image_wrapper,
.view-controls .engineers li.cs-event-colour-10 .image_wrapper,
.cs-event.cs-event-colour-10 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-10 .image_wrapper .avatar {
  background: #dff2c9;
}
.cs-event.cs-event-colour-11,
.view-controls .engineers li.cs-event-colour-11 {
  border-color: #1f5788;
  background: #f1f7fc;
}
.cs-event.cs-event-colour-11 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-11 .cs-event-header-event-type {
    background: #b8d5ef !important;
    color: #1f5788 !important;
    border-color: #1f5788 !important;
}
.cs-event.cs-event-colour-11 .cs-event-header,
.view-controls .engineers li.cs-event-colour-11 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-11 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-11 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f1f7fc;
}
.cs-event.cs-event-colour-11 .image_wrapper,
.view-controls .engineers li.cs-event-colour-11 .image_wrapper,
.cs-event.cs-event-colour-11 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-11 .image_wrapper .avatar {
  background: #b8d5ef;
}
.cs-event.cs-event-colour-12,
.view-controls .engineers li.cs-event-colour-12 {
  border-color: #88511f;
  background: #fcf6f1;
}
.cs-event.cs-event-colour-12 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-12 .cs-event-header-event-type {
    background: #efd2b8 !important;
    color: #88511f !important;
    border-color: #88511f !important;
}
.cs-event.cs-event-colour-12 .cs-event-header,
.view-controls .engineers li.cs-event-colour-12 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-12 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-12 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fcf6f1;
}
.cs-event.cs-event-colour-12 .image_wrapper,
.view-controls .engineers li.cs-event-colour-12 .image_wrapper,
.cs-event.cs-event-colour-12 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-12 .image_wrapper .avatar {
  background: #efd2b8;
}
.cs-event.cs-event-colour-13,
.view-controls .engineers li.cs-event-colour-13 {
  border-color: #881f57;
  background: #fcf1f7;
}
.cs-event.cs-event-colour-13 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-13 .cs-event-header-event-type {
    background: #efb8d5 !important;
    color: #881f57 !important;
    border-color: #881f57 !important;
}
.cs-event.cs-event-colour-13 .cs-event-header,
.view-controls .engineers li.cs-event-colour-13 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-13 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-13 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fcf1f7;
}
.cs-event.cs-event-colour-13 .image_wrapper,
.view-controls .engineers li.cs-event-colour-13 .image_wrapper,
.cs-event.cs-event-colour-13 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-13 .image_wrapper .avatar {
  background: #efb8d5;
}
.cs-event.cs-event-colour-14,
.view-controls .engineers li.cs-event-colour-14 {
  border-color: #511f88;
  background: #f6f1fc;
}
.cs-event.cs-event-colour-14 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-14 .cs-event-header-event-type {
    background: #d2b8ef !important;
    color: #511f88 !important;
    border-color: #511f88 !important;
}
.cs-event.cs-event-colour-14 .cs-event-header,
.view-controls .engineers li.cs-event-colour-14 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-14 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-14 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f6f1fc;
}
.cs-event.cs-event-colour-14 .image_wrapper,
.view-controls .engineers li.cs-event-colour-14 .image_wrapper,
.cs-event.cs-event-colour-14 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-14 .image_wrapper .avatar {
  background: #d2b8ef;
}
.cs-event.cs-event-colour-15,
.view-controls .engineers li.cs-event-colour-15 {
  border-color: #57881f;
  background: #f7fcf1;
}
.cs-event.cs-event-colour-15 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-15 .cs-event-header-event-type {
    background: #d5efb8 !important;
    color: #57881f !important;
    border-color: #57881f !important;
}
.cs-event.cs-event-colour-15 .cs-event-header,
.view-controls .engineers li.cs-event-colour-15 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-15 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-15 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f7fcf1;
}
.cs-event.cs-event-colour-15 .image_wrapper,
.view-controls .engineers li.cs-event-colour-15 .image_wrapper,
.cs-event.cs-event-colour-15 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-15 .image_wrapper .avatar {
  background: #d5efb8;
}
.cs-event.cs-event-colour-16,
.view-controls .engineers li.cs-event-colour-16 {
  border-color: #1c4c78;
  background: #eef5fb;
}
.cs-event.cs-event-colour-16 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-16 .cs-event-header-event-type {
    background: #a8cbeb !important;
    color: #1c4c78 !important;
    border-color: #1c4c78 !important;
}
.cs-event.cs-event-colour-16 .cs-event-header,
.view-controls .engineers li.cs-event-colour-16 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-16 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-16 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #eef5fb;
}
.cs-event.cs-event-colour-16 .image_wrapper,
.view-controls .engineers li.cs-event-colour-16 .image_wrapper,
.cs-event.cs-event-colour-16 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-16 .image_wrapper .avatar {
  background: #a8cbeb;
}
.cs-event.cs-event-colour-17,
.view-controls .engineers li.cs-event-colour-17 {
  border-color: #78471c;
  background: #fbf4ee;
}
.cs-event.cs-event-colour-17 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-17 .cs-event-header-event-type {
    background: #ebc8a8 !important;
    color: #78471c !important;
    border-color: #78471c !important;
}
.cs-event.cs-event-colour-17 .cs-event-header,
.view-controls .engineers li.cs-event-colour-17 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-17 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-17 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fbf4ee;
}
.cs-event.cs-event-colour-17 .image_wrapper,
.view-controls .engineers li.cs-event-colour-17 .image_wrapper,
.cs-event.cs-event-colour-17 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-17 .image_wrapper .avatar {
  background: #ebc8a8;
}
.cs-event.cs-event-colour-18,
.view-controls .engineers li.cs-event-colour-18 {
  border-color: #781c4c;
  background: #fbeef5;
}
.cs-event.cs-event-colour-18 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-18 .cs-event-header-event-type {
    background: #eba8cb !important;
    color: #781c4c !important;
    border-color: #781c4c !important;
}
.cs-event.cs-event-colour-18 .cs-event-header,
.view-controls .engineers li.cs-event-colour-18 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-18 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-18 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fbeef5;
}
.cs-event.cs-event-colour-18 .image_wrapper,
.view-controls .engineers li.cs-event-colour-18 .image_wrapper,
.cs-event.cs-event-colour-18 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-18 .image_wrapper .avatar {
  background: #eba8cb;
}
.cs-event.cs-event-colour-19,
.view-controls .engineers li.cs-event-colour-19 {
  border-color: #471c78;
  background: #f4eefb;
}
.cs-event.cs-event-colour-19 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-19 .cs-event-header-event-type {
    background: #c8a8eb !important;
    color: #471c78 !important;
    border-color: #471c78 !important;
}
.cs-event.cs-event-colour-19 .cs-event-header,
.view-controls .engineers li.cs-event-colour-19 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-19 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-19 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f4eefb;
}
.cs-event.cs-event-colour-19 .image_wrapper,
.view-controls .engineers li.cs-event-colour-19 .image_wrapper,
.cs-event.cs-event-colour-19 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-19 .image_wrapper .avatar {
  background: #c8a8eb;
}
.cs-event.cs-event-colour-20,
.view-controls .engineers li.cs-event-colour-20 {
  border-color: #4c781c;
  background: #f5fbee;
}
.cs-event.cs-event-colour-20 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-20 .cs-event-header-event-type {
    background: #cbeba8 !important;
    color: #4c781c !important;
    border-color: #4c781c !important;
}
.cs-event.cs-event-colour-20 .cs-event-header,
.view-controls .engineers li.cs-event-colour-20 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-20 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-20 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f5fbee;
}
.cs-event.cs-event-colour-20 .image_wrapper,
.view-controls .engineers li.cs-event-colour-20 .image_wrapper,
.cs-event.cs-event-colour-20 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-20 .image_wrapper .avatar {
  background: #cbeba8;
}
.cs-event.cs-event-colour-21,
.view-controls .engineers li.cs-event-colour-21 {
  border-color: #276ca9;
  background: #f7fbfd;
}
.cs-event.cs-event-colour-21 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-21 .cs-event-header-event-type {
    background: #d9e9f6 !important;
    color: #276ca9 !important;
    border-color: #276ca9 !important;
}
.cs-event.cs-event-colour-21 .cs-event-header,
.view-controls .engineers li.cs-event-colour-21 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-21 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-21 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #f7fbfd;
}
.cs-event.cs-event-colour-21 .image_wrapper,
.view-controls .engineers li.cs-event-colour-21 .image_wrapper,
.cs-event.cs-event-colour-21 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-21 .image_wrapper .avatar {
  background: #d9e9f6;
}
.cs-event.cs-event-colour-22,
.view-controls .engineers li.cs-event-colour-22 {
  border-color: #a96527;
  background: #fdfaf7;
}
.cs-event.cs-event-colour-22 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-22 .cs-event-header-event-type {
    background: #f6e7d9 !important;
    color: #a96527 !important;
    border-color: #a96527 !important;
}
.cs-event.cs-event-colour-22 .cs-event-header,
.view-controls .engineers li.cs-event-colour-22 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-22 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-22 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fdfaf7;
}
.cs-event.cs-event-colour-22 .image_wrapper,
.view-controls .engineers li.cs-event-colour-22 .image_wrapper,
.cs-event.cs-event-colour-22 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-22 .image_wrapper .avatar {
  background: #f6e7d9;
}
.cs-event.cs-event-colour-23,
.view-controls .engineers li.cs-event-colour-23 {
  border-color: #a9276c;
  background: #fdf7fb;
}
.cs-event.cs-event-colour-23 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-23 .cs-event-header-event-type {
    background: #f6d9e9 !important;
    color: #a9276c !important;
    border-color: #a9276c !important;
}
.cs-event.cs-event-colour-23 .cs-event-header,
.view-controls .engineers li.cs-event-colour-23 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-23 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-23 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fdf7fb;
}
.cs-event.cs-event-colour-23 .image_wrapper,
.view-controls .engineers li.cs-event-colour-23 .image_wrapper,
.cs-event.cs-event-colour-23 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-23 .image_wrapper .avatar {
  background: #f6d9e9;
}
.cs-event.cs-event-colour-24,
.view-controls .engineers li.cs-event-colour-24 {
  border-color: #6527a9;
  background: #faf7fd;
}
.cs-event.cs-event-colour-24 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-24 .cs-event-header-event-type {
    background: #e7d9f6 !important;
    color: #6527a9 !important;
    border-color: #6527a9 !important;
}
.cs-event.cs-event-colour-24 .cs-event-header,
.view-controls .engineers li.cs-event-colour-24 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-24 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-24 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #faf7fd;
}
.cs-event.cs-event-colour-24 .image_wrapper,
.view-controls .engineers li.cs-event-colour-24 .image_wrapper,
.cs-event.cs-event-colour-24 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-24 .image_wrapper .avatar {
  background: #e7d9f6;
}
.cs-event.cs-event-colour-25,
.view-controls .engineers li.cs-event-colour-25 {
  border-color: #6ca927;
  background: #fbfdf7;
}
.cs-event.cs-event-colour-25 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-25 .cs-event-header-event-type {
    background: #e9f6d9 !important;
    color: #6ca927 !important;
    border-color: #6ca927 !important;
}
.cs-event.cs-event-colour-25 .cs-event-header,
.view-controls .engineers li.cs-event-colour-25 .cs-event-header {
  background: transparent;
}
.cs-event.cs-event-colour-25 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-25 .cs-event-header .cs-event-header-upper .diary-event-status {
  background: #fbfdf7;
}
.cs-event.cs-event-colour-25 .image_wrapper,
.view-controls .engineers li.cs-event-colour-25 .image_wrapper,
.cs-event.cs-event-colour-25 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-25 .image_wrapper .avatar {
  background: #e9f6d9;
}
.cs-event.cs-event-colour-26,
.view-controls .engineers li.cs-event-colour-26 {
    border-color: #7695AF;
    background: #99C2E5;
}

.cs-event.cs-event-colour-26 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-26 .cs-event-header-event-type {
    background: #89AECE !important;
    color: #505b65 !important;
    border-color: #7695AF !important;
}

.cs-event.cs-event-colour-26 .cs-event-header,
.view-controls .engineers li.cs-event-colour-26 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-26 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-26 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #99C2E5;
}

.cs-event.cs-event-colour-26 .image_wrapper,
.view-controls .engineers li.cs-event-colour-26 .image_wrapper,
.cs-event.cs-event-colour-26 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-26 .image_wrapper .avatar {
    background: #89AECE;
}
.cs-event.cs-event-colour-27,
.view-controls .engineers li.cs-event-colour-27 {
    border-color: #B49479;
    background: #E5BC99;
}

.cs-event.cs-event-colour-27 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-27 .cs-event-header-event-type {
    background: #CFAA8B !important;
    color: #505b65 !important;
    border-color: #B49479 !important;
}

.cs-event.cs-event-colour-27 .cs-event-header,
.view-controls .engineers li.cs-event-colour-27 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-27 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-27 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #E5BC99;
}

.cs-event.cs-event-colour-27 .image_wrapper,
.view-controls .engineers li.cs-event-colour-27 .image_wrapper,
.cs-event.cs-event-colour-27 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-27 .image_wrapper .avatar {
    background: #CFAA8B;
}

.cs-event.cs-event-colour-28,
.view-controls .engineers li.cs-event-colour-28 {
    border-color: #A4708B;
    background: #E499C0;
}

.cs-event.cs-event-colour-28 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-28 .cs-event-header-event-type {
    background: #C684A6 !important;
    color: #505b65 !important;
    border-color: #A4708B !important;
}
.cs-event.cs-event-colour-28 .cs-event-header,
.view-controls .engineers li.cs-event-colour-28 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-28 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-28 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #E499C0;
}

.cs-event.cs-event-colour-28 .image_wrapper,
.view-controls .engineers li.cs-event-colour-28 .image_wrapper,
.cs-event.cs-event-colour-28 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-28 .image_wrapper .avatar {
    background: #C684A6;
}
.cs-event.cs-event-colour-29,
.view-controls .engineers li.cs-event-colour-29 {
    border-color: #9AA8B5;
    background: #CADFF1;
}

.cs-event.cs-event-colour-29 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-29 .cs-event-header-event-type {
    background: #BED3E6 !important;
    color: #505b65 !important;
    border-color: #9AA8B5 !important;
}

.cs-event.cs-event-colour-29 .cs-event-header,
.view-controls .engineers li.cs-event-colour-29 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-29 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-29 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #CADFF1;
}

.cs-event.cs-event-colour-29 .image_wrapper,
.view-controls .engineers li.cs-event-colour-29 .image_wrapper,
.cs-event.cs-event-colour-29 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-29 .image_wrapper .avatar {
    background: #BED3E6;
}
.cs-event.cs-event-colour-30,
.view-controls .engineers li.cs-event-colour-30 {
    border-color: #7F699B;
    background: #BB9AE5;
}

.cs-event.cs-event-colour-30 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-30 .cs-event-header-event-type {
    background: #AB8CD2 !important;
    color: #505b65 !important;
    border-color: #7F699B !important;
}
.cs-event.cs-event-colour-30 .cs-event-header,
.view-controls .engineers li.cs-event-colour-30 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-30 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-30 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #BB9AE5;
}

.cs-event.cs-event-colour-30 .image_wrapper,
.view-controls .engineers li.cs-event-colour-30 .image_wrapper,
.cs-event.cs-event-colour-30 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-30 .image_wrapper .avatar {
    background: #AB8CD2;
}
.cs-event.cs-event-colour-31,
.view-controls .engineers li.cs-event-colour-31 {
    border-color: #5589B6;
    background: #7AC2FF;
}

.cs-event.cs-event-colour-31 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-31 .cs-event-header-event-type {
    background: #71B4EE !important;
    color: #505b65 !important;
    border-color: #5589B6 !important;
}

.cs-event.cs-event-colour-31 .cs-event-header,
.view-controls .engineers li.cs-event-colour-31 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-31 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-31 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #7AC2FF;
}

.cs-event.cs-event-colour-31 .image_wrapper,
.view-controls .engineers li.cs-event-colour-31 .image_wrapper,
.cs-event.cs-event-colour-31 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-31 .image_wrapper .avatar {
    background: #71B4EE;
}
.cs-event.cs-event-colour-32,
.view-controls .engineers li.cs-event-colour-32 {
    border-color: #A9652C;
    background: #FF963D;
}

.cs-event.cs-event-colour-32 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-32 .cs-event-header-event-type {
    background: #DE8336 !important;
    color: #FFFFFF !important;
    border-color: #A9652C !important;
}
.cs-event.cs-event-colour-32 .cs-event-header,
.view-controls .engineers li.cs-event-colour-32 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-32 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-32 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #FF963D;
}

.cs-event.cs-event-colour-32 .image_wrapper,
.view-controls .engineers li.cs-event-colour-32 .image_wrapper,
.cs-event.cs-event-colour-32 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-32 .image_wrapper .avatar {
    background: #DE8336;
}
.cs-event.cs-event-colour-32 .cs-event-header-time,
.cs-event.cs-event-colour-32 .cs-event-header-status,.cs-event.cs-event-colour-32 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-32 .cs-event-header-text,.cs-event.cs-event-colour-32 .property-item .ss-buildings,
.cs-event.cs-event-colour-32 .cs-event-body,.cs-event.cs-event-colour-32 .contact-number .ss-phone,
.cs-event.cs-event-colour-32 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-32.highlight_event .cs-event-header-time,
.cs-event-colour-32.highlight_event .cs-event-header-status,.cs-event-colour-32.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-32.highlight_event .cs-event-header-text,.cs-event-colour-32.highlight_event .property-item .ss-buildings,
.cs-event-colour-32.highlight_event .cs-event-body, .cs-event-colour-32.highlight_event .contact-number .ss-phone,
.cs-event-colour-32.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-32 .cs-event-header-time a,
.cs-event.cs-event-colour-32 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}
.cs-event.cs-event-colour-33,
.view-controls .engineers li.cs-event-colour-33 {
    border-color: #BD3D80;
    background: #FF52AC;
}

.cs-event.cs-event-colour-33 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-33 .cs-event-header-event-type {
    background: #EB4B9E !important;
    color: #FFFFFF !important;
    border-color: #BD3D80 !important;
}

.cs-event.cs-event-colour-33 .cs-event-header,
.view-controls .engineers li.cs-event-colour-33 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-33 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-33 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #FF52AC;
}

.cs-event.cs-event-colour-33 .image_wrapper,
.view-controls .engineers li.cs-event-colour-33 .image_wrapper,
.cs-event.cs-event-colour-33 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-33 .image_wrapper .avatar {
    background: #EB4B9E;
}
.cs-event.cs-event-colour-33 .cs-event-header-time,
.cs-event.cs-event-colour-33 .cs-event-header-status,.cs-event.cs-event-colour-33 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-33 .cs-event-header-text,.cs-event.cs-event-colour-33 .property-item .ss-buildings,
.cs-event.cs-event-colour-33 .cs-event-body,.cs-event.cs-event-colour-33 .contact-number .ss-phone,
.cs-event.cs-event-colour-33 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-33.highlight_event .cs-event-header-time,
.cs-event-colour-33.highlight_event .cs-event-header-status,.cs-event-colour-33.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-33.highlight_event .cs-event-header-text,.cs-event-colour-33.highlight_event .property-item .ss-buildings,
.cs-event-colour-33.highlight_event .cs-event-body, .cs-event-colour-33.highlight_event .contact-number .ss-phone,
.cs-event-colour-33.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}
.cs-event.cs-event-colour-33 .cs-event-header-time a,
.cs-event.cs-event-colour-33 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}

.cs-event.cs-event-colour-34,
.view-controls .engineers li.cs-event-colour-34 {
    border-color: #205F92;
    background: #32A3FF;
}

.cs-event.cs-event-colour-34 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-34 .cs-event-header-event-type {
    background: #2B93E7 !important;
    color: #FFFFFF !important;
    border-color: #205F92 !important;
}
.cs-event.cs-event-colour-34 .cs-event-header,
.view-controls .engineers li.cs-event-colour-34 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-34 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-34 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #32A3FF;
}

.cs-event.cs-event-colour-34 .image_wrapper,
.view-controls .engineers li.cs-event-colour-34 .image_wrapper,
.cs-event.cs-event-colour-34 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-34 .image_wrapper .avatar {
    background: #2B93E7;
}
.cs-event.cs-event-colour-34 .cs-event-header-time,
.cs-event.cs-event-colour-34 .cs-event-header-status,.cs-event.cs-event-colour-34 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-34 .cs-event-header-text,.cs-event.cs-event-colour-34 .property-item .ss-buildings,
.cs-event.cs-event-colour-34 .cs-event-body,.cs-event.cs-event-colour-34 .contact-number .ss-phone,
.cs-event.cs-event-colour-34 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-34.highlight_event .cs-event-header-time,
.cs-event-colour-34.highlight_event .cs-event-header-status,.cs-event-colour-34.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-34.highlight_event .cs-event-header-text,.cs-event-colour-34.highlight_event .property-item .ss-buildings,
.cs-event-colour-34.highlight_event .cs-event-body, .cs-event-colour-34.highlight_event .contact-number .ss-phone,
.cs-event-colour-34.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-34 .cs-event-header-time a,
.cs-event.cs-event-colour-34 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}

.cs-event.cs-event-colour-35,
.view-controls .engineers li.cs-event-colour-35 {
    border-color: #6734A7;
    background: #9B4DFF;
}

.cs-event.cs-event-colour-35 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-35 .cs-event-header-event-type {
    background: #8E44EB !important;
    color: #FFFFFF !important;
    border-color: #6734A7 !important;
}
.cs-event.cs-event-colour-35 .cs-event-header,
.view-controls .engineers li.cs-event-colour-35 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-35 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-35 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #9B4DFF;
}

.cs-event.cs-event-colour-35 .image_wrapper,
.view-controls .engineers li.cs-event-colour-35 .image_wrapper,
.cs-event.cs-event-colour-35 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-35 .image_wrapper .avatar {
    background: #8E44EB;
}
.cs-event.cs-event-colour-35 .cs-event-header-time,
.cs-event.cs-event-colour-35 .cs-event-header-status,.cs-event.cs-event-colour-35 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-35 .cs-event-header-text,.cs-event.cs-event-colour-35 .property-item .ss-buildings,
.cs-event.cs-event-colour-35 .cs-event-body,.cs-event.cs-event-colour-35 .contact-number .ss-phone,
.cs-event.cs-event-colour-35 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-35.highlight_event .cs-event-header-time,
.cs-event-colour-35.highlight_event .cs-event-header-status,.cs-event-colour-35.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-35.highlight_event .cs-event-header-text,.cs-event-colour-35.highlight_event .property-item .ss-buildings,
.cs-event-colour-35.highlight_event .cs-event-body, .cs-event-colour-35.highlight_event .contact-number .ss-phone,
.cs-event-colour-35.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-35 .cs-event-header-time a,
.cs-event.cs-event-colour-35 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}

.cs-event.cs-event-colour-36,
.view-controls .engineers li.cs-event-colour-36 {
    border-color: #B7BF55;
    background: #F4FF74;
}

.cs-event.cs-event-colour-36 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-36 .cs-event-header-event-type {
    background: #E0EB69 !important;
    color: #505b65 !important;
    border-color: #B7BF55 !important;
}
.cs-event.cs-event-colour-36 .cs-event-header,
.view-controls .engineers li.cs-event-colour-36 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-36 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-36 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #F4FF74;
}

.cs-event.cs-event-colour-36 .image_wrapper,
.view-controls .engineers li.cs-event-colour-36 .image_wrapper,
.cs-event.cs-event-colour-36 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-36 .image_wrapper .avatar {
    background: #E0EB69;
}
.cs-event.cs-event-colour-37,
.view-controls .engineers li.cs-event-colour-37 {
    border-color: #5FC996;
    background: #74FFBC;
}

.cs-event.cs-event-colour-37 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-37 .cs-event-header-event-type {
    background: #6AEEAF !important;
    color: #505b65 !important;
    border-color: #5FC996 !important;
}
.cs-event.cs-event-colour-37 .cs-event-header,
.view-controls .engineers li.cs-event-colour-37 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-37 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-37 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #74FFBC;
}

.cs-event.cs-event-colour-37 .image_wrapper,
.view-controls .engineers li.cs-event-colour-37 .image_wrapper,
.cs-event.cs-event-colour-37 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-37 .image_wrapper .avatar {
    background: #6AEEAF;
}
.cs-event.cs-event-colour-38,
.view-controls .engineers li.cs-event-colour-38 {
    border-color: #59BCBC;
    background: #74FFFF;
}

.cs-event.cs-event-colour-38 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-38 .cs-event-header-event-type {
    background: #6DEEEE !important;
    color: #505b65 !important;
    border-color: #59BCBC !important;
}
.cs-event.cs-event-colour-38 .cs-event-header,
.view-controls .engineers li.cs-event-colour-38 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-38 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-38 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #74FFFF;
}

.cs-event.cs-event-colour-38 .image_wrapper,
.view-controls .engineers li.cs-event-colour-38 .image_wrapper,
.cs-event.cs-event-colour-38 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-38 .image_wrapper .avatar {
    background: #6DEEEE;
}
.cs-event.cs-event-colour-39,
.view-controls .engineers li.cs-event-colour-39 {
    border-color: #28A0BB;
    background: #2ED3F8;
}

.cs-event.cs-event-colour-39 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-39 .cs-event-header-event-type {
    background: #2DCAEC !important;
    color: #505b65 !important;
    border-color: #28A0BB !important;
}
.cs-event.cs-event-colour-39 .cs-event-header,
.view-controls .engineers li.cs-event-colour-39 .cs-event-header {
    background: transparent;
}
.cs-event.cs-event-colour-39 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-39 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #2ED3F8;
}

.cs-event.cs-event-colour-39 .image_wrapper,
.view-controls .engineers li.cs-event-colour-39 .image_wrapper,
.cs-event.cs-event-colour-39 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-39 .image_wrapper .avatar {
    background: #2DCAEC;
}
.cs-event.cs-event-colour-40,
.view-controls .engineers li.cs-event-colour-40 {
    border-color: #67BA26;
    background: #87F82E;
}

.cs-event.cs-event-colour-40 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-40 .cs-event-header-event-type {
    background: #82ED2D !important;
    color: #505b65 !important;
    border-color: #67BA26 !important;
}
.cs-event.cs-event-colour-40 .cs-event-header,
.view-controls .engineers li.cs-event-colour-40 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-40 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-40 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #87F82E;
}

.cs-event.cs-event-colour-40 .image_wrapper,
.view-controls .engineers li.cs-event-colour-40 .image_wrapper,
.cs-event.cs-event-colour-40 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-40 .image_wrapper .avatar {
    background: #82ED2D;
}
.cs-event.cs-event-colour-41,
.view-controls .engineers li.cs-event-colour-41 {
    border-color: #CB0000;
    background: #FF0000;
}

.cs-event.cs-event-colour-41 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-41 .cs-event-header-event-type {
    background: #E70404 !important;
    color: #FFFFFF !important;
    border-color: #CB0000 !important;
}
.cs-event.cs-event-colour-41 .cs-event-header,
.view-controls .engineers li.cs-event-colour-41 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-41 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-41 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #FF0000;
}

.cs-event.cs-event-colour-41 .image_wrapper,
.view-controls .engineers li.cs-event-colour-41 .image_wrapper,
.cs-event.cs-event-colour-41 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-41 .image_wrapper .avatar {
    background: #E70404;
}
.cs-event.cs-event-colour-41 .cs-event-header-time,
.cs-event.cs-event-colour-41 .cs-event-header-status,.cs-event.cs-event-colour-41 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-41 .cs-event-header-text,.cs-event.cs-event-colour-41 .property-item .ss-buildings,
.cs-event.cs-event-colour-41 .cs-event-body,.cs-event.cs-event-colour-41 .contact-number .ss-phone,
.cs-event.cs-event-colour-41 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-41.highlight_event .cs-event-header-time,
.cs-event-colour-41.highlight_event .cs-event-header-status,.cs-event-colour-41.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-41.highlight_event .cs-event-header-text,.cs-event-colour-41.highlight_event .property-item .ss-buildings,
.cs-event-colour-41.highlight_event .cs-event-body, .cs-event-colour-41.highlight_event .contact-number .ss-phone,
.cs-event-colour-41.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-41 .cs-event-header-time a,
.cs-event.cs-event-colour-41 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}
.cs-event.cs-event-colour-42,
.view-controls .engineers li.cs-event-colour-42 {
    border-color: #35A94F;
    background: #41D261;
}

.cs-event.cs-event-colour-42 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-42 .cs-event-header-event-type {
    background: #39BB56 !important;
    color: #FFFFFF !important;
    border-color: #35A94F !important;
}
.cs-event.cs-event-colour-42 .cs-event-header,
.view-controls .engineers li.cs-event-colour-42 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-42 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-42 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #41D261;
}

.cs-event.cs-event-colour-42 .image_wrapper,
.view-controls .engineers li.cs-event-colour-42 .image_wrapper,
.cs-event.cs-event-colour-42 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-42 .image_wrapper .avatar {
    background: #39BB56;
}
.cs-event.cs-event-colour-42 .cs-event-header-time,
.cs-event.cs-event-colour-42 .cs-event-header-status,.cs-event.cs-event-colour-42 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-42 .cs-event-header-text,.cs-event.cs-event-colour-42 .property-item .ss-buildings,
.cs-event.cs-event-colour-42 .cs-event-body,.cs-event.cs-event-colour-42 .contact-number .ss-phone,
.cs-event.cs-event-colour-42 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-42.highlight_event .cs-event-header-time,
.cs-event-colour-42.highlight_event .cs-event-header-status,.cs-event-colour-42.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-42.highlight_event .cs-event-header-text,.cs-event-colour-42.highlight_event .property-item .ss-buildings,
.cs-event-colour-42.highlight_event .cs-event-body, .cs-event-colour-42.highlight_event .contact-number .ss-phone,
.cs-event-colour-42.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-42 .cs-event-header-time a,
.cs-event.cs-event-colour-42 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}

.cs-event.cs-event-colour-43,
.view-controls .engineers li.cs-event-colour-43 {
    border-color: #910A8B;
    background: #BC0BB5;
}

.cs-event.cs-event-colour-43 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-43 .cs-event-header-event-type {
    background: #A808A1 !important;
    color: #FFFFFF !important;
    border-color: #910A8B !important;
}
.cs-event.cs-event-colour-43 .cs-event-header,
.view-controls .engineers li.cs-event-colour-43 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-43 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-43 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #BC0BB5;
}

.cs-event.cs-event-colour-43 .image_wrapper,
.view-controls .engineers li.cs-event-colour-43 .image_wrapper,
.cs-event.cs-event-colour-43 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-43 .image_wrapper .avatar {
    background: #A808A1;
}
.cs-event.cs-event-colour-43 .cs-event-header-time,
.cs-event.cs-event-colour-43 .cs-event-header-status,.cs-event.cs-event-colour-43 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-43 .cs-event-header-text,.cs-event.cs-event-colour-43 .property-item .ss-buildings,
.cs-event.cs-event-colour-43 .cs-event-body,.cs-event.cs-event-colour-43 .contact-number .ss-phone,
.cs-event.cs-event-colour-43 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-43.highlight_event .cs-event-header-time,
.cs-event-colour-43.highlight_event .cs-event-header-status,.cs-event-colour-43.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-43.highlight_event .cs-event-header-text,.cs-event-colour-43.highlight_event .property-item .ss-buildings,
.cs-event-colour-43.highlight_event .cs-event-body, .cs-event-colour-43.highlight_event .contact-number .ss-phone,
.cs-event-colour-43.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-43 .cs-event-header-time a,
.cs-event.cs-event-colour-43 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}

.cs-event.cs-event-colour-44,
.view-controls .engineers li.cs-event-colour-44 {
    border-color: #464700;
    background: #8F9200;
}

.cs-event.cs-event-colour-44 .cs-event-header-event-type,
.view-controls .engineers li.cs-event-colour-44 .cs-event-header-event-type {
    background: #838600 !important;
    color: #FFFFFF !important;
    border-color: #464700 !important;
}
.cs-event.cs-event-colour-44 .cs-event-header,
.view-controls .engineers li.cs-event-colour-44 .cs-event-header {
    background: transparent;
}

.cs-event.cs-event-colour-44 .cs-event-header .cs-event-header-upper .diary-event-status,
.view-controls .engineers li.cs-event-colour-44 .cs-event-header .cs-event-header-upper .diary-event-status {
    background: #8F9200;
}

.cs-event.cs-event-colour-44 .image_wrapper,
.view-controls .engineers li.cs-event-colour-44 .image_wrapper,
.cs-event.cs-event-colour-44 .image_wrapper .avatar,
.view-controls .engineers li.cs-event-colour-44 .image_wrapper .avatar {
    background: #838600;
}
.cs-event.cs-event-colour-44 .cs-event-header-time,
.cs-event.cs-event-colour-44 .cs-event-header-status,.cs-event.cs-event-colour-44 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-44 .cs-event-header-text,.cs-event.cs-event-colour-44 .property-item .ss-buildings,
.cs-event.cs-event-colour-44 .cs-event-body, .cs-event.cs-event-colour-44 .contact-number .ss-phone,
.cs-event.cs-event-colour-44 .cs-event-header-user span:last-of-type{
    color: #FFFFFF !important;
}

.cs-event-colour-44.highlight_event .cs-event-header-time,
.cs-event-colour-44.highlight_event .cs-event-header-status,.cs-event-colour-44.highlight_event .diary-lock-icon .ss-lock,
.cs-event-colour-44.highlight_event .cs-event-header-text,.cs-event-colour-44.highlight_event .property-item .ss-buildings,
.cs-event-colour-44.highlight_event .cs-event-body, .cs-event-colour-44.highlight_event .contact-number .ss-phone,
.cs-event-colour-44.highlight_event .cs-event-header-user span:last-of-type{
    color: #505b65 !important;
}

.cs-event.cs-event-colour-44 .cs-event-header-time a,
.cs-event.cs-event-colour-44 .cs-event-header-time a:hover{
    color: #FFFFFF !important;
    text-shadow: 0px 0 0;
}

.cs-event-header-time a,
.cs-event-header-time a:hover,
.cs-event > .highlight_event .cs-event-header-time a,.cs-event > .highlight_event .cs-event-header-time a:hover,
.cs-event-colour-41.highlight_event .cs-event-header-time a, .cs-event-colour-41.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-42.highlight_event .cs-event-header-time a, .cs-event-colour-42.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-43.highlight_event .cs-event-header-time a, .cs-event-colour-43.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-44.highlight_event .cs-event-header-time a, .cs-event-colour-44.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-32.highlight_event .cs-event-header-time a, .cs-event-colour-32.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-33.highlight_event .cs-event-header-time a, .cs-event-colour-33.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-34.highlight_event .cs-event-header-time a, .cs-event-colour-34.highlight_event .cs-event-header-time a:hover,
.cs-event-colour-35.highlight_event .cs-event-header-time a, .cs-event-colour-35.highlight_event .cs-event-header-time a:hover{
    color: #505b65 !important;
    text-shadow: 0px 0 0;
}

.cs-event.cs-event-colour-26 .property-item .ss-buildings,.cs-event.cs-event-colour-26 .contact-number .ss-phone,.cs-event.cs-event-colour-26 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-27 .property-item .ss-buildings,.cs-event.cs-event-colour-27 .contact-number .ss-phone,.cs-event.cs-event-colour-27 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-28 .property-item .ss-buildings,.cs-event.cs-event-colour-28 .contact-number .ss-phone,.cs-event.cs-event-colour-28 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-29 .property-item .ss-buildings,.cs-event.cs-event-colour-29 .contact-number .ss-phone,.cs-event.cs-event-colour-29 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-30 .property-item .ss-buildings,.cs-event.cs-event-colour-30 .contact-number .ss-phone,.cs-event.cs-event-colour-30 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-31 .property-item .ss-buildings,.cs-event.cs-event-colour-31 .contact-number .ss-phone,.cs-event.cs-event-colour-31 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-36 .property-item .ss-buildings,.cs-event.cs-event-colour-36 .contact-number .ss-phone,.cs-event.cs-event-colour-36 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-37 .property-item .ss-buildings,.cs-event.cs-event-colour-37 .contact-number .ss-phone,.cs-event.cs-event-colour-37 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-38 .property-item .ss-buildings,.cs-event.cs-event-colour-38 .contact-number .ss-phone,.cs-event.cs-event-colour-38 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-39 .property-item .ss-buildings,.cs-event.cs-event-colour-39 .contact-number .ss-phone,.cs-event.cs-event-colour-39 .diary-lock-icon .ss-lock,
.cs-event.cs-event-colour-40 .property-item .ss-buildings,.cs-event.cs-event-colour-40 .contact-number .ss-phone,.cs-event.cs-event-colour-40 .diary-lock-icon .ss-lock,
.cs-event-body .property-item .ss-buildings,.cs-event-body .contact-number .ss-phone,.cs-event-header .diary-lock-icon .ss-lock,
section .cs-event.highlight_event .cs-event-header-time,
.cs-event > .highlight_event .cs-event-header-time,
.cs-event > .highlight_event  .cs-event-header .cs-event-header-upper .s-event-header-status ,
.cs-event > .highlight_event  .cs-event-header .cs-event-header-upper .cs-event-header-text ,
.cs-event > .highlight_event:hover  .cs-event-body,
.cs-event > .highlight_event  .cs-event-header-user span:last-of-type,
.cs-event > .highlight_event  .cs-event-header .cs-event-header-upper .diary-lock-icon .ss-lock ,
.cs-event > .highlight_event:hover .cs-event-body .property-item .ss-buildings,
.cs-event > .highlight_event:hover  .cs-event-body .contact-number .ss-phone{
    color: #505b65 !important;
}
.cs-event .cs-special-event-spacer {
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: -1px;
}
.cs-event .cs-special-event-spacer.for-normal-event {
  background: #f7f2f1;
  border: 1px solid #dbc5c0;
}
.cs-event .cs-special-event-spacer.for-estimate-event, .cs-event .cs-special-event-spacer.for-opportunity-event {
  background: #eef4f7;
  border: 1px solid #bad2df;
}
.cs-event .cs-special-event-spacer.for-job-event {
  background: #f5f6f1;
  border: 1px solid #d4d8c2;
}
.cs-event .cs-special-event-spacer:nth-child(1) {
  border-bottom: none;
}
.cs-event .cs-special-event-spacer:nth-child(2) {
  border-top: none;
}
#diary-list-view {
  position: relative;
  padding-left: 19px;
}
#diary-list-view .cs-event-header {
  width: auto;
  background: transparent;
}
#diary-list-view .cs-event-header .cs-event-header-upper .cs-event-header-event-type {
  border-top-width: 1px;
  border-top-style: solid;
  border-left-width: 1px;
  border-left-style: solid;
}
#diary-list-view .cs-event-header .cs-event-header-upper .cs-event-header-time {
  width: 140px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#diary-list-view .cs-event-header .cs-event-header-lower .cs-event-header-user img {
  max-width: initial;
  float: left;
  width: 19px;
}
#diary-list-view .cs-event-header .cs-event-header-lower .cs-event-header-user span {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#diary-list-view .cs-event-header .cs-event-header-lower .cs-event-header-user span.ss-user {
  width: 19px;
}
#diary-list-view table.diary-list-view-header th:nth-child(1),
#diary-list-view table.diary-list-view-main th:nth-child(1),
#diary-list-view table.diary-list-view-header td:nth-child(1),
#diary-list-view table.diary-list-view-main td:nth-child(1) {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}
#diary-list-view table.diary-list-view-header th:nth-child(2),
#diary-list-view table.diary-list-view-main th:nth-child(2),
#diary-list-view table.diary-list-view-header td:nth-child(2),
#diary-list-view table.diary-list-view-main td:nth-child(2) {
  width: 110px;
  min-width: 110px;
  max-width: 110px;
}
#diary-list-view table.diary-list-view-header th:nth-child(4),
#diary-list-view table.diary-list-view-main th:nth-child(4),
#diary-list-view table.diary-list-view-header td:nth-child(4),
#diary-list-view table.diary-list-view-main td:nth-child(4) {
  width: 260px;
  min-width: 260px;
  max-width: 260px;
}
#diary-list-view table.diary-list-view-header th:nth-child(5),
#diary-list-view table.diary-list-view-main th:nth-child(5),
#diary-list-view table.diary-list-view-header td:nth-child(5),
#diary-list-view table.diary-list-view-main td:nth-child(5) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
#diary-list-view table.diary-list-view-header {
  background: transparent;
}
#diary-list-view table.diary-list-view-header thead {
  border-bottom: none;
}
#diary-list-view table.diary-list-view-header th {
    text-align: center !important;
    /*text-transform: uppercase;*/
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    color: #505b65;
    background: #fbfbfb;
    text-align: left !important;
    background: transparent;
    padding: 0;
}
#diary-list-view table.diary-list-view-header th:nth-child(1) {
  padding: 0 0 0 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#diary-list-view table.diary-list-view-header th:nth-child(2) {
  padding: 0 0 0 1px;
}
#diary-list-view table.diary-list-view-header th:nth-child(3) {
  padding: 0 0 0 11px;
}
#diary-list-view table.diary-list-view-header th:nth-child(5) {
  text-align: right !important;
  white-space: nowrap;
  padding: 0 20px 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#diary-list-view table.diary-list-view-main {
  font-size: 13px;
  border-right: 1px solid #bdc5cb;
}
#diary-list-view table.diary-list-view-main tr {
  background: #ffffff;
  border-bottom: none;
}
#diary-list-view table.diary-list-view-main tr:hover {
  background: #f7f7f7;
}
#diary-list-view table.diary-list-view-main tr td {
  vertical-align: top;
  padding: 5px 0;
  border-top: 1px solid #bdc5cb;
  border-bottom: 1px solid #bdc5cb;
}
#diary-list-view table.diary-list-view-main tr td .diary-image {
  background: #e6e3e3;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(1) {
  border-left: 1px solid #bdc5cb;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(1) .cs-event-header {
  top: -6px;
  left: -20px;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(1) .cs-event-header .cs-event-estimate-or-job-status {
  top: 38px;
  left: 28px;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(2) {
  color: rgba(80, 91, 101, 0.6);
}
#diary-list-view table.diary-list-view-main tr td:nth-child(3) {
  padding: 5px 40px 0 10px;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(4) .property-details {
  font-size: 12px;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(5) {
  padding: 5px 0;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(5) > div {
  float: right;
  padding: 0 16px 0 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#diary-list-view table.diary-list-view-main tr td:nth-child(5) > div i {
  margin: 0 6px 0 0;
}
#diary-list-view table.diary-list-view-main tr.for-estimate td:nth-child(1) .cs-event-header,
#diary-list-view table.diary-list-view-main tr.for-job td:nth-child(1) .cs-event-header {
  height: 55px;
}
#diary-list-view table.diary-list-view-main tr.is-work-address td:nth-child(1) .cs-event-header {
  top: -6px;
}
#diary-list-view.in-jobs .diary-list-view-header th:nth-child(1),
#diary-list-view.in-jobs tbody th:nth-child(1),
#diary-list-view.in-jobs .diary-list-view-header td:nth-child(1),
#diary-list-view.in-jobs tbody td:nth-child(1) {
  position: relative;
  padding: 0px 0px 0px 10px !important;
  box-sizing: content-box;
}
#diary-list-view.in-jobs .diary-list-view-header th:nth-child(1) .diary-image,
#diary-list-view.in-jobs tbody th:nth-child(1) .diary-image,
#diary-list-view.in-jobs .diary-list-view-header td:nth-child(1) .diary-image,
#diary-list-view.in-jobs tbody td:nth-child(1) .diary-image {
  position: absolute;
  left: -20px;
  top: -1px;
  width: 20px;
  height: 20px;
  border: solid 1px #bdc5cb;
  text-align: center;
  box-sizing: border-box;
}
#diary-list-view.in-jobs .diary-list-view-header th:nth-child(1) .diary-image img,
#diary-list-view.in-jobs tbody th:nth-child(1) .diary-image img,
#diary-list-view.in-jobs .diary-list-view-header td:nth-child(1) .diary-image img,
#diary-list-view.in-jobs tbody td:nth-child(1) .diary-image img {
  float: left;
}
#diary-list-view.in-jobs .diary-list-view-header th:nth-child(2),
#diary-list-view.in-jobs tbody th:nth-child(2),
#diary-list-view.in-jobs .diary-list-view-header td:nth-child(2),
#diary-list-view.in-jobs tbody td:nth-child(2) {
  width: auto;
  max-width: none;
  min-width: 0;
  color: #505b65 !important;
  padding: 5px !important;
}
#diary-list-view.in-jobs .diary-list-view-header th:nth-child(3),
#diary-list-view.in-jobs tbody th:nth-child(3),
#diary-list-view.in-jobs .diary-list-view-header td:nth-child(3),
#diary-list-view.in-jobs tbody td:nth-child(3) {
  width: 30%;
  max-width: 30%;
  min-width: 30%;
  padding: 5px !important;
}
#diary-list-view.in-jobs .diary-list-view-header th:nth-child(4),
#diary-list-view.in-jobs tbody th:nth-child(4),
#diary-list-view.in-jobs .diary-list-view-header td:nth-child(4),
#diary-list-view.in-jobs tbody td:nth-child(4) {
  width: 190px;
  max-width: 190px;
  min-width: 190px;
  padding: 5px !important;
}
#diary-list-view.in-jobs .feedback-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
}
#diary-list-view.in-jobs .feedback-container .diary-event-feedback-cell {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#cs-pop-up {
  position: fixed;
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity 0.02s ease-in 0.05s;
  -moz-transition: opacity 0.02s ease-in 0.05s;
  -o-transition: opacity 0.02s ease-in 0.05s;
  transition: opacity 0.02s ease-in 0.05s;
  background: #ffffff;
}
#cs-pop-up #cs-pop-up-arrow {
  position: fixed;
  width: 16px;
  height: 16px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.27);
}
#cs-pop-up #cs-pop-up-arrow.bottom {
  border-top: none;
  border-left: none;
}
#cs-pop-up #cs-pop-up-arrow.left {
  border-top: none;
  border-right: none;
  margin-left: 24px;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.06) -3px 3px 3px);
}
#cs-pop-up #cs-pop-up-arrow.right {
  border-bottom: none;
  border-left: none;
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.06) 3px -3px 3px);
}
#cs-pop-up.showing {
  opacity: 1;
  z-index: 10;
}
.add-additional-work {
  float: right;
}
.additional-works .work {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #ffffff;
  padding: 0 10px 10px;
  border: 1px solid #c9c9c9;
  border: solid 1px #c9c9c9;
  float: left;
  padding: 0;
  margin: 0 0 15px;
  position: relative;
  -webkit-transition: transform 0.25s ease-in-out;
  -moz-transition: transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.additional-works .work .field-content {
  margin-left: 240px;
}
.additional-works .work .work-title {
  float: left;
  width: 100%;
  padding: 10px 10px 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px #fdfdfd inset;
  -moz-box-shadow: 0px 1px 0px #fdfdfd inset;
  box-shadow: 0px 1px 0px #fdfdfd inset;
  background: #fcfcfc;
  border-bottom: 1px solid #efefef;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.additional-works .work .work-title .open-controls {
  float: left;
  font-size: 12px;
}
.additional-works .work .work-title .work-status {
  float: left;
  margin: 3px 0 0 4px;
}
.additional-works .work .work-title .work-title-text h3 {
  font-size: 18px;
  line-height: 15px;
  margin: 2px 9px;
  float: left;
}
.additional-works .work .work-title .work-title-text a {
  float: right;
  margin: -2px 10px;
}
.additional-works .work .work-title .download_options {
  float: right;
  margin: 0;
  padding: 0 9px 0 0;
  border-right: 1px solid #c9c9c9;
}
.additional-works .work .work-title .download_options .icon {
  font-size: 15px;
  width: 15px;
  height: 15px;
  margin: 0 2px 0 12px;
}
.additional-works .work .work-title .download_options .icon:hover {
  cursor: pointer;
}
.additional-works .work .work-title .quick-links-btn {
  margin-top: -6px;
  margin-right: 4px;
  background-color: white;
}
.additional-works .work .work-title .icon {
  float: left;
  margin: 0 0 0 15px;
}
.additional-works .work .work-title .work-status:hover,
.additional-works .work .work-title .icon:hover {
  cursor: help;
}
.additional-works .work .work-title .text {
  float: left;
  margin: -2px 0 0 7px;
}
.additional-works .work .work-title strong {
  color: #677077;
}
.additional-works .work .work-title .with-edit h3 {
  margin: 0 0 0 10px;
  line-height: 16px;
}
.additional-works .work .work-title .with-edit a {
  margin: -2px 0 0 10px;
}
.additional-works .work .work-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.additional-works .work .closed-body-content {
  clear: both;
  padding: 8px 0 0 0;
}
.additional-works .work .closed-body-content span {
  max-width: 100%;
}
.additional-works .work .closed-body-content .field-label {
  width: 200px;
}
.additional-works .work .work-body {
  position: relative;
  float: left;
  width: 100%;
  padding: 8px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #505b65;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.additional-works .work .work-body table.additional-work-todos tbody tr td .ss-check,
.additional-works .work .work-body table.additional-work-todos tbody tr td .ss-delete {
  position: relative;
  top: 2px;
  left: 10px;
}
.additional-works .work .work-body table.additional-work-todos tbody tr td .ss-check {
  color: #7eb419;
}
.additional-works .work .work-body table.additional-work-todos tbody tr td .ss-delete {
  color: rgba(240, 61, 37, 0.9);
}
.additional-works .work .work-body table.additional-work-todos tbody tr td .tooltip {
  margin-top: -2px;
}
.additional-works .work .work-body table.additional-work-todos tbody tr td:first-child {
  width: 30px;
  max-width: 30px;
  min-width: 30px;
}
.additional-works .work .work-body .timeline {
  position: relative;
  padding-left: 210px;
  margin-right: -10px;
  top: 0px;
  overflow: auto;
}
.additional-works .work .work-body .timeline .date-wrap {
  text-align: left;
}
.additional-works .work .work-body .timeline .line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 176px;
  margin-left: 94px;
}
.additional-works .work .work-body .timeline .content .field span {
  max-width: none;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even),
.additional-works .work .work-body .timeline .item:nth-of-type(odd) {
  right: 0px;
  left: 0px;
  width: auto;
  margin-left: 60px;
  margin-bottom: 20px;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-inner,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-inner {
  margin-left: 36px;
  margin-right: 0px;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-inner:after,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-inner:after {
  display: none;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-inner:before,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-inner:before {
  display: none;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-inner::after,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-inner::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 12px;
  left: 28px;
  right: auto;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #fcfcfc;
  border-left: none;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-inner::before,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-inner::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 11px;
  left: 27px;
  right: auto;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #cac9c9;
  border-left: none;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon {
  margin-left: -18px;
  margin-right: 0px;
  float: left;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-location,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-location,
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-navigate,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-navigate,
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-write,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-write,
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-camera,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-camera {
  color: #366dbd;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-check,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-check {
  color: #7eb419;
}
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-delete,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-delete,
.additional-works .work .work-body .timeline .item:nth-of-type(even) .item-icon .ss-alert,
.additional-works .work .work-body .timeline .item:nth-of-type(odd) .item-icon .ss-alert {
  color: #d25656;
}
.additional-works .work .work-body .timeline .title-bar .right-size {
  padding-right: 20px;
}
.additional-works .work .row-fluid.with-leftnav {
  padding-left: 220px;
  padding-right: 5px;
  padding-top: 20px;
}
.additional-works .work .row-fluid.with-leftnav.map-container {
  padding-top: 0px;
}
.additional-works .work .work-description {
  height: 20px;
  position: absolute;
  right: 8px;
  left: 105px;
  max-width: inherit;
}
.additional-works .work .icon {
  color: #677077;
}
.additional-works .work .text {
  font-size: 13px;
  color: #838d96;
}
.additional-works .work img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.additional-works .work.highlight .work-body {
  background: #d8e8f4;
  -webkit-transition: all 0s ease-in-out;
  -moz-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.additional-works .work.highlight .work-title {
  background: #d8e8f4;
  -webkit-transition: all 0s ease-in-out;
  -moz-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.additional-works .work.grid-view {
  width: 49.2%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.additional-works .work.grid-view:nth-child(even) {
  float: right;
}
.additional-works .work.grid-view.open {
  width: 100%;
}
.additional-works .work.list-view {
  clear: both;
  width: 100%;
}
.additional-works .work .show-when-closed {
  display: block;
}
.additional-works .work .show-when-open {
  display: none;
}
.additional-works .work.open .show-when-closed {
  display: none;
}
.additional-works .work.open .show-when-open {
  display: block;
}
.additional-works hr {
  margin: 10px 0 0 -10px;
  padding: 0 20px 0 0;
  border-top: 1px solid #eeeeee;
}
.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.status-circle.not-complete {
  background: #d25656;
}
.status-circle.complete {
  background: #60a369;
}
.status-circle.waiting-approval {
  background: #d78430;
}
.status-circle.status-progress {
  background: #2f96b4;
}
.sidepanel-link {
  float: right;
  margin: 2px 12px -20px 0;
}
.sidepanel-link .ss-view {
  color: #797979;
  font-size: 16px;
}
.panel-info-block li.officetasks-alert {
  text-align: center;
  border-bottom: 0px;
}
#page-panel-main.todolist {
  left: 0px;
}
#more-details-panel .todolist .tasks-loading {
  font-size: 80px;
  height: auto;
  border-bottom: 0px;
  text-align: center;
  line-height: 1;
  color: #eee;
}
#more-details-panel .todolist .data-section {
  width: 645px !important;
  margin: 5px 24px 0px 24px;
}
#more-details-panel .todolist section {
  position: absolute;
  bottom: 0px;
  top: 50px;
  overflow-y: scroll;
}
#more-details-panel .todolist .office-tasks-tabs {
  height: 36px;
  width: 100%;
}
#more-details-panel .todolist .search_box {
  width: 580px;
}
#more-details-panel .todolist .search_box input {
  margin-top: 6px;
  width: 490px;
}
#more-details-panel #page-panel-main.todolist {
  top: 86px;
}
#more-details-panel #page-panel-main.todolist #officetasks_assigned,
#more-details-panel #page-panel-main.todolist #officetasks_unassigned {
  padding: 0px;
}
#more-details-panel #page-panel-main.todolist .panel-info-block {
  width: 650px !important;
}
#todolist-title {
  float: left;
}
.todolist-task.animated {
  -webkit-animation: move 1s ease-out;
  animation: move 1s ease-out;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.todolist-task {
  background-color: #f9f9f9;
  max-height: 5000px;
  height: auto;
  padding: 10px 0px 10px 0px !important;
  word-wrap: break-word;
}
.todolist-task-inner-wrapper {
  -webkit-animation: move 1s ease-out;
  animation: move 1s ease-out;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  min-height: 30px;
  opacity: 1;
}
.todolist-task-inner-wrapper.fadeout {
  opacity: 0;
}
.todolist-task div,
.todolist-task span {
  overflow: hidden;
}
.todolist-task .checkbox-container {
  overflow: visible;
}
.todolist-task .assign-to-me-btn {
  color: #579cd7;
  line-height: 2.5;
}
.todolist-task .faux-checkbox {
  margin: auto;
}
.todolist-task .todolist-task-animation-wrapper {
  min-height: 30px;
}
.todolist-task .todolist-task-inner-wrapper {
  position: relative;
}
.todolist-task .customer-address,
.todolist-task .job-id,
.todolist-task .estimate-id {
  color: #acacac;
}
.todolist-task .customer-address a,
.todolist-task .job-id a,
.todolist-task .estimate-id a {
/*  color: #acacac !important;
*/
  color: #579cd7 !important;
}
.todolist-task .description {
  color: #505b65;
  overflow: hidden;
}
.todolist-task .checkbox-container {
  width: 45px;
  padding-top: 5px;
  position: absolute;
  display: flex;
}
 /*office task modal checkbox alignment*/
.checkbox-left-align{
    width: 20px !important;
    padding-top: 1px !important;
    padding-left: 13px !important;
}
.todolist-task .todolist-task-content {
  float: left;
  margin-left: 45px;
  width: auto;
}
.todolist-task .left-side {
  width: 70%;
  float: left;
}
.todolist-task .left-side .job-id {
  height: 20px;
}
.todolist-task .left-side .description {
  max-height: 60px;
}
.todolist-task .right-side {
  width: 30%;
  float: left;
}
.todolist-task .right-side .customer-name,
.todolist-task .right-side .customer-address-line-1,
.todolist-task .right-side .customer-address-line-2,
.todolist-task .right-side .customer-address-city,
.todolist-task .right-side .customer-address-post-code,
.todolist-task .right-side .customer-address-country {
  max-height: 60px;
}
.todolist-task.striked-out .todolist-task-content {
  text-decoration: line-through !important;
}
.todolist-task.shrinking {
  max-height: 0px;
  overflow: hidden;
  border-bottom: 0px !important;
  padding: 0px 60px 0px 0px !important;
}
.todolist-task.moving {
/*  position: absolute;
  z-index: 2;
  top: 101px;
  right: 0px;
*/
  border: 1px solid purple;
}
.todolist-task.just-moved {
  border: 3px solid purple;
  background-color: #ffff82;
}
#side-panel .help_section {
  padding: 20px 60px 10px 30px;
}
#side-panel #page-panel-main.without-actions {
  bottom: 0px;
}
#side-panel #page-panel-main.centered-content {
  text-align: center;
}
#side-panel #page-panel-main.with-top-padding {
  padding-top: 20px;
}
#side-panel #page-panel-main.todolist {
  left: 0px;
  right: 70px;
}
#side-panel .mce-tinymce iframe {
  position: static;
}
#side-panel .mce-tinymce .mce-tinymce {
  height: 180px !important;
}
.#side-panel .mce-tinymce .mce-edit-area:not(div.send-to-customer-height [class^="mce-edit-area"]) {
  height: 150px !important;
}

#side-panel .supplier-msg .mce-tinymce .mce-edit-area {
    height: 100% !important;
}

#side-panel .supplier-msg input {
    box-sizing: border-box; height: 34px
}
#side-panel .inner-content {
    padding:12px 50px 0px 12px
}

#side-panel .form_class {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#side-panel .fix-inputs input,
#side-panel .fix-inputs select {
  position: relative;
  top: -4px;
  left: 20px;
}
#side-panel .field input:not(.small) {
  width: 300px;
}
#side-panel .field input[type='checkbox'] {
  width: auto;
}
#side-panel .field input[type='radio'] {
  width: auto;
}
#side-panel .block-label {
  margin: 0 0 4px;
  clear: both;
}
#side-panel .block-label span {
  display: inline-block;
  margin: 0 0 4px;
}
#side-panel .required-field,
#side-panel .controls {
  margin-bottom: 9px;
}
#side-panel .required-field.checkbox-collection,
#side-panel .controls.checkbox-collection {
  margin: 0px 25px;
}
#side-panel .required-field input,
#side-panel .controls input {
  padding: 0px 8px !important;
  height: 30px !important;
}
#side-panel .required-field input[type='checkbox'],
#side-panel .controls input[type='checkbox'],
#side-panel .required-field input[type='radio'],
#side-panel .controls input[type='radio'] {
  height: auto !important;
}
#side-panel.print-and-post-panel,
.print-and-post-panel.print-and-post-panel,
.smart-table-sidepanel.print-and-post-panel {
  height: 100%;
}
#side-panel.panel-with-form,
.print-and-post-panel.panel-with-form,
.smart-table-sidepanel.panel-with-form {
  width: 500px;
}
#side-panel.panel-with-form .panel-scrollable-inner,
.print-and-post-panel.panel-with-form .panel-scrollable-inner,
.smart-table-sidepanel.panel-with-form .panel-scrollable-inner {
  padding-top: 10px !important;
}
#side-panel.panel-with-form .panel-scrollable-inner .required-block span:first-child,
.print-and-post-panel.panel-with-form .panel-scrollable-inner .required-block span:first-child,
.smart-table-sidepanel.panel-with-form .panel-scrollable-inner .required-block span:first-child {
  display: inline-block;
}
#side-panel.panel-with-form .select-with-clear-link-container,
.print-and-post-panel.panel-with-form .select-with-clear-link-container,
.smart-table-sidepanel.panel-with-form .select-with-clear-link-container {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
#side-panel.panel-with-form .select-with-clear-link-container select,
.print-and-post-panel.panel-with-form .select-with-clear-link-container select,
.smart-table-sidepanel.panel-with-form .select-with-clear-link-container select {
  margin-bottom: 0px;
}
#side-panel.panel-with-form .select-with-clear-link-container a,
.print-and-post-panel.panel-with-form .select-with-clear-link-container a,
.smart-table-sidepanel.panel-with-form .select-with-clear-link-container a {
  margin-left: 10px;
}
#side-panel.with-flip-transition,
.print-and-post-panel.with-flip-transition,
.smart-table-sidepanel.with-flip-transition {
  -webkit-transition: transform 1s ease-in-out;
  -moz-transition: transform 1s ease-in-out;
  -o-transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
}
#side-panel.without-transition,
.print-and-post-panel.without-transition,
.smart-table-sidepanel.without-transition {
  -webkit-transition: right 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: right 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: right 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: right 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#side-panel.flip-view,
.print-and-post-panel.flip-view,
.smart-table-sidepanel.flip-view {
  transform: perspective(850px) rotateY(-180deg) translate(0px, 0px) !important;
  margin-right: 0px;
  padding-right: 0px !important;
}
#side-panel.flip-view .page-panel-actions,
.print-and-post-panel.flip-view .page-panel-actions,
.smart-table-sidepanel.flip-view .page-panel-actions {
  right: 0px !important;
}
#side-panel.flip-view-360,
.print-and-post-panel.flip-view-360,
.smart-table-sidepanel.flip-view-360 {
  transform: perspective(850px) rotateY(-360deg) translate(0px, 0px) !important;
  margin-right: 0px;
  padding-right: 0px !important;
}
#side-panel.flip-view-360 .page-panel-actions,
.print-and-post-panel.flip-view-360 .page-panel-actions,
.smart-table-sidepanel.flip-view-360 .page-panel-actions {
  right: 0px !important;
}
#side-panel.pdf-preview,
.print-and-post-panel.pdf-preview,
.smart-table-sidepanel.pdf-preview {
  width: 800px;
  right: -800px;
}
#side-panel.pdf-preview #page-panel-main,
.print-and-post-panel.pdf-preview #page-panel-main,
.smart-table-sidepanel.pdf-preview #page-panel-main {
  bottom: 64px;
  padding-bottom: 0px;
  padding-top: 0px;
}
#side-panel.pdf-preview #page-panel-main object,
.print-and-post-panel.pdf-preview #page-panel-main object,
.smart-table-sidepanel.pdf-preview #page-panel-main object {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 800px;
  height: 100%;
}
#side-panel .flip,
.print-and-post-panel .flip,
.smart-table-sidepanel .flip {
  transform: rotateY(-180deg);
}
#side-panel {
  padding: 0 70px 0 0;
  margin-right: -70px;
  background: #f9f9f9;
  position: fixed;
  z-index: 1001;
  top: 0px;
  right: -770px;
  bottom: 0;
  width: 700px;
  margin: 0 -70px 0 0;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#side-panel.in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
#side-panel.narrow-side-panel {
  padding: 0 50px 0 0;
  margin-right: -50px;
  background: #f9f9f9;
  position: fixed;
  z-index: 1001;
  top: 0px;
  right: -550px;
  bottom: 0;
  width: 500px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#side-panel.narrow-side-panel.in-view {
  right: 0;
  -webkit-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
}
#side-panel.narrow-side-panel #page-panel-main,
#side-panel.narrow-side-panel .page-panel-actions {
  right: 50px;
}
#side-panel.narrow-side-panel .help_section {
  width: 440px !important;
}
/*#side-panel div:not(.loading-wrap, .control-group,.search_box,.timeline-container .item, .warning-icon-wrap, .data-section) {
  margin: 0;
}*/
#side-panel .row-fluid {
  width: 100%;
}
#side-panel .with-right-margin {
  margin-right: 50px;
}
#side-panel p {
  white-space: normal;
}
#side-panel .span12.with-top-padding {
  padding-top: 20px;
}
#side-panel #page-panel-main {
  left: 0;
  right: 70px;
  padding: 0 20px 50px;
  margin-bottom: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
#side-panel #page-panel-main.without-scroll {
  overflow: hidden;
}
#side-panel #page-panel-main.without-scroll .pretty-list-with-search .pretty-list {
  height: auto;
  bottom: 90px;
}
#side-panel #page-panel-main #page-panel-title-bar {
  margin: 0;
  margin-left: -20px;
  padding: 0 20px;
}
#side-panel #page-panel-main #page-panel-title-bar h4 {
  padding-left: 0;
}
#side-panel #page-panel-main #page-panel-title-bar .download_options {
  margin-right: -3px;
}
#side-panel #page-panel-main .control-group.milestone .control-label {
  float: left;
}
#side-panel #page-panel-main .control-group.milestone .controls {
  float: left;
  margin: -3px 0 0 10px;
}
#side-panel #page-panel-main table thead th {
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
}
#side-panel #page-panel-main table thead th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
#side-panel #page-panel-main table thead th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
#side-panel #page-panel-main table tbody tr:nth-child(even) td {
  background: #ffffff;
}
#side-panel #page-panel-main table tbody tr:nth-child(odd) td {
  background: #fbfbfb;
}
#side-panel #page-panel-main .custom-datepicker table tbody tr:nth-child(even) td,
#side-panel #page-panel-main .custom-datepicker table tbody tr:nth-child(odd) td {
  background: #ffffff;
}
#side-panel #page-panel-main .inset-section {
  padding-top: 10px;
}
#side-panel #page-panel-main .inset-section .field .field-label {
  width: 155px;
}
#side-panel #page-panel-main .inset-section .field .field-label.with-input {
  margin-top: 4px;
}
#side-panel #page-panel-main .inset-section .field .input {
  margin-bottom: 0px;
}
#side-panel .page-panel-actions {
  right: 70px;
}
#side-panel .panel-info-block li {
  padding-right: 0;
}
#side-panel .panel-info-block .date {
  clear: right;
  margin: -23px 100px 0 0;
}
#side-panel .panel-info-block-content {
  width: 620px;
}
#side-panel span {
  margin-bottom: 5px;
}
#side-panel .label {
  color: #ffffff;
}
#side-panel .system_settings_sortable {
  overflow: auto;
  height: auto;
  margin: 20px 10px 0 10px;
  padding: 0 0 5px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border-top: 1px solid rgba(220, 220, 221, 0.6);
  margin: 0 10px;
}
#side-panel .system_settings_sortable li {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-left: 1px solid #dcdcdd;
  border-right: 1px solid #dcdcdd;
}
#side-panel .system_settings_sortable li:first-of-type {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
#side-panel .system_settings_sortable li:last-of-type {
  border-bottom: none;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
#side-panel .system_settings_sortable label {
  margin: 0;
  padding: 10px 14px;
}
#side-panel .system_settings_sortable input[type="radio"] {
  float: left;
  margin: 3px 6px 0 0;
}
#side-panel .system_settings_sortable.with-small-margin {
  margin: 10px;
}
#side-panel .system_settings_sortable.without-margin {
  margin: 0px;
}
#side-panel .system_settings_sortable.static {
  position: static;
  margin: 10px 0;
}
#side-panel .system_settings_sortable.ui-sortable label {
  cursor: move;
}
#side-panel.with-help-text #page-panel-main {
  top: 41px;
}
#side-panel.with-help-text #page-panel-main.not-under-help-text {
  top: 61px;
}
#side-panel.with-help-text .help_section {
  margin: 0;
  width: 640px;
  margin-left: -20px;
  margin-top: 0;
  margin-bottom: 20px;
}
#side-panel.cancel-diary-event #page-panel-main {
  top: 86px;
}
#side-panel.cancel-diary-event #page-panel-main div.fix-width .field-label {
  width: 185px;
}
#side-panel.cancel-diary-event #page-panel-main div.fix-width textarea {
  width: 385px;
}
#side-panel .helper-with-icon,
#side-panel .panel-loading {
  margin: 200px 0 0 0;
}
#side-panel .helper-with-icon p,
#side-panel .panel-loading p {
  color: #7e8d93;
}
#side-panel .help-text.with-top-padding {
  padding-top: 20px;
}
#page-panel-main #page-panel-title-bar.fix-left {
  margin-left: -20px;
  padding: 0 20px;
}
#page-panel-main #page-panel-title-bar.fix-left h4 {
  padding-left: 0px;
}
#page-panel-main #page-panel-title-bar.fix-left .normal-link,
#page-panel-main #page-panel-title-bar.fix-left .breadcrumb-link {
  color: #3883c1;
}
#page-panel-main #page-panel-title-bar.fix-left .normal-link:hover,
#page-panel-main #page-panel-title-bar.fix-left .breadcrumb-link:hover {
  color: #265882;
}
#page-panel-main #page-panel-title-bar.fix-left .normal-link.normal-link,
#page-panel-main #page-panel-title-bar.fix-left .breadcrumb-link.normal-link {
  font-size: 14px;
}
#page-panel-main .field span,
#page-panel-main .field address {
  max-width: 465px;
}
#breadcrumb-title-bar {
  position: relative;
  opacity: 1;
  top: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#breadcrumb-title-bar .breadcrumb-title {
  float: left;
  font-size: 16px;
  overflow: hidden;
  transition: opacity 0.25s 0s ease-in, font-size 0.25s 0s ease-in, transform 0.25s 0s, width 0s 0.25s, height 0s 0.25s, padding 0s 0.25s, padding 0s 0.25s;
  opacity: 0;
  -webkit-transform: translate(100px, 0px);
  -moz-transform: translate(100px, 0px);
  -ms-transform: translate(100px, 0px);
  -o-transform: translate(100px, 0px);
  transform: translate(100px, 0px);
  width: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px;
}
#breadcrumb-title-bar .breadcrumb-title.in-view {
  transition: opacity 0.25s 0s ease-out, font-size 0.25s 0s ease-out, transform 0.25s 0s ease-out, width 0.25s 0s, height 0.25s 0s, padding 0.25s 0s, margin 0.25s 0s;
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  width: auto;
  height: auto;
  margin: 0px 5px 0px 0px;
}
#breadcrumb-title-bar .breadcrumb-title.not-link {
  color: #505b65;
  pointer-events: none;
  cursor: normal;
}
#breadcrumb-title-bar .breadcrumb-title.not-link:hover {
  color: #505b65;
}
#breadcrumb-title-bar .breadcrumb-title.main-title {
  font-size: 16px;
}
#breadcrumb-title-bar .breadcrumb-title.main-title.not-link {
  font-size: 18px;
  font-weight: normal;
  margin: 0px 10px 0px 0px;
}
#breadcrumb-title-bar .breadcrumb-title .ss-navigateright {
  margin-right: 8px;
}
#breadcrumb-title-bar .breadcrumb-title .help-block {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0px;
}
#breadcrumb-title-bar .right-side {
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0;
  font-size: 14px;
  font-weight: normal;
  -webkit-transform: translate(100px, 0px);
  -moz-transform: translate(100px, 0px);
  -ms-transform: translate(100px, 0px);
  -o-transform: translate(100px, 0px);
  transform: translate(100px, 0px);
  transition: opacity 0.25s 0s ease-out, z-index 0s 0.25s;
  z-index: -10;
}
#breadcrumb-title-bar .right-side.in-view {
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  transition: opacity 0.25s 0s ease-out, z-index 0.25s 0s;
  z-index: 10;
}
#breadcrumb-title-bar.out-of-view {
  top: -70px;
  opacity: 0;
}
#page-panel-main {
  overflow-x: hidden;
}
#page-panel-main.with-slides {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background: rgba(0, 0, 0, 0.12);
}
#page-panel-main #flip-panel-view {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
}
#page-panel-main #flip-panel-view.in-view {
  display: block;
}
#page-panel-main #flip-panel-view.with-padding {
  padding: 0px 20px 30px 20px;
  box-sizing: border-box;
}
#page-panel-main .slide {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background: #f9f9f9;
  transition: opacity 0s 0.25s, transform 0.25s 0s ease-in, z-index 0s 0.25s;
  opacity: 0;
  -webkit-transform: translate(100%, 0px);
  -moz-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  -o-transform: translate(100%, 0px);
  transform: translate(100%, 0px);
  overflow: hidden;
  overflow-y: auto;
  z-index: auto;
  -webkit-box-shadow: -2px 4px 6px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: -2px 4px 6px 0px rgba(0, 0, 0, 0.18);
  box-shadow: -2px 4px 6px 0px rgba(0, 0, 0, 0.18);
}
#page-panel-main .slide.in-view,
#page-panel-main .slide .in-view{
  transition: opacity 0.25s 0s, transform 0.25s 0s ease-out, z-index 0s 0s, padding 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  z-index: 30;
}
#page-panel-main .slide.with-padding {
  padding: 0px 20px 30px 20px;
  box-sizing: border-box;
}
#page-panel-main .diary-view .slide.with-padding {
    height: calc(100% - 40px);
    border-bottom: 1px solid #e1e1e1;
    box-shadow: none;
}
#page-panel-main .slide.with-top-padding {
  padding-top: 15px;
}
#page-panel-main .slide.centered-content {
  text-align: center;
}
#page-panel-main .slide.flexxed {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#page-panel-main .slide.below-fatal-error {
  padding-top: 230px !important;
}
#page-panel-main.with-flip {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 69px;
  display: none;
}
#page-panel-main.with-flip.in-view {
  display: block;
}
.page-panel-actions.with-slides {
  height: 28px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.page-panel-actions.with-slides .slide-button {
  position: absolute;
  left: 0px;
  right: 0px;
  padding-right: 20px;
  -webkit-transform: translate(30%, 0px);
  -moz-transform: translate(30%, 0px);
  -ms-transform: translate(30%, 0px);
  -o-transform: translate(30%, 0px);
  transform: translate(30%, 0px);
  opacity: 0;
  transition: opacity 0.125s 0s ease-in, transform 0.25s ease-in;
}
.page-panel-actions.with-slides .slide-button.in-view {
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  z-index: 30;
  transition: opacity 0.125s 0s, transform 0.25s ease-out;
}
.page-panel-actions.with-slides.out-of-view {
  bottom: -70px;
  opacity: 0;
}
#side-panel.with-saving-ui #breadcrumb-title-bar,
#side-panel.with-saving-ui #page-panel-title-bar,
#side-panel.with-saving-ui .page-panel-actions {
  -webkit-transition: transform 0.2s ease-in, opacity 0.1s ease-in;
  -moz-transition: transform 0.2s ease-in, opacity 0.1s ease-in;
  -o-transition: transform 0.2s ease-in, opacity 0.1s ease-in;
  transition: transform 0.2s ease-in, opacity 0.1s ease-in;
}
#side-panel.with-saving-ui #page-panel-main {
  -webkit-transition: opacity 0.15s ease-in;
  -moz-transition: opacity 0.15s ease-in;
  -o-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
}
#side-panel.with-saving-ui.form-being-saved #breadcrumb-title-bar,
#side-panel.with-saving-ui.form-being-saved #page-panel-title-bar {
  -webkit-transform: translate(0, -41px);
  -moz-transform: translate(0, -41px);
  -ms-transform: translate(0, -41px);
  -o-transform: translate(0, -41px);
  transform: translate(0, -41px);
  opacity: 0;
  -webkit-transition: transform 0.2s ease-out, opacity 0.1s ease-out;
  -moz-transition: transform 0.2s ease-out, opacity 0.1s ease-out;
  -o-transition: transform 0.2s ease-out, opacity 0.1s ease-out;
  transition: transform 0.2s ease-out, opacity 0.1s ease-out;
}
#side-panel.with-saving-ui.form-being-saved #page-panel-main {
  opacity: 0;
}
#side-panel.with-saving-ui.form-being-saved #page-panel-main input,
#side-panel.with-saving-ui.form-being-saved #page-panel-main textarea,
#side-panel.with-saving-ui.form-being-saved #page-panel-main select {
  pointer-events: none;
}
#side-panel.with-saving-ui.form-being-saved .page-panel-actions {
  -webkit-transform: translate(0, 70px);
  -moz-transform: translate(0, 70px);
  -ms-transform: translate(0, 70px);
  -o-transform: translate(0, 70px);
  transform: translate(0, 70px);
}
.estimate-price-options,
.estimate-send-options {
  float: left;
  width: 100%;
  margin: 20px 0 10px 0;
}
.estimate-price-options.additional-works,
.estimate-send-options.additional-works {
  margin-bottom: 35px;
}
.estimate-price-options.additional-works ul,
.estimate-send-options.additional-works ul {
  width: 566px;
}
.estimate-price-options.additional-works ul.with-top-margin,
.estimate-send-options.additional-works ul.with-top-margin {
  margin-top: 20px;
}
.estimate-price-options.new-invoice ul,
.estimate-send-options.new-invoice ul {
  width: 460px !important;
  margin-bottom: 20px;
}
.estimate-price-options.new-invoice ul li a,
.estimate-send-options.new-invoice ul li a {
  width: 220px;
}
.estimate-price-options + .transparent-section,
.estimate-send-options + .transparent-section,
.estimate-price-options + .data-section,
.estimate-send-options + .data-section {
  float: left;
  width: 100%;
  clear: both;
  margin: 30px 0 0 0;
}
.estimate-price-options + .transparent-section.data-section,
.estimate-send-options + .transparent-section.data-section,
.estimate-price-options + .data-section.data-section,
.estimate-send-options + .data-section.data-section {
  margin-bottom: 10px;
}
.estimate-price-options ul,
.estimate-send-options ul {
  list-style: none;
  width: 1154px;
  height: 122px;
  margin: 0 auto;
}
.estimate-price-options li a,
.estimate-send-options li a {
  float: left;
  width: 273px;
  height: 116px;
  margin: 0 20px 0 0;
  padding: 20px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.estimate-price-options li a .icon,
.estimate-send-options li a .icon {
  float: left;
  margin: 2px 10px 0 0;
  font-size: 20px;
  color: #50a69a;
}
.estimate-price-options li a .icon.ss-ban,
.estimate-send-options li a .icon.ss-ban {
  font-size: 22px;
}
.estimate-price-options li a .text-block,
.estimate-send-options li a .text-block {
  display: block;
  overflow: hidden;
}
.estimate-price-options li a .text-block h4,
.estimate-send-options li a .text-block h4 {
  margin: 0 0 14px;
  padding: 0 0 4px 0;
  border-bottom: 1px solid rgba(193, 201, 212, 0.5);
  -webkit-box-shadow: 1px 1px rgba(255, 255, 255, 0.45);
  -moz-box-shadow: 1px 1px rgba(255, 255, 255, 0.45);
  box-shadow: 1px 1px rgba(255, 255, 255, 0.45);
}
.estimate-price-options li a .text-block p,
.estimate-send-options li a .text-block p {
  margin: -4px 0 0 0;
  font-size: 13px;
  color: #98999c;
}
.estimate-price-options li a .active-arrow,
.estimate-send-options li a .active-arrow {
  position: absolute;
  left: 50%;
  margin: 0px 0 0 -6px;
  color: #f4f4f4;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-image: none !important;
  opacity: 0;
}
.estimate-price-options li a:hover .icon,
.estimate-send-options li a:hover .icon {
  color: #4b9c90;
}
.estimate-price-options li a:hover h4,
.estimate-send-options li a:hover h4 {
  color: #576576;
}
.estimate-price-options li a:hover p,
.estimate-send-options li a:hover p {
  color: #909195;
}
.estimate-price-options li a.active .active-arrow,
.estimate-send-options li a.active .active-arrow {
  margin: 12px 0 0 -6px;
  text-shadow: 0px 4px 3px rgba(96, 80, 166, 0.45);
  opacity: 1;
}
.estimate-price-options li:last-of-type a,
.estimate-send-options li:last-of-type a {
  margin: 0;
}
.estimate-price-options.estimate-send-options,
.estimate-send-options.estimate-send-options {
  margin-top: 10px;
}
.estimate-price-options.estimate-send-options ul,
.estimate-send-options.estimate-send-options ul {
  width: 859px;
  height: 60px;
}
.estimate-price-options.estimate-send-options ul li a,
.estimate-send-options.estimate-send-options ul li a {
  height: 135px;
  height: 60px;
}
.estimate-price-options.estimate-send-options ul li a .text-block h4,
.estimate-send-options.estimate-send-options ul li a .text-block h4 {
  border: 0px;
  border-bottom: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.estimate-price-options.estimate-send-options ul li a .active-arrow,
.estimate-send-options.estimate-send-options ul li a .active-arrow {
  margin: -14px 0 0 -6px;
}
.estimate-price-options.estimate-send-options ul li a.active,
.estimate-send-options.estimate-send-options ul li a.active {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}
.estimate-price-options.estimate-send-options ul li a.active .active-arrow,
.estimate-send-options.estimate-send-options ul li a.active .active-arrow {
  margin: -7px 0 0 -6px;
  display: none;
}
.estimate-price-options.estimate-send-options ul li a.active input,
.estimate-send-options.estimate-send-options ul li a.active input {
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
}
.estimate-price-options.estimate-send-options ul li .icon input,
.estimate-send-options.estimate-send-options ul li .icon input {
  margin-top: -10px;
}
.estimate-preview-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.estimate-preview-wrapper .preview-content {
  position: relative;
}
.estimate-preview-wrapper .preview-content object {
  width: 100%;
  height: 100%;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.estimate-preview-wrapper.add-in-fullscreen-mode .btn {
  display: none;
}
.add-in-fullscreen-mode {
  top: 0px !important;
  left: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  height: 100% !important;
}
.pricing-grand-totals {
  position: relative;
  float: right;
}
.pricing-grand-totals .hidden-vat-helper {
  position: absolute;
  top: 12px;
  left: -260px;
}
.pricing-grand-totals .hidden-vat-helper .helper-arrow {
  position: absolute;
  width: 105px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transform: rotate(186deg);
  -moz-transform: rotate(186deg);
  -ms-transform: rotate(186deg);
  -o-transform: rotate(186deg);
  transform: rotate(186deg);
  top: 30px;
  right: 0;
}
@media screen and (max-width: 1500px) {
  .estimate-price-options ul {
    width: 960px;
  }
  .estimate-price-options.additional-works ul {
    width: 470px !important;
  }
  .estimate-price-options li a {
    width: 225px;
    height: 100px;
    padding: 10px 12px;
  }
  .estimate-price-options li a .icon {
    display: none;
  }
  .estimate-price-options li a.active .active-arrow {
    margin-top: 5px;
  }
  .estimate-price-options .small-header {
    font-size: 15px;
  }
}
.purple-select-btn-group.with-top-margin,
.estimate-price-options.with-top-margin,
.estimate-send-options.with-top-margin {
  margin-top: 10px;
}
.purple-select-btn-group a,
.estimate-price-options a,
.estimate-send-options a {
  position: relative;
  z-index: 1;
  background-image: linear-gradient(to bottom, #f9f9f9 2%, #f6f6f6 50%, #f4f4f4 98%);
  border: 1px solid #dadada;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.25s !important;
  -moz-transition: all 0.25s !important;
  -o-transition: all 0.25s !important;
  transition: all 0.25s !important;
}
.purple-select-btn-group a:hover,
.estimate-price-options a:hover,
.estimate-send-options a:hover {
  z-index: 1 !important;
  background-image: linear-gradient(to bottom, #f9f9f9 2%, #f3f3f3 50%, #efefef 98%);
}
.purple-select-btn-group a:focus,
.estimate-price-options a:focus,
.estimate-send-options a:focus {
  outline: none;
}
.purple-select-btn-group a.active,
.estimate-price-options a.active,
.estimate-send-options a.active {
  z-index: 3 !important;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  border: 2px solid rgba(96, 80, 166, 0.5);
  -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9), 0 0 2px #6050a6 inset;
  -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9), 0 0 2px #6050a6 inset;
  box-shadow: 0 0 6px rgba(96, 80, 166, 0.9), 0 0 2px #6050a6 inset;
  background-image: linear-gradient(to bottom, #f9f9f9 2%, #f6f6f6 50%, #f4f4f4 98%);
}
.purple-select-btn-group a.active:hover,
.estimate-price-options a.active:hover,
.estimate-send-options a.active:hover {
  background-image: linear-gradient(to bottom, #f9f9f9 2%, #f6f6f6 50%, #f4f4f4 98%);
}
.purple-select-btn-group a input,
.estimate-price-options a input,
.estimate-send-options a input {
  margin-top: -8px;
}
.purple-select-btn-group ul.disabled-ui li a:not(.active),
.estimate-price-options ul.disabled-ui li a:not(.active),
.estimate-send-options ul.disabled-ui li a:not(.active) {
  opacity: 0.7;
  cursor: not-allowed;
}
.purple-select-btn-group ul.disabled-ui li a:hover,
.estimate-price-options ul.disabled-ui li a:hover,
.estimate-send-options ul.disabled-ui li a:hover {
  background-image: linear-gradient(to bottom, #f9f9f9 2%, #f6f6f6 50%, #f4f4f4 98%);
}
.purple-select-btn-group a {
  font-size: 13px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #5e6d7f;
}
.purple-select-btn-group a:hover {
  color: #576576;
}
.purple-select-btn-group a.active {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.notification-bar {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 200;
  max-width: 200px;
}
.notification-message {
  min-height: 1.5em;
  margin-top: 3px;
  opacity: 0;
  width: 100%;
  max-width: 200px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 15px 10px 10px 10px;
  box-shadow: 0 0px 15px -5px #000000;
  background: #ffffff;
  border: 2px solid #5e6d7f;
}
.notification-message.show {
  opacity: 1;
}
.notification-message .close-notification {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: solid 2px #5e6d7f;
  display: inline-block;
  background-color: white;
  color: black;
  text-align: center;
  position: absolute;
  top: -10px;
  right: -35px;
}
.notifications-panel #page-panel-main strong.diary {
  margin: 0 10px !important;
}
.notifications-panel #page-panel-main .notification-html-module {
  padding: 0px 30px;
}
.notifications-panel #page-panel-main section.notification_history li {
  padding: 12px 20px 2px;
}
.notifications-panel #page-panel-main section.notification_history .row-fluid img {
  position: relative;
  top: -5px;
  margin-right: 10px;
}
.notifications-panel #page-panel-main .without-top-padding {
  padding-top: 0px;
  margin-top: 0px !important;
  border-top: 0px !important;
}
.progress-content {
  width: auto !important;
}
.progress-section {
  padding-bottom: 0px;
}
.progress-section .not-current-level {
  opacity: 0.4;
}
.progress-section .current-level {
  opacity: 1;
}
.progress-section .section-title {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 1;
  height: 40px;
}
.levels-timeline-section {
  padding: 0px !important;
  top: 93px;
  -webkit-transition: all 0.3s ease-in-out 0.2s;
  -moz-transition: all 0.3s ease-in-out 0.2s;
  -o-transition: all 0.3s ease-in-out 0.2s;
  transition: all 0.3s ease-in-out 0.2s;
}
.levels-timeline-section h2 {
  font-size: 20px;
}
.levels-timeline-section .timeline {
  padding: 0px;
  top: 115px;
}
.levels-timeline-section .section-title .view-controls {
  padding: 0px 0px 0px 10px;
}
.levels-timeline-section div.title .help_section {
  -webkit-transition: all 0.3s ease-in-out 0.2s;
  -moz-transition: all 0.3s ease-in-out 0.2s;
  -o-transition: all 0.3s ease-in-out 0.2s;
  transition: all 0.3s ease-in-out 0.2s;
  margin: 0px;
}
.levels-timeline-section div.title .help_section.levels {
  height: 63px;
  padding: 15px 30px 0;
}
.levels-timeline-section div.title .help_section.colleagues {
  height: 93px;
}
.level-wide {
  background-color: #f7f7f7;
  position: relative;
  padding: 10px;
  min-height: 225px;
  text-align: center;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #ffffff;
}
.level-wide.active-level {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(73, 121, 154, 0.25);
  -moz-box-shadow: 0px 0px 15px 1px rgba(73, 121, 154, 0.25);
  box-shadow: 0px 0px 15px 1px rgba(73, 121, 154, 0.25);
  z-index: 1;
}
.level-wide.sub {
  background-color: #ffffff;
  min-height: 150px;
  max-height: 150px;
  padding-top: 45px;
  margin: 0;
  padding-left: 0px;
}
.level-wide .span2 {
  margin-top: 60px;
  height: 100%;
}
.level-wide .span2.completed {
  opacity: 0.3;
}
.level-wide .progress-icon {
  padding-top: 43px;
  font-size: 55px;
  color: #ffffff;
}
.level-wide .icon {
  position: relative;
  height: 90px;
  width: 90px;
  margin: 0 auto;
  padding: 0 !important;
  cursor: pointer;
  -webkit-border-radius: 9999px;
  -moz-border-radius: 9999px;
  border-radius: 9999px;
  /*a bit hacky*/

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.level-wide .icon.cyan {
  background-color: #487ea3;
}
.level-wide .icon.cyann {
  background-color: #487ea3;
}
.level-wide .icon.blue {
  background-color: #5fa1cd;
}
.level-wide .icon.yellow {
  background-color: #ffd700;
}
.level-wide .icon.red {
  background-color: rgba(240, 61, 37, 0.9);
}
.level-wide .icon.inactive:after,
.level-wide .icon.inactive:before {
  display: none !important;
}
.level-wide .icon:after,
.level-wide .icon:before {
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: -100px;
  width: 35px;
  height: 35px;
  margin-left: -17px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.level-wide .icon.active:after,
.level-wide .icon.active:before {
  opacity: 1;
  background: #f6f6f6;
}
.level-wide .icon.active:after {
  bottom: -104px;
  width: 35px;
  height: 35px;
  z-index: 100;
}
.level-wide .icon.active:before {
  bottom: -103px;
  width: 34px;
  height: 33px;
  border-color: #ffffff;
  z-index: 101;
  -webkit-box-shadow: 5px 5px 4px -4px rgba(73, 121, 154, 0.2);
  -moz-box-shadow: 5px 5px 4px -4px rgba(73, 121, 154, 0.2);
  box-shadow: 5px 5px 4px -4px rgba(73, 121, 154, 0.2);
}
.level-wide .task-span {
  padding-top: 15px;
  margin-left: 0px;
}
.level-wide .task-desc {
  -webkit-transition: all 500ms cubic-bezier(0.68, 0, 0.265, 1);
  -moz-transition: all 500ms cubic-bezier(0.68, 0, 0.265, 1);
  -o-transition: all 500ms cubic-bezier(0.68, 0, 0.265, 1);
  transition: all 500ms cubic-bezier(0.68, 0, 0.265, 1);
  height: 70%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  text-align: center;
  padding: 0 17% 0 17%;
}
.level-wide .task-desc section {
  min-height: 180px;
  position: relative;
  border: none;
}
.level-wide .task-desc .btn,
.level-wide .task-desc span {
  visibility: hidden;
  opacity: 0;
}
.level-wide .task-desc .btn,
.level-wide .task-desc .unnecessary {
  position: absolute;
  bottom: 45px;
  left: 50%;
  margin-left: -47px;
}
.level-wide .task-desc .unnecessary {
  left: 50%;
  margin-left: 57px;
  bottom: 55px;
  color: #d53847;
  opacity: 0.7;
  cursor: pointer;
}
.level-wide .task-desc.swipeleft {
  display: block;
  -webkit-animation: bouncein 0.25s ease-in-out;
}
.level-wide .task-desc.swipeleft .btn,
.level-wide .task-desc.swipeleft span,
.level-wide .task-desc.swipeleft .unnecessary {
  visibility: visible;
  opacity: 1;
}
.level-wide .selected {
  color: #d53847;
}
.level-wide .last-level .level-wide {
  -webkit-border-radius: 0px 0px 6px 6px;
  -moz-border-radius: 0px 0px 6px 6px;
  border-radius: 0px 0px 6px 6px;
}
.level-wide .first-level .level-wide {
  margin-top: 20px;
}
.level-wide .ss-check:before {
  content: '✓' !important;
}
.level-wide .ss-hyphen:before {
  content: '-' !important;
}
.span12.progress-search {
  margin-top: 30px;
  margin-left: 10px !important;
  perspective: 106px;
  transform: rotateX(-90deg) skew(18deg, 0deg) scale(1, 0.5);
  transform-style: preserve-3d;
  transform-origin: bottom;
}
.span12.progress-search.showing {
  margin-top: 14px;
  transform: rotateX(0deg) skew(0deg, 0deg) scale(1, 1);
}
.span12.progress-search.colleagues {
  -webkit-transition: all 0.3s ease-in-out 0.2s;
  -moz-transition: all 0.3s ease-in-out 0.2s;
  -o-transition: all 0.3s ease-in-out 0.2s;
  transition: all 0.3s ease-in-out 0.2s;
}
.span12.progress-search.levels {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.colleague-timeline-section {
  top: 130px;
  padding: 0px !important;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.colleague-timeline-section .section-title .view-controls {
  padding: 0px 0px 0px 10px;
}
.colleague-timeline-section .tooltip {
  -webkit-transition: margin-top 0.2s ease-in-out;
  -moz-transition: margin-top 0.2s ease-in-out;
  -o-transition: margin-top 0.2s ease-in-out;
  transition: margin-top 0.2s ease-in-out;
}
.colleague-timeline-section .tooltip.top.fade.in.active {
  margin-top: -12px;
}
.level-thermometer-container {
  padding: 12px 10px 0px 10px;
}
.level-thermometer-container .thermometer {
  position: relative;
  height: 30px;
  margin-right: 20px;
}
.level-thermometer-container .thermometer .line {
  width: 20%;
  position: absolute;
  top: 3px;
  left: 0px;
  height: 22px;
  text-align: center;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.level-thermometer-container .thermometer .line.beginner:before,
.level-thermometer-container .thermometer .line.gettingthere:before,
.level-thermometer-container .thermometer .line.competent:before,
.level-thermometer-container .thermometer .line.advanced:before,
.level-thermometer-container .thermometer .line.genius:before {
  position: relative;
  text-align: center;
  top: 40px;
  font-size: 13px;
}
.level-thermometer-container .thermometer .line.beginner {
  left: 0%;
  background-color: #d8e8f4;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.level-thermometer-container .thermometer .line.beginner:before {
  content: 'Beginner';
}
.level-thermometer-container .thermometer .line.gettingthere {
  left: 20%;
  background-color: #c6dbe8;
}
.level-thermometer-container .thermometer .line.gettingthere:before {
  content: 'Getting There';
}
.level-thermometer-container .thermometer .line.competent {
  left: 40%;
  background-color: #34a2dc;
}
.level-thermometer-container .thermometer .line.competent:before {
  content: 'Competent User';
}
.level-thermometer-container .thermometer .line.advanced {
  left: 60%;
  background-color: #366dbd;
}
.level-thermometer-container .thermometer .line.advanced:before {
  content: 'Advanced';
}
.level-thermometer-container .thermometer .line.genius {
  left: 80%;
  background-color: #0c3055;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.level-thermometer-container .thermometer .line.genius:before {
  content: 'Genius';
}
.level-thermometer-container .thermometer .line .beginner {
  position: absolute;
  left: 5%;
  border-left: 1px solid #000000;
}
.level-thermometer-container .thermometer .line .intermediate {
  position: absolute;
  left: 25%;
  border-left: 1px solid #000000;
}
.level-thermometer-container .thermometer .line .advanced {
  position: absolute;
  left: 50%;
  border-left: 1px solid #000000;
}
.level-thermometer-container .thermometer .line .super {
  position: absolute;
  left: 75%;
  border-left: 1px solid #000000;
}
.level-thermometer-container .thermometer .line .genius {
  position: absolute;
  left: 95%;
  border-left: 1px solid #000000;
}
.level-thermometer-container .thermometer .line:last-child {
  -webkit-border-top-right-radius: 50px;
  -moz-border-radius-topright: 50px;
  border-top-right-radius: 50px;
  -webkit-border-bottom-right-radius: 50px;
  -moz-border-radius-bottomright: 50px;
  border-bottom-right-radius: 50px;
}
.level-thermometer-container .thermometer .item-icon {
  position: absolute;
  top: -7px;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #797979;
  border: 2px solid #797979;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  margin-left: -50px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.level-thermometer-container .thermometer .item-icon.large {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
  z-index: 4;
}
.level-thermometer-container .thermometer .item-icon .thermometer-avatar,
.level-thermometer-container .thermometer .item-icon .thermometer-avatar img {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  overflow: hidden;
}
.level-thermometer-container .thermometer .item-icon.animate {
  margin-left: 0px;
  opacity: 1;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(0) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0s, opacity 0.3s ease-in-out 0.5s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(1) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.6s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(2) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.7s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(3) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.8s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(4) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.4s, opacity 0.3s ease-in-out 0.9s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(5) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.5s, opacity 0.3s ease-in-out 1s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(6) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.6s, opacity 0.3s ease-in-out 1.1s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(7) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.7s, opacity 0.3s ease-in-out 1.2s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(8) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.8s, opacity 0.3s ease-in-out 1.3s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(9) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 0.9s, opacity 0.3s ease-in-out 1.4s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(10) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1s, opacity 0.3s ease-in-out 1.5s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(11) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.1s, opacity 0.3s ease-in-out 1.6s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(12) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.2s, opacity 0.3s ease-in-out 1.7s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(13) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.3s, opacity 0.3s ease-in-out 1.8s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(14) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.4s, opacity 0.3s ease-in-out 1.9s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(15) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.5s, opacity 0.3s ease-in-out 2s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(16) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.6s, opacity 0.3s ease-in-out 2.1s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(17) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.7s, opacity 0.3s ease-in-out 2.2s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(18) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.8s, opacity 0.3s ease-in-out 2.2s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(19) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 1.9s, opacity 0.3s ease-in-out 2.3s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(20) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 2s, opacity 0.3s ease-in-out 2.4s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(21) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 2.1s, opacity 0.3s ease-in-out 2.5s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(22) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 2.2s, opacity 0.3s ease-in-out 2.6s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(23) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 2.3s, opacity 0.3s ease-in-out 2.6s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(24) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 2.4s, opacity 0.3s ease-in-out 2.7s;
}
.level-thermometer-container .thermometer .item-icon:nth-of-type(25) {
  transition: -webkit-transform 0.3s ease-in-out, margin-left 1.2s ease-in-out 2.5s, opacity 0.3s ease-in-out 2.8s;
}
.timeline.levels {
  margin-top: 105px;
  border-top: solid 1px #eeeeee;
}
.timeline.levels .line {
  top: 278px;
}
.timeline.levels .item.levels .item-icon {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  overflow: hidden;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.timeline.levels .item.levels .item-icon.active {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
.timeline.levels .item.levels .item-inner {
  -webkit-transition: all 600ms ease-in-out;
  -moz-transition: all 600ms ease-in-out;
  -o-transition: all 600ms ease-in-out;
  transition: all 600ms ease-in-out;
  transition-delay: 0.2s !important;
}
.timeline.levels .item.levels .item-inner.active {
  -webkit-box-shadow: 0 0 6px rgba(73, 121, 154, 0.5);
  -moz-box-shadow: 0 0 6px rgba(73, 121, 154, 0.5);
  box-shadow: 0 0 6px rgba(73, 121, 154, 0.5);
}
.timeline.levels .item.levels .item-inner .left-side {
  margin: 0 0 0 16px;
}
.timeline.levels .item.levels .item-inner .left-side .ss-star {
  float: left;
  margin-right: 7px;
  margin-left: -2px;
  cursor: pointer;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.timeline.levels .item.levels .item-inner .left-side .ss-star.active {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  color: #c3b453;
}
.timeline.levels .item.levels .item-inner .left-side .ss-star.disabled {
  cursor: not-allowed;
}
.timeline.levels .item.levels .item-inner .left-side .colleague-level {
  padding-left: 4px;
  font-style: italic;
}
.timeline.levels .item.levels .item-inner .content .levels-content {
  font-size: 14px;
}
.timeline.levels .item.levels .item-inner .content .levels-content .ss-check {
  color: #7eb419;
}
.timeline.levels .item.levels .item-inner .content .levels-content hr {
  margin: 10px 0;
}
.timeline.levels .item.levels .item-inner .content .levels-content span.title-icon {
  font-size: 10px;
}
.timeline.levels .item.levels .item-inner .content .levels-content div.ignored {
  color: red;
  text-decoration: line-through;
  opacity: 0.5;
}
.timeline.levels .item.levels .item-inner .content .levels-content div.ignored span {
  color: black;
}
.timeline.levels .item.levels .item-inner .content .levels-content div.complete {
  opacity: 0.5;
}
.timeline.levels .item.levels .item-inner .content .ss-volume {
  opacity: 0;
}
.timeline.levels .ss-star {
  display: inline-block !important;
}
.tooltip-inner {
  white-space: pre;
  max-width: none;
}
.notifications-table-wrapper {
  overflow: visible;
}
.notifications-table-wrapper table {
  margin: 0px;
}
.notifications-table td {
  overflow: auto;
}
.notifications-table tr.notification-row {
  display: none;
}
.notifications-table tr.notification-row.pending {
  display: table-row;
}
.notifications-table tr.notification-row.completed {
  display: table-row;
}
.dropdown-menu.notifications .title {
  padding: 0px 16px !important;
}
.dropdown-menu.notifications a {
  padding: 0 !important;
  white-space: normal !important;
}
.reminder-panel span.margin-bottom {
  margin-bottom: 15px;
}
.reminder-panel select,
.reminder-panel input {
  position: relative;
  left: 21px;
  width: 253px;
  top: -5px;
}
.reminder-panel .add-on {
  margin-left: 19px;
}
.reminder-panel .single-input input {
  position: relative;
  left: 22px;
}
.reminder-panel #page-panel-title-bar .help-block {
  display: inline-block;
}
.reminder-panel .field div.datepicker-widget {
  display: inline-block;
  top: -10px;
  position: relative;
}
.reminder-panel .field div.datepicker-append {
  position: relative;
  top: -5px;
  left: 22px;
  width: 223px;
  display: inline-block;
}
.reminder-panel .field div.datepicker-append .add-on {
  margin-left: -3px;
}
.reminder-panel .field div.datepicker-append .ss-calendar {
  margin-left: 0px;
}
.reminder-panel .field div.datepicker-append .daterange-wrapper {
  top: -11px;
}
.reminder-panel .field div.datepicker-append #datepicker-input {
  left: -2px;
  top: 0px;
  width: 213px;
  height: 20px !important;
  padding: 4px 6px !important;
}
.reminder-panel .field .custom-datepicker {
  left: 20px;
  width: 254px;
}
.section-title.with-edit .page-datepicker a {
  margin: 0;
  float: none;
}
.section-title.with-edit .page-datepicker a.daterange-day {
  float: left;
}
.batch-authorise-work-reminders .checkboxes-with-disabled strong {
  margin-left: 10px;
}
.batch-authorise-work-reminders .single-reminder li .field {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.batch-authorise-work-reminders .single-reminder li span {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: none;
  width: auto;
}
.batch-authorise-work-reminders .single-reminder li span.field-label {
  margin-left: 14px;
  flex: 0 0 auto;
}
.batch-authorise-work-reminders .single-reminder li .ss-check {
  float: left;
  margin-left: -50px;
  color: #7eb419;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease-in-out all;
  -moz-transition: 0.3s ease-in-out all;
  -o-transition: 0.3s ease-in-out all;
  transition: 0.3s ease-in-out all;
  position: relative;
  top: 2px;
}
.batch-authorise-work-reminders .single-reminder li .ss-check.processed {
  margin-left: 10px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.batch-authorise-work-reminders .single-reminder li:nth-child(1) .ss-check {
  transition-delay: 0.3s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(2) .ss-check {
  transition-delay: 0.6s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(3) .ss-check {
  transition-delay: 0.9s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(4) .ss-check {
  transition-delay: 1.2s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(5) .ss-check {
  transition-delay: 1.5s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(6) .ss-check {
  transition-delay: 1.8s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(7) .ss-check {
  transition-delay: 2.1s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(8) .ss-check {
  transition-delay: 2.4s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(9) .ss-check {
  transition-delay: 2.7s;
}
.batch-authorise-work-reminders .single-reminder li:nth-child(10) .ss-check {
  transition-delay: 3s;
}
#drag_helper {
  position: fixed;
  background-color: #f7f7f7;
  padding: 5px 10px;
  border-radius: 0px;
  z-index: 9000;
  border: solid 1px #c9c9c9;
  font-weight: bold;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
}
#drag_helper_top {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9001;
  background-color: red;
  height: 10%;
  width: 100%;
}
#drag_helper_bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 9002;
  background-color: red;
  height: 10%;
  width: 100%;
}
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */

  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.drag-handle {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.import_options_wrapper .search-wrapper,
.engineer-reserve-list .search-wrapper,
.allocate-to-invoices .search-wrapper {
  height: 45px;
  background: #eeeeee;
  padding: 0px 15px;
  border-bottom: solid 1px #d3d3d3;
}
.import_options_wrapper .search-wrapper.darker,
.engineer-reserve-list .search-wrapper.darker,
.allocate-to-invoices .search-wrapper.darker {
  background: #e9e9e9;
}
.import_options_wrapper .search-wrapper .search-dropdown-widget,
.engineer-reserve-list .search-wrapper .search-dropdown-widget,
.allocate-to-invoices .search-wrapper .search-dropdown-widget {
  width: 100%;
}
.import_options_wrapper .search-wrapper .search-dropdown-widget .search_box,
.engineer-reserve-list .search-wrapper .search-dropdown-widget .search_box,
.allocate-to-invoices .search-wrapper .search-dropdown-widget .search_box {
  border: 1px solid #b8bec8;
  width: 100%;
  margin: 10px 0px !important;
}
.import_options_wrapper .search-wrapper .search-dropdown-widget .search_box input,
.engineer-reserve-list .search-wrapper .search-dropdown-widget .search_box input,
.allocate-to-invoices .search-wrapper .search-dropdown-widget .search_box input {
  width: 100%;
  margin-left: 0;
  position: absolute;
  left: 32px;
  top: 0px;
  right: 0px;
}
.import_options_wrapper .search-wrapper .search-dropdown-widget .search_box .ss-search,
.engineer-reserve-list .search-wrapper .search-dropdown-widget .search_box .ss-search,
.allocate-to-invoices .search-wrapper .search-dropdown-widget .search_box .ss-search {
  margin: 3px 6px 0 12px !important;
  float: left;
}
.import_options_wrapper .search-wrapper.with-border,
.engineer-reserve-list .search-wrapper.with-border,
.allocate-to-invoices .search-wrapper.with-border {
  border: solid 1px #d3d3d3;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.system_settings_sortable,
.pretty-list {
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #ffffff;
  margin: 0;
  height: 204px;
  overflow: hidden;
  position: absolute;
  left: 10px;
  right: 10px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.postal-code-sidepanel {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px - 71px) !important; /* padding40px footer71px */
}
.post-code-list {
    flex: 1;
    overflow: auto;
}
.no-post-code {
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.system_settings_sortable.positionSetUl {
    position: unset;
    margin-top: 16px !important;
    height: 100%;
    overflow: auto;
}

.system_settings_sortable.pretty-list,
.pretty-list.pretty-list {
  height: auto;
  box-shadow: none;
  border: 1px solid #dadada;
}
.system_settings_sortable.pretty-list.inside-modal,
.pretty-list.pretty-list.inside-modal {
  max-height: 210px;
  overflow: auto;
}
.system_settings_sortable.pretty-list.with-top-margin,
.pretty-list.pretty-list.with-top-margin {
  margin-top: 20px;
}
.system_settings_sortable.pretty-list.without-absolute,
.pretty-list.pretty-list.without-absolute {
  position: static;
}
.system_settings_sortable.pretty-list.without-absolute.below-filter,
.pretty-list.pretty-list.without-absolute.below-filter {
  position: relative;
  top: -2px;
  left: 0px;
  right: 0px;
    height: 425px;
    overflow-y: auto;
    width: 698px;
}
.system_settings_sortable.pretty-list.inside-section,
.pretty-list.pretty-list.inside-section {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: 0px;
  border-right: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-right-radius: 0px;
  left: 0px;
  border: 0px;
}
.system_settings_sortable.pretty-list.inside-section:first-of-type,
.pretty-list.pretty-list.inside-section:first-of-type {
  border-top: 0px;
}
.system_settings_sortable.pretty-list.inside-section li,
.pretty-list.pretty-list.inside-section li {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}
.system_settings_sortable.pretty-list.with-overflow,
.pretty-list.pretty-list.with-overflow {
  overflow: visible;
}
.system_settings_sortable.pretty-list.with-overflow li,
.pretty-list.pretty-list.with-overflow li {
  float: none !important;
}
.system_settings_sortable.pretty-list.with-search,
.pretty-list.pretty-list.with-search {
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  border-top: 0px !important;
}
.system_settings_sortable.pretty-list.with-search li:first-of-type,
.pretty-list.pretty-list.with-search li:first-of-type {
  border-top: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}
.system_settings_sortable.pretty-list.without-positioning,
.pretty-list.pretty-list.without-positioning {
  left: 0px;
  right: 0px;
}
.system_settings_sortable.pretty-list.with-status-circles .status-circle,
.pretty-list.pretty-list.with-status-circles .status-circle {
  position: relative;
  top: 0px;
  margin-right: 5px;
  cursor: help;
}
.system_settings_sortable.pretty-list.with-status-circles.with-checkboxes input[type="checkbox"],
.pretty-list.pretty-list.with-status-circles.with-checkboxes input[type="checkbox"] {
  margin: -2px 5px 0px 1px !important;
}
.system_settings_sortable.pretty-list.with-checkboxes label,
.pretty-list.pretty-list.with-checkboxes label {
  margin-bottom: 0px;
}
.system_settings_sortable.pretty-list.with-checkboxes input[type="checkbox"],
.pretty-list.pretty-list.with-checkboxes input[type="checkbox"],
.system_settings_sortable.pretty-list.with-checkboxes input[type="radio"],
.pretty-list.pretty-list.with-checkboxes input[type="radio"] {
  margin: -2px 5px 0px 11px !important;
}
.system_settings_sortable.pretty-list.with-checkboxes li,
.pretty-list.pretty-list.with-checkboxes li {
  cursor: pointer;
}
.system_settings_sortable.pretty-list.with-checkboxes li.no-results-item,
.pretty-list.pretty-list.with-checkboxes li.no-results-item {
  cursor: default;
}
.system_settings_sortable.pretty-list.ui-sortable li,
.pretty-list.pretty-list.ui-sortable li,
.system_settings_sortable.pretty-list.ui-sortable label,
.pretty-list.pretty-list.ui-sortable label {
  cursor: move !important;
}
.system_settings_sortable.pretty-list.with-profile-image span,
.pretty-list.pretty-list.with-profile-image span,
.system_settings_sortable.pretty-list.with-profile-image strong,
.pretty-list.pretty-list.with-profile-image strong {
  line-height: 28px;
  margin-left: 0px;
}
.system_settings_sortable.pretty-list.with-profile-image span.profile_image,
.pretty-list.pretty-list.with-profile-image span.profile_image,
.system_settings_sortable.pretty-list.with-profile-image strong.profile_image,
.pretty-list.pretty-list.with-profile-image strong.profile_image {
  line-height: 20px;
}
.system_settings_sortable.pretty-list.with-profile-image.with-checkboxes input[type="checkbox"],
.pretty-list.pretty-list.with-profile-image.with-checkboxes input[type="checkbox"] {
  margin: -2px 5px 0px 11px !important;
}
.system_settings_sortable.pretty-list li span,
.pretty-list.pretty-list li span {
  margin-bottom: 0px !important;
}
.system_settings_sortable.pretty-list .delete-link,
.pretty-list.pretty-list .delete-link,
.system_settings_sortable.pretty-list .edit-link,
.pretty-list.pretty-list .edit-link {
  float: right;
  cursor: pointer;
  color: #5e6d7f;
}
.system_settings_sortable.pretty-list label.on-right,
.pretty-list.pretty-list label.on-right {
  float: right;
  margin: 1px 0 0 0;
}
.system_settings_sortable.with-small-max-height,
.pretty-list.with-small-max-height {
  max-height: 200px;
  overflow: auto;
}
.system_settings_sortable.slide_left,
.pretty-list.slide_left {
  left: -1300px;
  right: 1300px;
}
.system_settings_sortable.with-columns .sortable_entity,
.pretty-list.with-columns .sortable_entity {
  width: 23%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.system_settings_sortable.with-columns .sortable_options,
.pretty-list.with-columns .sortable_options {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}
.system_settings_sortable li,
.pretty-list li {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  list-style: none;
  padding: 10px 14px;
  border-bottom: 1px solid #dadada;
}
.system_settings_sortable li.disabled-list-element,
.pretty-list li.disabled-list-element {
  opacity: 0.5;
  border-color: #9a9a9a;
  cursor: not-allowed !important;
}
.system_settings_sortable li.disabled-list-element input,
.pretty-list li.disabled-list-element input {
  pointer-events: none;
}
.system_settings_sortable li .item-text.ellipsis,
.pretty-list li .item-text.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.system_settings_sortable li .sortable_entity,
.pretty-list li .sortable_entity {
  float: left;
  margin: 0 10px 0 0;
}
.system_settings_sortable li .sortable_entity.right-label,
.pretty-list li .sortable_entity.right-label {
  float: right;
}
.system_settings_sortable li .sortable_entity.with-cutoff,
.pretty-list li .sortable_entity.with-cutoff {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 77%;
  margin: 0;
}
.system_settings_sortable li .sortable_options,
.pretty-list li .sortable_options {
  float: right;
  display: block;
}
.system_settings_sortable li .sortable_options a,
.pretty-list li .sortable_options a {
  color: #3883c1;
  margin-left: 10px;
}
@link ColorHover;
.system_settings_sortable li .sortable_options .label,
.pretty-list li .sortable_options .label {
  margin-bottom: -4px;
}
.system_settings_sortable li:first-of-type,
.pretty-list li:first-of-type {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.system_settings_sortable li:last-of-type,
.pretty-list li:last-of-type {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.system_settings_sortable li.with-chevron,
.pretty-list li.with-chevron {
  position: relative;
  padding-right: 24px;
}
.system_settings_sortable li .activate-chevron,
.pretty-list li .activate-chevron {
  font-size: 13px;
  position: absolute;
  top: 12px;
  right: 10px;
  color: #3883c1;
}
.system_settings_sortable .record {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.system_settings_sortable .record .sortable_entity,
.system_settings_sortable .record span:first-child {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.system_settings_sortable .record .sortable_entity.default-label,
.system_settings_sortable .record span:first-child.default-label {
  margin-right: 0px;
}
.system_settings_sortable .record .sortable_entity.default-label span,
.system_settings_sortable .record span:first-child.default-label span {
  float: right;
}
.system_settings_sortable .record .sortable_entity .label,
.system_settings_sortable .record span:first-child .label {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.system_settings_sortable .record .right-label {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 0px;
  margin-left: 10px;
  position: relative;
  top: 1px;
}
.system_settings_sortable .record .right-label span {
  display: block;
}
.engineer-reserve-list a,
.add-line-items-to-invoice a,
.view-engineer-feedback a,
.add-line-items-to-credit-note a {
  margin-left: 0px;
}
.engineer-reserve-list .help_section,
.add-line-items-to-invoice .help_section,
.view-engineer-feedback .help_section,
.add-line-items-to-credit-note .help_section {
  box-sizing: initial;
  margin-left: -20px !important;
  flex: 0 auto;
}
.engineer-reserve-list .help_section p,
.add-line-items-to-invoice .help_section p,
.view-engineer-feedback .help_section p,
.add-line-items-to-credit-note .help_section p {
  padding-right: 30px;
}
.engineer-reserve-list .data-section,
.add-line-items-to-invoice .data-section,
.view-engineer-feedback .data-section,
.add-line-items-to-credit-note .data-section {
  margin: 20px;
  width: auto !important;
}
.engineer-reserve-list .engineer-list .add-engineer-button-wrapper,
.add-line-items-to-invoice .engineer-list .add-engineer-button-wrapper,
.view-engineer-feedback .engineer-list .add-engineer-button-wrapper,
.add-line-items-to-credit-note .engineer-list .add-engineer-button-wrapper {
  margin-bottom: 10px;
}
.engineer-reserve-list .engineer-list .add-engineer-button-wrapper button,
.add-line-items-to-invoice .engineer-list .add-engineer-button-wrapper button,
.view-engineer-feedback .engineer-list .add-engineer-button-wrapper button,
.add-line-items-to-credit-note .engineer-list .add-engineer-button-wrapper button {
  float: right;
}
.engineer-reserve-list .engineer-list .search-dropdown-widget,
.add-line-items-to-invoice .engineer-list .search-dropdown-widget,
.view-engineer-feedback .engineer-list .search-dropdown-widget,
.add-line-items-to-credit-note .engineer-list .search-dropdown-widget {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.engineer-reserve-list .engineer-list .search-dropdown-widget select,
.add-line-items-to-invoice .engineer-list .search-dropdown-widget select,
.view-engineer-feedback .engineer-list .search-dropdown-widget select,
.add-line-items-to-credit-note .engineer-list .search-dropdown-widget select {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0px;
  margin-top: 8px;
}
.engineer-reserve-list .engineer-list .search-dropdown-widget .btn,
.add-line-items-to-invoice .engineer-list .search-dropdown-widget .btn,
.view-engineer-feedback .engineer-list .search-dropdown-widget .btn,
.add-line-items-to-credit-note .engineer-list .search-dropdown-widget .btn {
  float: right;
  margin: 10px 0px 10px 20px !important;
}
.engineer-reserve-list .engineer-list .list-wrapper,
.add-line-items-to-invoice .engineer-list .list-wrapper,
.view-engineer-feedback .engineer-list .list-wrapper,
.add-line-items-to-credit-note .engineer-list .list-wrapper {
  overflow: auto;
  width: 100%;
}
.engineer-reserve-list .engineer-list .list-wrapper li label,
.add-line-items-to-invoice .engineer-list .list-wrapper li label,
.view-engineer-feedback .engineer-list .list-wrapper li label,
.add-line-items-to-credit-note .engineer-list .list-wrapper li label {
  margin-bottom: 0px;
}
.engineer-reserve-list .engineer-list .list-wrapper li .delete-link,
.add-line-items-to-invoice .engineer-list .list-wrapper li .delete-link,
.view-engineer-feedback .engineer-list .list-wrapper li .delete-link,
.add-line-items-to-credit-note .engineer-list .list-wrapper li .delete-link {
  position: relative;
  top: 2px;
}
.engineer-reserve-list .engineer-list .list-wrapper li select,
.add-line-items-to-invoice .engineer-list .list-wrapper li select,
.view-engineer-feedback .engineer-list .list-wrapper li select,
.add-line-items-to-credit-note .engineer-list .list-wrapper li select {
  margin-bottom: 0px;
}
.engineer-reserve-list .engineer-list .list-wrapper li button,
.add-line-items-to-invoice .engineer-list .list-wrapper li button,
.view-engineer-feedback .engineer-list .list-wrapper li button,
.add-line-items-to-credit-note .engineer-list .list-wrapper li button {
  float: right;
  margin-top: 2px;
}
.engineer-reserve-list .engineer-list .list-wrapper .pretty-list,
.add-line-items-to-invoice .engineer-list .list-wrapper .pretty-list,
.view-engineer-feedback .engineer-list .list-wrapper .pretty-list,
.add-line-items-to-credit-note .engineer-list .list-wrapper .pretty-list {
  margin-bottom: 20px;
  top: 0px;
}
.engineer-reserve-list .engineer-list .no-results,
.add-line-items-to-invoice .engineer-list .no-results,
.view-engineer-feedback .engineer-list .no-results,
.add-line-items-to-credit-note .engineer-list .no-results {
  background: #ffffff;
  top: -12px;
  position: relative;
  padding: 30px;
  border: solid 1px #d3d3d3;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.engineer-reserve-list .engineer-list .no-results .icon,
.add-line-items-to-invoice .engineer-list .no-results .icon,
.view-engineer-feedback .engineer-list .no-results .icon,
.add-line-items-to-credit-note .engineer-list .no-results .icon {
  top: 0px;
}
.engineer-reserve-list .engineer-list .with-gray-background,
.add-line-items-to-invoice .engineer-list .with-gray-background,
.view-engineer-feedback .engineer-list .with-gray-background,
.add-line-items-to-credit-note .engineer-list .with-gray-background {
  background: #eeeeee;
  border-bottom: 0px;
}
.engineer-reserve-list .engineer-list .with-gray-background .btn,
.add-line-items-to-invoice .engineer-list .with-gray-background .btn,
.view-engineer-feedback .engineer-list .with-gray-background .btn,
.add-line-items-to-credit-note .engineer-list .with-gray-background .btn {
  float: right;
  margin: 6px 0px;
}
.engineer-reserve-list .engineer-list .with-gray-background select,
.add-line-items-to-invoice .engineer-list .with-gray-background select,
.view-engineer-feedback .engineer-list .with-gray-background select,
.add-line-items-to-credit-note .engineer-list .with-gray-background select {
  margin-bottom: 0px;
  margin-top: 4px;
  width: 320px;
}
.engineer-reserve-list .engineer-list.filter-engineers,
.add-line-items-to-invoice .engineer-list.filter-engineers,
.view-engineer-feedback .engineer-list.filter-engineers,
.add-line-items-to-credit-note .engineer-list.filter-engineers {
  margin-top: 15px;
}
.engineer-reserve-list .engineer-list.filter-engineers .list-wrapper,
.add-line-items-to-invoice .engineer-list.filter-engineers .list-wrapper,
.view-engineer-feedback .engineer-list.filter-engineers .list-wrapper,
.add-line-items-to-credit-note .engineer-list.filter-engineers .list-wrapper {
  position: relative;
  overflow: visible;
}
.engineer-reserve-list .full-width-smart-filter,
.add-line-items-to-invoice .full-width-smart-filter,
.view-engineer-feedback .full-width-smart-filter,
.add-line-items-to-credit-note .full-width-smart-filter {
  margin-top: 15px;
}
.engineer-reserve-list.add-line-items-to-credit-note .diary-list-view,
.add-line-items-to-invoice.add-line-items-to-credit-note .diary-list-view,
.view-engineer-feedback.add-line-items-to-credit-note .diary-list-view,
.add-line-items-to-credit-note.add-line-items-to-credit-note .diary-list-view {
  padding: 15px 20px;
}
.section-title.with-inline-helper .view-engineer-feedback,
.section-title.with-inline-helper .add-line-items-to-credit-note {
  width: 900px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main {
  padding: 0px !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main #page-panel-title-bar,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main #page-panel-title-bar {
  margin-bottom: 20px !important;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide ul,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide ul {
  margin: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide ul.pretty-list,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide ul.pretty-list {
  margin: 20px 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide ul.pretty-list li .profile_image .image_wrapper,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide ul.pretty-list li .profile_image .image_wrapper {
  border-left-width: 1px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide ul.pretty-list li.without-padding,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide ul.pretty-list li.without-padding {
  padding: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide ul.pretty-list li .ss-check,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide ul.pretty-list li .ss-check {
  margin-left: 0px;
  color: #60a369;
  padding-right: 0px;
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide .help_section,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide .help_section {
  margin-bottom: 0px;
  padding-left: 60px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide .help_section p,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide .help_section p {
  padding-right: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide .help_section p span,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide .help_section p span {
  margin-left: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .slide.with-padding .help_section,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .slide.with-padding .help_section {
  margin-bottom: 0px;
  padding-left: 40px;
  width: 600px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter {
  float: none;
  margin: 0px 20px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .filter-block,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .filter-block {
  padding: 0px 10px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .search_box,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .search_box {
  margin-left: 10px;
  margin-right: 10px;
  width: 395px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .search_box.with-milestone-filter,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .search_box.with-milestone-filter {
  width: 186px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .search_box.with-milestone-filter input,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .search_box.with-milestone-filter input {
  width: 151px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .search_box .ss-search,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .search_box .ss-search {
  margin-left: 7px !important;
  margin-right: 4px !important;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .search_box input,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .search_box input {
  padding-top: 0px;
  width: 360px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter .filter-label,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter .filter-label {
  margin: 11px 6px 0 0;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .app-filter select,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .app-filter select {
  width: 120px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .timeline-no-results .warning-icon-wrap,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .timeline-no-results .warning-icon-wrap {
  margin-top: 90px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .timeline-no-results .helper-arrow,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .timeline-no-results .helper-arrow,
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .timeline-no-results .helper-text,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .timeline-no-results .helper-text {
  margin-top: -65px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0px;
  height: auto;
  padding-top: 15px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline-container,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline-container {
  position: relative;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #ffffff;
  margin-top: 15px;
  border-top: solid 1px #dadada;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .line,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .line {
  margin-bottom: 0px;
  left: 51px;
  top: 0px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline {
  top: 0px;
  padding-left: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline span,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline span {
  margin-left: 0px;
  margin-bottom: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline strong,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline strong {
  padding-right: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-icon span,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-icon span {
  margin: 8px 0 0 0 !important;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline input[type="checkbox"],
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline input[type="checkbox"] {
  margin: 4px 0px 0px 10px !important;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .ss-check,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .ss-check {
  margin-left: 10px;
  color: #60a369;
  padding-right: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .event-time-icon,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .event-time-icon {
  margin-right: 5px;
  margin-left: 10px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .event-time-icon:first-child,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .event-time-icon:first-child {
  margin-left: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .event-time-icon + .tooltip,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .event-time-icon + .tooltip {
  margin-left: -4px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .engineer-name.without-border,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .engineer-name.without-border {
  border-right: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .diary-event-time,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .diary-event-time {
  margin-left: 10px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .diary-event-time.without-border,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .diary-event-time.without-border {
  border: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .status-wrapper,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .status-wrapper {
  display: inline;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .label,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .label {
  top: 1px;
  margin-right: -10px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-icon,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-icon {
  margin-left: -8px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner {
  margin-left: 46px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .field,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .field {
  margin-bottom: 5px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .field span,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .field span {
  max-width: 340px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .question,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .question {
  margin-bottom: 10px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .question .question-name,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .question .question-name {
  font-weight: bold;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .question .inline-no-results-wrap,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .question .inline-no-results-wrap {
  margin-left: 22px;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .question .question-answer,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .question .question-answer {
  margin-left: 40px;
  margin-top: 5px;
  display: inline-block;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item-inner .question .question-answer .ss-quote,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item-inner .question .question-answer .ss-quote {
  margin: 0px 4px !important;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item.selected-event .item-inner,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item.selected-event .item-inner {
  -webkit-box-shadow: 0 0 4px 2px #3b7aa6;
  -moz-box-shadow: 0 0 4px 2px #3b7aa6;
  box-shadow: 0 0 4px 2px #3b7aa6;
}
.section-title.with-inline-helper .view-engineer-feedback #page-panel-main .diary-list-view .timeline .item.selected-event .item-inner::before,
.section-title.with-inline-helper .add-line-items-to-credit-note #page-panel-main .diary-list-view .timeline .item.selected-event .item-inner::before {
  -webkit-filter: drop-shadow(-2px 0px 1px #3b7aa6);
  filter: drop-shadow(-2px 0px 1px #3b7aa6);
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field:first-of-type,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field:first-of-type {
  margin-top: 10px;
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field .diary-event-status,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field .diary-event-status {
  font-size: 18px !important;
  margin-left: 25px;
  position: relative;
  top: 2px;
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field .field-label,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field .field-label {
  margin-left: 20px;
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field span,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field span {
  max-width: 420px;
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field .inline-no-results-wrap,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field .inline-no-results-wrap {
  margin-left: -28px;
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field .ss-quote,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field .ss-quote {
  margin-left: 0px;
}
.section-title.with-inline-helper .view-engineer-feedback .form-wrapper .field .diary-event-status-label,
.section-title.with-inline-helper .add-line-items-to-credit-note .form-wrapper .field .diary-event-status-label {
  margin-left: 10px;
}
.add-line-items-to-invoice .pretty-list,
.add-line-items-to-credit-note .pretty-list,
#select-service-date .pretty-list {
  position: static;
}
.add-line-items-to-invoice .pretty-list li .line-item-wrapper,
.add-line-items-to-credit-note .pretty-list li .line-item-wrapper,
#select-service-date .pretty-list li .line-item-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.add-line-items-to-invoice .pretty-list li .line-item-wrapper .description,
.add-line-items-to-credit-note .pretty-list li .line-item-wrapper .description,
#select-service-date .pretty-list li .line-item-wrapper .description {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.add-line-items-to-invoice .pretty-list li .line-item-wrapper .quantity,
.add-line-items-to-credit-note .pretty-list li .line-item-wrapper .quantity,
#select-service-date .pretty-list li .line-item-wrapper .quantity,
.add-line-items-to-invoice .pretty-list li .line-item-wrapper .amount,
.add-line-items-to-credit-note .pretty-list li .line-item-wrapper .amount,
#select-service-date .pretty-list li .line-item-wrapper .amount {
  opacity: 0.7;
  margin-left: 20px;
  font-size: 14px;
}
.add-line-items-to-invoice .pretty-list li .line-item-wrapper .quantity.amount,
.add-line-items-to-credit-note .pretty-list li .line-item-wrapper .quantity.amount,
#select-service-date .pretty-list li .line-item-wrapper .quantity.amount,
.add-line-items-to-invoice .pretty-list li .line-item-wrapper .amount.amount,
.add-line-items-to-credit-note .pretty-list li .line-item-wrapper .amount.amount,
#select-service-date .pretty-list li .line-item-wrapper .amount.amount {
  width: 70px;
  display: inline-block;
  text-align: right;
}
.add-line-items-to-invoice .pretty-list.with-profile-image.with-checkboxes input[type="checkbox"],
.add-line-items-to-credit-note .pretty-list.with-profile-image.with-checkboxes input[type="checkbox"],
#select-service-date .pretty-list.with-profile-image.with-checkboxes input[type="checkbox"] {
  margin: 8px 10px 0px 0px !important;
}
.add-line-items-to-invoice .no-results,
.add-line-items-to-credit-note .no-results,
#select-service-date .no-results {
  top: 0px !important;
  margin-bottom: 20px;
}
.add-line-items-to-invoice .no-results .warning-icon-wrap .icon,
.add-line-items-to-credit-note .no-results .warning-icon-wrap .icon,
#select-service-date .no-results .warning-icon-wrap .icon {
  font-size: 34px;
}
.add-line-items-to-invoice .buttons a,
.add-line-items-to-credit-note .buttons a,
#select-service-date .buttons a {
  margin: 0px !important;
  margin-left: 20px !important;
}
#select-service-date #diary-list-view .cs-event-header {
  left: 0px;
  min-height: 60px;
  width: 100%;
  border-bottom: 0px;
  overflow: visible;
}
#select-service-date #diary-list-view .cs-event-header:before,
#select-service-date #diary-list-view .cs-event-header:after {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#select-service-date #diary-list-view table.diary-list-view-main td:nth-child(1) {
  min-width: 23px;
  max-width: 23px;
  width: 23px;
  padding: 0px;
}
#select-service-date #diary-list-view table.diary-list-view-main td:nth-child(2) {
  min-width: 110px;
  max-width: 110px;
  width: 110px;
  position: relative;
  padding: 5px 10px 0px 0px;
  box-sizing: content-box;
}
#select-service-date #diary-list-view table.diary-list-view-main td:nth-child(2) .diary-image {
  position: absolute;
  left: -49px;
  top: -5px;
  width: 20px;
  height: 20px;
  border: solid 1px #bdc5cb;
  text-align: center;
  box-sizing: border-box;
}
#select-service-date #diary-list-view table.diary-list-view-main td:nth-child(2) .diary-image img {
  float: left;
}
#select-service-date #diary-list-view table.diary-list-view-main td:nth-child(2) .cs-event-header-upper .cs-event-header-time {
  left: 0px;
}
#select-service-date #diary-list-view table.diary-list-view-main td:nth-child(2) .cs-event-header-lower .cs-event-header-user span:last-of-type {
  left: 6px;
}
#select-service-date #diary-list-view img {
  top: -5px;
  left: -27px;
}
#select-service-date #diary-list-view .cs-event-header-status {
  position: relative;
  top: 38px;
  left: 6px;
}
#select-service-date #diary-list-view input[type="radio"] {
  margin: 8px !important;
}
.add-milestone-button {
  float: right;
  margin: 7px 0px 0px 10px !important;
}
.btn.push-down {
  margin-top: 8px !important;
}
.static-shelf li {
  height: auto !important;
}
.static-shelf li .milestone-title {
  max-width: 150px;
  line-height: 20px;
  display: inline-block;
  padding-top: 3px;
}
.static-shelf li .label-wrapper {
  float: right;
  text-align: right;
  width: 40px;
  display: inline-block;
}
.static-shelf li.milestone-header .left-padding {
  padding-left: 30px;
}
.breakdown-options input {
  margin-top: 6px;
}
.breakdown-options .radio-label {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 5px;
}
.breakdown-options .radio-label input {
  position: relative;
  top: -5px;
  margin-right: 5px;
}
.estimate-details-wrapper.narrow {
  left: 300px;
}
.estimate-details-wrapper.on-right {
  right: 220px;
  padding: 0px 30px 0px 0px;
  bottom: 0px;
  overflow: auto;
}
.estimate-details-wrapper.smaller-top-spacing {
  padding-top: 10px;
}
.estimate-details-wrapper.project-costs {
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.estimate-details-wrapper .download_options {
  float: right;
}
.estimate-details-wrapper .download_options a {
  margin: 10px 0 0 10px;
}
section.smaller-top-padding.fix-top-margin {
  margin-top: -10px;
}
.view-controls .engineers {
  margin: 0 15px 0 0;
  padding: 0px;
  float: left;
}
.view-controls .engineers li {
  float: right !important;
  width: auto;
  margin: -5px 0 0 8px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border 0.1s ease-in;
  -moz-transition: border 0.1s ease-in;
  -o-transition: border 0.1s ease-in;
  transition: border 0.1s ease-in;
  cursor: pointer;
  list-style-type: none;
  position: relative;
  top: 3px;
}
.view-controls .engineers li span {
  margin: 0px;
  padding: 0px;
}
.view-controls .engineers li .image_wrapper .avatar {
  opacity: 0.5;
}
.view-controls .engineers li.active .image_wrapper .avatar {
  opacity: 1;
}
.additional-works-no-results .no-results-wrap .helper-arrow {
  left: 340px;
  right: auto;
}
.additional-works-no-results .no-results-wrap .helper-text {
  left: 160px;
  right: auto;
}
.section-title a.on-left {
  float: left;
}
.job-costings-scrollwrapper {
  position: absolute;
  top: 0px;
  bottom: 125px;
  left: 0px;
  right: 0px;
  overflow: auto;
  padding: 20px 30px;
}
.job-costings-scrollwrapper.with-left-nav {
  left: 270px;
}
.job-costings-scrollwrapper .no-results-wrap .helper-text {
  right: 170px;
}
.job-costings-scrollwrapper .no-results-wrap .helper-arrow {
  margin-right: 40px;
}
.job-costings-totals-section {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding-right: 48px;
  height: 125px;
  padding-top: 23px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px -5px 2px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -5px 2px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -5px 2px -2px rgba(0, 0, 0, 0.1);
}
.job-costings-totals-section.with-left-nav {
  left: 270px;
}
.job-costings-totals-section ul {
  width: 603px;
}
.job-costings-totals-section ul li {
  width: auto;
  display: inline-block;
}
.job-costings-totals-section ul li .total-label {
  border-bottom: solid 1px #e1e1e1;
}
.job-costings-totals-section ul li .total-value {
  border-bottom: 0px;
  border-right: solid 1px #e1e1e1;
}
.job-costings-totals-section ul li:last-of-type .total-value,
.job-costings-totals-section ul li:last-of-type .total-label {
  border-right: 0px;
}
.invoice-choose-jobs-wrapper {
  padding-bottom: 40px;
}
.complete-job #page-panel-main {
  padding-left: 0px !important;
}
.complete-job .diary-step:nth-child(1) {
  top: 0px;
  -webkit-transform: translate(0px, -1px);
  -moz-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  -o-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}
.complete-job .diary-step:nth-child(2) {
  bottom: 44px;
  margin-top: -132px;
}
.complete-job .diary-step:nth-child(2).step-open {
  top: 174px;
  bottom: 88px;
}
.complete-job .diary-step:nth-child(3) {
  bottom: 0px;
  margin-top: -88px;
}
.complete-job .diary-step:nth-child(3).step-open {
  top: 173px;
  bottom: 44px;
}
.complete-job .diary-step:nth-child(4) {
  bottom: 0px;
  margin-top: -44px;
}
.complete-job .diary-step:nth-child(4).step-open {
  top: 172px;
}
.complete-job .diary-step .help-section-full-width {
  background: #ffffff;
  margin-left: -20px;
  padding: 20px 20px;
  width: 100%;
  margin-top: -20px;
  border-bottom: solid 1px #dadada;
}
.complete-job .diary-step .data-section {
  margin: 15px 0px !important;
  background: #f9f9f9;
}
.complete-job .diary-step .data-section.information-bar {
  margin: 0px !important;
  background: #f9f3de;
}
.complete-job .diary-step .data-section #page-panel-title-bar {
  margin-left: -10px !important;
  margin-right: 0px;
  border-top: 0px !important;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  padding: 0px 10px !important;
}
.complete-job .diary-step .data-section .form-wrapper {
  padding: 50px 10px 0px;
}
.complete-job .diary-step .data-section .form-wrapper input {
  float: left;
  margin-right: 10px;
}
.complete-job .diary-step .data-section .form-wrapper label {
  float: left;
  margin-bottom: 0px;
}
.complete-job .diary-step .data-section .form-wrapper .help-block {
  display: inline-block;
  margin: 5px 0px 15px 22px;
}
.complete-job ul li .with-width {
  display: inline-block;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 16px;
  position: relative;
  top: 3px;
}
.complete-job ul li .todo-max-width {
  width: 265px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  line-height: 15px;
  position: relative;
  top: 3px;
}
.complete-job ul li .second-line-content {
  display: block;
  margin-left: 20px;
  margin-top: 10px;
}
.complete-job .page-panel-actions {
  padding-right: 70px !important;
}
.configure-job-pricing-columns #page-panel-main .controls {
  margin-left: 0px;
}
.configure-job-pricing-columns #page-panel-main .control-group.with-checkbox input {
  margin: 0px 10px;
}
.static-shelf.jobs {
  padding: 0px;
  box-sizing: border-box;
}
.static-shelf.jobs .title {
  position: relative;
  top: 10px;
  left: 10px;
  display: inline-block;
  height: 27px;
}
.static-shelf.jobs .search-bar-with-bg {
  margin-top: 13px;
}
.static-shelf.jobs .search-bar-with-bg .search_box {
  width: 190px;
}
.static-shelf.jobs .search-bar-with-bg .search_box input {
  width: 148px;
}
.static-shelf.jobs .outstanding-top-bar .tooltip {
  margin: -2px 0 0 0 !important;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li {
  float: right !important;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(1):hover {
  border-color: #f3d3d3;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(1).active {
  background: #faebeb;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(1) span {
  background: #d25656;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(2):hover {
  border-color: #cedde8;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(2).active {
  background: #e3ecf2;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(2) span {
  background: #6393b5;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(3):hover {
  border-color: #d1cee2;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(3).active {
  background: #e3e2ed;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(3) span {
  background: #746ca6;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(4):hover {
  border-color: #c4ddc7;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(4).active {
  background: #d8e8da;
}
.static-shelf.jobs .outstanding-top-bar .color-keys li:nth-of-type(4) span {
  background: #60a369;
}
.static-shelf.jobs .ticket-wrapper {
  position: absolute;
  top: 82px;
  bottom: 0px;
  overflow: auto;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #fdfdfd;
  border-right: 0px;
}
.static-shelf.jobs .ticket-wrapper.search-active {
  top: 121px;
}
.static-shelf.jobs .ticket-wrapper .no-search-results-wrapper {
  margin: 20px;
  font-size: 14px;
}
.static-shelf.jobs .ticket-wrapper .diary-bar-sections .group-title {
  margin-left: 0px;
}
.static-shelf.jobs .ticket-wrapper .ticket-header {
  background: rgba(238, 238, 238, 0.7) !important;
}
.static-shelf.jobs .ticket-wrapper .milestone-ticket-wrapper {
  padding: 10px;
  box-sizing: border-box;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner {
  border-left: 1px solid rgba(208, 208, 208, 0.7) !important;
  border-right: 1px solid rgba(208, 208, 208, 0.7);
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .on-dragging .ss-ellipsis {
  opacity: 0;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .transparent-drag-helper {
  left: -20px;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .drag-handle {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 21px;
  cursor: move;
  background-color: #fbfbfb !important;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  box-sizing: border-box;
  border: 1px solid #dadada;
  border-left: 0px;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .drag-handle .ss-grid {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 8px;
  font-size: 11px;
  width: 8px;
  overflow: hidden;
  height: 8px;
  margin-left: -4px;
  color: #888;
  position: absolute;
  top: 50%;
  left: 50%;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .drag-handle .ss-grid:first-of-type {
  margin-top: -8px;
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .drag-handle.disabled {
  cursor: not-allowed;
  background: rgba(251, 251, 251, 0.002);
}
.static-shelf.jobs .ticket-wrapper .ticket-inner .drag-handle.disabled * {
  opacity: 0.2;
}
.static-shelf.jobs .ticket-inner {
  margin-bottom: 10px;
}
.static-shelf.jobs .ticket-inner .ticket-header {
  border-bottom: 1px solid rgba(208, 208, 208, 0.7);
}
.static-shelf.jobs .ticket-inner p.outstanding-address {
  margin: 2px 0 0 0;
}
#side-panel.view-diary-event {
  margin-right: 0px;
  padding-right: 0px !important;
}
#side-panel.view-diary-event .span12 {
  width: 100%;
  display: inline-block;
}
#side-panel.view-diary-event .span12.with-right-padding {
  padding-right: 0px;
}
#side-panel.view-diary-event #page-panel-title-bar .right-side {
  margin-right: 20px;
}
#side-panel.view-diary-event #page-panel-title-bar .normal-link,
#side-panel.view-diary-event #page-panel-title-bar .breadcrumb-link {
  color: #3883c1;
}
#side-panel.view-diary-event #page-panel-title-bar .normal-link:hover,
#side-panel.view-diary-event #page-panel-title-bar .breadcrumb-link:hover {
  color: #265882;
}
#side-panel.view-diary-event #page-panel-title-bar .normal-link.normal-link,
#side-panel.view-diary-event #page-panel-title-bar .breadcrumb-link.normal-link {
  font-size: 14px;
  margin-top: 10px;
}
#side-panel.view-diary-event #breadcrumb-title-bar .ss-download {
  margin-top: 10px;
  margin-left: 10px;
  color: #797979;
  position: relative;
  top: 2px;
}
#side-panel.view-diary-event #breadcrumb-title-bar .diary-event-status {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
#side-panel.view-diary-event #page-panel-main {
  position: absolute;
  top: 41px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
    height: calc(100vh - 41px - 71px); /* topheaderheight(41px) bottomfooterheight(71px) */
}
#side-panel.view-diary-event #page-panel-main .loading-placeholder {
  text-align: center;
}
#side-panel.view-diary-event #page-panel-main .loading-placeholder p {
  margin-top: 10px;
  color: #8dadb9;
}
#side-panel.view-diary-event #page-panel-main .no-results-wrap {
  clear: both;
}
#side-panel.view-diary-event #page-panel-main .with-cancelled-bar .slide {
  top: 62px;
}
#side-panel.view-diary-event #page-panel-main .cancelled-bar .span12 .information-bar {
  margin: 15px 0px 0px;
}
#side-panel.view-diary-event #page-panel-main .cancelled-bar .span12 .information-bar .span12 {
  border-bottom: 0px;
}
#side-panel.view-diary-event #page-panel-main .cancelled-bar .span12 .information-bar span {
  margin-bottom: 0px;
}
#side-panel.view-diary-event #page-panel-main .view-diary-event-main-view {
  position: absolute;
  top: 36px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
  overflow-x: hidden;
  padding: 0px 20px;
  padding-bottom: 20px;
}
#side-panel.view-diary-event #page-panel-main .view-diary-event-main-view.without-tabs {
  top: 0px;
}
#side-panel.view-diary-event #page-panel-main.view-job-sheet-view,
#side-panel.view-diary-event #page-panel-main.view-certificate-view,
#side-panel.view-diary-event #page-panel-main.centered-content {
  text-align: center;
}
#side-panel.view-diary-event #page-panel-main #page-panel-title-bar .right-side {
  margin-right: 0px;
}
#side-panel.view-diary-event #page-panel-main .help_section.full-width {
  margin-left: -20px;
  padding: 20px 20px 10px 30px;
}
#side-panel.view-diary-event #page-panel-main .help_section.full-width p {
  padding-right: 10px;
}
#side-panel.view-diary-event .tab-group.view-diary-event {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 36px;
  border-bottom: solid 15px #ffffff;
  z-index: 1;
}
#side-panel.view-diary-event .tab-group.view-diary-event .tab.active {
  background: transparent;
}
#side-panel.view-diary-event .field.with-image {
  margin-top: 3px;
  margin-bottom: 3px;
}
#side-panel.view-diary-event .field.with-image .field-label {
  margin-bottom: 0px;
  line-height: 30px;
}
#side-panel.view-diary-event .field.with-image .image_wrapper {
  margin-right: 0px;
}
#side-panel.view-diary-event .field.with-image img {
  margin-left: 20px;
}
#side-panel.view-diary-event .field.with-image .engineer-name {
  margin-left: 10px;
}
#side-panel.view-diary-event .field.with-quotes span {
  padding-bottom: 0px;
  margin-bottom: 0px !important;
}
#side-panel.view-diary-event .field.audit strong {
  float: none;
}
#side-panel.view-diary-event .field.audit span {
  margin-left: 0px;
}
#side-panel.view-diary-event .field .multi-line {
  color: #505b65;
  display: inline-block;
  max-width: 450px;
  margin-bottom: 5px;
  min-height: 15px;
}
#side-panel.view-diary-event .field .event-description {
  max-width: 420px;
}
#side-panel.view-diary-event .field .without-margin {
  margin-left: 0px;
}
#side-panel.view-diary-event .field .status-icon {
  margin-bottom: 0px;
  position: relative;
  top: 3px;
  margin-right: 4px;
  font-size: 18px;
  margin-left: 25px;
}
#side-panel.view-diary-event .field .status-icon.red {
  color: #d25656;
}
#side-panel.view-diary-event .field .status-icon.blue {
  color: #6393b5;
}
#side-panel.view-diary-event .field .status-icon.green {
  color: #60a369;
}
#side-panel.view-diary-event .ss-quote {
  font-size: 9px;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  top: -4px;
  margin: 0px 2px;
  display: inline;
  line-height: 4px;
}
#side-panel.view-diary-event .ss-quote.less-top {
  top: -10px;
}
#side-panel.view-diary-event .ss-quote.rotated {
  display: inline-block;
  transform: rotate(180deg);
  top: -15px;
}
#side-panel.view-diary-event .ss-quote.rotated.less-top {
  top: -8px;
}
#side-panel.view-diary-event .details .send-confirmation,
#side-panel.view-diary-event .details .confirm-confirmation {
  position: relative;
}
#side-panel.view-diary-event .details .send-confirmation .confirmation-link,
#side-panel.view-diary-event .details .confirm-confirmation .confirmation-link {
  position: absolute;
  right: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#side-panel.view-diary-event .details .send-confirmation .confirmation-link.cancel,
#side-panel.view-diary-event .details .confirm-confirmation .confirmation-link.cancel {
  right: 63px;
}
#side-panel.view-diary-event .details .send-confirmation span,
#side-panel.view-diary-event .details .confirm-confirmation span {
  max-width: 330px;
}
#side-panel.view-diary-event .chzn-results {
  margin-top: -2px !important;
}
#side-panel.view-diary-event .details .label,
#side-panel.view-diary-event .pretty-list .label {
  float: none !important;
  position: relative;
  top: -1px;
}
#side-panel.view-diary-event .details .label img,
#side-panel.view-diary-event .pretty-list .label img {
  max-width: 100%;
  border: solid 1px #c9c9c9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#side-panel.view-diary-event .details.details .label,
#side-panel.view-diary-event .pretty-list.details .label {
  margin-left: 0px;
}
#side-panel.view-diary-event .pretty-list-title {
  margin: 15px 15px 5px;
  display: inline-block;
}
#side-panel.view-diary-event .pretty-list.todos {
  overflow: visible;
  width: 100%;
  position: relative;
  left: 0px;
  top: 20px;
}
#side-panel.view-diary-event .pretty-list.pricing-items-to-merge {
  top: 0px;
}
#side-panel.view-diary-event .pretty-list.diary-event-todos-list .helper-arrow {
  -ms-transform: rotateX(164deg) rotate(-31deg);
  -webkit-transform: rotateX(164deg) rotate(-31deg);
  transform: rotateX(164deg) rotate(-31deg);
  right: auto;
  left: 140px;
}
#side-panel.view-diary-event .pretty-list li strong,
#side-panel.view-diary-event .pretty-list li span.bold {
    display: inline-block;
    width: 100%;
/*
    width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
*/
    margin-left: 5px;
    float: left;
}
#side-panel.view-diary-event .pretty-list li strong.without-width,
#side-panel.view-diary-event .pretty-list li span.bold.without-width {
  width: auto;
}
#side-panel.view-diary-event .pretty-list li .status-circle {
  float: left;
  margin: 5px;
}
#side-panel.view-diary-event .pretty-list li input {
  float: left;
  margin: 5px !important;
}
#side-panel.view-diary-event .pretty-list li .todo-detail .audit {
  margin-bottom: 5px;
}
#side-panel.view-diary-event .pretty-list li .todo-detail .audit strong {
  margin-left: 40px;
  width: 105px;
  float: left;
}
#side-panel.view-diary-event .pretty-list li .todo-detail .audit.larger strong {
  width: 140px;
}
#side-panel.view-diary-event .pretty-list li .todo-detail .audit .view-link {
  float: right;
  margin-left: 15px;
}
#side-panel.view-diary-event .pretty-list li .todo-description {
  width: 300px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0px;
  float: left;
}
#side-panel.view-diary-event .pretty-list li .todo-description.extra-top {
  top: 5px;
}
#side-panel.view-diary-event .pretty-list li .label {
  float: right;
}
#side-panel.view-diary-event .pretty-list li .tooltip {
  margin-top: -5px;
}
#side-panel.view-diary-event .pretty-list li .tooltip-arrow {
  margin-left: -5px;
}
#side-panel.view-diary-event .pretty-list li .ss-view,
#side-panel.view-diary-event .pretty-list li .ss-delete {
  float: right;
  margin-top: 12px;
  cursor: pointer;
  line-height: 0px;
  margin-left: 26px;
}
#side-panel.view-diary-event .pretty-list li .todo-link {
  float: right !important;
  margin-left: 10px;
}
#side-panel.view-diary-event .pretty-list li .todo-link.cancelled-status {
  margin-top: 2px;
}
#side-panel.view-diary-event .pretty-list li .ss-navigateright,
#side-panel.view-diary-event .pretty-list li .ss-navigatedown {
  position: relative;
  top: 2px;
  margin-right: 3px;
  float: left;
}
#side-panel.view-diary-event .pretty-list li .ss-navigateright.hiding,
#side-panel.view-diary-event .pretty-list li .ss-navigatedown.hiding {
  opacity: 0.5;
  -webkit-filter: grayscale(50%);
  pointer-events: none;
}
#side-panel.view-diary-event .pretty-list li .field:after {
  clear: both;
  content: "";
  display: block;
}
#side-panel.view-diary-event .pretty-list li .field span {
  margin-bottom: 5px !important;
  max-width: 410px;
  float: left;
}
#side-panel.view-diary-event .pretty-list li .field.with-quotes span {
  margin-bottom: 0px !important;
}
#side-panel.view-diary-event .pretty-list li .field .btn {
  top: -3px;
  float: none;
  display: inline-block;
  margin-right: 0px;
}
#side-panel.view-diary-event .pretty-list li.todos-no-results {
  height: 0px;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
#side-panel.view-diary-event .pretty-list li.todos-no-results.active {
  opacity: 1;
  height: 149px;
}
#side-panel.view-diary-event .pretty-list.parts li strong,
#side-panel.view-diary-event .pretty-list.parts li span.bold {
  width: 190px;
  margin-right: 5px;
}
#side-panel.view-diary-event .pretty-list.parts li .instructions {
  display: inline-block;
  width: 330px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
  position: relative;
  top: 3px;
}
#side-panel.view-diary-event .pretty-list.parts li.part-details-showing strong,
#side-panel.view-diary-event .pretty-list.parts li.part-details-showing span.bold {
  width: 490px;
}
#side-panel.view-diary-event .pretty-list.assign-todos .status-circle {
  top: 8px;
}
#side-panel.view-diary-event .pretty-list.assign-todos li span.bold {
  margin-bottom: 0px;
  top: 5px;
}
#side-panel.view-diary-event .pretty-list.confirmations li span.bold {
    width: 190px;
    margin-right: 5px;
}

#side-panel.view-diary-event .pretty-list.questions li span.bold,
#side-panel.view-diary-event .pretty-list.signatures li span.bold,
#side-panel.view-diary-event .pretty-list.add-remove-todos li span.bold {
/*    width: 190px;
*/
    width: 100%;
    margin-right: 5px;
}
#side-panel.view-diary-event .pretty-list.confirmations li .confirmation-decision .field,
#side-panel.view-diary-event .pretty-list.questions li .confirmation-decision .field,
#side-panel.view-diary-event .pretty-list.signatures li .confirmation-decision .field,
#side-panel.view-diary-event .pretty-list.add-remove-todos li .confirmation-decision .field {
  float: left;
  margin-top: 4px;
}
#side-panel.view-diary-event .pretty-list.confirmations li .confirmation-decision select,
#side-panel.view-diary-event .pretty-list.questions li .confirmation-decision select,
#side-panel.view-diary-event .pretty-list.signatures li .confirmation-decision select,
#side-panel.view-diary-event .pretty-list.add-remove-todos li .confirmation-decision select {
  margin-top: -5px;
}
#side-panel.view-diary-event .pretty-list.confirmations li .confirmation-decision span,
#side-panel.view-diary-event .pretty-list.questions li .confirmation-decision span,
#side-panel.view-diary-event .pretty-list.signatures li .confirmation-decision span,
#side-panel.view-diary-event .pretty-list.add-remove-todos li .confirmation-decision span {
  padding-left: 20px;
  width: 208px;
}
#side-panel.view-diary-event .pretty-list.confirmations li .confirmation-decision .btn,
#side-panel.view-diary-event .pretty-list.questions li .confirmation-decision .btn,
#side-panel.view-diary-event .pretty-list.signatures li .confirmation-decision .btn,
#side-panel.view-diary-event .pretty-list.add-remove-todos li .confirmation-decision .btn {
  float: right;
}
#side-panel.view-diary-event .pretty-list.questions {
  margin: 0px;
}
#side-panel.view-diary-event .pretty-list.questions li strong {
/*    width: 180px;
*/
    width: 575px;
    display: inline-block;
/*
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
*/
    position: static;
    line-height: 20px;
    margin-right: 10px;
}
#side-panel.view-diary-event .pretty-list.questions li strong.auto-width {
/*    width: 450px !important;
*/
     width: 575px;
}
#side-panel.view-diary-event .pretty-list.questions li strong.with-chevron-on-left {
/*    width: 160px;
*/
     width: 575px;
}
#side-panel.view-diary-event .pretty-list.questions li .answer-excerpt .answer {
    max-width: 575px;
/*
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
*/
}
#side-panel.view-diary-event .pretty-list.questions li .answer-excerpt .ss-quote {
  top: -9px;
}
#side-panel.view-diary-event .pretty-list.questions li .field span {
/*    max-width: 398px;
*/
    max-width: 575px;
}
#side-panel.view-diary-event .pretty-list.questions li .todo-detail {
  padding-bottom: 10px;
}
#side-panel.view-diary-event .pretty-list.questions li .todo-detail p.with-left-padding,
#side-panel.view-diary-event .pretty-list.questions li .todo-detail img.with-left-padding,
#side-panel.view-diary-event .pretty-list.questions li .todo-detail span.with-left-padding {
/*
    margin-left: 20px;
*/
   margin-left: 8px;
}
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision .field {
  float: left;
  margin-top: 4px;
}
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision .btn {
  margin-top: 0px;
}
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision input,
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision textarea,
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision select {
  position: relative;
  top: -6px;
}
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision input {
/*    width: 190px;
*/
    width: 575px;
}
#side-panel.view-diary-event .pretty-list.questions .confirmation-decision textarea {
/*    width: 190px;
*/
    width: 575px;
    resize: vertical;
}
#side-panel.view-diary-event .pretty-list.signatures li {
  padding-bottom: 5px;
}
#side-panel.view-diary-event .pretty-list.signatures li img {
  height: 25px;
  width: auto;
}
#side-panel.view-diary-event .pretty-list.signatures li strong {
/*
    width: 180px;
*/
    width: 100%;
    display: inline-block;
/*
    text-overflow: ellipsis;
    white-space: nowrap;
*/
    overflow: hidden;
    position: static;
    line-height: 20px;
}
#side-panel.view-diary-event .pretty-list.signatures li .field.with-image {
  padding: 10px 0px;
}
#side-panel.view-diary-event .pretty-list.signatures li .field.with-image img {
  width: auto;
  height: auto;
}
#side-panel.view-diary-event .pretty-list.signatures li .todo-detail {
  padding-bottom: 10px;
}
#side-panel.view-diary-event .pretty-list.signatures li .todo-detail p.with-left-padding,
#side-panel.view-diary-event .pretty-list.signatures li .todo-detail img.with-left-padding {
/*    margin-left: 20px;
*/
     margin-left: 8px;
}
#side-panel.view-diary-event .pretty-list.add-remove-todos .todo-description {
  width: 340px !important;
  white-space: normal;
}
#side-panel.view-diary-event .pretty-list.add-remove-todos li {
  float: left !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
#side-panel.view-diary-event .pretty-list.add-remove-todos li span.bold {
  width: 125px;
}
#side-panel.view-diary-event .pretty-list.add-remove-todos li .todo-description {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#side-panel.view-diary-event .pretty-list .warning-icon-wrap {
  margin-bottom: 22px !important;
}
#side-panel.view-diary-event .pretty-list .warning-message {
  margin-bottom: 10px !important;
}
#side-panel.view-diary-event .pretty-list-section .pretty-list {
  margin-top: 60px;
}
#side-panel.view-diary-event .pretty-list-section .pretty-list.without-margin {
  margin-top: 0px;
}
#side-panel.view-diary-event .button-container {
  margin-top: 15px;
}
#side-panel.view-diary-event .todos .btn {
  float: right;
  display: inline-block;
}
#side-panel.view-diary-event .noresults-with-top-margin {
  margin-top: 15px;
}
#side-panel.view-diary-event .noresults-with-extra-top-margin {
  margin-top: 55px;
}
#side-panel.view-diary-event .edit-form {
  padding-top: 20px;
}
#side-panel.view-diary-event .edit-form .on-left {
  float: left;
}
#side-panel.view-diary-event .edit-form .on-right {
  float: right;
}
#side-panel.view-diary-event .edit-todo .information-bar,
#side-panel.view-diary-event .send-confirmation .information-bar,
#side-panel.view-diary-event .edit-question-view .information-bar {
  margin: 0px 0px 15px 0px;
}
#side-panel.view-diary-event .edit-todo .information-bar .icon,
#side-panel.view-diary-event .send-confirmation .information-bar .icon,
#side-panel.view-diary-event .edit-question-view .information-bar .icon {
  top: 3px !important;
}
#side-panel.view-diary-event .edit-todo .edit-form .transparent-block .ss-alert,
#side-panel.view-diary-event .send-confirmation .edit-form .transparent-block .ss-alert,
#side-panel.view-diary-event .edit-question-view .edit-form .transparent-block .ss-alert {
  position: relative;
  top: 0px;
}
#side-panel.view-diary-event .edit-todo .edit-form .transparent-block .tooltip-arrow,
#side-panel.view-diary-event .send-confirmation .edit-form .transparent-block .tooltip-arrow,
#side-panel.view-diary-event .edit-question-view .edit-form .transparent-block .tooltip-arrow {
  margin-top: -5px;
}
#side-panel.view-diary-event .edit-todo .edit-form .transparent-block .tooltip,
#side-panel.view-diary-event .send-confirmation .edit-form .transparent-block .tooltip,
#side-panel.view-diary-event .edit-question-view .edit-form .transparent-block .tooltip {
  margin-top: -3px;
  margin-left: 5px;
}
#side-panel.view-diary-event .edit-question-view {
  padding-top: 55px !important;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section {
  margin: 15px 0px;
  padding: 0px 20px;
  background: #f9f9f9;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section #page-panel-title-bar {
  border-top: 0px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .form-wrapper {
  padding: 50px 0px 10px;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section input[type='time'] {
  width: 200px !important;
  margin-right: 20px;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-alert,
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-check {
  font-size: 16px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
  margin-top: 7px;
  pointer-events: none;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-alert.showing,
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-check.showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-alert.ss-alert,
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-check.ss-alert {
  color: rgba(240, 61, 37, 0.9);
}
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-alert.ss-check,
#side-panel.view-diary-event .edit-feedback-view .edit-form .options-container .data-section .ss-check.ss-check {
  color: #7eb419;
}
#side-panel.view-diary-event .view-job-sheet-link {
  float: right;
  margin: 10px 15px;
}
#side-panel.view-diary-event .custom-datepicker {
  left: 0px;
  width: 253px;
}
#side-panel.view-diary-event .engineer-feedback-speech-bubble {
  margin-top: 20px;
}
#side-panel.view-diary-event .engineer-feedback-speech-bubble .speech-bubble {
  margin-left: 88px;
  margin-top: -55px;
}
#side-panel.view-diary-event .checkboxes-with-disabled input {
  margin-left: 25px;
}
#side-panel.view-diary-event .page-panel-actions {
  right: 0px;
}
#side-panel.view-diary-event .page-panel-actions .buttons {
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
}
#side-panel.view-diary-event .page-panel-actions .buttons .btn.on-left {
  float: left;
  margin-right: 20px;
  margin-left: 0px;
}
#side-panel.view-diary-event .page-panel-actions .buttons .btn.without-right-margin {
  margin-right: 0px;
}
#side-panel.view-diary-event .geolocation {
  position: relative;
}
#side-panel.view-diary-event .geolocation #map {
  height: 400px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #dadada;
  border-top: 0px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
#side-panel.view-diary-event .geolocation .no-location-data-no-results {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
  padding: 10px 30px;
  padding-top: 100px;
  background: rgba(255, 255, 255, 0.35);
}
#side-panel.view-diary-event .geolocation .statuses {
  height: 70px;
  margin-top: 60px;
  border: solid 1px #dadada;
  border-bottom: 0px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  background: white;
  text-align: center;
  -webkit-box-shadow: 0px 5px 4px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 4px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 4px -4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  position: relative;
}
#side-panel.view-diary-event .geolocation .statuses .location {
  position: relative;
  float: left;
  font-size: 16px;
  margin-top: 15px;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
}
#side-panel.view-diary-event .geolocation .statuses .location:after {
  content: '';
  position: absolute;
  z-index: 100;
  right: 0px;
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-right: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  top: 50%;
  margin-top: -7px;
}
#side-panel.view-diary-event .geolocation .statuses .location:last-of-type:after {
  display: none;
}
#side-panel.view-diary-event .geolocation .statuses .location.active {
  font-weight: bold;
}
#side-panel.view-diary-event .geolocation .statuses .location.without-geolocation-data {
  cursor: not-allowed;
}
#side-panel.view-diary-event .geolocation .statuses .location.without-geolocation-data .inner {
  opacity: 0.5 !important;
}
#side-panel.view-diary-event .geolocation .statuses .location .inner {
  position: relative;
  top: -5px;
}
#side-panel.view-diary-event .geolocation .statuses .location .inner .location-title {
  position: relative;
  top: -2px;
  line-height: 30px;
}
#side-panel.view-diary-event .geolocation .statuses .location .inner .location-time {
  font-size: 14px;
}
#side-panel.view-diary-event .no-access-photo {
  padding: 55px 10px 20px;
}
#side-panel.view-diary-event .no-access-photo img {
  max-width: 100%;
  border: solid 1px #c9c9c9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#side-panel.view-diary-event .information-bar span {
  margin-bottom: 0px;
}
#side-panel.view-diary-event .information-bar p {
  padding-bottom: 0px;
}
#side-panel.view-diary-event .panel-loading p {
  margin-top: 20px;
}
#side-panel.view-diary-event .mce-container .mce-edit-area.mce-first {
  top: 0px;
  margin-top: 0px;
}
#side-panel.view-diary-event .select2-container {
  margin-bottom: 9px;
}
#side-panel.view-diary-event .mce-container .mce-flow-layout-item .mce-btn button {
  padding: 4px 10px !important;
}
#side-panel.view-diary-event input.full-width {
  box-sizing: border-box;
  height: 31px;
}
#view-part .right-side,
#view-todo .right-side {
  margin-top: 9px;
}
#view-part .history-section .right-side.with-status,
#view-todo .history-section .right-side.with-status {
  margin: 9px 75px 0 0;
}
#view-part .history-section .data-section.with-vertical-padding,
#view-todo .history-section .data-section.with-vertical-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}
#view-part .history-section .field span.label,
#view-todo .history-section .field span.label {
  color: #ffffff;
  position: relative;
  top: -1px;
}
#view-part .audits,
#view-todo .audits {
  padding: 0px 75px 0px 30px;
}
#view-part .audits img,
#view-todo .audits img {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  border: solid 2px rgba(0, 0, 0, 0.6);
  float: left;
}
#view-part .audits strong,
#view-todo .audits strong,
#view-part .audits .time,
#view-todo .audits .time {
  margin-top: 10px;
}
#view-part .audits strong,
#view-todo .audits strong {
  float: left;
  margin-left: 14px;
}
#view-part .audits .time,
#view-todo .audits .time {
  float: right;
}
#view-part .audits .time .ss-clock,
#view-todo .audits .time .ss-clock {
  position: relative;
  top: 2px;
}
#view-part .audits p,
#view-todo .audits p {
  margin-left: 58px;
  margin-top: 15px;
}
#view-part#view-todo #breadcrumb-title-bar .right-side,
#view-todo#view-todo #breadcrumb-title-bar .right-side {
  margin-top: 0px;
}
#view-part#view-todo #page-panel-main .right-side,
#view-todo#view-todo #page-panel-main .right-side {
  margin-right: 0px;
}
#view-part .data-section.information-bar,
#view-todo .data-section.information-bar {
  margin: 0px !important;
  background: #f9f3de;
}
#view-part .data-section #page-panel-title-bar,
#view-todo .data-section #page-panel-title-bar {
  margin-left: -10px !important;
  margin-right: 0px;
  margin-top: 0px !important;
  border-top: 0px !important;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  padding: 0px 10px !important;
}
#view-part .data-section #page-panel-title-bar .right-side,
#view-todo .data-section #page-panel-title-bar .right-side {
  margin-right: 0px;
}
#view-part .data-section .form-wrapper,
#view-todo .data-section .form-wrapper {
  padding: 50px 10px 0px;
}
#view-part .data-section .form-wrapper input,
#view-todo .data-section .form-wrapper input {
  float: left;
  margin-right: 10px;
}
#view-part .data-section .form-wrapper label,
#view-todo .data-section .form-wrapper label {
  float: left;
  margin-bottom: 0px;
}
table.configure-csv-table th {
  width: 20%;
  min-width: 20%;
  max-width: 20%;
}
table.configure-csv-table th .header-wrap,
table.configure-csv-table th .header-edit-state {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
}
table.configure-csv-table th .header-wrap .header-name,
table.configure-csv-table th .header-edit-state .header-name,
table.configure-csv-table th .header-wrap select,
table.configure-csv-table th .header-edit-state select {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: auto;
}
table.configure-csv-table th .header-wrap .header-actions,
table.configure-csv-table th .header-edit-state .header-actions {
  width: 100px;
  text-align: right;
}
table.configure-csv-table th .header-wrap.header-edit-state .header-actions,
table.configure-csv-table th .header-edit-state.header-edit-state .header-actions {
  position: relative;
  top: 3px;
}
.remove-todos strong {
  width: 130px;
  display: inline-block;
}
.remove-todos .pretty-list {
  max-height: 200px;
  overflow: auto;
}
.quotes-ui .ss-quote {
  font-size: 9px;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  top: -4px;
  margin: 0px 2px;
  display: inline;
  line-height: 4px;
}
.quotes-ui .ss-quote.less-top {
  top: -10px;
}
.quotes-ui .ss-quote.rotated {
  display: inline-block;
  transform: rotate(180deg);
  top: -15px;
}
.quotes-ui .ss-quote.rotated.less-top {
  top: -8px;
  margin-left: 4px;
}
.speech-bubble {
  background: #f2f6f7;
  border: solid 1px #d3d3d3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  padding: 10px 13px;
  display: inline-block;
}
.speech-bubble:after {
  content: ' ';
  position: absolute;
  display: block;
  background: #f2f6f7;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.speech-bubble.top-left:after {
  border-left: solid 1px #d3d3d3;
  border-top: solid 1px #d3d3d3;
  top: -7px;
  left: 13px;
}
.speech-bubble.top-center:after {
  border-left: solid 1px #d3d3d3;
  border-top: solid 1px #d3d3d3;
  top: -7px;
  left: 50%;
  margin-left: -7px;
}
.speech-bubble.top-right:after {
  border-left: solid 1px #d3d3d3;
  border-top: solid 1px #d3d3d3;
  top: -7px;
  right: 13px;
}
.speech-bubble.bottom-left:after {
  border-right: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  bottom: -7px;
  left: 13px;
}
.speech-bubble.bottom-center:after {
  border-right: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  bottom: -7px;
  left: 50%;
  margin-left: -7px;
}
.speech-bubble.bottom-right:after {
  border-right: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  bottom: -7px;
  right: 13px;
}
.speech-bubble.right-top:after {
  border-right: solid 1px #d3d3d3;
  border-top: solid 1px #d3d3d3;
  right: -7px;
  top: 13px;
}
.speech-bubble.right-center:after {
  border-right: solid 1px #d3d3d3;
  border-top: solid 1px #d3d3d3;
  right: -7px;
  top: 50%;
  margin-top: -7px;
}
.speech-bubble.right-bottom:after {
  border-right: solid 1px #d3d3d3;
  border-top: solid 1px #d3d3d3;
  right: -7px;
  bottom: 13px;
}
.speech-bubble.left-top:after {
  border-left: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  left: -7px;
  top: 13px;
}
.speech-bubble.left-center:after {
  border-left: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  left: -7px;
  top: 50%;
  margin-top: -7px;
}
.speech-bubble.left-bottom:after {
  border-left: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  left: -7px;
  bottom: 13px;
}
.tab-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  background: #ffffff;
}
.tab-group .tab {
  background: #eeeeee;
  line-height: 35px;
  display: inline-block;
  cursor: pointer;
  border-bottom: solid 1px #c9c9c9;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.tab-group .tab.active {
  background: transparent;
  border-left: solid 1px #c9c9c9;
  border-right: solid 1px #c9c9c9;
  border-bottom: solid 0px #c9c9c9;
}
.tab-group .tab.active.with-bottom-border {
  border-bottom: solid 1px #c9c9c9;
}
.tab-group .tab:first-of-type {
  border-left: 0px;
}
.tab-group .tab:first-of-type.active {
  border-left: 0px;
}
.tab-group .tab:last-of-type {
  border-right: 0px;
}
.tab-group .tab:last-of-type.active {
  border-right: 0px;
}
.tab-group .tab.disabled-tab {
  opacity: 0.6;
  cursor: not-allowed;
}
.tab-group .tab .small-count {
  float: none;
  display: inline-block;
  margin-top: 10px;
}
.tab-group.complete-job {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}
.tab-group.complete-job .tab {
  width: 25%;
}
.view-customer-feedback .large-profile-wrapper {
  float: left;
}
.view-customer-feedback .speech-bubble {
  float: left;
  margin-top: 15px;
  margin-left: 20px;
  width: 540px;
}
.reporting_wrapper .app-filter {
    margin-left: 0px;
}
#saving-component {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  bottom: 0;
}
#saving-component .pending-icon,
#saving-component .ss-check,
#saving-component .ss-alert {
  display: block;
  -webkit-transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;
  -moz-transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;
  -o-transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-out;
}
#saving-component .pending-icon.ss-check,
#saving-component .ss-check.ss-check,
#saving-component .ss-alert.ss-check,
#saving-component .pending-icon.ss-alert,
#saving-component .ss-check.ss-alert,
#saving-component .ss-alert.ss-alert {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  font-size: 30px;
}
#saving-component .pending-icon.ss-check,
#saving-component .ss-check.ss-check,
#saving-component .ss-alert.ss-check {
  margin: -50px 0 0 0;
  color: #60a369;
}
#saving-component .pending-icon.ss-alert,
#saving-component .ss-check.ss-alert,
#saving-component .ss-alert.ss-alert {
  margin: -20px 0 0 0;
  color: #d25656;
}
#saving-component.saved .pending-icon {
  opacity: 0;
}
#saving-component.saved .ss-check {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#saving-component.error .pending-icon {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#saving-component.error .ss-alert {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#saving-component p {
  margin-top: 30px;
}
.dash-color {
  width: 100%;
  height: 20px;
  display: none;
}
.dash-color.a1 {
  background: #89cebf;
}
.dash-color.a2 {
  background: #7a8d9b;
}
.dash-color.a3 {
  background: #b0d0d7;
}
.dash-color.a4 {
  background: #fde5ac;
}
.dash-color.a5 {
  background: #ce9a85;
}
.dash-color.a6 {
  background: #fcb1b2;
}
.dash-color.a7 {
  background: #b8b8b8;
}
.dash-color.a8 {
  background: #bea0c2;
}
.dash-color.a9 {
  background: #bcd4a5;
}
.dash-color.a10 {
  background: #7c978e;
}
#diary-wrapper.in-dashboard .nav_wrapper {
  height: 41px !important;
  box-sizing: border-box;
}
#diary-wrapper.in-dashboard .nav_wrapper h3 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
  line-height: 15px;
}
.loader-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  align-items: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  text-align: center;
}
.loader-wrapper .loader-inner {
  display: inline-block;
  margin: 0 auto;
}
.loader-wrapper .loader-inner .loading-wrap {
  margin-top: 0px;
}
.dashboard-container {
  position: absolute;
  top: 125px !important;
  left: 20px;
  right: 0px !important;
  bottom: 0px;
  overflow: auto;
  box-sizing: border-box;
  height: auto !important;
  z-index: 0;
}
.dashboard-container .dashboard-inner {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 20px 20px 00px;
}
.dashboard-container .dashboard-inner .gridster {
  margin-bottom: 60px;
}
.gridster-preview-holder {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.15);
}
.widget.reporting-widget {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: move;
  overflow: hidden;
    /*padding-right:10px;*/
}
.widget.reporting-widget.disable-hover {
  pointer-events: none;
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-s {
  left: 50%;
  width: 40px;
  bottom: 0px;
  margin-left: -20px;
  height: 15px;
  -webkit-transform: translate(0px, 10px);
  -moz-transform: translate(0px, 10px);
  -ms-transform: translate(0px, 10px);
  -o-transform: translate(0px, 10px);
  transform: translate(0px, 10px);
  -webkit-transition: transform 0.12s ease-out;
  -moz-transition: transform 0.12s ease-out;
  -o-transition: transform 0.12s ease-out;
  transition: transform 0.12s ease-out;
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-s:after {
  position: absolute;
  content: ' ';
  top: 12px;
  height: 3px;
  width: 20px;
  left: 10px;
  background: rgba(0, 0, 0, 0.6);
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-e {
  right: 0px;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  width: 15px;
  -webkit-transform: translate(10px, 0px);
  -moz-transform: translate(10px, 0px);
  -ms-transform: translate(10px, 0px);
  -o-transform: translate(10px, 0px);
  transform: translate(10px, 0px);
  -webkit-transition: transform 0.12s ease-out;
  -moz-transition: transform 0.12s ease-out;
  -o-transition: transform 0.12s ease-out;
  transition: transform 0.12s ease-out;
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-e:after {
  position: absolute;
  content: ' ';
  right: 0px;
  width: 3px;
  height: 20px;
  top: 10px;
  background: rgba(0, 0, 0, 0.6);
}
.widget.reporting-widget.without-s-resize .gridster-item-resizable-handler.handle-s {
  display: none;
}
.widget.reporting-widget.without-e-resize .gridster-item-resizable-handler.handle-e {
  display: none;
}
.widget.reporting-widget:hover .gridster-item-resizable-handler.handle-s,
.widget.reporting-widget:hover .gridster-item-resizable-handler.handle-e {
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.widget.reporting-widget .widget-inner {
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.widget.reporting-widget .widget-inner .loaded-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.widget.reporting-widget .widget-inner .title {
  flex: 0 0 auto;
}
.widget.reporting-widget .widget-inner .content-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.widget.reporting-widget .widget-inner .content-wrapper .flex-item {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.widget.reporting-widget .widget-inner .content-wrapper .content {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}
.widget.reporting-widget .widget-inner .content-wrapper .content.with-expanded-detail {
  flex: 0 0 auto;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item {
  position: relative;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item .graph-widget {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item .graph-widget .graph-inner {
  height: 100%;
  padding: 15px 15px 0px 0px;
  box-sizing: border-box;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item .graph-widget .graph-inner .graph {
  height: 100%;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content {
  margin: 0 15px 15px 35px;
  box-sizing: border-box;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner {
  width: 100%;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail {
  margin: 30px 0px;
  width: 100%;
  color: #64767c;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h2,
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h3,
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h4 {
  margin: 0 10px 0 0;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h2 {
  font-size: 40px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h3 {
  font-size: 20px;
  margin-bottom: -5px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h4 {
  font-size: 18px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .numbers {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  align-items: center;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change {
  cursor: help;
  margin-left: 10px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change.good {
  color: #60a369;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change.bad {
  color: #d25656;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change .icon {
  font-size: 0.7em;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change p {
  margin-bottom: 0px;
  font-size: 14px;
}
.widget.reporting-widget .widget-inner .content-wrapper .show-large-width {
  display: none;
}
.widget.reporting-widget .widget-inner .content-wrapper .show-extra-large-width {
  display: none;
}
.widget.reporting-widget .widget-inner .content-wrapper .show-large-height {
  display: none;
  background: blue;
}
.widget.reporting-widget .widget-inner .content-wrapper.x-2 .show-large-width,
.widget.reporting-widget .widget-inner .content-wrapper.x-3 .show-large-width {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.widget.reporting-widget .widget-inner .content-wrapper.x-3 .show-extra-large-width {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.widget.reporting-widget .widget-inner .content-wrapper.y-2 .show-large-height {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.dashboard-modal .widgets-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-modal .widgets-container .widget-wrapper {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.dashboard-modal .widgets-container .widget-wrapper .widget {
  margin: 1px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.dashboard-modal .widgets-container .widget-wrapper .widget.highlight {
  -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  border: 2px solid rgba(96, 80, 166, 0.5);
  margin: 0px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .title input {
  margin-top: -6px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content {
  padding: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .left-side {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  align-items: center;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side {
  flex: 2;
  padding: 0px 10px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side .two-column {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  margin-top: 10px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side .two-column .column {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side .two-column .column {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.change-dashboard-date .pretty-list {
  overflow: auto;
  height: 400px;
}
.cs-highcharts-tooltip {
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.27);
  -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.06) 3px -3px 3px);
}
.cs-highcharts-tooltip .value {
  display: block;
  margin-top: 5px;
}
.left-right-controls .icon {
  font-size: 9px !important;
  width: auto !important;
  height: auto;
  background: #f0f0f0;
  color: rgba(143, 146, 150, 0.9) !important;
  border: 1px solid #dadada;
  border-bottom-width: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 6px 8px 3px 7px;
  line-height: 13px;
  float: right !important;
  margin: 7px 0px 0px 5px !important;
  transition: color 0.1s ease-in, background 0.1s ease-in;
}
.left-right-controls .icon:hover {
  color: rgba(138, 141, 145, 0.9);
  background: #ebebeb;
}
.left-right-controls .quick-links-btn {
  background: transparent !important;
  border: 0px !important;
}
.left-right-controls .quick-links-btn .caret {
  margin-left: 2px;
}
.left-right-controls .quick-links-wrap.open .quick-links-btn {
  background: transparent !important;
}
#accounting-integration-dashboard {
  margin-top: -10px;
  display: inline-block;
}
#accounting-integration-dashboard .flex-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  align-items: center;
}
#accounting-integration-dashboard .flex-wrapper.horizontal {
  flex-direction: row;
}
#accounting-integration-dashboard .flex-wrapper.vertical {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: initial;
}
#accounting-integration-dashboard .flex-wrapper .flex-item {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 10px 10px 0px;
}
#accounting-integration-dashboard .flex-wrapper .flex-item.without-right-margin {
  margin-right: 0px;
}
#accounting-integration-dashboard .flex-wrapper .flex-item.without-left-margin {
  margin-left: 0px;
}
#accounting-integration-dashboard .flex-wrapper .flex-item.flex-wrapper {
  margin: 0px;
}
#accounting-integration-dashboard .flex-wrapper .flex-item .widget {
  margin: 0px;
}
#accounting-integration-dashboard .flex-wrapper .arrow {
  flex: 0 0 auto;
  align-items: center;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 30px;
  margin: 0px 10px;
}
#accounting-integration-dashboard .flex-wrapper .arrow .ss-right {
  position: relative;
  top: 10px;
  color: #7e8d93;
  margin: 50px 0px;
}
#accounting-integration-dashboard .flex-wrapper .arrow .up {
  display: inline-block;
  -webkit-transform: rotate(-22.5deg);
  -moz-transform: rotate(-22.5deg);
  -ms-transform: rotate(-22.5deg);
  -o-transform: rotate(-22.5deg);
  transform: rotate(-22.5deg);
  left: 2px;
}
#accounting-integration-dashboard .flex-wrapper .arrow .down {
  display: inline-block;
  -webkit-transform: rotate(22.5deg);
  -moz-transform: rotate(22.5deg);
  -ms-transform: rotate(22.5deg);
  -o-transform: rotate(22.5deg);
  transform: rotate(22.5deg);
  left: -1px;
}
#accounting-integration-dashboard .widget .section-btns {
  margin: -5px 15px 10px;
  text-align: right;
}
#setup-accounting-integration .row-fluid {
  padding: 0px 15px;
}
#setup-accounting-integration .row-fluid .row-fluid {
  padding: 0px;
}
#setup-accounting-integration .row-fluid.single-notice {
  padding: 0px 5px;
}
#setup-accounting-integration .slide {
  padding-top: 30px;
}
#setup-accounting-integration .tab {
  padding-top: 8px;
  cursor: not-allowed;
}
#setup-accounting-integration .tab.active {
  cursor: default;
}
#setup-accounting-integration .field span {
  max-width: 460px;
}
#setup-accounting-integration .no-results-wrap {
  padding-top: 30px;
}
#setup-accounting-integration .no-results-wrap .warning-icon-wrap {
  margin-top: 0px;
}
#setup-accounting-integration .choose-accounting-package {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  clear: both;
}
#setup-accounting-integration .choose-accounting-package .accounting-package {
  padding: 0 10px 20px;
  box-sizing: border-box;
  width: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
#setup-accounting-integration .choose-accounting-package .accounting-package .package {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
}
#setup-accounting-integration .choose-accounting-package .accounting-package .package .content {
  padding: 10px 20px;
  text-align: center;
}
#setup-accounting-integration .choose-accounting-package .accounting-package .package .content img {
  height: 55px;
  width: auto;
}
#setup-accounting-integration .choose-accounting-package .accounting-package.disabled {
  opacity: 0.8;
}
#setup-accounting-integration .no-results-wrap {
  color: #7e8d93;
}
#setup-accounting-integration .no-results-wrap .loading-wrap {
  margin-top: 30px;
  margin-bottom: 20px;
}
#setup-accounting-integration .no-results-wrap p {
  text-align: center;
}
#setup-accounting-integration .setup-steps {
  list-style-type: none;
  margin: 0 20px;
}
#setup-accounting-integration .setup-steps li {
  clear: both;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
}
#setup-accounting-integration .setup-steps li .small-count {
  margin-right: 10px;
}
#setup-accounting-integration .setup-steps li .step-text {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#setup-accounting-integration .setup-steps li label {
  margin-left: 38px;
}
#setup-accounting-integration .import-confirmation .field-label.large {
  width: 180px;
}
#setup-accounting-integration .control-group .controls label {
  margin-top: 5px;
}
#setup-accounting-integration .information-bar {
  margin-bottom: 30px;
}
#setup-accounting-integration .modal-footer {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  top: 0px;
}
#setup-accounting-integration .modal-footer.out-of-view {
  top: 60px;
  opacity: 0;
  pointer-events: none;
}
#setup-accounting-integration form {
  margin-bottom: 0px;
}
.leaflet-popup {
  pointer-events: none;
}
.leaflet-popup .leaflet-popup-content-wrapper {
  background-color: #000000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.leaflet-popup .leaflet-popup-content {
  margin: 0px;
  padding: 3px 0px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 11px;
}
.leaflet-popup .leaflet-popup-tip {
  width: 6px;
  height: 6px;
  padding: 1px;
  margin: -4px auto 0;
  background-color: #000000;
}
.leaflet-popup .leaflet-popup-tip-container {
  height: 0px;
  overflow: visible;
}
#map {
  background: white;
}
.leaflet-tile-pane {
  opacity: 0.9;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.leaflet-bottom {
  display: none;
}
body .if-below-minimum-screen-resolution,
.app-container .if-below-minimum-screen-resolution {
  display: none;
}
body .if-above-minimum-screen-resolution,
.app-container .if-above-minimum-screen-resolution {
  display: block;
  opacity: 0;
}
body .if-above-minimum-screen-resolution.commusoft-has-loaded,
.app-container .if-above-minimum-screen-resolution.commusoft-has-loaded {
  opacity: 1;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
body .if-above-minimum-screen-resolution.commusoft-has-loaded.ng-leave.ng-leave-active,
.app-container .if-above-minimum-screen-resolution.commusoft-has-loaded.ng-leave.ng-leave-active {
  opacity: 0;
  -webkit-transition: all 0s ease-in-out;
  -moz-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
@media screen and (max-width: 1300px) {
  body,
  .app-container {
    padding: 0px;
  }
  body .if-below-minimum-screen-resolution,
  .app-container .if-below-minimum-screen-resolution {
    display: block;
    position: relative;
    background: #f7faff;
  }
  body .if-above-minimum-screen-resolution,
  .app-container .if-above-minimum-screen-resolution {
    display: none;
  }
}
.mobile-wrap {
  width: 262px;
  margin: 0px auto;
  text-align: center;
}
.mobile-wrap p {
  padding: 30px 15px;
}
.mobile-wrap img {
  width: 160px;
}
.mobile-wrap .devices {
  text-align: center;
  padding-top: 30px;
}
.mobile-wrap .devices .tablet {
  left: 15px;
}
.mobile-wrap .devices .phone {
  left: -15px;
}
.mobile-wrap .devices .device {
  display: inline-block;
  border: solid 2px #3ab0bc;
  position: relative;
  margin-left: 0 auto;
  background: #f7faff;
  overflow: hidden;
  background: #e8f0ff;
}
.mobile-wrap .devices .device .grill {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-bottom: solid 2px #3ab0bc;
  background: #f7faff;
}
.mobile-wrap .devices .device .grill:after {
  content: ' ';
  display: block;
  border: solid 2px #3ab0bc;
}
.mobile-wrap .devices .device .button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-top: solid 2px #3ab0bc;
  background: #f7faff;
}
.mobile-wrap .devices .device .button:after {
  content: ' ';
  display: block;
  border: solid 2px #3ab0bc;
  -webkit-border-radius: 300px;
  -moz-border-radius: 300px;
  border-radius: 300px;
}
.mobile-wrap .devices .device.phone {
  width: 100px;
  height: 200px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.mobile-wrap .devices .device.phone .grill {
  height: 20px;
}
.mobile-wrap .devices .device.phone .grill:after {
  width: 22px;
  height: 8px;
  margin: 4px auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.mobile-wrap .devices .device.phone .button {
  height: 20px;
}
.mobile-wrap .devices .device.phone .button:after {
  width: 8px;
  height: 8px;
  margin: 4px auto;
}
.mobile-wrap .devices .device.tablet {
  width: 150px;
  height: 225px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.mobile-wrap .devices .device.tablet .grill {
  height: 30px;
}
.mobile-wrap .devices .device.tablet .grill:after {
  width: 28px;
  height: 10px;
  margin: 8px auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.mobile-wrap .devices .device.tablet .button {
  height: 30px;
}
.mobile-wrap .devices .device.tablet .button:after {
  width: 10px;
  height: 10px;
  margin: 8px auto;
}
.desktop-wrap {
  width: 450px;
  margin: 0px auto;
  padding-top: 30px;
  text-align: center;
}
.desktop-wrap p {
  padding: 30px 15px;
}
.desktop-wrap .device {
  padding: 0px 15px;
}
.desktop-wrap .device .screen {
  margin: 0px 30px;
  height: 200px;
  border: solid 2px #3ab0bc;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 20px;
  background: #f7faff;
  position: relative;
}
.desktop-wrap .device .screen .camera {
  position: absolute;
  top: 6px;
  left: 50%;
  margin-left: -2px;
  width: 4px;
  height: 4px;
  border: solid 2px #3ab0bc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.desktop-wrap .device .screen .screen-inner {
  background: #e8f0ff;
  border: solid 2px #3ab0bc;
  height: 191px;
}
.desktop-wrap .device .base {
  position: relative;
  border: solid 2px #3ab0bc;
  height: 17px;
  margin-top: -9px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
  -webkit-border-bottom-left-radius: 30px;
  -moz-border-radius-bottomleft: 30px;
  border-bottom-left-radius: 30px;
  -webkit-border-bottom-right-radius: 30px;
  -moz-border-radius-bottomright: 30px;
  border-bottom-right-radius: 30px;
  background: #f7faff;
}
.desktop-wrap .device .base .indent {
  width: 120px;
  height: 7px;
  border: solid 2px #3ab0bc;
  border-top: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: -2px auto;
}
.commusoft-loading-wrap {
  display: flex;
  background: #f7faff;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  align-items: center;
  opacity: 1;
  z-index: 100;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}
.commusoft-loading-wrap.commusoft-has-loaded {
  z-index: -100;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-in-out, z-index 0s 0.6s;
  -moz-transition: opacity 0.6s ease-in-out, z-index 0s 0.6s;
  -o-transition: opacity 0.6s ease-in-out, z-index 0s 0.6s;
  transition: opacity 0.6s ease-in-out, z-index 0s 0.6s;
}
.commusoft-loading-wrap .commusoft-loading-inner {
  margin: 0 auto;
}
.commusoft-device-spec-error-wrap {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.commusoft-loader {
    position: relative;
    margin: 30px auto;
    width: 121px;
    height: 121px;
    top: 0;
    left: 0;
    overflow: hidden;
}
.orange-square {
    position: relative;
    top: 0;
    left: 0;
    display: unset !important;
}
.white-arrow {
    display: unset !important;
    position: absolute;
    top: 0;
    left: 0;
    -moz-animation: spin 2.5s infinite linear;
    -webkit-animation: spin 2.5s infinite linear;
    animation: spin 2.5s infinite linear;
}

/*
.commusoft-loader {
  margin: 30px auto;
  padding: 20px;
  background: #ff8833;
  text-align: center;
  width: 80px;
  height: 80px;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.commusoft-loader .inner {
  position: relative;
  width: 80px;
  height: 80px;
  -webkit-box-shadow: inset 0px 0px 0 12.8px #f7faff;
  -moz-box-shadow: inset 0px 0px 0 12.8px #f7faff;
  box-shadow: inset 0px 0px 0 12.8px #f7faff;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  background: #ff8833;
  -moz-animation: spin 2.5s infinite linear;
  -webkit-animation: spin 2.5s infinite linear;
  animation: spin 2.5s infinite linear;
}
.commusoft-loader .inner:after {
  content: ' ';
  background: #ff8833;
  position: absolute;
  top: 0px;
}
.commusoft-loader .inner:after {
  width: 48px;
  height: 48px;
  right: -14.4px;
  transform: rotate(45deg);
  top: 16px;
}
.commusoft-loader .inner .arrow {
  position: absolute;
  right: 16.919540229885058px;
  top: 13.24137931034483px;
  z-index: 1;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.commusoft-loader .inner .arrow:after,
.commusoft-loader .inner .arrow:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.commusoft-loader .inner .arrow:after {
  border-color: transparent;
  border-bottom-color: #f7faff;
  border-width: 14.4px;
  margin-left: -11.520000000000001px;
}
.commusoft-loader .inner .arrow:before {
  border-color: transparent;
  border-bottom-color: #f7faff;
  border-width: 14.4px;
  margin-left: -11.520000000000001px;
}
*/

@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.error-404 {
  position: fixed;
  background: #f7faff;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 30px;
  z-index: 10000000000;
  text-align: center;
}
.error-404 .error-text {
  position: relative;
  z-index: 300;
  margin-top: 44px;
}
.error-404 .error-text p {
  margin-bottom: 30px;
}
.van {
  display: inline-block;
  position: relative;
  left: -33px;
}
.van .main-compartment {
  width: 215px;
  height: 112px;
  border: solid 2px #3ab0bc;
  border-right: 0px;
  -webkit-border-top-right-radius: 11px;
  -moz-border-radius-topright: 11px;
  border-top-right-radius: 11px;
  -webkit-border-top-left-radius: 15px;
  -moz-border-radius-topleft: 15px;
  border-top-left-radius: 15px;
  -webkit-border-bottom-left-radius: 15px;
  -moz-border-radius-bottomleft: 15px;
  border-bottom-left-radius: 15px;
  background: #e8f0ff;
}
.van .main-compartment .error-code {
  font-size: 54px;
  font-weight: bold;
  position: absolute;
  top: 30px;
  left: 30px;
  color: #f7faff;
  letter-spacing: 3px;
  text-shadow: -1px -1px 0 #3ab0bc, 1px -1px 0 #3ab0bc, -1px 1px 0 #3ab0bc, 1px 1px 0 #3ab0bc;
}
.van .front-window {
  border: solid 2px #3ab0bc;
  width: 55px;
  height: 43px;
  position: absolute;
  top: 13px;
  right: 7px;
  z-index: 14;
  border-radius: 5px;
  background: #f7faff;
}
.van .front-window .front-window-triangle {
  position: absolute;
  top: 0px;
  right: -22px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 21px 45.2px 8px;
  border-color: transparent transparent #3ab0bc transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.van .front-window .front-window-triangle:after {
  content: ' ';
  position: absolute;
  top: 2px;
  right: -18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 19px 41.2px 14px;
  border-color: transparent transparent #f7faff transparent;
}
.van .windscreen {
  border: solid 2px #3ab0bc;
  width: 1px;
  height: 70px;
  width: 35px;
  -webkit-transform: rotate(-24deg);
  -moz-transform: rotate(-24deg);
  -ms-transform: rotate(-24deg);
  -o-transform: rotate(-24deg);
  transform: rotate(-24deg);
  position: absolute;
  right: -14px;
  top: 1px;
  -webkit-border-top-right-radius: 14px;
  -moz-border-radius-topright: 14px;
  border-top-right-radius: 14px;
  border-top: 0px;
  border-left: 0px;
  border-bottom: 0px;
  background: #e8f0ff;
}
.van .cover {
  background: #e8f0ff;
  -webkit-transform: rotate(-74deg);
  -moz-transform: rotate(-74deg);
  -ms-transform: rotate(-74deg);
  -o-transform: rotate(-74deg);
  transform: rotate(-74deg);
  position: absolute;
  right: -45px;
  top: 48px;
  width: 34px;
  height: 62px;
  z-index: 10;
}
.van .bonnet {
  border: solid 2px #3ab0bc;
  width: 7px;
  height: 40px;
  -webkit-transform: rotate(-74deg);
  -moz-transform: rotate(-74deg);
  -ms-transform: rotate(-74deg);
  -o-transform: rotate(-74deg);
  transform: rotate(-74deg);
  position: absolute;
  right: -50px;
  top: 48px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-radius-bottomright: 9px;
  border-bottom-right-radius: 9px;
  border-top: 0px;
  border-left: 0px;
  background: #e8f0ff;
  z-index: 10;
}
.van .bonnet-flap {
  border: solid 2px #3ab0bc;
  width: 0px;
  height: 40px;
  -webkit-transform: rotate(-118deg);
  -moz-transform: rotate(-118deg);
  -ms-transform: rotate(-118deg);
  -o-transform: rotate(-118deg);
  transform: rotate(-118deg);
  position: absolute;
  right: -43px;
  top: 31px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-radius-bottomright: 9px;
  border-bottom-right-radius: 9px;
  border-top: 0px;
  border-left: 0px;
  background: #e8f0ff;
}
.van .front-wheel-arch {
  border: solid 2px #3ab0bc;
  position: absolute;
  bottom: 0px;
  right: -65px;
  width: 64px;
  height: 37px;
  border-top: 0px;
  border-left: 0px;
  -webkit-border-bottom-right-radius: 15px;
  -moz-border-radius-bottomright: 15px;
  border-bottom-right-radius: 15px;
  background: #e8f0ff;
  z-index: 10;
}
.van .rear-wheel-arch {
  position: absolute;
  bottom: -5px;
  left: 38px;
  width: 44px;
  height: 44px;
  background: #e8f0ff;
}
.van .wheel {
  background: #f7faff;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  top: 13px;
  left: -9px;
  border: solid 2px #3ab0bc;
}
.van .wheel .wheel-inner {
  margin-left: 3px;
  margin-top: 3px;
  background: #f7faff;
  width: 41px;
  height: 41px;
  -webkit-border-radius: 47px;
  -moz-border-radius: 47px;
  border-radius: 47px;
  border: solid 2px #3ab0bc;
  position: relative;
  z-index: 10;
  background: #e8f0ff;
}
.van .wheel .flat-tyre {
  height: 8px;
  border: solid 2px #3ab0bc;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  border-bottom-right-radius: 5px;
  width: 38px;
  position: absolute;
  bottom: 9px;
  z-index: 14;
  left: 6px;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}
.van .wheel .wheel-bottom-mask {
  position: absolute;
  bottom: -11px;
  height: 37px;
  width: 58px;
  left: -5px;
  background: #f7faff;
}
.van .wheel .flat-tyre-bottom-mask {
  position: absolute;
  bottom: -26px;
  height: 37px;
  width: 58px;
  left: -5px;
  background: #f7faff;
  z-index: 11;
}
.van .wheel .hub-cap {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 22px;
  height: 22px;
  margin-top: -14px;
  margin-left: -13px;
  background: #f7faff;
  border: solid 2px #3ab0bc;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}
/* Smoke container */
.smoke {
  position: absolute;
  z-index: 3;
  width: 1px;
  height: 160px;
  right: -59px;
  top: -46px;
  transform: scale(0.5);
  opacity: 0.5;
}
.smoke span {
  display: block;
  position: absolute;
  bottom: -35px;
  left: 50%;
  margin-left: -20px;
  height: 0px;
  width: 0px;
  border: 35px solid #4b4b4b;
  border-radius: 35px;
  left: -14px;
  opacity: 0;
  transform: scale(0.2);
}
.smoke span.s0 {
  animation: smokeL 10s 0s infinite;
}
.smoke span.s1 {
  animation: smokeR 10s 1s infinite;
}
.smoke span.s2 {
  animation: smokeL 10s 2s infinite;
}
.smoke span.s3 {
  animation: smokeR 10s 3s infinite;
}
.smoke span.s4 {
  animation: smokeL 10s 4s infinite;
}
.smoke span.s5 {
  animation: smokeR 10s 5s infinite;
}
.smoke span.s6 {
  animation: smokeL 10s 6s infinite;
}
.smoke span.s7 {
  animation: smokeR 10s 7s infinite;
}
.smoke span.s8 {
  animation: smokeL 10s 8s infinite;
}
.smoke span.s9 {
  animation: smokeR 10s 9s infinite;
}
/* Smoke animation */
@-webkit-keyframes smokeL {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(-20px, -130px);
  }
}
@-moz-keyframes smokeL {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(-20px, -130px);
  }
}
@-o-keyframes smokeL {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(-20px, -130px);
  }
}
@keyframes smokeL {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(-20px, -130px);
  }
}
@-webkit-keyframes smokeR {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(20px, -130px);
  }
}
@-moz-keyframes smokeR {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(20px, -130px);
  }
}
@-o-keyframes smokeR {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(20px, -130px);
  }
}
@keyframes smokeR {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(20px, -130px);
  }
}
.cm-container {
  width: 100%;
  font-family: inherit;
}
.cm-container .cm-results-wrap {
  width: 100%;
  box-sizing: border-box;
}
.cm-container .cm-results-wrap .cm-results {
  overflow: hidden;
}
.cm-container .cm-results-wrap .cm-results a {
  box-sizing: border-box;
}
.max-width-inputs {
  width: 600px;
}
input[type="submit"].cancel-question {
  border: none;
  background: none;
  padding: 0;
  color: #0088cd;
}
input[type="submit"].cancel-question:hover {
  color: #3e77b3;
}
.percentage-input {
  position: relative;
}
.percentage-input span {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #999999;
}
.currency-input {
  position: absolute;
  width: 160px;
}
.currency-input input {
  width: 100%;
}
.currency-input span {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #999999;
  display: block;
  padding: 2px 6px;
  margin: 3px 4px;
  background: #ffffff;
}
.smarttable-buttons {
  position: absolute;
  top: 7px;
  right: 10px;
}
.emphasis {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
}
.import-csv-input {
  visibility: hidden;
}
.email_editor,
.estimate_template {
  width: 100%;
  height: 200px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.email_editor.large_editor,
.estimate_template.large_editor {
  height: 400px;
}
.default_email {
  clear: both;
  margin-bottom: 1rem;
}
.default_email .wysihtml5-sandbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.email_subject_wrapper {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
.email_subject_wrapper input {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
}
.email_subject_wrapper.with_top_margin {
  margin: 24px 0 0 0;
}
.email_subject_wrapper .mce-panel {
  width: 100%;
  clear: both;
}
.import-csv-button {
  margin: 20px 0 0 0;
}
.import-csv-button.without-top-margin {
  margin-top: 0px;
}
a.back.disabled:hover {
  cursor: default;
}
.toggle-advanced {
  float: left;
  margin-bottom: 0;
  border-bottom: 0 !important;
  -webkit-border-radius: 4px 4px 0 0 !important;
  -moz-border-radius: 4px 4px 0 0 !important;
  border-radius: 4px 4px 0 0 !important;
}
.toggle-advanced:hover {
  color: #787272 !important;
}
.toggle-advanced .ss-down,
.toggle-advanced .ss-up {
  margin-left: 4px;
  font-size: 8px;
}
.content-title {
  color: #505b65;
  font-size: 18px;
}
.required_field:after {
  content: ' *';
  color: #d53847;
  font-size: 16px;
}
form,
.form_class {
  padding: 20px 20px;
  color: #505b65;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.86), inset 0 1px 0 rgba(1, 0, 0, 0.09), inset 0 0 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.86), inset 0 1px 0 rgba(1, 0, 0, 0.09), inset 0 0 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.86), inset 0 1px 0 rgba(1, 0, 0, 0.09), inset 0 0 5px rgba(0, 0, 0, 0.09);
  background-color: rgba(1, 0, 0, 0.05);
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
}
form.no-style,
.form_class.no-style {
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
}
form.without-bottom-margin,
.form_class.without-bottom-margin {
  margin-bottom: 0px;
}
form .required_field:after,
.form_class .required_field:after {
  content: ' *';
  color: #d53847;
  font-size: 16px;
}
form fieldset,
.form_class fieldset {
  background: #f9f9f9;
  border-top: 1px solid white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
form fieldset.no-style,
.form_class fieldset.no-style {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  background: transparent;
}
form fieldset:last-child,
.form_class fieldset:last-child {
  margin-bottom: 0;
}
form fieldset.no-bottom-margin,
.form_class fieldset.no-bottom-margin {
  margin: 0;
}
form fieldset.no-bottom-padding,
.form_class fieldset.no-bottom-padding {
  padding-bottom: 0;
}
form fieldset .help-inline.top-margin,
.form_class fieldset .help-inline.top-margin {
  margin-top: 5px;
}
form fieldset .smart-filter,
.form_class fieldset .smart-filter {
  display: inline-block;
  margin-bottom: 7px;
}
form fieldset .title,
.form_class fieldset .title {
  border-bottom: 1px solid #dadada;
  color: #505b65;
  font-size: 18px;
  margin: 0 0 20px;
  padding: 10px 20px;
  -webkit-box-shadow: 0 1px 0 #ffffff;
  -moz-box-shadow: 0 1px 0 #ffffff;
  box-shadow: 0 1px 0 #ffffff;
}
form fieldset .title .help-block,
.form_class fieldset .title .help-block {
  font-size: 80%;
  float: none;
}
form fieldset .title.no-border,
.form_class fieldset .title.no-border {
  border-bottom: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
}
form fieldset .title.no-margin,
.form_class fieldset .title.no-margin {
  margin: 0;
}
form fieldset .help-block,
.form_class fieldset .help-block {
  float: left;
  clear: both;
}
form fieldset .help-block.no-float,
.form_class fieldset .help-block.no-float {
  float: none;
}
form fieldset .help-block.no-margin,
.form_class fieldset .help-block.no-margin {
  margin: 0;
}
form fieldset .help-block.bottom-margin,
.form_class fieldset .help-block.bottom-margin {
  margin-bottom: 10px;
}
form fieldset .help-block.left-margin,
.form_class fieldset .help-block.left-margin {
  margin-left: 19px;
}
form fieldset .help-block.inline-block,
.form_class fieldset .help-block.inline-block {
  float: none;
  margin: 0 0 10px 0;
}
form fieldset .control-group .help-block.inline-block,
.form_class fieldset .control-group .help-block.inline-block,
form fieldset .control-group .text-block.inline-block,
.form_class fieldset .control-group .text-block.inline-block,
form fieldset .control-group .error-block.inline-block,
.form_class fieldset .control-group .error-block.inline-block {
  display: inline-block;
  margin: 5px 0 0 0;
}
form fieldset.advanced-panel .advanced-content.show-advanced,
.form_class fieldset.advanced-panel .advanced-content.show-advanced {
  display: block;
}
form fieldset.advanced-panel .title,
.form_class fieldset.advanced-panel .title {
  *zoom: 1;
  clear: both;
  padding-bottom: 0;
  position: relative;
}
form fieldset.advanced-panel .title:before,
.form_class fieldset.advanced-panel .title:before,
form fieldset.advanced-panel .title:after,
.form_class fieldset.advanced-panel .title:after {
  display: table;
  content: "";
  line-height: 0;
}
form fieldset.advanced-panel .title:after,
.form_class fieldset.advanced-panel .title:after {
  clear: both;
}
form fieldset.advanced-panel .title p,
.form_class fieldset.advanced-panel .title p {
  *zoom: 1;
  clear: both;
  float: right;
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
form fieldset.advanced-panel .title p:before,
.form_class fieldset.advanced-panel .title p:before,
form fieldset.advanced-panel .title p:after,
.form_class fieldset.advanced-panel .title p:after {
  display: table;
  content: "";
  line-height: 0;
}
form fieldset.advanced-panel .title p:after,
.form_class fieldset.advanced-panel .title p:after {
  clear: both;
}
form .spreadsheet-section,
.form_class .spreadsheet-section {
  -webkit-box-shadow: 0px 1px 2px -1px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px -1px #a5a4a6;
  box-shadow: 0px 1px 2px -1px #a5a4a6;
}
form .full-width-select,
.form_class .full-width-select {
  width: 100%;
}
form input,
.form_class input,
form textarea,
.form_class textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
form input.without-bottom-margin,
.form_class input.without-bottom-margin,
form textarea.without-bottom-margin,
.form_class textarea.without-bottom-margin {
  margin-bottom: 0px;
}
form input[type="text"],
form input[type="tel"],
.form_class input[type="text"],
form input[type="number"],
.form_class input[type="number"],
form input[type="time"],
.form_class input[type="time"],
form input[type="password"],
.form_class input[type="password"],
form input[type="email"],
.form_class input[type="email"] {
  height: 30px !important;
  padding: 0 8px !important;
}
form .input-medium,
.form_class .input-medium {
  width: 150px !important;
}
form .input-large,
.form_class .input-large {
  width: 250px !important;
}
form .input-larger,
.form_class .input-larger {
  width: 280px !important;
}
form .full-width-inputs-wrapper input,
.form_class .full-width-inputs-wrapper input {
  width: 100%;
  /* need to change padding as border-box is used - this
				 ensures that the input really is 100% width*/

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
form textarea.full-width,
.form_class textarea.full-width {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
form .add-on.below-input,
.form_class .add-on.below-input {
  margin-top: 5px;
  display: inline-block;
}
form .input-append,
.form_class .input-append,
form .input-prepend,
.form_class .input-prepend {
  width: 183px;
}
form .input-append a.add-on .ss-search,
.form_class .input-append a.add-on .ss-search,
form .input-prepend a.add-on .ss-search,
.form_class .input-prepend a.add-on .ss-search,
form .input-append a.add-on .ss-calendar,
.form_class .input-append a.add-on .ss-calendar,
form .input-prepend a.add-on .ss-calendar,
.form_class .input-prepend a.add-on .ss-calendar,
form .input-append a.add-on .ss-lock,
.form_class .input-append a.add-on .ss-lock,
form .input-prepend a.add-on .ss-lock,
.form_class .input-prepend a.add-on .ss-lock {
  margin: 1px 6px 0;
  color: #606c7f;
  text-shadow: none;
}
form .input-append .currency-icon,
.form_class .input-append .currency-icon,
form .input-prepend .currency-icon,
.form_class .input-prepend .currency-icon {
  font-size: 17px;
}
form .input-append.card-types .add-on,
.form_class .input-append.card-types .add-on,
form .input-prepend.card-types .add-on,
.form_class .input-prepend.card-types .add-on {
  padding: 0px;
  width: auto;
  height: auto;
  overflow: hidden;
}
form .input-append.card-types img,
.form_class .input-append.card-types img,
form .input-prepend.card-types img,
.form_class .input-prepend.card-types img {
  height: 28px;
  width: auto;
  max-width: none;
}
form .input-append.card-types .input-wrapper,
.form_class .input-append.card-types .input-wrapper,
form .input-prepend.card-types .input-wrapper,
.form_class .input-prepend.card-types .input-wrapper {
  margin-right: -1px;
}
form .input-append.card-types .input-wrapper.without-add-on input,
.form_class .input-append.card-types .input-wrapper.without-add-on input,
form .input-prepend.card-types .input-wrapper.without-add-on input,
.form_class .input-prepend.card-types .input-wrapper.without-add-on input {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
form .controls.no-margin,
.form_class .controls.no-margin {
  margin-left: 20px;
}
form .controls.small,
.form_class .controls.small {
  margin-left: 100px;
}
form .controls.huge,
.form_class .controls.huge {
  margin-left: 210px;
}
form .controls.ginormous,
.form_class .controls.ginormous {
  margin-left: 270px;
}
form .controls.with-bottom-margin,
.form_class .controls.with-bottom-margin {
  margin-bottom: 10px;
}
form .controls.with-bottom-margin:last-of-type,
.form_class .controls.with-bottom-margin:last-of-type {
  margin-bottom: 0px;
}
form .controls .inline-link,
.form_class .controls .inline-link {
  margin-left: 10px;
}
form .controls .select2-container,
.form_class .controls .select2-container {
  min-width: 220px;
}
form .controls .inline-block,
.form_class .controls .inline-block {
  display: inline-block;
}
form .actions,
.form_class .actions {
  *zoom: 1;
  clear: both;
}
form .actions:before,
.form_class .actions:before,
form .actions:after,
.form_class .actions:after {
  display: table;
  content: "";
  line-height: 0;
}
form .actions:after,
.form_class .actions:after {
  clear: both;
}
form .actions.above-fieldset,
.form_class .actions.above-fieldset {
  margin-bottom: 20px;
}
form .actions a,
.form_class .actions a {
  color: #999999;
  float: right;
  margin-right: 20px;
  padding-top: 5px;
}
form .actions .btn,
.form_class .actions .btn {
  color: rgba(255, 255, 255, 0.95);
}
form .actions .btn-primary,
.form_class .actions .btn-primary {
  float: right;
  margin-right: 0;
}
form .actions .btn-two,
.form_class .actions .btn-two {
  margin-left: 10px;
}
form .actions .btn-danger,
.form_class .actions .btn-danger {
  color: #ffffff;
  margin: 0;
}
form .actions.with-top-margin,
.form_class .actions.with-top-margin {
  margin-top: 20px;
}
form .actions.above-advanced,
.form_class .actions.above-advanced {
  margin-bottom: 20px;
}
form .actions .without-margin,
.form_class .actions .without-margin {
  margin: 0;
}
form .label,
.form_class .label {
  font-weight: 500;
  text-shadow: none;
}
form .label.label-success,
.form_class .label.label-success {
  background: #60a369;
}
form .label.label-important,
.form_class .label.label-important {
  background: #b95858;
}
form select.left,
.form_class select.left {
  float: left;
}
.form_class.with-full-width-inputs .block-label,
.form_class.with-full-width-inputs .required_field {
  display: inline-block;
  margin: 0 0 4px !important;
}
.inline-radio,
.inline-checkbox {
  margin: -3px 2px 0 0 !important;
}
.inline-radio.with-no-float,
.inline-checkbox.with-no-float {
  float: none;
}
.inline-radio.above,
.inline-checkbox.above {
  margin: 9px 2px 0 0 !important;
}
.title_bar > h3:hover .edit.hover {
  opacity: 1;
}
.title_bar > h3 .edit.hover {
  opacity: 0;
  font-size: 14px;
  color: #505b65;
  -webkit-transition: ease-in all 0.2s;
  -moz-transition: ease-in all 0.2s;
  -o-transition: ease-in all 0.2s;
  transition: ease-in all 0.2s;
}
.multi-select-tag .span5 {
  margin-left: 0px;
}
.multi-select-tag .chzn-container {
  width: 97.5% !important;
}
#default-file-input {
  display: none;
}
.ie-file-upload {
  display: none;
}
.ie-file-upload .btn {
  margin-left: 10px;
}
.tooltip {
  font-size: 13px !important;
}
.with_helper {
  padding: 10px 20px 9px 20px !important;
}
.with_helper span {
  float: left;
}
.with_helper .help-block {
  display: inline;
  font-style: italic;
  margin: 0 0 0 7px;
}
.control-label.without-padding {
  padding: 0;
}
.control-label.large {
  width: 142px;
}
.control-label.huge {
  width: 182px;
}
.control-label.ginormous {
  width: 250px;
}
.control-label.small {
  width: 100px;
}
.control-label.fix-top-pos {
  position: relative;
  top: -6px;
}
.help-block {
  color: #60a369;
}
.error-block {
  color: #d25656;
}
textarea {
  resize: vertical;
}
.form-vertical .control-label {
  float: none;
  width: auto;
  padding-top: 0px;
  text-align: left;
}
.form-vertical .controls {
  margin-left: 0px;
}
.confirmation {
  position: relative;
  display: inline-flex;
  z-index: 1;
  text-align: center;
  border-top: 0;
  padding: 5px 12px;
  background: #ffebb0;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}
#toast-box-wrapper {
    position: absolute;
    z-index: 1100;
    float: left;
    width: 100%;
    text-align: center;
    top: 30px;
}
#toast-box {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
#toast-box.toast-showing {
  opacity: 1;
  -webkit-transition: opacity 0.15s ease-out;
  -moz-transition: opacity 0.15s ease-out;
  -o-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}
#toast-box .close {
  font-size: 14px;
  line-height: 14px;
  padding: 6px 0px 1px 10px;
}
#confirmation-wrapper {
  position: fixed;
  z-index: 9999;
  top: -100px;
  float: left;
  width: 100%;
  text-align: center;
  -webkit-transition: all 350ms cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition: all 350ms cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 350ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 350ms cubic-bezier(0.86, 0, 0.07, 1);
  font-weight: bold;
}
#confirmation-wrapper.confirmation-showing {
  top: 30px;
}
#confirmation-wrapper p {
  float: left;
  margin: 0 10px 0 0;
}
#confirmation-wrapper a {
  margin: 0 0 0 5px;
}
.year-controls {
  width: 100%;
  text-align: center;
  padding: 0 0 15px 0;
}
.year-controls .control .icon {
  font-size: 11px;
}
.year-controls .control.left-control {
  float: left;
}
.year-controls .control.right-control {
  float: right;
}
.year-controls .view-year {
  font-size: 16px;
  color: #505b65;
}
.year-controls.with-view-dropdown {
  position: relative;
  padding: 0;
}
.year-controls.with-view-dropdown .view-year {
  display: inline-block;
}
.year-controls.with-view-dropdown .dropdown-toggle {
  color: #505b65;
}
.year-controls.with-view-dropdown .caret {
  margin: 7px 0 0 2px;
  border-top-color: #505b65;
}
.year-controls.with-view-dropdown .dropdown-menu {
  top: 24px;
  left: 50%;
  margin-left: -164px;
  text-align: left;
}
.year-controls {
  width: 100%;
  text-align: center;
  padding: 0 0 15px 0;
}
.year-controls .control .icon {
  font-size: 11px;
}
.year-controls .control.left-control {
  float: left;
}
.year-controls .control.right-control {
  float: right;
}
.year-controls .view-year {
  font-size: 16px;
  color: #505b65;
}
.color-square {
  display: block;
  width: 20px;
  height: 20px;
}
.color-square.with-border {
  border: solid 1px #dadada;
  width: 18px;
  height: 18px;
}
.color-widget .current-color {
  margin: 5px 0 0 0;
}
.color-widget .current-color p {
  float: left;
}
.color-widget .current-color .color-square {
  float: left;
  margin: 0 0 0 10px;
}
.color-widget a.on-right {
  float: right;
  margin-top: -3px;
}
.buttons-bottom {
  background: #ffffff;
  border-top: 1px solid #dadada;
  padding: 12px 15px 0px;
}
.color-selector,
.spectrum-selector {
  -webkit-box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
  clear: both;
  width: 100%;
  padding: 0 0 10px 0;
  background: #ffffff;
  border: 1px solid #dadada;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.color-selector p,
.spectrum-selector p {
  padding: 8px 10px;
  border-bottom: 1px solid #dadada;
}
.color-selector ul,
.spectrum-selector ul {
  margin: 0;
  padding: 0 10px 10px 10px;
  list-style: none;
}
.color-selector ul li,
.spectrum-selector ul li {
  float: left;
  border: 1px solid transparent;
  margin: 5px;
}
.color-selector ul li:hover,
.spectrum-selector ul li:hover {
  border: 1px solid #bbbbbb;
}
.color-selector ul li.active,
.spectrum-selector ul li.active {
  border: 1px solid #bbbbbb;
}
.color-selector ul .color-square,
.spectrum-selector ul .color-square {
  margin: 4px;
}
.color-selector.spectrum-selector .spectrum .sp-container,
.spectrum-selector.spectrum-selector .spectrum .sp-container {
  width: 100%;
  background: transparent;
  border: 0px;
}
.color-selector.spectrum-selector .spectrum .sp-container .sp-picker-container,
.spectrum-selector.spectrum-selector .spectrum .sp-container .sp-picker-container {
  width: 100%;
  box-sizing: border-box;
}
.color-selector.spectrum-selector .spectrum .sp-container .sp-button-container,
.spectrum-selector.spectrum-selector .spectrum .sp-container .sp-button-container {
  display: none;
}
.color-selector.spectrum-selector .spectrum .sp-container .sp-color,
.spectrum-selector.spectrum-selector .spectrum .sp-container .sp-color,
.color-selector.spectrum-selector .spectrum .sp-container .sp-hue,
.spectrum-selector.spectrum-selector .spectrum .sp-container .sp-hue,
.color-selector.spectrum-selector .spectrum .sp-container .sp-clear,
.spectrum-selector.spectrum-selector .spectrum .sp-container .sp-clear {
  border-color: #c9c9c9;
}
.color-selector.spectrum-selector .spectrum .sp-container .sp-slider,
.spectrum-selector.spectrum-selector .spectrum .sp-container .sp-slider {
  width: 114%;
  left: -10% !important;
  border-radius: 8px;
  height: 5px;
  border-color: #969696;
  opacity: 1;
}
.fullscreen-icon {
  font-size: 16px;
  color: #797979;
  float: right;
}
.fullscreen-icon:hover {
  cursor: pointer;
}
.fullscreen-icon-wrapper {
  float: left;
}
#fullscreen-wrapper {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7faff;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  transition-timing-function: ease-in;
}
#fullscreen-wrapper:hover {
  cursor: default;
}
#fullscreen-wrapper .fullscreen-actions {
  position: fixed;
  bottom: 15px;
  opacity: 0;
  right: 420px;
  -webkit-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#fullscreen-wrapper .fullscreen-actions.fullwidth {
  right: 20px;
}
#fullscreen-wrapper .fullscreen-actions a {
  font-size: 14px;
}
#fullscreen-wrapper.in-view {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  z-index: 10000;
}
#fullscreen-wrapper.in-view .fullscreen-actions {
  bottom: 15px;
  opacity: 1;
}
.fullscreen-links #open-notes-panel-link,
.fullscreen-links #exit-fullscreen-link {
  color: #3883c1;
  font-size: 14px;
}
.fullscreen-links #open-notes-panel-link {
  position: relative;
  z-index: 10;
  margin: 20px 50px 0 0;
}
#fullscreen-editor {
  position: absolute;
  top: 15px;
  bottom: 100px;
  right: 420px;
  left: 20px;
  -webkit-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#fullscreen-editor .mce-tinymce,
#fullscreen-editor .mce-container-body,
#fullscreen-editor .mce-edit-area,
#fullscreen-editor #fullscreen_ifr {
  height: 100% !important;
}
#fullscreen-editor #exit-fullscreen-link {
  float: right;
  margin: 7px 0 0 10px;
  position: relative;
  z-index: 100;
}
#fullscreen-editor .wysihtml5-toolbar .dropdown-menu li {
  float: left;
  width: 100%;
  font-size: 12px;
}
#fullscreen-editor .wysihtml5-toolbar .dropdown-menu a {
  font-size: 12px;
}
#fullscreen-editor .wysihtml5-toolbar .current-font {
  font-size: 14px;
}
#fullscreen-editor .wysihtml5-toolbar a {
  float: left;
  margin: 0 -1px 0 0;
}
#fullscreen-editor #estimate_body_editor {
  position: absolute;
  top: 40px;
  bottom: 40px;
  height: auto;
  margin: 0;
  width: 100%;
}
#fullscreen-editor #estimate_body_editor,
#fullscreen-editor .wysihtml5-sandbox,
#fullscreen-editor .email_editor {
  -webkit-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#fullscreen-editor.fullwidth {
  right: 20px;
}
#fullscreen-editor.fullwidth #estimate_body_editor {
  right: 20px !important;
  left: 20px !important;
}
#fullscreen-editor.fullwidth #estimate_body_editor,
#fullscreen-editor.fullwidth .wysihtml5-sandbox,
#fullscreen-editor.fullwidth .email_editor {
  width: 100% !important;
}
#fullscreen-editor-panel {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 400px;
  box-shadow: 1px 1px 1px 1px black;
}
#fullscreen-map {
  margin: 0;
  width: 100%;
  height: 100%;
}
#fullscreen-map .map {
    right: 0;
    left: 0;
    bottom: 0;
    background: url('../images/null.png') no-repeat;
    background-size: cover;
}
#fullscreen-map .map-panel {
  padding: 10px 0 0 0;
}
#fullscreen-map .map-panel .icon {
  float: right;
  margin: 8px 0 -6px 0;
}
#quick-add-panel.swivelled-in {
  transition: transform 0.5s ease-out, right 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: skewY(0deg) perspective(850px) rotateY(0deg);
}
#quick-add-panel.swivelled-out {
  transition: transform 0.5s ease-in;
  transform: skewY(-5deg) perspective(850px) rotateY(-90deg);
}
#smart-post-code-wrap #quick-add-panel {
  transform: skewY(-5deg) perspective(850px) rotateY(90deg);
}
#smart-post-code-wrap.swivelled-in #quick-add-panel {
  transition: transform 0.5s ease-out;
  transform: skewY(0deg) perspective(850px) rotateY(0deg);
}
#smart-post-code-wrap.swivelled-out #quick-add-panel {
  transition: transform 0.5s ease-in;
  transform: skewY(0deg) perspective(850px) rotateY(90deg);
}
#smart-post-code-wrap .system_settings_sortable {
  overflow: auto;
  height: auto;
  margin: 20px 10px 0 10px;
  padding: 0 0 5px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border-top: 1px solid rgba(220, 220, 221, 0.6);
  margin: 10px 0;
}
#smart-post-code-wrap .system_settings_sortable li {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-left: 1px solid #dcdcdd;
  border-right: 1px solid #dcdcdd;
}
#smart-post-code-wrap .system_settings_sortable li:first-of-type {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
#smart-post-code-wrap .system_settings_sortable li:last-of-type {
  border-bottom: none;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
#smart-post-code-wrap .system_settings_sortable label {
  margin: 0;
  padding: 10px 14px;
}
#smart-post-code-wrap .system_settings_sortable input[type="radio"] {
  float: left;
  margin: 3px 6px 0 0;
}
#smart-post-code-wrap .system_settings_sortable input[type="radio"] {
  margin-bottom: 10px;
}
#smart-post-code-wrap .page-panel-actions a {
  float: left;
  margin: 6px 0 0 20px;
  color: #999999;
}
#smart-post-code-wrap .help-text {
  position: absolute;
  left: 160px;
  bottom: 40px;
}
#smart-post-code-wrap .help-text .helper-arrow {
  position: absolute;
  top: 12px;
  left: -110px;
  width: 105px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transform: rotate(330deg);
  -moz-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  -o-transform: rotate(330deg);
  transform: rotate(330deg);
}
.print-and-post-panel #page-panel-main .slide {
  padding-top: 20px !important;
}
.print-and-post-panel .no-results .warning-icon-wrap.with-info-icon .icon.fix-left {
  margin: 8px 0 0 15px !important;
}
#side-panel.pdf-preview #page-panel-main .slide.with-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.table-inside-section {
  padding: 10px 0px;
    display: block;
    width: 100%;
    overflow-x: auto;
}
table {
  width: 100%;
  background: #ffffff;
}
table thead {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #eee;
}
table thead tr.with-forced-background {
  background: #6393b5;
}
table thead tr.with-forced-background:hover {
  background: #6393b5 !important;
}
table thead th {
  color: #ffffff;
  background: #6393b5;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  padding: 4px 10px;
  text-align: left;
}
table thead th:first-child {
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
table thead th:last-child {
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  border-radius: 0 4px 0 0;
}
.handsontable table thead th:first-child {
    -webkit-border-radius: 0px 0 0 0;
    -moz-border-radius: 0px 0 0 0;
    border-radius: 0px 0 0 0;
}

.handsontable table thead th:last-child {
    -webkit-border-radius: 0 0px 0 0;
    -moz-border-radius: 0 0px 0 0;
    border-radius: 0 0px 0 0;
}

table thead th.with-right-border {
  border-right: 1px solid #6393b5;
}
table thead th.with-left-border {
  border-left: 1px solid #6393b5;
}
table thead th a {
  color: #ffffff;
  cursor: default;
}
table thead th a:hover {
  color: #ffffff;
  cursor: default;
}
table thead th.with-filter .text {
  float: left;
}
table thead th.with-filter .filter-icon {
  float: left;
  margin: 1px 0 0 7px;
  font-size: 10px;
}
table thead th.with-filter .filter-icon:hover {
  color: #ffffff;
}
table thead.subheader th {
  background: #ededed;
  color: #000000;
  letter-spacing: 0px;
  font-weight: normal;
}
table.with-border tbody {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
table.with-border tbody td {
  border: 1px solid #dddddd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
table tbody {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
table tbody tr {
  border-bottom: 1px solid #dddddd;
}
table tbody tr:nth-child(odd) {
  background: #fbfbfb;
}
table tbody tr:hover {
  background: #fff6d9;
}
table tbody tr:hover .actions-toggle {
  background-color: #ffffff;
}
table tbody tr.active-row {
  background: #dafdd7;
}
table tbody tr.active-row .checkbox-cell {
  background: #dafdd7;
}
table tbody tr.active-row:hover td {
  background: #dafdd7;
}
table tbody tr.highlight,
table tbody tr.record-selected {
  background-color: #d8e8f4 !important;
}
table tbody tr.highlight td,
table tbody tr.record-selected td {
  background-color: #d8e8f4 !important;
}
table tbody tr.feature-group {
  border-bottom: 0px;
}
table tbody tr.feature-group th {
  color: #ffffff;
  background: #6393b5;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  padding: 4px 10px;
  text-align: left;
}
table tbody tr.subheader th {
  background: #ededed;
  color: #000000;
  letter-spacing: 0px;
  font-weight: normal;
  text-align: left;
  padding: 8px 10px;
}
table tbody td {
  padding: 8px 10px;
}
table tbody td.with-right-border {
  border-right: 1px solid #dddddd;
}
table tbody td.with-left-border {
  border-left: 1px solid #dddddd;
}
table tbody td.with-bottom-border {
  border-bottom: 1px solid #dddddd;
}
table tbody td.centered {
  text-align: center;
}
table tbody td .large-profile-wrapper {
  display: inline-block;
}
table tbody td .empty-cell {
  float: left;
  line-height: 10px;
  color: rgba(80, 91, 101, 0.5);
}
table tbody .actions .edit {
  margin: 0 20px 0 0;
}
table tbody .actions .disable {
  margin: 0px;
  position: absolute;
}
table tbody .actions .btn {
  margin: 0 0 0 10px;
}
table tbody .actions.with-icons .delete,
table tbody .actions.with-icons .edit {
  float: left;
}
table.top-aligned td {
  vertical-align: top;
}
table.with-disabled-rows {
  background: repeating-linear-gradient(-45deg, rgba(210, 86, 86, 0.04), rgba(210, 86, 86, 0.04) 10px, transparent 10px, transparent 20px) !important;
}
table.with-disabled-rows tr {
  background: #ffffff;
}
table.with-disabled-rows tr.disabled-row {
  background: transparent;
}
table.with-disabled-rows tr.disabled-row:hover {
  background: transparent;
}
table.with-disabled-rows.tbody-table tr:nth-child(odd) {
  background: #ffffff;
}
table.with-disabled-rows.tbody-table tbody.disabled-row tr {
  background: transparent !important;
}
table.with-disabled-rows.tbody-table tbody.disabled-row tr:hover {
  background: transparent !important;
}
table .address-cell span {
  display: block;
}
table .date-column {
    width: 12.5%;
}
table .profile-image-with-status-column {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  padding: 0px 20px;
  text-align: center;
}
table .engineer-feedback-cell {
  padding: 10px 35px 30px;
}
table .type-column {
  width: 10%;
}
table .job-column {
  width: 12%;
}
table .tiny-description-column {
  width: 20%;
}
@media only screen and (max-width: 1500px) {
  table .tiny-description-column {
    width: 10%;
  }
}
table .small-description-column {
  width: 25%;
}
@media only screen and (max-width: 1500px) {
  table .small-description-column {
    width: 17%;
  }
}
table .medium-description-column {
  width: 30%;
}
table .moderate-description-column {
  width: 35%;
}
table .description-column {
  width: 40%;
}
table .big-description-column {
  width: 55%;
}
table .medium-column-static {
  width: 74px;
}
table .medium-column {
  width: 15%;
}
table .mobile-sharing {
  width: 140px;
}
table .actions-column {
  width: 140px;
  min-width: 140px;
}
table .amount-column {
  min-width: 140px;
}
table .small-amount-column {
  min-width: 128px;
  width: 128px;
  max-width: 128px;
}
table .small-actions-column {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
table .large-actions-column {
  width: 280px;
}
table .view-column {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}
table .large-totals-column {
  width: 180px;
}
table .larger-totals-column {
  width: 250px;
}
table .totals-column {
  width: 140px;
}
@media only screen and (max-width: 1500px) {
  table .totals-column {
    width: 105px;
  }
}
table .right-aligned {
  text-align: right;
}
table .status-column {
  width: 100px;
}
table .small-status-column {
  width: 10px;
  max-width: 10px;
  min-width: 10px;
  text-align: center;
  padding-right: 0px;
}
table .sent-from-column,
table .sent-to-column {
  width: 12%;
}
table .large-technical-reference-column {
  width: 350px;
}
table .technical-reference-column {
  width: 260px;
}
table .next-visit-column {
    width: 440px;
}
table .medium-fixed-column {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}
table .small-fixed-column {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}
table .icon_column {
  padding: 0;
  width: 16px;
}
table .automatic-statements-column {
  width: 173px !important;
  min-width: 173px;
  max-width: 173px;
}
table .automatic-debt-care-column {
  width: 191px !important;
  min-width: 191px;
  max-width: 191px;
}
table .after-sales-care-column {
  width: 132px !important;
  min-width: 132px;
  max-width: 132px;
}
table .with-status-label .label {
  float: right;
}
table.with-tooltips {
  position: relative;
}
table .nowrap-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
table .download_options {
  float: none;
  display: inline-block;
}
table tr.tr-no-style {
  border-bottom: none !important;
  background: none !important;
}
table tr.tr-no-style:hover {
  background: none !important;
}
table tr.tr-no-style:hover td {
  background: none !important;
}
table tr.view-totals strong {
  padding: 2px 0;
  float: left;
}
table.flat-style thead {
  background: #ffffff;
  border-bottom: none;
}
table.flat-style thead th {
  color: #505b65;
  font-weight: bold;
  letter-spacing: 0;
}
table.flat-style tr {
  border-bottom: none;
}
table.flat-style tr,
table.flat-style tr:nth-child(odd) {
  background: none;
}
table.flat-style tr:hover,
table.flat-style tr:nth-child(odd):hover {
  background: none;
}
table.flat-style strong {
  color: #505b65;
}
table.flat-style .with-bottom-border {
  border-bottom: 1px solid #f3f3f3;
}
table.with-orderable-rows tr {
  width: 100%;
}
table.with-orderable-rows tr.ui-sortable-helper {
  display: table;
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
table.with-orderable-rows tr:hover .light-icon {
  color: #acacac;
}
table.tbody-table tr {
  background: transparent;
  border: 0px;
}
table.tbody-table tr:hover {
  background: transparent;
}
table.tbody-table tr:nth-child(odd) {
  background: transparent;
}
table.tbody-table tbody:nth-child(odd) tr {
  background: #fbfbfb;
}
table.tbody-table tbody:hover tr {
  background: #fff6d9;
}
table.tbody-table tbody tr:last-of-type td {
  border-bottom: solid 1px #dddddd;
}
table.tbody-table tbody.on-drag-hover tr {
  background: #dff0d8;
}
.filter {
  float: left;
}
.filter .search_box {
  height: 26px;
  width: 324px;
  margin: 0;
}
.filter .search_box input {
  width: 270px;
  margin: 3px 0 0 0;
}
.filter .search_box .ss-search {
  margin: 3px 6px 0 10px;
}
.filter .search_box .ss-delete {
  color: #505b65;
  font-size: 10px;
  position: absolute;
  margin: 3px 0 0 3px;
  display: none;
}
.created-by-column {
  width: 250px;
}
.profile-cell .profile_image {
  float: left;
  margin-right: 20px !important;
}
.profile-cell img {
  float: left;
  margin: 0 20px 0 4px;
  width: 65px;
  height: 65px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #c9c9c9;
  padding: 4px;
}
.profile-cell div {
  overflow: hidden;
}
.profile-cell div span {
  display: block;
  font-size: 13px;
}
.profile-cell div:first-of-type {
  margin: 0 0 20px 0;
}
.telephone-cell div:first-of-type {
  margin: 0 0 20px 0;
}
.telephone-cell span {
  display: block;
}
.notes-table .actions-column {
  width: 160px;
}
.notes-table .description-cell {
  padding-right: 40px;
}
.contacts-table .actions-column {
  width: 270px;
}
.invoice_addresses-table .actions-column {
  width: 180px;
}
.holiday-table .holiday-duration-cell div:first-of-type {
  margin: 0 0 20px 0;
}
.holiday-table .holiday-duration-cell span {
  display: block;
}
.table-with-filter {
  clear: both;
}
td.with-elipsis div {
  position: relative;
  height: 20px;
}
td.with-elipsis span {
  position: absolute;
  left: 0;
  right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.attached-files-table {
  clear: both;
}
.attached-files-table .date-added-column {
  width: 250px;
}
.preview-file-link {
  font-size: 16px;
  color: #797979;
  position: relative;
  top: 2px;
}
.preview-file-link:hover {
  color: #797979;
}
.fix-tooltip-position .tooltip {
  margin-left: -4px;
}
.empty-cell-text {
  color: #999999;
}
table tbody tr.unassigned {
  background: #eaedf1;
}
.current_users_table table label {
  margin-bottom: 0px;
}
.without-bottom-border {
  border-bottom: 0px;
}
.relative-fixed-table-wrapper {
  position: relative;
}
.relative-fixed-table-wrapper.with-totals {
  padding-bottom: 30px;
}
.relative-fixed-table-wrapper.job-costs {
  padding-right: 290px !important;
}
@media only screen and (max-width: 1500px) {
  .relative-fixed-table-wrapper.job-costs {
    padding-right: 255px !important;
  }
}
.fixed-table-wrapper {
  overflow: auto;
}
.fixed-table-wrapper table.fixed-table tr:nth-child(odd):hover {
  background: #fff6d9;
}
.fixed-table-wrapper table.fixed-table tr:nth-child(odd):hover td {
  background: #fff6d9;
}
.fixed-table-wrapper table.fixed-table tr:nth-child(odd) td {
  background: #fbfbfb;
}
.fixed-table-wrapper table.fixed-table tr:nth-child(even):hover {
  background: #fff6d9;
}
.fixed-table-wrapper table.fixed-table tr:nth-child(even):hover td {
  background: #fff6d9;
}
.fixed-table-wrapper table.fixed-table tr.tr-no-style:nth-child(odd):hover {
  background: #ffffff;
}
.fixed-table-wrapper table.fixed-table tr.tr-no-style:nth-child(odd):hover td {
  background: #ffffff;
}
.fixed-table-wrapper table.fixed-table tr.tr-no-style:nth-child(odd) td {
  background: #ffffff;
}
.fixed-table-wrapper table.fixed-table tr.tr-no-style:nth-child(even):hover {
  background: #ffffff;
}
.fixed-table-wrapper table.fixed-table tr.tr-no-style:nth-child(even):hover td {
  background: #ffffff;
}
.fixed-table-wrapper table.fixed-table th,
.fixed-table-wrapper table.fixed-table td {
  white-space: nowrap;
}
.fixed-table-wrapper table.fixed-table td.fixed-column,
.fixed-table-wrapper table.fixed-table th.fixed-column {
  position: absolute;
}
.fixed-table-wrapper table.fixed-table td.fixed-column.with-bottom-border,
.fixed-table-wrapper table.fixed-table th.fixed-column.with-bottom-border {
  border-bottom: 1px solid #dddddd;
}
.fixed-table-wrapper table.fixed-table.job-costs th.fixed-column-1,
.fixed-table-wrapper table.fixed-table.job-costs td.fixed-column-1 {
  right: 130px;
}
.fixed-table-wrapper table.fixed-table.job-costs th.fixed-column-2,
.fixed-table-wrapper table.fixed-table.job-costs td.fixed-column-2 {
  right: 10px;
}
table.diary {
  margin-bottom: 30px;
}
table.diary th {
  background: #fbfbfb;
  font-weight: bold;
  letter-spacing: 0;
  color: black;
  padding: 5px 10px;
}
table.diary th,
table.diary td {
  border: solid 1px #e1e1e1;
  width: 14.2857142857%;
}
table.diary td {
  height: 80px;
  background: white;
  position: relative;
  padding-top: 28px;
  vertical-align: top;
}
table.diary td .day-number {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
  opacity: 0.8;
}
table.diary td.disabled {
  background: rgba(0, 0, 0, 0.04);
  cursor: not-allowed;
}
table.diary td .diary-event {
  margin-bottom: 5px;
  border-left: solid 3px;
  cursor: pointer;
}
table.diary td .diary-event.disabled {
  opacity: 0.2;
  pointer-events: none;
}
table.diary td .diary-event.engineer-1 {
  border-left-color: #d25656;
}
table.diary td .diary-event.engineer-1 .event-header {
  background: #fbefef;
  border-bottom-color: #f8e3e3;
}
table.diary td .diary-event.engineer-2 {
  border-left-color: #6393b5;
}
table.diary td .diary-event.engineer-2 .event-header {
  background: #e6eef3;
  border-bottom-color: #dce7ee;
}
table.diary td .diary-event.engineer-3 {
  border-left-color: #746ca6;
}
table.diary td .diary-event.engineer-3 .event-header {
  background: #e6e5ef;
  border-bottom-color: #dddbe9;
}
table.diary td .diary-event.engineer-4 {
  border-left-color: #60a369;
}
table.diary td .diary-event.engineer-4 .event-header {
  background: #d8e8da;
  border-bottom-color: #c4ddc7;
}
table.diary td .diary-event .event-header {
  padding: 5px 5px;
  position: relative;
}
table.diary td .diary-event .event-header .ss-clock {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 12px;
}
table.diary td .diary-event .event-header .tooltip {
  margin-top: -2px;
}
table.diary tr:hover {
  background: transparent;
}
table.diary tr:hover td {
  background: white;
}
table.diary tr:hover td.disabled {
  background: rgba(0, 0, 0, 0.04);
}
.diary-event-feedback-cell strong {
  display: inline-block;
  margin-bottom: 5px;
}
.diary-event-feedback-cell .quotes-ui strong {
  margin-bottom: 0px;
}
.diary-event-feedback-cell .quotes-ui span {
  margin-left: 20px;
  margin-bottom: 5px;
  display: inline-block;
  margin-top: 2px;
}
.diary-event-feedback-cell .quotes-ui span.question-name {
  margin-left: 5px;
  margin-bottom: 0px;
}
.diary-event-feedback-cell .quotes-ui span.ss-quote {
  margin-left: 0px;
}
.diary-event-feedback-cell .and-more {
  margin-left: 20px;
  display: inline-block;
}
.year-controls {
  width: 100%;
  text-align: center;
  padding: 0 0 15px 0;
}
.year-controls .control .icon {
  font-size: 11px;
}
.year-controls .control.left-control {
  float: left;
}
.year-controls .control.right-control {
  float: right;
}
.year-controls .view-year {
  font-size: 16px;
  color: #505b65;
}
.social-button {
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 4px 14px;
  border: 1px solid #2a4f89;
  background-color: #4b7bc8;
  background-image: -moz-linear-gradient(top, #5484cd, #3e6dc0);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5484cd), to(#3e6dc0));
  background-image: -webkit-linear-gradient(top, #5484cd, #3e6dc0);
  background-image: -o-linear-gradient(top, #5484cd, #3e6dc0);
  background-image: linear-gradient(to bottom, #5484cd, #3e6dc0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5484cd', endColorstr='#ff3e6dc0', GradientType=0);
  -webkit-box-shadow: inset 1px 1px 0 0 #6a94d5;
  -moz-box-shadow: inset 1px 1px 0 0 #6a94d5;
  box-shadow: inset 1px 1px 0 0 #6a94d5;
  font-size: 12px;
  letter-spacing: 1px;
  text-shadow: 0px -1px 0px #333333;
}
.social-button:hover {
  background-color: rgba(75, 123, 200, 0.8);
  background-image: -moz-linear-gradient(top, rgba(84, 132, 205, 0.8), rgba(62, 109, 192, 0.8));
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(84, 132, 205, 0.8)), to(rgba(62, 109, 192, 0.8)));
  background-image: -webkit-linear-gradient(top, rgba(84, 132, 205, 0.8), rgba(62, 109, 192, 0.8));
  background-image: -o-linear-gradient(top, rgba(84, 132, 205, 0.8), rgba(62, 109, 192, 0.8));
  background-image: linear-gradient(to bottom, rgba(84, 132, 205, 0.8), rgba(62, 109, 192, 0.8));
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc5484cd', endColorstr='#cc3e6dc0', GradientType=0);
}
@media screen and (max-width: 435px) {
  .remove-padding-on-mobile {
    padding: 0px 10px;
  }
}
.login {
  max-width: 660px;
  margin: 80px auto 0 auto;
}
@media screen and (max-width: 435px) {
  .login {
    margin-top: -10px;
  }
}
.login fieldset {
  padding-bottom: 0;
}
.login fieldset .add-on {
  padding: 4px 10px;
}
.login .input-outer-wrapper {
  margin: 0 0 30px 0;
}
.login .input-outer-wrapper .control-error-message.show {
  top: -10px;
}
.login .input-outer-wrapper:last-of-type {
  margin-bottom: 0px;
}
.login .input-outer-wrapper.error .control-error-message {
  top: 0px;
}
.login .or-divider {
  -webkit-box-shadow: inset 1px 0 0 0 #ffffff;
  -moz-box-shadow: inset 1px 0 0 0 #ffffff;
  box-shadow: inset 1px 0 0 0 #ffffff;
  border-left: 1px solid #dadada;
  color: #999999;
  position: relative;
}
.login .or-divider span {
  background: #f9f9f9;
  position: absolute;
  top: 50%;
  margin-left: -14px;
  margin-top: -17px;
  padding: 6px;
}
@media screen and (max-width: 710px) {
  .login .or-divider {
    border-left: 0px;
    border-bottom: solid 1px #dadada;
    width: 100%;
  }
  .login .or-divider span {
    top: 0;
    left: 50%;
    margin-left: -17px;
    margin-top: -15px;
  }
}
.login .login-wrapper {
  position: relative;
  margin-top: -20px;
}
.login .login-wrapper .flex-1 {
  padding: 30px;
  min-width: 245px;
}
@media screen and (max-width: 435px) {
  .login .login-wrapper .flex-1 {
    min-width: 200px;
  }
}
.login .login-wrapper .flex-1.with-help-block {
  border-left: 1px solid #dadada;
  background: #ffffff;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
@media screen and (max-width: 710px) {
  .login .login-wrapper .flex-1.with-help-block {
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
    border-left: 0px;
    border-top: 1px solid #dadada;
  }
}
.login .login-wrapper .help-block {
  margin-top: 0px;
}
.login .login-wrapper .help-block p {
  color: #60a369;
}
.login .btn-primary {
  float: right;
}
#social-login {
  margin-left: 0;
}
#social-login .social-button {
  float: right;
  width: 100% ;
}
#social-login .social-button:first-of-type {
  margin-bottom: 20px;
}
.bottom-link {
  color: #999999;
  float: left;
  font-size: 11px;
  margin: 5px 0 0 0;
}
.bottom-link:hover {
  color: #919191;
}
.password {
  max-width: 440px;
  margin: 80px auto 0 auto;
}
.password .input-outer-wrapper {
  margin: 0 0 24px 0;
}
.alert-error {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.password-requirements ul {
  list-style: none;
  margin: 12px 0 0 0;
}
.password-requirements ul li.active {
  color: #60a369;
}
.password-requirements ul li.active .ss-check {
  visibility: visible;
}
.password-requirements ul .ss-check {
  visibility: hidden;
}
.password-requirements.inline {
  margin: 0px !important;
  padding-top: 0px !important;
}
.search_box {
  position: relative;
  width: 90px;
  height: 24px;
  overflow: hidden;
  background-color: #ffffff;
  margin: 8px 26px 0 32px;
  border-radius: 14px;
  float: left;
  transition: width 0.7s, border linear 0.2s, box-shadow linear 0.2s;
}
.search_box input {
  -webkit-transition: width 0.7s;
  -moz-transition: width 0.7s;
  -o-transition: width 0.7s;
  transition: width 0.7s;
  line-height: 22px;
  width: 42px;
  border: none;
  box-shadow: none;
  padding: 2px 0px 0px;
  margin: 0;
  color: #5e6d7f;
  font-size: 12px;
  background-color: #ffffff;
  font-weight: 500;
  outline: none;
  position: relative;
  background: transparent;
}
.search_box input:focus {
  outline: none;
  -webkit-box-shadow: 0px 0px 0px transparent;
  -moz-box-shadow: 0px 0px 0px transparent;
  box-shadow: 0px 0px 0px transparent;
}
.search_box .search-placeholder {
  color: #999999;
  font-size: 13px;
  position: absolute;
  left: 33px;
  top: 3px;
}
.search_box.widen {
  width: 600px;
}
.search_box.widen input {
  width: 560px;
}
.search_box.search-focussed {
  border: 1px solid rgba(82, 168, 236, 0.8) !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}
.search_box.small input {
  margin-top: 0px;
}
.ss-search {
  color: #5e6d7f;
  font-size: 13px;
  float: left;
  margin: 3px 6px 0 12px;
  text-shadow: none;
}
.results-wrapper {
  padding-top: 20px;
}
.results-wrapper .widget {
  padding-bottom: 1.5%;
}
.results-wrapper .results-table-wrapper {
  position: absolute;
  right: 30px;
  left: 360px;
  background: #ffffff;
}
.results-wrapper .results-table-wrapper .pagination {
  margin-right: 1.3%;
}
.results-wrapper .results-table-wrapper .title h3 {
  color: #505b65;
  font-size: 18px;
  margin: 0;
}
.results-wrapper.no-padding {
  padding: 0;
}
.loading-wrapper {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  margin-top: -50px;
}
.loading-wrapper p {
  padding-top: 40px;
  color: #7e8d93;
}
.filter-results {
  position: fixed;
  width: 300px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  padding: 0;
}
.filter-results .search-well li a span:nth-child(2) {
  pointer-events: relative;
  top: -3px;
}
.filter-results ul {
  clear: both;
  margin: 0;
}
.filter-results li {
  list-style: none;
  width: 100%;
}
.filter-results li a {
  display: block;
  padding: 6px 12px;
  color: #304f65;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.filter-results li a:hover {
  background: #f6f7fa;
}
.filter-results li a.active {
  background: #eceef4;
  border-top: 1px solid #d1d8e7;
  border-bottom: 1px solid #d1d8e7;
  -webkit-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
}
.filter-results li a.active .number {
  background: #ffffff;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
}
.filter-results li a.active.first {
  border-top: 0;
  padding-top: 7px;
}
.filter-results li a.active.last {
  border-bottom: 0;
}
.filter-results li a.last {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.filter-results li a .icon {
  font-size: 16px;
  display: inline-block;
  margin: 3px 0 0 0;
}
.filter-results li a .number {
  float: right;
  background: #eceef4;
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
  padding: 0 6px;
  border-radius: 3px;
  border: 1px solid #d1d8e7;
}
.filter-results li:last-child a {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
.filter-results li:last-child a.active {
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.6);
  box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.6);
}
.results-table {
  width: 97%;
  margin: 5px auto 10px auto;
}
.results-table thead tr {
  background: transparent !important;
  border-left: none;
  border-right: none;
}
.results-table th {
  text-align: left;
  padding: 2px 10px;
  border-bottom: 1px solid #dcdada;
}
.results-table tr {
  border-bottom: 1px dotted #dcdada;
  border-left: 1px solid #dcdada;
  border-right: 1px solid #dcdada;
}
.results-table tr:nth-child(odd) {
  background: #fbfbfb;
}
.results-table tr:nth-child(even) {
  background: #ffffff;
}
.results-table th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.results-table th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.results-table tr:last-child {
  border-bottom: 1px solid #dcdada;
}
.chat-history .medium-header {
  float: none;
}
.chat-list-wrapper {
  width: 500px;
  overflow: auto;
  height: 100%;
  background: #f8f8f8;
}
.conversation-messages-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #d1d8e7;
}
.conversation-messages-wrapper .chat-wrapper {
  overflow: auto;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message {
  padding: 23px 20px 15px;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message .profile-pic {
  float: left;
  display: block;
  margin: 0 10px 0 0;
  width: 35px;
  height: 35px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: -8px;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message .profile-pic img {
  width: 37px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message .message-text {
  display: block;
  overflow: hidden;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message .message-text p {
  margin: 10px 50px 0 0;
  color: #5e6d7f;
  font-size: 12px;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message .message-text .time {
  margin: 0 20px;
}
.conversation-messages-wrapper .chat-wrapper .chat .single-message .message-text .light {
  color: #9c9797;
  font-size: 12px;
}
.conversation-messages-wrapper .chat-wrapper .chat:nth-of-type(even) {
  background: rgba(0, 0, 0, 0.01);
  border-top: solid 1px rgba(0, 0, 0, 0.03);
  border-bottom: solid 1px rgba(0, 0, 0, 0.03);
}
.all_messages {
  height: 100%;
}
.all_messages .message-row {
  height: 90%;
}
.all_messages .customers {
  height: 100%;
}
.all_messages .filter_links input {
  padding: 2px 4px;
  margin: 0;
}
.all_messages .filter_links input.from {
  margin: 0 10px 0 0;
}
.all_messages .data-section {
  height: 96%;
  overflow: hidden;
  margin-top: -1px;
  padding: 0 !important;
}
.all_messages .data-section .all_message_main,
.all_messages .data-section > .row-fluid {
  height: 100%;
}
.all_messages .download_options {
  float: right;
  margin: 12px 20px 0 0;
}
.all_messages .app-filter {
  margin-left: 10px;
}
.all_messages .app-filter .daterange-wrapper,
.all_messages .app-filter .search-dropdown-wrapper {
  z-index: 2;
}
.title-banner {
  padding: 10px 0 8px;
  border-bottom: 1px solid #dddddd;
  -webkit-box-shadow: 0px 1px 1px #e9e9e9;
  -moz-box-shadow: 0px 1px 1px #e9e9e9;
  box-shadow: 0px 1px 1px #e9e9e9;
  position: relative;
  z-index: 1;
}
.title-banner > .row-fluid {
  padding: 0;
}
.title-banner .span5 h4 {
  border-right: 1px solid #dddddd;
  padding: 0 0 0 20px;
}
.title-banner h4 {
  color: #505b65;
}
.title-banner .messages h4 {
  float: left;
}
.all_message_main {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.all_message_main > .row-fluid {
  padding: 0;
}
.all_message_main ul {
  margin: 0;
  list-style: none;
}
.conversations {
  overflow: auto;
}
.conversations li {
  padding: 10.4px 0;
  border-bottom: 1px solid #d1d8e7;
  -webkit-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #f7f7f7;
  position: relative;
  cursor: pointer;
}
.conversations li.active {
  background: #ffffff;
  cursor: default;
}
.conversations li.unread {
  background: #f3fccf;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.conversations li .activate-chevron {
  font-size: 13px;
  color: #3883c1;
  margin-right: -3px;
  margin-top: 1px;
}
.conversations .info-block {
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  margin-top: 4px;
}
.conversations .info-block .unread-count {
  margin-right: 5px;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.conversations .info-block .unread-count.showing {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.conversations .name {
  color: #304f65;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -1px;
}
.conversations .time-stamp {
  float: right;
  color: #9c9797;
  font-size: 13px;
  margin: 0px 10px;
}
.conversations .time {
  margin: 0 0 0 20px;
}
.conversations .device {
  font-size: 12px;
  color: #5e6d7f;
}
.conversations .no-records-from-search {
  margin-top: 25px;
}
.conversations .no-records-from-search .warning-icon-wrap {
  margin-top: 90px !important;
}
.conversations .no-records-from-search .helper-arrow {
  top: -60px;
}
.conversations .no-records-from-search .helper-text {
  top: -41px;
}
.conversations .loading-wrapper {
  margin-top: 40px;
}
.conversations .no-conversations {
  margin-top: 92px;
}
.reply-to-conversation {
  height: 61px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}
.reply-to-conversation a {
  float: right;
  margin: 16px;
}
.warning-icon-wrap {
  display: block;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  border: 7px solid #ecbd2e;
  margin: 12px auto 14px auto;
}
.warning-icon-wrap .icon {
  font-size: 34px;
  display: block;
  float: left;
  margin: 4px 17px;
  width: 50px;
  height: 51px;
  line-height: 65px;
  color: #ecbd2e;
}
.warning-icon-wrap.with-info-icon {
  border-color: #bccfd6;
}
.warning-icon-wrap.with-info-icon .icon {
  margin: 7px 0 0 15px !important;
  top: 0px;
  color: #bccfd6;
}
.warning-icon-wrap.with-info-icon .icon.fix-left {
  margin: 7px 0 0 18px !important;
  top: 0px;
}
.warning-icon-wrap.with-info-icon + p {
  color: #7e8d93;
}
.trigger-warning-modal {
  display: none;
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 3;
}
.warning-modal {
  width: 360px;
  margin-left: -180px;
}
.warning-modal .modal-header {
  background: #eeeeee;
  border-bottom: 1px solid rgba(80, 91, 101, 0.2);
}
.warning-modal .modal-header h4 {
  color: #505b65;
  letter-spacing: 0;
}
.warning-modal .modal-body {
  text-align: center;
}
.warning-modal .modal-body p {
  margin: 10px 14px 25px;
}
.warning-modal.large {
  width: 560px;
  margin-left: -280px;
}
.warning-modal .warning-icon-wrap .icon {
  margin: 5px 17px 4px 15px !important;
}
.service-type-dropdown {
  margin-bottom: 10px;
}
.service-type-dropdown:last-of-type {
  margin-bottom: 0px;
}
.service-type-dropdown a {
  margin-left: 10px;
  display: inline-block;
}
.list-widget .app-filter {
  position: relative;
}
.list-widget .app-filter .input_box {
  position: absolute;
  left: 10px;
  top: 6px;
  width: 400px;
}
.list-widget .app-filter.compensate-for-drag {
  margin-left: 21px;
  width: auto;
  display: block;
  float: none;
  border-bottom: 0px;
}
.list-widget ul {
  position: relative !important;
  left: 0px;
  right: 0px;
  top: 0px !important;
}
.list-widget ul.with-drag {
  border-top: 1px solid #dadada;
  width: auto;
  float: none;
}
.list-widget ul li {
  position: relative;
}
.list-widget ul li.with-drag {
  padding-left: 31px;
}
.list-widget ul li.ui-sortable-helper {
  border-top: solid 1px #dadada;
  left: 0px !important;
  background-color: #dafdd7;
}
.list-widget ul li:hover,
.list-widget ul li.editing {
  background-color: #dafdd7;
  -webkit-transition: background 0s;
  -moz-transition: background 0s;
  -o-transition: background 0s;
  transition: background 0s;
}
.list-widget ul li.with-highlight-transition {
  -webkit-transition: background 1s ease 0.5s, border-radius 0s 1.5s;
  -moz-transition: background 1s ease 0.5s, border-radius 0s 1.5s;
  -o-transition: background 1s ease 0.5s, border-radius 0s 1.5s;
  transition: background 1s ease 0.5s, border-radius 0s 1.5s;
}
.list-widget ul li.with-highlight {
  background: #d8e8f4;
  -webkit-transition: background 0s ease 0s, border-radius 0s 0s;
  -moz-transition: background 0s ease 0s, border-radius 0s 0s;
  -o-transition: background 0s ease 0s, border-radius 0s 0s;
  transition: background 0s ease 0s, border-radius 0s 0s;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
}
.list-widget ul li.no-results-li {
  background-color: #ffffff;
  -webkit-transition: background 0s;
  -moz-transition: background 0s;
  -o-transition: background 0s;
  transition: background 0s;
}
.list-widget ul li.editing {
  padding: 3px 10px;
}
.list-widget ul li form {
  position: static;
  margin-bottom: 0px;
}
.list-widget ul li span {
  display: block;
  margin-right: 55px;
}
.list-widget ul li span.ss-delete {
  margin-right: 0px;
  margin-top: 2px;
}
.list-widget ul li input {
  width: 400px;
}
.list-widget ul li .right-side {
  float: right;
  cursor: pointer;
}
.list-widget ul li .right-side.delete-link {
  position: absolute;
  right: 15px;
  top: 8px;
}
.list-widget ul li .right-side.edit-link {
  position: absolute;
  right: 65px;
  top: 8px;
}
.list-widget ul li .drag-handle {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 21px;
  cursor: move;
  background-color: #fbfbfb !important;
  box-sizing: border-box;
  border-right: 1px solid #dadada;
}
.list-widget ul li .drag-handle .ss-grid {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 8px;
  font-size: 11px;
  width: 8px;
  overflow: hidden;
  height: 8px;
  margin-left: -4px;
  color: #888;
  position: absolute;
  top: 50%;
  left: 50%;
}
.list-widget ul li .drag-handle .ss-grid:first-of-type {
  margin-top: -8px;
}
.list-widget ul .no-results-li .no-results-wrap .helper-arrow {
  left: 45px;
  top: 0px;
}
.list-widget ul .no-results-li .no-results-wrap .helper-text {
  top: 28px;
  left: 156px;
}
.choose_tagging_fields {
  margin: 0 0 20px 0;
}
.tagging-header {
  margin-bottom: 10px;
}
.tagging-header .title {
  margin-bottom: 0px;
}
.tagging-header .title,
.tagging-header .label-title {
  float: left;
}
.tagging-header .label-title {
  margin-bottom: 20px;
  display: inline-block;
}
.tagging-header .btn {
  display: block;
  margin: -3px 0 0 10px;
  float: left;
}
.tagging-header .preview-link {
  float: right;
}
.tagging-header .open-sidebar,
.controls .open-sidebar,
.tagging-header .helper-btn,
.controls .helper-btn {
  float: right;
}
.tagging-header .open-sidebar.with-bottom-margin,
.controls .open-sidebar.with-bottom-margin,
.tagging-header .helper-btn.with-bottom-margin,
.controls .helper-btn.with-bottom-margin {
  margin-bottom: 18px;
}
.controls .helper-btn {
  float: left;
  margin-top: 10px;
}
.truncated-text {
  display: none !important;
}
.large-screens {
  display: none;
}
.small-screen-row {
  display: none;
}
@media screen and (max-width: 450px) {
  .reset_password > .row-fluid {
    padding: 0;
  }
  .reset_password form {
    margin-top: 30px;
  }
  .reset_password fieldset > .row-fluid {
    padding: 0 15px;
  }
  .confirmation-wrapper {
    width: 86%;
    margin-left: -43%;
  }
}
@media (max-height: 768px) {
  .all_messages .message-row {
    height: 86%;
  }
  .preview-modal .letterhead-preview,
  .preview-modal .file-preview {
    height: 400px !important;
  }
}
@media screen and (max-width: 1030px) {
  .top_bar .search_box {
    margin: 8px 12px 0 32px;
  }
  .top_bar .help_button .btn-help {
    margin: 7px 0 0 8px;
  }
  .settings-section .span9 ul {
    min-height: 140px !important;
  }
}
@media screen and (max-width: 1030px), screen and (max-height: 788px) {
  .account-modal {
    top: 39.5% !important;
  }
}
@media screen and (max-width: 1170px) {
  .top_bar .search_box {
    margin: 8px 14px 0 32px;
  }
  .top_bar .help_button .btn-help {
    margin: 8px 3px 0;
  }
  .properties .content .icon {
    display: none;
  }
  .left-column-on-ss {
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
  }
  .created-by-column,
  .profile-cell,
  .column-to-hide {
    display: none;
  }
  .customisable-form-wrapper .dropdown_view input[type="text"],
  .customisable-form-wrapper .checkbox_view input[type="text"] {
    width: 120px !important;
  }
  .edit_dropdown_view .control-label,
  .edit_checkbox_view .control-label {
    width: 120px !important;
  }
  .edit_dropdown_view input[type="text"],
  .edit_checkbox_view input[type="text"] {
    width: 120px !important;
  }
  .large-screen-row {
    display: none;
  }
  .small-screen-row {
    display: block;
  }
  .map-container .details-wrap {
    width: 50%;
  }
  .map-container .map {
    width: 50%;
  }
}
@media screen and (max-width: 1255px) {
  .question-type .input-large,
  .question-type .phantom-input {
    width: 140px;
  }
  .question-type .phantom-checkbox {
    display: none;
  }
  .results-wrapper .filter-results {
    width: 220px;
  }
  .results-wrapper .results-table-wrapper {
    left: 280px;
  }
}
@media screen and (min-width: 1300px) {
  .with-columns .sortable_entity {
    width: 26% !important;
  }
}
@media screen and (max-width: 1470px) {
  .truncated-text {
    display: inline !important;
  }
  .normal-text {
    display: none !important;
  }
  .customers-wrapper .customer-details {
    width: 25.914894%;
  }
  .customers-wrapper .customer-details .profile_image {
    display: none;
  }
  .customers-wrapper .property-details {
    width: 71.957447%;
  }
  .customers-wrapper .property-details .field .field-label {
    width: 122px;
  }
}
@media screen and (max-width: 1070px) {
  .customers-wrapper .section-title h3 {
    font-size: 16px;
  }
  .customers-wrapper .section-title a {
    font-size: 12px;
  }
  .customers-wrapper .customer-details {
    width: 23.914894%;
  }
  .customers-wrapper .property-details {
    width: 73.957447%;
  }
  .customers-wrapper .property-details .left-column {
    width: 42.93617%;
  }
  .customers-wrapper .property-details .left-column .field .field-label {
    margin-left: 0;
  }
  .customers-wrapper .property-details .right-column {
    width: 54.93617%;
  }
}
@media screen and (max-height: 745px) {
  .responsive-table .row-to-hide {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .dashboard-section {
    padding-bottom: 0;
  }
  .dashboard-section .row-fluid.small-padding {
    padding-top: 0;
  }
  .dashboard-section .row-fluid .span6 {
    width: 100%;
    margin: 0;
  }
  .dashboard-section .row-fluid .widget {
    margin-bottom: 20px;
  }
  .dashboard-section .row-fluid .widget .normal-text {
    display: inline !important;
  }
  .dashboard-section .row-fluid .widget .truncated-text {
    display: none !important;
  }
  .dashboard-section .row-fluid .widget .year-controls .icon {
    display: inline;
  }
  .dashboard-section .row-fluid .widget .recent-supplier-graph {
    display: none;
  }
}
@media screen and (min-width: 1900px) {
  .dashboard-section .span6 {
    width: 31.914893617021278%;
    margin-left: 2.127659574468085%;
  }
  .dashboard-section .span6:first-child {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1375px) {
  .existing_customer_records.with_filters .search_box.large {
    display: none;
  }
  .existing_customer_records.with_filters .search_box.small {
    display: block !important;
    width: 90px !important;
  }
  .existing_customer_records.with_filters .search_box.small input {
    width: 42px !important;
  }
  .existing_customer_records.with_filters .search_box.small .ss-delete {
    margin: 3px 0 0 -3px;
  }
  .existing_customer_records.with_filters .search_box.small.widen {
    width: 100% !important;
  }
  .existing_customer_records.with_filters .search_box.small.widen input {
    width: 90% !important;
  }
  .existing_customer_records.with_filters .filter_links .input-medium {
    width: 119px !important;
  }
}
@media screen and (max-width: 1550px) {
  .letterhead-container .span8 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .letter {
    max-width: 960px !important;
    float: none !important;
    margin: 20px auto !important;
  }
  .letterhead-spacing {
    width: 100% !important;
  }
  .letterhead-spacing {
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
    padding: 0 !important;
    position: relative !important;
    margin-left: 0 !important;
    border-left: none !important;
    border-top: 1px solid #dadada;
  }
  .letterhead-spacing .title,
  .letterhead-spacing p {
    padding: 0 20px !important;
  }
  .letterhead-spacing .title {
    padding-top: 20px !important;
  }
  .metric-wrapper {
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
    position: relative !important;
    margin: 20px 0 0 0 !important;
  }
}
@media screen and (max-width: 1060px) {
  .letter {
    width: 880px !important;
  }
}
@media screen and (min-width: 1400px) {
  .system_settings_sortable li .sortable_entity.with-cutoff {
    width: 84% !important;
  }
}
@media screen and (max-width: 1024px) {
  .tagging-header a.ss-view {
    font-size: 24px;
    float: right;
    color: #797979;
  }
  .tagging-header a.letter-preview_2 {
    display: none;
  }
  .telephone-column-to-hide {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .tagging-header a.ss-view {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .property_gold_plan_large {
    display: none !important;
  }
}
@media screen and (min-width: 1601px) {
  .property_gold_plan_small {
    display: none !important;
  }
}
@media screen and (max-width: 1359px) {
  .service_reminders_large_text {
    display: none;
  }
}
@media screen and (min-width: 1360px) {
  .service_reminders_small_text {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .column-to-hide-invoice-addrs {
    display: none;
  }
}
@media screen and (max-width: 1600px) {
  .customers .properties .content .service-icon,
  .customers .properties .content .icon {
    display: none;
  }
}
.confirmation-wrapper {
  width: 360px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -160px 0 0 -180px;
  border: 1px solid #acaeb2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.confirmation-title {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  background: #eeeeee;
  border-bottom: 1px solid #ced0d2;
}
.confirmation-title h4 {
  color: #505b65;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  padding: 9px 15px;
  margin: 0;
}
.confirmation-body {
  float: left;
  width: 100%;
  background: #ffffff;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.confirmation-body .confirmation-icon-wrap {
  display: block;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  border: 7px solid #60a369;
  margin: 26px auto 22px auto;
}
.confirmation-body .icon {
  color: #60a369;
  font-size: 34px;
  display: block;
  float: left;
  margin: 8px 18px;
  width: 50px;
  height: 51px;
  line-height: 65px;
}
.confirmation-body p {
  margin: 0;
  width: 242px;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 40px 0;
}
.notification_preferences {
  position: absolute;
  left: 23.404255319148934%;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding: 0px;
  margin: 0px !important;
  width: auto !important;
  overflow: auto;
  padding-top: 45px;
  padding-left: 0px;
}
.notification_preferences .import_panel.without-padding {
  padding: 0px;
}
.notification_preferences .title {
  background: #ffffff;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 46px;
  display: inline-block;
  border-bottom: solid 1px #dadada !important;
  padding: 12px 20px !important;
  box-sizing: border-box;
}
.notification_preferences .help-section {
  background: #ffffff;
  padding: 20px 20px 10px;
  border-bottom: solid 1px #dadada;
  margin-bottom: 20px;
}
.top_bar {
  height: 100%;
}
.top_bar .search_box input {
  z-index: 1;
}
nav.main {
  float: left;
  height: 100%;
}
nav.main ul {
  margin: 0;
  float: left;
}
nav.main li {
  height: 100%;
}
.help_button {
  overflow: hidden;
  padding: 0 10px 0 0;
  border-right: 1px solid #446985;
}
.help_button .btn-help {
  margin: 7px 6px;
  float: right;
}
.help_sidebar .support_channels {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 10px 20px;
  min-height: 690px;
}
.help_sidebar .support_channels li {
  margin-bottom: 30px;
}
.help_sidebar .support_channels li a {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.help_sidebar .support_channels li a:hover {
  text-decoration: none;
  color: #3276ad;
}
.help_sidebar .support_channels li a:hover span {
  color: #3276ad;
}
.help_sidebar .support_channels li a:hover span.ss-right {
  opacity: 1;
}
.help_sidebar .support_channels li a span {
  float: left;
  font-size: 24px;
  color: #888888;
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 48px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.help_sidebar .support_channels li a span.ss-right {
  font-size: 12px;
  float: none;
  margin-left: 10px;
  display: inline;
  opacity: 0;
}
.help_sidebar .support_channels li p {
  color: #333333;
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
}
.help-sidepanel .block {
  margin: 20px 20px 0px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  align-items: center;
  padding: 10px 30px;
}
.help-sidepanel .block:first-of-type {
  margin-top: 20px;
}
.help-sidepanel .block.app-filter .search_box {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0px;
  position: relative;
}
.help-sidepanel .block.app-filter .search_box input {
  position: absolute;
  top: 0px;
  left: 0px;
  padding-left: 35px;
  padding-top: 1px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 14px;
}
.help-sidepanel .block.app-filter .btn {
  margin-left: 10px;
}
.help-sidepanel .block form {
  margin-bottom: 0px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.help-sidepanel .block .icon {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  align-items: center;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 29px;
  margin-right: 25px;
  margin-top: 6px;
}
.help-sidepanel .block .block-content {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.help-sidepanel .block .block-content p {
  margin-bottom: 20px;
}
#new_message textarea {
  width: 380px;
}
.chat_dock_wrapper {
  bottom: 0;
  right: 0;
  left: 30px;
  height: 30px;
  position: fixed;
  z-index: 995;
}
.chat_dock_wrapper.compensate-for-email-widget {
  right: 740px;
}
.chat_window {
  background: #ffffff;
  top: -270px;
  height: 300px;
  position: relative;
  margin-right: 10px;
  right: auto;
  float: right;
  width: 280px;
  z-index: 1001;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
}
.chat_window.closed {
  top: 0;
}
.chat_window.closed a.ss-delete {
  display: none;
}
.chat_window.closed:hover a.ss-delete {
  display: inline;
  color: #fff;
}
.chat_window header {
  background: #6393b5;
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 10px;
}
.chat_window header h5 {
  letter-spacing: 1px;
  margin: 0;
}
.chat_window header h5 a {
  color: #F0F3F8;
}
.chat_window header h5 a:hover {
  text-decoration: none;
}
.chat_window header a.ss-delete,
.chat_window header a.ss-hyphen {
  color: #fff;
  float: right;
  font-size: 10px;
  margin: 2px 0 -2px 0;
  margin-left: 10px;
}
.chat_window header a.ss-delete:hover,
.chat_window header a.ss-hyphen:hover {
  text-decoration: none;
}
.chat_window .chat-name {
  float: left;
  width: 207px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat_window form.comment {
  margin: 0;
  width: 100%;
}
.chat_window form.comment input[type="text"] {
  color: #333333;
  font-size: 12px;
  margin: 0;
  border-radius: 0;
  border: none;
  border-top: 1px solid #cccccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  background: #f8f5f5;
}
.chat_window form.comment input[type="text"]:focus {
  border-color: none;
  -webkit-box-shadow: inset 0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 0 8px rgba(82, 168, 236, 0.6);
}
.chat_window section.chat_messages {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  left: 0px;
  right: 0px;
  padding: 5px 8px;
}
.add-new-person {
  float: left;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  background: #ffebb0;
  border-bottom: 1px solid #ecbd2e;
  font-size: 12px;
  padding: 5px 9px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.add-new-person:hover {
  color: #000000;
}
.add-new-person-form {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 8px;
  margin: 0;
  border-bottom: 1px solid #cccccc;
  float: left;
  width: 100%;
}
.add-new-person-form .select2-container {
  margin-left: 0px;
  width: 210px !important;
}
.add-new-person-form .select2-container .select2-choices {
  padding: 2px 0px 0px;
}
.add-new-person-form .select2-container .select2-choices .select2-search-choice {
  margin-bottom: 2px !important;
}
.add-new-person-form .select2-container .select2-choices .select2-search-field input {
  margin-bottom: 2px;
}
.add-new-person-form input[type="text"] {
  margin: 0;
  float: left;
  width: 214px;
  height: 24px !important;
}
.add-new-person-form .btn {
  float: right;
  padding-top: 5px !important;
}
.people-in-chat {
  display: none;
  position: absolute;
  bottom: 300px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 7px 0;
}
.people-in-chat ul {
  padding: 6px 10px;
  margin: 0;
  list-style: none;
  width: 120px;
  float: left;
}
.people-in-chat li {
  color: rgba(255, 255, 255, 0.95);
  font-size: 12px;
  float: left;
  width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.people-in-chat:before {
  content: ' ';
  display: block;
  position: absolute;
  bottom: -7px;
  left: 10px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #cad5e0;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 7px solid rgba(0, 0, 0, 0.8);
}
.chat_box_count_wrapper {
  padding: 2px 0;
  position: relative;
  float: right;
  margin: 0 10px 0 0;
}
.chats_out_of_view {
  visibility: hidden;
  background: #ffffff;
  border: 1px solid #d3d8db;
  width: 145px;
  position: absolute;
  z-index: 9999;
  bottom: 32px;
  margin-left: -62px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.chats_out_of_view ul {
  margin: 0;
}
.chats_out_of_view li {
  list-style: none;
  float: left;
  width: 100%;
}
.chats_out_of_view li:hover {
  background: #eceef4;
}
.chats_out_of_view li:hover .icon {
  color: #0088d5;
}
.chats_out_of_view li:hover .icon:hover {
  color: #0077c1;
}
.chats_out_of_view a {
  float: left;
  padding: 5px 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
}
.chats_out_of_view .icon {
  color: #ffffff;
  float: right;
  padding: 5px 6px 0 0;
  font-size: 10px;
}
.chats_out_of_view.open {
  visibility: visible;
}
.chats_out_of_view.on_right {
  margin-left: 0;
}
.chat_box_count {
  position: relative;
  z-index: 9999;
  background: #6393b5;
  border-bottom: none;
  float: left;
  height: 30px;
  margin: -2px 0 0 0;
}
.chat_box_count .icon {
  float: left;
  margin: 6px 4px 0 8px;
  font-size: 10px;
  color: #b3c6d3;
}
.chat_box_count .icon.ss-down {
  display: none;
}
.chat_box_count .count {
  float: right;
  margin: 6px 8px 0 0;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 100;
}
.chat_box_count.active .ss-up {
  display: none;
}
.chat_box_count.active .ss-down {
  display: block;
}
.chat_box_count.fixed {
  position: fixed;
  left: 20px;
  bottom: 0;
}
.select2-results .online,
.select2-results .offline {
  display: inline-block;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 7px 5px 7px 2px;
  float: left;
}
.select2-results .online {
  background: #60a369;
}
.select2-results .offline {
  background: #d25656;
}
#new_message .form-horizontal .control-label {
  width: 80px;
}
#new_message .form-horizontal .controls {
  margin-left: 100px;
}
.customers .well,
.suppliers .well {
  background: #fff;
}
.customers .well a,
.suppliers .well a {
  margin-bottom: 10px;
}
.customers .well p:last-child,
.suppliers .well p:last-child {
  margin-bottom: 0;
}
.customer-details hr {
  margin: 0 0 10px 0;
}
.customer-top-section-row {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.customer-top-section-row .full-width-customer-section {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.customer-top-section-row .customer-top-section-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.customer-top-section-row .customer-top-section-wrapper .span4 {
  flex: 31.9;
}
.customer-top-section-row .customer-top-section-wrapper .span8 {
  flex: 65.8;
}
.pricing-grand-totals .total-label.with-help {
  position: relative;
}
.pricing-grand-totals .total-label.with-help .ss-help {
  position: absolute;
  top: 13px;
  right: 10px;
  font-size: 15px;
  color: #999999;
}
.new-supplier-well {
  height: 94px;
}
.widget-action-btn {
  float: right;
  margin: 7px 0 0 0;
}
.graph-wrapper {
  height: 240px;
}
.section-with-graph .year-controls {
  position: relative;
  z-index: 2;
}
.dashboard-section .small-padding {
  clear: both;
  padding-top: 10px;
}
.dashboard-section .widget .quick-links {
  right: 58px !important;
  width: 170px !important;
}
.dashboard-section .widget .quick-links:before,
.dashboard-section .widget .quick-links:after {
  left: 142px;
}
.dashboard-section .widget .quick-links .link-column {
  width: 100%;
  padding-bottom: 10px;
}
.dashboard-section .widget .quick-links a {
  float: none;
  margin: 0px;
}
.dashboard-section .recent-supplier-graph .graph {
  height: 250px;
}
.play-icon .icon {
  float: left;
  font-size: 11px;
  color: #797979;
  padding: 5px 3px 1px 9px;
  border-radius: 20px;
  border: 3px solid #797979;
}
.play-icon .text {
  float: left;
  margin: 7px 0 0 10px;
}
.notes-scrollable {
  position: absolute;
  top: 41px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 10px 0;
  overflow: auto;
}
.notes-scrollable.in-jobs {
  top: 0px;
}
.widget {
  background: #eeeeee;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
}
.widget.highlight {
  -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
  box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
}
.widget .title {
  padding: 0 15px;
}
.widget .title input {
  margin-top: -6px !important;
}
.widget .title h2 {
  color: #5e6d7f;
  display: inline;
  font-size: 20px;
  margin: 0;
  padding: 0;
  padding-left: 5px;
}
.widget .title .view-all {
  float: right;
  margin-top: 10px;
}
.widget .title .icon {
  float: right;
  margin: 13px 0 0 0;
  font-size: 16px;
  color: #797979;
}
.widget .content-wrapper.half-size {
  width: 50%;
  float: left;
  height: 100%;
  overflow: auto;
}
.widget .content-wrapper.half-size .content {
  height: 170px;
  overflow: auto;
  box-sizing: border-box;
}
.widget .content {
  background: #ffffff;
  border-radius: 4px;
  margin: 0 15px 15px;
  border: 1px solid #dcdada;
}
.widget .content.centered {
  text-align: center;
}
.widget .content.job-parts {
  padding: 20px 0px !important;
}
.widget .content .diary-event-engineer {
  margin-top: 12px;
  margin-bottom: 20px;
}
.widget .content .diary-event-engineer img {
  margin: -1px 10px 0 20px;
  float: left;
}
.widget .content .diary-event-engineer .ss-user {
  font-size: 30px;
  display: inline-block;
  float: left;
  margin: 15px 10px 0 20px;
  height: 30px;
}
.widget .content .diary-event-engineer span,
.widget .content .diary-event-engineer strong {
  display: inline-block;
  margin: 1px 0;
}
@media screen and (max-width: 1670px) {
  .widget .content .diary-event-engineer img {
    display: none;
  }
  .widget .content .diary-event-engineer span,
  .widget .content .diary-event-engineer strong {
    margin: 1px 20px 0px;
    font-size: 13px;
  }
}
.widget .content .diary-event-notes {
  margin: 10px 20px;
}
.widget .content .diary-event-notes .ss-quote {
  font-size: 9px;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  top: -4px;
  margin: 0px 2px;
}
.widget .content .diary-event-notes .closing-quote {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  position: relative;
  display: inline-block;
  top: -10px;
  left: 2px;
}
.widget .widget-actions {
  margin: 0 0 15px 0;
}
.widget .widget-actions .btn {
  margin: 0 3px;
}
.widget .widget-actions .btn:first-of-type {
  margin-left: 20px;
}
.widget .widget-actions hr {
  border-top-color: #dadada;
}
.widget .widget-actions hr.top {
  margin: 0 0 10px 0;
}
.widget .widget-actions hr.bottom {
  margin: 10px 0 0 0;
  float: none;
}
.widget.properties {
  margin-bottom: 8px;
  position: relative;
}
.widget.properties.gridster-item {
  position: absolute;
  cursor: move;
}
.widget.properties .title .btn {
  float: right;
  margin: 5px 0;
}
.widget.properties .field-to-show {
  display: none;
}
@media screen and (max-width: 1670px) {
  .widget.properties .field-to-hide {
    display: none;
  }
  .widget.properties .field-to-show {
    display: block;
  }
}
.widget.properties .content {
  min-height: auto;
  padding: 30px 0 12px 0;
  cursor: default;
}
.widget.properties .content.smaller-padding {
  padding-top: 10px;
}
.widget.properties .content.no-top-padding {
  padding-top: 0;
}
.widget.properties .content.without-icons {
  padding: 12px 0;
}
.widget.properties .content.without-icons strong {
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
}
.widget.properties .content .field .field-label {
  width: 70px;
}
.widget.properties .content .field span {
  max-width: 240px;
}
.widget.properties .content .section-title h3 {
  font-size: 16px;
}
.widget.properties .content .row-fluid {
  padding: 0 10px;
}
.widget.properties .content .icon:not(.fix-left),
.widget.properties .content .stat-text:not(.fix-left) {
  color: #42585f;
  font-size: 28px;
  float: left;
  width: 35px;
}
.widget.properties .content .icon:not(.fix-left).pound,
.widget.properties .content .stat-text:not(.fix-left).pound {
  margin: 8px 0 0 0;
}
.widget.properties .content .year-controls .icon {
  color: #3883c1;
  font-size: 11px;
  float: none;
}
.widget.properties .content .stats-block {
  margin: -18px 0 0 0;
  display: inline-block;
}
.widget.properties .content .stats-block .stat-count {
  color: #adadad;
  font-size: 12px;
}
.widget.properties .content .stats-block .stat-number {
  font-size: 26px;
  display: block;
  margin: 6px 0 0 0;
}
.widget.properties .content .stats-block .stat-number.warning {
  color: rgba(240, 61, 37, 0.9);
}
@media screen and (max-width: 1655px) {
  .widget.properties .content .stats-block .stat-number {
    font-size: 22px;
  }
}
.widget.properties .content .stats-block span.stat-number {
  color: #42585f;
}
.widget.properties .content .stats-block .large-number {
  display: block;
  margin: 45px 0px;
  font-size: 70px;
  color: #64767c;
}
.widget.properties .content .stats-block .large-number.warning,
.widget.properties .content .stats-block .large-number.bad {
  color: rgba(240, 61, 37, 0.9);
}
.widget.properties .content .stats-block .large-number.good {
  color: #7eb419;
}
.widget.properties .content .stats-block .large-number.in-progress {
  color: #6393b5;
}
.widget.properties .content .stats-block.without-icon {
  display: block;
  padding: 12px 0 31px;
}
.widget.properties .content .stats-block.without-icon .stat-number {
  float: left;
  margin: 0 0 0 10px;
}
.widget.properties .content .stats-block.with-small-margins .large-number {
  margin-top: 35px;
  margin-bottom: 35px;
}
.widget.properties .content .stats-block.suppliers a {
  cursor: default;
}
.no-results-wrap {
  margin-bottom: 10px;
  position: relative;
}
.no-results-wrap.text-center {
  text-align: center;
}
.no-results-wrap .warning-icon-wrap {
  margin: 12px auto 14px auto;
}
.no-results-wrap .warning-icon-wrap .icon {
  line-height: 65px !important;
}
.no-results-wrap .with-warning-icon {
  border-color: #ecbd2e;
}
.no-results-wrap .with-check-icon {
  border-color: #7eb419;
}
.no-results-wrap .warning-message {
  text-align: center;
  color: #7e8d93;
}
.no-results-wrap .helper-arrow {
  position: absolute;
  top: 10px;
  right: 0;
  margin-right: 105px;
  width: 125px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transform: rotate(151deg);
  -moz-transform: rotate(151deg);
  -ms-transform: rotate(151deg);
  -o-transform: rotate(151deg);
  transform: rotate(151deg);
}
.no-results-wrap .helper-arrow.left {
  left: 80px;
  right: auto;
  -ms-transform: rotateX(-180deg) rotateZ(-27deg);
  -webkit-transform: rotateX(-180deg) rotateZ(-27deg);
  transform: rotateX(-180deg) rotateZ(-27deg);
}
.no-results-wrap .helper-text {
  position: absolute;
  top: 28px;
  right: 240px;
  color: #60a369;
}
.no-results-wrap .helper-text.left {
  left: 210px;
  right: auto;
}
.no-results-wrap .icon.ss-alert {
  color: #ecbd2e;
  position: relative;
  top: -2px;
}
.no-results-wrap .icon.ss-check {
  color: #7eb419;
  position: relative;
}
.inside-timeline-section {
  position: absolute;
  top: 41px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.inside-timeline-section .no-results-wrap {
  position: static;
}
.inside-timeline-section .no-results-wrap .warning-icon-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -72px;
  margin-left: -42px;
}
.inside-timeline-section .no-results-wrap .warning-message {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: 30px;
}
.inside-timeline-section .no-results-wrap .helper-arrow {
  top: 40px;
  right: 140px;
}
.inside-timeline-section .no-results-wrap .helper-text {
  top: 59px;
  right: 380px;
}
.job-diary-events .helper-arrow {
  margin-right: 100px;
}
.job-diary-events .helper-text {
  right: 230px;
}
.no-results.with-large-top-margin {
  margin-top: 100px;
}
.inline-no-results-wrap {
  display: inline-block;
}
.inline-no-results-wrap .warning-icon-wrap {
  display: inline-block;
  border: 0px;
  float: left;
  margin: 0px;
  height: auto;
  width: auto;
}
.inline-no-results-wrap .warning-icon-wrap .icon {
  margin: 0px !important;
  font-size: 21px;
  height: auto;
  width: auto;
  line-height: 21px;
}
margin- .inline-no-results-wrap .warning-icon-wrap .icon.fix-left {
  margin: 0px 0px 0px 10px !important;
}
.inline-no-results-wrap .warning-message {
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
}
.commusoft-react-component .warning-icon-wrap {
  box-sizing: content-box !important;
}
.commusoft-react-component .warning-icon-wrap.with-info-icon .icon.fix-left {
  margin: 7px 0 0 16px !important;
}
.commusoft-graph {
  height: 400px;
  width: 100%;
}
.commusoft-graph.small {
  height: 300px;
}
.quick_links {
  display: none;
  background: #ffffff;
  border: 1px solid #c9ced2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  min-width: 450px;
  top: 40px;
  z-index: 1;
  -webkit-box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 9px 2px rgba(0, 0, 0, 0.25);
}
.quick_links.open {
  display: block;
}
.quick_links:before {
  content: ' ';
  border-color: transparent transparent #c9ced2;
  border-style: solid;
  border-width: 9px;
  position: absolute;
  top: -18px;
  right: 17px;
}
.quick_links:after {
  content: ' ';
  border-color: transparent transparent white;
  border-style: solid;
  border-width: 7px;
  position: absolute;
  top: -14px;
  right: 19px;
}
.quick_links .row-fluid {
  padding: 0 20px 20px;
}
.quick_links h5 {
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 1px solid #c9ced2;
}
.quick_links ul {
  list-style: none;
  margin: 0;
}
ul.quick-links.dropdown-menu {
  border-color: #a0acb5;
}
ul.quick-links.dropdown-menu::before {
  border-bottom-color: #929fa9;
}
.customer_list_wrapper td {
  width: 16%;
}
.commusoft_scroll_wrapper {
  padding-bottom: 40px;
  position: absolute;
  overflow: auto;
  left: 0;
  right: 0;
  top: 73px;
  bottom: 0;
}
.has_total_summary {
    padding-bottom: 130px;
    position: absolute;
    overflow: auto;
    left: 0;
    right: 0;
    top: 115px;
    bottom: 0;
}

.has_total_summary .commusoft_scroll_wrapper.under_sub_nav,
.has_total_summary .commusoft_scroll_wrapper {
    top: 0px;
}

.commusoft_scroll_wrapper.under_sub_nav {
  top: 115px;
}
.commusoft_scroll_wrapper.without_sub_nav {
  top: 73px;
}
.commusoft_scroll_wrapper.under_breadcrumbs {
  top: 40px;
}
.commusoft_scroll_wrapper.under_sub_nav_scroll_bar {
  top: 155px;
}
.commusoft_scroll_wrapper.under_sub_nav_scroll_bar .timeline .line {
  top: 260px;
}
.commusoft_scroll_wrapper.under_static_bar {
  top: 153px;
}
.subnav-scroll-bar {
  height: 0px;
  overflow: hidden;
  background: #f7faff;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: relative;
  z-index: 2;
}
.subnav-scroll-bar .key-page-content {
  margin: 10px 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.subnav-scroll-bar .item-group {
  font-size: 12px;
  margin: 0 20px 0 0;
  color: rgba(94, 109, 133, 0.8);
  display: inline-block;
}
.subnav-scroll-bar .item-group span:first-of-type {
  color: #5e6d85;
}
.subnav-scroll-bar .item-group span.warning {
  color: rgba(240, 61, 37, 0.95);
}
.subnav-scroll-bar.active,
.subnav-scroll-bar.static {
  height: 40px;
  border-bottom: 1px solid #d1d9dd;
  -webkit-box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
}
.subnav-scroll-bar.static {
  z-index: 1;
}
.subnav-scroll-bar.just-shadow {
  float: left;
  border: 0;
  height: 10px;
  background: rgba(0, 0, 0, 0);
  margin-top: -10px;
  z-index: 1;
}
.subnav-scroll-bar.just-shadow.active {
  -webkit-box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.2);
}
form fieldset .title.delete a {
  float: right;
  margin: 0px -11px 0 0;
  font-size: 14px;
}
form fieldset .title.add_burner a {
  float: right;
  margin: -3px -11px 0 0;
}
.add-link_topspace {
  margin: 4px 0 0 0;
  float: left;
}
.add-link_topspace.with-left-margin {
  margin-left: 20px;
}
.add-link_topspace.with-right-margin {
  margin-right: 10px;
}
table .nozzle_size {
  width: 20%;
}
.data-section.nozzles-section {
  width: 100%;
  float: left;
  margin: 0px;
  border: none;
  padding: 0 10px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background: none;
}
.nozzles_title {
  margin-left: 10px !important;
}
.make-phone-call-main .page-panel-actions {
  padding-right: 70px;
  right: 0px;
}
.make-phone-call-main .help_section {
  margin: 0;
  padding: 20px 90px 10px 20px;
}
.make-phone-call-main .service_reminder_details {
  padding-top: 20px;
}
.make-phone-call-main .phone_form_view {
  padding: 15px 20px 0px 20px;
  width: 87.5%;
}
.make-phone-call-main .phone_form_view .actions {
  float: right;
}
.make-phone-call-main textarea {
  width: 98%;
  height: 180px;
  margin: 0px;
}
.make-phone-call-main select {
  width: 100%;
}
.make-phone-call-main .page-datepicker .custom-datepicker {
  top: 54px;
  width: 251px;
  left: 0px;
}
.search_box.search-invoice-address {
  margin-bottom: -5px;
  width: 500px;
}
.search_box.search-invoice-address input {
  width: 456px;
}
.search-invoice-address-norecord td {
  background: #ffffff;
}
.convert_customer_model .control-error-message {
  margin-top: -36px;
}
.convert_customer_model .section_separator {
  margin: 10px 0;
}
.convert_customer_model .required_field:after {
  content: ' *';
  color: #d53847;
  font-size: 16px;
}
.convert_customer_model select {
  width: 100%;
  margin-bottom: 0px;
}
.convert_customer_model input[type="text"] {
  margin-bottom: 0px;
}
.convert_drap {
  margin-top: -1px;
}
.view_customers_work_addresses th {
  width: auto;
}
.view_customers_work_addresses td {
  width: auto !important;
}
.customers_notification_preferences .search_table {
  height: 27px;
}
.customers_notification_preferences .filter_links {
  float: left;
  background: #eeeeee;
  padding: 5px 3px 5px 20px;
  border: 1px solid #c9c9c9;
  border-left: none;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
  height: 27px;
}
.customers_notification_preferences .filter_links .nav-tabs {
  border-bottom: none;
}
.customers_notification_preferences .filter_links .quick_filter_title {
  color: #999999;
  margin: 4px 6px 0 0;
}
.certificate-wrapper-main {
  width: 100%;
  background: #0000FF;
}
.certificate-wrapper {
  padding: 15px 5%;
  margin: 0 auto;
  color: #333333;
  background: #FFFFFF;
  font-size: 14px;
  font-family: arial;
}
.certificate-wrapper .row-fluid {
  padding: 10px 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.certificates_header {
  margin-bottom: 10px;
}
.certificates_header .brand-title {
  width: 64%;
  float: left;
}
.certificates_header .brand-title-large {
  width: 81%;
}
.certificates_header .brand-title-extra-large {
  width: 90%;
}
.certificates_header .brand-title-portable-appliance {
  width: 74%;
}
.certificates_header .brand-logo {
  width: 24%;
  float: left;
  margin-left: 2%;
  text-align: right;
}
.certificates_header .brand-logo-icon {
  width: 8%;
}
.certificates_header .right-gas-safety-logo {
  width: 8%;
  float: left;
  margin-left: 2%;
  text-align: right;
}
.certificates_header .right-gas-safety-logo-small {
  width: 7%;
}
.certificates_header .title {
  font-size: 28px;
  line-height: 29px;
  margin-bottom: 10px;
}
.certificates_header p {
  margin: 0px;
  font-size: 12px;
  line-height: 14px;
}
.data-row-section {
  margin: 0;
  padding: 0;
}
.data-row-section .field {
  line-height: 16px;
  min-height: 16px;
}
.data-row-section .field .field-label {
  float: left;
  width: 155px;
  background: none;
  font-weight: normal;
}
.data-row-section .field .field-label-id {
  width: auto;
  font-weight: bold;
  margin-right: 4px;
}
.data-row-section .field span {
  color: #333333;
  margin-left: 0px;
  max-width: 290px;
  margin-bottom: 0px;
  min-height: 15px;
}
.none-border-left-table {
  border-left: none !important;
}
.certificates-table {
  border: 1px solid #afafaf;
  border-top: none;
  border-bottom: none;
  width: 100%;
  border-collapse: 0px;
}
.certificates-table thead {
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-bottom: none;
}
.certificates-table thead .no-th-right-border {
  border-right: none;
}
.certificates-table thead th {
  font-weight: bold;
  font-size: 13px;
  font-family: arial;
  line-height: 28px;
  letter-spacing: 0px;
  margin: 0;
  padding: 4px 6px;
  text-align: left;
  border-right: 1px solid #888888;
  border-bottom: 1px solid #afafaf;
}
.certificates-table thead th:first-child {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.certificates-table thead th:last-child {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-right: none;
}
.certificates-table thead th.thead-title-two-row {
  line-height: 14px;
}
.certificates-table thead th.thead-bg-color {
  background: #00b6cb;
}
.certificates-table thead th.thead-title-color {
  color: #ffffff;
}
.certificates-table thead th.thead-appliances-color {
  color: #333333;
  font-weight: normal;
  letter-spacing: 0px;
  background: none;
}
.certificates-table tbody tr {
  border-bottom: none;
}
.certificates-table tbody tr:nth-child(odd) {
  background: none;
}
.certificates-table tbody tr:hover {
  background: none;
}
.certificates-table tbody .tr-no-style {
  border-top: none !important;
  background: none !important;
}
.certificates-table tbody .tr-no-style:hover {
  background: none !important;
}
.certificates-table tbody .no-td-right-border {
  border-right: none;
}
.certificates-table tbody .td-sub-title {
  background: #ededed;
  padding: 6px;
}
.certificates-table tbody .td-sub-title-portable-appliance {
  background: #ededed;
  padding: 6px;
  vertical-align: bottom;
}
.certificates-table tbody .field-text-align-right {
  text-align: right;
  color: #919191;
}
.certificates-table tbody .none-td-border {
  border-top: none;
  border-right: none;
}
.certificates-table tbody td {
  padding: 4px 6px;
  border-bottom: 1px solid #afafaf;
  border-right: 1px solid #afafaf;
  font-size: 13px;
  color: #333333;
  font-family: arial;
  vertical-align: top;
  word-break: break-all;
}
.certificates-table tbody td:last-child {
  border-right: none;
}
.certificates-table tbody td p strong {
  color: #333333;
}
.certificates-table tbody td strong {
  color: #333333;
}
.certificates-table tbody td span {
  color: #919191;
}
.certificates-table tbody td span small {
  float: right;
  font-size: 9px;
  margin-top: -7px;
}
.certificates-table tbody td .fields-type {
  float: right;
}
.certificates-table tbody td p {
  margin: 0 0 10px 0;
}
.certificates-table tbody td p:last-child {
  margin: 0;
}
.certificates-table .no-column {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}
.certificates-table_tr-no-style .thead-border-bottom {
  border-top: 1px solid #afafaf;
}
.certificates-table_tr-no-style tbody tr td {
  padding: 0.5px 6px;
  border-top: none;
  border-bottom: none;
}
.data-row-section .need-border-top {
  border-top: 1px solid #afafaf;
}
.data-row-section .need-border-bottom {
  border-bottom: 1px solid #afafaf;
}
.data-row-section .need-border-bottom-thead-th {
  border-bottom: 1px solid #afafaf;
}
.data-row-section .none-border-left {
  border-left: none;
}
.data-row-section .none-border-right {
  border-right: none;
}
.certificates-table .call-type-checkbox {
  margin: 0px 0 0 2px;
}
.certificates-table .call-type-checkbox_2 {
  margin: 0px 0 0 2px;
  float: left;
}
.align-float-right {
  float: right !important;
}
.margin-top-up {
  margin-top: -1px;
  float: left;
}
.margin-left-up {
  margin-left: -1px;
  float: left;
}
.required-field-pdf {
  color: #d53847;
}
.header-newbrand-logo-table tbody tr td {
  text-align: center;
  vertical-align: middle;
  height: 150px;
}
.header-newbrand-full-image-table tbody tr td {
  text-align: center;
  vertical-align: middle;
  height: 150px;
}
.header-newbrand-full-image-table tbody tr td img {
  width: 100%;
  height: 100%;
}
.certificates_header_wrap {
  width: 100%;
  margin-top: -1px;
}
.certificates_header_wrap .logo-phone-main {
  padding: 0px;
  height: 155px;
}
.certificates_header_wrap .logo-phone-full-image-main {
  width: 100%;
  height: 350px;
  padding: 10px 0;
}
.certificates_header_wrap .logo-phone-full-image-main img {
  width: 100%;
  height: 350px;
}
.certificates_header_wrap .header-newbrand-logo {
  width: 350px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
}
.certificates_header_wrap .header-newbrand-logo img {
  max-width: 350px;
  max-height: 150px;
}
.letterhead-header-row .letterhead-logo {
  width: 50%;
  float: left;
}
.letterhead-header-row .letterhead-phone-numbers {
  width: 18%;
  float: right;
  margin-top: 106px;
}
.letterhead-header-row .letterhead-phone-numbers span {
  float: right;
  text-align: left;
  font-weight: bold;
  color: #333333;
  font-size: 18px;
}
.letterhead-middle-row {
  padding: 369px 0;
  text-align: center;
}
.letterhead-middle-row .title {
  color: #505b65;
  font-size: 18px;
}
.letterhead-middle-row .help-block {
  color: #60a369;
  margin-top: 10px;
}
.certificates-footer-wrap {
  height: 150px;
}
.letterhead-footer-row {
  width: 100%;
  padding: 12px 0 0 0;
  border-top: 1px solid #8f8f8f;
}
.letterhead-footer-row .address-first {
  width: 15%;
  float: left;
}
.letterhead-footer-row .address-second {
  width: 38%;
  float: left;
}
.letterhead-footer-row .address-second-row .email {
  width: 34%;
}
.letterhead-footer-row .address-second-row .email-field {
  width: 64%;
}
.letterhead-footer-row .footer-logo {
  width: 100px;
  margin-left: 1%;
  float: left;
}
.letterhead-footer-row .label-field {
  font-weight: bold;
}
.letterhead-footer-full-image {
  width: 100%;
  padding: 0px;
}
.letterhead-footer-header-full-image {
  width: 100%;
  height: 150px;
  padding: 0px;
  margin: 0px;
  border: none;
}
.letterhead-footer-full-image-table tbody tr td {
  text-align: center;
  vertical-align: middle;
  height: 150px;
}
.letterhead-footer-full-image-table tbody tr td img {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  border: none;
}
.registered-address .job-address {
  width: 26%;
}
.registered-address .certificate-info {
  width: 22%;
}
.appliances-tested-defects .tested {
  width: 34%;
  float: left;
}
.appliances-tested-defects .defects {
  width: 66%;
  float: left;
}
.appliances-view-first .location {
  width: 100px;
}
.appliances-view-first .appliance-type {
  width: 130px;
}
.appliances-view-first .make-model {
  width: 130px;
}
.appliances-view-first .flue-type {
  width: 40px;
}
.appliances-view-first .landlords-appliance {
  width: 100px;
}
.appliances-view-first .combustion-analyser {
  width: 130px;
}
.appliances-view-first .operating-pressure {
  width: 150px;
}
.appliances-view-first .safety-device {
  width: 120px;
}
.appliances-view-second .ventilation-provision {
  width: 130px;
}
.appliances-view-second .visual-conditions {
  width: 160px;
}
.appliances-view-second .flue-performance {
  width: 120px;
}
.appliances-view-second .appliance-serviced {
  width: 70px;
}
.appliances-view-second .appliance-safe {
  width: 70px;
}
.appliances-view-second .approved {
  width: 80px;
}
.appliances-view-second .is-co-alarm {
  width: 70px;
}
.appliances-view-second .testing-co {
  width: 110px;
}
.appliances-defects .defect-identified {
  width: 42%;
}
.appliances-defects .remedial-action {
  width: 42%;
}
.appliances-defects .warning-advic {
  width: 16%;
}
.appliances-tested-table tbody tr td {
  padding: 2px 6px;
}
.tested-td {
  padding: 3px 6px 4px 6px !important;
}
.engineer-information .received-Info-column {
  width: 31%;
}
.engineer-information .comments-column {
  width: 38%;
}
.data-row-section .engineer-information .field {
  line-height: 15px;
  min-height: 15px;
}
.data-row-section .engineer-information .field span {
  margin-bottom: 0px;
}
.appliances-tested-table-tr-td-no-style thead tr th {
  padding: 4px 6px;
  border-bottom: 1px solid #888888;
}
.appliances-tested-table-tr-td-no-style tbody tr td {
  padding: 4px 6px;
  border-top: none;
  border-right: none;
}
.service-maintenance-address .job-address {
  width: 33.3333%;
}
.certificates-warning_advice_notice-table_tr-no-style .thead-border-bottom {
  border-top: 1px solid #afafaf;
}
.certificates-warning_advice_notice-table_tr-no-style tbody tr td {
  padding: 1px 6px;
  border-top: none;
  border-bottom: none;
}
.warning-advice-appliance-details-row .type {
  width: 25%;
}
.warning-advice-appliance-details-row .type-field {
  width: 20%;
}
.warning-advice-appliance-details-row .model {
  width: 20%;
}
.warning-advice-appliance-details-row .model-field {
  width: 35%;
}
.appliance-checks-row .appliance-checks {
  width: 25%;
}
.appliance-checks-row .appliance-checks-field {
  width: 20%;
}
.appliance-checks-row .comments {
  width: 55%;
}
.findings-row .findings {
  width: 85%;
}
.findings-row .findings-field {
  width: 15%;
}
.service-maintenance-engineer-info-row .enginee-info {
  width: 35%;
}
.service-maintenance-engineer-info-row .certificate-info {
  width: 30%;
}
.appliance-type-app-row .appliance-type-app {
  width: 40%;
}
.appliance-type-app-row .app-field-1 {
  width: 24%;
}
.appliance-type-app-row .app-field-2 {
  width: 12%;
}
.non-domestic-meter-carried .meter {
  width: 60%;
  float: left;
}
.non-domestic-meter-carried .carried {
  width: 40.08%;
  float: left;
}
.meter-installation-row .meter-installation {
  width: 90%;
}
.meter-installation-row .meter-installation-field {
  width: 10%;
}
.commercial-gas-ventilation-answers-1 {
  margin-left: 20px;
}
.commercial-gas-ventilation-answers-2 {
  margin-left: 40px;
}
.installation-row .installation {
  width: 35%;
}
.installation-row .installation-field {
  width: 65%;
}
.commercial-flue-flow-check-row .flue-flow-check {
  width: 18%;
}
.commercial-flue-flow-check-row .appliance-serviced {
  width: 18%;
}
.commercial-flue-flow-check-row .appliance-safe {
  width: 64%;
}
.commercial-operating-pressure-row .operating-pressure {
  width: 20%;
}
.commercial-operating-pressure-row .safety-device {
  width: 18%;
}
.commercial-operating-pressure-row .vetilation-provision {
  width: 18%;
}
.commercial-operating-pressure-row .visual-condition {
  width: 18%;
}
.commercial-operating-pressure-row .flue-termination {
  width: 15%;
}
.commercial-operating-pressure-row .spillage-check {
  width: 9%;
}
.commercial-appliance-type-row .appliance-type {
  width: 20%;
}
.commercial-appliance-type-row .make-model {
  width: 20%;
}
.commercial-appliance-type-row .serial-no {
  width: 20%;
}
.commercial-appliance-type-row .flue-type {
  width: 10%;
}
.commercial-appliance-type-row .appliance-secure {
  width: 15%;
}
.commercial-appliance-type-row .appliance-isolation {
  width: 15%;
}
.commercial-risk-analysis-row .risk-analysis {
  width: 32%;
}
.commercial-risk-analysis-row .risk-analysis-field {
  width: 68%;
}
.commercial-safety-information-row .safety-information {
  width: 34%;
}
.commercial-safety-information-row .safety-information-field {
  width: 66%;
}
.commercial-recording-instruments-row .make-model {
  width: 16%;
}
.commercial-recording-instruments-row .make-model-field {
  width: 34%;
}
.commercial-recording-instruments-row .calibration {
  width: 16%;
}
.commercial-recording-instruments-row .calibration-field {
  width: 34%;
}
.commercial-installation-and-ventilation .gas-installation {
  width: 47%;
  float: left;
}
.commercial-installation-and-ventilation .ventilation-exhaust-system {
  width: 53.08%;
  float: left;
}
.commercial-installation .installation {
  width: 70%;
}
.commercial-installation .installation-field {
  width: 10%;
}
.commercial-ventilation .ventilation {
  width: 70%;
}
.commercial-ventilation .ventilation-field {
  width: 10%;
}
.appliance-and-temperature-control .appliance-main {
  width: 50%;
  float: left;
}
.appliance-and-temperature-control .temperature-control-main {
  width: 49.6%;
  float: right;
}
.appliance-row .appliance {
  width: 50%;
}
.appliance-row .appliance-field {
  width: 50%;
}
.temperature-control-row .temperature-control {
  width: 75%;
}
.temperature-control-row .temperature-control-field {
  width: 25%;
}
.controls-row .controls {
  width: 80%;
}
.controls-row .controls-field {
  width: 20%;
}
.all-systems-row .all-systems {
  width: 80%;
}
.all-systems-row .all-systems-field {
  width: 20%;
}
.measure-record-row .measure-record {
  width: 80%;
}
.measure-record-row .measure-record-field {
  width: 20%;
}
.combination-boilers-row .combination {
  width: 80%;
}
.combination-boilers-row .combination-field {
  width: 20%;
}
.central-heating-mode-row .central-heating {
  width: 80%;
}
.central-heating-mode-row .central-heating-field {
  width: 20%;
}
.condensing-boilers-row .condensing {
  width: 80%;
}
.condensing-boilers-row .condensing-field {
  width: 20%;
}
.manufacturer-record-row .manufacturer {
  width: 80%;
}
.manufacturer-record-row .manufacturer-field {
  width: 20%;
}
.all-installation-row .installation {
  width: 80%;
}
.all-installation-row .installation-field {
  width: 20%;
}
.leisure-industry-appliances-view-first .location {
  width: 100px;
}
.leisure-industry-appliances-view-first .appliance-type {
  width: 130px;
}
.leisure-industry-appliances-view-first .make-model {
  width: 130px;
}
.leisure-industry-appliances-view-first .flue-type {
  width: 45px;
}
.leisure-industry-appliances-view-first .landlords-appliance {
  width: 100px;
}
.leisure-industry-appliances-view-first .co-reading {
  width: 90px;
}
.leisure-industry-appliances-view-first .combustion-analyser {
  width: 140px;
}
.leisure-industry-appliances-view-first .operating-pressure {
  width: 90px;
}
.leisure-industry-appliances-view-first .heat-input {
  width: 90px;
}
.leisure-industry-appliances-view-second .safety-device {
  width: 126px;
}
.leisure-industry-appliances-view-second .ventilation-provision {
  width: 156px;
}
.leisure-industry-appliances-view-second .visual-conditions {
  width: 186px;
}
.leisure-industry-appliances-view-second .flue-performance {
  width: 130px;
}
.leisure-industry-appliances-view-second .appliance-serviced {
  width: 86px;
}
.leisure-industry-appliances-view-second .appliance-safe {
  width: 94px;
}
.leisure-industry-appliances-view-second .approved-co {
  width: 110px;
}
.leisure-industry-appliances-view-second .is-co-alarm {
  width: 100px;
}
.leisure-industry-appliances-view-second .testing-of-co {
  width: 140px;
}
.leisure-industry-appliances-tested-table tbody tr td {
  padding: 2.5px 6px;
}
.appliance-details-row .appliance-details {
  width: 33.3333%;
}
.appliance-details-row .appliance-details-field {
  width: 22.2222%;
}
.combustion-checks-row .combustion-checks {
  width: 40%;
}
.combustion-checks-row .combustion-checks-field {
  width: 10%;
}
.additional-checks-row .additional-checks {
  width: 40%;
}
.additional-checks-row .additional-checks-field {
  width: 20%;
}
.safety-information-row .safety-information {
  width: 80%;
}
.safety-information-row .safety-information-field {
  width: 20%;
}
.general-safety-checks-row .general-safety {
  width: 80%;
}
.general-safety-checks-row .general-safety-field {
  width: 20%;
}
.ventilation-row .ventilation {
  width: 80%;
}
.ventilation-row .ventilation-field {
  width: 20%;
}
.mobile-catering-vehicle-traile-row .vehicle {
  width: 10%;
}
.mobile-catering-vehicle-traile-row .vehicle-field {
  width: 10%;
}
.mobile-catering-vehicle-traile-row .registration {
  width: 16%;
}
.mobile-catering-vehicle-traile-row .registration-field {
  width: 10%;
}
.mobile-catering-vehicle-traile-row .lpg-safety {
  width: 20%;
}
.mobile-catering-vehicle-traile-row .lpg-safety-field {
  width: 10%;
}
.mobile-catering-vehicle-traile-row .fire-blanket {
  width: 14%;
}
.mobile-catering-vehicle-traile-row .fire-blanket-field {
  width: 10%;
}
.mobile-catering-appliances-view-first .location {
  width: 100px;
}
.mobile-catering-appliances-view-first .appliance-type {
  width: 130px;
}
.mobile-catering-appliances-view-first .make-model {
  width: 130px;
}
.mobile-catering-appliances-view-first .flue-type {
  width: 45px;
}
.mobile-catering-appliances-view-first .overall-condition {
  width: 80px;
}
.mobile-catering-appliances-view-first .isolation-valve {
  width: 90px;
}
.mobile-catering-appliances-view-first .age {
  width: 43px;
}
.mobile-catering-appliances-view-first .operating-pressure {
  width: 80px;
}
.mobile-catering-appliances-view-first .heat-input {
  width: 50px;
}
.mobile-catering-appliances-view-first .safety-device {
  width: 100px;
}
.mobile-catering-appliances-view-first .ventilation-provision {
  width: 245px;
}
.mobile-catering-appliances-view-second .visual-conditions {
  width: 186px;
}
.mobile-catering-appliances-view-second .flue-flow {
  width: 100px;
}
.mobile-catering-appliances-view-second .spillage-test {
  width: 100px;
}
.mobile-catering-appliances-view-second .testTermination-satisfactory {
  width: 186px;
}
.mobile-catering-appliances-view-second .appliance-serviced {
  width: 80px;
}
.mobile-catering-appliances-view-second .appliance-secure {
  width: 80px;
}
.mobile-catering-appliances-view-second .current-standards {
  width: 80px;
}
.mobile-catering-appliances-view-second .unsafe-situation {
  width: 80px;
}
.mobile-catering-appliances-view-second .safe-to-use {
  width: 227px;
}
.mobile-catering-appliances-tested-defects .left-defects {
  width: 44%;
  float: left;
}
.mobile-catering-appliances-tested-defects .right-defects {
  width: 55.8%;
  float: right;
}
.mobile-catering-appliances-tested-td tbody tr td {
  padding: 4.4px 6px;
}
.left-defects-option .ratio-1st {
  width: 20%;
}
.left-defects-option .ratio-2st {
  width: 20%;
}
.left-defects-option .co2 {
  width: 12%;
}
.left-defects-option .co-ppm {
  width: 14%;
}
.left-defects-option .flue-gas {
  width: 14%;
}
.left-defects-option .efficiency {
  width: 20%;
}
.right-defects-option .defect-identified {
  width: 49%;
}
.right-defects-option .remedial-action {
  width: 50%;
}
.gas-installation-row .gas-installation {
  width: 25%;
}
.gas-installation-row .gas-installation-field {
  width: 75%;
}
.mobile-catering-appliances-view-third .no_column {
  width: 5%;
}
.mobile-catering-appliances-view-third .location {
  width: 20%;
}
.mobile-catering-appliances-view-third .smoke {
  width: 15%;
}
.mobile-catering-appliances-view-third .working {
  width: 60%;
}
.page-continuous-row {
  margin: 10px 0 0 0;
  font-size: 12px;
  font-family: arial;
}
.page-continuous-row p {
  margin-bottom: -5px;
  color: #333333;
}
.appliance-details-and-test-results-row .appliance-id {
  width: 100px;
}
.appliance-details-and-test-results-row .test-date {
  width: 100px;
}
.appliance-details-and-test-results-row .site {
  width: 80px;
}
.appliance-details-and-test-results-row .description {
  width: 160px;
}
.appliance-details-and-test-results-row .location {
  width: 110px;
}
.appliance-details-and-test-results-row .serial-no {
  width: 110px;
}
.appliance-details-and-test-results-row .retest-period {
  width: 100px;
}
.appliance-details-and-test-results-row .retest-date {
  width: 100px;
}
.appliance-details-and-test-results-row .status {
  width: 50px;
}
.analyser-readings-row .analyser-readings {
  width: 20%;
}
.analyser-readings-row .analyser-readings-field {
  width: 80%;
}
.oil-firing-service-row .make {
  width: 10%;
}
.oil-firing-service-row .make-field {
  width: 20%;
}
.oil-firing-service-row .burner-make {
  width: 10%;
}
.oil-firing-service-row .burner-make-field {
  width: 20%;
}
.oil-firing-service-row .tank-type {
  width: 10%;
}
.oil-firing-service-row .tank-type-field {
  width: 20%;
}
.landlord-oil-appliance-details-row .make {
  width: 14%;
}
.landlord-oil-appliance-details-row .make-field {
  width: 19.3333%;
}
.landlord-oil-appliance-details-row .burner-make {
  width: 13.3333%;
}
.landlord-oil-appliance-details-row .burner-make-field {
  width: 20%;
}
.landlord-oil-appliance-details-row .tank-type {
  width: 11.3333%;
}
.landlord-oil-appliance-details-row .tank-type-field {
  width: 22%;
}
.oil-firing-service-row .schedule-item {
  width: 20%;
}
.oil-firing-service-row .checked {
  width: 10%;
}
.oil-firing-service-row .passed {
  width: 10%;
}
.oil-firing-service-row .parts-fitted {
  width: 60%;
}
.comments-observations-row .observations-field-1 {
  width: 50%;
}
.comments-observations-row .observations-field-2 {
  width: 50%;
}
.landlord-oil-test-results-row .smoke-no {
  width: 12%;
}
.landlord-oil-test-results-row .smoke-no-field {
  width: 14%;
}
.landlord-oil-test-results-row .efficiency-nett {
  width: 12%;
}
.landlord-oil-test-results-row .efficiency-nett-field {
  width: 14%;
}
.landlord-oil-test-results-row .gross {
  width: 10%;
}
.landlord-oil-test-results-row .gross-field {
  width: 14%;
}
.pre-commissionin-row .commissioning-checks {
  width: 79.3%;
}
.pre-commissionin-row .commissioning-checks-field {
  width: 20.7%;
}
.oil-firing-servicing-appliance-details .make {
  width: 14%;
}
.oil-firing-servicing-appliance-details .make-field {
  width: 18%;
}
.oil-firing-servicing-appliance-details .burner-make {
  width: 15%;
}
.oil-firing-servicing-appliance-details .burner-make-field {
  width: 17%;
}
.oil-firing-servicing-appliance-details .tank-type {
  width: 12%;
}
.oil-firing-servicing-appliance-details .tank-type-field {
  width: 20%;
}
.oil-firing-servicing-appliance-details-sno {
  width: 100%;
}
.oil-firing-servicing-appliance-details-sno div {
  color: #505b64;
  font-size: 13px;
  padding: 4px 6px;
  border-right: 1px solid #afafaf;
  border-bottom: 1px solid #afafaf;
}
.oil-firing-servicing-appliance-details-sno .make {
  width: 12%;
  float: left;
}
.oil-firing-servicing-appliance-details-sno .make-field {
  width: 19%;
  float: left;
}
.oil-firing-servicing-appliance-details-sno .burner-make {
  width: 10%;
  float: left;
}
.oil-firing-servicing-appliance-details-sno .burner-make-field {
  width: 20%;
  float: left;
}
.oil-firing-servicing-appliance-details-sno .tank-type {
  width: 11%;
  float: left;
}
.oil-firing-servicing-appliance-details-sno .tank-type-field {
  width: 14%;
  float: left;
}
.oil-firing-servicing-commissioning-schedule-row .schedule-item {
  width: 30%;
}
.oil-firing-servicing-commissioning-schedule-row .checked {
  width: 10%;
}
.oil-firing-servicing-commissioning-schedule-row .passed {
  width: 10%;
}
.oil-firing-servicing-commissioning-schedule-row .parts-fitted {
  width: 50%;
}
.oil-firing-servicing-test-results-row .test-results-field-1 {
  width: 15%;
}
.oil-firing-servicing-test-results-row .test-results-field-2 {
  width: 20%;
}
.oil-firing-servicing-test-results-row .test-results-field-3 {
  width: 14%;
}
.oil-firing-servicing-test-results-row .test-results-field-4 {
  width: 8%;
}
.oil-firing-servicing-test-results-row .test-results-field-5 {
  width: 12%;
}
.oil-firing-servicing-test-results-row .test-results-field-6 {
  width: 10%;
}
.oil-firing-servicing-call-type-row .call-type {
  width: 10%;
}
.oil-firing-servicing-call-type-row .call-type-1 {
  width: 15%;
}
.oil-firing-servicing-call-type-row .call-type-2 {
  width: 17%;
}
.oil-firing-servicing-call-type-row .call-type-3 {
  width: 17%;
}
.oil-firing-servicing-call-type-row .call-type-4 {
  width: 25%;
}
.oil-firing-servicing-call-type-row .call-type-5 {
  width: 16%;
}
.oil-firing-servicing-call-type-row tbody tr td {
  padding: 6px;
  border-right: none;
}
.oil_firing_servicing-yes-question {
  margin-left: 20px;
}
.warning-potential-immediate-row .potential-immediate-field-1 {
  width: 80%;
}
.warning-potential-immediate-row .potential-immediate-field-2 {
  width: 20%;
}
.warning-label_tr-no-style .warning-label-field-1 {
  width: 80%;
}
.warning-label_tr-no-style .warning-label-field-2 {
  width: 20%;
}
.warning-label_tr-no-style tbody tr td {
  padding: 4px 6px;
  border-bottom: none;
}
.details-minor-row .details-minor-field-1 {
  width: 30%;
}
.details-minor-row .details-minor-field-2 {
  width: 70%;
}
.details-minor-row .description-users-answer {
  min-height: 40px;
}
.details-minor-row .departures-users-answer {
  min-height: 25px;
}
.installation-details-row .system-type {
  width: 33%;
}
.installation-details-row .tncs {
  width: 7%;
}
.installation-details-row .tncs-field {
  width: 6%;
}
.installation-details-row .tns {
  width: 5%;
}
.installation-details-row .tns-field {
  width: 6%;
}
.installation-details-row .tt {
  width: 3%;
}
.installation-details-row .tt-field {
  width: 8%;
}
.installation-details-row .tnc {
  width: 5%;
}
.installation-details-row .tnc-field {
  width: 9%;
}
.installation-details-row .it {
  width: 3%;
}
.installation-details-row .it-field {
  width: 13%;
}
.installation-details-row .protective-device {
  width: 33%;
}
.installation-details-row .bsen {
  width: 7%;
}
.installation-details-row .bsen-field {
  width: 22%;
}
.installation-details-row .protectiv-type {
  width: 5%;
}
.installation-details-row .protectiv-type-field {
  width: 12%;
}
.installation-details-row .rating {
  width: 7%;
}
.installation-details-row .rating-field {
  width: 10%;
}
.installation-details-row .wiring-type {
  width: 20%;
}
.installation-details-row .type {
  width: 4%;
}
.installation-details-row .type-field {
  width: 15%;
}
.installation-details-row .reference-method {
  width: 16%;
}
.installation-details-row .reference-method-field {
  width: 4%;
}
.installation-details-row .live-csa {
  width: 8%;
}
.installation-details-row .live-csa-field {
  width: 7%;
}
.installation-details-row .cpc-conductor {
  width: 16%;
}
.installation-details-row .cpc-conductor-field {
  width: 7%;
}
.installation-details-row .coments-users-answer {
  min-height: 50px;
}
.essential-tests-row .necesary-inspections {
  width: 38%;
}
.essential-tests-row .necesary-inspections-field {
  width: 62%;
}
.essential-tests-row .insulation-resistance {
  width: 18%;
}
.essential-tests-row .line-line {
  width: 12%;
}
.essential-tests-row .line-line-field {
  width: 10%;
}
.essential-tests-row .line-neutral {
  width: 10%;
}
.essential-tests-row .line-neutral-field {
  width: 10%;
}
.essential-tests-row .line-earth {
  width: 10%;
}
.essential-tests-row .line-earth-field {
  width: 10%;
}
.essential-tests-row .neutral-eart {
  width: 10%;
}
.essential-tests-row .neutral-eart-field {
  width: 10%;
}
.essential-tests-row .earth-continuit {
  width: 42%;
}
.essential-tests-row .earth-continuit-field-1 {
  width: 12%;
}
.essential-tests-row .earth-continuit-field-2 {
  width: 23%;
}
.essential-tests-row .earth-continuit-field-3 {
  width: 23%;
}
.essential-tests-row .agreed-limitations-users-answer {
  min-height: 50px;
}
.the-installation-row .the-installation {
  width: 18%;
}
.the-installation-row .installation-new {
  width: 6.3333%;
}
.the-installation-row .installation-new-field {
  width: 9%;
}
.the-installation-row .an-addition {
  width: 12.3333%;
}
.the-installation-row .an-addition-field {
  width: 21%;
}
.the-installation-row .al-alteration {
  width: 12.3333%;
}
.the-installation-row .al-alteration-field {
  width: 21%;
}
.next-inspection-row .next-inspection {
  width: 76%;
}
.next-inspection-row .next-inspection-field {
  width: 24%;
}
.test-instruments-row .multi-functional {
  width: 18%;
}
.test-instruments-row .multi-functional-field {
  width: 35%;
}
.test-instruments-row .earth-electrode {
  width: 23%;
}
.test-instruments-row .earth-electrode-field {
  width: 24%;
}
.oil-storage-details-view-first-row .oil-storage {
  width: 38%;
}
.oil-storage-details-view-first-row .metal {
  width: 6%;
}
.oil-storage-details-view-first-row .metal-field {
  width: 13%;
}
.oil-storage-details-view-first-row .plastic {
  width: 7%;
}
.oil-storage-details-view-first-row .plastic-field {
  width: 13%;
}
.oil-storage-details-view-first-row .bunded {
  width: 7%;
}
.oil-storage-details-view-first-row .bunded-field {
  width: 16%;
}
.oil-storage-details-view-second-row .capacity {
  width: 37.8%;
}
.oil-storage-details-view-second-row .capacity-field {
  width: 62.2%;
}
.oil-supply-details-row .oil-supply-line {
  width: 40%;
}
.oil-supply-details-row .oil-supply-line-field {
  width: 11%;
}
.oil-supply-details-row .metal {
  width: 6%;
}
.oil-supply-details-row .metal-field {
  width: 7%;
}
.oil-supply-details-row .plastic {
  width: 7%;
}
.oil-supply-details-row .plastic-field {
  width: 8%;
}
.oil-supply-details-row .presure-tested {
  width: 13%;
}
.oil-supply-details-row .presure-tested-field {
  width: 8%;
}
.oil-firing-installation-appliance-details-row .appliance-type {
  width: 20%;
}
.oil-firing-installation-appliance-details-row .appliance-type-field {
  width: 30%;
}
.oil-firing-installation-appliance-details-row .appliance-type {
  width: 20%;
}
.oil-firing-installation-appliance-details-row .burner-make-field {
  width: 30%;
}
.fueling-arrangements-row .fueling-arrangements {
  width: 51%;
}
.fueling-arrangements-row .fueling-arrangements-field {
  width: 49%;
}
.fueling-arrangements-row .stainless-steel {
  width: 51%;
}
.fueling-arrangements-row .stainless-steel-field {
  width: 10%;
}
.fueling-arrangements-row .dia {
  width: 5%;
}
.fueling-arrangements-row .dia-field {
  width: 34%;
}
.fueling-arrangements-row .arranagements-suitable {
  width: 51%;
}
.fueling-arrangements-row .oil {
  width: 4%;
}
.fueling-arrangements-row .oil-field {
  width: 6%;
}
.fueling-arrangements-row .gas {
  width: 5%;
}
.fueling-arrangements-row .gas-field {
  width: 6%;
}
.fueling-arrangements-row .solid-fue {
  width: 9%;
}
.fueling-arrangements-row .solid-fue-field {
  width: 7%;
}
.fueling-arrangements-row .all {
  width: 4%;
}
.fueling-arrangements-row .all-field {
  width: 8%;
}
.air-supply-details-row .air-supply {
  width: 56%;
}
.air-supply-details-row .air-supply-field {
  width: 44%;
}
.controls-fitted-row .controls-fitted {
  width: 30%;
}
.controls-fitted-row .controls-fitted-field {
  width: 70%;
}
.no-of-zones-row .no-of-zones {
  width: 40%;
}
.no-of-zones-row .no-of-zones-field {
  width: 60%;
}
.system-details-row .system-details {
  width: 60%;
}
.system-details-row .system-details-field {
  width: 40%;
}
.oil-firing-installation-commissioning-row .commissioning {
  width: 28%;
}
.oil-firing-installation-commissioning-row .commissioning-field {
  width: 72%;
}
.text-rotate {
  width: 30px;
  height: auto;
  -ms-transform: rotate(270deg);
  /* IE 9 */

  -webkit-transform: rotate(270deg);
  /* Chrome, Safari, Opera */

  transform: rotate(270deg);
  margin-bottom: 9px;
  word-break: normal;
}
.appliance-details-and-test-results-detailed-row .appliance-id {
  width: 4%;
  height: 150px;
}
.appliance-details-and-test-results-detailed-row .test-date {
  width: 7%;
}
.appliance-details-and-test-results-detailed-row .description {
  width: 14%;
}
.appliance-details-and-test-results-detailed-row .location {
  width: 10%;
}
.appliance-details-and-test-results-detailed-row .rp {
  width: 3%;
}
.appliance-details-and-test-results-detailed-row .retest-date {
  width: 7%;
}
.appliance-details-and-test-results-detailed-row .fuse-size {
  width: 3%;
}
.appliance-details-and-test-results-detailed-row .class {
  width: 3%;
}
.appliance-details-and-test-results-detailed-row .visual {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .polarity {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .earth-continuity {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .earth-continuity {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .insulation {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .leakage {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .touch-leakage {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .sub-leakage {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .flash {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .load {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .clamp {
  width: 4%;
}
.appliance-details-and-test-results-detailed-row .status {
  width: 4%;
}
.no-style-certificates-table {
  border: none;
}
.no-style-certificates-table tbody tr td {
  padding: 1px 0px;
  border-bottom: none;
  border-right: none;
}
.supplier-address-left {
  width: 40%;
  float: left;
}
.supplier-address-right {
  width: 49%;
  float: right;
}
.supplier-address-right .title {
  font-size: 40px;
  margin-bottom: 24px;
  line-height: 30px;
}
.supplier-address-right .label-field {
  font-weight: bold;
}
.supplier-payment-table {
  border: 1px solid #afafaf;
}
.supplier-payment-table tbody tr td {
  padding: 10px 6px;
}
.supplier-payment-table .date {
  width: 45%;
}
.supplier-payment-table .date-field {
  width: 55%;
}
.supplier-payment-description {
  margin: 10px 0px;
  font-size: 13px;
  color: #333333;
}
.top-padding-space {
  margin-top: 14px;
}
.top-small-margin-space {
  margin-top: 5px;
}
.top-margin-space {
  margin-top: 14px;
}
.top-more-margin-space {
  margin-top: 25px;
}
.bottom-margin-space {
  margin-bottom: 14px;
}
.invoice-number-row .invoice-number {
  width: 70.1%;
}
.invoice-number-row .total-field {
  width: 10%;
}
.invoice-number-row .amount-field {
  width: 20%;
}
.supplier-credit-description-row .description {
  width: 46%;
}
.supplier-credit-description-row .unit-price {
  width: 14%;
}
.supplier-credit-description-row .quantity {
  width: 11%;
}
.supplier-credit-description-row .vat-rate {
  width: 12%;
}
.supplier-credit-description-row .total-exc {
  width: 17%;
}
.supplier-credit-description-row .description-bottom {
  width: 60%;
}
.supplier-credit-description-row .vat-rate-bottom {
  width: 23%;
}
.supplier-credit-description-row .total-exc-bottom {
  width: 17%;
}
.supplier-credit-bottom-told-table {
  border: none;
  border-right: 1px solid #afafaf;
}
.supplier-credit-bottom-told-table tbody tr td {
  padding: 4px 6px;
  border-bottom: none;
  border-right: none;
}
.supplier-credit-bottom-told-td {
  border-top: 1px solid #afafaf;
  border-left: 1px solid #afafaf;
}
.page-main-title {
  width: 100%;
}
.page-main-title .title {
  width: 50%;
  float: left;
  font-size: 27px;
  margin-bottom: 22px;
  line-height: 30px;
}
.page-main-title .job-sheet-date-engineer-name {
  width: 50%;
  float: right;
  text-align: right;
  line-height: 35px;
}
.job-sheet-top-address .left-address {
  width: 50%;
  float: left;
}
.job-sheet-top-address .right-account {
  width: 50.07%;
  float: left;
}
.job-sheet-address-row .customer-address {
  width: 50%;
}
.job-sheet-address-row .work-address {
  width: 50%;
}
.job-sheet-diary-tr-td-no-style .job-sheet-diary {
  width: 40%;
}
.job-sheet-diary-tr-td-no-style .job-sheet-diary-field {
  width: 60%;
}
.job-sheet-diary-tr-td-no-style tbody tr td {
  padding: 3.8px 6px;
  border-bottom: none;
  border-right: none;
}
.job-sheet-contacts-row .contacts-name {
  width: 28%;
}
.job-sheet-contacts-row .contacts-field {
  width: 24%;
}
.tr-td-no-style tbody tr td {
  padding: 4px 6px;
  border-top: none;
  border-bottom: none;
  border-right: none;
}
.job-details-row .job-details {
  width: 20%;
}
.job-details-row .job-details-field {
  width: 80%;
}
.notes-engineer-users-answer {
  min-height: 100px;
}
.access-notes-users-answer {
  min-height: 70px;
}
.section-separator-border {
  border-bottom: 1px solid #afafaf !important;
}
.separator-padding-bottom {
  padding-bottom: 5px !important;
}
.separator-padding-top {
  padding-top: 5px !important;
}
.job-sheet-daily-summary-row .daily-summary {
  width: 20%;
}
.job-sheet-daily-summary-row .daily-summary-field {
  width: 80%;
}
.tr-no-style-main {
  border-bottom: 1px solid #afafaf;
}
.tr-no-style-main tbody tr td {
  padding: 4px 6px;
  border-top: none;
  border-bottom: none;
}
.td-no-style-main tbody tr td {
  padding: 4px 6px;
  border-right: none;
  border-bottom: 1px solid #afafaf;
}
.estimate-breakdown-description-row .description {
  width: 71%;
}
.estimate-breakdown-description-row .quantity {
  width: 12%;
}
.estimate-breakdown-description-row .total {
  width: 17%;
}
.estimate-breakdown-description-row .total-exc {
  width: 17%;
}
.page-sub-main-title {
  width: 100%;
}
.page-sub-main-title .title {
  float: left;
  font-size: 25px;
  margin: 10px 0px;
  line-height: 30px;
}
.estimate-breakdown-with-columns-total-table-row .bottom-description {
  width: 71%;
  border-left: 1px solid #FFFFFF;
}
.estimate-breakdown-with-columns-total-table-row .bottom-total {
  width: 12%;
}
.estimate-breakdown-with-columns-total-table-row .bottom-total-field {
  width: 17%;
}
.estimate-breakdown-total-table-row .bottom-description {
  width: 71%;
  border-left: 1px solid #FFFFFF;
}
.estimate-breakdown-total-table-row .bottom-total {
  width: 12%;
}
.estimate-breakdown-total-table-row .bottom-total-field {
  width: 17%;
}
.estimate-breakdown-with-categories-total-table-row .bottom-description {
  width: 71%;
  border-left: 1px solid #FFFFFF;
}
.estimate-breakdown-with-categories-total-table-row .bottom-total {
  width: 12%;
}
.estimate-breakdown-with-categories-total-table-row .bottom-total-field {
  width: 17%;
}
.job-sheet-appliances-installed-row .type {
  width: 20%;
}
.job-sheet-appliances-installed-row .make {
  width: 17%;
}
.job-sheet-appliances-installed-row .model {
  width: 17%;
}
.job-sheet-appliances-installed-row .gc-number {
  width: 20%;
}
.job-sheet-appliances-installed-row .warranty-expires {
  width: 26%;
}
.tr-row-no-style tbody tr td {
  padding: 4px 6px;
  border-top: none;
}
.job-sheet-sub-title {
  height: 38px;
  border-left: 1px solid #888888;
  border-right: 1px solid #888888;
}
.job-sheet-sub-title .title {
  float: left;
  font-size: 13px;
  font-weight: bold;
  margin-left: 6px;
  line-height: 38px;
}
.job-sheet-history-row .date {
  width: 15%;
}
.job-sheet-history-row .description {
  width: 65%;
}
.job-sheet-history-row .invoice-number {
  width: 20%;
}
.job-sheet-parts-required-row .date {
  width: 80%;
}
.job-sheet-parts-required-row .description {
  width: 20%;
}
.job-sheet-signatureg-costings-row .signatureg {
  width: 50%;
  float: left;
}
.job-sheet-signatureg-costings-row .costings {
  width: 50.08%;
  float: left;
}
.signatureg-costings-row .signatureg-img {
  height: 123px;
  vertical-align: middle;
}
.signatureg-costings-row .signatureg-date-time {
  width: 35%;
}
.signatureg-costings-row .signatureg-date-time-field {
  width: 65%;
}
.signatureg-costings-row .costings-total {
  padding: 9px 6px;
}
.signatureg-costings-row .costings-Labour {
  width: 30px;
}
.signatureg-costings-row .costings-Labour-field {
  width: 70px;
}
.invoice-paid-stamp {
  background: url(/images/paid-stamp.jpg) 57% center no-repeat;
}
.invoice-paynow-button {
  background: #746ca6;
  float: right;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  width: 20%;
}
.invoice-paynow-button a {
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
}
.invoice-paynow-button a .button-text {
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}
.invoice-paynow-button a .button {
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}
.invoice-paynow-button a:hover {
  color: #FFFFFF;
}
.invoice-screen-invoice-description-row .work-completed {
  width: 21%;
}
.invoice-screen-invoice-description-row .work-completed-field {
  width: 29%;
}
.invoice-screen-invoice-description-row .job-number {
  width: 20%;
}
.invoice-screen-invoice-description-row .job-number-field {
  width: 30%;
}
.invoice-screen-labour-description-row .description {
  width: 60%;
}
.invoice-screen-labour-description-row .unit-price {
  width: 15%;
}
.invoice-screen-labour-description-row .quantity {
  width: 10%;
}
.invoice-screen-labour-description-row .total-exc {
  width: 15%;
}
.invoice-terms-and-conditions-row .conditions-number-list {
  font-size: 13px;
  width: 100%;
  margin-bottom: 10px;
}
.header-image-row {
  width: 85%;
  height: 150px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #990000;
}
.header-image-row img {
  max-width: 100px;
  max-height: 100px;
}
.invoice-screen-subtotal-table-row .bottom-description {
  width: 75%;
}
.invoice-screen-subtotal-table-row .bottom-subtotal {
  width: 9.9%;
}
.invoice-screen-subtotal-table-row .bottom-subtotal-field {
  width: 15%;
}
.invoice-screen-with-breakdown-row .description {
  width: 72%;
}
.invoice-screen-with-breakdown-row .quantity {
  width: 13%;
}
.invoice-screen-with-breakdown-row .total {
  width: 15%;
}
.invoice-screen-with-breakdown-row .bottom-description {
  width: 72%;
  border-left: 1px solid #FFFFFF;
}
.invoice-screen-with-breakdown-row .bottom-total {
  width: 13%;
}
.invoice-screen-with-breakdown-row .bottom-total-field {
  width: 15%;
}
.job-sheet-summary-parts-follow-row .parts-requierd {
  width: 50%;
}
.job-sheet-summary-parts-follow-row .follow-requierd {
  width: 50%;
}
.invoice-without-breakdown-description-row .job-number {
  width: 30%;
}
.invoice-without-breakdown-description-row .work-completed {
  width: 70%;
}
.invoice-right-table .date {
  width: 45%;
}
.invoice-right-table .date-field {
  width: 55%;
}
.invoice-pay-invoices-promptly-row .bank-name {
  width: 20%;
}
.invoice-pay-invoices-promptly-row .bank-name-field {
  width: 80%;
}
.invoice-2-description-row .description {
  width: 60%;
}
.invoice-2-description-row .exc-vat {
  width: 12%;
}
.invoice-2-description-row .vat {
  width: 13%;
}
.invoice-2-description-row .total {
  width: 15%;
}
.invoice-5-screen-title {
  font-size: 28px;
  text-align: center;
}
.legionnaires-summary-sheet-row .potential {
  width: 27%;
}
.legionnaires-summary-sheet-row .potential-field {
  width: 6%;
}
.legionnaires-summary-sheet-row .potential-action-req {
  width: 11%;
}
.legionnaires-summary-sheet-row .potential-comments {
  width: 37%;
}
.legionnaires-summary-sheet-row .potential-risk {
  width: 7%;
}
.legionnaires-advisories-row tbody tr td {
  padding: 4px 6px;
  border-top: none;
  border-bottom: none;
}
.page-sidebar {
  background: #ffffff;
  border-left: 1px solid #c9c9c9;
  display: block;
  right: -100%;
  position: fixed;
  width: 30%;
  bottom: 0px;
  top: 73px;
  z-index: 1;
  -webkit-transition: 0.5s all ease-in;
  -moz-transition: 0.5s all ease-in;
  -o-transition: 0.5s all ease-in;
  transition: 0.5s all ease-in;
}
.page-sidebar header {
  background: #6393b5;
  border-bottom: 1px solid #c9c9c9;
  padding: 1px 10px 0;
  float: left;
  width: 100%;
}
.page-sidebar header h3 {
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0;
  font-size: 18px;
}
.page-sidebar header h3 {
  float: left;
}
.page-sidebar header .ss-delete {
  font-size: 10px;
  float: right;
  display: block;
  margin: 10px 20px 0;
  color: #ffffff;
}
.page-sidebar.open {
  right: 0;
}
.tagging_sidebar {
  width: 255px !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.tagging_sidebar header {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.tagging_sidebar header h3 {
  float: left;
}
.tagging_sidebar header a {
  float: right;
  margin: 9px 20px;
  color: #ffffff;
}
.tagging_sidebar header .back-to-tagging {
  display: none;
}
.tagging_sidebar .tagging-help-wrapper {
  display: none;
}
.tagging-view {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}
.tagging-view .help-text {
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #c9c9c9;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.tagging-view .open-tagging-help-wrap.hidden {
  display: none;
}
.tagging-view .close-tagging-help-wrap {
  display: none;
}
.tagging-view .close-tagging-help-wrap.show {
  display: block;
}
.tagging-view .tags-wrapper {
  padding: 15px;
}
.tagging-view .tags-wrapper.close-tagging {
  display: none;
}
.tagging-view .section-title {
  color: #999999;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px 0;
  display: block;
}
.tagging-view ul {
  margin: 0;
}
.tagging-view ul li {
  list-style: none;
}
.tagging-view .tag-btn {
  color: #505b65;
  border: 1px solid #bcbcbc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  text-shadow: 0px -1px 1px rgba(255, 255, 255, 0.4);
  background: #f0f0f0;
  display: inline-block;
  padding: 1px 10px;
  margin: 0 10px 10px 0;
}
.tagging-help-section {
  padding: 0 15px;
  margin: 15px 0 0 0;
  background: #ffffff;
  -webkit-transition: top 0.8s;
  -moz-transition: top 0.8s;
  -o-transition: top 0.8s;
  transition: top 0.8s;
  display: none;
}
.tagging-help-section.open-help {
  top: 0;
  overflow: auto;
  height: 90%;
}
.tagging-help-section ol {
  color: #60a369;
}
.tagging-help-section li {
  margin: 18px 0 0;
}
.tagging-scroll-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  position: relative;
  -webkit-box-shadow: 0px -5px 4px -3px #f1f1f1 inset;
  -moz-box-shadow: 0px -5px 4px -3px #f1f1f1 inset;
  box-shadow: 0px -5px 4px -3px #f1f1f1 inset;
}
.wizard-container {
  font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
  color: #59636d;
  line-height: 18px;
  padding-top: 50px;
}
.wizard-container .wizard {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #cccccc;
  padding: 12px 20px 20px;
}
.wizard-container .wizard hr {
  margin: 10px 0;
}
.wizard-container header h5 {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 10px;
}
.wizard-container .steps {
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}
.wizard-container .steps h4 {
  margin: 0;
}
.wizard-container .steps ul {
  border-top: 1px solid #d8d8d8;
  list-style: none;
  margin: 0;
  *zoom: 1;
  clear: both;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.wizard-container .steps ul:before,
.wizard-container .steps ul:after {
  display: table;
  content: "";
  line-height: 0;
}
.wizard-container .steps ul:after {
  clear: both;
}
.wizard-container .steps ul li {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
  border-right: 1px solid #d8d8d8;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.wizard-container .steps ul li:first-child {
  padding-left: 20px;
}
.wizard-container .steps ul li:last-child {
  border-right: none;
}
.wizard-container .steps .count {
  position: absolute;
  left: 50%;
  margin: 2px 0 0 -14px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: 2px solid #959ba6;
  background: #ffffff;
  color: #959ba6;
  font-weight: bold;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 2;
}
.wizard-container .steps .count.ss-check {
  line-height: 32px;
  color: #7eb419;
}
.wizard-container .steps .step {
  margin: 40px 0 0 5px;
  display: inline-block;
}
.wizard-container .pretty-list.inside-section {
  border-left: 0px !important;
}
.wizard-container input[type="text"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  /*		padding: 7px 8px !important;*/

  height: 30px !important;
}
.wizard-container input[type="text"].set-width {
  width: 314px;
}
.wizard-container input[type="text"].phone-number-input {
  width: 150px;
}
.wizard-container .client_import_data_wrapper {
  position: static;
}
.wizard-container .client_import_data_wrapper .title {
  margin-bottom: 0px;
}
.wizard-container .client_import_data_wrapper .row-fluid {
  padding-left: 0px;
}
.wizard-container .client_import_data_wrapper .import_panel .row-fluid {
  padding-left: 30px;
}
.wizard-container .client_import_data_wrapper .import_panel .title {
  margin-bottom: 20px;
}
@media (max-width: 1000px) {
  .wizard .steps li {
    padding: 6px 1% !important;
  }
  .wizard .steps li:first-child {
    margin-left: 8px;
  }
}
@media (max-width: 1200px) {
  .wizard-span {
    width: 100% !important;
    margin: 0 auto !important;
    float: none !important;
    margin: 4px !important;
  }
}
@media (max-height: 768px) {
  .wizard-container {
    padding-top: 0;
  }
  .wizard-container .form-horizontal {
    margin-bottom: 0;
  }
  .wizard-container fieldset {
    padding-bottom: 10px;
  }
  .contract_summary_wrapper .pricing {
    height: 80px !important;
  }
  .contract_wrapper .contract {
    height: 330px !important;
  }
}
.my_account {
  padding: 20px 0;
}
.my_account .settings-section .span9 {
  border: none;
}
.settings {
  padding: 20px 0;
}
.settings.general .span9 {
  border-top: none;
}
.settings-section .span9 {
  border-top: 1px solid #c9c9c9;
}
.settings-section .span9:first-child {
  border-top: none;
}
.settings-section .span9 ul {
  display: inline-table;
  width: 33%;
  min-height: 110px;
  padding: 0 15px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.settings-section .span9 ul li {
  list-style: none;
}
.settings-section h2 {
  font-weight: 600;
  color: #5e6d7f;
  font-size: 24px;
}
.settings-section h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 26px;
}
.settings-section p {
  margin-bottom: 0;
}
.existing_customer_records {
  width: 90%;
  float: right;
}
.existing_customer_records .control-label {
  width: 140px;
}
.existing_customer_records .controls {
  margin-left: 160px;
}
.existing_customer_records .settings_search {
  padding-left: 0;
}
.existing_customer_records .settings_search .search_table {
  border-bottom: 0;
  border-right: 1px solid #c9c9c9;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  margin-bottom: 0px;
  margin-left: 9px;
}
.existing_customer_records .settings_search.full_width {
  width: 100%;
}
.existing_customer_records .settings_search.full_width .search_table {
  width: 100%;
}
.existing_customer_records .settings_search.full_width .search_table .search_box {
  min-height: 26px;
}
.existing_customer_records .settings_search.full_width .search_table input {
  width: 80%;
}
.existing_customer_records.with_filters .search_box {
  width: 188px;
}
.existing_customer_records.with_filters .search_box input {
  width: 136px;
}
.existing_customer_records.with_filters .search_box.large input {
  width: 142px;
}
.existing_customer_records.with_filters .filter_links {
  float: left;
  border: 0;
  padding: 0;
  background: none;
  margin: 0 0 0 10px;
}
.existing_customer_records.with_filters .filter_links ul {
  margin: 0px;
  border: 0;
}
.existing_customer_records.with_filters .filter_links ul .quick_filter_title {
  margin: 3px 0 0 0;
}
.existing_customer_records.with_filters .filter_links .input-medium {
  height: 26px;
}
.existing_customer_records.with_filters .filter_links .input-medium.first {
  margin: 0 10px;
}
.existing_customer_records.with_download_options strong {
  float: left;
}
.existing_customer_records.with_download_options > p {
  clear: both;
}
.existing_customer_records.empty .pagination {
  display: none;
}
.existing_customer_records.empty strong,
.existing_customer_records.empty p,
.existing_customer_records.empty .search_table,
.existing_customer_records.empty .settings_search,
.existing_customer_records.empty .download_options {
  display: none;
}
.existing_customer_records.empty .sortable_wrapper {
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}
.existing_customer_records.empty ul {
    background: #ffffff url('../images/arrow.png') no-repeat;
    background-size: 24%;
    background-position: 67px 25px;
    text-align: center;
}
.existing_customer_records.empty ul:hover {
  cursor: default;
}
.existing_customer_records.empty ul li {
  border: none;
  padding: 0;
}
.existing_customer_records.empty ul li:first-of-type {
  margin: 110px 0 0 0;
}
.confirm_delete_wrapper {
  margin: 10px 0 0 0;
}
.confirm_delete_wrapper .ss-caution {
  font-size: 17px;
  line-height: 25px;
}
.account_number_system_settings input[type="radio"],
.account_number_system_settings input[type="checkbox"] {
  float: left;
}
.account_number_system_settings input[type="radio"].fix-margin,
.account_number_system_settings input[type="checkbox"].fix-margin {
  margin: 3px 10px 0px 10px !important;
}
.account_number_system_settings .help-block {
  padding-left: 30px;
}
.nominal_accounts_wrapper .nominal-select {
  width: 60%;
}
.nominal_accounts_wrapper .new-nominal-link {
  margin: 0 0 0 10px;
}
.nominal_accounts_wrapper .new-nominal-inputs {
  display: none;
  position: relative;
  z-index: 4;
}
.nominal_accounts_wrapper .new-nominal-inputs input[type="text"] {
  float: left;
  width: 60%;
}
.nominal_accounts_wrapper .new-nominal-inputs .btn {
  margin: 0 0 0 10px;
}
.nominal_accounts_wrapper .new-nominal-inputs .cancel-nominal-link {
  display: inline;
  margin: 0 0 0 5px;
}
.nominal_accounts_wrapper #add_new_error {
  position: relative;
  z-index: 2;
}
.nominal_accounts_wrapper #add_new_error.hide-error-message {
  display: none;
}
.item_category_label {
  width: 149px !important;
}
.filter {
  float: left;
}
.filter .search_box {
  height: 26px;
  width: 324px;
  margin: 0;
}
.filter .search_box input {
  width: 270px;
  margin: 3px 0 0 0;
}
.filter .search_box .ss-search {
  margin: 3px 6px 0 10px;
}
.filter .search_box .ss-delete {
  color: #505b65;
  font-size: 10px;
  position: absolute;
  margin: 3px 0 0 3px;
  display: none;
}
.filter .search_box.small input {
  margin-top: 0px;
}
.filter.search_table {
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
  border-radius: 5px 0 0 0;
  background: #eeeeee;
  padding: 5px;
  border: 1px solid #c9c9c9;
  border-right: 0;
  margin-left: 12px;
}
.filter.search_table .search_box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b8bec8;
}
.filter.without-filter {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  border-right: 1px solid #c9c9c9;
}
.filter.without-filter .search_box {
  width: 365px;
}
.filter.without-filter .search_box input {
  width: 309px;
}
.backup-form .content_below_help_section {
  padding-top: 2.127659574468085%;
}
.backup-row {
  margin: 0 0 2.127659574468085% 0;
}
.backup-row .backup-option-wrapper {
  position: relative;
  padding: 12px 15px;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.backup-row .backup-option-wrapper.active {
  padding: 10px 13px;
  border: 3px solid #505b65;
}
.backup-row .backup-option-wrapper.active .btn {
/*  display: block;
*/
}
.backup-row .backup-option-wrapper.disabled {
  border-color: #7f7f7f;
}
.backup-row .backup-option-wrapper.disabled .disabled-state {
  display: block;
}
.backup-row .backup-option-wrapper.disabled:hover {
    cursor: url("../images/null.png"), default;
    background: #ffffff;
}
.backup-row .backup-option-wrapper.selected:hover {
  cursor: default;
  background: #ffffff;
}
.backup-row .backup-option-wrapper.selected .backup-default-view {
  display: none;
}
.backup-row .backup-option-wrapper.selected .backup-selected-view {
  display: block;
}
.backup-row .backup-option {
  position: relative;
  height: 200px;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100px;
}
.backup-row .backup-option.loading {
  padding-top: 70px;
  box-sizing: border-box;
  text-align: center;
  color: #7e8d93;
}
.backup-row .backup-option.loading .loading-wrap {
  margin-top: 0px;
}
.backup-row .backup-option.loading p {
  margin-top: 20px;
}
.backup-row .backup-option.dropbox {
    background-image: url("../images/dropbox.png");
}
.backup-row .backup-option.google-drive {
    background-image: url("../images/google_drive.png");
}
.backup-row .backup-option.skydrive {
    background-image: url("../images/skydrive.png");
    background-size: 128px;
}
.backup-row .backup-option.excel {
    background-image: url("../images/excel.png");
}
.backup-row .backup-option.stripe {
  background-image: url("../images/credit-card/stripe.png");
}
.backup-row .backup-option.voice_commerce {
  background-image: url("../images/credit-card/vcg.png");
  background-size: 200px;
}
.backup-row .backup-option p {
  margin: 7px 0 20px;
}
.backup-selected-view {
  display: none;
}
.backup-selected-view ul {
  margin: 10px 0 10px 25px;
}
.backup-selected-view .cancel-this-backup {
  position: absolute;
  bottom: 0;
}
.disabled-state {
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.backup-option-name {
  float: none !important;
  margin: 0 !important;
}
.disabled-backup-message {
  float: left;
}
.merge-wrapper {
  margin: 10px 0 0 0;
}
.merge-wrapper .merge-question input[type="radio"] {
  margin: -3px 3px 0 10px;
}
.merge-wrapper .merge-with {
  overflow: hidden;
  height: 0;
  -webkit-transition: height 0.45s;
  -moz-transition: height 0.45s;
  -o-transition: height 0.45s;
  transition: height 0.45s;
}
.merge-wrapper .merge-with.show {
  height: 100px;
}
.merge-wrapper .merge-with p {
  float: left;
  margin: 15px 0 0;
}
.merge-wrapper .merge-with select {
  margin: 10px 0 0 15px;
}
.merge-wrapper .merge-with .btn {
  margin: 10px 0 0 0;
}
.terms_widget_wrapper .terms_conditions_editor {
  height: 200px;
}
.terms_widget_wrapper .wysihtml5-sandbox {
  margin: 0 0 20px 0 !important;
}
.terms_widget_wrapper .wysihtml5-toolbar {
  float: left;
}
.terms_widget_wrapper .terms_preview_link {
  float: right;
  margin: 6px 0 0;
  position: relative;
  z-index: 2;
}
.terms_widget_wrapper hr {
  clear: both;
}
.terms_conditions .name {
  width: 20%;
}
.terms_conditions .actions a {
  color: #0088CC;
  float: none;
}
.terms_conditions .actions .delete {
  margin: 0px;
  padding: 0px;
}
.terms_conditions .description-terms {
  padding-right: 40px;
}
.terms_conditions .section-separator {
  margin: 7px 0 10px 0px;
}
.delete-category-modal .modal-body {
  padding: 15px 0 0 0;
  height: 330px;
  position: relative;
  overflow: hidden;
}
.delete-category-modal.tall-body .modal-body,
.delete-category-modal.tall-body .category-view {
  height: 350px;
}
.delete-category-modal .row-fluid {
  padding: 0;
}
.delete-category-modal .category-view {
  padding: 0 30px;
  position: absolute;
  overflow: hidden;
  left: 1000px;
  right: -1000px;
  height: 330px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.delete-category-modal .category-view.initial {
  left: 0;
  right: 0;
}
.delete-category-modal .category-view.initial.slide-out-left {
  left: -1000px;
  right: 1000px;
}
.delete-category-modal .category-view.slide-out-left {
  left: -1000px;
  right: 1000px;
}
.delete-category-modal .category-view.slide-out-right {
  left: 1000px;
  right: -1000px;
}
.delete-category-modal .category-view.slide-in-left {
  left: 0;
  right: 0;
}
.delete-category-modal .help_section {
  padding: 0;
  margin: 0 0 20px 0;
  border-bottom-color: #eeeeee;
}
.delete-category-modal .final-btn {
  display: none;
}
.delete-category-modal .first-radio {
  margin: 0 0 20px 0;
}
.delete-category-modal .previous-section-link {
  position: absolute;
  z-index: 1;
  bottom: 2px;
  left: 30px;
}
.delete-category-modal .previous-section-link .ss-left {
  float: left;
  font-size: 10px;
  margin: 1px 4px 0 0;
}
.delete-category-modal .transfer-delete-view {
  display: none;
}
.delete-category-modal .confirm_delete_wrapper {
  display: none;
  padding: 0 32px;
  position: absolute;
  bottom: 43px;
}
.delete-category-modal .confirm_delete_wrapper.with-error {
  bottom: 8px;
}
.modal-inline-select .control-group {
  margin: 0;
}
.modal-inline-select .control-label {
  float: left;
  margin: 4px 0 0 0;
}
.modal-inline-select select {
  margin: -1px 0 0 20px;
}
.modal-inline-select .help-block {
  margin: 10px 0 0 0;
}
.unassigned .help-block {
  margin: 10px 0 0 0;
}
#confirm-downgrade-account .modal-body,
#confirm-downgrade-account .category-view {
  height: 460px;
}
#basic-plan-downgrade-modal .help_section ul,
#confirm-downgrade-account .help_section ul {
  float: left;
  margin: 0 30px 10px 25px;
}
#basic-plan-downgrade-modal .help_section p,
#confirm-downgrade-account .help_section p {
  clear: both;
}
.customer_job_confirmation_wrapper {
  position: relative;
  padding: 0px !important;
}
.customer_job_confirmation_wrapper .import_options_wrapper .label {
  float: right;
  margin: 9px 8px 0 0;
}
.customer_job_confirmation_wrapper ul {
  padding-top: 0px !important;
}
.customer_job_confirmation_wrapper.below-page-options {
  border-top: 1px solid #ffffff;
  padding-top: 20px;
}
.confirmation-option-wrapper {
  margin-left: 20px !important;
  margin-top: 20px !important;
}
.confirmation-option-wrapper > .row-fluid {
  padding: 0;
}
.confirmation-option-wrapper .confirmation-option {
  width: 79.468085% !important;
  display: none;
  margin: 0 0 0 3px !important;
}
.confirmation-option-wrapper .confirmation-option.active {
  display: block;
}
.confirmation-option-wrapper .letter_confirmation_textarea {
  height: 400px;
}
.confirmation-option-wrapper .help-block {
  margin: 0 0 10px 0;
}
.confirmation-option-wrapper .sms_message {
  margin: 10px 0 0 0;
}
.confirmation-option-wrapper .no-answer-wrapper {
  width: 100%;
  margin-bottom: 45px;
}
.confirmation-option-wrapper .no-answer-wrapper * {
  float: left;
}
.confirmation-option-wrapper .no-answer-wrapper select {
  position: relative;
  left: 10px;
  top: -4px;
}
.default_email p {
  float: left;
  clear: both;
  margin: 5px 0 -14px 0;
}
.email_subject_wrapper .letter-preview {
  float: right;
}
.char-count-text {
  visibility: hidden;
}
.char-count-text.show {
  visibility: visible;
}
.textarea-with-count {
  height: 120px;
}
.with-left-sidbar {
  padding-bottom: 0;
}
.with-left-sidbar .import_options_wrapper {
  top: 20px;
}
.with-left-sidbar .import_options_wrapper li {
  float: left;
  border-bottom: 1px solid #dadada !important;
}
.job-summary-text-block {
  float: left;
  margin: 30px 0 0 0;
  color: #000000;
  font-size: 16px;
}
.job-summary-text-block:last-of-type {
  float: right;
}
.job_summary_template {
  border: none !important;
}
.job_summary_template .job_sheet_block {
  margin: 0 0 12px 0;
  border: 1px solid #000000;
}
.job_summary_template .job_sheet_block:last-of-type {
  border-bottom: 1px solid #000000 !important;
}
.job_summary_template ul li {
  margin: 0 0 6px 0;
}
.job_summary_template ul li strong {
  float: left;
  margin: 0 10px 0 0;
  min-width: 138px;
}
.job_summary_template ul li div {
  overflow: hidden;
}
.required-row {
  height: 100px;
  padding-top: 10px;
  border-top: 1px solid #000000;
}
.required-row strong {
  padding: 10px 0 0 0;
}
.required-row .span6:first-of-type {
  height: 100px;
  border-right: 1px solid #d6d6d6;
}
.add_customer_sms .open-sidebar {
  margin: -37px 0 0;
}
.account-plan-wrapper .customer_job_confirmation_wrapper {
  border-top: 0px;
  margin-top: 1px;
}
.account-plan-wrapper .confirmation-option-wrapper {
  min-height: 400px;
  margin: 60px 0 20px 0;
  position: relative;
  border-top: 0px;
  left: 0px;
}
.account-plan-wrapper .confirmation-option-wrapper .confirmation-option {
  width: 100% !important;
}
.account-plan-wrapper .confirmation-option-wrapper .confirmation-option hr,
.account-plan-wrapper .confirmation-option-wrapper .confirmation-option p {
  clear: both;
}
.account-plan-wrapper .confirmation-option-wrapper .confirmation-option > .row-fluid {
  padding-left: 0;
}
.account-plan-wrapper .pricing-support {
  text-align: right;
}
.account-plan-wrapper .pricing-support p {
  margin: 0;
}
.account-plan-wrapper .pricing-support a {
  margin: 8px 0 0 0;
}
.account-plan-wrapper .import_options_wrapper {
  bottom: auto;
  top: 18px;
  margin-top: -1px !important;
}
.account-plan-wrapper .import_options_wrapper ul {
  border-bottom: 1px solid #dadada !important;
  overflow: visible;
}
.account-plan-wrapper .import_options_wrapper ul li {
  overflow: visible;
}
.account-plan-wrapper .account-plans {
  width: 66% !important;
  height: 55px !important;
}
.account-plan-wrapper .account-plans .confirmation-method {
  width: 25% !important;
  float: left !important;
  clear: none !important;
  position: relative;
  border: none !important;
  border-left: 1px solid #dadada !important;
  -webkit-box-shadow: 0px 1px 0px #dadada !important;
  -moz-box-shadow: 0px 1px 0px #dadada !important;
  box-shadow: 0px 1px 0px #dadada !important;
  text-align: center;
}
.account-plan-wrapper .account-plans .confirmation-method a {
  margin: 7px 0;
  width: 100%;
}
.account-plan-wrapper .account-plans .confirmation-method:first-of-type {
  border-left: none !important;
}
.account-plan-wrapper .account-plans .confirmation-method:last-of-type {
  border-bottom: none !important;
}
.account-plan-wrapper .account-plans .confirmation-method.active {
  background: #34a2dc !important;
  border-bottom: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  padding: 11px 0;
  margin-top: -1px;
}
.account-plan-wrapper .account-plans .confirmation-method.active a {
  color: #ffffff;
}
.account-plan-wrapper .account-plans .confirmation-method.active::after {
  content: ' ';
  display: block;
  position: absolute;
  left: 50%;
  margin: 43px 0 0 -9px;
  z-index: 1;
  border-color: transparent transparent transparent #34a2dc;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #34a2dc;
}
.account-plan-wrapper .upgrade-btn {
  float: right;
  margin: 20px 0 0 0;
}
.account-details-wrapper {
  position: absolute;
  overflow: hidden;
  top: -1px;
  bottom: 0;
  right: 0;
  width: 34%;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #dadada;
}
.account-details-wrapper .storage-helper {
  display: none;
  margin: 0;
  color: #72818f;
}
.account-details ul {
  margin: 6px 0 20px 25px;
  list-style: none;
}
.account-details .change-payment-method {
  margin: 0 0 0 10px;
}
.payment-methods {
  position: absolute;
  top: -1000px;
  background: #ffffff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.payment-methods.active {
  top: 20px;
  bottom: 0;
}
.payment-methods .actions {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.payment-methods .payment_details .control-label {
  width: 94px;
}
.payment-methods .payment_details .controls {
  margin: 0 20px 0 118px;
}
.payment-methods .payment_details #cvv-number,
.payment-methods .payment_details #month,
.payment-methods .payment_details #year {
  width: 62px;
}
.payment-methods .cancel-payment {
  float: right;
  margin: 0 20px 0 0;
}
.inline-print {
  font-size: 16px;
  color: #797979;
  margin: 0 0 0 7px;
}
.inline-print:hover {
  cursor: pointer;
  color: #797979;
}
.payment-row td:first-of-type {
  padding-left: 26px;
}
.payment-row .ss-downright {
  float: left;
  margin: -3px 4px 0 0;
  color: #797979;
  font-size: 12px;
}
.mobile_wrapper input[type="radio"] {
  float: left !important;
  margin: 3px 5px 0 20px;
}
.mobile_wrapper label {
  margin-bottom: 0;
}
.mobile_wrapper .bank_details_form {
  display: none;
}
.mobile_wrapper .bank_details_form .content_below_help_section {
  border-top: none;
  padding-top: 0;
}
.edit-datepicker {
  display: inline-block !important;
}
.edit_dropdown_view .checkbox-type-wrapper,
.edit_checkbox_view .checkbox-type-wrapper {
  overflow: auto;
  display: inline-block;
  padding-left: 10px;
  margin-left: -10px;
  box-sizing: border-box;
}
.fixed-phantom-checkbox {
  margin: 4px 0 0 0;
  list-style: none;
}
.checkbox-type-wrapper {
  list-style: none;
  margin: 0;
  width: 350px;
}
.checkbox-type-wrapper .delete-link {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -60px;
}
.checkbox-type-wrapper .input-wrapper {
  margin-bottom: 10px;
}
.phantom-checkbox {
  float: left;
  width: 12px;
  height: 12px;
  margin: 8px 10px 0 0;
  border: 1px solid #dadada;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.phantom-radio {
  float: left;
  width: 12px;
  height: 12px;
  margin: 8px 10px 0 0;
  border: 1px solid #dadada;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.phantom-input {
  display: inline-block;
  padding: 0 6px;
  height: 30px;
  line-height: 29px;
  width: 210px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid rgba(204, 204, 204, 0.5);
  color: #c4c4c4;
}
.phantom-input:hover {
  color: #bcbcbc;
  border: 1px solid rgba(204, 204, 204, 0.6);
}
.edit_view_wrapper .checkbox-type-wrapper {
  width: 100%;
}
.image-input {
  visibility: hidden;
}
.file-trigger {
  float: left;
  clear: both;
  margin: 10px 0 0 0;
}
.image-preview {
  clear: both;
}
.image-preview.small-square-preview {
  width: 100px;
  height: 100px;
}
.remove-image {
  float: left;
  margin: 0px 0 0 10px;
}
.upload-new-logo {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #f7f7f7;
  border: 1px dashed #b9b9b9;
  text-align: center;
}
.upload-new-logo .plus-icon {
  display: block;
  width: 50px;
  height: 50px;
  background: url("../images/dropzone-add-2.png") no-repeat;
  margin: 0 auto 10px auto;
}
.upload-new-logo a {
  float: none !important;
  display: block;
  margin: 0 auto;
  color: #7c868f;
}
.upload-new-logo .help-text {
  margin: 6px 0 0 0;
}
.upload-new-logo:hover {
  background: #f2f2f2;
  cursor: pointer;
}
.upload-new-logo .plus-icon {
  margin-top: 40px;
}
.upload-new-logo.small-square {
  width: 100px;
  height: 100px;
  float: left;
  clear: none;
}
.upload-new-logo.small-square .plus-icon {
  margin: -4px auto 4px auto !important;
}
.upload-new-logo.square {
  width: 200px;
}
.upload-new-logo.rectangle {
  width: 350px;
  height: 150px;
}
.choose-logo-wrap {
  float: left;
  margin: 0 0 0 20px;
}
.change-logo-overlay {
  position: relative;
  width: 200px;
  height: 200px;
  float: left;
  clear: both;
  margin: 10px 0 0 0;
}
.change-logo-overlay img {
  margin: 0 !important;
}
.change-logo-overlay .overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.change-logo-overlay:hover {
  cursor: pointer;
}
.change-logo-overlay:hover .overlay {
  display: block;
  text-align: center;
}
.change-logo-overlay:hover .overlay:after {
  content: "Change Logo";
  color: #ffffff;
  font-size: 16px;
  margin: 160px 0 0 0;
  display: block;
}
.image-logo {
  font-size: 50px;
  display: block;
  width: 51px;
  margin: 17px auto 7px auto;
  color: #dddddd;
}
.library-logo-zone {
  float: left;
  margin: 0 10px 0 0;
  position: relative;
}
.library-logo-zone:last-of-type {
  margin: 0;
}
.library-logo-zone .upload-new-logo,
.library-logo-zone .change-logo-overlay {
  margin: 5px 0 0 0 !important;
}
.library-logo-zone .zone-content {
  position: absolute;
  width: 93px;
  height: 65px;
  top: 50%;
  left: 50%;
  margin-top: -33px;
  margin-left: -47px;
}
.image-upload-modal,
.image-resize-modal {
  width: 826px;
  left: 50%;
  margin-left: -413px;
}
.image-upload-modal ul,
.image-resize-modal ul {
  list-style: none;
  margin: 0;
}
.image-upload-modal .modal-body,
.image-resize-modal .modal-body {
  padding: 0;
}
.image-upload-modal .modal-header,
.image-resize-modal .modal-header {
  border-bottom: none;
}
.image-upload-content {
  padding: 0;
}
.image-locations .image-location {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  clear: both;
  width: 100%;
  padding: 14px 0 15px 16px;
  border-bottom: 1px solid #dadada;
}
.image-locations .image-location.active {
  color: #ffffff;
  background: #34a2dc;
}
.image-locations .image-location.active::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -8px;
  z-index: 1;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #34a2dc;
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid rgba(0, 0, 0, 0);
  border-left: 8px solid #34a2dc;
}
.image-locations .help-link {
  position: absolute;
  left: 15px;
  bottom: 70px;
}
.image-area {
  height: 400px;
  margin: 0 !important;
  padding: 12px 20px;
  border-left: 1px solid #dadada;
}
.image-area .image-section {
  display: none;
}
.image-area .image-section.active {
  display: block;
}
.image-area h4,
.image-area ol {
  color: #505b65;
}
.image-area hr {
  margin: 0;
}
.image-area hr.with-bottom-margin {
  margin-bottom: 20px;
}
.image-area .help-block {
  margin: 10px 0 0 0;
}
.library-image-wrapper {
  padding: 20px 0 0 0;
}
.library-image-wrapper a {
  float: left;
  display: inline-block;
  margin: 0 10px 15px 0;
}
.library-image-wrapper .image {
    display: block;
    width: 100px;
    height: 100px;
    background: url("../../../images/test-image.png") no-repeat;
}
.image-upload-modal .title-bar {
  background: #f5f5f5;
  padding: 10px 15px;
  border-bottom: solid 1px #ddd;
}
.image-upload-modal .title-bar h4 {
  float: left;
  margin: 4px 0px;
}
.image-upload-modal .title-bar .image-upload-btn {
  float: right;
}
.image-upload-modal .modal-body {
  overflow: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: auto !important;
  width: 100% !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.image-upload-modal .modal-body .help-text {
  padding: 20px 30px 10px;
}
.image-upload-modal .modal-body .images-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  border-top: solid 1px #ddd;
}
.image-upload-modal .modal-body .images-wrapper .no-results-wrap .helper-arrow {
  top: -4px;
  margin-right: 60px;
}
.image-upload-modal .modal-body .images-wrapper .no-results-wrap .helper-text {
  top: 14px;
  right: 190px;
}
.image-upload-modal .modal-body .library-image-wrapper ul li {
  float: left;
  margin: 10px;
  padding: 2px;
  border: solid 1px transparent;
}
.image-upload-modal .modal-body .library-image-wrapper ul li:first-of-type {
  margin-left: 20px;
}
.image-upload-modal .modal-body .library-image-wrapper ul li:last-of-type {
  margin-right: 20px;
}
.image-upload-modal .modal-body .library-image-wrapper ul li.image-selected {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(96, 80, 166, 0.9);
  -moz-box-shadow: 0px 0px 6px 0px rgba(96, 80, 166, 0.9);
  box-shadow: 0px 0px 6px 0px rgba(96, 80, 166, 0.9);
  border: solid 1px rgba(96, 80, 166, 0.9);
}
.image-upload-modal .modal-body .library-image-wrapper ul li img {
  max-width: 200px;
  height: auto;
}
.image-resize-modal {
  top: 50% !important;
  margin-top: -300px;
  height: 600px;
  overflow: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.image-resize-modal .modal-body {
  position: absolute;
  top: 42px;
  left: 0px;
  right: 0px;
  bottom: 58px;
}
.image-resize-modal .modal-body .resize-bar-container {
  position: relative;
  padding: 25px 0 0;
}
.image-resize-modal .modal-body .image-container {
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  border-top: solid 1px #ddd;
  position: absolute;
  top: 90px;
  bottom: 0px;
  overflow: auto;
}
.image-resize-modal .modal-body .image-container .main-image {
  position: relative;
  overflow: hidden;
}
.image-resize-modal .modal-body .image-container .main-image .cropped-image-area {
  position: absolute;
  border: dashed 2px #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 2001px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 180ms ease-in-out;
  -moz-transition: all 180ms ease-in-out;
  -o-transition: all 180ms ease-in-out;
  transition: all 180ms ease-in-out;
  cursor: pointer;
}
.image-resize-modal .modal-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.jcrop-handle {
  display: none;
}
.ord-nw.jcrop-handle,
.ord-sw.jcrop-handle,
.ord-ne.jcrop-handle,
.ord-se.jcrop-handle {
  display: block;
  width: 20px;
  height: 20px;
  margin: -10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: white;
  opacity: 1 !important;
}
.jcrop-hline,
.jcrop-vline {
  opacity: 1 !important;
}
.jcrop-hline {
  height: 3px !important;
}
.jcrop-vline {
  width: 3px !important;
}
.jcrop-keymgr {
  opacity: 0;
}
.image-resize-modal .resize-widget-view,
.image-resize-modal .image-preview-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}
.image-resize-modal .ng-jcrop-thumbnail-wrapper {
  text-align: center !important;
  position: absolute !important;
  left: 50% !important;
  margin-top: 0px !important;
  margin-left: -50px !important;
  transform: scale(4.09, 4.09);
  transform-origin: top;
}
.image-resize-modal .temp-image {
  box-shadow: 0px 0px 0px 10000px rgba(0, 0, 0, 0.7), 0 0 7px -2px rgba(0, 0, 0, 0.8);
}
.image-resize-modal .resize-widget-view,
.image-resize-modal .image-preview-view {
  perspective: 600px;
  pointer-events: none;
}
.image-resize-modal .resize-widget-view.resize-widget-view,
.image-resize-modal .image-preview-view.resize-widget-view {
  -webkit-transition: all 0.9s ease-in 0s;
  -moz-transition: all 0.9s ease-in 0s;
  -o-transition: all 0.9s ease-in 0s;
  transition: all 0.9s ease-in 0s;
  transform: perspective(1200px) rotateY(90deg) scale(0.85);
}
.image-resize-modal .resize-widget-view.resize-widget-view .ng-jcrop-thumbnail,
.image-resize-modal .image-preview-view.resize-widget-view .ng-jcrop-thumbnail {
  display: none;
}
.image-resize-modal .resize-widget-view.image-preview-view,
.image-resize-modal .image-preview-view.image-preview-view {
  -webkit-transition: all 0.9s ease-in 0s;
  -moz-transition: all 0.9s ease-in 0s;
  -o-transition: all 0.9s ease-in 0s;
  transition: all 0.9s ease-in 0s;
  transform: perspective(1200px) rotateY(-90deg) scale(0.85);
}
.image-resize-modal .resize-widget-view.image-preview-view .ng-jcrop-image-wrapper,
.image-resize-modal .image-preview-view.image-preview-view .ng-jcrop-image-wrapper {
  display: none;
}
.image-resize-modal .resize-widget-view.flipin,
.image-resize-modal .image-preview-view.flipin {
  pointer-events: auto;
  transform: skewY(0deg) perspective(1200px) rotateY(0deg) scale(1);
}
.image-resize-modal .resize-widget-view.flipin.resize-widget-view,
.image-resize-modal .image-preview-view.flipin.resize-widget-view {
  -webkit-transition: all 0.9s ease-out 0s;
  -moz-transition: all 0.9s ease-out 0s;
  -o-transition: all 0.9s ease-out 0s;
  transition: all 0.9s ease-out 0s;
}
.image-resize-modal .resize-widget-view.flipin.resize-widget-view .ng-jcrop-image-wrapper,
.image-resize-modal .image-preview-view.flipin.resize-widget-view .ng-jcrop-image-wrapper {
  display: block;
}
.image-resize-modal .resize-widget-view.flipin.image-preview-view,
.image-resize-modal .image-preview-view.flipin.image-preview-view {
  -webkit-transition: all 0.9s ease-out 0s;
  -moz-transition: all 0.9s ease-out 0s;
  -o-transition: all 0.9s ease-out 0s;
  transition: all 0.9s ease-out 0s;
}
.image-resize-modal .resize-widget-view.flipin.image-preview-view .ng-jcrop-thumbnail,
.image-resize-modal .image-preview-view.flipin.image-preview-view .ng-jcrop-thumbnail {
  display: block;
}
.image-resize-modal-body .full-image {
  background: #ffffff;
  padding: 10px 15px;
  width: 100%;
  margin-left: -15px;
  margin-top: -15px;
  text-align: center;
}
.image-resize-modal-body .preview-image {
  text-align: center;
  display: none;
}
.certificate-widget {
  float: left;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  padding: 10px 0 20px 0;
}
.certificate-widget .actions {
  clear: none;
}
.certificate-details {
  padding: 0 20px;
}
.certificate-details h2,
.certificate-details p {
  float: left;
}
.certificate-details a {
  float: right;
}
.certificate-details p {
  clear: both;
  color: #7f7f7f;
}
.certificate-body {
  clear: both;
}
.add-question {
  margin: 0 0 0 20px;
}
.cancel-question {
  float: right;
  margin: 8px 20px 0 0;
  color: #999999;
}
.cancel-question:hover {
  color: #999999;
}
.new-question,
.edit-question {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  display: none;
  float: left;
  width: 100%;
  background: #f9f9f9;
  margin: 10px 0;
  padding: 14px 20px 20px 20px;
}
.new-question i,
.edit-question i {
  color: #76818f;
  font-weight: 100;
}
.questions {
  margin: 15px 0;
}
.questions .btn {
  float: right;
  margin: 10px 0 0;
}
.questions-wrapper {
  margin: 10px 0;
}
.questions-wrapper .added-question-row {
  padding: 10px 0 0 0;
}
.questions-wrapper .added-question-row a:not(.btn-cancel) {
  color: #0077b3;
}
.questions-wrapper .added-question-wrap {
  float: left;
  width: 100%;
  padding: 12px 0;
}
.questions-wrapper .added-question-wrap .control-group.span6 {
  margin-bottom: 0px;
}
.questions-wrapper .added-question-wrap:hover {
  background: #f9f9f9;
}
.questions-wrapper .added-question {
  padding: 0 20px;
}
.questions-wrapper .added-question a {
  margin: 0 0 0 20px;
  color: #3883c1;
}
.questions-wrapper .added-question a:hover {
  color: #3276ad;
}
.questions-wrapper .added-question .controls {
  margin-top: 5px;
}
.questions-wrapper .added-question .controls input {
  margin-top: -3px;
  margin-right: 2px;
}
.questions-wrapper .added-question .controls select {
  margin-top: -4px;
}
.questions-wrapper .controls {
  padding-bottom: 5px;
}
.questions-wrapper .controls .help-block {
  float: none;
  margin-top: 5px;
  margin-bottom: 5px;
}
.option-wrapper {
  float: left;
  width: 100%;
  list-style: none;
  margin: 0;
}
.option-wrapper.top-margin {
  margin-top: 10px !important;
}
.option-wrapper .input-wrapper.cover-phantom-input {
  margin-left: -14px;
}
.option-wrapper .edit-wrapper {
  float: left;
  margin: 0 10px 0 0;
}
.option-wrapper .edit-options {
  display: inline-table;
  float: right;
  margin-top: 5px;
}
.option-wrapper .add-option {
  margin: 0 0 0 10px;
}
.option-wrapper li {
  clear: both;
  float: left;
  width: 100%;
  margin: 0 0 4px 0;
}
.option-wrapper label {
  float: left;
  display: inline;
  margin-top: 5px;
}
.option-wrapper label.control-label {
  margin-top: 0px;
}
.option-wrapper .delete-link,
.option-wrapper .save-link {
  margin: 0 0 0 20px;
}
.option-wrapper #editOptionInput {
  margin: -4px 0 4px 0;
}
.added-question-row input[type='checkbox'] {
  margin-top: 9px;
}
#contract-container {
  position: relative;
}
#contract-container .contract-pdf-wrap {
  width: 820px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  bottom: 0;
}
#contract-container #contract-pdf {
  width: 820px;
  height: 100%;
  position: absolute;
  bottom: 0px;
}
.account_number_system_settings.full-height {
  box-sizing: border-box;
}
.account_number_system_settings.full-height .download_options {
  width: 820px;
  text-align: right;
  margin: 16px auto 0px;
  float: none;
}
.full-height-section {
  position: absolute;
  top: 0px;
  left: 30px;
  right: 30px;
  width: auto !important;
  bottom: 20px;
}
.textarea_app_integration {
  padding-top: 16px;
  height: auto;
  margin-bottom: 10px !important;
}
.app_integration_button {
  float: right;
  margin: 10px 0 0 20px;
}
.mini_app_checkbox {
  margin: 30px 0 0 0 !important;
}
.email_subject_wrapper .letter-preview_2 {
  float: right;
}
.option-wrapper.contact_option li {
  margin-bottom: 10px;
}
.option-wrapper.contact_option li:first-child {
  margin-bottom: 0px;
}
.option-wrapper.contact_option li:nth-child(2) {
  margin-top: 10px;
}
.option-wrapper.contact_option li:last-child {
  margin-bottom: 0px;
}
.option-wrapper.contact_option li span {
  float: right;
}
.control-error-message.contact-error-message {
  margin-top: -58px;
}
.tasks {
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: inset 0 1px 1px #eff2f5;
  -moz-box-shadow: inset 0 1px 1px #eff2f5;
  box-shadow: inset 0 1px 1px #eff2f5;
  border: 1px solid #9c9fa5;
  border-top: 0;
  background: #e4e8ee;
  padding: 10px;
  width: 100%;
}
.tasks h5 {
  margin: 0 0 5px;
  font-weight: bold;
}
.tasks .question {
  display: none;
  width: 100%;
}
.tasks .question.open {
  max-height: 100px;
}
.tasks .question-title {
  font-weight: normal;
  margin-top: 4px;
  color: #4e525a;
}
.tasks .inline-checkbox input[type="checkbox"] {
  width: 15px;
  float: none;
  display: inline;
  margin-left: 5px;
  margin-top: 0;
}
.tasks .dropdown {
  margin-top: 5px;
  *zoom: 1;
  clear: both;
}
.tasks .dropdown:before,
.tasks .dropdown:after {
  display: table;
  content: "";
  line-height: 0;
}
.tasks .dropdown:after {
  clear: both;
}
.tasks .dropdown.inline .question-title {
  float: left;
  margin-top: 4px;
}
.tasks .dropdown.inline .chzn-container .chzn-choices,
.tasks .dropdown.inline .dotted {
  background: none;
  border: none;
  border-bottom: 1px dotted;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.tasks .todo-title {
  margin: 6px 0 4px;
  width: 100%;
  display: block;
}
.tasks .certificate-select {
  float: none;
}
.tasks #add-task {
  margin: 10px 0 0 0;
}
.checklist-title {
  margin-bottom: 5px;
  font-weight: bold;
}
.checklist {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  border: 1px solid #9c9fa5;
  background: #ffffff;
  margin-bottom: 0;
  width: 100%;
  list-style: none;
}
.checklist:empty {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  padding: 1px 0 0;
  border-bottom: 0;
}
.checklist li {
  *zoom: 1;
  clear: both;
  border-bottom: 1px solid #e4e4e4;
}
.checklist li:before,
.checklist li:after {
  display: table;
  content: "";
  line-height: 0;
}
.checklist li:after {
  clear: both;
}
.checklist li:last-child {
  border: none;
}
.checklist li:hover {
  background: #f2f2f2;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.checklist .incomplete {
  *zoom: 1;
  clear: both;
  background: #f2f2f2;
  color: #d14231;
  float: left;
  padding: 8px;
}
.checklist .incomplete:before,
.checklist .incomplete:after {
  display: table;
  content: "";
  line-height: 0;
}
.checklist .incomplete:after {
  clear: both;
}
.checklist .task {
  float: left;
  padding: 8px 8px 8px 15px;
  width: 100%;
  box-sizing: border-box;
}
.checklist .task a {
  float: right;
}
.checklist .task .remove-todo {
  margin: 0 10px 0 0;
}
.checklist .task .cancel-edit-todo {
  margin: 0 0 0 10px;
  display: none;
}
#contract,
#payment {
  display: none;
}
.setup_wrapper ul li {
  list-style: none;
}
.setup_wrapper ul li .align-right {
  float: right;
}
.setup_wrapper ul li .align-right.total {
  font-weight: bold;
}
.setup_wrapper.contract_summary_wrapper .pricing {
  height: 140px;
  width: 100%;
  overflow: auto;
  margin-bottom: 10px;
}
.setup_wrapper.contract_summary_wrapper .pricing #fixed-pricing-header {
  position: absolute;
}
.setup_wrapper.contract_summary_wrapper #total-price {
  font-weight: bold;
}
.setup_wrapper.contract_summary_wrapper .price-warning {
  margin: 10px 0;
  font-size: 13px;
}
.setup_wrapper.contract_wrapper .contract {
  height: 350px;
  width: 100%;
  overflow: auto;
}
.setup_wrapper.contract_wrapper .contract p {
  margin: 0 30px 10px 0;
}
.setup_wrapper.contract_wrapper .contract table {
  border: 1px solid #dcdada;
  margin: 16px 0;
  width: 95% !important;
}
.setup_wrapper.contract_wrapper .contract table tr:hover {
  background: #fbfbfb;
}
.setup_wrapper.contract_wrapper .contract-consent-form {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #f2d3d3;
    border: 1px solid #cab2b2;
    float: left;
    width: 100%;
    padding: 16px 8px;
}
.setup_wrapper.contract_wrapper .contract-consent-form label {
  float: left;
  margin: 0 0 0 10px;
}
.setup_wrapper.contract_wrapper .contract-consent-form label.without-left-margin {
  margin-left: 0px;
}
.setup_wrapper.contract_wrapper .contract-consent-form .input-wrapper {
  margin: 10px 0;
}
.setup_wrapper.contract_wrapper .contract-consent-form .input-wrapper input {
  width: 90%;
  clear: both;
  margin: 5px 0 0 0;
}
.setup_wrapper.contract_wrapper .contract-consent-form .input-wrapper input.left-aligned {
  float: left;
}
.setup_wrapper.contract_wrapper .contract-consent-form .input-wrapper strong {
  float: left;
  margin: 5px 10px 0 0;
}
.setup_wrapper.contract_wrapper .contract-consent-form #terms-conditions {
  margin: -1px 0 0 6px;
}
.setup_wrapper.payment_wrapper .btn {
  color: #ffffff;
}
.setup_wrapper .account_settings_wrapper .span12 label {
  display: inline-block;
}
.setup_wrapper .invoice_template_wrapper {
  position: static;
}
.setup_wrapper .invoice_template_wrapper .fixed-height {
  position: static;
}
.setup_wrapper .invoice_template_wrapper .fixed-height .title {
  margin: 0;
  position: relative;
}
.setup_wrapper .invoice_template_wrapper .fixed-height .row-fluid {
  padding-left: 0px;
  height: 400px;
  overflow: hidden;
}
.setup_wrapper .invoice_template_wrapper .fixed-height .row-fluid .span2 {
  height: 100%;
}
.setup_wrapper .invoice_template_wrapper .fixed-height .row-fluid .template_preview_wrapper {
  padding-top: 20px;
}
.amount,
.contract-duration {
  margin-left: -12px;
}
.amount.mac-align,
.contract-duration.mac-align {
  margin-left: 0;
}
hr {
  clear: both;
  float: left;
  width: 100%;
}
#client-numbers,
#client-bank-details,
#confirm-import-data,
#client-import-data,
#client-setup-users,
#account-number,
#account-number,
#job-number,
#estimate-number,
#invoice-number,
#upload-company-logo,
#upload-logo,
#invoice-template,
#setup-smtp,
#default-email-footer,
#estimate-default-email,
#job-default-email,
#invoice-default-email,
#postcode-lookup,
#sms-messaging {
  display: none;
}
.requirements-list {
  margin: 12px 0 18px 40px;
  color: #505b65;
}
.requirements-list li {
  margin: 0 0 10px 0;
}
.inline_checkbox {
  width: 12px !important;
  margin: 9px !important;
}
.help_section {
  background: #ffffff;
  border-bottom: 1px solid #dadada;
  margin: -20px 0 0 0;
  padding: 20px 30px 10px;
  color: #60a369;
}
.help_section.under_tabs {
  margin: 0;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid #dadada;
}
.help_section.no-margin {
  margin: 0;
}
.help_section.border-top {
  border-top: 1px solid #dadada;
}
.help_section strong {
  color: #60a369 !important;
}
.control-label.left {
  text-align: left;
}
.help-text {
  color: #60a369;
}
.help-text strong {
  color: #60a369 !important;
}
.help-text.cleared {
  clear: both;
}
.help-text.with-padding {
  padding: 0px 20px;
}
.help-text.with-arrow-on-left {
  margin: 0px 120px 45px;
}
.warning-text {
  color: #525d66;
}
.content_below_help_section {
  border-top: 1px solid #ffffff;
  padding-top: 20px;
}
.content_below_help_section.without-top-border {
  border-top: 0px;
}
.page-options {
  border-bottom: 1px solid #dadada;
}
.account_settings_wrapper .control-group input {
  /*validate-control-input should be added
				to inputs within a control input
				(allows for the validation boxes tobe responsive)*/

}
.account_settings_wrapper .control-group input.validate-control-input {
  width: 100%;
}
.account_settings_wrapper a.back {
  color: #999999;
}
.account_settings_wrapper.confirm_data_import_wrapper .confirm_data_options,
.account_settings_wrapper.company_logo_wrapper .confirm_data_options {
  margin: 0 0 12px 18px;
}
.account_settings_wrapper.confirm_data_import_wrapper .confirm_data_options a:first-of-type,
.account_settings_wrapper.company_logo_wrapper .confirm_data_options a:first-of-type {
  margin: 0 15px 0 0;
}
.account_settings_wrapper.client_setup_users_wrapper strong {
  margin: 0 0 5px 0;
  display: block;
  clear: both;
}
.account_settings_wrapper.client_setup_users_wrapper .save-user-button {
  float: right;
}
.account_settings_wrapper.client_setup_users_wrapper .setup_user_content .user-login {
  width: 269px;
  margin: 0 0 20px 0;
}
.account_settings_wrapper.client_setup_users_wrapper .setup_user_content .current_users_table {
  width: 90%;
  float: right;
}
.account_settings_wrapper.client_setup_users_wrapper .setup_user_content .current_users_table tbody {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.account_settings_wrapper.client_setup_users_wrapper .setup_user_content .current_users_table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.account_settings_wrapper.client_setup_users_wrapper .setup_user_content .current_users_table tr.just-added span {
  float: right;
  font-weight: bold;
}
.account_settings_wrapper.client_setup_users_wrapper .user_count .allowed-users {
  display: none;
}
.account_settings_wrapper.client_setup_users_wrapper .user_count p {
  margin: 0 0 20px;
  border-bottom: 1px solid #eeeeee;
  -webkit-box-shadow: 0 1px 0 #ffffff;
  -moz-box-shadow: 0 1px 0 #ffffff;
  box-shadow: 0 1px 0 #ffffff;
  float: left;
}
.account_settings_wrapper.client_setup_users_wrapper .user_count p .number-of-users-left {
  color: #d53847;
}
.account_settings_wrapper.client_setup_users_wrapper .user-access-wrapper {
  display: none;
  margin: -10px 0 12px 40px;
}
.account_settings_wrapper.default_record_numbers hr.no-top-margin {
  margin: 0;
}
.account_settings_wrapper.default_record_numbers input[type="radio"],
.account_settings_wrapper.default_record_numbers input[type="checkbox"] {
  margin: -3px 5px 0 20px;
}
.account_settings_wrapper.default_record_numbers .control-label {
  width: 180px;
}
.account_settings_wrapper.default_record_numbers .controls {
  display: inline-block;
  margin: 0 0 0 20px;
  float: left;
}
.account_settings_wrapper.default_record_numbers #job-record .control-label {
  width: 150px;
}
.account_settings_wrapper.default_record_numbers #estimate-record .control-label {
  width: 182px;
}
.account_settings_wrapper.default_record_numbers #invoice-record {
  display: block;
}
.account_settings_wrapper.default_record_numbers #invoice-record .control-label {
  width: 100px;
}
.account_settings_wrapper.smtp_wrapper .setup_email_inputs {
  padding: 12px 0 0 0;
}
.account_settings_wrapper .turn_feature_on_label {
  width: 207px;
}
.user-access-wrapper {
  float: left;
}
.user-access-wrapper span {
  width: 44%;
  float: left;
}
.user-access-wrapper select {
  float: right;
  width: 50%;
}
.user-access-wrapper .help-block {
  margin-top: 0;
}
.client_numbers_wrapper .control-label {
  width: 205px;
}
.client_numbers_wrapper .controls {
  width: 50%;
  margin: 0 0 0 20px;
  float: left;
}
.estimate-portal-checkboxes {
  float: left;
  width: 100%;
  margin: 0 0 20px 0;
}
.estimate-portal-checkboxes label {
  margin: 0 0 10px 20px;
}
.estimate-portal-checkboxes .attach-pdf-to-email {
  display: none;
}
.estimate-portal-checkboxes .attach-pdf-to-email.show {
  display: block;
}
.decalration-textarea {
  height: 200px;
}
.below-tagging-header {
  clear: both;
}
.type-of-job-address .control-label {
  width: 141px;
}
.no-access-message .confirmation-option {
  min-height: 352px;
}
.upload-logo-wrapper {
  padding-bottom: 10px;
}
.additional-section {
  border-bottom: 1px solid #dadada;
  padding: 20px 30px;
}
.additional-section .additional-control {
  margin: 15px 0 0 0;
}
.additional-section .additional-control label {
  float: left;
}
.additional-section .additional-control select {
  margin: -5px 0 0 15px;
}
.below-additional-section {
  margin-top: 1px;
  padding-top: 20px;
}
.notifications-wrapper .control-group .control-label {
  width: 272px;
}
.notifications-wrapper .control-group .controls {
  margin-left: 288px;
}
.client_import_data_wrapper fieldset {
  padding-bottom: 0;
  position: relative;
}
.client_import_data_wrapper ol {
  margin: 0 10px 10px 5px;
  border-right: 1px solid #dadada;
  -webkit-box-shadow: 1px 0 0 0 #ffffff;
  -moz-box-shadow: 1px 0 0 0 #ffffff;
  box-shadow: 1px 0 0 0 #ffffff;
}
.client_import_data_wrapper .helpful_link li {
  list-style: none !important;
}
.data-import-advanced {
  padding-bottom: 20px !important;
}
.data-import-advanced table .deleted {
  background: rgba(241, 197, 197, 0.7);
}
.company_settings .row-with-left-options {
  padding-left: 0px;
}
.company_settings .row-with-left-options ul.import_options {
  padding-top: 0px;
}
.company_settings .row-with-left-options .span9 {
  padding-top: 20px;
}
.import_panel {
  padding: 10px;
}
.import_panel .helpful_link strong {
  text-decoration: underline;
}
.import_panel .helpful_link ul {
  margin: 10px 0 10px 0;
}
.import_panel .import-button {
  margin: 16px 0 0 0;
}
.import_panel#import_steps ol {
  border-right: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.import_panel.persist {
  display: block !important;
}
#save_csv_file {
  width: 900px;
  margin: -250px 0 0 -450px;
}
.csv_table {
  overflow: auto;
  width: 870px;
  position: relative;
}
.csv_table table thead {
  background-color: #ffffff;
  background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
  background-image: -o-linear-gradient(top, #ffffff, #ffffff);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=0);
  border: 1px solid #eee;
}
.csv_table table thead th {
  width: 180px !important;
  width: 180px;
}
.csv_table table thead th .unamed {
  display: block;
}
.csv_table table thead th:last-of-type {
  border-right: 2px solid #dddddd;
}
.csv_table table tbody td {
  min-width: 217px !important;
}
.csv_table table a {
  color: #bccfd6;
  cursor: pointer;
}
.csv_table table a:hover {
  color: #fbfcfd;
}
.csv_table .deleted_message {
  display: none;
  color: #dd2626;
}
.csv_table .readd_column_link {
  display: none;
}
.csv_table .edit_column_actions {
  display: none;
}
.csv_table .edit_column_actions .name_this_column {
  display: block;
}
.csv_table .edit_column_actions select {
  display: block;
  margin: 6px 0;
  width: 100%;
}
.csv_table .edit_column_actions .edit_column_links {
  clear: both;
}
.csv_table .edit_column_actions .edit_column_links .edit_column_link {
  border-left: 1px solid #000000;
  padding: 0 5px 0 5px;
}
.job_confirm_steps {
  position: absolute;
  height: 100%;
}
.confirmation-option-wrapper {
  position: relative;
  left: 15%;
}
.import_options_wrapper {
  background: #ffffff;
  height: 100%;
  margin: 0px;
  border-bottom: none;
  border-right: 1px solid #dadada;
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}
.import_options_wrapper ul {
  margin: 0;
  overflow: auto;
  box-sizing: border-box;
}
.import_options_wrapper ul li {
  background: #ffffff;
  text-align: center;
  -webkit-box-shadow: 0px -1px 0 0 #dadada inset;
  -moz-box-shadow: 0px -1px 0 0 #dadada inset;
  box-shadow: 0px -1px 0 0 #dadada inset;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  height: auto;
  border: 0;
  border-bottom: solid 1px #d3d3d3;
  border-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px 20px;
  text-align: left;
}
.import_options_wrapper ul li:last-of-type {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.import_options_wrapper ul li:hover {
  cursor: pointer;
}
.import_options_wrapper ul li h1 {
  font-size: 28px;
  width: 200px;
  margin: 10px auto;
}
.import_options_wrapper.without-background-color {
  background: none;
}
.import_options_wrapper.without-border-radius {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.import_options_wrapper .no-results.with-top-margin {
  margin-top: 60px;
  width: 80%;
  margin-left: 10%;
}
.import_options_wrapper .compensate-for-search {
  position: absolute;
  top: 46px;
  overflow: auto;
  bottom: 0px;
  right: 1px;
    left:0;
}
.import_options_wrapper li {
  height: 146px;
  width: 83%;
  margin: 0 auto 13px auto;
  padding: 0;
  overflow: hidden;
  border: 2px solid #505b65;
}
.import_options_wrapper li .image_wrapper {
  position: relative;
  float: left;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.import_options_wrapper li .image_wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
.import_options_wrapper li:hover .image_wrapper:before {
  background: rgba(0, 0, 0, 0.3);
}
.import_options_wrapper li.active {
  cursor: default;
}
.import_options_wrapper li.active:hover {
  cursor: default;
}
.import_options_wrapper li.active .image_wrapper:before {
  background: none;
}
.import_options_wrapper.invoice_template_thumbnails {
  overflow: visible;
}
.import_options_wrapper ul li {
  clear: both;
  width: 100%;
  -webkit-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6) !important;
  -moz-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6) !important;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.6) !important;
}
.import_options_wrapper ul li .search_box {
  margin: 0px;
  padding: 0px;
}
.import_options_wrapper ul li a {
  float: left;
  margin: 7px;
}
.import_options_wrapper ul li a.ss-search {
  margin: 3px 6px 0 12px;
}
.import_options_wrapper ul li.active {
  background: #f7f7f7;
}
.import_options_wrapper ul li.option-group {
  background: #efefef;
  cursor: default;
}
.import_options_wrapper ul li.child-option {
  padding-left: 35px;
}
.import_options_wrapper .count {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: 2px solid #959ba6;
  background: #fff;
  color: #959ba6;
  font-weight: bold;
  float: left;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 2;
  margin: 0 0 0 10px;
}
.import_options_wrapper .count.ss-check {
  line-height: 32px;
  color: #7eb419;
}
.import_options_wrapper .section-title {
  background: #fdfdfd;
}
.import_options_wrapper .section-title strong {
  float: left;
  margin: 0 0 0 20px;
}
.import_options_wrapper .import_options.with-min-height {
  min-height: 400px;
}
.import_options_wrapper .import_options.with-background-color {
  background: #ffffff;
}
.input-wrapper {
  position: relative;
  display: inline-block;
}
.input-wrapper.with-bottom-margin {
  margin-bottom: 10px;
}
.input-prepend.input-fullwidth {
  position: relative;
  margin: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.input-prepend.input-fullwidth .add-on {
  width: 17px;
  z-index: 1;
  position: relative;
}
.input-prepend.input-fullwidth .input-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.input-prepend.input-fullwidth .input-wrapper {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.input-prepend.input-fullwidth .input-wrapper input {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.success-message {
  display: block;
  margin: 10px 0 0;
}
.control-error-message,
.options-error-message,
.control-success-message {
    font-size: 14px;
    margin-top: 0.25rem;
    clear: both;
}
.control-error-message span,
.options-error-message span,
.control-success-message span {
  color: #b94a48;
}
.control-error-message.show,
.options-error-message.show,
.control-success-message.show {
  visibility: visible;
}
.image-error-message {
  display: block;
  width: 100%;
  color: rgba(240, 61, 37, 0.9);
  clear: both;
}
.input-fullwidth {
  width: 100% !important;
}
.input-fullwidth .add-on {
  padding: 4px 10px;
}
.inline-error-message {
  display: none;
  float: left;
  clear: both;
  color: rgba(240, 61, 37, 0.9);
}
.inline-error-message.active {
  display: block;
}
.wysiwyg-error {
  z-index: 1;
  position: relative;
  padding-bottom: 20px !important;
}
.controls.with-error .mce-tinymce {
  padding-bottom: 10px;
}
.row-with-left-options {
  position: relative;
}
.row-with-left-options.without-padding {
  padding: 0px !important;
}
.invoice_template_wrapper,
.client_import_data_wrapper {
  position: fixed;
  top: 73px;
  bottom: 20px;
  right: 30px;
  left: 30px;
  width: auto !important;
}
.invoice_template_wrapper fieldset.data-import-advanced,
.client_import_data_wrapper fieldset.data-import-advanced {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 50px;
  top: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.invoice_template_wrapper fieldset.data-import-advanced.show-advanced-section,
.client_import_data_wrapper fieldset.data-import-advanced.show-advanced-section {
  top: 20px;
  bottom: 50px;
}
.invoice_template_wrapper fieldset.fixed-height,
.client_import_data_wrapper fieldset.fixed-height {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 50px;
  padding-bottom: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.invoice_template_wrapper fieldset.fixed-height.with-advanced-section,
.client_import_data_wrapper fieldset.fixed-height.with-advanced-section {
  bottom: 150px;
}
.invoice_template_wrapper fieldset.fixed-height.with-advanced-section.advanced-section-showing,
.client_import_data_wrapper fieldset.fixed-height.with-advanced-section.advanced-section-showing {
  opacity: 0;
}
.invoice_template_wrapper fieldset.fixed-height section.fixed-height,
.client_import_data_wrapper fieldset.fixed-height section.fixed-height,
.invoice_template_wrapper fieldset.fixed-height .row-fluid.row-with-left-options,
.client_import_data_wrapper fieldset.fixed-height .row-fluid.row-with-left-options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.invoice_template_wrapper fieldset.fixed-height section.fixed-height.row-fluid,
.client_import_data_wrapper fieldset.fixed-height section.fixed-height.row-fluid,
.invoice_template_wrapper fieldset.fixed-height .row-fluid.row-with-left-options.row-fluid,
.client_import_data_wrapper fieldset.fixed-height .row-fluid.row-with-left-options.row-fluid {
  top: 41px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding-left: 0px;
}
.invoice_template_wrapper fieldset.fixed-height section.fixed-height.row-fluid.without-border-radius,
.client_import_data_wrapper fieldset.fixed-height section.fixed-height.row-fluid.without-border-radius,
.invoice_template_wrapper fieldset.fixed-height .row-fluid.row-with-left-options.row-fluid.without-border-radius,
.client_import_data_wrapper fieldset.fixed-height .row-fluid.row-with-left-options.row-fluid.without-border-radius {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.invoice_template_wrapper fieldset.fixed-height section.fixed-height.row-fluid .notification-preferences,
.client_import_data_wrapper fieldset.fixed-height section.fixed-height.row-fluid .notification-preferences,
.invoice_template_wrapper fieldset.fixed-height .row-fluid.row-with-left-options.row-fluid .notification-preferences,
.client_import_data_wrapper fieldset.fixed-height .row-fluid.row-with-left-options.row-fluid .notification-preferences {
  height: 100%;
}
.invoice_template_wrapper fieldset.fixed-height section.fixed-height .span2,
.client_import_data_wrapper fieldset.fixed-height section.fixed-height .span2,
.invoice_template_wrapper fieldset.fixed-height .row-fluid.row-with-left-options .span2,
.client_import_data_wrapper fieldset.fixed-height .row-fluid.row-with-left-options .span2 {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow-y: auto;
  overflow-x: overlay;
}
.invoice_template_wrapper fieldset.fixed-height section.fixed-height .job_sheet_templates,
.client_import_data_wrapper fieldset.fixed-height section.fixed-height .job_sheet_templates,
.invoice_template_wrapper fieldset.fixed-height .row-fluid.row-with-left-options .job_sheet_templates,
.client_import_data_wrapper fieldset.fixed-height .row-fluid.row-with-left-options .job_sheet_templates {
  margin-left: 17%;
  overflow: hidden;
}
.invoice_template_wrapper form,
.client_import_data_wrapper form {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0px;
}
.invoice_template_wrapper form .actions,
.client_import_data_wrapper form .actions {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.invoice_template_wrapper .fixed-height-with-scroll,
.client_import_data_wrapper .fixed-height-with-scroll {
  height: 100%;
  overflow: auto;
}
.invoice_template_wrapper .import_panel,
.client_import_data_wrapper .import_panel {
  padding-top: 30px;
}
.invoice_template_wrapper ul.invoice-preview-thumbnail,
.client_import_data_wrapper ul.invoice-preview-thumbnail {
  height: auto !important;
}
.invoice_template_wrapper ul.invoice-preview-thumbnail li,
.client_import_data_wrapper ul.invoice-preview-thumbnail li {
  height: auto !important;
  border: 0;
}
.invoice_template_wrapper.settings fieldset,
.client_import_data_wrapper.settings fieldset {
  height: 100%;
}
.invoice_template_wrapper #invoice_template_explanation,
.client_import_data_wrapper #invoice_template_explanation {
  height: 326px;
}
.invoice_template_wrapper .template_preview_wrapper,
.client_import_data_wrapper .template_preview_wrapper {
  height: 100%;
}
.notification-preferences.with-search {
  position: relative;
}
.template_preview {
  visibility: hidden;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.template_preview.visible {
  display: block;
}
.template_preview #template_preview_header {
  visibility: hidden;
  padding-top: 30px;
}
.template_preview #template_preview_header.show_header {
  visibility: visible;
}
.template_preview .invoice_template {
  visibility: hidden;
  border-bottom: 0;
  overflow: auto;
  width: 96%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  position: absolute;
  top: 120%;
  right: 10px;
  left: 20px;
  bottom: 0px;
  opacity: 0;
  -webkit-transform: scale(1, 0.7);
  -moz-transform: scale(1, 0.7);
  -ms-transform: scale(1, 0.7);
  -o-transform: scale(1, 0.7);
  transform: scale(1, 0.7);
  -ms-transform-origin: bottom;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.template_preview .invoice_template.show_template {
  margin: 0;
  visibility: visible;
  top: 70px;
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.setup_wrapper .template_preview {
  display: block;
}
.invoice_template {
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border: 1px solid #cccccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.template-wrapper .invoice-record {
  margin: 20px 0;
}
.template-wrapper .invoice-record .span7,
.template-wrapper .invoice-record .span5 {
  padding-top: 16px;
  border-top: 1px solid #dadada;
}
.template-wrapper .invoice-record .span7.top-row,
.template-wrapper .invoice-record .span5.top-row {
  border-top: none;
  padding-top: 0;
}
.template-wrapper .invoice-record strong {
  color: #000000;
}
.template-wrapper .invoice-record .invoice-small-headers {
  display: block;
  margin: 0 0 10px 0;
}
.template-wrapper ul {
  margin: 0;
  list-style: none;
}
.template-wrapper ul .large-font {
  color: #000000;
  font-size: 24px;
  margin: 0 0 5px 0;
}
.template-wrapper ul .date {
  font-weight: bold;
}
.template-wrapper .company-logo img {
  width: 70%;
  height: 120px;
}
.template-wrapper table tr.no-styling {
  border-bottom: none;
  background: none;
}
.template1 {
  color: #000000;
}
.template1 .invoice-record .span7,
.template1 .invoice-record .span5 {
  border-top: 2px solid #e7e7e7;
}
.template1 ul li {
  font-size: 16px;
}
.template1 ul .large-font,
.template1 ul .date {
  color: #505b65;
}
.template1 table thead {
  background: #000000;
}
.template2 {
  border-top: 4px solid #5a9b1d;
}
.template2 table thead {
  background: #5a9b1d;
}
.template3 {
  color: #000000;
}
.template3 .invoice-record {
  padding: 0px 30px;
  margin: 0;
  border-bottom: 1px solid #000000;
}
.template3 .invoice-record:last-of-type {
  border-bottom: none;
}
.template3 .invoice-record .span7,
.template3 .invoice-record .span5 {
  padding-top: 0;
  border-top: none;
}
.template3 .invoice-record .span7.top-row,
.template3 .invoice-record .span5.top-row {
  border-top: none;
  padding-top: 20px;
}
.template3 .invoice-record .span5 {
  border-left: 1px solid #000000;
  padding-left: 20px;
  height: 160px;
  padding: 20px;
}
.template3 .invoice-record .span7 {
  padding: 20px 0;
}
.template3 .invoice-record .invoice-small-headers {
  float: left;
  border-bottom: 1px solid #000000;
}
.template3 .invoice-record ul {
  clear: both;
}
.template3 .invoice-record table {
  margin: 20px 0;
}
.template3 .invoice-record table thead {
  background: #ffffff;
}
.template3 .invoice-record table thead th {
  color: #000000;
  border-right: 1px solid #000000;
  font-weight: bold;
}
.template3 .invoice-record table tr:nth-child(odd) {
  background: none;
}
.template3 .invoice-record table td {
  border-right: 1px solid #000000;
}
.template3 .invoice-record table td.no-border {
  border-right: none;
}
.template4 .invoice-record .invoice-details {
  background: #f1fae3;
  border: 1px solid #d7ecb3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 16px;
}
.template4 .invoice-record .invoice-details .date {
  color: #73b117;
}
.template4 .invoice-record .invoice-details strong {
  font-style: italic;
  color: rgba(0, 0, 0, 0.45);
}
.template4 .invoice-record ul {
  color: #878c7f;
  font-size: 15px;
}
.template4 .invoice-record .invoice-small-headers {
  color: #504545;
  font-size: 18px;
}
.template4 .invoice-record strong {
  color: #878c7f;
}
.template4 .invoice-record table thead th {
  color: #878c7f;
  font-size: 16px;
}
.template4 .invoice-record table tr {
  background: #ffffff;
  border-bottom: 1px solid #e8f0db;
  font-size: 18px;
}
.template4 .invoice-record table tr:nth-child(odd) {
  background: #ffffff;
}
.template4 .invoice-record table tr.no-styling {
  border-bottom: none;
}
.template4 .invoice-record table td strong {
  color: #76b617;
  font-size: 18px;
}
table.cruises {
  cellspacing: 0;
  border-collapse: collapse;
}
table.cruises td {
  padding: 2px 4px;
}
table.cruises tr:first-child td {
  border-top: none;
}
table.cruises td {
  overflow: hidden;
  padding: 8px 10px;
}
table.cruises td.relative {
  position: relative;
}
table.cruises td.relative .just-added {
  top: 0px;
}
div.scrollableContainer {
  position: relative;
  width: 100%;
  padding-top: 34px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
div.scrollingArea {
  height: 211px;
  overflow: auto;
}
table.scrollable thead tr {
  left: -1px;
  top: 0;
  position: absolute;
}
.fixed-header {
  background: #6393b5;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 34px;
  color: white;
  font-weight: bold;
  line-height: 33px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.fixed-header .table-header {
  margin-left: 11px;
  font-weight: 500;
  letter-spacing: 1px;
}
.payment_wrapper strong {
  text-decoration: underline;
  display: block;
}
.payment_information {
  position: relative;
}
.payment_information strong {
  margin: 10px 0 0 30px;
}
.payment_information ul {
  margin: 0;
}
.payment_information .bullet_points {
  margin: 10px 0 0 25px;
}
.payment_information .bullet_points li {
  list-style: disc;
}
.payment_information .info_block {
  padding: 15px 30px;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #ffffff;
}
.payment_information .info_block:first-of-type {
  border-top: none;
  padding-top: 10px;
}
.payment_information .info_block:last-of-type {
  border-bottom: none;
}
.payment_information .info_block .edit_email_link {
  display: block;
  margin: 10px 0 0 0;
}
.payment_information .info_block .edit_email_inputs {
  display: none;
  margin: 10px 0 0 0;
}
.payment_information .info_block .edit_email_inputs .btn {
  margin: 10px 0 0;
}
.payment_details strong {
  margin: 0 0 12px 0;
}
.payment_details input {
  margin: 0 0 10px 0;
}
.payment_details input[type="radio"] {
  margin-top: 3px;
}
.payment_details #cvv_number input {
  width: 20%;
}
.payment_details #cvv_number .info-helper {
  width: 40%;
  float: right;
  margin: -3px 58px 0 0;
}
.payment_details #expiry_date_block input {
  width: 20%;
}
.payment_details #expiry_date_block input#year {
  margin-left: 6px;
}
#new_payment_address_modal .new_payment_address_details {
  padding: 18px 0 0 0;
  margin-left: -22px;
}
.info-helper {
  color: #72818f;
  font-size: 12px;
  font-style: italic;
}
.dropzone:hover {
  background: rgba(0, 0, 0, 0.05);
}
.dropzone .default.message {
  background: none;
  width: 386px;
  height: 34px;
  margin-left: -193px;
  margin-top: -61.5px;
  top: 64%;
  left: 50%;
}
.dropzone .default.message:after {
  font-size: 34px;
  content: 'Click here to upload a file';
}
#upload_image_options {
  display: none;
}
#upload_image_options a {
  color: #3e88cc;
}
.dropzone-wrapper {
  border: 1px dashed #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: 0 0 12px 0;
}
.dropzone-wrapper .dropzone {
  border: none;
}
.dropzone-wrapper .image-preview.old-image {
  display: none;
}
.dropzone-wrapper.preview-image {
  border: none;
}
.dropzone-wrapper.preview-image .success-mark,
.dropzone-wrapper.preview-image .error-mark,
.dropzone-wrapper.preview-image .progress,
.dropzone-wrapper.preview-image .size,
.dropzone-wrapper.preview-image .default.message {
  display: none;
}
.dropzone-wrapper.whole-page-dropzone {
  border: none;
  position: relative;
}
.dropzone-wrapper.whole-page-dropzone .image-dragged-message {
  display: none;
  font-size: 50px;
  color: #3b7aa6;
  position: absolute;
  left: 50%;
  margin-left: -114.5px;
  top: 50%;
  margin-top: -8px;
}
.dropzone-wrapper.with-plus-icon .default.message {
  background: url("../images/dropzone-add-2.png") no-repeat;
  text-align: center;
}
.dropzone-wrapper.with-plus-icon .default.message:after {
  font-size: 14px;
  display: block;
}
.dropzone-wrapper.medium-dropzone {
  margin: 0;
  width: 250px;
  height: 120px;
}
.dropzone-wrapper.medium-dropzone .dropzone {
  min-height: 120px;
  margin: 0;
}
.dropzone-wrapper.medium-dropzone .dropzone .default.message {
  width: 120px;
  height: 90px;
  background-position: center 15px;
  margin: 0 0 0 -60px;
  top: 10px;
}
.dropzone-wrapper.medium-dropzone .dropzone .default.message:after {
  content: 'Upload your Logo';
  margin-top: 80px;
}
.upload-logo-error {
  display: none;
  color: #ee2324;
}
.save_logo_btn {
  display: none;
}
.profile_image_wrapper {
  background: #ffffff;
  border-bottom: 1px solid #dadada;
  margin-top: -20px;
}
.profile_image_wrapper .upload_profile_image_wrapper {
  padding: 20px 0;
}
.profile_image_wrapper h3 {
  margin: 4px;
}
.profile_image {
  padding: 16px 0;
}
.profile_image.without_padding {
  padding: 0;
}
.profile_image .image_wrapper {
    float: left;
    width: 110px;
    height: 110px;
    border: 1px solid #c9c9c9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 3px 3px 3px 3px;
    -moz-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    margin-right: 1rem;
}
.profile_image .avatar {
  width: 100px;
  height: 100px;
  margin: 0;
  border: 1px solid;
  padding: 4px;
  background: #ffffff;
  border-radius: 3px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.profile_image .avatar .no-avatar {
  padding: 0;
  font-size: 74px;
  float: none;
  text-align: center;
  margin: 0;
  background: #e6e3e3;
  float: left;
  line-height: 124px;
  width: 100px;
  height: 100px;
}
.profile_image ul {
  list-style: none;
}
.profile_image.small .image_wrapper {
  width: 75px;
  height: 75px;
}
.profile_image.small .avatar,
.profile_image.small .no-avatar {
  width: 65px;
  height: 65px;
}
.profile_image.small .no-avatar {
  font-size: 46px;
  line-height: 78px;
}
.profile_image.small.no-padding {
  padding: 0;
}
.profile_image.extra_small .image_wrapper {
  width: 50px;
  height: 50px;
}
.profile_image.extra_small .avatar,
.profile_image.extra_small .no-avatar {
  width: 40px;
  height: 40px;
}
.profile_image.extra_small .no-avatar {
  font-size: 30px;
  line-height: 57px;
}
.profile_image.extra_small.no-padding {
  padding: 0;
}
.profile_image.extra_extra_small .image_wrapper {
  width: 35px;
  height: 35px;
}
.profile_image.extra_extra_small .avatar,
.profile_image.extra_extra_small .no-avatar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px;
}
.profile_image.extra_extra_small .avatar {
  width: 33px;
  height: 33px;
}
.profile_image.extra_extra_small .no-avatar {
  width: 29px;
  height: 29px;
  font-size: 22px;
  line-height: 38px;
}
.profile_image.extra_extra_tiny .image_wrapper {
  width: 27px;
  height: 27px;
}
.profile_image.extra_extra_tiny .avatar,
.profile_image.extra_extra_tiny .no-avatar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2px;
}
.profile_image.extra_extra_tiny .avatar {
  width: 24px;
  height: 24px;
}
.profile_image.extra_extra_tiny .no-avatar {
  width: 21px;
  height: 21px;
  font-size: 14px;
  line-height: 25px;
}
.profile_image.with-left-border .image_wrapper {
  margin: 0px;
  background: #ffffff;
}
.profile_image.with-left-border .image_wrapper .avatar {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#upload_profile_image,
#attach_file_dropzone {
  background: transparent;
  padding: 0 !important;
  border: 2px dashed transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#upload_profile_image .default.message,
#attach_file_dropzone .default.message {
  margin: 0;
  top: 112px;
  left: 157px;
}
#upload_profile_image .default.message:after,
#attach_file_dropzone .default.message:after {
  content: 'Change Photo';
  font-size: 14px;
}
#upload_profile_image .default.message.upload-image,
#attach_file_dropzone .default.message.upload-image {
  display: none;
}
#upload_profile_image .lightbox-content img,
#attach_file_dropzone .lightbox-content img {
  float: left;
  width: 70px;
  height: 70px;
}
#upload_profile_image .image-preview,
#attach_file_dropzone .image-preview {
  border: 0;
  padding: 0;
  margin: 0;
}
#upload_profile_image .image-preview .details .size,
#attach_file_dropzone .image-preview .details .size {
  display: none;
}
#upload_profile_image .image-preview .progress,
#attach_file_dropzone .image-preview .progress {
  top: 94px;
}
#upload_profile_image .preview,
#attach_file_dropzone .preview {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  top: 63px;
  left: 35px;
}
#upload_profile_image .preview .filename,
#attach_file_dropzone .preview .filename {
  display: none;
}
#upload_profile_image.started .upload-image,
#attach_file_dropzone.started .upload-image {
  display: block;
  position: absolute;
  padding: 6px 0 !important;
  width: 118px !important;
}
#upload_profile_image.started .upload-image span,
#attach_file_dropzone.started .upload-image span {
  display: none;
}
#upload_profile_image.started .default.message:after,
#attach_file_dropzone.started .default.message:after {
  content: 'Change Photo';
}
#upload_profile_image.image-dragged,
#attach_file_dropzone.image-dragged {
  -webkit-box-shadow: 0 0 4px 2px #3b7aa6;
  -moz-box-shadow: 0 0 4px 2px #3b7aa6;
  box-shadow: 0 0 4px 2px #3b7aa6;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 20px;
}
#upload_profile_image.image-dragged fieldset,
#attach_file_dropzone.image-dragged fieldset {
  margin: 0;
}
#upload_profile_image .image-success-message,
#attach_file_dropzone .image-success-message {
  color: #46a546;
  display: none;
  margin-top: 6px;
  float: left;
}
#upload_profile_image .image-error-message,
#attach_file_dropzone .image-error-message {
  color: #d53847;
  margin-top: 6px;
  display: none;
  float: left;
}
#attach_file_dropzone {
  background-color: #ffffff;
}
#attach_file_dropzone .default.message {
  width: 100%;
  text-align: center;
  left: 0;
  top: 150px;
}
#attach_file_dropzone .default.message:after {
  content: 'Upload Supplier Invoice';
  font-size: 14px;
}
.user_profile_details {
  border-top: 1px solid #ffffff;
  padding: 25px 20px 0;
}
.large-profile-wrapper {
  overflow: hidden;
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  border-radius: 70px;
  width: 68px;
  height: 68px;
  border: solid 1px #999999;
  background: #ffffff;
}
.large-profile-wrapper .no-avatar {
  width: 70px;
  height: 70px;
  font-size: 50px;
  line-height: 100px;
  display: inline-block;
  text-align: center;
}
.change_password_wrapper {
  max-width: 550px;
}
.change_password_wrapper .control-label {
  width: 160px;
}
.change_password_wrapper .controls {
  margin-left: 180px;
}
.customer_creditor_days .filter-wrapper {
  float: left;
  margin: 0 0 0 12px;
}
.customer_creditor_days .table_actions .edit-group-of-customers-active {
  display: none;
}
.customer_creditor_days .table_actions .btn {
  float: right;
}
.customer_creditor_days .search_table {
  border-bottom: 0;
  margin: 0;
}
.customer_creditor_days .search_table .search_box {
  width: 224px;
}
.customer_creditor_days .search_table .search_box input {
  width: 170px;
}
.customer_creditor_days .filter_links {
  border-bottom: 0 !important;
}
.customer_creditor_days .filter_links li {
  display: block;
}
.customer_creditor_days .filter_links li a {
  float: left;
  border: 0;
}
.customer_creditor_days .filter_links li a:hover {
  border: 0;
}
.customer_creditor_days .filter_links li select {
  height: 26px;
  margin: 0 10px;
}
.customer_creditor_days .filter_links li select:last-of-type {
  margin-right: 0;
}
.customer_creditor_days table td .inline-edit {
  float: right;
}
.customer_creditor_days table td .inline-input {
  display: none;
  width: 126px;
  height: 26px;
  margin: 0;
  float: left;
}
.customer_creditor_days table td .inline-save {
  display: none;
  float: left;
  margin: 0 0 0 10px;
}
.customer_creditor_days table td .inline-cancel {
  display: none;
  float: left;
  margin: 3px 0 0 8px;
}
.customer_creditor_days table tr td:last-child {
  width: 31%;
}
.customer_creditor_days table tr.active .current-creditor-day,
.customer_creditor_days table tr.active .default-label {
  display: none;
}
.customer_creditor_days table tr.active .inline-input,
.customer_creditor_days table tr.active .inline-save,
.customer_creditor_days table tr.active .inline-cancel {
  display: block;
}
.customer_creditor_days table tr.active .inline-edit {
  margin: 3px 0 0 0;
}
.customer_creditor_days table tr.active td:last-child {
  width: 31%;
}
.customer_creditor_days table tr.active td {
  padding: 5px 10px;
}
.tbody-placeholder {
  width: 100%;
  height: 90px;
  background-color: #aaa;
}
.spreadsheet-wrapper {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative;
}
.spreadsheet-wrapper.spreadsheet-fullscreen {
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  padding-bottom: 57px;
  background: #ffffff;
  border-radius: 0;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section {
  height: 100%;
  overflow: auto;
  padding: 0 10px 10px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section .collapse-spreadsheet hr,
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section .collapse-spreadsheet .icon,
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section .collapse-spreadsheet .more-btn.opened {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.in-fullscreen-mode {
  border: 0px;
  border-top: solid 1px transparent;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.in-fullscreen-mode .section-title {
  z-index: 1;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.in-fullscreen-mode-with-sidepanel {
  margin: 0 46px 0 0;
  padding: 0;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.in-fullscreen-mode-with-sidepanel .collapse-spreadsheet hr,
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.in-fullscreen-mode-with-sidepanel .collapse-spreadsheet .icon,
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.in-fullscreen-mode-with-sidepanel .collapse-spreadsheet .more-btn.opened {
  margin-top: 5px;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.sidepanel-in-view {
  margin-right: 0px;
}
.spreadsheet-wrapper.spreadsheet-fullscreen .data-section.sidepanel-in-view .download_options {
  right: 596px !important;
}
.spreadsheet-wrapper.with-scroll .scrollable-wrapper {
  overflow-x: auto;
  overflow-y: visible;
  border-right: 10px solid #ffffff;
  border-left: 10px solid #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 10px 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper.with-scroll .scrollable-wrapper.in-fullscreen-mode {
  border-right: 0px;
  position: absolute;
  top: 0px !important;
  right: 10px;
  left: 0px;
  bottom: 0px;
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 46px;
}
.spreadsheet-wrapper.with-scroll .scrollable-wrapper.in-fullscreen-mode.without-grand-totals {
  bottom: 0px !important;
}
.spreadsheet-wrapper.with-scroll .scrollable-wrapper.in-fullscreen-mode-with-sidepanel {
  right: 15px;
}
.spreadsheet-wrapper.with-scroll .scrollable-wrapper.in-fullscreen-mode-with-sidepanel.sidepanel-in-view {
  right: 608px;
}
.spreadsheet-wrapper.with-bottom-padding .scrollable-wrapper {
  padding-bottom: 140px;
}
.spreadsheet-wrapper.with-filter {
  margin-top: -1px;
}
.spreadsheet-wrapper .scrollable-wrapper {
  clear: both;
  padding: 10px 10px 10px 10px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section {
  height: 100%;
  padding: 0;
  -webkit-transition: transform 0.25s ease-in-out;
  -moz-transition: transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  position: relative;
}
.spreadsheet-wrapper .spreadsheet-section.data-section.in-fullscreen-mode {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title {
  margin: 0;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .section-btns {
  margin: 0 10px 0 0;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .fullscreen-icon {
  margin: 0 6px 0 0;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title {
  float: left;
  width: 100%;
  padding: 11px 10px 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px #fdfdfd inset;
  -moz-box-shadow: 0px 1px 0px #fdfdfd inset;
  box-shadow: 0px 1px 0px #fdfdfd inset;
  background: #fcfcfc;
  border-bottom: 1px solid #efefef;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title h3 {
  font-size: 18px;
  line-height: 15px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .help-block {
  float: left;
  margin: -3px 0 0 10px;
  clear: none;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .open-controls {
  float: left;
  font-size: 12px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .open-controls a {
  margin: -1px 0 0px 0;
  padding-bottom: 1px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .open-controls.in-fullscreen {
  width: 0;
  height: 20px;
  visibility: hidden;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .download_options {
  float: right;
  margin: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  right: 0px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .download_options .icon {
  font-size: 15px;
  margin: 0 2px 0 12px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .download_options .icon:hover {
  cursor: pointer;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .edit-buttons {
  float: right;
  margin: -3px 0 0 9px;
  border-left: 1px solid #c9c9c9;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .edit-buttons a {
  float: none;
  margin: 0 0 0 10px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .edit-buttons #cancel-panel-btn {
  float: right;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .icon {
  float: left;
  margin: 0 0 0 15px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title strong {
  color: #677077;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .with-edit h3 {
  margin: 0 0 0 10px;
  line-height: 15px;
}
.spreadsheet-wrapper .spreadsheet-section.data-section .section-title .with-edit a {
  margin: -2px 0 0 10px;
}
.spreadsheet-wrapper .fullscreen-actions {
  position: fixed;
  right: 0px;
  left: 0px;
  background: #fcfcfc;
  border-top: 1px solid #efefef;
  bottom: -1000px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper .fullscreen-actions a {
  float: right;
  margin: 15px 13px;
}
.spreadsheet-wrapper .fullscreen-actions.fullscreen-mode {
  bottom: 0px;
}
.spreadsheet-wrapper .fullscreen-actions.compensate-for-sidepanel {
  right: 47px;
  padding-right: 2px;
}
.spreadsheet-wrapper .fullscreen-actions.compensate-for-sidepanel.sidepanel-in-view {
  right: 593px;
}
.spreadsheet-wrapper .open-panel {
  position: relative;
  float: right;
}
.spreadsheet-wrapper .spreadsheet-sidepanel {
  z-index: 100;
  height: 100%;
  position: fixed;
  top: 0;
  padding-right: 0px;
  width: 0;
  opacity: 0;
  right: -1000px;
  background: #fcfcfc;
  border-left: 1px solid #efefef;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper .spreadsheet-sidepanel.fullscreen-mode {
  position: absolute;
  width: 596px;
  right: -550px;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.spreadsheet-wrapper .spreadsheet-sidepanel.sidepanel-in-view {
  right: 0px;
  overflow: auto;
  -webkit-box-shadow: 5px 0px 21px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 0px 21px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 0px 21px rgba(0, 0, 0, 0.5);
  border-left: 0px solid transparent;
}
.spreadsheet-wrapper .spreadsheet-sidepanel.sidepanel-in-view .spreadsheet-sidepanel-main {
  margin-left: 0px;
  opacity: 1;
}
.spreadsheet-wrapper .spreadsheet-sidepanel #page-panel-title-bar {
  border-bottom: 1px solid #eeeeee;
}
.spreadsheet-wrapper .spreadsheet-sidepanel #page-panel-title-bar h3 {
  font-size: 18px;
  line-height: 16px;
  float: left;
  padding: 0 0 0 10px;
}
.spreadsheet-wrapper .spreadsheet-sidepanel img {
  z-index: 10;
  position: relative;
}
.spreadsheet-wrapper .spreadsheet-sidepanel .right-side {
  float: right;
  margin: 8px 12px;
}
.spreadsheet-wrapper .spreadsheet-sidepanel .right-side .ss-delete {
  position: relative;
  cursor: pointer;
  top: 11px;
}
.spreadsheet-wrapper .spreadsheet-sidepanel .menu-hamburger {
  margin: 8px 17px 8px;
  cursor: pointer;
  font-size: 15px;
}
.spreadsheet-wrapper .spreadsheet-sidepanel .menu-hamburger .ss-list {
  position: relative;
  top: 3px;
}
.spreadsheet-wrapper .spreadsheet-sidepanel .spreadsheet-sidepanel-main {
  padding: 46px 13px 10px 10px;
  margin-left: 40px;
  opacity: 0;
}
.spreadsheet-wrapper .spreadsheet-sidepanel .spreadsheet-sidepanel-main .row-fluid {
  padding: 0;
}
.spreadsheet-wrapper #supplier-credit-note .spreadsheet-panel-image-container {
  width: 100%;
  height: auto;
  border: solid 2px #e1e1e1;
}
.spreadsheet-wrapper #supplier-credit-note .spreadsheet-panel-image-container .placeholder-image {
  font-size: 50px;
}
.spreadsheet-wrapper #supplier-credit-note #attach_file_dropzone .default.message:after {
  content: 'Upload Supplier Credit Note';
}
.spreadsheet-wrapper #supplier-credit-note .spreadsheet-panel-image-container {
  width: 100%;
  height: auto;
  border: solid 2px #e1e1e1;
}
.spreadsheet-wrapper #supplier-credit-note .spreadsheet-panel-image-container .placeholder-image {
  font-size: 50px;
}
.spreadsheet-wrapper #supplier-credit-note #attach_file_dropzone .default.message:after {
  content: 'Upload Supplier Invoice';
}
.spreadsheet-wrapper .dropzone-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -5;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.spreadsheet-wrapper.add_dropzone_tint .dropzone-overlay {
  opacity: 1;
  z-index: 5;
}
.spreadsheet-loading table {
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.spreadsheet-table thead {
  background-color: #ffffff;
  background-image: -moz-linear-gradient(top, #ffffff, #ffffff);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#ffffff));
  background-image: -webkit-linear-gradient(top, #ffffff, #ffffff);
  background-image: -o-linear-gradient(top, #ffffff, #ffffff);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffffffff', GradientType=0);
}
.spreadsheet-table thead th {
  padding: 5px 10px;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0;
  background: #fbfbfb;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}
.spreadsheet-table thead th.with-filter .filter-icon {
  color: #000000;
}
.spreadsheet-table thead th.with-filter .filter-icon:hover {
  color: #000000;
}
.spreadsheet-table thead th.number-column {
  width: 17px;
  min-width: 17px;
  border-top: 0;
  border-left: 0;
  background: transparent;
}
.spreadsheet-table thead th.status-column {
  width: 17px;
  min-width: 17px;
  position: relative;
}
.spreadsheet-table thead th.status-column .ss-record {
  position: absolute;
  font-size: 20px;
  top: 8px;
  color: #797979;
  left: 9px;
}
.spreadsheet-table thead th.status-column .pound-symbol {
  color: #fbfbfb;
  position: absolute;
  left: 6px;
  top: -2px;
  font-size: 13px;
  font-weight: normal;
}
.spreadsheet-table thead th span.select-all-rows input {
  position: relative;
  top: -2px;
  left: 4px;
}
.spreadsheet-table thead th.col-axis-active {
  background: #ececec;
}
.spreadsheet-table thead th.hidden-cell {
  display: none;
}
.spreadsheet-table thead th.not-shown-to-user .header-text {
  opacity: 0.4;
}
.spreadsheet-table thead th.not-shown-to-user .ss-ban {
  float: right;
  margin: 2px 0 -3px 0;
  font-size: 16px;
  color: #60a369;
}
.spreadsheet-table thead th .ss-merge {
  float: left;
  margin: 6px 0 0 2px;
  font-size: 16px;
  line-height: 16px;
  color: #797979;
}
.spreadsheet-table thead .type-column {
  width: 200px;
}
.spreadsheet-table thead .delete-todo-column {
  width: 55px;
}
.spreadsheet-table tbody.active-tbody td {
  background: #fdfbf2;
}
.spreadsheet-table tbody.active-tbody tr.selected-row td {
  background: #d8e8f4 !important;
}
.spreadsheet-table tbody td {
  padding: 0;
  border: 1px solid #e1e1e1;
}
.spreadsheet-table tbody td:hover {
  cursor: default;
}
.spreadsheet-table tbody td.cell-without-border {
  background: rgba(0, 0, 0, 0.02);
}
.spreadsheet-table tbody td.cell-without-border:hover {
  cursor: not-allowed;
}
.spreadsheet-table tbody td.cell-without-bottom-border {
  border-bottom-color: transparent;
}
.spreadsheet-table tbody td.status-cell {
  position: relative;
  text-align: center;
}
.spreadsheet-table tbody td.status-cell .icon {
  position: relative;
  top: 12px;
  cursor: help;
}
.spreadsheet-table tbody td.cell-with-green-bg {
  background: #e4f5e5;
}
.spreadsheet-table tbody td.cell-with-red-bg {
  background: #f2cfcf;
}
.spreadsheet-table tbody td.cell-with-bold-text {
  font-weight: bold;
}
.spreadsheet-table tbody td.cell-with-help-text {
  background: #e4f5e5;
}
.spreadsheet-table tbody td.cell-with-warning-text {
  background: #f2cfcf;
}
.spreadsheet-table tbody td.hidden-cell {
  display: none;
}
.spreadsheet-table tbody td.not-shown-to-user {
  opacity: 0.4;
}
.spreadsheet-table tbody td .cell-container {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 8px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.spreadsheet-table tbody td .cell-container ul {
  position: relative;
  margin: 0;
  list-style: none;
}
.spreadsheet-table tbody td .cell-container strong {
  color: #505b65;
  float: left;
}
.spreadsheet-table tbody td.invalid-cell-value .cell-container {
  border-color: #d25656 !important;
}
.spreadsheet-table tbody td.invalid-cell-value .autocomplete-wrap {
  outline: 2px solid #d25656 !important;
}
.spreadsheet-table tbody td.invalid-cell-value .ss-dropdown,
.spreadsheet-table tbody td.invalid-cell-value .ss-help {
  color: #d25656 !important;
}
.spreadsheet-table tbody td.validation-failed-on-server .cell-container {
  border-color: #d25656;
}
.spreadsheet-table tbody td.active-mode .cell-container {
  border-color: #50a69a;
}
.spreadsheet-table tbody td.active-mode .ss-dropdown,
.spreadsheet-table tbody td.active-mode .ss-help {
  color: #50a69a;
}
.spreadsheet-table tbody td.active-mode .ss-dropdown {
  border-color: #50a69a;
}
.spreadsheet-table tbody td.active-mode .invalid-reason-tag {
  border-color: #50a69a;
  border-bottom-color: transparent;
}
.spreadsheet-table tbody td.edit-mode {
  background: #ffffff;
}
.spreadsheet-table tbody td.edit-mode .cell-container {
  z-index: 1;
  height: 40px;
  border-color: #4387fd;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.spreadsheet-table tbody td.edit-mode .ss-dropdown,
.spreadsheet-table tbody td.edit-mode .ss-help {
  color: #4387fd;
}
.spreadsheet-table tbody td.edit-mode .ss-dropdown {
  border-color: #4387fd;
}
.spreadsheet-table tbody td.edit-mode .ss-dropdown.brought-forward {
  z-index: 101;
}
.spreadsheet-table tbody td.invalid-up .cell-container {
  border-top-color: #d25656;
}
.spreadsheet-table tbody td.invalid-down .cell-container {
  border-bottom-color: #d25656;
}
.spreadsheet-table tbody td.invalid-left .cell-container {
  border-left-color: #d25656;
}
.spreadsheet-table tbody td.invalid-right .cell-container {
  border-right-color: #d25656;
}
.spreadsheet-table tbody td .invalid-reason-tag {
  position: absolute;
  top: -30px;
  left: 10px;
  padding: 4px 8px 2px;
  border: 2px solid #d25656;
  border-bottom-color: transparent;
  background: #f2d3d3;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  font-size: 12px;
  text-shadow: -1px 0px 0px rgba(255, 255, 255, 0.4);
}
.spreadsheet-table tbody tr {
  border: 0;
}
.spreadsheet-table tbody tr.row-axis-active .number-cell {
  background: #ececec;
}
.spreadsheet-table tbody tr:nth-child(odd) {
  background: #ffffff;
}
.spreadsheet-table tbody tr:hover {
  background: #ffffff;
}
.spreadsheet-table tbody tr.active-spreadsheet-row {
  background: #fdfbf2;
}
.spreadsheet-table tbody tr.selected-row {
  background: #d8e8f4 !important;
}
.spreadsheet-table tbody tr.selected-row td {
  background-color: transparent;
}
.spreadsheet-table tbody tr.disabled-row {
  pointer-events: none;
}
.spreadsheet-table tbody tr.row-with-associations,
.spreadsheet-table tbody tr.associated-row {
  border-bottom: 0;
}
.spreadsheet-table tbody tr.row-with-associations td.active-mode .cell-container,
.spreadsheet-table tbody tr.associated-row td.active-mode .cell-container {
  border: 2px solid #50a69a;
}
.spreadsheet-table tbody tr.row-with-associations td.invalid-up .cell-container,
.spreadsheet-table tbody tr.associated-row td.invalid-up .cell-container {
  border-top-color: #d25656 !important;
}
.spreadsheet-table tbody tr.row-with-associations td.invalid-down .cell-container,
.spreadsheet-table tbody tr.associated-row td.invalid-down .cell-container {
  border-bottom-color: #d25656 !important;
}
.spreadsheet-table tbody tr.row-with-associations td.invalid-left .cell-container,
.spreadsheet-table tbody tr.associated-row td.invalid-left .cell-container {
  border-left-color: #d25656 !important;
}
.spreadsheet-table tbody tr.row-with-associations td.invalid-right .cell-container,
.spreadsheet-table tbody tr.associated-row td.invalid-right .cell-container {
  border-right-color: #d25656 !important;
}
.spreadsheet-table tbody tr.row-with-associations td.edit-mode .cell-container,
.spreadsheet-table tbody tr.associated-row td.edit-mode .cell-container {
  border: 2px solid #4387fd;
}
.spreadsheet-table tbody tr.row-with-associations td:nth-child(2) .cell-container,
.spreadsheet-table tbody tr.associated-row td:nth-child(2) .cell-container {
  border-bottom: 0;
}
.spreadsheet-table tbody tr.row-with-associations td:nth-child(2).active-mode .cell-container,
.spreadsheet-table tbody tr.associated-row td:nth-child(2).active-mode .cell-container {
  border: 2px solid #50a69a;
}
.spreadsheet-table tbody tr.row-with-associations td:nth-child(2).edit-mode .cell-container,
.spreadsheet-table tbody tr.associated-row td:nth-child(2).edit-mode .cell-container {
  border: 2px solid #4387fd;
}
.spreadsheet-table tbody .label-cell {
  font-weight: bold;
  color: #505b65;
}
.spreadsheet-table tbody .number-cell {
  padding: 8px 0 0 0;
  text-align: center;
  background: #fbfbfb;
}
.spreadsheet-table tbody .number-cell.active-cell-helper {
  background: #ececec;
}
.spreadsheet-table tbody .checkbox-cell label {
  margin: 0;
  float: left;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.spreadsheet-table tbody .checkbox-cell input[type="checkbox"] {
  float: left;
  margin: 14px 0 0 14px;
}
.spreadsheet-table tbody .checkbox-cell.hidden-checkbox {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}
.spreadsheet-table tbody .checkbox-cell.without-bottom-border {
  border-bottom: solid 1px transparent;
}
.spreadsheet-table tbody .delete-cell a {
  float: left;
  margin: 10px;
}
.spreadsheet-table tbody .delete-cell.delete-cell-in-last-row {
  background: rgba(0, 0, 0, 0.02);
}
.spreadsheet-table tbody .delete-cell.delete-cell-in-last-row:hover {
  cursor: not-allowed;
}
.spreadsheet-table tbody .input-next-to-label {
  position: absolute;
  left: 210px;
  right: 0;
}
.spreadsheet-table tbody input[type="text"],
.spreadsheet-table tbody input[type="date"] {
  position: relative;
  width: 100%;
  margin: -2px 0 0 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0 !important;
  height: auto !important;
}
.spreadsheet-table tbody input[type="date"] {
  outline: none;
}
.spreadsheet-table tbody input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}
.spreadsheet-table tbody input[type="date"]::-webkit-clear-button {
  display: none;
  /* Hide the button */

  -webkit-appearance: none;
  /* turn off default browser styling */

}
.spreadsheet-table tbody input[type="date"]::-ms-clear {
  display: none;
  /* Hide the button */

  -webkit-appearance: none;
  /* turn off default browser styling */

}
.spreadsheet-table tbody .autocomplete-wrap {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  outline: 2px solid #4387fd;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.spreadsheet-table tbody .autocomplete-wrap .autocomplete {
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.spreadsheet-table tbody .autocomplete-wrap input[type="text"] {
  height: 40px !important;
  padding: 6px 7px 10px 36px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
  right: 26px;
}
.spreadsheet-table tbody .autocomplete-wrap ul {
  position: static;
  max-height: 290px;
  overflow: auto;
  top: -4px;
  left: 0;
  right: 0;
  margin: 0 0 -4px 0 !important;
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.spreadsheet-table tbody .autocomplete-wrap li {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
}
.spreadsheet-table tbody .autocomplete-wrap li .highlight {
  background: transparent;
  border-bottom: 2px solid #8194bd;
}
.spreadsheet-table tbody .autocomplete-wrap li:hover,
.spreadsheet-table tbody .autocomplete-wrap li.active {
  background: #f1f1f1;
}
.spreadsheet-table tbody .autocomplete-wrap li:hover .highlight,
.spreadsheet-table tbody .autocomplete-wrap li.active .highlight {
  color: #505b65;
}
.spreadsheet-table tbody .autocomplete-wrap li:hover {
  cursor: pointer;
}
.spreadsheet-table tbody .autocomplete-wrap input {
  margin: 0;
}
.spreadsheet-table tbody .autocomplete-wrap.autocomplete-next-to-label {
  left: 200px;
  right: 0;
  width: auto;
}
.spreadsheet-table tbody .autocomplete-wrap.autocomplete-next-to-label:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 6px;
  height: 36px;
  background: #ffffff;
  box-shadow: -6px 0px 0px 0px #ffffff !important;
}
.spreadsheet-table .value-cell {
  display: block;
  overflow: hidden;
}
.spreadsheet-table .value-cell.with-right-padding {
  right: 20px;
  position: relative;
  padding-left: 20px;
}
.spreadsheet-table .cell-helper {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #999999;
}
.spreadsheet-table .ss-dropdown,
.spreadsheet-table .ss-help {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 7px;
  color: #999999;
}
.spreadsheet-table .ss-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 17px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.spreadsheet-table .ss-dropdown:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.06);
}
.spreadsheet-table .required-asterix:after {
  position: absolute;
  top: 0;
  right: 2px;
  content: ' *';
  color: #d53847;
  font-size: 16px;
}
.spreadsheet-table .tooltip {
  font-weight: normal;
}
.spreadsheet-table .ss-help {
  font-size: 15px;
  cursor: default;
  right: 6px;
}
.spreadsheet-table .ss-help.ss-ban {
  color: #60a369;
}
.spreadsheet-table .add-todo-row td {
  padding: 0;
}
.spreadsheet-table .add-todo-row .number-cell {
  padding: 8px 10px;
}
.spreadsheet-table .add-todo-row input {
  margin: 0;
  padding: 8px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 2px solid transparent;
}
.spreadsheet-table .add-todo-row input:focus {
  border: 2px solid #4387fd;
}
.spreadsheet-table .add-todo-row a {
  float: left;
  width: 100%;
  padding: 8px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.data-section.with-collapse {
  margin-top: 5px;
}
.data-section.with-collapse table {
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}
.data-section.with-collapse .small-more-btn {
  position: relative;
  top: 4px;
}
.data-section.with-collapse .download_options {
  height: 21px;
}
.data-section.with-collapse .scrollable-wrapper {
  height: auto;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
table.with-merge-column td:nth-child(2),
table.with-batch-column td:nth-child(2),
table.with-merge-column th:nth-child(2),
table.with-batch-column th:nth-child(2) {
  max-width: 20px;
  min-width: 20px;
  width: 20px;
}
table.estimate_basic_no_breakdown td:nth-child(2),
table.estimate_basic_no_breakdown th:nth-child(2),
table.estimate_basic_no_breakdown td:nth-child(3),
table.estimate_basic_no_breakdown th:nth-child(3) {
  width: 50%;
}
table.estimate_basic_category_breakdown .cell-without-border {
  border-bottom: 1px solid #e1e1e1 !important;
}
table.estimate_basic_category_breakdown td:nth-child(2),
table.estimate_basic_category_breakdown th:nth-child(2),
table.estimate_basic_category_breakdown td:nth-child(3),
table.estimate_basic_category_breakdown th:nth-child(3),
table.estimate_basic_category_breakdown td:nth-child(4),
table.estimate_basic_category_breakdown th:nth-child(4) {
  width: 33%;
}
.delete-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  cursor: pointer;
}
.delete-modal-overlay.in-view {
  opacity: 1;
}
.spreadsheet-help-text {
  position: relative;
}
.spreadsheet-help-text .helper-arrow {
  position: absolute;
  bottom: 10px;
  left: 90px;
  margin-left: 0px;
  width: 100px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(333deg);
}
.spreadsheet-help-text p.help-text {
  padding-top: 15px;
}
.spreadsheet-help-text p.help-text.with-arrow {
  padding-left: 170px;
}
.new_purchase_order_parts td,
.new_job_purchase_order_parts td,
.new_job_purchase_order_parts_prepopulated td,
.edit_purchase_order_parts td,
.new_purchase_order_parts th,
.new_job_purchase_order_parts th,
.new_job_purchase_order_parts_prepopulated th,
.edit_purchase_order_parts th {
  white-space: nowrap;
}
.new_purchase_order_parts thead th:nth-child(2),
.new_job_purchase_order_parts thead th:nth-child(2),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(2),
.edit_purchase_order_parts thead th:nth-child(2) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.new_purchase_order_parts thead th:nth-child(3),
.new_job_purchase_order_parts thead th:nth-child(3),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(3),
.edit_purchase_order_parts thead th:nth-child(3),
.new_purchase_order_parts thead th:nth-child(4),
.new_job_purchase_order_parts thead th:nth-child(4),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(4),
.edit_purchase_order_parts thead th:nth-child(4),
.new_purchase_order_parts thead th:nth-child(5),
.new_job_purchase_order_parts thead th:nth-child(5),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(5),
.edit_purchase_order_parts thead th:nth-child(5) {
  min-width: 80px;
}
.new_purchase_order_parts thead th:nth-child(6),
.new_job_purchase_order_parts thead th:nth-child(6),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(6),
.edit_purchase_order_parts thead th:nth-child(6) {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}
.new_purchase_order_parts thead th:nth-child(7),
.new_job_purchase_order_parts thead th:nth-child(7),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(7),
.edit_purchase_order_parts thead th:nth-child(7) {
  min-width: 350px;
  max-width: 350px;
  width: 350px;
}
.new_purchase_order_parts thead th:nth-child(8),
.new_job_purchase_order_parts thead th:nth-child(8),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(8),
.edit_purchase_order_parts thead th:nth-child(8) {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
.new_purchase_order_parts thead th:nth-child(11),
.new_job_purchase_order_parts thead th:nth-child(11),
.new_job_purchase_order_parts_prepopulated thead th:nth-child(11),
.edit_purchase_order_parts thead th:nth-child(11) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_purchase_order_parts tbody tr td:nth-child(3),
.new_job_purchase_order_parts tbody tr td:nth-child(3),
.new_job_purchase_order_parts_prepopulated tbody tr td:nth-child(3),
.edit_purchase_order_parts tbody tr td:nth-child(3),
.new_purchase_order_parts tbody tr td:nth-child(4),
.new_job_purchase_order_parts tbody tr td:nth-child(4),
.new_job_purchase_order_parts_prepopulated tbody tr td:nth-child(4),
.edit_purchase_order_parts tbody tr td:nth-child(4),
.new_purchase_order_parts tbody tr td:nth-child(5),
.new_job_purchase_order_parts tbody tr td:nth-child(5),
.new_job_purchase_order_parts_prepopulated tbody tr td:nth-child(5),
.edit_purchase_order_parts tbody tr td:nth-child(5) {
  max-width: 80px;
}
.new_purchase_order_parts tbody strong,
.new_job_purchase_order_parts tbody strong,
.new_job_purchase_order_parts_prepopulated tbody strong,
.edit_purchase_order_parts tbody strong {
  width: 200px;
}
.new_purchase_order_items td,
.edit_purchase_order_items td,
.new_purchase_order_items th,
.edit_purchase_order_items th {
  white-space: nowrap;
}
.new_purchase_order_items thead th:nth-child(2),
.edit_purchase_order_items thead th:nth-child(2) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.new_purchase_order_items thead th:nth-child(3),
.edit_purchase_order_items thead th:nth-child(3),
.new_purchase_order_items thead th:nth-child(4),
.edit_purchase_order_items thead th:nth-child(4),
.new_purchase_order_items thead th:nth-child(5),
.edit_purchase_order_items thead th:nth-child(5) {
  min-width: 80px;
}
.new_purchase_order_items thead th:nth-child(6),
.edit_purchase_order_items thead th:nth-child(6) {
  min-width: 520px;
  max-width: 520px;
  width: 520px;
}
.new_purchase_order_items thead th:nth-child(7),
.edit_purchase_order_items thead th:nth-child(7) {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
.new_purchase_order_items thead th:nth-child(8),
.edit_purchase_order_items thead th:nth-child(8) {
  min-width: 150px;
}
.new_purchase_order_items thead th:nth-child(9),
.edit_purchase_order_items thead th:nth-child(9) {
  min-width: 150px;
}
.new_purchase_order_items thead th:nth-child(10),
.edit_purchase_order_items thead th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_purchase_order_items tbody strong,
.edit_purchase_order_items tbody strong {
  width: 200px;
}
.complete_purchase_order_items td,
.complete_purchase_order_parts td,
.complete_purchase_order_items th,
.complete_purchase_order_parts th {
  white-space: nowrap;
}
.complete_purchase_order_items thead th:nth-child(2),
.complete_purchase_order_parts thead th:nth-child(2) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.complete_purchase_order_items thead th:nth-child(3),
.complete_purchase_order_parts thead th:nth-child(3),
.complete_purchase_order_items thead th:nth-child(4),
.complete_purchase_order_parts thead th:nth-child(4),
.complete_purchase_order_items thead th:nth-child(5),
.complete_purchase_order_parts thead th:nth-child(5) {
  min-width: 80px;
}
.complete_purchase_order_items thead th:nth-child(6),
.complete_purchase_order_parts thead th:nth-child(6) {
  min-width: 520px;
  max-width: 520px;
  width: 520px;
}
.complete_purchase_order_items thead th:nth-child(7),
.complete_purchase_order_parts thead th:nth-child(7) {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
.complete_purchase_order_items thead th:nth-child(8),
.complete_purchase_order_parts thead th:nth-child(8) {
  min-width: 150px;
}
.complete_purchase_order_items thead th:nth-child(9),
.complete_purchase_order_parts thead th:nth-child(9) {
  min-width: 150px;
}
.complete_purchase_order_items thead th:nth-child(10),
.complete_purchase_order_parts thead th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.complete_purchase_order_items tbody strong,
.complete_purchase_order_parts tbody strong {
  width: 200px;
}
.new_supplier_invoice_items td,
.edit_supplier_invoice_items td,
.new_supplier_invoice_parts td,
.edit_supplier_invoice_parts td,
.complete_purchase_order_items td,
.complete_purchase_order_parts td,
.new_supplier_invoice_items th,
.edit_supplier_invoice_items th,
.new_supplier_invoice_parts th,
.edit_supplier_invoice_parts th,
.complete_purchase_order_items th,
.complete_purchase_order_parts th {
  white-space: nowrap;
}
.new_supplier_invoice_items thead th:nth-child(2),
.edit_supplier_invoice_items thead th:nth-child(2),
.new_supplier_invoice_parts thead th:nth-child(2),
.edit_supplier_invoice_parts thead th:nth-child(2),
.complete_purchase_order_items thead th:nth-child(2),
.complete_purchase_order_parts thead th:nth-child(2) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.new_supplier_invoice_items thead th:nth-child(3),
.edit_supplier_invoice_items thead th:nth-child(3),
.new_supplier_invoice_parts thead th:nth-child(3),
.edit_supplier_invoice_parts thead th:nth-child(3),
.complete_purchase_order_items thead th:nth-child(3),
.complete_purchase_order_parts thead th:nth-child(3),
.new_supplier_invoice_items thead th:nth-child(4),
.edit_supplier_invoice_items thead th:nth-child(4),
.new_supplier_invoice_parts thead th:nth-child(4),
.edit_supplier_invoice_parts thead th:nth-child(4),
.complete_purchase_order_items thead th:nth-child(4),
.complete_purchase_order_parts thead th:nth-child(4),
.new_supplier_invoice_items thead th:nth-child(5),
.edit_supplier_invoice_items thead th:nth-child(5),
.new_supplier_invoice_parts thead th:nth-child(5),
.edit_supplier_invoice_parts thead th:nth-child(5),
.complete_purchase_order_items thead th:nth-child(5),
.complete_purchase_order_parts thead th:nth-child(5) {
  min-width: 80px;
}
.new_supplier_invoice_items thead th:nth-child(6),
.edit_supplier_invoice_items thead th:nth-child(6),
.new_supplier_invoice_parts thead th:nth-child(6),
.edit_supplier_invoice_parts thead th:nth-child(6),
.complete_purchase_order_items thead th:nth-child(6),
.complete_purchase_order_parts thead th:nth-child(6) {
  min-width: 240px;
  max-width: 240px;
  width: 240px;
}
.new_supplier_invoice_items thead th:nth-child(7),
.edit_supplier_invoice_items thead th:nth-child(7),
.new_supplier_invoice_parts thead th:nth-child(7),
.edit_supplier_invoice_parts thead th:nth-child(7),
.complete_purchase_order_items thead th:nth-child(7),
.complete_purchase_order_parts thead th:nth-child(7) {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}
.new_supplier_invoice_items thead th:nth-child(8),
.edit_supplier_invoice_items thead th:nth-child(8),
.new_supplier_invoice_parts thead th:nth-child(8),
.edit_supplier_invoice_parts thead th:nth-child(8),
.complete_purchase_order_items thead th:nth-child(8),
.complete_purchase_order_parts thead th:nth-child(8) {
  min-width: 150px;
}
.new_supplier_invoice_items thead th:nth-child(9),
.edit_supplier_invoice_items thead th:nth-child(9),
.new_supplier_invoice_parts thead th:nth-child(9),
.edit_supplier_invoice_parts thead th:nth-child(9),
.complete_purchase_order_items thead th:nth-child(9),
.complete_purchase_order_parts thead th:nth-child(9) {
  min-width: 150px;
}
.new_supplier_invoice_items thead th:nth-child(10),
.edit_supplier_invoice_items thead th:nth-child(10),
.new_supplier_invoice_parts thead th:nth-child(10),
.edit_supplier_invoice_parts thead th:nth-child(10),
.complete_purchase_order_items thead th:nth-child(10),
.complete_purchase_order_parts thead th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_invoice_items tbody strong,
.edit_supplier_invoice_items tbody strong,
.new_supplier_invoice_parts tbody strong,
.edit_supplier_invoice_parts tbody strong,
.complete_purchase_order_items tbody strong,
.complete_purchase_order_parts tbody strong {
  width: 200px;
}
.new_supplier_credit_note_parts td,
.new_supplier_credit_note_items td,
.edit_supplier_credit_note_parts td,
.edit_supplier_credit_note_items td,
.new_supplier_credit_note_parts th,
.new_supplier_credit_note_items th,
.edit_supplier_credit_note_parts th,
.edit_supplier_credit_note_items th {
  white-space: nowrap;
}
.new_supplier_credit_note_parts thead th:nth-child(2),
.new_supplier_credit_note_items thead th:nth-child(2),
.edit_supplier_credit_note_parts thead th:nth-child(2),
.edit_supplier_credit_note_items thead th:nth-child(2) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.new_supplier_credit_note_parts thead th:nth-child(3),
.new_supplier_credit_note_items thead th:nth-child(3),
.edit_supplier_credit_note_parts thead th:nth-child(3),
.edit_supplier_credit_note_items thead th:nth-child(3),
.new_supplier_credit_note_parts thead th:nth-child(4),
.new_supplier_credit_note_items thead th:nth-child(4),
.edit_supplier_credit_note_parts thead th:nth-child(4),
.edit_supplier_credit_note_items thead th:nth-child(4),
.new_supplier_credit_note_parts thead th:nth-child(5),
.new_supplier_credit_note_items thead th:nth-child(5),
.edit_supplier_credit_note_parts thead th:nth-child(5),
.edit_supplier_credit_note_items thead th:nth-child(5) {
  min-width: 80px;
}
.new_supplier_credit_note_parts thead th:nth-child(6),
.new_supplier_credit_note_items thead th:nth-child(6),
.edit_supplier_credit_note_parts thead th:nth-child(6),
.edit_supplier_credit_note_items thead th:nth-child(6) {
  min-width: 150px;
}
.new_supplier_credit_note_parts thead th:nth-child(7),
.new_supplier_credit_note_items thead th:nth-child(7),
.edit_supplier_credit_note_parts thead th:nth-child(7),
.edit_supplier_credit_note_items thead th:nth-child(7) {
  min-width: 240px;
  max-width: 240px;
  width: 240px;
}
.new_supplier_credit_note_parts thead th:nth-child(8),
.new_supplier_credit_note_items thead th:nth-child(8),
.edit_supplier_credit_note_parts thead th:nth-child(8),
.edit_supplier_credit_note_items thead th:nth-child(8) {
  min-width: 150px;
}
.new_supplier_credit_note_parts thead th:nth-child(9),
.new_supplier_credit_note_items thead th:nth-child(9),
.edit_supplier_credit_note_parts thead th:nth-child(9),
.edit_supplier_credit_note_items thead th:nth-child(9) {
  min-width: 150px;
}
.new_supplier_credit_note_parts thead th:nth-child(10),
.new_supplier_credit_note_items thead th:nth-child(10),
.edit_supplier_credit_note_parts thead th:nth-child(10),
.edit_supplier_credit_note_items thead th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_note_parts tbody strong,
.new_supplier_credit_note_items tbody strong,
.edit_supplier_credit_note_parts tbody strong,
.edit_supplier_credit_note_items tbody strong {
  width: 200px;
}
.job_costs_labour td,
.job_costs_labour th {
  white-space: nowrap;
}
.job_costs_labour thead th:nth-child(3) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.job_costs_labour thead th:nth-child(4),
.job_costs_labour thead th:nth-child(5),
.job_costs_labour thead th:nth-child(7) {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
}
.job_costs_labour thead th:nth-child(8) {
  min-width: 150px;
  max-width: 150px;
}
.job_costs_labour thead th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.job_costs_labour tbody strong {
  width: 200px;
}
.job_costs_parts td,
.job_costs_parts th {
  white-space: nowrap;
}
.job_costs_parts thead th:nth-child(3) {
  min-width: 310px;
  max-width: 310px;
  width: 310px;
}
.job_costs_parts thead th:nth-child(4),
.job_costs_parts thead th:nth-child(5),
.job_costs_parts thead th:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.job_costs_parts thead th:nth-child(8) {
  min-width: 150px;
  width: 150px;
  max-width: 150px;
}
.job_costs_parts thead th:nth-child(11) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.job_costs_parts tbody strong {
  width: 200px;
}
.edit_supplier_invoice_payment td,
.edit_supplier_invoice_payment th {
  white-space: nowrap;
}
.edit_supplier_invoice_payment thead th:nth-child(3),
.edit_supplier_invoice_payment thead th:nth-child(4),
.edit_supplier_invoice_payment thead th:nth-child(5),
.edit_supplier_invoice_payment thead th:nth-child(6),
.edit_supplier_invoice_payment thead th:nth-child(7),
.edit_supplier_invoice_payment thead th:nth-child(8),
.edit_supplier_invoice_payment thead th:nth-child(10) {
  min-width: 12%;
  max-width: 12%;
  width: 12%;
}
.edit_supplier_invoice_payment thead th:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.edit_supplier_invoice_payment tbody strong {
  width: 200px;
}
.new_supplier_credit_allocation td,
.edit_supplier_credit_allocation td,
.new_supplier_credit_allocation th,
.edit_supplier_credit_allocation th {
  white-space: nowrap;
}
.new_supplier_credit_allocation thead th:nth-child(2),
.edit_supplier_credit_allocation thead th:nth-child(2) {
  min-width: 140px;
}
.new_supplier_credit_allocation thead th:nth-child(3),
.edit_supplier_credit_allocation thead th:nth-child(3),
.new_supplier_credit_allocation thead th:nth-child(5),
.edit_supplier_credit_allocation thead th:nth-child(5) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(4),
.edit_supplier_credit_allocation thead th:nth-child(4) {
  min-width: 180px;
}
.new_supplier_credit_allocation thead th:nth-child(6),
.edit_supplier_credit_allocation thead th:nth-child(6) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(7),
.edit_supplier_credit_allocation thead th:nth-child(7) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(8),
.edit_supplier_credit_allocation thead th:nth-child(8) {
  min-width: 150px;
}
.new_supplier_credit_allocation thead th:nth-child(9),
.edit_supplier_credit_allocation thead th:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(2),
.edit_supplier_credit_allocation tbody tr td:nth-child(2) {
  max-width: 140px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(3),
.edit_supplier_credit_allocation tbody tr td:nth-child(3),
.new_supplier_credit_allocation tbody tr td:nth-child(5),
.edit_supplier_credit_allocation tbody tr td:nth-child(5) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(4),
.edit_supplier_credit_allocation tbody tr td:nth-child(4) {
  max-width: 180px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(6),
.edit_supplier_credit_allocation tbody tr td:nth-child(6) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(7),
.edit_supplier_credit_allocation tbody tr td:nth-child(7) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(8),
.edit_supplier_credit_allocation tbody tr td:nth-child(8) {
  max-width: 150px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(9),
.edit_supplier_credit_allocation tbody tr td:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_allocation tbody strong,
.edit_supplier_credit_allocation tbody strong {
  width: 200px;
}
.todos-spreadsheet tbody strong,
.jobtodos tbody strong,
.jobtodosnewmilestone tbody strong {
  width: 200px;
}
.todos-spreadsheet thead th:nth-child(2),
.templatetodos thead th:nth-child(2),
.blank_todos thead th:nth-child(2) {
  width: 160px;
}
.todos-spreadsheet thead th:nth-child(4),
.templatetodos thead th:nth-child(4),
.blank_todos thead th:nth-child(4) {
  width: 100px;
}
.jobtodos:not(.jobtodos-with-drag) thead th:nth-child(1),
.jobtodosnewmilestone thead th:nth-child(1) {
  width: 20px;
}
.jobtodos:not(.jobtodos-with-drag) thead th:nth-child(2),
.jobtodosnewmilestone thead th:nth-child(2) {
  width: 160px;
}
.jobtodos:not(.jobtodos-with-drag) thead th:nth-child(4),
.jobtodosnewmilestone thead th:nth-child(4) {
  width: 100px;
}
.jobtodos-with-drag thead th:nth-child(1) {
  width: 20px;
}
.jobtodos-with-drag thead th:nth-child(3) {
  width: 160px;
}
.jobtodos-with-drag thead th:nth-child(5) {
  width: 100px;
}
.costs-spreadsheet td,
.costs-spreadsheet th {
  white-space: nowrap;
}
.costs-spreadsheet thead th:nth-child(2) {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
.costs-spreadsheet thead th:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.costs-spreadsheet thead th:nth-child(4) {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}
.costs-spreadsheet thead th:nth-child(5),
.costs-spreadsheet thead th:nth-child(6),
.costs-spreadsheet thead th:nth-child(7),
.costs-spreadsheet thead th:nth-child(8),
.costs-spreadsheet thead th:nth-child(9),
.costs-spreadsheet thead th:nth-child(10) {
  min-width: 74px;
  max-width: 74px;
  width: 74px;
}
.costs-spreadsheet thead th:nth-child(10),
.costs-spreadsheet thead th:nth-child(12) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.costs-spreadsheet thead th:nth-child(11) {
  min-width: 180px;
  max-width: 180px;
  width: 180px;
}
.costs-spreadsheet tbody tr td:nth-child(2) {
  white-space: initial;
  max-width: 200px;
}
.costs-spreadsheet tbody tr td:nth-child(5),
.costs-spreadsheet tbody tr td:nth-child(6),
.costs-spreadsheet tbody tr td:nth-child(7),
.costs-spreadsheet tbody tr td:nth-child(8),
.costs-spreadsheet tbody tr td:nth-child(9),
.costs-spreadsheet tbody tr td:nth-child(10) {
  max-width: 74px;
}
.creditor_days td,
.creditor_days th {
  white-space: nowrap;
}
.creditor_days thead th:nth-child(3) {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
.creditor_days thead th:nth-child(5) {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
.creditor_days thead th:nth-child(7) {
  min-width: 140px;
  max-width: 140px;
  width: 140px;
}
.creditor_days tbody tr td:nth-child(3) {
  white-space: initial;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
.creditor_days tbody tr td:nth-child(7) {
  min-width: 140px;
  max-width: 140px;
  width: 140px;
}
.new_supplier_invoice_payment td,
.new_supplier_invoice_payment th {
  white-space: nowrap;
}
.new_supplier_invoice_payment thead th:nth-child(2) {
  min-width: 140px;
}
.new_supplier_invoice_payment thead th:nth-child(3),
.new_supplier_invoice_payment thead th:nth-child(5) {
  min-width: 120px;
}
.new_supplier_invoice_payment thead th:nth-child(4),
.new_supplier_invoice_payment thead th:nth-child(6) {
  min-width: 160px;
}
.new_supplier_invoice_payment thead th:nth-child(7) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_invoice_payment tbody tr td:nth-child(2) {
  max-width: 140px;
}
.new_supplier_invoice_payment tbody tr td:nth-child(3),
.new_supplier_invoice_payment tbody tr td:nth-child(5) {
  max-width: 120px;
}
.new_supplier_invoice_payment tbody tr td:nth-child(4),
.new_supplier_invoice_payment tbody tr td:nth-child(6) {
  max-width: 160px;
}
.new_supplier_invoice_payment tbody tr td:nth-child(7) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_invoice_payment tbody strong {
  width: 200px;
}
.new_supplier_cash_allocation td,
.new_supplier_cash_allocation_invoices td,
.new_customer_cash_allocation_invoices td,
.new_supplier_cash_allocation th,
.new_supplier_cash_allocation_invoices th,
.new_customer_cash_allocation_invoices th {
  white-space: nowrap;
}
.new_supplier_cash_allocation thead th:nth-child(2),
.new_supplier_cash_allocation_invoices thead th:nth-child(2),
.new_customer_cash_allocation_invoices thead th:nth-child(2) {
  min-width: 20px;
  max-width: 20px;
  width: 20px;
}
.new_supplier_cash_allocation thead th:nth-child(3),
.new_supplier_cash_allocation_invoices thead th:nth-child(3),
.new_customer_cash_allocation_invoices thead th:nth-child(3) {
  min-width: 110px;
}
.new_supplier_cash_allocation thead th:nth-child(4),
.new_supplier_cash_allocation_invoices thead th:nth-child(4),
.new_customer_cash_allocation_invoices thead th:nth-child(4),
.new_supplier_cash_allocation thead th:nth-child(6),
.new_supplier_cash_allocation_invoices thead th:nth-child(6),
.new_customer_cash_allocation_invoices thead th:nth-child(6) {
  min-width: 140px;
}
.new_supplier_cash_allocation thead th:nth-child(5),
.new_supplier_cash_allocation_invoices thead th:nth-child(5),
.new_customer_cash_allocation_invoices thead th:nth-child(5) {
  min-width: 140px;
}
.new_supplier_cash_allocation thead th:nth-child(7),
.new_supplier_cash_allocation_invoices thead th:nth-child(7),
.new_customer_cash_allocation_invoices thead th:nth-child(7) {
  min-width: 120px;
}
.new_supplier_cash_allocation thead th:nth-child(8),
.new_supplier_cash_allocation_invoices thead th:nth-child(8),
.new_customer_cash_allocation_invoices thead th:nth-child(8) {
  min-width: 190px;
  max-width: 190px;
  width: 190px;
}
.new_supplier_cash_allocation thead th:nth-child(9),
.new_supplier_cash_allocation_invoices thead th:nth-child(9),
.new_customer_cash_allocation_invoices thead th:nth-child(9) {
  min-width: 180px;
  max-width: 180px;
  width: 180px;
}
.new_supplier_cash_allocation thead th:nth-child(10),
.new_supplier_cash_allocation_invoices thead th:nth-child(10),
.new_customer_cash_allocation_invoices thead th:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(2),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(2),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(2) {
  min-width: 20px;
  max-width: 20px;
  width: 20px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(3),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(3),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(3) {
  min-width: 110px;
  max-width: 110px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(4),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(4),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(4),
.new_supplier_cash_allocation tbody tr td:nth-child(6),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(6),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(6) {
  min-width: 140px;
  max-width: 140px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(5),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(5),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(5) {
  min-width: 140px;
  max-width: 140px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(7),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(7),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(7) {
  min-width: 120px;
  max-width: 120px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(8),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(8),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(8) {
  min-width: 190px;
  max-width: 190px;
  width: 190px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(9),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(9),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(9) {
  min-width: 180px;
  max-width: 180px;
  width: 180px;
}
.new_supplier_cash_allocation tbody tr td:nth-child(10),
.new_supplier_cash_allocation_invoices tbody tr td:nth-child(10),
.new_customer_cash_allocation_invoices tbody tr td:nth-child(10) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_cash_allocation tbody strong,
.new_supplier_cash_allocation_invoices tbody strong,
.new_customer_cash_allocation_invoices tbody strong {
  width: 200px;
}
.new_supplier_credit_allocation td,
.edit_supplier_credit_allocation td,
.new_supplier_credit_allocation th,
.edit_supplier_credit_allocation th {
  white-space: nowrap;
}
.new_supplier_credit_allocation thead th:nth-child(2),
.edit_supplier_credit_allocation thead th:nth-child(2) {
  min-width: 140px;
}
.new_supplier_credit_allocation thead th:nth-child(3),
.edit_supplier_credit_allocation thead th:nth-child(3),
.new_supplier_credit_allocation thead th:nth-child(5),
.edit_supplier_credit_allocation thead th:nth-child(5) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(4),
.edit_supplier_credit_allocation thead th:nth-child(4) {
  min-width: 180px;
}
.new_supplier_credit_allocation thead th:nth-child(6),
.edit_supplier_credit_allocation thead th:nth-child(6) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(7),
.edit_supplier_credit_allocation thead th:nth-child(7) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(8),
.edit_supplier_credit_allocation thead th:nth-child(8) {
  min-width: 150px;
}
.new_supplier_credit_allocation thead th:nth-child(9),
.edit_supplier_credit_allocation thead th:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(2),
.edit_supplier_credit_allocation tbody tr td:nth-child(2) {
  max-width: 140px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(3),
.edit_supplier_credit_allocation tbody tr td:nth-child(3),
.new_supplier_credit_allocation tbody tr td:nth-child(5),
.edit_supplier_credit_allocation tbody tr td:nth-child(5) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(4),
.edit_supplier_credit_allocation tbody tr td:nth-child(4) {
  max-width: 180px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(6),
.edit_supplier_credit_allocation tbody tr td:nth-child(6) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(7),
.edit_supplier_credit_allocation tbody tr td:nth-child(7) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(8),
.edit_supplier_credit_allocation tbody tr td:nth-child(8) {
  max-width: 150px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(9),
.edit_supplier_credit_allocation tbody tr td:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_allocation tbody strong,
.edit_supplier_credit_allocation tbody strong {
  width: 200px;
}
.edit_supplier_invoice_payment td,
.edit_supplier_invoice_payment th {
  white-space: nowrap;
}
.edit_supplier_invoice_payment thead th:nth-child(3),
.edit_supplier_invoice_payment thead th:nth-child(4),
.edit_supplier_invoice_payment thead th:nth-child(5),
.edit_supplier_invoice_payment thead th:nth-child(6),
.edit_supplier_invoice_payment thead th:nth-child(7),
.edit_supplier_invoice_payment thead th:nth-child(8),
.edit_supplier_invoice_payment thead th:nth-child(10) {
  min-width: 12%;
  max-width: 12%;
  width: 12%;
}
.edit_supplier_invoice_payment thead th:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.edit_supplier_invoice_payment tbody strong {
  width: 200px;
}
.new_supplier_credit_allocation td,
.edit_supplier_credit_allocation td,
.new_supplier_credit_allocation th,
.edit_supplier_credit_allocation th {
  white-space: nowrap;
}
.new_supplier_credit_allocation thead th:nth-child(2),
.edit_supplier_credit_allocation thead th:nth-child(2) {
  min-width: 140px;
}
.new_supplier_credit_allocation thead th:nth-child(3),
.edit_supplier_credit_allocation thead th:nth-child(3),
.new_supplier_credit_allocation thead th:nth-child(5),
.edit_supplier_credit_allocation thead th:nth-child(5) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(4),
.edit_supplier_credit_allocation thead th:nth-child(4) {
  min-width: 180px;
}
.new_supplier_credit_allocation thead th:nth-child(6),
.edit_supplier_credit_allocation thead th:nth-child(6) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(7),
.edit_supplier_credit_allocation thead th:nth-child(7) {
  min-width: 120px;
}
.new_supplier_credit_allocation thead th:nth-child(8),
.edit_supplier_credit_allocation thead th:nth-child(8) {
  min-width: 150px;
}
.new_supplier_credit_allocation thead th:nth-child(9),
.edit_supplier_credit_allocation thead th:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(2),
.edit_supplier_credit_allocation tbody tr td:nth-child(2) {
  max-width: 140px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(3),
.edit_supplier_credit_allocation tbody tr td:nth-child(3),
.new_supplier_credit_allocation tbody tr td:nth-child(5),
.edit_supplier_credit_allocation tbody tr td:nth-child(5) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(4),
.edit_supplier_credit_allocation tbody tr td:nth-child(4) {
  max-width: 180px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(6),
.edit_supplier_credit_allocation tbody tr td:nth-child(6) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(7),
.edit_supplier_credit_allocation tbody tr td:nth-child(7) {
  max-width: 120px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(8),
.edit_supplier_credit_allocation tbody tr td:nth-child(8) {
  max-width: 150px;
}
.new_supplier_credit_allocation tbody tr td:nth-child(9),
.edit_supplier_credit_allocation tbody tr td:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.new_supplier_credit_allocation tbody strong,
.edit_supplier_credit_allocation tbody strong {
  width: 200px;
}
.pricing-spreadsheet tr.merge-not-allowed td,
.job_invoice_full_breakdown tr.merge-not-allowed td,
.job_multiple_invoice_full_breakdown tr.merge-not-allowed td,
.job_invoice_category_breakdown tr.merge-not-allowed td,
.job_invoice_no_breakdown tr.merge-not-allowed td,
.credit_note_labour tr.merge-not-allowed td,
.credit_note_parts tr.merge-not-allowed td,
.credit_note_category_breakdown tr.merge-not-allowed td,
.credit_note_full_breakdown tr.merge-not-allowed td {
  opacity: .2;
}
.pricing-spreadsheet tr.merge-not-allowed td.number-cell,
.job_invoice_full_breakdown tr.merge-not-allowed td.number-cell,
.job_multiple_invoice_full_breakdown tr.merge-not-allowed td.number-cell,
.job_invoice_category_breakdown tr.merge-not-allowed td.number-cell,
.job_invoice_no_breakdown tr.merge-not-allowed td.number-cell,
.credit_note_labour tr.merge-not-allowed td.number-cell,
.credit_note_parts tr.merge-not-allowed td.number-cell,
.credit_note_category_breakdown tr.merge-not-allowed td.number-cell,
.credit_note_full_breakdown tr.merge-not-allowed td.number-cell {
  opacity: 1;
}
.pricing-spreadsheet tr.merge-not-allowed td:hover,
.job_invoice_full_breakdown tr.merge-not-allowed td:hover,
.job_multiple_invoice_full_breakdown tr.merge-not-allowed td:hover,
.job_invoice_category_breakdown tr.merge-not-allowed td:hover,
.job_invoice_no_breakdown tr.merge-not-allowed td:hover,
.credit_note_labour tr.merge-not-allowed td:hover,
.credit_note_parts tr.merge-not-allowed td:hover,
.credit_note_category_breakdown tr.merge-not-allowed td:hover,
.credit_note_full_breakdown tr.merge-not-allowed td:hover {
  opacity: .2;
  cursor: not-allowed;
}
.pricing-spreadsheet tr.merge-not-allowed td:hover.number-cell,
.job_invoice_full_breakdown tr.merge-not-allowed td:hover.number-cell,
.job_multiple_invoice_full_breakdown tr.merge-not-allowed td:hover.number-cell,
.job_invoice_category_breakdown tr.merge-not-allowed td:hover.number-cell,
.job_invoice_no_breakdown tr.merge-not-allowed td:hover.number-cell,
.credit_note_labour tr.merge-not-allowed td:hover.number-cell,
.credit_note_parts tr.merge-not-allowed td:hover.number-cell,
.credit_note_category_breakdown tr.merge-not-allowed td:hover.number-cell,
.credit_note_full_breakdown tr.merge-not-allowed td:hover.number-cell {
  opacity: 1;
  cursor: default;
}
.pricing-spreadsheet tr.top-merged-row td.cell-without-border,
.job_invoice_full_breakdown tr.top-merged-row td.cell-without-border,
.job_multiple_invoice_full_breakdown tr.top-merged-row td.cell-without-border,
.job_invoice_category_breakdown tr.top-merged-row td.cell-without-border,
.job_invoice_no_breakdown tr.top-merged-row td.cell-without-border,
.credit_note_labour tr.top-merged-row td.cell-without-border,
.credit_note_parts tr.top-merged-row td.cell-without-border,
.credit_note_category_breakdown tr.top-merged-row td.cell-without-border,
.credit_note_full_breakdown tr.top-merged-row td.cell-without-border,
.pricing-spreadsheet tr.child-merged-row td.cell-without-border,
.job_invoice_full_breakdown tr.child-merged-row td.cell-without-border,
.job_multiple_invoice_full_breakdown tr.child-merged-row td.cell-without-border,
.job_invoice_category_breakdown tr.child-merged-row td.cell-without-border,
.job_invoice_no_breakdown tr.child-merged-row td.cell-without-border,
.credit_note_labour tr.child-merged-row td.cell-without-border,
.credit_note_parts tr.child-merged-row td.cell-without-border,
.credit_note_category_breakdown tr.child-merged-row td.cell-without-border,
.credit_note_full_breakdown tr.child-merged-row td.cell-without-border {
  border-right: 1px solid #e1e1e1;
}
.pricing-spreadsheet tr.top-merged-row td:hover,
.job_invoice_full_breakdown tr.top-merged-row td:hover,
.job_multiple_invoice_full_breakdown tr.top-merged-row td:hover,
.job_invoice_category_breakdown tr.top-merged-row td:hover,
.job_invoice_no_breakdown tr.top-merged-row td:hover,
.credit_note_labour tr.top-merged-row td:hover,
.credit_note_parts tr.top-merged-row td:hover,
.credit_note_category_breakdown tr.top-merged-row td:hover,
.credit_note_full_breakdown tr.top-merged-row td:hover,
.pricing-spreadsheet tr.child-merged-row td:hover,
.job_invoice_full_breakdown tr.child-merged-row td:hover,
.job_multiple_invoice_full_breakdown tr.child-merged-row td:hover,
.job_invoice_category_breakdown tr.child-merged-row td:hover,
.job_invoice_no_breakdown tr.child-merged-row td:hover,
.credit_note_labour tr.child-merged-row td:hover,
.credit_note_parts tr.child-merged-row td:hover,
.credit_note_category_breakdown tr.child-merged-row td:hover,
.credit_note_full_breakdown tr.child-merged-row td:hover {
  cursor: not-allowed;
}
.pricing-spreadsheet tr.top-merged-row td,
.job_invoice_full_breakdown tr.top-merged-row td,
.job_multiple_invoice_full_breakdown tr.top-merged-row td,
.job_invoice_category_breakdown tr.top-merged-row td,
.job_invoice_no_breakdown tr.top-merged-row td,
.credit_note_labour tr.top-merged-row td,
.credit_note_parts tr.top-merged-row td,
.credit_note_category_breakdown tr.top-merged-row td,
.credit_note_full_breakdown tr.top-merged-row td {
  font-weight: 600;
  color: rgba(51, 51, 51, 0.83);
}
.pricing-spreadsheet tr.top-merged-row td.number-cell,
.job_invoice_full_breakdown tr.top-merged-row td.number-cell,
.job_multiple_invoice_full_breakdown tr.top-merged-row td.number-cell,
.job_invoice_category_breakdown tr.top-merged-row td.number-cell,
.job_invoice_no_breakdown tr.top-merged-row td.number-cell,
.credit_note_labour tr.top-merged-row td.number-cell,
.credit_note_parts tr.top-merged-row td.number-cell,
.credit_note_category_breakdown tr.top-merged-row td.number-cell,
.credit_note_full_breakdown tr.top-merged-row td.number-cell {
  font-weight: normal;
  color: #333333;
}
.pricing-spreadsheet tr.top-merged-row td:nth-child(10),
.job_invoice_full_breakdown tr.top-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown tr.top-merged-row td:nth-child(10),
.job_invoice_category_breakdown tr.top-merged-row td:nth-child(10),
.job_invoice_no_breakdown tr.top-merged-row td:nth-child(10),
.credit_note_labour tr.top-merged-row td:nth-child(10),
.credit_note_parts tr.top-merged-row td:nth-child(10),
.credit_note_category_breakdown tr.top-merged-row td:nth-child(10),
.credit_note_full_breakdown tr.top-merged-row td:nth-child(10) {
  border-bottom: 1px solid #e1e1e1;
  font-weight: normal;
}
.pricing-spreadsheet tr.top-merged-row + .child-merged-row td,
.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_labour tr.top-merged-row + .child-merged-row td,
.credit_note_parts tr.top-merged-row + .child-merged-row td,
.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td {
    background-image: url("../images/null.png");
    background-repeat: repeat-x;
}
.pricing-spreadsheet tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2) {
  background: #f6f6f6;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08) inset;
  -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08) inset;
}
.pricing-spreadsheet tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10) {
    background: url("../images/null.png") repeat-y right, #f6f6f6;
    -webkit-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
}
.pricing-spreadsheet tr.child-merged-row td,
.job_invoice_full_breakdown tr.child-merged-row td,
.job_multiple_invoice_full_breakdown tr.child-merged-row td,
.job_invoice_category_breakdown tr.child-merged-row td,
.job_invoice_no_breakdown tr.child-merged-row td,
.credit_note_labour tr.child-merged-row td,
.credit_note_parts tr.child-merged-row td,
.credit_note_category_breakdown tr.child-merged-row td,
.credit_note_full_breakdown tr.child-merged-row td {
  background: #f6f6f6;
  opacity: .7;
}
.pricing-spreadsheet tr.child-merged-row td.number-cell,
.job_invoice_full_breakdown tr.child-merged-row td.number-cell,
.job_multiple_invoice_full_breakdown tr.child-merged-row td.number-cell,
.job_invoice_category_breakdown tr.child-merged-row td.number-cell,
.job_invoice_no_breakdown tr.child-merged-row td.number-cell,
.credit_note_labour tr.child-merged-row td.number-cell,
.credit_note_parts tr.child-merged-row td.number-cell,
.credit_note_category_breakdown tr.child-merged-row td.number-cell,
.credit_note_full_breakdown tr.child-merged-row td.number-cell {
  background: #fbfbfb;
  opacity: 1;
}
.pricing-spreadsheet tr.child-merged-row td:nth-child(2),
.job_invoice_full_breakdown tr.child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(2),
.job_invoice_category_breakdown tr.child-merged-row td:nth-child(2),
.job_invoice_no_breakdown tr.child-merged-row td:nth-child(2),
.credit_note_labour tr.child-merged-row td:nth-child(2),
.credit_note_parts tr.child-merged-row td:nth-child(2),
.credit_note_category_breakdown tr.child-merged-row td:nth-child(2),
.credit_note_full_breakdown tr.child-merged-row td:nth-child(2) {
    background-image: url("../images/null.png");
    background-repeat: repeat-y;
}
.pricing-spreadsheet tr.child-merged-row td:nth-child(10),
.job_invoice_full_breakdown tr.child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(10),
.job_invoice_category_breakdown tr.child-merged-row td:nth-child(10),
.job_invoice_no_breakdown tr.child-merged-row td:nth-child(10),
.credit_note_labour tr.child-merged-row td:nth-child(10),
.credit_note_parts tr.child-merged-row td:nth-child(10),
.credit_note_category_breakdown tr.child-merged-row td:nth-child(10),
.credit_note_full_breakdown tr.child-merged-row td:nth-child(10) {
    background-image: url("../images/null.png");
    background-repeat: repeat-y;
    background-position: right;
}
.pricing-spreadsheet tr.child-merged-row:last-of-type td,
.job_invoice_full_breakdown tr.child-merged-row:last-of-type td,
.job_multiple_invoice_full_breakdown tr.child-merged-row:last-of-type td,
.job_invoice_category_breakdown tr.child-merged-row:last-of-type td,
.job_invoice_no_breakdown tr.child-merged-row:last-of-type td,
.credit_note_labour tr.child-merged-row:last-of-type td,
.credit_note_parts tr.child-merged-row:last-of-type td,
.credit_note_category_breakdown tr.child-merged-row:last-of-type td,
.credit_note_full_breakdown tr.child-merged-row:last-of-type td {
  border-bottom: 1px solid #e1e1e1;
}
.pricing-spreadsheet td,
.job_invoice_full_breakdown td,
.job_multiple_invoice_full_breakdown td,
.job_invoice_category_breakdown td,
.job_invoice_no_breakdown td,
.credit_note_labour td,
.credit_note_parts td,
.credit_note_category_breakdown td,
.credit_note_full_breakdown td,
.pricing-spreadsheet th,
.job_invoice_full_breakdown th,
.job_multiple_invoice_full_breakdown th,
.job_invoice_category_breakdown th,
.job_invoice_no_breakdown th,
.credit_note_labour th,
.credit_note_parts th,
.credit_note_category_breakdown th,
.credit_note_full_breakdown th {
  white-space: nowrap;
}
.pricing-spreadsheet td:nth-child(3),
.job_invoice_full_breakdown td:nth-child(3),
.job_multiple_invoice_full_breakdown td:nth-child(3),
.job_invoice_category_breakdown td:nth-child(3),
.job_invoice_no_breakdown td:nth-child(3),
.credit_note_labour td:nth-child(3),
.credit_note_parts td:nth-child(3),
.credit_note_category_breakdown td:nth-child(3),
.credit_note_full_breakdown td:nth-child(3),
.pricing-spreadsheet th:nth-child(3),
.job_invoice_full_breakdown th:nth-child(3),
.job_multiple_invoice_full_breakdown th:nth-child(3),
.job_invoice_category_breakdown th:nth-child(3),
.job_invoice_no_breakdown th:nth-child(3),
.credit_note_labour th:nth-child(3),
.credit_note_parts th:nth-child(3),
.credit_note_category_breakdown th:nth-child(3),
.credit_note_full_breakdown th:nth-child(3),
.pricing-spreadsheet td:nth-child(4),
.job_invoice_full_breakdown td:nth-child(4),
.job_multiple_invoice_full_breakdown td:nth-child(4),
.job_invoice_category_breakdown td:nth-child(4),
.job_invoice_no_breakdown td:nth-child(4),
.credit_note_labour td:nth-child(4),
.credit_note_parts td:nth-child(4),
.credit_note_category_breakdown td:nth-child(4),
.credit_note_full_breakdown td:nth-child(4),
.pricing-spreadsheet th:nth-child(4),
.job_invoice_full_breakdown th:nth-child(4),
.job_multiple_invoice_full_breakdown th:nth-child(4),
.job_invoice_category_breakdown th:nth-child(4),
.job_invoice_no_breakdown th:nth-child(4),
.credit_note_labour th:nth-child(4),
.credit_note_parts th:nth-child(4),
.credit_note_category_breakdown th:nth-child(4),
.credit_note_full_breakdown th:nth-child(4),
.pricing-spreadsheet td:nth-child(5),
.job_invoice_full_breakdown td:nth-child(5),
.job_multiple_invoice_full_breakdown td:nth-child(5),
.job_invoice_category_breakdown td:nth-child(5),
.job_invoice_no_breakdown td:nth-child(5),
.credit_note_labour td:nth-child(5),
.credit_note_parts td:nth-child(5),
.credit_note_category_breakdown td:nth-child(5),
.credit_note_full_breakdown td:nth-child(5),
.pricing-spreadsheet th:nth-child(5),
.job_invoice_full_breakdown th:nth-child(5),
.job_multiple_invoice_full_breakdown th:nth-child(5),
.job_invoice_category_breakdown th:nth-child(5),
.job_invoice_no_breakdown th:nth-child(5),
.credit_note_labour th:nth-child(5),
.credit_note_parts th:nth-child(5),
.credit_note_category_breakdown th:nth-child(5),
.credit_note_full_breakdown th:nth-child(5),
.pricing-spreadsheet td:nth-child(6),
.job_invoice_full_breakdown td:nth-child(6),
.job_multiple_invoice_full_breakdown td:nth-child(6),
.job_invoice_category_breakdown td:nth-child(6),
.job_invoice_no_breakdown td:nth-child(6),
.credit_note_labour td:nth-child(6),
.credit_note_parts td:nth-child(6),
.credit_note_category_breakdown td:nth-child(6),
.credit_note_full_breakdown td:nth-child(6),
.pricing-spreadsheet th:nth-child(6),
.job_invoice_full_breakdown th:nth-child(6),
.job_multiple_invoice_full_breakdown th:nth-child(6),
.job_invoice_category_breakdown th:nth-child(6),
.job_invoice_no_breakdown th:nth-child(6),
.credit_note_labour th:nth-child(6),
.credit_note_parts th:nth-child(6),
.credit_note_category_breakdown th:nth-child(6),
.credit_note_full_breakdown th:nth-child(6),
.pricing-spreadsheet td:nth-child(7),
.job_invoice_full_breakdown td:nth-child(7),
.job_multiple_invoice_full_breakdown td:nth-child(7),
.job_invoice_category_breakdown td:nth-child(7),
.job_invoice_no_breakdown td:nth-child(7),
.credit_note_labour td:nth-child(7),
.credit_note_parts td:nth-child(7),
.credit_note_category_breakdown td:nth-child(7),
.credit_note_full_breakdown td:nth-child(7),
.pricing-spreadsheet th:nth-child(7),
.job_invoice_full_breakdown th:nth-child(7),
.job_multiple_invoice_full_breakdown th:nth-child(7),
.job_invoice_category_breakdown th:nth-child(7),
.job_invoice_no_breakdown th:nth-child(7),
.credit_note_labour th:nth-child(7),
.credit_note_parts th:nth-child(7),
.credit_note_category_breakdown th:nth-child(7),
.credit_note_full_breakdown th:nth-child(7) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}
.pricing-spreadsheet td:nth-child(9),
.job_invoice_full_breakdown td:nth-child(9),
.job_multiple_invoice_full_breakdown td:nth-child(9),
.job_invoice_category_breakdown td:nth-child(9),
.job_invoice_no_breakdown td:nth-child(9),
.credit_note_labour td:nth-child(9),
.credit_note_parts td:nth-child(9),
.credit_note_category_breakdown td:nth-child(9),
.credit_note_full_breakdown td:nth-child(9),
.pricing-spreadsheet th:nth-child(9),
.job_invoice_full_breakdown th:nth-child(9),
.job_multiple_invoice_full_breakdown th:nth-child(9),
.job_invoice_category_breakdown th:nth-child(9),
.job_invoice_no_breakdown th:nth-child(9),
.credit_note_labour th:nth-child(9),
.credit_note_parts th:nth-child(9),
.credit_note_category_breakdown th:nth-child(9),
.credit_note_full_breakdown th:nth-child(9) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.pricing-spreadsheet td:nth-child(10),
.job_invoice_full_breakdown td:nth-child(10),
.job_multiple_invoice_full_breakdown td:nth-child(10),
.job_invoice_category_breakdown td:nth-child(10),
.job_invoice_no_breakdown td:nth-child(10),
.credit_note_labour td:nth-child(10),
.credit_note_parts td:nth-child(10),
.credit_note_category_breakdown td:nth-child(10),
.credit_note_full_breakdown td:nth-child(10),
.pricing-spreadsheet th:nth-child(10),
.job_invoice_full_breakdown th:nth-child(10),
.job_multiple_invoice_full_breakdown th:nth-child(10),
.job_invoice_category_breakdown th:nth-child(10),
.job_invoice_no_breakdown th:nth-child(10),
.credit_note_labour th:nth-child(10),
.credit_note_parts th:nth-child(10),
.credit_note_category_breakdown th:nth-child(10),
.credit_note_full_breakdown th:nth-child(10) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.pricing-spreadsheet.with-merge-column td:nth-child(3),
.job_invoice_full_breakdown.with-merge-column td:nth-child(3),
.job_multiple_invoice_full_breakdown.with-merge-column td:nth-child(3),
.job_invoice_category_breakdown.with-merge-column td:nth-child(3),
.job_invoice_no_breakdown.with-merge-column td:nth-child(3),
.credit_note_labour.with-merge-column td:nth-child(3),
.credit_note_parts.with-merge-column td:nth-child(3),
.credit_note_category_breakdown.with-merge-column td:nth-child(3),
.credit_note_full_breakdown.with-merge-column td:nth-child(3),
.pricing-spreadsheet.with-merge-column th:nth-child(3),
.job_invoice_full_breakdown.with-merge-column th:nth-child(3),
.job_multiple_invoice_full_breakdown.with-merge-column th:nth-child(3),
.job_invoice_category_breakdown.with-merge-column th:nth-child(3),
.job_invoice_no_breakdown.with-merge-column th:nth-child(3),
.credit_note_labour.with-merge-column th:nth-child(3),
.credit_note_parts.with-merge-column th:nth-child(3),
.credit_note_category_breakdown.with-merge-column th:nth-child(3),
.credit_note_full_breakdown.with-merge-column th:nth-child(3) {
  max-width: none;
  min-width: 0px;
  width: auto;
}
.pricing-spreadsheet tbody td .ss-fork,
.job_invoice_full_breakdown tbody td .ss-fork,
.job_multiple_invoice_full_breakdown tbody td .ss-fork,
.job_invoice_category_breakdown tbody td .ss-fork,
.job_invoice_no_breakdown tbody td .ss-fork,
.credit_note_labour tbody td .ss-fork,
.credit_note_parts tbody td .ss-fork,
.credit_note_category_breakdown tbody td .ss-fork,
.credit_note_full_breakdown tbody td .ss-fork {
  float: left;
  margin: 14px 0 0 13px;
  font-size: 14px;
  line-height: 16px;
  color: #60a369;
}
.pricing-spreadsheet.job_labour tr th:nth-child(3),
.job_invoice_full_breakdown.job_labour tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_labour tr th:nth-child(3),
.job_invoice_category_breakdown.job_labour tr th:nth-child(3),
.job_invoice_no_breakdown.job_labour tr th:nth-child(3),
.credit_note_labour.job_labour tr th:nth-child(3),
.credit_note_parts.job_labour tr th:nth-child(3),
.credit_note_category_breakdown.job_labour tr th:nth-child(3),
.credit_note_full_breakdown.job_labour tr th:nth-child(3),
.pricing-spreadsheet.job_invoice_full_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr th:nth-child(3),
.credit_note_labour.job_invoice_full_breakdown tr th:nth-child(3),
.credit_note_parts.job_invoice_full_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_invoice_full_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_invoice_full_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.credit_note_labour.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.credit_note_parts.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_labour.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_parts.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_invoice_no_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr th:nth-child(3),
.credit_note_labour.job_invoice_no_breakdown tr th:nth-child(3),
.credit_note_parts.job_invoice_no_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_invoice_no_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_invoice_no_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_labour tr td:nth-child(3),
.job_invoice_full_breakdown.job_labour tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_labour tr td:nth-child(3),
.job_invoice_category_breakdown.job_labour tr td:nth-child(3),
.job_invoice_no_breakdown.job_labour tr td:nth-child(3),
.credit_note_labour.job_labour tr td:nth-child(3),
.credit_note_parts.job_labour tr td:nth-child(3),
.credit_note_category_breakdown.job_labour tr td:nth-child(3),
.credit_note_full_breakdown.job_labour tr td:nth-child(3),
.pricing-spreadsheet.job_invoice_full_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr td:nth-child(3),
.credit_note_labour.job_invoice_full_breakdown tr td:nth-child(3),
.credit_note_parts.job_invoice_full_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_invoice_full_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_invoice_full_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.credit_note_labour.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.credit_note_parts.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_labour.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_parts.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_invoice_no_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr td:nth-child(3),
.credit_note_labour.job_invoice_no_breakdown tr td:nth-child(3),
.credit_note_parts.job_invoice_no_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_invoice_no_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_invoice_no_breakdown tr td:nth-child(3) {
  min-width: 200px;
  white-space: normal;
}
.pricing-spreadsheet.job_labour tr th:nth-child(7),
.job_invoice_full_breakdown.job_labour tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour tr th:nth-child(7),
.job_invoice_category_breakdown.job_labour tr th:nth-child(7),
.job_invoice_no_breakdown.job_labour tr th:nth-child(7),
.credit_note_labour.job_labour tr th:nth-child(7),
.credit_note_parts.job_labour tr th:nth-child(7),
.credit_note_category_breakdown.job_labour tr th:nth-child(7),
.credit_note_full_breakdown.job_labour tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_labour tr td:nth-child(7),
.job_invoice_full_breakdown.job_labour tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour tr td:nth-child(7),
.job_invoice_category_breakdown.job_labour tr td:nth-child(7),
.job_invoice_no_breakdown.job_labour tr td:nth-child(7),
.credit_note_labour.job_labour tr td:nth-child(7),
.credit_note_parts.job_labour tr td:nth-child(7),
.credit_note_category_breakdown.job_labour tr td:nth-child(7),
.credit_note_full_breakdown.job_labour tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown tr td:nth-child(7) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.pricing-spreadsheet.job_labour tr th:nth-child(8),
.job_invoice_full_breakdown.job_labour tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.job_labour tr th:nth-child(8),
.job_invoice_category_breakdown.job_labour tr th:nth-child(8),
.job_invoice_no_breakdown.job_labour tr th:nth-child(8),
.credit_note_labour.job_labour tr th:nth-child(8),
.credit_note_parts.job_labour tr th:nth-child(8),
.credit_note_category_breakdown.job_labour tr th:nth-child(8),
.credit_note_full_breakdown.job_labour tr th:nth-child(8),
.pricing-spreadsheet.job_invoice_full_breakdown tr th:nth-child(8),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(8),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr th:nth-child(8),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr th:nth-child(8),
.credit_note_labour.job_invoice_full_breakdown tr th:nth-child(8),
.credit_note_parts.job_invoice_full_breakdown tr th:nth-child(8),
.credit_note_category_breakdown.job_invoice_full_breakdown tr th:nth-child(8),
.credit_note_full_breakdown.job_invoice_full_breakdown tr th:nth-child(8),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.credit_note_labour.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.credit_note_parts.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(8),
.pricing-spreadsheet.job_invoice_category_breakdown tr th:nth-child(8),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(8),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(8),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(8),
.credit_note_labour.job_invoice_category_breakdown tr th:nth-child(8),
.credit_note_parts.job_invoice_category_breakdown tr th:nth-child(8),
.credit_note_category_breakdown.job_invoice_category_breakdown tr th:nth-child(8),
.credit_note_full_breakdown.job_invoice_category_breakdown tr th:nth-child(8),
.pricing-spreadsheet.job_invoice_no_breakdown tr th:nth-child(8),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(8),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr th:nth-child(8),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr th:nth-child(8),
.credit_note_labour.job_invoice_no_breakdown tr th:nth-child(8),
.credit_note_parts.job_invoice_no_breakdown tr th:nth-child(8),
.credit_note_category_breakdown.job_invoice_no_breakdown tr th:nth-child(8),
.credit_note_full_breakdown.job_invoice_no_breakdown tr th:nth-child(8),
.pricing-spreadsheet.job_labour tr td:nth-child(8),
.job_invoice_full_breakdown.job_labour tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.job_labour tr td:nth-child(8),
.job_invoice_category_breakdown.job_labour tr td:nth-child(8),
.job_invoice_no_breakdown.job_labour tr td:nth-child(8),
.credit_note_labour.job_labour tr td:nth-child(8),
.credit_note_parts.job_labour tr td:nth-child(8),
.credit_note_category_breakdown.job_labour tr td:nth-child(8),
.credit_note_full_breakdown.job_labour tr td:nth-child(8),
.pricing-spreadsheet.job_invoice_full_breakdown tr td:nth-child(8),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(8),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr td:nth-child(8),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr td:nth-child(8),
.credit_note_labour.job_invoice_full_breakdown tr td:nth-child(8),
.credit_note_parts.job_invoice_full_breakdown tr td:nth-child(8),
.credit_note_category_breakdown.job_invoice_full_breakdown tr td:nth-child(8),
.credit_note_full_breakdown.job_invoice_full_breakdown tr td:nth-child(8),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.credit_note_labour.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.credit_note_parts.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(8),
.pricing-spreadsheet.job_invoice_category_breakdown tr td:nth-child(8),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(8),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(8),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(8),
.credit_note_labour.job_invoice_category_breakdown tr td:nth-child(8),
.credit_note_parts.job_invoice_category_breakdown tr td:nth-child(8),
.credit_note_category_breakdown.job_invoice_category_breakdown tr td:nth-child(8),
.credit_note_full_breakdown.job_invoice_category_breakdown tr td:nth-child(8),
.pricing-spreadsheet.job_invoice_no_breakdown tr td:nth-child(8),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(8),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr td:nth-child(8),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr td:nth-child(8),
.credit_note_labour.job_invoice_no_breakdown tr td:nth-child(8),
.credit_note_parts.job_invoice_no_breakdown tr td:nth-child(8),
.credit_note_category_breakdown.job_invoice_no_breakdown tr td:nth-child(8),
.credit_note_full_breakdown.job_invoice_no_breakdown tr td:nth-child(8) {
  max-width: 140px;
  min-width: 140px;
  width: 140px;
}
.pricing-spreadsheet.job_labour tr th:nth-child(10),
.job_invoice_full_breakdown.job_labour tr th:nth-child(10),
.job_multiple_invoice_full_breakdown.job_labour tr th:nth-child(10),
.job_invoice_category_breakdown.job_labour tr th:nth-child(10),
.job_invoice_no_breakdown.job_labour tr th:nth-child(10),
.credit_note_labour.job_labour tr th:nth-child(10),
.credit_note_parts.job_labour tr th:nth-child(10),
.credit_note_category_breakdown.job_labour tr th:nth-child(10),
.credit_note_full_breakdown.job_labour tr th:nth-child(10),
.pricing-spreadsheet.job_invoice_full_breakdown tr th:nth-child(10),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(10),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr th:nth-child(10),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr th:nth-child(10),
.credit_note_labour.job_invoice_full_breakdown tr th:nth-child(10),
.credit_note_parts.job_invoice_full_breakdown tr th:nth-child(10),
.credit_note_category_breakdown.job_invoice_full_breakdown tr th:nth-child(10),
.credit_note_full_breakdown.job_invoice_full_breakdown tr th:nth-child(10),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.credit_note_labour.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.credit_note_parts.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(10),
.pricing-spreadsheet.job_invoice_category_breakdown tr th:nth-child(10),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(10),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(10),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(10),
.credit_note_labour.job_invoice_category_breakdown tr th:nth-child(10),
.credit_note_parts.job_invoice_category_breakdown tr th:nth-child(10),
.credit_note_category_breakdown.job_invoice_category_breakdown tr th:nth-child(10),
.credit_note_full_breakdown.job_invoice_category_breakdown tr th:nth-child(10),
.pricing-spreadsheet.job_invoice_no_breakdown tr th:nth-child(10),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(10),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr th:nth-child(10),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr th:nth-child(10),
.credit_note_labour.job_invoice_no_breakdown tr th:nth-child(10),
.credit_note_parts.job_invoice_no_breakdown tr th:nth-child(10),
.credit_note_category_breakdown.job_invoice_no_breakdown tr th:nth-child(10),
.credit_note_full_breakdown.job_invoice_no_breakdown tr th:nth-child(10),
.pricing-spreadsheet.job_labour tr td:nth-child(10),
.job_invoice_full_breakdown.job_labour tr td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_labour tr td:nth-child(10),
.job_invoice_category_breakdown.job_labour tr td:nth-child(10),
.job_invoice_no_breakdown.job_labour tr td:nth-child(10),
.credit_note_labour.job_labour tr td:nth-child(10),
.credit_note_parts.job_labour tr td:nth-child(10),
.credit_note_category_breakdown.job_labour tr td:nth-child(10),
.credit_note_full_breakdown.job_labour tr td:nth-child(10),
.pricing-spreadsheet.job_invoice_full_breakdown tr td:nth-child(10),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(10),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr td:nth-child(10),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr td:nth-child(10),
.credit_note_labour.job_invoice_full_breakdown tr td:nth-child(10),
.credit_note_parts.job_invoice_full_breakdown tr td:nth-child(10),
.credit_note_category_breakdown.job_invoice_full_breakdown tr td:nth-child(10),
.credit_note_full_breakdown.job_invoice_full_breakdown tr td:nth-child(10),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.credit_note_labour.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.credit_note_parts.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(10),
.pricing-spreadsheet.job_invoice_category_breakdown tr td:nth-child(10),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(10),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(10),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(10),
.credit_note_labour.job_invoice_category_breakdown tr td:nth-child(10),
.credit_note_parts.job_invoice_category_breakdown tr td:nth-child(10),
.credit_note_category_breakdown.job_invoice_category_breakdown tr td:nth-child(10),
.credit_note_full_breakdown.job_invoice_category_breakdown tr td:nth-child(10),
.pricing-spreadsheet.job_invoice_no_breakdown tr td:nth-child(10),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(10),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr td:nth-child(10),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr td:nth-child(10),
.credit_note_labour.job_invoice_no_breakdown tr td:nth-child(10),
.credit_note_parts.job_invoice_no_breakdown tr td:nth-child(10),
.credit_note_category_breakdown.job_invoice_no_breakdown tr td:nth-child(10),
.credit_note_full_breakdown.job_invoice_no_breakdown tr td:nth-child(10) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}
.pricing-spreadsheet.job_labour tr th:nth-child(13),
.job_invoice_full_breakdown.job_labour tr th:nth-child(13),
.job_multiple_invoice_full_breakdown.job_labour tr th:nth-child(13),
.job_invoice_category_breakdown.job_labour tr th:nth-child(13),
.job_invoice_no_breakdown.job_labour tr th:nth-child(13),
.credit_note_labour.job_labour tr th:nth-child(13),
.credit_note_parts.job_labour tr th:nth-child(13),
.credit_note_category_breakdown.job_labour tr th:nth-child(13),
.credit_note_full_breakdown.job_labour tr th:nth-child(13),
.pricing-spreadsheet.job_invoice_full_breakdown tr th:nth-child(13),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr th:nth-child(13),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr th:nth-child(13),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr th:nth-child(13),
.credit_note_labour.job_invoice_full_breakdown tr th:nth-child(13),
.credit_note_parts.job_invoice_full_breakdown tr th:nth-child(13),
.credit_note_category_breakdown.job_invoice_full_breakdown tr th:nth-child(13),
.credit_note_full_breakdown.job_invoice_full_breakdown tr th:nth-child(13),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.credit_note_labour.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.credit_note_parts.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(13),
.pricing-spreadsheet.job_invoice_category_breakdown tr th:nth-child(13),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(13),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(13),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(13),
.credit_note_labour.job_invoice_category_breakdown tr th:nth-child(13),
.credit_note_parts.job_invoice_category_breakdown tr th:nth-child(13),
.credit_note_category_breakdown.job_invoice_category_breakdown tr th:nth-child(13),
.credit_note_full_breakdown.job_invoice_category_breakdown tr th:nth-child(13),
.pricing-spreadsheet.job_invoice_no_breakdown tr th:nth-child(13),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr th:nth-child(13),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr th:nth-child(13),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr th:nth-child(13),
.credit_note_labour.job_invoice_no_breakdown tr th:nth-child(13),
.credit_note_parts.job_invoice_no_breakdown tr th:nth-child(13),
.credit_note_category_breakdown.job_invoice_no_breakdown tr th:nth-child(13),
.credit_note_full_breakdown.job_invoice_no_breakdown tr th:nth-child(13),
.pricing-spreadsheet.job_labour tr td:nth-child(13),
.job_invoice_full_breakdown.job_labour tr td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_labour tr td:nth-child(13),
.job_invoice_category_breakdown.job_labour tr td:nth-child(13),
.job_invoice_no_breakdown.job_labour tr td:nth-child(13),
.credit_note_labour.job_labour tr td:nth-child(13),
.credit_note_parts.job_labour tr td:nth-child(13),
.credit_note_category_breakdown.job_labour tr td:nth-child(13),
.credit_note_full_breakdown.job_labour tr td:nth-child(13),
.pricing-spreadsheet.job_invoice_full_breakdown tr td:nth-child(13),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr td:nth-child(13),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr td:nth-child(13),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr td:nth-child(13),
.credit_note_labour.job_invoice_full_breakdown tr td:nth-child(13),
.credit_note_parts.job_invoice_full_breakdown tr td:nth-child(13),
.credit_note_category_breakdown.job_invoice_full_breakdown tr td:nth-child(13),
.credit_note_full_breakdown.job_invoice_full_breakdown tr td:nth-child(13),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.credit_note_labour.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.credit_note_parts.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(13),
.pricing-spreadsheet.job_invoice_category_breakdown tr td:nth-child(13),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(13),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(13),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(13),
.credit_note_labour.job_invoice_category_breakdown tr td:nth-child(13),
.credit_note_parts.job_invoice_category_breakdown tr td:nth-child(13),
.credit_note_category_breakdown.job_invoice_category_breakdown tr td:nth-child(13),
.credit_note_full_breakdown.job_invoice_category_breakdown tr td:nth-child(13),
.pricing-spreadsheet.job_invoice_no_breakdown tr td:nth-child(13),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr td:nth-child(13),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr td:nth-child(13),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr td:nth-child(13),
.credit_note_labour.job_invoice_no_breakdown tr td:nth-child(13),
.credit_note_parts.job_invoice_no_breakdown tr td:nth-child(13),
.credit_note_category_breakdown.job_invoice_no_breakdown tr td:nth-child(13),
.credit_note_full_breakdown.job_invoice_no_breakdown tr td:nth-child(13) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.pricing-spreadsheet.job_labour tr.top-merged-row + .child-merged-row td,
.job_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td,
.job_multiple_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td,
.job_invoice_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td,
.job_invoice_no_breakdown.job_labour tr.top-merged-row + .child-merged-row td,
.credit_note_labour.job_labour tr.top-merged-row + .child-merged-row td,
.credit_note_parts.job_labour tr.top-merged-row + .child-merged-row td,
.credit_note_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td,
.credit_note_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td,
.pricing-spreadsheet.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_labour.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_parts.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_labour.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_parts.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td,
.pricing-spreadsheet.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_labour.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_parts.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td,
.pricing-spreadsheet.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_labour.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_parts.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td,
.credit_note_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td {
    background-image: url("../images/null.png");
    background-repeat: repeat-x;
}
.pricing-spreadsheet.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_multiple_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_no_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_labour.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_parts.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(1),
.pricing-spreadsheet.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_labour.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_parts.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_labour.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_parts.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.pricing-spreadsheet.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_labour.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_parts.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.pricing-spreadsheet.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_labour.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_parts.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1),
.credit_note_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(1) {
  background: #fbfbfb;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.pricing-spreadsheet.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_no_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_labour.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_parts.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(2),
.pricing-spreadsheet.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_labour.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_parts.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_labour.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_parts.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.pricing-spreadsheet.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_labour.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_parts.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.pricing-spreadsheet.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_labour.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_parts.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2),
.credit_note_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(2) {
  background: #f6f6f6;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08) inset;
  -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08) inset;
}
.pricing-spreadsheet.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_no_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_labour.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_parts.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(10),
.pricing-spreadsheet.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_labour.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_parts.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_labour.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_parts.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.pricing-spreadsheet.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_labour.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_parts.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.pricing-spreadsheet.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_labour.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_parts.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10),
.credit_note_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(10) {
    background: url("../images/null.png"), #f6f6f6;
    background-repeat: repeat-x;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.pricing-spreadsheet.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_no_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_labour.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_parts.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_category_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_full_breakdown.job_labour tr.top-merged-row + .child-merged-row td:nth-child(13),
.pricing-spreadsheet.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_labour.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_parts.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_category_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_full_breakdown.job_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_labour.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_parts.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.pricing-spreadsheet.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_labour.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_parts.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_category_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_full_breakdown.job_invoice_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.pricing-spreadsheet.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_labour.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_parts.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_category_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13),
.credit_note_full_breakdown.job_invoice_no_breakdown tr.top-merged-row + .child-merged-row td:nth-child(13) {
    background: url("../images/null.png") repeat-y right, #f6f6f6;
    -webkit-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
}
.pricing-spreadsheet.job_labour tr.child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.job_labour tr.child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour tr.child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.job_labour tr.child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.job_labour tr.child-merged-row td:nth-child(7),
.credit_note_labour.job_labour tr.child-merged-row td:nth-child(7),
.credit_note_parts.job_labour tr.child-merged-row td:nth-child(7),
.credit_note_category_breakdown.job_labour tr.child-merged-row td:nth-child(7),
.credit_note_full_breakdown.job_labour tr.child-merged-row td:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(7) {
  background-image: none;
  background-repeat: repeat-y;
  background-position: right;
}
.pricing-spreadsheet.job_labour tr.child-merged-row td:nth-child(19),
.job_invoice_full_breakdown.job_labour tr.child-merged-row td:nth-child(19),
.job_multiple_invoice_full_breakdown.job_labour tr.child-merged-row td:nth-child(19),
.job_invoice_category_breakdown.job_labour tr.child-merged-row td:nth-child(19),
.job_invoice_no_breakdown.job_labour tr.child-merged-row td:nth-child(19),
.credit_note_labour.job_labour tr.child-merged-row td:nth-child(19),
.credit_note_parts.job_labour tr.child-merged-row td:nth-child(19),
.credit_note_category_breakdown.job_labour tr.child-merged-row td:nth-child(19),
.credit_note_full_breakdown.job_labour tr.child-merged-row td:nth-child(19),
.pricing-spreadsheet.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_full_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_category_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_no_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_labour.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_parts.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_category_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_full_breakdown.job_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_labour.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_parts.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown tr.child-merged-row td:nth-child(19),
.pricing-spreadsheet.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_full_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_category_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_no_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_labour.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_parts.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_category_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_full_breakdown.job_invoice_category_breakdown tr.child-merged-row td:nth-child(19),
.pricing-spreadsheet.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_full_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_category_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.job_invoice_no_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_labour.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_parts.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_category_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19),
.credit_note_full_breakdown.job_invoice_no_breakdown tr.child-merged-row td:nth-child(19) {
    background-image: url("../images/null.png");
    background-repeat: repeat-y;
    background-position: right;
}
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(2),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(2),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(2),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(2) {
  max-width: auto;
  min-width: 0px;
  width: auto;
}
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(3),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(4),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(4),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(4),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(4),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(5),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(5),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(5),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(5) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(7) {
  max-width: 140px;
  min-width: 140px;
  width: 140px;
}
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr th:nth-child(12),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr th:nth-child(12),
.pricing-spreadsheet.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_no_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_labour.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_parts.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_category_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_full_breakdown.job_labour.job_invoice_category_breakdown tr td:nth-child(12),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_category_breakdown tr td:nth-child(12) {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.pricing-spreadsheet.job_labour.job_invoice_no_breakdown:nth-child(6),
.job_invoice_full_breakdown.job_labour.job_invoice_no_breakdown:nth-child(6),
.job_multiple_invoice_full_breakdown.job_labour.job_invoice_no_breakdown:nth-child(6),
.job_invoice_category_breakdown.job_labour.job_invoice_no_breakdown:nth-child(6),
.job_invoice_no_breakdown.job_labour.job_invoice_no_breakdown:nth-child(6),
.credit_note_labour.job_labour.job_invoice_no_breakdown:nth-child(6),
.credit_note_parts.job_labour.job_invoice_no_breakdown:nth-child(6),
.credit_note_category_breakdown.job_labour.job_invoice_no_breakdown:nth-child(6),
.credit_note_full_breakdown.job_labour.job_invoice_no_breakdown:nth-child(6),
.pricing-spreadsheet.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_labour.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_parts.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown:nth-child(6),
.pricing-spreadsheet.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_labour.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_parts.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_invoice_no_breakdown:nth-child(6),
.pricing-spreadsheet.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_labour.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_parts.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_invoice_no_breakdown:nth-child(6) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}
.pricing-spreadsheet.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_multiple_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_no_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_labour.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_parts.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.pricing-spreadsheet.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_labour.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_parts.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.pricing-spreadsheet.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_labour.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_parts.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.pricing-spreadsheet.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_labour.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_parts.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(6),
.pricing-spreadsheet.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_multiple_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_no_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_labour.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_parts.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.pricing-spreadsheet.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_labour.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_parts.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.pricing-spreadsheet.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_labour.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_parts.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.pricing-spreadsheet.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_labour.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_parts.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(6),
.pricing-spreadsheet.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.pricing-spreadsheet.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_labour.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_parts.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(7) {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}
.pricing-spreadsheet.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_multiple_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_no_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_labour.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_parts.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.pricing-spreadsheet.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_labour.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_parts.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.pricing-spreadsheet.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_labour.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_parts.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.pricing-spreadsheet.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_labour.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_parts.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr th:nth-child(18),
.pricing-spreadsheet.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_multiple_invoice_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_no_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_labour.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_parts.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_category_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_full_breakdown.job_labour.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.pricing-spreadsheet.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_multiple_invoice_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_no_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_labour.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_parts.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_category_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_full_breakdown.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.pricing-spreadsheet.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_labour.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_parts.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_category_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_full_breakdown.job_multiple_invoice_full_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.pricing-spreadsheet.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_multiple_invoice_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_no_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_labour.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_parts.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_category_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_full_breakdown.job_invoice_category_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.pricing-spreadsheet.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_multiple_invoice_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.job_invoice_no_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_labour.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_parts.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_category_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18),
.credit_note_full_breakdown.job_invoice_no_breakdown.job_multiple_invoice_full_breakdown tr td:nth-child(18) {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}
.pricing-spreadsheet.job_parts tr th:nth-child(3),
.job_invoice_full_breakdown.job_parts tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.job_parts tr th:nth-child(3),
.job_invoice_category_breakdown.job_parts tr th:nth-child(3),
.job_invoice_no_breakdown.job_parts tr th:nth-child(3),
.credit_note_labour.job_parts tr th:nth-child(3),
.credit_note_parts.job_parts tr th:nth-child(3),
.credit_note_category_breakdown.job_parts tr th:nth-child(3),
.credit_note_full_breakdown.job_parts tr th:nth-child(3),
.pricing-spreadsheet.credit_note_labour tr th:nth-child(3),
.job_invoice_full_breakdown.credit_note_labour tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_labour tr th:nth-child(3),
.job_invoice_category_breakdown.credit_note_labour tr th:nth-child(3),
.job_invoice_no_breakdown.credit_note_labour tr th:nth-child(3),
.credit_note_labour.credit_note_labour tr th:nth-child(3),
.credit_note_parts.credit_note_labour tr th:nth-child(3),
.credit_note_category_breakdown.credit_note_labour tr th:nth-child(3),
.credit_note_full_breakdown.credit_note_labour tr th:nth-child(3),
.pricing-spreadsheet.credit_note_parts tr th:nth-child(3),
.job_invoice_full_breakdown.credit_note_parts tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_parts tr th:nth-child(3),
.job_invoice_category_breakdown.credit_note_parts tr th:nth-child(3),
.job_invoice_no_breakdown.credit_note_parts tr th:nth-child(3),
.credit_note_labour.credit_note_parts tr th:nth-child(3),
.credit_note_parts.credit_note_parts tr th:nth-child(3),
.credit_note_category_breakdown.credit_note_parts tr th:nth-child(3),
.credit_note_full_breakdown.credit_note_parts tr th:nth-child(3),
.pricing-spreadsheet.credit_note_full_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.credit_note_full_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.credit_note_full_breakdown tr th:nth-child(3),
.credit_note_labour.credit_note_full_breakdown tr th:nth-child(3),
.credit_note_parts.credit_note_full_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.credit_note_full_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.credit_note_full_breakdown tr th:nth-child(3),
.pricing-spreadsheet.job_parts tr td:nth-child(3),
.job_invoice_full_breakdown.job_parts tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.job_parts tr td:nth-child(3),
.job_invoice_category_breakdown.job_parts tr td:nth-child(3),
.job_invoice_no_breakdown.job_parts tr td:nth-child(3),
.credit_note_labour.job_parts tr td:nth-child(3),
.credit_note_parts.job_parts tr td:nth-child(3),
.credit_note_category_breakdown.job_parts tr td:nth-child(3),
.credit_note_full_breakdown.job_parts tr td:nth-child(3),
.pricing-spreadsheet.credit_note_labour tr td:nth-child(3),
.job_invoice_full_breakdown.credit_note_labour tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_labour tr td:nth-child(3),
.job_invoice_category_breakdown.credit_note_labour tr td:nth-child(3),
.job_invoice_no_breakdown.credit_note_labour tr td:nth-child(3),
.credit_note_labour.credit_note_labour tr td:nth-child(3),
.credit_note_parts.credit_note_labour tr td:nth-child(3),
.credit_note_category_breakdown.credit_note_labour tr td:nth-child(3),
.credit_note_full_breakdown.credit_note_labour tr td:nth-child(3),
.pricing-spreadsheet.credit_note_parts tr td:nth-child(3),
.job_invoice_full_breakdown.credit_note_parts tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_parts tr td:nth-child(3),
.job_invoice_category_breakdown.credit_note_parts tr td:nth-child(3),
.job_invoice_no_breakdown.credit_note_parts tr td:nth-child(3),
.credit_note_labour.credit_note_parts tr td:nth-child(3),
.credit_note_parts.credit_note_parts tr td:nth-child(3),
.credit_note_category_breakdown.credit_note_parts tr td:nth-child(3),
.credit_note_full_breakdown.credit_note_parts tr td:nth-child(3),
.pricing-spreadsheet.credit_note_full_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.credit_note_full_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.credit_note_full_breakdown tr td:nth-child(3),
.credit_note_labour.credit_note_full_breakdown tr td:nth-child(3),
.credit_note_parts.credit_note_full_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.credit_note_full_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.credit_note_full_breakdown tr td:nth-child(3) {
  min-width: 200px;
  white-space: normal;
}
.pricing-spreadsheet.job_parts tr th:nth-child(7),
.job_invoice_full_breakdown.job_parts tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.job_parts tr th:nth-child(7),
.job_invoice_category_breakdown.job_parts tr th:nth-child(7),
.job_invoice_no_breakdown.job_parts tr th:nth-child(7),
.credit_note_labour.job_parts tr th:nth-child(7),
.credit_note_parts.job_parts tr th:nth-child(7),
.credit_note_category_breakdown.job_parts tr th:nth-child(7),
.credit_note_full_breakdown.job_parts tr th:nth-child(7),
.pricing-spreadsheet.credit_note_labour tr th:nth-child(7),
.job_invoice_full_breakdown.credit_note_labour tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_labour tr th:nth-child(7),
.job_invoice_category_breakdown.credit_note_labour tr th:nth-child(7),
.job_invoice_no_breakdown.credit_note_labour tr th:nth-child(7),
.credit_note_labour.credit_note_labour tr th:nth-child(7),
.credit_note_parts.credit_note_labour tr th:nth-child(7),
.credit_note_category_breakdown.credit_note_labour tr th:nth-child(7),
.credit_note_full_breakdown.credit_note_labour tr th:nth-child(7),
.pricing-spreadsheet.credit_note_parts tr th:nth-child(7),
.job_invoice_full_breakdown.credit_note_parts tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_parts tr th:nth-child(7),
.job_invoice_category_breakdown.credit_note_parts tr th:nth-child(7),
.job_invoice_no_breakdown.credit_note_parts tr th:nth-child(7),
.credit_note_labour.credit_note_parts tr th:nth-child(7),
.credit_note_parts.credit_note_parts tr th:nth-child(7),
.credit_note_category_breakdown.credit_note_parts tr th:nth-child(7),
.credit_note_full_breakdown.credit_note_parts tr th:nth-child(7),
.pricing-spreadsheet.credit_note_full_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.credit_note_full_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.credit_note_full_breakdown tr th:nth-child(7),
.credit_note_labour.credit_note_full_breakdown tr th:nth-child(7),
.credit_note_parts.credit_note_full_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.credit_note_full_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.credit_note_full_breakdown tr th:nth-child(7),
.pricing-spreadsheet.job_parts tr td:nth-child(7),
.job_invoice_full_breakdown.job_parts tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_parts tr td:nth-child(7),
.job_invoice_category_breakdown.job_parts tr td:nth-child(7),
.job_invoice_no_breakdown.job_parts tr td:nth-child(7),
.credit_note_labour.job_parts tr td:nth-child(7),
.credit_note_parts.job_parts tr td:nth-child(7),
.credit_note_category_breakdown.job_parts tr td:nth-child(7),
.credit_note_full_breakdown.job_parts tr td:nth-child(7),
.pricing-spreadsheet.credit_note_labour tr td:nth-child(7),
.job_invoice_full_breakdown.credit_note_labour tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_labour tr td:nth-child(7),
.job_invoice_category_breakdown.credit_note_labour tr td:nth-child(7),
.job_invoice_no_breakdown.credit_note_labour tr td:nth-child(7),
.credit_note_labour.credit_note_labour tr td:nth-child(7),
.credit_note_parts.credit_note_labour tr td:nth-child(7),
.credit_note_category_breakdown.credit_note_labour tr td:nth-child(7),
.credit_note_full_breakdown.credit_note_labour tr td:nth-child(7),
.pricing-spreadsheet.credit_note_parts tr td:nth-child(7),
.job_invoice_full_breakdown.credit_note_parts tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_parts tr td:nth-child(7),
.job_invoice_category_breakdown.credit_note_parts tr td:nth-child(7),
.job_invoice_no_breakdown.credit_note_parts tr td:nth-child(7),
.credit_note_labour.credit_note_parts tr td:nth-child(7),
.credit_note_parts.credit_note_parts tr td:nth-child(7),
.credit_note_category_breakdown.credit_note_parts tr td:nth-child(7),
.credit_note_full_breakdown.credit_note_parts tr td:nth-child(7),
.pricing-spreadsheet.credit_note_full_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.credit_note_full_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.credit_note_full_breakdown tr td:nth-child(7),
.credit_note_labour.credit_note_full_breakdown tr td:nth-child(7),
.credit_note_parts.credit_note_full_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.credit_note_full_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.credit_note_full_breakdown tr td:nth-child(7) {
  max-width: 140px;
  min-width: 140px;
  width: 140px;
}
.pricing-spreadsheet.job_parts tr th:nth-child(8),
.job_invoice_full_breakdown.job_parts tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.job_parts tr th:nth-child(8),
.job_invoice_category_breakdown.job_parts tr th:nth-child(8),
.job_invoice_no_breakdown.job_parts tr th:nth-child(8),
.credit_note_labour.job_parts tr th:nth-child(8),
.credit_note_parts.job_parts tr th:nth-child(8),
.credit_note_category_breakdown.job_parts tr th:nth-child(8),
.credit_note_full_breakdown.job_parts tr th:nth-child(8),
.pricing-spreadsheet.credit_note_labour tr th:nth-child(8),
.job_invoice_full_breakdown.credit_note_labour tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.credit_note_labour tr th:nth-child(8),
.job_invoice_category_breakdown.credit_note_labour tr th:nth-child(8),
.job_invoice_no_breakdown.credit_note_labour tr th:nth-child(8),
.credit_note_labour.credit_note_labour tr th:nth-child(8),
.credit_note_parts.credit_note_labour tr th:nth-child(8),
.credit_note_category_breakdown.credit_note_labour tr th:nth-child(8),
.credit_note_full_breakdown.credit_note_labour tr th:nth-child(8),
.pricing-spreadsheet.credit_note_parts tr th:nth-child(8),
.job_invoice_full_breakdown.credit_note_parts tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.credit_note_parts tr th:nth-child(8),
.job_invoice_category_breakdown.credit_note_parts tr th:nth-child(8),
.job_invoice_no_breakdown.credit_note_parts tr th:nth-child(8),
.credit_note_labour.credit_note_parts tr th:nth-child(8),
.credit_note_parts.credit_note_parts tr th:nth-child(8),
.credit_note_category_breakdown.credit_note_parts tr th:nth-child(8),
.credit_note_full_breakdown.credit_note_parts tr th:nth-child(8),
.pricing-spreadsheet.credit_note_full_breakdown tr th:nth-child(8),
.job_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(8),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(8),
.job_invoice_category_breakdown.credit_note_full_breakdown tr th:nth-child(8),
.job_invoice_no_breakdown.credit_note_full_breakdown tr th:nth-child(8),
.credit_note_labour.credit_note_full_breakdown tr th:nth-child(8),
.credit_note_parts.credit_note_full_breakdown tr th:nth-child(8),
.credit_note_category_breakdown.credit_note_full_breakdown tr th:nth-child(8),
.credit_note_full_breakdown.credit_note_full_breakdown tr th:nth-child(8),
.pricing-spreadsheet.job_parts tr td:nth-child(8),
.job_invoice_full_breakdown.job_parts tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.job_parts tr td:nth-child(8),
.job_invoice_category_breakdown.job_parts tr td:nth-child(8),
.job_invoice_no_breakdown.job_parts tr td:nth-child(8),
.credit_note_labour.job_parts tr td:nth-child(8),
.credit_note_parts.job_parts tr td:nth-child(8),
.credit_note_category_breakdown.job_parts tr td:nth-child(8),
.credit_note_full_breakdown.job_parts tr td:nth-child(8),
.pricing-spreadsheet.credit_note_labour tr td:nth-child(8),
.job_invoice_full_breakdown.credit_note_labour tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.credit_note_labour tr td:nth-child(8),
.job_invoice_category_breakdown.credit_note_labour tr td:nth-child(8),
.job_invoice_no_breakdown.credit_note_labour tr td:nth-child(8),
.credit_note_labour.credit_note_labour tr td:nth-child(8),
.credit_note_parts.credit_note_labour tr td:nth-child(8),
.credit_note_category_breakdown.credit_note_labour tr td:nth-child(8),
.credit_note_full_breakdown.credit_note_labour tr td:nth-child(8),
.pricing-spreadsheet.credit_note_parts tr td:nth-child(8),
.job_invoice_full_breakdown.credit_note_parts tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.credit_note_parts tr td:nth-child(8),
.job_invoice_category_breakdown.credit_note_parts tr td:nth-child(8),
.job_invoice_no_breakdown.credit_note_parts tr td:nth-child(8),
.credit_note_labour.credit_note_parts tr td:nth-child(8),
.credit_note_parts.credit_note_parts tr td:nth-child(8),
.credit_note_category_breakdown.credit_note_parts tr td:nth-child(8),
.credit_note_full_breakdown.credit_note_parts tr td:nth-child(8),
.pricing-spreadsheet.credit_note_full_breakdown tr td:nth-child(8),
.job_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(8),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(8),
.job_invoice_category_breakdown.credit_note_full_breakdown tr td:nth-child(8),
.job_invoice_no_breakdown.credit_note_full_breakdown tr td:nth-child(8),
.credit_note_labour.credit_note_full_breakdown tr td:nth-child(8),
.credit_note_parts.credit_note_full_breakdown tr td:nth-child(8),
.credit_note_category_breakdown.credit_note_full_breakdown tr td:nth-child(8),
.credit_note_full_breakdown.credit_note_full_breakdown tr td:nth-child(8) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}
.pricing-spreadsheet.job_parts tr th:nth-child(14),
.job_invoice_full_breakdown.job_parts tr th:nth-child(14),
.job_multiple_invoice_full_breakdown.job_parts tr th:nth-child(14),
.job_invoice_category_breakdown.job_parts tr th:nth-child(14),
.job_invoice_no_breakdown.job_parts tr th:nth-child(14),
.credit_note_labour.job_parts tr th:nth-child(14),
.credit_note_parts.job_parts tr th:nth-child(14),
.credit_note_category_breakdown.job_parts tr th:nth-child(14),
.credit_note_full_breakdown.job_parts tr th:nth-child(14),
.pricing-spreadsheet.credit_note_labour tr th:nth-child(14),
.job_invoice_full_breakdown.credit_note_labour tr th:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_labour tr th:nth-child(14),
.job_invoice_category_breakdown.credit_note_labour tr th:nth-child(14),
.job_invoice_no_breakdown.credit_note_labour tr th:nth-child(14),
.credit_note_labour.credit_note_labour tr th:nth-child(14),
.credit_note_parts.credit_note_labour tr th:nth-child(14),
.credit_note_category_breakdown.credit_note_labour tr th:nth-child(14),
.credit_note_full_breakdown.credit_note_labour tr th:nth-child(14),
.pricing-spreadsheet.credit_note_parts tr th:nth-child(14),
.job_invoice_full_breakdown.credit_note_parts tr th:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_parts tr th:nth-child(14),
.job_invoice_category_breakdown.credit_note_parts tr th:nth-child(14),
.job_invoice_no_breakdown.credit_note_parts tr th:nth-child(14),
.credit_note_labour.credit_note_parts tr th:nth-child(14),
.credit_note_parts.credit_note_parts tr th:nth-child(14),
.credit_note_category_breakdown.credit_note_parts tr th:nth-child(14),
.credit_note_full_breakdown.credit_note_parts tr th:nth-child(14),
.pricing-spreadsheet.credit_note_full_breakdown tr th:nth-child(14),
.job_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr th:nth-child(14),
.job_invoice_category_breakdown.credit_note_full_breakdown tr th:nth-child(14),
.job_invoice_no_breakdown.credit_note_full_breakdown tr th:nth-child(14),
.credit_note_labour.credit_note_full_breakdown tr th:nth-child(14),
.credit_note_parts.credit_note_full_breakdown tr th:nth-child(14),
.credit_note_category_breakdown.credit_note_full_breakdown tr th:nth-child(14),
.credit_note_full_breakdown.credit_note_full_breakdown tr th:nth-child(14),
.pricing-spreadsheet.job_parts tr td:nth-child(14),
.job_invoice_full_breakdown.job_parts tr td:nth-child(14),
.job_multiple_invoice_full_breakdown.job_parts tr td:nth-child(14),
.job_invoice_category_breakdown.job_parts tr td:nth-child(14),
.job_invoice_no_breakdown.job_parts tr td:nth-child(14),
.credit_note_labour.job_parts tr td:nth-child(14),
.credit_note_parts.job_parts tr td:nth-child(14),
.credit_note_category_breakdown.job_parts tr td:nth-child(14),
.credit_note_full_breakdown.job_parts tr td:nth-child(14),
.pricing-spreadsheet.credit_note_labour tr td:nth-child(14),
.job_invoice_full_breakdown.credit_note_labour tr td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_labour tr td:nth-child(14),
.job_invoice_category_breakdown.credit_note_labour tr td:nth-child(14),
.job_invoice_no_breakdown.credit_note_labour tr td:nth-child(14),
.credit_note_labour.credit_note_labour tr td:nth-child(14),
.credit_note_parts.credit_note_labour tr td:nth-child(14),
.credit_note_category_breakdown.credit_note_labour tr td:nth-child(14),
.credit_note_full_breakdown.credit_note_labour tr td:nth-child(14),
.pricing-spreadsheet.credit_note_parts tr td:nth-child(14),
.job_invoice_full_breakdown.credit_note_parts tr td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_parts tr td:nth-child(14),
.job_invoice_category_breakdown.credit_note_parts tr td:nth-child(14),
.job_invoice_no_breakdown.credit_note_parts tr td:nth-child(14),
.credit_note_labour.credit_note_parts tr td:nth-child(14),
.credit_note_parts.credit_note_parts tr td:nth-child(14),
.credit_note_category_breakdown.credit_note_parts tr td:nth-child(14),
.credit_note_full_breakdown.credit_note_parts tr td:nth-child(14),
.pricing-spreadsheet.credit_note_full_breakdown tr td:nth-child(14),
.job_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr td:nth-child(14),
.job_invoice_category_breakdown.credit_note_full_breakdown tr td:nth-child(14),
.job_invoice_no_breakdown.credit_note_full_breakdown tr td:nth-child(14),
.credit_note_labour.credit_note_full_breakdown tr td:nth-child(14),
.credit_note_parts.credit_note_full_breakdown tr td:nth-child(14),
.credit_note_category_breakdown.credit_note_full_breakdown tr td:nth-child(14),
.credit_note_full_breakdown.credit_note_full_breakdown tr td:nth-child(14) {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}
.pricing-spreadsheet.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_labour.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_parts.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_category_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_full_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.pricing-spreadsheet.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_labour.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_parts.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_category_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_full_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(7),
.pricing-spreadsheet.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_labour.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_parts.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_category_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_full_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(7),
.pricing-spreadsheet.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_labour.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_parts.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_category_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_full_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7) {
    background: url("../images/null.png"), #f6f6f6 !important;
    background-repeat: repeat-x !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.pricing-spreadsheet.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_full_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_multiple_invoice_full_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_category_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_no_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_labour.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_parts.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_category_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_full_breakdown.job_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.pricing-spreadsheet.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_full_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_category_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_no_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_labour.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_parts.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_category_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_full_breakdown.credit_note_labour tr.top-merged-row + .child-merged-row td:nth-child(14),
.pricing-spreadsheet.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_full_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_category_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_no_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_labour.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_parts.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_category_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_full_breakdown.credit_note_parts tr.top-merged-row + .child-merged-row td:nth-child(14),
.pricing-spreadsheet.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_full_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_category_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_no_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_labour.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_parts.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_category_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_full_breakdown.credit_note_full_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14) {
    background: url("../images/null.png") repeat-y right, #f6f6f6;
    -webkit-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
}
.pricing-spreadsheet.credit_note_category_breakdown tr th:nth-child(3),
.job_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(3),
.job_invoice_category_breakdown.credit_note_category_breakdown tr th:nth-child(3),
.job_invoice_no_breakdown.credit_note_category_breakdown tr th:nth-child(3),
.credit_note_labour.credit_note_category_breakdown tr th:nth-child(3),
.credit_note_parts.credit_note_category_breakdown tr th:nth-child(3),
.credit_note_category_breakdown.credit_note_category_breakdown tr th:nth-child(3),
.credit_note_full_breakdown.credit_note_category_breakdown tr th:nth-child(3),
.pricing-spreadsheet.credit_note_category_breakdown tr td:nth-child(3),
.job_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(3),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(3),
.job_invoice_category_breakdown.credit_note_category_breakdown tr td:nth-child(3),
.job_invoice_no_breakdown.credit_note_category_breakdown tr td:nth-child(3),
.credit_note_labour.credit_note_category_breakdown tr td:nth-child(3),
.credit_note_parts.credit_note_category_breakdown tr td:nth-child(3),
.credit_note_category_breakdown.credit_note_category_breakdown tr td:nth-child(3),
.credit_note_full_breakdown.credit_note_category_breakdown tr td:nth-child(3) {
  min-width: 200px;
  white-space: normal;
}
.pricing-spreadsheet.credit_note_category_breakdown tr th:nth-child(6),
.job_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(6),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(6),
.job_invoice_category_breakdown.credit_note_category_breakdown tr th:nth-child(6),
.job_invoice_no_breakdown.credit_note_category_breakdown tr th:nth-child(6),
.credit_note_labour.credit_note_category_breakdown tr th:nth-child(6),
.credit_note_parts.credit_note_category_breakdown tr th:nth-child(6),
.credit_note_category_breakdown.credit_note_category_breakdown tr th:nth-child(6),
.credit_note_full_breakdown.credit_note_category_breakdown tr th:nth-child(6),
.pricing-spreadsheet.credit_note_category_breakdown tr td:nth-child(6),
.job_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(6),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(6),
.job_invoice_category_breakdown.credit_note_category_breakdown tr td:nth-child(6),
.job_invoice_no_breakdown.credit_note_category_breakdown tr td:nth-child(6),
.credit_note_labour.credit_note_category_breakdown tr td:nth-child(6),
.credit_note_parts.credit_note_category_breakdown tr td:nth-child(6),
.credit_note_category_breakdown.credit_note_category_breakdown tr td:nth-child(6),
.credit_note_full_breakdown.credit_note_category_breakdown tr td:nth-child(6),
.pricing-spreadsheet.credit_note_category_breakdown tr th:nth-child(7),
.job_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(7),
.job_invoice_category_breakdown.credit_note_category_breakdown tr th:nth-child(7),
.job_invoice_no_breakdown.credit_note_category_breakdown tr th:nth-child(7),
.credit_note_labour.credit_note_category_breakdown tr th:nth-child(7),
.credit_note_parts.credit_note_category_breakdown tr th:nth-child(7),
.credit_note_category_breakdown.credit_note_category_breakdown tr th:nth-child(7),
.credit_note_full_breakdown.credit_note_category_breakdown tr th:nth-child(7),
.pricing-spreadsheet.credit_note_category_breakdown tr td:nth-child(7),
.job_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(7),
.job_invoice_category_breakdown.credit_note_category_breakdown tr td:nth-child(7),
.job_invoice_no_breakdown.credit_note_category_breakdown tr td:nth-child(7),
.credit_note_labour.credit_note_category_breakdown tr td:nth-child(7),
.credit_note_parts.credit_note_category_breakdown tr td:nth-child(7),
.credit_note_category_breakdown.credit_note_category_breakdown tr td:nth-child(7),
.credit_note_full_breakdown.credit_note_category_breakdown tr td:nth-child(7) {
  max-width: 140px;
  min-width: 140px;
  width: 140px;
}
.pricing-spreadsheet.credit_note_category_breakdown tr th:nth-child(9),
.job_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(9),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr th:nth-child(9),
.job_invoice_category_breakdown.credit_note_category_breakdown tr th:nth-child(9),
.job_invoice_no_breakdown.credit_note_category_breakdown tr th:nth-child(9),
.credit_note_labour.credit_note_category_breakdown tr th:nth-child(9),
.credit_note_parts.credit_note_category_breakdown tr th:nth-child(9),
.credit_note_category_breakdown.credit_note_category_breakdown tr th:nth-child(9),
.credit_note_full_breakdown.credit_note_category_breakdown tr th:nth-child(9),
.pricing-spreadsheet.credit_note_category_breakdown tr td:nth-child(9),
.job_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(9),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr td:nth-child(9),
.job_invoice_category_breakdown.credit_note_category_breakdown tr td:nth-child(9),
.job_invoice_no_breakdown.credit_note_category_breakdown tr td:nth-child(9),
.credit_note_labour.credit_note_category_breakdown tr td:nth-child(9),
.credit_note_parts.credit_note_category_breakdown tr td:nth-child(9),
.credit_note_category_breakdown.credit_note_category_breakdown tr td:nth-child(9),
.credit_note_full_breakdown.credit_note_category_breakdown tr td:nth-child(9) {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}
.pricing-spreadsheet.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_full_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_category_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.job_invoice_no_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_labour.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_parts.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_category_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7),
.credit_note_full_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(7) {
    background: url("../images/null.png"), #f6f6f6 !important;
    background-repeat: repeat-x !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.pricing-spreadsheet.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_full_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_multiple_invoice_full_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_category_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.job_invoice_no_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_labour.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_parts.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_category_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14),
.credit_note_full_breakdown.credit_note_category_breakdown tr.top-merged-row + .child-merged-row td:nth-child(14) {
    background: url("../images/null.png") repeat-y right, #f6f6f6;
    -webkit-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
    box-shadow: 0 5px 9px -7px rgba(0, 0, 0, 0.3) inset;
}
.invoice-schedule-spreadsheet thead th:nth-child(2),
.invoice-schedule-spreadsheet thead th:nth-child(3),
.invoice-schedule-spreadsheet thead th:nth-child(4) {
  width: 32%;
}
.spreadsheet-totals-wrapper table {
  margin-top: -1px;
  background: transparent;
}
.spreadsheet-totals-wrapper table thead {
  border-bottom: 0;
  background: #e1e1e1;
}
.spreadsheet-totals-wrapper table thead th {
  padding: 0 10px;
}
.spreadsheet-totals-wrapper table tbody td {
  border: 0;
  font-weight: bold;
  background: transparent;
}
.spreadsheet-totals-wrapper table tbody td.number-cell {
  background: transparent !important;
}
.spreadsheet-totals-wrapper table tbody td.with-left-border {
  border-left: 1px solid #e1e1e1;
}
.spreadsheet-totals-wrapper table tbody td.with-right-border {
  border-right: 1px solid #e1e1e1;
}
.spreadsheet-totals-wrapper table tbody td:hover {
  cursor: default;
}
.spreadsheet-totals-wrapper .purchase-orders-parts-spreadsheet th:nth-child(9),
.spreadsheet-totals-wrapper .purchase-orders-parts-spreadsheet td:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.spreadsheet-totals-wrapper .purchase-orders-parts-spreadsheet tbody tr td:nth-child(7),
.spreadsheet-totals-wrapper .purchase-orders-parts-spreadsheet tbody tr td:nth-child(8) {
  min-width: 100px;
}
.spreadsheet-totals-wrapper .purchase-orders-items-spreadsheet th:nth-child(8),
.spreadsheet-totals-wrapper .purchase-orders-items-spreadsheet td:nth-child(8) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.spreadsheet-totals-wrapper .purchase-orders-items-spreadsheet tbody tr td:nth-child(6),
.spreadsheet-totals-wrapper .purchase-orders-items-spreadsheet tbody tr td:nth-child(7) {
  min-width: 100px;
}
.spreadsheet-totals-wrapper .supplier-cash-allocation-spreadsheet th:nth-child(9),
.spreadsheet-totals-wrapper .supplier-cash-allocation-spreadsheet td:nth-child(9) {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.spreadsheet-totals-wrapper .supplier-cash-allocation-spreadsheet tbody tr td:nth-child(5),
.spreadsheet-totals-wrapper .supplier-cash-allocation-spreadsheet tbody tr td:nth-child(6),
.spreadsheet-totals-wrapper .supplier-cash-allocation-spreadsheet tbody tr td:nth-child(7),
.spreadsheet-totals-wrapper .supplier-cash-allocation-spreadsheet tbody tr td:nth-child(8) {
  min-width: 100px;
}
table.grand-total-table {
  margin: 0 0 20px 0;
}
table.grand-total-table thead {
  background: none;
}
table.grand-total-table thead th {
  height: 35px;
  background: none;
}
table.grand-total-table.costs-spreadsheet thead th:nth-child(8),
table.grand-total-table.costs-spreadsheet thead th:nth-child(9),
table.grand-total-table.costs-spreadsheet thead th:nth-child(10),
table.grand-total-table.costs-spreadsheet thead th:nth-child(11) {
  background: #fbfbfb;
}
table.grand-total-table.costs-spreadsheet tr td:nth-child(8),
table.grand-total-table.costs-spreadsheet tr td:nth-child(9),
table.grand-total-table.costs-spreadsheet tr td:nth-child(10),
table.grand-total-table.costs-spreadsheet tr td:nth-child(11) {
  background: #ffffff;
  -webkit-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
}
table.grand-total-table.costs-spreadsheet tr td:nth-child(8).cell-with-green-bg,
table.grand-total-table.costs-spreadsheet tr td:nth-child(9).cell-with-green-bg,
table.grand-total-table.costs-spreadsheet tr td:nth-child(10).cell-with-green-bg,
table.grand-total-table.costs-spreadsheet tr td:nth-child(11).cell-with-green-bg {
  background: #e4f5e5;
}
table.grand-total-table.costs-spreadsheet tr td:nth-child(8).cell-with-red-bg,
table.grand-total-table.costs-spreadsheet tr td:nth-child(9).cell-with-red-bg,
table.grand-total-table.costs-spreadsheet tr td:nth-child(10).cell-with-red-bg,
table.grand-total-table.costs-spreadsheet tr td:nth-child(11).cell-with-red-bg {
  background: #f2cfcf;
}
table.grand-total-table.purchase-orders-spreadsheet thead th {
  height: 0px;
  background: none;
  width: auto;
  min-width: 0;
  max-width: none;
  border: 0;
}
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(10),
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(11) {
  background: #ffffff;
  -webkit-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  min-width: 150px;
  max-width: 150px;
  width: 150px;
  display: table-cell;
}
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(10).cell-with-green-bg,
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(11).cell-with-green-bg {
  background: #e4f5e5;
}
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(10).cell-with-red-bg,
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(11).cell-with-red-bg {
  background: #f2cfcf;
}
table.grand-total-table.purchase-orders-spreadsheet tr td:nth-child(10) {
  background: #fbfbfb;
  min-width: 150px;
  max-width: 150px;
  width: 150px;
  display: table-cell;
  text-align: left;
}
table.grand-total-table.pricing-spreadsheet thead th:nth-child(4),
table.grand-total-table.pricing-spreadsheet thead th:nth-child(5),
table.grand-total-table.pricing-spreadsheet thead th:nth-child(6) {
  background: #fbfbfb;
}
table.grand-total-table.pricing-spreadsheet tr td:nth-child(4),
table.grand-total-table.pricing-spreadsheet tr td:nth-child(5),
table.grand-total-table.pricing-spreadsheet tr td:nth-child(6) {
  background: #ffffff;
  -webkit-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
}
table.grand-total-table.pricing-spreadsheet tr td:nth-child(4).cell-with-green-bg,
table.grand-total-table.pricing-spreadsheet tr td:nth-child(5).cell-with-green-bg,
table.grand-total-table.pricing-spreadsheet tr td:nth-child(6).cell-with-green-bg {
  background: #e4f5e5;
}
table.grand-total-table.pricing-spreadsheet tr td:nth-child(4).cell-with-red-bg,
table.grand-total-table.pricing-spreadsheet tr td:nth-child(5).cell-with-red-bg,
table.grand-total-table.pricing-spreadsheet tr td:nth-child(6).cell-with-red-bg {
  background: #f2cfcf;
}
@media screen and (max-width: 1629px) {
  table.grand-total-table.costs-spreadsheet thead th:nth-child(8),
  table.grand-total-table.costs-spreadsheet thead th:nth-child(9),
  table.grand-total-table.costs-spreadsheet thead th:nth-child(10) {
    letter-spacing: 0;
  }
  table.grand-total-table.costs-spreadsheet td,
  table.grand-total-table.costs-spreadsheet th {
    white-space: initial;
    min-width: initial;
    max-width: initial;
  }
  table.grand-total-table.costs-spreadsheet td:nth-child(8),
  table.grand-total-table.costs-spreadsheet th:nth-child(8),
  table.grand-total-table.costs-spreadsheet td:nth-child(9),
  table.grand-total-table.costs-spreadsheet th:nth-child(9) {
    min-width: 74px;
    max-width: 74px;
    width: 74px;
  }
  table.grand-total-table.costs-spreadsheet td:nth-child(10),
  table.grand-total-table.costs-spreadsheet th:nth-child(10) {
    min-width: 181px;
    max-width: 181px;
    width: 181px;
    display: none;
  }
}
.email-widget-wrapper {
  position: fixed;
  bottom: 0;
  -webkit-transform: translate(0px, 569px);
  -moz-transform: translate(0px, 569px);
  -ms-transform: translate(0px, 569px);
  -o-transform: translate(0px, 569px);
  transform: translate(0px, 569px);
  right: 30px;
  z-index: 300;
  width: 700px;
  height: 600px;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  overflow: hidden;
}
.email-widget-wrapper.on-drag-hover * {
  pointer-events: none;
}
.email-widget-wrapper .email-title-bar,
.email-widget-wrapper .attachment-title-bar {
  background: #5e6d7f;
  padding: 1px 15px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  z-index: 300;
}
.email-widget-wrapper .email-title-bar.small,
.email-widget-wrapper .attachment-title-bar.small {
  position: absolute;
  left: 0px;
  top: 5px;
  height: 30px;
  width: 100%;
  opacity: 1;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.email-widget-wrapper .email-title-bar.small h5,
.email-widget-wrapper .attachment-title-bar.small h5 {
  margin-top: 0;
}
.email-widget-wrapper .email-title-bar.attachment-title-bar,
.email-widget-wrapper .attachment-title-bar.attachment-title-bar {
  background: #ffffff;
  color: black;
  border-radius: 0;
  border-bottom: solid 1px #c9c9c9;
}
.email-widget-wrapper .title-bar-actions {
  position: absolute;
  top: 8px;
  right: 13px;
  display: inline-block;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  z-index: 400;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.email-widget-wrapper .title-bar-actions .ss-navigatedown,
.email-widget-wrapper .title-bar-actions .ss-navigateup,
.email-widget-wrapper .title-bar-actions .ss-settings {
  margin-right: 8px;
}
.email-widget-wrapper .title-bar-actions .ss-navigatedown,
.email-widget-wrapper .title-bar-actions .ss-navigateup,
.email-widget-wrapper .title-bar-actions .ss-settings,
.email-widget-wrapper .title-bar-actions .ss-delete {
  cursor: pointer;
}
.email-widget-wrapper .title-bar-actions .ss-navigateup {
  position: relative;
  top: 1px;
}
.email-widget-wrapper .email-content {
  border-left: solid 1px #c9c9c9;
  border-right: solid 1px #c9c9c9;
  position: absolute;
  top: 0;
  -webkit-transform: translate(0px, 42px);
  -moz-transform: translate(0px, 42px);
  -ms-transform: translate(0px, 42px);
  -o-transform: translate(0px, 42px);
  transform: translate(0px, 42px);
  background: #ffffff;
  left: 0;
  right: 0;
  bottom: 0;
}
.email-widget-wrapper .email-content form {
  padding: 0;
  background: #f7f7f7;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.email-widget-wrapper .email-content form .main-recipient,
.email-widget-wrapper .email-content form .cc-email {
  border-bottom: solid 1px #c9c9c9;
  position: relative;
}
.email-widget-wrapper .email-content form .main-recipient .cc-delete-link,
.email-widget-wrapper .email-content form .cc-email .cc-delete-link,
.email-widget-wrapper .email-content form .main-recipient .cc-link,
.email-widget-wrapper .email-content form .cc-email .cc-link {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 15px;
  cursor: pointer;
  color: #3883c1;
}
.email-widget-wrapper .email-content form .main-recipient .cc-delete-link:hover,
.email-widget-wrapper .email-content form .cc-email .cc-delete-link:hover,
.email-widget-wrapper .email-content form .main-recipient .cc-link:hover,
.email-widget-wrapper .email-content form .cc-email .cc-link:hover {
  color: #3276ad;
}
.email-widget-wrapper .email-content form .main-recipient .select2-container,
.email-widget-wrapper .email-content form .cc-email .select2-container {
  width: 100%;
}
.email-widget-wrapper .email-content form .main-recipient .select2-container .select2-choices,
.email-widget-wrapper .email-content form .cc-email .select2-container .select2-choices {
  border: 0;
  padding: 10px 100px 6px 15px;
}
.email-widget-wrapper .email-content form .main-recipient .select2-container .select2-choices input,
.email-widget-wrapper .email-content form .cc-email .select2-container .select2-choices input {
  padding: 0 !important;
  margin: 0 0 4px;
}
.email-widget-wrapper .email-content form .main-recipient .select2-container .select2-choices .select2-search-choice,
.email-widget-wrapper .email-content form .cc-email .select2-container .select2-choices .select2-search-choice {
  margin: 3px 5px 3px 0;
}
.email-widget-wrapper .email-content form .subject {
  border-bottom: solid 1px #c9c9c9;
}
.email-widget-wrapper .email-content form .subject input {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: 42px !important;
  padding: 0 15px !important;
}
.email-widget-wrapper .email-content form .main-message {
  position: relative;
  width: 100%;
  padding-right: 0;
  background: #ffffff;
  padding-bottom: 84px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.email-widget-wrapper .email-content form .main-message .message-area {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.email-widget-wrapper .email-content form .main-message .message-area .mce-tinymce {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.email-widget-wrapper .email-content form .main-message .message-area .mce-tinymce .mce-container-body {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.email-widget-wrapper .email-content form .main-message .message-area .mce-tinymce .mce-container-body .mce-edit-area {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.email-widget-wrapper .email-content form .main-message .message-area .mce-tinymce .mce-container-body .mce-edit-area iframe {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: auto !important;
}
.email-widget-wrapper .email-content form .main-message .message-area .mce-container .mce-panel.mce-last {
  border: 0 !important;
  border-radius: 0 !important;
  margin: 0 7px 0;
  top: 0;
}
.email-widget-wrapper .email-content form .main-message textarea {
  resize: none;
}
.email-widget-wrapper .email-content form .main-message .attached_files_list {
  padding: 3px 10px 5px;
  border-top: solid 1px #c9c9c9;
  margin: 0 0 0 0;
  z-index: 10;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-transform: translate(0px, 84px);
  -moz-transform: translate(0px, 84px);
  -ms-transform: translate(0px, 84px);
  -o-transform: translate(0px, 84px);
  transform: translate(0px, 84px);
  -webkit-transition: all 0.125s ease-out 0.25s;
  -moz-transition: all 0.125s ease-out 0.25s;
  -o-transition: all 0.125s ease-out 0.25s;
  transition: all 0.125s ease-out 0.25s;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.email-widget-wrapper .email-content form .main-message .attached_files_list li {
  background: #f7f7f7 !important;
  list-style-type: none;
  background: #ffffff;
  border: solid 1px #c9c9c9;
  margin: 5px 5px 2px;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.4);
  padding: 3px 10px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
}
.email-widget-wrapper .email-content form .main-message .attached_files_list li .file-size {
  margin-right: 15px;
  display: none;
}
.email-widget-wrapper .email-content form .main-message .attached_files_list li .attachment-title {
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
}
.email-widget-wrapper .email-content form .main-message .attached_files_list li .delete-link {
  top: 6px;
}
.email-widget-wrapper .email-content form .main-message .attached_files_list li.view-more-link .delete-link {
  top: 4px;
}
.email-widget-wrapper .email-content form .ss-dropdown,
.email-widget-wrapper .email-content form .ss-help {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 20;
  font-size: 7px;
  color: #999999;
}
.email-widget-wrapper .email-content form .ss-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 17px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.email-widget-wrapper .email-content form .ss-dropdown:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.06);
}
.email-widget-wrapper .actions {
  position: absolute;
  bottom: 0;
  -webkit-transform: translate(0px, 100px);
  -moz-transform: translate(0px, 100px);
  -ms-transform: translate(0px, 100px);
  -o-transform: translate(0px, 100px);
  transform: translate(0px, 100px);
  height: 42px;
  width: 100%;
  background: #f7f7f7;
  border-top: solid 1px #c9c9c9;
  padding: 8px 15px;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-out 0s;
  -moz-transition: all 0.25s ease-out 0s;
  -o-transition: all 0.25s ease-out 0s;
  transition: all 0.25s ease-out 0s;
}
.email-widget-wrapper .actions .left {
  float: left;
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
}
.email-widget-wrapper .actions .right {
  float: right;
  display: inline-block;
  width: 50%;
  text-align: right;
}
.email-widget-wrapper .attachments-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
  background: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.222);
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.3));
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(0, 0, 0, 0.17)), to(rgba(0, 0, 0, 0.3)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.3));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.3));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.3));
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b000000', endColorstr='#4d000000', GradientType=0);
  opacity: 0;
  -webkit-transition: z-index 0s ease-out 0.25s, opacity 0.25s ease-out 0s, display 0s;
  -moz-transition: z-index 0s ease-out 0.25s, opacity 0.25s ease-out 0s, display 0s;
  -o-transition: z-index 0s ease-out 0.25s, opacity 0.25s ease-out 0s, display 0s;
  transition: z-index 0s ease-out 0.25s, opacity 0.25s ease-out 0s, display 0s;
}
.email-widget-wrapper .attachments-wrapper,
.email-widget-wrapper .attached-files-wrapper,
.email-widget-wrapper .settings-wrapper,
.email-widget-wrapper .errors-wrapper {
  position: absolute;
  z-index: 200;
  background: white;
  top: 42px;
  left: 30px;
  right: 30px;
  height: 460px;
  transform-origin: top;
  -webkit-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0.25s ease-out;
  -moz-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0.25s ease-out;
  -o-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0.25s ease-out;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0.25s ease-out;
  border: solid 1px #c9c9c9;
  border-top: 0;
  -ms-transform: perspective(700px) rotateX(-90deg);
  -webkit-transform: perspective(700px) rotateX(-90deg);
  transform: perspective(700px) rotateX(-90deg);
  opacity: 0;
  overflow: hidden;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
  -webkit-box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.25);
}
.email-widget-wrapper .attachments-wrapper .attachments-content,
.email-widget-wrapper .attached-files-wrapper .attachments-content,
.email-widget-wrapper .settings-wrapper .attachments-content,
.email-widget-wrapper .errors-wrapper .attachments-content {
  position: absolute;
  background: rgba(1, 0, 0, 0.05);
  top: 0;
  bottom: 0;
  padding-bottom: 42px;
  left: 0;
  width: 100%;
}
.email-widget-wrapper .attachments-wrapper .attachments-content strong,
.email-widget-wrapper .attached-files-wrapper .attachments-content strong,
.email-widget-wrapper .settings-wrapper .attachments-content strong,
.email-widget-wrapper .errors-wrapper .attachments-content strong {
  padding: 10px 0 10px 25px;
  display: inline-block;
  box-sizing: border-box;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files {
  position: absolute;
  bottom: 0;
  top: 30px;
  width: 100%;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .search-box-wrapper,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .search-box-wrapper,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .search-box-wrapper,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .search-box-wrapper {
  position: absolute;
  top: 9px;
  height: 42px;
  left: 10px;
  right: 10px;
  background: #eeeeee;
  padding: 0 10px;
  box-sizing: border-box;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  border-top: solid 1px #dadada;
  border-left: solid 1px #dadada;
  border-right: solid 1px #dadada;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .search-box-wrapper .search_box,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .search-box-wrapper .search_box,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .search-box-wrapper .search_box,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .search-box-wrapper .search_box {
  border: 1px solid #b8bec8;
  width: 100%;
  margin: 10px 0;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .search-box-wrapper .search_box input,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .search-box-wrapper .search_box input,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .search-box-wrapper .search_box input,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .search-box-wrapper .search_box input {
  width: 100%;
  margin-left: 0;
  position: absolute;
  left: 32px;
  top: 2px;
  right: 0;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .no-results,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .no-results,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .no-results,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .no-results {
  padding-top: 55px;
  background: #ffffff;
  margin-top: 51px;
  padding-bottom: 40px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  border: solid 1px #dadada;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list {
  position: absolute;
  left: 0;
  right: 0;
  margin: 10px;
  top: 41px;
  bottom: 30px;
  overflow: auto;
  background: transparent;
  border: 0;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  border-top: solid 1px #dadada;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list li,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list li,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list li,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list li {
  border-left: solid 1px #dadada;
  border-right: solid 1px #dadada;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list li:first-of-type,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list li:first-of-type,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list li:first-of-type,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list li:first-of-type {
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list li input[type="checkbox"],
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list li input[type="checkbox"],
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list li input[type="checkbox"],
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list li input[type="checkbox"] {
  margin-top: -2px;
  margin-right: 4px;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list li:last-of-type,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list li:last-of-type,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list li:last-of-type,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list li:last-of-type {
  margin-bottom: 20px;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list.without-search,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list.without-search,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list.without-search,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list.without-search {
  top: 0px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.email-widget-wrapper .attachments-wrapper .attachments-content .available-files .pretty-list.without-search li:first-of-type,
.email-widget-wrapper .attached-files-wrapper .attachments-content .available-files .pretty-list.without-search li:first-of-type,
.email-widget-wrapper .settings-wrapper .attachments-content .available-files .pretty-list.without-search li:first-of-type,
.email-widget-wrapper .errors-wrapper .attachments-content .available-files .pretty-list.without-search li:first-of-type {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.email-widget-wrapper .attachments-wrapper .more-settings,
.email-widget-wrapper .attached-files-wrapper .more-settings,
.email-widget-wrapper .settings-wrapper .more-settings,
.email-widget-wrapper .errors-wrapper .more-settings {
  padding: 0 20px;
  box-sizing: border-box;
}
.email-widget-wrapper .attachments-wrapper .more-settings .block-label,
.email-widget-wrapper .attached-files-wrapper .more-settings .block-label,
.email-widget-wrapper .settings-wrapper .more-settings .block-label,
.email-widget-wrapper .errors-wrapper .more-settings .block-label {
  padding: 0 10px;
  box-sizing: border-box;
}
.email-widget-wrapper .attachments-wrapper .more-settings .settings-title,
.email-widget-wrapper .attached-files-wrapper .more-settings .settings-title,
.email-widget-wrapper .settings-wrapper .more-settings .settings-title,
.email-widget-wrapper .errors-wrapper .more-settings .settings-title {
  width: 100%;
  background: #ffffff;
  margin-left: -20px;
  box-sizing: content-box;
  padding: 10px 20px 9px;
  margin-bottom: 10px;
  border-bottom: solid 1px #c9c9c9;
}
.email-widget-wrapper .cancel-btn {
  color: #999999;
  margin: 2px 10px;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
}
.email-widget-wrapper.open {
  -webkit-transform: translate(0px, 0);
  -moz-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  -o-transform: translate(0px, 0);
  transform: translate(0px, 0);
  -webkit-box-shadow: 0px 0 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0 8px 0 rgba(0, 0, 0, 0.2);
}
.email-widget-wrapper.open .email-content .actions {
  -webkit-transform: translate(0px, -42px);
  -moz-transform: translate(0px, -42px);
  -ms-transform: translate(0px, -42px);
  -o-transform: translate(0px, -42px);
  transform: translate(0px, -42px);
  -webkit-transition: all 0.25s ease-out 0.125s;
  -moz-transition: all 0.25s ease-out 0.125s;
  -o-transition: all 0.25s ease-out 0.125s;
  transition: all 0.25s ease-out 0.125s;
}
.email-widget-wrapper.open .email-title-bar.small {
  opacity: 0;
}
.email-widget-wrapper.open .title-bar-actions {
  top: 13px;
  color: rgba(255, 255, 255, 0.7);
}
.email-widget-wrapper.open .attached_files_list {
  -webkit-transform: translate(0px, 0) !important;
  -moz-transform: translate(0px, 0) !important;
  -ms-transform: translate(0px, 0) !important;
  -o-transform: translate(0px, 0) !important;
  transform: translate(0px, 0) !important;
}
.email-widget-wrapper.hide-widget {
  -webkit-transform: translate(0px, 600px);
  -moz-transform: translate(0px, 600px);
  -ms-transform: translate(0px, 600px);
  -o-transform: translate(0px, 600px);
  transform: translate(0px, 600px);
}
.email-widget-wrapper.attachments-open .attachments-overlay {
  z-index: 100;
  opacity: 1;
  -webkit-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -moz-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -o-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
}
.email-widget-wrapper.attachments-open .attachments-wrapper {
  -ms-transform: perspective(1200px) rotateX(0deg);
  -webkit-transform: perspective(1200px) rotateX(0deg);
  transform: perspective(1200px) rotateX(0deg);
  -webkit-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -moz-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -o-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  opacity: 1;
}
.email-widget-wrapper.attachments-open .attachments-wrapper .actions {
  bottom: 100px;
  -webkit-transition: all 0.25s ease-out 0.15s;
  -moz-transition: all 0.25s ease-out 0.15s;
  -o-transition: all 0.25s ease-out 0.15s;
  transition: all 0.25s ease-out 0.15s;
}
.email-widget-wrapper.attached-files-open .attachments-overlay {
  z-index: 100;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -moz-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -o-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
}
.email-widget-wrapper.attached-files-open .attached-files-wrapper {
  -ms-transform: perspective(1200px) rotateX(0deg);
  -webkit-transform: perspective(1200px) rotateX(0deg);
  transform: perspective(1200px) rotateX(0deg);
  -webkit-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -moz-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -o-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  opacity: 1;
}
.email-widget-wrapper.attached-files-open .attached-files-wrapper .actions {
  bottom: 0px;
  -webkit-transition: all 0.25s ease-out 0.15s;
  -moz-transition: all 0.25s ease-out 0.15s;
  -o-transition: all 0.25s ease-out 0.15s;
  transition: all 0.25s ease-out 0.15s;
  -webkit-transform: translate(0px, 0);
  -moz-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  -o-transform: translate(0px, 0);
  transform: translate(0px, 0);
}
.email-widget-wrapper.settings-open .attachments-overlay {
  z-index: 100;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -moz-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -o-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
}
.email-widget-wrapper.settings-open .settings-wrapper {
  -ms-transform: perspective(1200px) rotateX(0deg);
  -webkit-transform: perspective(1200px) rotateX(0deg);
  transform: perspective(1200px) rotateX(0deg);
  -webkit-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -moz-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -o-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  opacity: 1;
}
.email-widget-wrapper.settings-open .settings-wrapper .actions {
  bottom: 0px;
  -webkit-transition: all 0.25s ease-out 0.15s;
  -moz-transition: all 0.25s ease-out 0.15s;
  -o-transition: all 0.25s ease-out 0.15s;
  transition: all 0.25s ease-out 0.15s;
  -webkit-transform: translate(0px, 0);
  -moz-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  -o-transform: translate(0px, 0);
  transform: translate(0px, 0);
}
.email-widget-wrapper.errors-open .attachments-overlay {
  z-index: 100;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -moz-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  -o-transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
  transition: z-index 0s ease-out 0s, opacity 0.25s ease-out 0s, display 0s;
}
.email-widget-wrapper.errors-open .errors-wrapper {
  -ms-transform: perspective(1200px) rotateX(0deg);
  -webkit-transform: perspective(1200px) rotateX(0deg);
  transform: perspective(1200px) rotateX(0deg);
  -webkit-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -moz-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  -o-transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out, box-shadow 0s;
  opacity: 1;
}
.email-widget-wrapper.errors-open .errors-wrapper .actions {
  bottom: 0px;
  -webkit-transition: all 0.25s ease-out 0.15s;
  -moz-transition: all 0.25s ease-out 0.15s;
  -o-transition: all 0.25s ease-out 0.15s;
  transition: all 0.25s ease-out 0.15s;
  -webkit-transform: translate(0px, 0);
  -moz-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  -o-transform: translate(0px, 0);
  transform: translate(0px, 0);
}
.email-widget-wrapper ul li {
  position: relative;
}
.email-widget-wrapper ul li .attachment-title {
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
  position: relative;
  top: 3px;
}
.email-widget-wrapper ul li .delete-link {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 12px;
  color: #5e6d7f;
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.email-widget-wrapper ul li .delete-link.text-only {
  top: 5px;
}
.email-widget-wrapper ul li .delete-link:hover {
  opacity: 1;
}
.email-widget-wrapper ul li .status {
  position: absolute;
  top: 12px;
  right: 12px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.email-widget-wrapper ul li .status.link {
  cursor: pointer;
}
.email-widget-wrapper ul li .status.active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.email-widget-overlay {
  position: fixed;
  bottom: 0;
  -webkit-transform: translate(0px, 1000px);
  -moz-transform: translate(0px, 1000px);
  -ms-transform: translate(0px, 1000px);
  -o-transform: translate(0px, 1000px);
  transform: translate(0px, 1000px);
  opacity: 0;
  left: 0;
  width: 100%;
  height: 0;
  -webkit-box-shadow: 0px -60px 500px 400px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px -60px 500px 400px rgba(50, 50, 50, 0.1);
  box-shadow: 0px -60px 500px 400px rgba(50, 50, 50, 0.1);
  z-index: 200;
  pointer-events: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.email-widget-overlay.active {
  opacity: 1;
  -webkit-transform: translate(0px, 0);
  -moz-transform: translate(0px, 0);
  -ms-transform: translate(0px, 0);
  -o-transform: translate(0px, 0);
  transform: translate(0px, 0);
}
.customise_settings_wrapper .add_title .control-label {
  width: 40px;
}
.customise_settings_wrapper .add_title .controls {
  margin-left: 60px;
}
.customise_settings_wrapper strong {
  display: block;
  margin: 0 0 12px 0;
}
.customise_settings_wrapper .more-btn {
  float: right;
}
.customise_settings_wrapper .customise_settings_error,
.customise_settings_wrapper .customise_settings_error_exists {
  color: #d53847;
  display: none;
}
.customise_settings_wrapper .add_record_button:hover {
  color: #787272;
}
.customise_settings_wrapper.technical_reference label {
  width: 142px;
}
.customise_settings_wrapper .customisable-form-wrapper .actions {
  margin-top: 10px;
}
.section-title.without-top-padding {
  padding-top: 0px;
}
.section-title.with-buttons button.btn,
.section-title.with-buttons a.btn {
  margin-left: 10px;
}
.section-title.with-top-border {
  border-top: 1px solid #eeeeee;
}
.section-title.with-top-margin {
  margin-top: 20px;
}
.sortable_wrapper {
  -webkit-transition: height 0.7s;
  -moz-transition: height 0.7s;
  -o-transition: height 0.7s;
  transition: height 0.7s;
  position: relative;
  padding: 10px;
  overflow: hidden;
  height: 244px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.86), inset 0 1px 0 rgba(1, 0, 0, 0.09), inset 0 0 5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.86), inset 0 1px 0 rgba(1, 0, 0, 0.09), inset 0 0 5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.86), inset 0 1px 0 rgba(1, 0, 0, 0.09), inset 0 0 5px rgba(0, 0, 0, 0.09);
  background-color: rgba(1, 0, 0, 0.05);
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
  background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
  background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.09), rgba(1, 0, 0, 0.05));
}
.sortable_wrapper .pagination {
  margin: 10px 0 0 0;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.sortable_wrapper.expanded-sortable {
  height: 386px;
}
.sortable_wrapper.expanded-sortable .edit_title_content,
.sortable_wrapper.expanded-sortable .edit_titles {
  height: 347px;
}
.sortable_wrapper.expanded-sortable .edit_title_content .input-large,
.sortable_wrapper.expanded-sortable .edit_titles .input-large {
  display: inline-block !important;
}
.ui-sortable:hover {
  cursor: move;
}
.edit_options {
  display: inline-block;
  position: relative;
}
.edit_view_wrapper {
  position: absolute;
  left: 1300px;
  right: -1300px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.edit_view_wrapper .actions {
  margin: 10px 0 0 0;
}
.edit_view_wrapper .edit_title_content {
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
  background: #ffffff;
  -webkit-box-shadow: 0px 1px 2px #a5a4a6;
  -moz-box-shadow: 0px 1px 2px #a5a4a6;
  box-shadow: 0px 1px 2px #a5a4a6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 0;
  height: 204px;
}
.edit_view_wrapper .edit_title_content .edit_titles {
  padding: 20px;
}
.edit_view_wrapper .edit_title_content .edit_titles input[type="text"] {
  position: relative;
}
.edit_view_wrapper .edit_title_content.with_scroll {
  overflow: auto;
}
.edit_view_wrapper .edit_title_content.with-scroll {
  height: 200px;
  overflow: auto;
}
.edit_view_wrapper.show_edit_view {
  left: 10px;
  right: 10px;
}
.edit_view_wrapper #edit_time_to_complete {
  margin-top: 10px;
}
.inline-advanced-pane .more-btn {
  float: right;
}
.add_record .save-user-button {
  float: right;
  margin: 10px 0 0 0 !important;
  padding: 4px 17px;
}
.add_record .save-user-button.loading {
  padding-left: 32px;
}
.popover-content ul {
  margin: 0;
}
.popover-content li {
  list-style: none;
}
.job_sheet_templates {
  position: relative;
}
.job_sheet_templates .invoice_template h2 {
  color: #000000;
}
.job_sheet_template {
  color: #000000;
  border: 1px solid #000000;
  margin: 0 32px 30px 32px;
  font-size: 16px;
}
.job_sheet_template.without_bottom_margin {
  margin-bottom: 0;
}
.job_sheet_template .row-fluid {
  padding: 0 10px !important;
}
.job_sheet_template .job_sheet_block {
  padding: 10px 0;
  border-bottom: 1px solid #000000;
}
.job_sheet_template .job_sheet_block:last-of-type {
  border-bottom: 0;
}
.job_sheet_template .job_sheet_block.timestamp {
  padding-bottom: 0;
}
.job_sheet_template ul {
  margin: 0;
}
.job_sheet_template li {
  list-style: none;
}
.job_sheet_template strong {
  display: inline-block;
  margin: 0 0 10px 0;
}
.job_sheet_template .diary_details span {
  float: left;
  margin: 0 40px 0 0;
}
.job_sheet_template .job_details {
  height: 240px;
}
.job_sheet_template .feedback {
  height: 220px;
}
.job_sheet_template .signatures {
  height: 140px;
}
.job_sheet_template .additional_work {
  height: 100px;
}
.price_breakdown {
  float: right;
  width: 50%;
  font-size: 16px;
  color: #000000;
  border: 1px solid #000000;
  border-top: 0;
  margin: 0 2px 30px 0;
}
.price_breakdown li {
  padding: 4px 8px;
  list-style: none;
  border-bottom: 1px solid #f3f4f4;
}
.price_breakdown li:last-of-type {
  border: 0;
}
.with_title_bar {
  height: 240px;
}
.with_title_bar .job_sheet_section {
  margin: 0 -10px;
  padding: 10px;
  background: #d9e4ef;
  border-top: 1px solid #686a6d;
  border-bottom: 1px solid #686a6d;
}
.with_title_bar .job_sheet_section strong {
  margin: 0;
}
.with_title_bar.appliances .job_sheet_section strong {
  width: 19%;
}
.with_title_bar.history strong {
  width: 33%;
}
.with_title_bar.parts .job_sheet_section {
  float: left;
  width: 100%;
}
.with_title_bar.parts .job_sheet_section strong:first-of-type {
  float: left;
}
.with_title_bar.parts .job_sheet_section strong:last-of-type {
  float: right;
}
.level-info-wrapper {
  margin: 20px 0 0 0;
  padding: 0px 20px;
  box-sizing: border-box;
}
.level-info-wrapper hr {
  margin: 10px 0 20px;
}
.level-info-wrapper .level-info {
  display: none;
}
.level-info-wrapper .level-info.active {
  display: block;
}
.level-info-wrapper .level-info ul {
  min-height: auto !important;
  width: 100%;
  padding: 0;
}
.configure-checkboxes {
  margin: 20px 0;
}
.configure-checkboxes input[type="checkbox"] {
  margin-right: 7px !important;
}
.permissions-group {
  clear: both;
  margin: 6px 0 0 0;
}
.permissions-group li {
  clear: both;
  list-style: none;
}
.permissions-group .icon {
  float: left;
  display: block;
  font-size: 11px;
  margin: 2px 5px 0 0;
}
.permissions-group p {
  display: block;
  overflow: hidden;
  clear: none !important;
  margin: 0;
}
.permissions-group.included .icon {
  color: #7eb419;
}
.permissions-group.not-included .icon {
  color: rgba(240, 61, 37, 0.9);
}
#role-based-warning {
  width: 580px;
  margin-left: -290px;
}
.handle-wrapper {
  float: left;
  width: 0;
  height: 26px;
  -webkit-transition: all 0.65s;
  -moz-transition: all 0.65s;
  -o-transition: all 0.65s;
  transition: all 0.65s;
  background-color: #92bb27;
  background-image: -moz-linear-gradient(top, #99bf2c, #87b620);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#99bf2c), to(#87b620));
  background-image: -webkit-linear-gradient(top, #99bf2c, #87b620);
  background-image: -o-linear-gradient(top, #99bf2c, #87b620);
  background-image: linear-gradient(to bottom, #99bf2c, #87b620);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff99bf2c', endColorstr='#ff87b620', GradientType=0);
}
.settings-section.in_role_based_security .span9 {
  border-top: 0px;
  padding-top: 0px;
}
.settings-section.in_role_based_security .import_panel {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.settings-section.in_role_based_security .table-container {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  padding: 20px 30px;
}
.settings-section.in_role_based_security .help-section {
  padding: 20px 30px 20px;
  margin-bottom: 0px;
}
.settings-section.in_role_based_security .Level_list {
  padding: 0px 20px;
}
.settings-section.in_role_based_security .Level_list ul {
  box-sizing: border-box;
  padding: 0px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  width: 108%;
  margin-left: -4%;
  margin-top: 20px;
}
.settings-section.in_role_based_security .Level_list ul li {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.settings-section.in_role_based_security .Level_list ul li a {
  width: 100%;
  display: inline-block;
  text-align: center;
}
.settings-section.in_role_based_security .role_based_security_table tbody:first-of-type th:first-of-type {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.settings-section.in_role_based_security .role_based_security_table tbody:first-of-type th:last-of-type {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.settings-section.in_role_based_security .role_based_security_table tbody label {
  margin-bottom: 0px;
}
.settings-section.in_role_based_security .role_based_security_table tbody td.nested-item {
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slider-wrapper select {
  display: none;
}
.slider-wrapper .ui-slider {
  width: 100%;
  border: 1px solid #bdbdbd;
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  height: 26px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.slider-wrapper .ui-slider li {
  width: 10%;
}
.slider-wrapper .ui-slider-scale {
  margin: 25px 0 0 0;
}
.slider-wrapper .ui-slider-label:hover {
  cursor: pointer;
}
.slider-wrapper .ui-slider-tic {
  display: none;
}
.slider-wrapper .ui-slider-handle {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  margin: 0;
  height: 40px !important;
  width: 28px !important;
  margin-top: -8px;
  border-top: 1px solid #b5b5b5 !important;
  border-left: 1px solid #b5b5b5 !important;
  border-right: 1px solid #b5b5b5 !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  background-color: #f2f3f3 !important;
  background-image: -moz-linear-gradient(top, #ffffff, #dfe0e0) !important;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#dfe0e0)) !important;
  background-image: -webkit-linear-gradient(top, #ffffff, #dfe0e0) !important;
  background-image: -o-linear-gradient(top, #ffffff, #dfe0e0) !important;
  background-image: linear-gradient(to bottom, #ffffff, #dfe0e0) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffdfe0e0', GradientType=0) !important;
}
.slider-wrapper .ui-slider-handle:hover {
  cursor: pointer;
}
.slider-wrapper .ui-slider-handle::before {
  content: ' ';
  display: block;
  border-color: transparent transparent transparent #cad5e0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 16px solid #b5b5b5;
  position: absolute;
  bottom: -14.5px;
}
.slider-wrapper .ui-slider-handle::after {
  content: ' ';
  display: block;
  border-color: transparent transparent transparent #cad5e0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 16px solid #e1e2e2;
  position: absolute;
  bottom: -14px;
  left: 0;
}
.ui-slider-range {
  background-color: #75bb5e !important;
  background-image: -moz-linear-gradient(top, #81ce5e, #639e5d) !important;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#81ce5e), to(#639e5d)) !important;
  background-image: -webkit-linear-gradient(top, #81ce5e, #639e5d) !important;
  background-image: -o-linear-gradient(top, #81ce5e, #639e5d) !important;
  background-image: linear-gradient(to bottom, #81ce5e, #639e5d) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff81ce5e', endColorstr='#ff639e5d', GradientType=0) !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  height: 26px;
  width: 0;
}
.timeline .line {
  transition: all .7s;
  bottom: 1000px;
}
.timeline .date-wrap .date {
  opacity: 0;
  transition: opacity .3s;
  transition-delay: .5s;
  transition-timing-function: ease-in;
}
.timeline .date-wrap hr {
  left: 50%;
  right: 50%;
  transition: all .6s;
  transition-delay: .8s;
  transition-timing-function: ease-out;
}
.timeline .item-icon {
  opacity: 0;
  transition: opacity .3s;
  transition-delay: 1.4s;
  transition-timing-function: ease-in;
}
.timeline .item-icon span {
  transition: all .3s;
  transition-delay: 1.6s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  transition-timing-function: ease-in;
}
.timeline .item .item-inner {
  opacity: 0;
  transition: opacity .4s;
}
.timeline .item:nth-of-type(1) .item-inner {
  transition-delay: 2.2s;
}
.timeline .item:nth-of-type(2) .item-inner {
  transition-delay: 2.4s;
}
.timeline .item:nth-of-type(3) .item-inner {
  transition-delay: 2.6s;
}
.timeline .item:nth-of-type(4) .item-inner {
  transition-delay: 2.8s;
}
.timeline .item:nth-of-type(5) .item-inner {
  transition-delay: 3.0s;
}
.timeline .item:nth-of-type(6) .item-inner {
  transition-delay: 3.2s;
}
.timeline .item:nth-of-type(7) .item-inner {
  transition-delay: 3.4s;
}
.timeline .item:nth-of-type(8) .item-inner {
  transition-delay: 3.6s;
}
.timeline .item:nth-of-type(9) .item-inner {
  transition-delay: 3.8s;
}
.timeline .item:nth-of-type(10) .item-inner {
  transition-delay: 4s;
}
.timeline .item:nth-of-type(11) .item-inner {
  transition-delay: 4.2s;
}
.timeline .item:nth-of-type(12) .item-inner {
  transition-delay: 4.4s;
}
.timeline.animate .line {
  bottom: 30px;
}
.timeline.animate .date-wrap .date {
  opacity: 1;
}
.timeline.animate .date-wrap hr {
  left: 0;
  right: 0;
}
.timeline.animate .item-icon {
  opacity: 1;
}
.timeline.animate .item-icon span {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.timeline.animate .item .item-inner {
  opacity: 1;
}
.client_bank_details_wrapper .control-label {
  width: 230px;
}
.client_bank_details_wrapper .controls {
  width: 50%;
  margin: 0 0 0 20px;
  float: left;
}
.client_bank_details_wrapper input[type="radio"] {
  float: left !important;
  margin: 3px 5px 0 20px;
}
.client_bank_details_wrapper label {
  margin-bottom: 0;
}
.client_bank_details_wrapper .bank_details_form {
  margin: 10px 0 0 20px;
}
.client_bank_details_wrapper .bank_details_form .content_below_help_section {
  border-top: none;
  padding-top: 0;
}
#add_user_btn {
  float: right;
}
.users_wrapper {
  margin: -1px 0 0 0 !important;
}
.users_wrapper td {
  width: 20%;
}
.user-details .signature-field .field-label {
  margin-top: 10px;
}
.user-details .signature-field img {
  width: 250px;
  height: 40px;
  margin: 0 0 0 20px;
}
.user-details .profile_image {
  padding: 0;
}
.user-details .profile_image .image_wrapper {
  margin: 0 0 20px 20px;
}
.user-details .profile_image_wrap + div {
  clear: both;
}
.change-signature-btn {
  margin: 0 0 0 10px;
}
.reset-user-password .controls {
  margin-left: 181px;
}
.reset-user-password .control-label {
  width: 159px;
}
.supported-file-types .small-title {
  float: left;
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #5e6d7f;
}
.letterhead-container {
  position: relative;
  padding: 0;
}
.letterhead-container > .row-fluid {
  padding: 0;
}
.letterhead-container > .row-fluid .span8 {
  margin-left: 1.06382978723404% ;
}
.letter {
  width: 960px;
  background: #ffffff;
  margin: 20px auto;
  padding: 0 0 20px 0;
}
.letter .top,
.letter .middle {
  clear: both;
  padding: 20px 26px;
}
.letter .top {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.letter .middle {
  padding: 280px 0;
  text-align: center;
  border-top: 1px solid #dcdada;
  border-bottom: 1px solid #dcdada;
}
.letter .middle .help-block {
  float: none;
}
.letter .bottom {
  padding: 20px 0 20px 20px;
  height: 80px;
}
.letter .top a {
  float: left;
}
.letter .phone-numbers {
  float: right;
  padding: 102px 0 0 0;
  font-size: 20px;
}
.letter .phone-numbers span {
  display: block;
  line-height: 24px;
}
.letter .bottom ul {
  list-style: none;
  margin: 0;
}
.letter .bottom .two-col strong {
  display: inline-block;
  width: 120px;
  float: left;
}
.letter .bottom a {
  float: right;
}
.letterhead-spacing {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff;
  border-left: 1px solid #dadada;
  padding: 15px 20px;
}
.letterhead-spacing .control-label {
  width: 50px;
}
.letterhead-spacing .controls {
  margin-left: 70px;
}
.letterhead-spacing .metric {
  display: inline-table;
  margin: 1px 0 0 6px;
}
.metric-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 24%;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  padding: 20px;
  background: #f9f9f9;
  border-top: 1px solid #dadada;
}
.multiple-logo-wrap {
  margin: 0 !important;
}
.multiple-logo-wrap .multiple-logos {
  float: right;
}
.multiple-logo-wrap .overlay:after {
  margin: 70px 0 0 0 !important;
  font-size: 14px !important;
}
.modal.fixed-height {
  top: 5% !important;
  bottom: 5%;
  margin-top: 0px;
}
.modal.fixed-height .modal-footer {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
}
.modal.fixed-height .modal-body {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 57px;
}
.modal .input-outer-wrapper.with-bottom-margin {
  margin-bottom: 10px;
}
.modal.dashboard-modal {
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: 0;
  width: auto;
}
.modal.with-slides,
.modal .modal-with-slides {
  overflow: hidden;
}
.modal.with-slides .modal-body,
.modal .modal-with-slides .modal-body {
  padding: 0px;
}
.modal.with-slides .slides,
.modal .modal-with-slides .slides {
  position: relative;
}
.modal.with-slides .slides .slide,
.modal .modal-with-slides .slides .slide {
  left: 100%;
  top: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  width: 100%;
  height: 350px;
  max-height: 350px !important;
  overflow: auto;
  padding: 15px;
  box-sizing: border-box;
  background: #ffffff;
  opacity: 0;
}
.modal.with-slides .slides .slide.in-view,
.modal .modal-with-slides .slides .slide.in-view {
  position: relative;
  top: 0px;
  left: 0%;
  opacity: 1;
}
.modal.with-slides .slides .slide.out-of-view,
.modal .modal-with-slides .slides .slide.out-of-view {
  position: absolute;
  left: -100%;
}
.modal.large {
  width: 800px;
  margin-left: -400px;
}
.modal .modal-white-background {
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.oAuth-modal .logo {
  text-align: center;
  margin: 4px 0 20px 0;
}
.oAuth-modal .input-outer-wrapper {
  margin: 0 0 20px 0;
}
.oAuth-modal .social-button {
  float: right;
  margin: 0 0 0 10px;
}
.tagging-modal fieldset {
  max-height: 240px;
  overflow: auto;
}
.preview-modal {
  width: 90%;
  overflow: hidden;
  left: 50%;
  margin-left: -45%;
  bottom: 10%;
  height: 80%;
  margin-top: 0px;
}
.preview-modal .letterhead-preview,
.preview-modal .file-preview {
  float: left;
  width: 100%;
  height: 520px;
}
.preview-modal .row-fluid {
  padding: 15px;
}
.preview-modal .modal-body {
  position: absolute;
  top: 42px;
  left: 0px;
  right: 0px;
  bottom: 54px;
}
.preview-modal .modal-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.preview-modal .no-preview {
  position: absolute;
  width: 100%;
  top: 50%;
  margin: -120px 0 0 0;
  color: #505b65;
  list-style: none;
  text-align: center;
}
.preview-modal .no-preview:hover {
  cursor: default;
}
.preview-modal .no-preview li {
  border: none;
  padding: 0;
}
.preview-modal .no-preview li.icon {
  font-size: 50px;
  margin: 66px 0 20px 0;
}
.preview-modal#letterhead-preview .letterhead-preview {
  overflow: auto;
}
.preview-modal.fade.in {
  top: 10% !important;
}
.lost-features-wrap {
  height: 70px;
  overflow: auto;
}
#shortcut-modal {
  width: 390px;
  margin: -250px 0 0 0;
  overflow: visible;
}
#shortcut-modal .modal-body {
  height: 100px;
  overflow: visible;
}
#shortcut-modal .chzn-search input {
  width: 260px !important;
}
#shortcut-modal select {
  width: 300px !important;
}
#sidebarHelper {
  width: 300px;
  margin-left: -150px;
}
#sidebarHelper img {
  display: block;
  margin: 0 auto 7px auto;
  border: 1px solid #d1cece;
}
#unmerge-line-item .modal-footer .btn,
#delete-cost-modal .modal-footer .btn {
  margin-top: 0;
}
.modal.fixed-height {
  top: 5% !important;
  bottom: 5%;
  margin-top: 0px;
}
.modal.fixed-height .modal-footer {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
}
.modal.fixed-height .modal-body {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 58px;
}
.estimate-reason-modal .required_field:after {
  content: ' *';
  color: #d53847;
  font-size: 16px;
}
.estimate-reason-modal .control-group .control-label {
  margin-top: 4px;
  margin-right: 10px;
  width: 140px;
}
.estimate-reason-modal .control-group .controls textarea {
  width: 305px;
}
.estimate-reason-modal .control-group .controls select {
  width: 319px;
}
.letter-preview-wrapper {
  height: 400px;
  padding: 0px;
}
.letter-preview-wrapper .row-fluid {
  padding: 0px;
}
.letter-preview-wrapper #contract-container {
  position: absolute;
  top: 42px;
  left: 0px;
  right: 0px;
  bottom: 58px;
  padding: 0px;
  margin: 0px;
  overflow: auto;
}
.letter-preview-wrapper #contract-container .contract-pdf-wrap {
  width: 100%;
}
.letter-preview-wrapper #contract-container .contract-pdf-wrap #contract-pdf {
  position: absolute;
  height: 100%;
  width: 560px;
  margin: 0px;
}
.job-on-hold .control-label {
  margin-right: 15px;
}
.job-on-hold textarea {
  width: 392px;
}
.modal-backdrop.without-click {
  pointer-events: none;
}
@-moz-document url-prefix() {
  .amount,
  .contract-duration {
    margin-left: -12px !important;
  }
  .default_email .wysihtml5-sandbox {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
  }
}
.wysihtml5-firefox-focussed {
  border: 1px solid #74b9ef !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(82, 168, 236, 0.6) !important;
  -moz-box-shadow: 0px 0px 8px 0px rgba(82, 168, 236, 0.6) !important;
  box-shadow: 0px 0px 8px 0px rgba(82, 168, 236, 0.6) !important;
}
.wysihtml5-ie-focussed {
  display: none !important;
}
/* bootstraps input-prepend and input-append have spacing
	issues with safari on a windows machine
*/
.windows-safari .input-append .add-on {
  margin-left: -5px;
}
.windows-safari .input-prepend .add-on {
  margin-right: -5px;
}
.windows-safari .daterange_filter span.add-on {
  display: none;
}
.windows-safari .daterange_filter #datepicker-input {
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
}
.mce-container html {
  position: relative;
  height: 100%;
}
.mce-container body {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.mceEditor td.mceIframeContainer iframe {
  min-height: 350px !important;
}
.mceEditor table {
  height: auto !important;
}
.tiny-mce-single-line-input-wrapper {
  position: relative;
}
.tiny-mce-single-line-input-wrapper .mce-container .mce-edit-area.mce-first {
  margin-bottom: 0px;
}
.mce-fixed-width span {
  margin-bottom: 0px !important;
}
.mce-container {
  border: 0px !important;
  background: transparent !important;
  z-index: 1;
}
.mce-container.mce-floatpanel {
  background: #ffffff !important;
}
.mce-container * {
  background-image: none !important;
}
.mce-container .mce-content-body p {
  margin: 0px;
  padding: 0px;
}
.mce-container .mce-panel,
.mce-container .mce-statusbar {
  background: transparent !important;
  border-color: #bdc5cb !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.mce-container .mce-panel.mce-last,
.mce-container .mce-statusbar.mce-last {
  position: relative;
  height: 0px;
}
.mce-container .mce-panel.mce-last .mce-container-body,
.mce-container .mce-statusbar.mce-last .mce-container-body {
  width: 20px;
  float: right;
  height: 20px;
  margin-top: -25px;
}
.mce-container .mce-flow-layout {
  margin-bottom: 0.5rem;
}
.mce-container .mce-flow-layout-item {
  margin: 0px 5px 0px 0px !important;
}
.mce-container .mce-flow-layout-item .mce-btn {
  background: #ffffff !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  border: solid 1px #bdc5cb !important;
  border-right: 0px !important;
}
.mce-container .mce-flow-layout-item .mce-btn:last-child {
  border-right: solid 1px #bdc5cb !important;
}
.mce-container .mce-flow-layout-item .mce-btn i {
  color: #797979;
  text-shadow: none;
}
.mce-container .mce-flow-layout-item .mce-btn button {
  padding: 4px 12px !important;
}
.mce-container .mce-edit-area {
  border: solid 1px #bdc5cb !important;
  border-radius: 4px !important;
  overflow: hidden;
  height: auto !important;
  position: static !important;
}
.mce-container .mce-edit-area.mce-first {
  position: relative !important;
}
.mce-container #mce_8-body {
  background: transparent !important;
}
.mce-container .mce-path.mce-path,
.mce-container .mce-resizehandle.mce-path {
  visibility: hidden;
  pointer-events: none;
}
.mce-container .mce-path *,
.mce-container .mce-resizehandle * {
  color: rgba(143, 146, 150, 0.9) !important;
  text-decoration: none !important;
}
.mce-container .mce-active button {
  background: #f0f0f0;
}
.mce-container .mce-active button i {
  color: #606060 !important;
}
.mce-tooltip {
  margin-top: 3px !important;
}
.mce-tooltip .mce-tooltip-inner {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 13px !important;
  max-width: 200px !important;
  padding: 3px 8px !important;
  color: #ffffff !important;
  text-align: center !important;
  text-decoration: none !important;
  background-color: #000000 !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}
.tiny-mce-single-line-input-wrapper .mce-edit-area {
  height: 30px !important;
}
.tiny-mce-single-line-input-wrapper .mce-edit-area iframe {
  position: relative;
  top: -7px;
}
.wysihtml5-toolbar .btn.wysihtml5-command-active {
  background-color: #f4f4f4 !important;
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1) !important;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1) !important;
}
.wysihtml5-toolbar .dropdown-menu {
  width: 200px;
}
.wysihtml5-toolbar .dropdown-menu li {
  padding: 6px 0;
  background: #ffffff;
}
.wysihtml5-toolbar .dropdown-menu li:hover a {
  color: #5e6d7f;
}
.wysihtml5-toolbar .modal-footer .btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #4d74a2;
  background-image: -moz-linear-gradient(top, #4d7fa2, #4d63a2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d7fa2), to(#4d63a2));
  background-image: -webkit-linear-gradient(top, #4d7fa2, #4d63a2);
  background-image: -o-linear-gradient(top, #4d7fa2, #4d63a2);
  background-image: linear-gradient(to bottom, #4d7fa2, #4d63a2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4d7fa2', endColorstr='#ff4d63a2', GradientType=0);
  border-color: #4d63a2 #4d63a2 #34436e;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #4d63a2;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.wysihtml5-toolbar .modal-footer .btn-primary:hover,
.wysihtml5-toolbar .modal-footer .btn-primary:active,
.wysihtml5-toolbar .modal-footer .btn-primary.active,
.wysihtml5-toolbar .modal-footer .btn-primary.disabled,
.wysihtml5-toolbar .modal-footer .btn-primary[disabled] {
  color: #ffffff;
  background-color: #4d63a2;
  *background-color: #455891;
}
.wysihtml5-toolbar .modal-footer .btn-primary:active,
.wysihtml5-toolbar .modal-footer .btn-primary.active {
  background-color: #3d4e7f \9;
}
.estimate-details-wrapper {
  position: absolute;
  top: 0px;
  left: 300px;
  right: 30px;
  bottom: 40px;
  padding: 30px 0 0;
}
.estimate-details-wrapper.smaller-top-padding {
  padding-top: 10px;
}
.estimate-details-wrapper.extra-top-padding {
  padding-top: 30px;
}
.estimate-details-wrapper .information-bar-height,
.estimate-details-wrapper .small-slider,
.estimate-details-wrapper .view-switch-wrapper {
  flex: none;
}
.scrollable-inner {
  padding: 0 0 50px 0;
}
#estimates-shelf,
#additional-works-shelf {
  top: 155px;
}
#estimates-shelf#additional-works-shelf,
#additional-works-shelf#additional-works-shelf {
  top: 113px;
}
#estimates-shelf li,
#additional-works-shelf li {
  height: 44px;
}
#estimates-shelf .step-that-animates,
#additional-works-shelf .step-that-animates {
  margin-left: -1px;
  position: relative;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates #invoice-schedule-count,
#additional-works-shelf .step-that-animates #invoice-schedule-count {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates #step-7-text.text-hidden,
#additional-works-shelf .step-that-animates #step-7-text.text-hidden {
  opacity: 0;
}
#estimates-shelf .step-that-animates #step-loading-image,
#additional-works-shelf .step-that-animates #step-loading-image,
#estimates-shelf .step-that-animates #step-confirmation-image,
#additional-works-shelf .step-that-animates #step-confirmation-image {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  float: left;
  width: 24px;
  height: 24px;
  margin: 3px 10px 0 -34px;
}
#estimates-shelf .step-that-animates #step-loading-image,
#additional-works-shelf .step-that-animates #step-loading-image {
    background-image: url("../../../images/loading/estimate-sidebar-loader.gif");
    background-repeat: no-repeat;
    background-size: 21px;
}
#estimates-shelf .step-that-animates #step-loading-image.image-hidden,
#additional-works-shelf .step-that-animates #step-loading-image.image-hidden {
  -webkit-transform: scale(0) !important;
  -moz-transform: scale(0) !important;
  -ms-transform: scale(0) !important;
  -o-transform: scale(0) !important;
  transform: scale(0) !important;
}
#estimates-shelf .step-that-animates #step-confirmation-image,
#additional-works-shelf .step-that-animates #step-confirmation-image {
    background-image: url("../images/null.png");
    background-repeat: no-repeat;
    background-position: 8px 5px;
}
#estimates-shelf .step-that-animates #step-confirmation-image.image-showing,
#additional-works-shelf .step-that-animates #step-confirmation-image.image-showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates.animate-step,
#additional-works-shelf .step-that-animates.animate-step {
  background: rgba(255, 255, 255, 0.92);
  margin-left: 10px;
  height: 44px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #d1d9dd;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
#estimates-shelf .step-that-animates.animate-step#estimate-budget-step,
#additional-works-shelf .step-that-animates.animate-step#estimate-budget-step,
#estimates-shelf .step-that-animates.animate-step#estimate-price-step,
#additional-works-shelf .step-that-animates.animate-step#estimate-price-step,
#estimates-shelf .step-that-animates.animate-step#estimate-invoice-step,
#additional-works-shelf .step-that-animates.animate-step#estimate-invoice-step {
  margin-top: -40px;
}
#estimates-shelf .step-that-animates.animate-step#estimate-budget-step,
#additional-works-shelf .step-that-animates.animate-step#estimate-budget-step {
  margin-left: 18px;
}
#estimates-shelf .step-that-animates.animate-step#estimate-price-step,
#additional-works-shelf .step-that-animates.animate-step#estimate-price-step {
  margin-left: 26px;
}
#estimates-shelf .step-that-animates.animate-step#estimate-invoice-step,
#additional-works-shelf .step-that-animates.animate-step#estimate-invoice-step {
  margin-left: 34px;
}
#estimates-shelf .step-that-animates.animate-step#estimate-invoice-step.move-up,
#additional-works-shelf .step-that-animates.animate-step#estimate-invoice-step.move-up {
  margin-top: -48px;
}
#estimates-shelf .step-that-animates.animate-step#estimate-invoice-step.move-left,
#additional-works-shelf .step-that-animates.animate-step#estimate-invoice-step.move-left {
  margin-left: 0px;
}
#estimates-shelf .step-that-animates.animate-step#estimate-invoice-step.without-outline,
#additional-works-shelf .step-that-animates.animate-step#estimate-invoice-step.without-outline {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
#estimates-shelf .step-that-animates.animate-step#estimate-budget-step.faded-out,
#additional-works-shelf .step-that-animates.animate-step#estimate-budget-step.faded-out,
#estimates-shelf .step-that-animates.animate-step#estimate-todo-step.faded-out,
#additional-works-shelf .step-that-animates.animate-step#estimate-todo-step.faded-out,
#estimates-shelf .step-that-animates.animate-step#estimate-price-step.faded-out,
#additional-works-shelf .step-that-animates.animate-step#estimate-price-step.faded-out {
  opacity: 0;
  height: 0px;
  margin-bottom: 0;
}
#estimates-shelf .step-that-animates.loading-state,
#additional-works-shelf .step-that-animates.loading-state {
  background: #ffffff;
}
#estimates-shelf .step-that-animates.loading-state #invoice-schedule-count,
#additional-works-shelf .step-that-animates.loading-state #invoice-schedule-count {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#estimates-shelf .step-that-animates.loading-state #invoice-schedule-count.count-showing,
#additional-works-shelf .step-that-animates.loading-state #invoice-schedule-count.count-showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates.loading-state #step-7-text,
#additional-works-shelf .step-that-animates.loading-state #step-7-text {
  color: #505b65;
  font-weight: bold;
}
#estimates-shelf .step-that-animates.loading-state #step-7-text.normal-link,
#additional-works-shelf .step-that-animates.loading-state #step-7-text.normal-link {
  color: #3883c1;
  font-weight: normal;
}
#estimates-shelf .step-that-animates.loading-state #step-loading-image,
#additional-works-shelf .step-that-animates.loading-state #step-loading-image {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates.animate-step-up,
#additional-works-shelf .step-that-animates.animate-step-up {
  margin-top: -48px;
}
#estimates-shelf .step-that-animates.animate-step-up#estimate-todo-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-todo-step,
#estimates-shelf .step-that-animates.animate-step-up#estimate-budget-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-budget-step,
#estimates-shelf .step-that-animates.animate-step-up#estimate-price-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-price-step,
#estimates-shelf .step-that-animates.animate-step-up#estimate-invoice-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-invoice-step {
  margin-top: -42px;
}
#estimates-shelf .step-that-animates.animate-step-up#estimate-budget-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-budget-step {
  margin-left: 15px;
}
#estimates-shelf .step-that-animates.animate-step-up#estimate-price-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-price-step {
  margin-left: 20px;
}
#estimates-shelf .step-that-animates.animate-step-up#estimate-invoice-step,
#additional-works-shelf .step-that-animates.animate-step-up#estimate-invoice-step {
  margin-left: 25px;
}
#estimates-shelf #finishing-step-tooltip,
#additional-works-shelf #finishing-step-tooltip {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#estimates-shelf #finishing-step-tooltip.label-showing,
#additional-works-shelf #finishing-step-tooltip.label-showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf #estimate-customer-step .small-count,
#additional-works-shelf #estimate-customer-step .small-count,
#estimates-shelf #estimate-accept-step .small-count,
#additional-works-shelf #estimate-accept-step .small-count {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf #estimate-customer-step .small-count#invoice-schedule-count,
#additional-works-shelf #estimate-customer-step .small-count#invoice-schedule-count,
#estimates-shelf #estimate-accept-step .small-count#invoice-schedule-count,
#additional-works-shelf #estimate-accept-step .small-count#invoice-schedule-count {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
#estimates-shelf #estimate-customer-step .small-count#send-customer-count,
#additional-works-shelf #estimate-customer-step .small-count#send-customer-count,
#estimates-shelf #estimate-accept-step .small-count#send-customer-count,
#additional-works-shelf #estimate-accept-step .small-count#send-customer-count {
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
}
#estimates-shelf #estimate-customer-step .small-count#accept-reject-count,
#additional-works-shelf #estimate-customer-step .small-count#accept-reject-count,
#estimates-shelf #estimate-accept-step .small-count#accept-reject-count,
#additional-works-shelf #estimate-accept-step .small-count#accept-reject-count {
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
}
#estimates-shelf #estimate-customer-step.with-hidden-count .small-count,
#additional-works-shelf #estimate-customer-step.with-hidden-count .small-count,
#estimates-shelf #estimate-accept-step.with-hidden-count .small-count,
#additional-works-shelf #estimate-accept-step.with-hidden-count .small-count {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#estimates-shelf #estimate-customer-step.with-count-showing .small-count,
#additional-works-shelf #estimate-customer-step.with-count-showing .small-count,
#estimates-shelf #estimate-accept-step.with-count-showing .small-count,
#additional-works-shelf #estimate-accept-step.with-count-showing .small-count {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates,
#additional-works-shelf .step-that-animates {
  border: 1px solid transparent;
}
#estimates-shelf .step-that-animates.step-showing,
#additional-works-shelf .step-that-animates.step-showing {
  display: block !important;
  margin-top: -48px;
  z-index: -1;
}
#estimates-shelf .step-that-animates.step-showing .small-count,
#additional-works-shelf .step-that-animates.step-showing .small-count,
#estimates-shelf .step-that-animates.step-showing #invoice-schedule-count,
#additional-works-shelf .step-that-animates.step-showing #invoice-schedule-count {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#estimates-shelf .step-that-animates.unfold-step,
#additional-works-shelf .step-that-animates.unfold-step {
  border-color: #d1d9dd;
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  margin-top: 4px;
  margin-left: 15px;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-todo-step .small-count,
#additional-works-shelf .step-that-animates.unfold-step#estimate-todo-step .small-count {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-budget-step,
#additional-works-shelf .step-that-animates.unfold-step#estimate-budget-step {
  margin-left: 23px;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-budget-step .small-count,
#additional-works-shelf .step-that-animates.unfold-step#estimate-budget-step .small-count {
  transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-price-step,
#additional-works-shelf .step-that-animates.unfold-step#estimate-price-step {
  margin-left: 31px;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-price-step .small-count,
#additional-works-shelf .step-that-animates.unfold-step#estimate-price-step .small-count {
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-invoice-step,
#additional-works-shelf .step-that-animates.unfold-step#estimate-invoice-step {
  margin-left: 39px;
}
#estimates-shelf .step-that-animates.unfold-step#estimate-invoice-step .small-count,
#additional-works-shelf .step-that-animates.unfold-step#estimate-invoice-step .small-count {
  transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
}
#estimates-shelf .step-that-animates.with-steps-showing .small-count,
#additional-works-shelf .step-that-animates.with-steps-showing .small-count,
#estimates-shelf .step-that-animates.with-steps-showing #invoice-schedule-count,
#additional-works-shelf .step-that-animates.with-steps-showing #invoice-schedule-count {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#estimates-shelf .step-that-animates.left-aligned,
#additional-works-shelf .step-that-animates.left-aligned {
  margin-left: -1px;
}
#estimates-shelf .step-that-animates.left-aligned#estimate-budget-step,
#additional-works-shelf .step-that-animates.left-aligned#estimate-budget-step,
#estimates-shelf .step-that-animates.left-aligned#estimate-price-step,
#additional-works-shelf .step-that-animates.left-aligned#estimate-price-step,
#estimates-shelf .step-that-animates.left-aligned#estimate-invoice-step,
#additional-works-shelf .step-that-animates.left-aligned#estimate-invoice-step {
  margin-left: -1px;
}
#estimates-shelf .step-that-animates.without-border,
#additional-works-shelf .step-that-animates.without-border {
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.intermediary-loading p {
  padding: 50px;
  text-align: center;
}
.full-width-wysiwyg-wrapper {
  position: absolute;
  top: 50px;
  bottom: 52px;
  left: 10px;
  right: 10px;
  width: auto;
}
.full-width-wysiwyg-wrapper.without-absolute {
  position: static;
}
.full-width-wysiwyg-wrapper.with-overflow {
  bottom: 15px;
  overflow: auto;
  right: 0px;
  padding-right: 10px;
}
.full-width-wysiwyg-wrapper .mce-tinymce.mce-container,
.full-width-wysiwyg-wrapper .mce-container-body,
.full-width-wysiwyg-wrapper .mce-edit-area,
.full-width-wysiwyg-wrapper #estimate_intro_ifr,
.full-width-wysiwyg-wrapper #estimate_body_ifr {
  height: 100% !important;
}
.clearfix {
  clear: both;
}
.use-template-link {
  margin: 5px 0px 5px 0px;
  float: left;
}
hr.clearfix {
  clear: both !important;
  margin-bottom: 0;
}
hr.no-top-margin {
  margin-top: 0;
}
hr.no-bottom-margin {
  margin-bottom: 0;
}
hr.no-margin {
  margin: 0;
}
hr.large-margin {
  float: none;
  margin: 10px 0 30px 0;
}
hr.small-margin {
  margin: 10px 0;
}
hr.extra-small-margin {
  margin: 5px 0;
}
hr.no-float {
  float: none;
}
hr.dark-border {
  border-top-color: #dadada;
}
hr.dashed {
  border-top: 1px dashed #d8d8d8;
}
ul.no-style {
  margin: 0;
  list-style: none;
}
.full-height {
  height: 100%;
}
.container-full-height {
  height: 90%;
}
.green {
  color: #46a546;
}
.green-text {
  color: #7eb419 !important;
}
.red-text {
  color: rgba(240, 61, 37, 0.9) !important;
}
.orange-text {
  color: #eba528 !important;
}
.up.caret {
  border-bottom: 5px solid #000000;
  border-top: 0;
}
.large-caret {
  border-top-width: 6px;
  border-left-width: 5px;
  border-right-width: 5px;
}
.base-icon {
  font-size: 16px;
  color: #797979;
}
.base-icon:hover {
  color: #797979;
  cursor: pointer;
}
.base-icon.light-icon {
  font-size: 13px;
  color: #c5c5c5;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.small-header {
  font-size: 16px;
  color: #5e6d7f;
}
.small-header.no-margin {
  margin: 0;
}
.small-header.no-top-margin {
  margin: 0 0 10px 0;
}
.small-header.no-bottom-margin {
  margin: 10px 0 0 0;
}
.medium-header {
  float: left;
  width: 100%;
  padding: 0 14px 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  font-size: 18px;
  color: #505b65;
  background: #f9f9f9;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #dadada;
}
.medium-header.transparent-bg {
  background: none;
  border: none;
  padding: 0;
}
.medium-header.inline {
  width: initial;
}
.collapsible-separator {
  float: left;
  width: 100%;
}
.collapsible-separator .more-btn {
  float: left;
}
.collapsible-separator .base-icon {
  float: right;
  margin: 1px 0 0 0;
}
.collapsible-separator + table {
  float: left;
}
.collapsible-content {
  float: left;
  width: 100%;
  margin: 0 0 20px 0;
}
.directive-link-tooltip {
  float: left;
  margin: 0;
}
.item-disabled {
  opacity: .6;
}
.item-disabled label {
  cursor: not-allowed;
}
.helper-with-icon {
  text-align: center;
}
.helper-with-icon .warning-icon-wrap .ss-info {
  font-size: 40px;
  margin-top: 9px !important;
}
.helper-with-icon p {
  margin: 15px 100px 10px 100px;
}
.opacity-1 {
  opacity: 1;
}
.opacity-07 {
  opacity: 0.7;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-03 {
  opacity: 0.3;
}
li.inactive span,
li.inactive a {
  opacity: 0.3;
  cursor: not-allowed;
}
.checkboxes-with-disabled div {
  position: relative;
  left: -7px;
}
.checkboxes-with-disabled input {
  position: relative;
  top: -3px;
  margin-right: 10px;
  width: 30px;
  width: auto;
}
.checkboxes-with-disabled input.disabled {
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}
.checkboxes-with-disabled label span {
  margin-left: 20px;
}
.checkboxes-with-disabled label input {
  top: 0px;
  margin-right: 0px;
}
.checkboxes-with-disabled strong.disabled {
  cursor: not-allowed;
  opacity: 0.7;
  color: #d25656 !important;
  text-decoration: line-through;
  font-weight: 300;
}
.checkboxes-with-disabled strong p {
  color: black;
  width: 60px;
  display: inline-block;
  padding-left: 10px;
}
.checkboxes-with-disabled span {
  color: #d25656;
}
.checkboxes-with-disabled span.field-label {
  color: #505b65;
}
.checkboxes-with-disabled span.ss-alert {
  position: relative;
  top: 2px;
  margin: 0 6px 0 0;
}
.checkboxes-with-disabled a {
  margin-left: 10px;
}
.checkboxes-with-disabled .field-label {
  height: 300px;
}
.checkboxes-with-disabled p.help-text {
  padding: 0px 70px 0px 203px;
}
.pointer-events-none {
  pointer-events: none;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.add-edit-contact-checkboxes-with-disabled div {
  left: 0px;
}
.add-edit-contact-checkboxes-with-disabled strong p {
  width: 90px;
}
.files-help {
  position: relative;
}
.files-help .helper-arrow {
  position: absolute;
  top: 10px;
  left: 0;
  margin-left: 40px;
  width: 100px;
  height: 30px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(333deg);
}
.files-help .help-text {
  margin-left: 155px;
}
.files-help.with-top-margin {
  margin-top: 15px;
}
span.direction {
  font-size: 10px;
  line-height: 0px;
}
.customer-note-textarea {
  height: 220px;
  display: inline-block;
  position: relative;
}
span.direction {
  font-size: 10px;
  line-height: 0px;
}
span.confirmation button.close {
  line-height: 20px;
  padding-left: 10px;
}
.developer {
  font-size: 25px;
  font-family: monospace;
  padding: 10px 20px;
}
.windows-safari select {
  line-height: 24px !important;
}
.break-word {
  word-wrap: break-word;
}
.transparent-drag-helper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
}
#loading-indicator,
.save_confirmation_wrapper {
    position: fixed;
    z-index: 1100;
    text-align: center;
    width: 100%;
    top: 30px;
}
#loading-indicator .save_confirmation_wrapper,
.save_confirmation_wrapper .save_confirmation_wrapper {
  margin: 0px !important;
}
.link-with-number {
  font-size: 14px !important;
  position: relative;
}
.link-with-number a:not(.breadcrumb-title) {
  margin-right: 20px !important;
}
.link-with-number.on-right {
  float: right;
}
.link-with-number a.message_number {
  margin: 0px !important;
  right: 0px;
  top: -4px;
}
.link-with-number a.message_number:hover {
  color: #ffffff;
}
.message_number {
    color: #ffffff;
    float: left;
    background: #f03d25;
    padding: 0px 2px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    margin: -5px 0 0 -6px;
    line-height: 14px;
    position: absolute;
    z-index: 0;
}
.message_number:hover {
  text-decoration: none;
}
.label,
.just-added {
  border-radius: 22px;
  height: 16px;
  line-height: 16px;
  margin-bottom: -20px;
  display: inline-block;
  padding: 1px 8px;
  text-shadow: none;
  font-weight: 100;
  font-size: 11px;
}
.label.label-info,
.just-added.label-info {
  background: #60a369;
}
.label.label-important,
.just-added.label-important {
  background: #b95858;
}
.label.label-progress,
.just-added.label-progress {
  background: #2f96b4;
}
.label.label-warning,
.just-added.label-warning {
  background: #d78430;
}
.just-added {
  position: absolute;
  top: 4px;
  right: 11px;
  border: solid 8px #fff6b9;
  background: #60a369;
  color: #ffffff;
}
.load-on-scroll-elm.with-shadow {
  -webkit-box-shadow: inset 0 6px 6px -6px rgba(211, 210, 210, 0.5), inset 0 -6px 6px -6px rgba(211, 210, 210, 0.65);
  -moz-box-shadow: inset 0 6px 6px -6px rgba(211, 210, 210, 0.5), inset 0 -6px 6px -6px rgba(211, 210, 210, 0.65);
  box-shadow: inset 0 6px 6px -6px rgba(211, 210, 210, 0.5), inset 0 -6px 6px -6px rgba(211, 210, 210, 0.65);
}
.shadow-on-scroll-elm {
  position: relative;
}
.shadow-on-scroll-elm .scrollable-shadow {
  position: fixed;
  z-index: 2;
  height: 5px;
}
#transfer-stock-panel #page-panel-title-bar,
#adjust-stock-panel #page-panel-title-bar,
.batch-parts-change-status #page-panel-title-bar {
  margin: 0 0 15px;
}
#transfer-stock-panel .history-section,
#adjust-stock-panel .history-section,
.batch-parts-change-status .history-section {
  padding-bottom: 10px;
}
#transfer-stock-panel .history-section.transfer-options,
#adjust-stock-panel .history-section.transfer-options,
.batch-parts-change-status .history-section.transfer-options {
  padding: 0px;
}
#transfer-stock-panel .history-section.no-background,
#adjust-stock-panel .history-section.no-background,
.batch-parts-change-status .history-section.no-background {
  background: #f9f9f9;
}
#transfer-stock-panel .history-section.no-top-margin,
#adjust-stock-panel .history-section.no-top-margin,
.batch-parts-change-status .history-section.no-top-margin {
  padding-top: 0px;
}
#transfer-stock-panel .history-section .field.with-fixed-height,
#adjust-stock-panel .history-section .field.with-fixed-height,
.batch-parts-change-status .history-section .field.with-fixed-height {
  height: 30px;
}
#transfer-stock-panel .history-section .summary,
#adjust-stock-panel .history-section .summary,
.batch-parts-change-status .history-section .summary {
  border-top: 1px solid #dadada;
  padding: 10px;
  position: relative;
  top: 10px;
}
#transfer-stock-panel .history-section .summary .field,
#adjust-stock-panel .history-section .summary .field,
.batch-parts-change-status .history-section .summary .field {
  margin-top: 6px;
  margin-bottom: 6px;
}
#transfer-stock-panel .history-section .summary .field .field-label,
#adjust-stock-panel .history-section .summary .field .field-label,
.batch-parts-change-status .history-section .summary .field .field-label {
  width: 156px;
}
#transfer-stock-panel .add-edit-option,
#adjust-stock-panel .add-edit-option,
.batch-parts-change-status .add-edit-option {
  padding: 15px 47px 20px;
  width: 633px;
}
#transfer-stock-panel .add-edit-option a,
#adjust-stock-panel .add-edit-option a,
.batch-parts-change-status .add-edit-option a {
  position: relative;
  top: -2px;
  font-weight: normal;
}
#transfer-stock-panel .add-edit-option a .ss-plus,
#adjust-stock-panel .add-edit-option a .ss-plus,
.batch-parts-change-status .add-edit-option a .ss-plus {
  margin-right: 8px;
  position: relative;
  top: 1px;
  font-size: 11px;
}
#transfer-stock-panel .add-edit-option a.add,
#adjust-stock-panel .add-edit-option a.add,
.batch-parts-change-status .add-edit-option a.add {
  float: right;
  margin-top: 10px;
  -webkit-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
#transfer-stock-panel .add-edit-option a.add.disable-button,
#adjust-stock-panel .add-edit-option a.add.disable-button,
.batch-parts-change-status .add-edit-option a.add.disable-button {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
#transfer-stock-panel .data-section.stock-edit-option,
#adjust-stock-panel .data-section.stock-edit-option,
.batch-parts-change-status .data-section.stock-edit-option {
  padding: 15px 0;
  margin: 10px 30px !important;
  position: relative;
  width: 640px;
  float: left;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  top: 0px;
  overflow: visible;
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
#transfer-stock-panel .data-section.stock-edit-option select,
#adjust-stock-panel .data-section.stock-edit-option select,
.batch-parts-change-status .data-section.stock-edit-option select {
  width: 194px;
}
#transfer-stock-panel .data-section.stock-edit-option input,
#adjust-stock-panel .data-section.stock-edit-option input,
.batch-parts-change-status .data-section.stock-edit-option input {
  width: 180px;
}
#transfer-stock-panel .data-section.stock-edit-option .field span:not(.field-label),
#adjust-stock-panel .data-section.stock-edit-option .field span:not(.field-label),
.batch-parts-change-status .data-section.stock-edit-option .field span:not(.field-label),
#transfer-stock-panel .data-section.stock-edit-option .field address:not(.field-label),
#adjust-stock-panel .data-section.stock-edit-option .field address:not(.field-label),
.batch-parts-change-status .data-section.stock-edit-option .field address:not(.field-label) {
  margin-left: 0px;
}
#transfer-stock-panel .data-section.stock-edit-option .field .field-label,
#adjust-stock-panel .data-section.stock-edit-option .field .field-label,
.batch-parts-change-status .data-section.stock-edit-option .field .field-label {
  position: relative;
  top: 4px;
}
#transfer-stock-panel .data-section.stock-edit-option .control-group,
#adjust-stock-panel .data-section.stock-edit-option .control-group,
.batch-parts-change-status .data-section.stock-edit-option .control-group {
  margin-bottom: 0px;
}
#transfer-stock-panel .data-section.stock-edit-option .control-group .control-label,
#adjust-stock-panel .data-section.stock-edit-option .control-group .control-label,
.batch-parts-change-status .data-section.stock-edit-option .control-group .control-label {
  display: inline-block;
  width: 200px;
  margin-right: 30px;
  padding-left: 20px;
  position: relative;
  top: -3px;
}
#transfer-stock-panel .data-section.stock-edit-option .control-group .controls,
#adjust-stock-panel .data-section.stock-edit-option .control-group .controls,
.batch-parts-change-status .data-section.stock-edit-option .control-group .controls {
  display: inline-block;
  margin-bottom: 0px !important;
}
#transfer-stock-panel .data-section.stock-edit-option .control-group .ss-check,
#adjust-stock-panel .data-section.stock-edit-option .control-group .ss-check,
.batch-parts-change-status .data-section.stock-edit-option .control-group .ss-check {
  margin-left: 20px;
}
#transfer-stock-panel .data-section.stock-edit-option .control-group .control-error-message span,
#adjust-stock-panel .data-section.stock-edit-option .control-group .control-error-message span,
.batch-parts-change-status .data-section.stock-edit-option .control-group .control-error-message span {
  line-height: 20px;
}
#transfer-stock-panel .data-section.stock-edit-option .remove-edit-option,
#adjust-stock-panel .data-section.stock-edit-option .remove-edit-option,
.batch-parts-change-status .data-section.stock-edit-option .remove-edit-option {
  position: absolute;
  top: 11px;
  right: 19px;
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#transfer-stock-panel .data-section.stock-edit-option .remove-edit-option .ss-delete,
#adjust-stock-panel .data-section.stock-edit-option .remove-edit-option .ss-delete,
.batch-parts-change-status .data-section.stock-edit-option .remove-edit-option .ss-delete {
  position: relative;
  top: 2px;
  left: 5px;
  font-size: 12px;
}
#transfer-stock-panel .data-section.stock-edit-option .remove-edit-option.enable-button,
#adjust-stock-panel .data-section.stock-edit-option .remove-edit-option.enable-button,
.batch-parts-change-status .data-section.stock-edit-option .remove-edit-option.enable-button {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
#transfer-stock-panel .data-section.stock-edit-option.option-removed,
#adjust-stock-panel .data-section.stock-edit-option.option-removed,
.batch-parts-change-status .data-section.stock-edit-option.option-removed {
  height: 0 !important;
  padding: 0;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  top: -10px;
  -webkit-transform: scale(0.8, 1);
}
#transfer-stock-panel .data-section.stock-edit-option.error,
#adjust-stock-panel .data-section.stock-edit-option.error,
.batch-parts-change-status .data-section.stock-edit-option.error {
  -webkit-box-shadow: inset 0px 0px 4px 2px rgba(240, 61, 37, 0.9);
  -moz-box-shadow: inset 0px 0px 4px 2px rgba(240, 61, 37, 0.9);
  box-shadow: inset 0px 0px 4px 2px rgba(240, 61, 37, 0.9);
}
#transfer-stock-panel .data-section.stock-edit-option span:not(.field-label),
#adjust-stock-panel .data-section.stock-edit-option span:not(.field-label),
.batch-parts-change-status .data-section.stock-edit-option span:not(.field-label) {
  line-height: 26px;
}
#transfer-stock-panel .data-section.stock-edit-option .ss-alert,
#transfer-stock-panel .delivered-section .ss-alert,
#adjust-stock-panel .data-section.stock-edit-option .ss-alert,
.batch-parts-change-status .data-section.stock-edit-option .ss-alert {
  font-size: 16px;
  color: #f03d25;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
#transfer-stock-panel .data-section.stock-edit-option .ss-alert.showing,
#adjust-stock-panel .data-section.stock-edit-option .ss-alert.showing,
.batch-parts-change-status .data-section.stock-edit-option .ss-alert.showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#transfer-stock-panel .quantity-delivered,
#adjust-stock-panel .quantity-delivered,
.batch-parts-change-status .quantity-delivered {
  width: 255px !important;
}
.stock-alert .ss-alert {
  font-size: 16px;
  color: #f03d25;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: relative;
  top: -4px;
  left: 10px;
  opacity: 0;
}
.stock-alert .ss-alert.showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
#stock-history-panel .field-label {
  width: 190px;
}
#stock-history-panel .inline-icon {
  margin-right: 5px;
}
#stock-history-panel .ss-hyphen,
#stock-history-panel .ss-trash {
  color: #d25656;
}
#stock-history-panel .ss-buildings,
#stock-history-panel .ss-plus {
  color: #60a369;
}
#stock-history-panel .ss-transfer,
#stock-history-panel .ss-flag {
  color: #2f96b4;
}
#stock-history-panel .ss-flag.swapped {
  position: relative;
  transform: rotateY(180deg);
}
#stock-history-panel .currency-icon {
  background: #60a369;
  color: #ffffff;
  width: 12px;
  min-height: 12px;
  font-size: 12px;
  top: 0px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  margin-right: 0px;
}
.stock-top-detail-section {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.stock-top-detail-section .stock-detail.span4 {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.stock-top-detail-section .stock-detail .data-section {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
#side-panel,
#more-details-panel {
  margin-right: -70px;
  height: 100%;
  width: 700px;
  margin-top: 0px;
  top: 0px;
}
#side-panel .panel-view,
#more-details-panel .panel-view {
  -webkit-transition: all 0.1s ease-in-out 0.3s;
  -moz-transition: all 0.1s ease-in-out 0.3s;
  -o-transition: all 0.1s ease-in-out 0.3s;
  transition: all 0.1s ease-in-out 0.3s;
  z-index: 10000000000;
}
#side-panel .modal-view,
#more-details-panel .modal-view {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out 0.3s;
  -moz-transition: all 0.3s ease-in-out 0.3s;
  -o-transition: all 0.3s ease-in-out 0.3s;
  transition: all 0.3s ease-in-out 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10000000000;
}
#side-panel .modal-view .modal-header,
#more-details-panel .modal-view .modal-header {
  border-radius: 3px 3px 0 0;
}
#side-panel .modal-view .modal-content,
#more-details-panel .modal-view .modal-content {
  position: relative;
  height: 435px;
}
#side-panel .modal-view .modal-content .message,
#more-details-panel .modal-view .modal-content .message {
  padding: 10px 30px;
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
}
#side-panel .modal-view .modal-content .message.without-background,
#more-details-panel .modal-view .modal-content .message.without-background {
  background: transparent;
  border-bottom: 0px;
}
#side-panel .modal-view .modal-content .actions,
#more-details-panel .modal-view .modal-content .actions {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 10px 20px;
  top: auto;
  background: white;
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  *zoom: 1;
  clear: both;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
#side-panel .modal-view .modal-content .actions:before,
#more-details-panel .modal-view .modal-content .actions:before,
#side-panel .modal-view .modal-content .actions:after,
#more-details-panel .modal-view .modal-content .actions:after {
  display: table;
  content: "";
  line-height: 0;
}
#side-panel .modal-view .modal-content .actions:after,
#more-details-panel .modal-view .modal-content .actions:after {
  clear: both;
}
#side-panel .modal-view .modal-content .actions a,
#more-details-panel .modal-view .modal-content .actions a {
  float: right;
  margin-left: 10px;
  margin-top: 0px;
  font-size: 14px !important;
}
#side-panel .modal-view .modal-content .actions a.cancel-panel-btn,
#more-details-panel .modal-view .modal-content .actions a.cancel-panel-btn {
  margin-right: 10px !important;
  margin-top: 4px;
  color: #999999;
  cursor: pointer;
}
#side-panel .modal-view .modal-content .actions a.cancel-panel-btn:hover,
#more-details-panel .modal-view .modal-content .actions a.cancel-panel-btn:hover {
  color: #999999;
}
#side-panel .modal-view .actions a.cancel-btn,
#more-details-panel .modal-view .actions a.cancel-btn {
  margin-top: 4px;
  margin-right: 10px;
}
#side-panel .modal-view .actions a.cancel-btn:hover,
#more-details-panel .modal-view .actions a.cancel-btn:hover {
  color: #999999;
}
#side-panel .modal-view .no-results-wrap,
#more-details-panel .modal-view .no-results-wrap {
  padding: 10px 15px 30px;
}
#side-panel.with-modal-transition,
#more-details-panel.with-modal-transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#side-panel.modal-view,
#more-details-panel.modal-view {
  right: 50%;
  margin-right: -243px;
  height: 470px;
  width: 400px;
  top: 50%;
  margin-top: -250px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}
#side-panel.modal-view .panel-view,
#more-details-panel.modal-view .panel-view {
  opacity: 0;
  z-index: -10000000000;
}
#side-panel.modal-view .modal-view,
#more-details-panel.modal-view .modal-view {
  opacity: 1;
  z-index: 10000000000;
}
#side-panel.animate-modal-away,
#more-details-panel.animate-modal-away {
  top: -1000px;
  -webkit-transition: all 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: all 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 1200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.job-parts.pretty-list {
  width: 660px;
  position: relative !important;
}
.job-parts.pretty-list .view-link {
  float: right;
}
.access-confirmation #page-panel-main {
  top: 121px;
}
.access-confirmation #page-panel-main .checkboxes-with-disabled {
  margin-top: 10px !important;
}
.access-confirmation #page-panel-main .checkboxes-with-disabled .ss-alert {
  margin: 0 6px 0 14px;
  left: 0px;
}
.access-confirmation #page-panel-main .checkboxes-with-disabled input {
  margin-left: 25px !important;
  margin-right: 10px !important;
}
.engineer-reserve-list ul.pretty-list li .engineer-image-wrapper {
  border-left: solid 3px transparent;
  float: left;
}
.engineer-reserve-list ul.pretty-list li .engineer-image-wrapper.with-normal-border {
  border-left: 1px solid #c9c9c9;
}
.engineer-reserve-list ul.pretty-list li .engineer-image-wrapper.with-normal-border .image_wrapper {
  padding-left: 0px;
  width: 26px;
}
.transparent-block.with-textarea input.full-width,
.transparent-block.with-textarea select.full-width,
.transparent-block.with-textarea textarea.full-width {
  box-sizing: border-box;
}
.transparent-block.with-textarea input.full-width {
  height: 30px !important;
}
.edit-scheduled-todo .transparent-block {
  padding: 0px 90px 0px 20px;
}
.edit-scheduled-todo .transparent-block span {
  margin-bottom: 5px;
}
.view-todo .diary-event-status {
  position: relative;
  top: 2px;
}
#side-panel.recall-change-blame a {
  margin-left: 0px !important;
}
.choose-line-item-category .line-item-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.choose-line-item-category .line-item-wrapper .description {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.choose-line-item-category.with-multiple-services .modal-body {
  overflow: auto;
}
#configure-job-parts #page-panel-title-bar {
  margin: 0 0 15px !important;
}
#configure-job-parts .transparent-block {
  padding: 20px 80px 10px 30px !important;
}
#configure-job-parts .transparent-block.with-textarea {
  padding-right: 95px !important;
}
#configure-job-parts .transparent-block.without-top-padding {
  padding-top: 0px !important;
}
#configure-job-parts .transparent-block .select-label {
  padding-bottom: 5px;
  display: inline-block;
}
#configure-job-parts .transparent-block .engineer-notes {
  width: 635px;
}
#configure-job-parts .transparent-block .available-stock-quantity {
  width: 395px;
}
#configure-job-parts .transparent-block.stock-alert span.ss-alert {
  position: relative;
  top: 0px;
}
#configure-job-parts .transparent-block input {
  box-sizing: border-box;
  height: 30px;
}
#configure-job-parts .transparent-block .data-section {
  margin: 0px;
}
#configure-job-parts .transparent-block .data-section.information-bar {
  width: 641px !important;
}
#configure-job-parts .transparent-block .controls.inline-block {
  display: inline-block;
}
#configure-job-parts .transparent-block .controls.inline-block .input-large {
  width: 250px;
}
#configure-job-parts .history-section {
  padding: 15px 0 30px;
}
#configure-job-parts .history-section.transfer-options {
  padding: 0px;
}
#configure-job-parts .history-section.no-background {
  background: #f9f9f9;
}
#configure-job-parts .history-section.no-top-margin {
  padding-top: 0px;
}
#configure-job-parts .history-section .field.with-fixed-height {
  height: 30px;
}
#configure-job-parts .history-section .summary {
  border-top: 1px solid #dadada;
  padding: 10px;
}
#configure-job-parts .history-section .summary .field {
  margin-top: 6px;
  margin-bottom: 6px;
}
#configure-job-parts .history-section .summary .field .field-label {
  width: 200px;
}
#configure-job-parts .add-edit-option {
  padding: 15px 47px 20px;
  width: 633px;
}
#configure-job-parts .add-edit-option a {
  position: relative;
  top: -2px;
  font-weight: normal;
}
#configure-job-parts .add-edit-option a .ss-plus {
  margin-right: 8px;
  position: relative;
  top: 1px;
  font-size: 11px;
}
#configure-job-parts .add-edit-option a.add {
  float: right;
  margin-top: 10px;
  -webkit-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
#configure-job-parts .add-edit-option a.add.disable-button {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
#configure-job-parts .options-container {
  padding: 0px 53px 0px 20px;
}
#configure-job-parts .options-container .stock-location-option {
  width: 660px !important;
  padding: 0px 0 15px 0;
  margin: 10px 0px;
  position: relative;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  float: none;
  overflow: hidden;
}
#configure-job-parts .options-container .stock-location-option.stock-list-view {
    padding-bottom:0;
}
#configure-job-parts .stock-location-option.stock-list-view #page-panel-title-bar {
    margin-bottom:0 !important;
}
#configure-job-parts .stock-location-option.stock-list-view #page-panel-title-bar:last-child {
    border-bottom-width: 0;
}
.sidepanel-inner-width {
    width: 660px !important;
}
#configure-job-parts .select2-container {
    display: block;
}
#configure-job-parts .select2-container > a {
    float: none;
}
#configure-job-parts .options-container .stock-location-option.stock-list-view .right-actions {
    margin-top: 14px;
}
#configure-job-parts .options-container .stock-location-option.stock-list-view .check-actions {
    margin-top: 8px;
    margin-right: 10px;
}
#configure-job-parts .options-container .stock-location-option.stock-list-view .check-actions > input {
    width: auto !important;
}

#configure-job-parts .options-container .stock-location-option.stock-list-view .right-actions .ss-delete{
    color: #b95858
}
#configure-job-parts .options-container .stock-location-option.stock-list-view .right-actions .fa-check {
    color: #60a369
}


#configure-job-parts .options-container .stock-location-option h4:not(.count) {
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#configure-job-parts .options-container .stock-location-option h4.count {
  color: #72818f;
  font-weight: normal;
  padding-left: 10px !important;
  font-style: italic;
}
#configure-job-parts .options-container .stock-location-option .help_section {
  padding: 0px 20px 5px !important;
  margin-bottom: 15px;
}
#configure-job-parts .options-container .stock-location-option #page-panel-title-bar {
  border-top: 0px;
  background: transparent;
  box-sizing: border-box;
}
#configure-job-parts .options-container .stock-location-option .field-label {
  position: relative;
  top: 4px;
  font-weight: normal;
  width: 209px;
  margin-right: 25px;
}
#configure-job-parts .options-container .stock-location-option .field-label span {
  font-style: italic;
  float: right;
  color: #72818f;
}
#configure-job-parts .options-container .stock-location-option a {
  float: left;
  margin: 0 0 0 0px;
}
#configure-job-parts .options-container .stock-location-option select {
  width: 187px;
}
#configure-job-parts .options-container .stock-location-option input {
  width: 173px;
}
#configure-job-parts .options-container .stock-location-option textarea {
  width: 325px;
}
#configure-job-parts .options-container .stock-location-option.transfer-option {
  height: 100px;
}
#configure-job-parts .options-container .stock-location-option.adjust-option {
  height: 182px;
}
#configure-job-parts .options-container .stock-location-option.error {
  -webkit-box-shadow: inset 0px 0px 4px 2px rgba(240, 61, 37, 0.9);
  -moz-box-shadow: inset 0px 0px 4px 2px rgba(240, 61, 37, 0.9);
  box-shadow: inset 0px 0px 4px 2px rgba(240, 61, 37, 0.9);
}
#configure-job-parts .options-container .stock-location-option .control-group {
  margin-bottom: 0px;
}
#configure-job-parts .options-container .stock-location-option .control-group .control-label {
  display: inline-block;
  width: 200px;
  margin-right: 30px;
  padding-left: 20px;
  position: relative;
  top: -3px;
}
#side-panel.sidepanel-job-parts .options-container .stock-location-option .control-group .control-label,
#configure-job-parts.sidepanel-job-parts .options-container .stock-location-option .control-group .control-label{
    vertical-align: top;
    margin-top: 5px;
    top:0;
}
#side-panel.sidepanel-job-parts .control-error-message,
#configure-job-parts.sidepanel-job-parts .control-error-message{
    margin-top: -7px;
}

#configure-job-parts .options-container .stock-location-option .control-group .controls {
  display: inline-block;
  margin-bottom: 0px !important;
}
#configure-job-parts .options-container .stock-location-option .control-group .controls.with-error {
  margin-bottom: 9px !important;
}
#configure-job-parts .options-container .stock-location-option .control-group .ss-check {
  margin-left: 20px;
}
#configure-job-parts .options-container .stock-location-option .field.fix-icon-top-spacing {
  margin-top: 10px;
}
#configure-job-parts .options-container .stock-location-option .field.fix-icon-top-spacing span.fix-width {
  width: 187px;
  border-top: solid 1px #eeeeee;
  padding-top: 5px;
  text-align: right;
  margin-left: 0px;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
#configure-job-parts .options-container .stock-location-option .field.fix-icon-top-spacing .ss-alert,
#configure-job-parts .options-container .stock-location-option .field.fix-icon-top-spacing .ss-check {
  margin-top: 6px;
  margin-left: 23px;
}
#configure-job-parts .options-container .stock-location-option .ss-alert,
#configure-job-parts .options-container .stock-location-option .ss-check {
  font-size: 16px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  position: absolute;
  margin-top: 7px;
}
#configure-job-parts .options-container .stock-location-option .ss-alert.showing,
#configure-job-parts .options-container .stock-location-option .ss-check.showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#configure-job-parts .options-container .stock-location-option .ss-alert.ss-alert,
#configure-job-parts .options-container .stock-location-option .ss-check.ss-alert {
  color: rgba(240, 61, 37, 0.9);
}
#configure-job-parts .options-container .stock-location-option .ss-alert.ss-check,
#configure-job-parts .options-container .stock-location-option .ss-check.ss-check {
  color: #7eb419;
}
#configure-job-parts .data-section.stock-edit-option.transfer-option #page-panel-title-bar {
  box-sizing: border-box;
  margin-top: -15px !important;
  border-top: 0px;
  background: transparent;
  clear: both;
}
#configure-job-parts #page-panel-main {
  bottom: 69px;
  top: 41px;
  left: 0;
  right: 0;
  padding-left: 0px;
  overflow-x: hidden;
}
#configure-job-parts #page-panel-main .help_section {
  padding-top: 20px;
  padding-right: 90px;
}
#configure-job-parts #page-panel-main #page-panel-title-bar {
  margin-left: 0px;
}
#configure-job-parts #page-panel-main .block-label {
  margin-bottom: 5px;
}
.select2-container .select2-choices .select2-search-choice a {
    margin-top: 0 !important;
}
#configure-job-parts .options-container .stock-location-option input[type="checkbox"] {
    width: auto;
    margin-top: -1px;
}
#configure-job-parts.mass-update .control-group {
    padding: 0 20px;
}
#configure-job-parts.mass-update .control-group.actions-checkbox {
    margin-left: -30px;
}

#configure-job-parts.mass-update .controls {
    vertical-align: top;
}
#configure-job-parts.mass-update .options-container .stock-location-option .control-group .control-label {
    padding-left: 10px;
}
#configure-job-parts.mass-update #page-panel-main #page-panel-title-bar {
    padding: 0 10px;
}

#configure-job-parts.mass-update .no-data {
    text-align: left;
    margin-left:10px;
}
#configure-job-parts.mass-update .options-container .stock-location-option .control-group .control-label {
    top:unset;
}
.no-width {
    width: auto !important;
}
#configure-job-parts.mass-update .control-error-message {
    margin-top: -5px;
    margin-left: 0px;
}
#configure-job-parts.mass-update .options-container .stock-location-option .ss-alert.ss-alert,
#configure-job-parts.mass-update .options-container .stock-location-option .ss-check {
    right: 20px;
    top: 7px;
}

.job-add-milestone .help_section {
  margin-top: 40px !important;
}
.job-add-milestone #page-panel-main {
  top: 100px;
  padding-top: 20px !important;
}
#merge-pricing-items-wrapper .pricing-items-to-merge {
  overflow: initial;
  left: 0px;
  right: 0px;
}
#merge-pricing-items-wrapper .pricing-items-to-merge li {
  transition: margin 0.4s ease-in-out, opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
}
#merge-pricing-items-wrapper .pricing-items-to-merge li:first-of-type {
  position: relative;
  z-index: 9999;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
#merge-pricing-items-wrapper .pricing-items-to-merge li:last-of-type {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #dcdcdd;
}
#merge-pricing-items-wrapper .pricing-items-to-merge.merge-pricing-items li:first-of-type {
  transform: perspective(600px) rotateX(26deg) scale(1.022, 1.1);
  -webkit-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.14);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
}
#merge-pricing-items-wrapper .pricing-items-to-merge.merge-pricing-items li:first-of-type label {
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.8);
}
#merge-pricing-items-wrapper .pricing-items-to-merge label {
  margin-bottom: 0px;
}
#merge-pricing-items-wrapper li:first-of-type {
  position: relative;
  height: 40px;
}
#merge-pricing-items-wrapper .merging-gif,
#merge-pricing-items-wrapper .merged-tick {
  position: absolute;
  top: 12px;
  left: 14px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#merge-pricing-items-wrapper .merging-text,
#merge-pricing-items-wrapper .merged-text {
  position: absolute;
  left: 40px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#merge-pricing-items-wrapper .merging-gif {
    width: 16px;
    height: 16px;
    background-image: url("../../../images/loading/estimate-sidebar-loader.gif");
    background-size: 16px;
    background-repeat: no-repeat;
}
#merge-pricing-items-wrapper .merging-gif.gif-showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
#merge-pricing-items-wrapper .merging-text.merging-text-showing {
  opacity: 1;
}
#merge-pricing-items-wrapper .merged-text {
  transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
}
#merge-pricing-items-wrapper .merged-text.merged-text-showing {
  opacity: 1;
}
#merge-pricing-items-wrapper .merged-tick {
  color: #7eb419;
}
#merge-pricing-items-wrapper .merged-tick.merged-tick-showing {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.merge-pricing-line-items .control-label {
  float: none;
  width: auto;
  text-align: left;
  padding-top: 0px;
}
.merge-pricing-line-items .controls {
  margin-left: 0px;
}
.merge-pricing-line-items .controls input {
  margin-bottom: 9px;
}
.vertical-grand-totals {
  float: right;
  list-style: none;
  width: 420px;
}
.vertical-grand-totals li {
  float: left;
  width: 100%;
}
.vertical-grand-totals li.hidden-from-customer .total-label,
.vertical-grand-totals li.hidden-from-customer .total-value {
  opacity: 0.5;
}
.vertical-grand-totals .total-label {
  float: left;
  width: 200px;
}
.vertical-grand-totals .total-label strong,
.vertical-grand-totals .total-label span {
  float: left;
  padding: 10px;
}
.vertical-grand-totals .total-label strong strong,
.vertical-grand-totals .total-label span strong {
  padding: 0px;
}
.vertical-grand-totals .total-value strong,
.vertical-grand-totals .total-value span {
  display: inline-block;
  padding: 10px 14px;
  font-weight: normal;
}
.vertical-grand-totals .total-value.with-green-bg {
  background: #e4f5e5;
}
.vertical-grand-totals .total-value.with-red-bg {
  background: #f2cfcf;
}
.vertical-grand-totals .total-value .toggle-total {
  float: right;
  margin: 10px;
}
.vertical-grand-totals.below-spreadsheet {
  border: 1px solid #e1e1e1;
  border-bottom: none;
  -webkit-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 1px -1px rgba(0, 0, 0, 0.15);
}
.vertical-grand-totals.below-spreadsheet li {
  background: #ffffff;
  border-bottom: 1px solid #e1e1e1;
}
.vertical-grand-totals.below-spreadsheet .total-label {
  border-right: 1px solid #e1e1e1;
  background: #fbfbfb;
}
.vertical-grand-totals.below-spreadsheet .total-label strong:after,
.vertical-grand-totals.below-spreadsheet .total-label span:after {
  content: '';
}
.vertical-grand-totals.below-spreadsheet .total-value strong {
  font-weight: bold;
}
.vertical-grand-totals.below-spreadsheet .total-value .toggle-total {
  float: right;
  margin: 10px;
}
.vertical-grand-totals.customer-invoicing-totals {
  width: 520px;
}
.vertical-grand-totals.customer-invoicing-totals .total-label {
  width: 300px;
}
.above-section {
  margin-bottom: 20px;
}
.spreadsheet-loading-wrap {
  position: relative;
  margin: 100px auto;
  width: 162px;
  height: 66px;
  text-align: center;
  padding-top: 12px;
}
.spreadsheet-loading-wrap .loading-spreadsheet {
  display: inline-block;
  width: 86px;
  margin: 0 auto;
}
.spreadsheet-loading-wrap .loading-x-axis,
.spreadsheet-loading-wrap .loading-y-axis {
  position: absolute;
  background: #dfdfdf;
}
.spreadsheet-loading-wrap .loading-x-axis {
  top: 0;
  left: 38px;
  width: 80px;
  height: 7px;
}
.spreadsheet-loading-wrap .loading-y-axis {
  top: 7px;
  left: 31px;
  width: 7px;
  height: 76px;
}
.spreadsheet-loading-wrap .loading-row {
  background: #ececec;
  height: 8px;
  width: 10px;
  margin: 0 0 6px 0;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}
.spreadsheet-loading-wrap .loading-row.row-2 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.spreadsheet-loading-wrap .loading-row.row-3 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.spreadsheet-loading-wrap .loading-row.row-4 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.spreadsheet-loading-wrap .loading-row.row-5 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes stretchdelay {
  20% {
    width: 80px;
  }
}
@keyframes stretchdelay {
  20% {
    width: 80px;
  }
}
#page-panel-main span.bold {
  font-weight: bold;
}
#page-panel-main .unassigned-todo-list {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 1;
  margin-top: 0px;
}
#page-panel-main .unassigned-todo-list ul {
  -webkit-transition: all 0.3s ease-in-out 2s;
  -moz-transition: all 0.3s ease-in-out 2s;
  -o-transition: all 0.3s ease-in-out 2s;
  transition: all 0.3s ease-in-out 2s;
  margin-left: 0px;
  margin-right: 0px;
  left: 0px;
}
#page-panel-main .unassigned-todo-list.hiding {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out 2s;
  -moz-transition: all 0.3s ease-in-out 2s;
  -o-transition: all 0.3s ease-in-out 2s;
  transition: all 0.3s ease-in-out 2s;
}
#page-panel-main .unassigned-todo-list.hiding ul {
  border-top: solid 1px transparent;
  -webkit-transition: border-top 0s;
  -moz-transition: border-top 0s;
  -o-transition: border-top 0s;
  transition: border-top 0s;
}
#page-panel-main .assigned-todo-list {
  opacity: 1;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#page-panel-main .assigned-todo-list ul {
  -webkit-transition: all 0.3s ease-in-out 2s;
  -moz-transition: all 0.3s ease-in-out 2s;
  -o-transition: all 0.3s ease-in-out 2s;
  transition: all 0.3s ease-in-out 2s;
}
#page-panel-main .assigned-todo-list.hiding {
  opacity: 0;
  margin-top: -167px;
  -webkit-transition: all 0.3s ease-in-out 2s;
  -moz-transition: all 0.3s ease-in-out 2s;
  -o-transition: all 0.3s ease-in-out 2s;
  transition: all 0.3s ease-in-out 2s;
}
#page-panel-main .assigned-todo-list.hiding ul {
  border-top: solid 1px transparent;
  -webkit-transition: border-top 0s;
  -moz-transition: border-top 0s;
  -o-transition: border-top 0s;
  transition: border-top 0s;
}
#merge-pricing-items-wrapper ul.todos {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  left: 0px;
}
#merge-pricing-items-wrapper ul.todos li {
  height: 47px;
  display: block;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0);
}
#merge-pricing-items-wrapper ul.todos li label span,
#merge-pricing-items-wrapper ul.todos li label strong {
  position: relative;
  top: 3px;
  margin-left: 10px;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event {
  color: #838d96;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event .cancelled-warning,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event .cancelled-warning {
  color: rgba(240, 61, 37, 0.9);
  float: right;
  margin-top: -3px;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event .cancelled-warning strong,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event .cancelled-warning strong {
  color: rgba(240, 61, 37, 0.9);
  margin-left: 5px;
  top: 0px;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event .cancelled-warning .ss-alert,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event .cancelled-warning .ss-alert {
  position: relative;
  top: 2px;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event .completed-warning,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event .completed-warning {
  color: #7eb419;
  float: right;
  margin-top: -3px;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event .completed-warning strong,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event .completed-warning strong {
  color: #7eb419;
  margin-left: 5px;
  top: 0px;
}
#merge-pricing-items-wrapper ul.todos li label span.diary-event .completed-warning .ss-info,
#merge-pricing-items-wrapper ul.todos li label strong.diary-event .completed-warning .ss-info {
  position: relative;
  top: 2px;
}
#merge-pricing-items-wrapper ul.todos li label span.ss-calendar,
#merge-pricing-items-wrapper ul.todos li label strong.ss-calendar,
#merge-pricing-items-wrapper ul.todos li label span.ss-clock,
#merge-pricing-items-wrapper ul.todos li label strong.ss-clock {
  margin-left: 0px;
  margin-right: 5px;
  color: #677077;
  position: relative;
  top: 2px;
}
#merge-pricing-items-wrapper ul.todos li label span.ss-clock,
#merge-pricing-items-wrapper ul.todos li label strong.ss-clock {
  margin-left: 10px;
}
#merge-pricing-items-wrapper ul.todos li label span.engineer,
#merge-pricing-items-wrapper ul.todos li label strong.engineer {
  top: 0px;
}
#merge-pricing-items-wrapper ul.todos li label strong {
  position: relative;
  margin-left: 10px;
}
#merge-pricing-items-wrapper ul.todos li label strong.engineer {
  margin-right: 5px;
}
#merge-pricing-items-wrapper ul.todos li input {
  margin-left: 2px;
}
#merge-pricing-items-wrapper ul.todos li#pos_fixed_spacer_unassigned {
  opacity: 0;
}
#merge-pricing-items-wrapper ul.todos li.animate-pos-fixed-spacer-height-to-0,
#merge-pricing-items-wrapper ul.todos li#pos_fixed_spacer_assigned {
  height: 0;
  border: 0;
  opacity: 0;
  -webkit-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
}
#merge-pricing-items-wrapper ul.todos li.animate-pos-fixed-spacer-height-to-full {
  height: 47px !important;
  border-bottom: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  -webkit-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
}
#merge-pricing-items-wrapper ul.todos li.animate-todo-pos-absolute {
  position: absolute;
  z-index: 10000000;
}
#merge-pricing-items-wrapper ul.todos li.animate-todo-border-radius {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
#merge-pricing-items-wrapper ul.todos li.animate-todo-add-transition {
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  transition: top 1200ms cubic-bezier(0.77, 0, 0.175, 1), transform 600ms cubic-bezier(0.77, 0, 0.175, 1), box-shadow 600ms cubic-bezier(0.77, 0, 0.175, 1);
}
#merge-pricing-items-wrapper ul.todos li.animate-todo-remove-scale {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
}
#merge-pricing-items-wrapper ul.todos li#pos_fixed_spacer_unassigned.assigned {
  height: 0px !important;
  border: 0 !important;
  -webkit-transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
}
#merge-pricing-items-wrapper ul.todos li#pos_fixed_spacer_unassigned.assigned.animate-pos-fixed-spacer-height-to-full {
  height: 47px !important;
  -webkit-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  border-bottom: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}
#merge-pricing-items-wrapper ul.todos li#pos_fixed_spacer_assigned.assigned {
  height: 47px !important;
  border-bottom: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  -webkit-transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0ms cubic-bezier(0.77, 0, 0.175, 1);
}
#merge-pricing-items-wrapper ul.todos li#pos_fixed_spacer_assigned.assigned.animate-pos-fixed-spacer-height-to-0 {
  height: 0px !important;
  border: 0 !important;
  -webkit-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 1200ms cubic-bezier(0.77, 0, 0.175, 1);
}
.assign-todo {
  float: right;
  margin: 10px 0px 10px 10px;
}
.appliance-preview-panel #page-panel-main table {
  margin: 0px !important;
  float: none !important;
  width: 100%;
}
.btn {
  border: 0;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: rgba(255, 255, 255, 0.95);
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  background-image: none;
}
.btn:hover {
  color: #ffffff;
  text-shadow: none;
}
.btn.with-left-margin {
  margin-left: 10px !important;
}
.btn.btn-small {
  padding: 4px 12px;
}
.btn.btn-primary {
  background: #746ca6;
}
.btn.btn-primary:hover {
  background: #6e66a2;
}
.btn.btn-action,
.btn.fake-uploader {
  color: rgba(255, 255, 255, 0.95);
  background: #6393b5;
}
.btn.btn-action:hover,
.btn.fake-uploader:hover {
  background: #5c8eb2;
}
.btn.btn-cancel {
  color: #999999;
  background: transparent;
}
.btn.btn-cancel:hover {
  color: #949494;
  background: transparent;
}
.btn.btn-cancel:active {
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn.btn-link {
  text-decoration: none;
  color: #3883c1;
  background: transparent;
}
.btn.btn-link:hover {
  color: #3276ad;
  background: transparent;
}
.btn.btn-link:active {
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn.btn-success {
  color: rgba(255, 255, 255, 0.95);
  background: #60a369;
}
.btn.btn-success:hover {
  color: #e1f5e3;
  background: #5b9e64;
}
.btn.btn-warning {
  background: #d4a93c;
}
.btn.btn-warning:hover {
  background: #d2a534;
}
.btn.btn-danger {
  background: #b95858;
}
.btn.btn-danger:hover {
  background: #b65151;
}
.btn.btn-cancel {
  color: #999999;
  background: transparent;
}
.btn.btn-cancel:hover {
  color: #949494;
  background: transparent;
}
.btn.btn-cancel:active {
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn.btn-help {
  color: rgba(255, 255, 255, 0.95);
  background: #60a369;
  padding: 3px 12px 2px 12px;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.btn.btn-help:hover {
  color: #e1f5e3;
  background: #5b9e64;
}
.btn.quick-links-btn {
  background: #fcfcfc;
  color: #7f8d94;
  border: 1px solid #bdc5cb;
  padding: 3px 12px;
}
.btn.quick-links-btn .caret {
  border-top-color: #acb2b6;
}
.btn.quick-links-btn:hover {
  background: #f9f9f9;
  color: #79888f;
}
.btn.quick-links-btn:hover .caret {
  border-top-color: #a4aaaf;
}
.btn.more-btn {
  float: left;
  background: #f0f0f0;
  color: rgba(143, 146, 150, 0.9);
  border: 1px solid #dadada;
  line-height: 19px;
  padding: 6px 14px 4px 15px;
  font-size: 13px;
  border-bottom: none;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.btn.more-btn:hover {
  color: rgba(138, 141, 145, 0.9);
  background: #ebebeb;
}
.btn.more-btn.small-more-btn {
  padding: 4px 10px 1px;
}
.btn.more-btn .ss-down,
.btn.more-btn .ss-up {
  margin-left: 4px;
  font-size: 8px;
}
.btn.more-btn.opened .ss-up {
  display: inline-block;
}
.btn.downgrade-btn {
  background: #b2b1b1;
  color: #ffffff;
}
.btn.change-signature-btn {
  margin-left: 0px;
  margin-top: 3px;
}
.btn.on-left {
  float: left;
}
.cancel-btn {
  color: #999999;
}
.cancel-panel-btn {
  color: #999999;
  font-size: 14px !important;
}
.tagging-header .open-sidebar,
.controls .open-sidebar,
.tagging-header .helper-btn,
.controls .helper-btn {
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: rgba(96, 135, 163, 0.9);
}
.tagging-header .open-sidebar:hover,
.controls .open-sidebar:hover,
.tagging-header .helper-btn:hover,
.controls .helper-btn:hover {
  background: rgba(91, 130, 158, 0.9);
}
.btn.loading {
    background-repeat: no-repeat !important;
    background-position: 8px 6px !important;
    padding: 4px 14px 4px 32px;
}
.btn.loading:hover {
  cursor: not-allowed;
  background-repeat: no-repeat;
  background-position: 8px 6px;
}
.btn.loading.btn-primary {
    background-color: #a19cc3 !important;
    background-image: url("../../../images/loading/purple-loader.gif") !important;
}
.btn.loading.btn-action {
    background-color: #97b7ce;
    background-image: url("../../../images/loading/blue-loader.gif");
}
.btn.loading.btn-success {
    background-color: #90bf97;
    background-image: url("../../../images/loading/green-loader.gif");
}
.btn.loading.btn-warning {
    background-color: #e2c57b;
    background-image: url("../../../images/loading/orange-loader.gif");
}
.btn.loading.btn-danger {
    background-color: #d08e8e;
    background-image: url("../../../images/loading/red-loader.gif");
}
.wysihtml5-toolbar .btn,
.chzn-container-multi .chzn-choices .btn,
.wysihtml5-toolbar li.search-choice,
.chzn-container-multi .chzn-choices li.search-choice {
  border: 1px solid #bdc5cb;
  background: #fcfcfc;
  color: #787272;
  margin: 3px 0 6px 5px;
}
.wysihtml5-toolbar .btn:hover,
.chzn-container-multi .chzn-choices .btn:hover,
.wysihtml5-toolbar li.search-choice:hover,
.chzn-container-multi .chzn-choices li.search-choice:hover {
  background: #f7f7f7;
  color: #736d6d;
}
.wysihtml5-toolbar .btn .search-choice-close:hover,
.chzn-container-multi .chzn-choices .btn .search-choice-close:hover,
.wysihtml5-toolbar li.search-choice .search-choice-close:hover,
.chzn-container-multi .chzn-choices li.search-choice .search-choice-close:hover {
  background-position: right top;
}
.wysihtml5-toolbar.wysihtml5-toolbar .btn,
.chzn-container-multi .chzn-choices.wysihtml5-toolbar .btn,
.wysihtml5-toolbar.wysihtml5-toolbar li.search-choice,
.chzn-container-multi .chzn-choices.wysihtml5-toolbar li.search-choice {
  margin-left: 0px;
}
.modal-footer .btn:not(.btn-danger) {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  text-shadow: none;
  color: #999999;
}
.modal-footer .btn.btn-primary {
  color: rgba(255, 255, 255, 0.95);
  background: #746ca6;
}
.modal-footer .btn.btn-primary:hover {
  color: #ffffff;
  background: #6e66a2;
}
.modal-footer .btn.btn-action {
  color: rgba(255, 255, 255, 0.95);
  background: #6393b5;
}
.modal-footer .btn.btn-action:hover {
  background: #5c8eb2;
}
.panel-loading {
  text-align: center;
}
.loading-wrap {
  margin: 100px auto 0;
  text-align: center;
}
.loading-wrap span {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #bccfd6;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: circularBounce 1.2s infinite ease-in-out;
  animation: circularBounce 1.2s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.loading-wrap .circle1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading-wrap .circle2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loading-wrap p {
  margin-top: 30px;
  color: #7da2af;
}
@-webkit-keyframes circularBounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes circularBounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
#fatal-error-box {
  position: absolute;
  top: 40px;
  left: 35px;
  z-index: 9999;
  width: 430px;
  height: 210px;
  border-top: 0;
  text-align: center;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  background: #f2d3d3;
  -webkit-box-shadow: inset 0 10px 10px -13px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0 10px 10px -13px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 10px 10px -13px rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  -ms-transform: perspective(250px) rotateX(-90deg);
  -webkit-transform: perspective(250px) rotateX(-90deg);
  transform: perspective(250px) rotateX(-90deg);
  -ms-transform-origin: top;
  -webkit-transform-origin: top;
  transform-origin: top;
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-in;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s ease-in;
}
#fatal-error-box .ss-alert {
  display: inline-block;
  margin: 65px 0 35px 0;
  font-size: 50px;
  color: #b95858;
}
#fatal-error-box p {
  color: #b95858;
  font-weight: bold;
}
#fatal-error-box .fatal-error-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 6px 0;
  background: rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(236, 198, 198, 0.8);
  border-bottom: 1px solid rgba(236, 198, 198, 0.5);
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
#fatal-error-box a {
  float: right;
  margin: 0 10px 0 0;
  font-size: 13px;
  color: #999999;
}
#fatal-error-box.swung-in {
  -ms-transform: perspective(900px) rotateX(0deg);
  -webkit-transform: perspective(900px) rotateX(0deg);
  transform: perspective(900px) rotateX(0deg);
  opacity: 1;
}
.search-bar-with-bg {
  float: left;
  width: 100%;
  margin: -1px 0 0 0;
  background: #efefef;
  border-top: 1px solid rgba(208, 208, 208, 0.7);
  border-bottom: 1px solid rgba(208, 208, 208, 0.7);
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
  opacity: 0.4;
  -ms-transform: perspective(150px) rotateX(-90deg);
  -webkit-transform: perspective(150px) rotateX(-90deg);
  transform: perspective(150px) rotateX(-90deg);
  -ms-transform-origin: top;
  -webkit-transform-origin: top;
  transform-origin: top;
  transition: transform 0.13s ease-in, opacity 0.1s ease-in;
  -webkit-transition: -webkit-transform 0.13s ease-in, opacity 0.1s ease-in;
}
.search-bar-with-bg.showing {
  -ms-transform: perspective(150px) rotateX(0deg);
  -webkit-transform: perspective(150px) rotateX(0deg);
  transform: perspective(150px) rotateX(0deg);
  opacity: 1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.search-bar-with-bg.without-top-border {
  border-top-color: transparent;
}
.search-bar-with-bg .search_box {
  margin: 7px 13px;
  width: 210px;
  border: 1px solid rgba(208, 208, 208, 0.7);
}
.search-bar-with-bg .search_box input {
  width: 178px;
}
.pretty-list-with-search {
  position: relative;
  height: 100%;
}
.pretty-list-with-search .app-filter {
  float: left;
  width: 100%;
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
}
.pretty-list-with-search .app-filter .search_box {
  width: 50%;
}
.pretty-list-with-search .app-filter .search_box input {
  width: calc(85%);
}
.pretty-list-with-search .app-filter .pretty-list-actions {
  float: right;
}
.pretty-list-with-search .app-filter .pretty-list-actions .btn {
  margin: 8px 15px;
}
.pretty-list-with-search .pretty-list {
  position: absolute;
  top: 44px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  float: left;
  width: 100%;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  border-top: none;
}
.pretty-list-with-search .pretty-list li {
  padding: 8px 14px;
  position: relative;
}
.pretty-list-with-search .pretty-list li.containing-nested-pretty-list {
  padding: 0;
  border: none;
}
.pretty-list-with-search .pretty-list li.containing-nested-pretty-list .nested-pretty-list-header {
  text-align: center !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  color: #505b65;
  background: #fbfbfb;
  text-align: left !important;
  padding: 4px 14px;
  border-top: 1px solid #c0c4c7;
  border-bottom: 1px solid #c0c4c7;
  background: -webkit-linear-gradient(rgba(189, 197, 203, 0.3), rgba(189, 197, 203, 0.4));
}
.pretty-list-with-search .pretty-list li.containing-nested-pretty-list .nested-pretty-list-header.header-for-today {
  background: #ea9b70;
  color: #fafafa;
  font-weight: 100;
}
.pretty-list-with-search .pretty-list li.containing-nested-pretty-list.bottom-nested-list li:last-of-type {
  border-bottom: 1px solid #dadada;
}
.pretty-list-with-search .pretty-list li.containing-nested-pretty-list:first-of-type .nested-pretty-list-header {
  border-top: none;
}
.pretty-list-with-search .pretty-list label {
  float: left;
  width: 100%;
  margin: 0;
}
.pretty-list-with-search .pretty-list label:hover {
  cursor: pointer;
}
.pretty-list-with-search .pretty-list .nested-pretty-list {
  margin: 0;
}
.pretty-list-with-search .pretty-list .nested-pretty-list > li {
  border-top: none;
}
.pretty-list-with-search .pretty-list .nested-pretty-list li:first-of-type {
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
}
.pretty-list-with-search .pretty-list .nested-pretty-list li:last-of-type {
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}
.pretty-list-with-search .no-results-wrap {
  margin: 40px 0 0 0;
}
.pretty-list-with-search .no-results-wrap .helper-arrow {
  top: 35px;
  margin: 0 22px 0 0;
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(126deg);
  -moz-transform: rotateX(0deg) rotateY(0deg) rotateZ(126deg);
  -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(126deg);
  -o-transform: rotateX(0deg) rotateY(0deg) rotateZ(126deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(126deg);
  background-size: 80%;
}
.pretty-list-with-search .no-results-wrap .helper-arrow.left {
  left: 20px;
  -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(126deg);
  -moz-transform: rotateX(0deg) rotateY(180deg) rotateZ(126deg);
  -ms-transform: rotateX(0deg) rotateY(180deg) rotateZ(126deg);
  -o-transform: rotateX(0deg) rotateY(180deg) rotateZ(126deg);
  transform: rotateX(0deg) rotateY(180deg) rotateZ(126deg);
}
.pretty-list-with-search.with-interface-preview .preview-image {
  float: left;
  width: 35px;
  height: 35px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #efeded;
}
.pretty-list-with-search.with-interface-preview .preview-text {
  float: left;
  height: 12px;
  width: 120px;
  background: -webkit-linear-gradient(left, #f2f1f1, #ffffff);
  background: -moz-linear-gradient(left, #f2f1f1, #ffffff);
}
.pretty-list-with-search.with-interface-preview li {
  border-top: 1px solid transparent;
}
.pretty-list-with-search.with-interface-preview li.without-border-bottom {
  border-bottom: none;
}
.pretty-list-with-search.with-interface-preview .preview-image {
  margin: 1px 20px 0 30px;
}
.pretty-list-with-search.with-interface-preview .preview-text {
  margin: 3px 0 0 0;
}
.pretty-list-with-search.for-users .pretty-list input[type="checkbox"] {
  margin-right: 10px;
}
.pretty-list-with-search.for-users .pretty-list label {
  line-height: 34px;
}
.pretty-list-with-search.for-users .pretty-list .profile_image {
  line-height: 0px;
}
.property-item .profile_image {
  padding: 0;
}
.property-item .property-details {
  float: left;
  font-size: 13px;
  line-height: 18px;
}
.property-item .property-details a {
  font-weight: bold;
}
.property-item .property-details strong {
  float: left;
  clear: both;
}
.property-item .property-details p {
  margin: 0;
  clear: both;
}
.property-item .property-details p strong {
  float: none;
}
.property-item .property-details p.job-id-with-description {
  float: left;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 360px;
}
.property-item .property-details .label {
  position: absolute;
  top: 8px;
  right: 12px;
}
.property-item.with-small-image .image_wrapper {
  margin: 0 8px 0 0;
}
.property-item.flagged-property {
  background: repeating-linear-gradient(-45deg, rgba(210, 86, 86, 0.04), rgba(210, 86, 86, 0.04) 10px, transparent 10px, transparent 20px);
}
.property-item.flagged-property label {
  cursor: not-allowed;
}
.property-item.flagged-property label:hover {
  cursor: not-allowed;
}
.property-item .ss-buildings,
.property-item .ss-flag {
  font-size: 16px;
  cursor: help;
}
.property-item .ss-buildings {
  margin: 0px 2px 0 0;
  font-size: 13px;
  color: #959ba6;
}
.property-item .ss-flag {
  float: left;
  margin: 10px 14px 0 0;
  color: #d25656;
}
#side-panel.sms-templates-container .help_section,
#side-panel.attach-terms-and-conditions .help_section {
  background: #ffffff;
}
#side-panel.sms-templates-container #page-panel-main.with-top-margin,
#side-panel.attach-terms-and-conditions #page-panel-main.with-top-margin {
  margin-top: 81px;
}
#side-panel .sms-templates fieldset,
#side-panel .list-container fieldset {
  background-color: #ffffff;
  margin-bottom: 0px;
  margin-top: 20px;
}
#side-panel .sms-templates fieldset input,
#side-panel .list-container fieldset input {
  width: auto;
}
#side-panel .sms-templates fieldset .row-fluid,
#side-panel .list-container fieldset .row-fluid {
  padding: 0 0;
  width: 660px;
}
#side-panel .sms-templates fieldset .row-fluid .title,
#side-panel .list-container fieldset .row-fluid .title {
  padding: 15px 20px;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: 0 1px 0 #ffffff;
}
#side-panel .sms-templates fieldset .row-fluid p,
#side-panel .list-container fieldset .row-fluid p {
  word-wrap: break-word;
}
#side-panel .sms-templates fieldset .row-fluid .content,
#side-panel .list-container fieldset .row-fluid .content {
  margin: 10px 30px 0;
}
#side-panel .sms-templates fieldset .row-fluid .content button,
#side-panel .list-container fieldset .row-fluid .content button {
  margin-top: 5px;
}
#side-panel .sms-templates fieldset.highlight,
#side-panel .list-container fieldset.highlight {
  -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9), 0 0 2px #6050a6 inset;
  -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9), 0 0 2px #6050a6 inset;
  box-shadow: 0 0 6px rgba(96, 80, 166, 0.9), 0 0 2px #6050a6 inset;
  border-top: 0px;
  padding-top: 1px;
}
#side-panel .sms-templates fieldset:last-child,
#side-panel .list-container fieldset:last-child {
  margin-bottom: 20px;
}
.branding-header {
  background: #70b2b1;
  margin-bottom: 20px;
  text-align: center;
  padding: 20px 0px;
  color: white;
}
.certificate-wrapper {
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.tab-button {
  position: absolute;
  font-size: 13px;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: 1px solid #d0d0d0;
  color: #787272;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.tab-button:hover,
.tab-button:active,
.tab-button.active,
.tab-button.disabled,
.tab-button[disabled] {
  color: #787272;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.tab-button:active,
.tab-button.active {
  background-color: #cccccc \9;
}
.tab-button span {
  display: inline-block;
}
#select-all-link {
  left: 28px;
  height: 23px;
  width: 25px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  border-bottom-width: 0;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
#select-all-link span {
  margin: 3px 0 0 6px;
}
#select-all-link.fix-right {
  left: 29px;
}
#select-all-link.without-configurable-columns {
  left: 6px;
}
#select-all-link.floating {
  border-bottom-width: 1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: static;
  display: inline-block;
  margin-bottom: 10px;
}
#update-column-link {
  left: 0;
  top: 32px;
  height: 25px;
  width: 23px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  border-right: 0;
}
#update-column-link span {
  margin: 4px 0 0px 5px;
}
#update-column-link.higher-position {
  top: 8px;
}
#update-column-link.fix-right {
  top: 3px;
  left: 0px;
}
#update-column-link.fix-right.higher-poision-with-selectable-and-actions {
  top: 27px;
}
.flex-container {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-container.wrap-children {
  flex-wrap: wrap;
}
.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.flex-row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.flex-0 {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.flex-1 {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flex-2 {
  -webkit-flex: 2;
  -moz-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.flex-3 {
  -webkit-flex: 3;
  -moz-flex: 3;
  -ms-flex: 3;
  flex: 3;
}
.flex-4 {
  -webkit-flex: 4;
  -moz-flex: 4;
  -ms-flex: 4;
  flex: 4;
}
.flex-5 {
  -webkit-flex: 5;
  -moz-flex: 5;
  -ms-flex: 5;
  flex: 5;
}
#main {
  height: 90%;
}
.relative {
  position: relative;
}
strong.with-bottom-margin {
  display: block;
  margin: 0 0 12px 0;
}
strong.inline {
  display: inline;
  margin: 0;
}
.modal-body {
  overflow: hidden;
  max-height: 100%;
}
.modal-body.without-padding {
  padding: 15px 0 0 0;
}
.modal-body .help_section {
  padding: 0;
  margin: 0 0 20px 0;
}
.modal-body.change_customer_model {
  overflow: inherit;
}
.model,
.change_customer {
  overflow: inherit;
}
.content {
  margin-top: 20px;
}
.content-info_bar{
    padding:0 16px;
}

.content.scrollable {
  padding-top: 20px;
  margin-top: 0;
}
.postcode-modal {
  max-height: 300px;
  overflow: auto;
  background: #f9f9f9;
}
.postcode-modal .postcodes ul {
  position: static;
}
.download_options {
  float: left;
  margin: 2px 0 0 10px;
}
.download_options a,
.download_options .icon {
  margin: 0 0 0 10px;
  font-size: 16px;
  color: #797979;
  cursor: pointer;
}
.download_options a.btn-primary,
.download_options .icon.btn-primary {
  color: rgba(255, 255, 255, 0.95);
}
.download_options.right {
  float: right;
}
.span12.no-min-height {
  min-height: 0;
}
.btn-small .caret {
  margin-top: 8px;
}
.btn.disabled {
  cursor: not-allowed;
}
.small-count {
  float: left;
  width: 24px;
  height: 24px;
  margin: -2px 0 0 0;
  text-align: center;
  line-height: 25px;
  font-weight: bold;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: 2px solid #959ba6;
  background: #ffffff;
  color: #959ba6;
}
.small-count .text {
  -webkit-transition: all 0.3s ease-in-out 0.2s;
  -moz-transition: all 0.3s ease-in-out 0.2s;
  -o-transition: all 0.3s ease-in-out 0.2s;
  transition: all 0.3s ease-in-out 0.2s;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.small-count .ss-check {
  color: #7eb419;
  float: left;
  margin: -23px 0 0 5px;
  -webkit-transition: all 0.3s ease-in-out 0.2s;
  -moz-transition: all 0.3s ease-in-out 0.2s;
  -o-transition: all 0.3s ease-in-out 0.2s;
  transition: all 0.3s ease-in-out 0.2s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.small-count.complete .text {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.small-count.complete .ss-check {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
@media screen and (max-width: 1300px) {
  .stock-image {
    display: none;
  }
}
.no-bold {
  font-weight: normal;
}
.faux-checkbox {
  cursor: pointer;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #ccc;
}
.faux-checkbox .ss-check {
  display: none;
}
.card-confirm-checkbox{
    float:left;
    position: relative; top: 4px;
}

.faux-checkbox.checked .ss-check {
  display: block;
}
span.number-bubble {
  padding: 1px 8px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
  background: #7eb419;
  color: #ffffff;
  position: relative;
}
fieldset.with-spreadsheet {
  background-color: #ffffff;
}
iframe {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.watermarked-image {
  max-width: 660px;
  position: relative;
  background: rgba(143, 146, 150, 0.9);
}
.watermarked-image:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 240px;
  text-align: center;
  content: 'Watermarked';
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  height: 70px;
  line-height: 74px;
  font-size: 30px;
  margin-top: -35px;
  margin-left: -120px;
  background: #60a369;
  color: #ffffff;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.5);
}
.watermarked-image img {
  opacity: 0.71;
}
.flex-container {
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.top-margin {
  margin-top: 10px;
}
.--overflow-auto {
  overflow: auto;
}
.--no-wrap {
  white-space: nowrap;
}
input.--without-bottom-margin,
select.--without-bottom-margin {
  margin-bottom: 0px;
}
/* TACHYONS v4.0.0-beta.34| github.com/tachyons-css/tachyons */
/*

   BACKGROUND SIZE

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/*
  Often used in combination with background image set as an inline style
  on an html element.
*/
.cover {
  background-size: cover;
}
.contain {
  background-size: contain;
}
/*

   BORDER BASE

   Legend

   a = all
   t = top
   r = right
   b = bottom
   l = left

*/
.ba {
  border-style: solid;
  border-width: 1px;
}
.bt {
  border-top-style: solid;
  border-top-width: 1px;
}
.br {
  border-right-style: solid;
  border-right-width: 1px;
}
.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bl {
  border-left-style: solid;
  border-left-width: 1px;
}
.bn {
  border-style: none;
  border-width: 0;
}
/*

   Tachyons
   COLOR VARIABLES

   Grayscale
   - Solids
   - Transparencies
*/
/*

   BORDER COLORS

*/
.b--black {
  border-color: #000;
}
.b--near-black {
  border-color: #111;
}
.b--dark-gray {
  border-color: #333;
}
.b--mid-gray {
  border-color: #555;
}
.b--gray {
  border-color: #777;
}
.b--silver {
  border-color: #999;
}
.b--light-silver {
  border-color: #aaa;
}
.b--light-gray {
  border-color: #eee;
}
.b--near-white {
  border-color: #f4f4f4;
}
.b--white {
  border-color: #fff;
}
.b--white-90 {
  border-color: rgba(255, 255, 255, 0.9);
}
.b--white-80 {
  border-color: rgba(255, 255, 255, 0.8);
}
.b--white-70 {
  border-color: rgba(255, 255, 255, 0.7);
}
.b--white-60 {
  border-color: rgba(255, 255, 255, 0.6);
}
.b--white-50 {
  border-color: rgba(255, 255, 255, 0.5);
}
.b--white-40 {
  border-color: rgba(255, 255, 255, 0.4);
}
.b--white-30 {
  border-color: rgba(255, 255, 255, 0.3);
}
.b--white-20 {
  border-color: rgba(255, 255, 255, 0.2);
}
.b--white-10 {
  border-color: rgba(255, 255, 255, 0.1);
}
.b--white-05 {
  border-color: rgba(255, 255, 255, 0.05);
}
.b--white-025 {
  border-color: rgba(255, 255, 255, 0.025);
}
.b--white-0125 {
  border-color: rgba(255, 255, 255, 0.0125);
}
.b--black-90 {
  border-color: rgba(0, 0, 0, 0.9);
}
.b--black-80 {
  border-color: rgba(0, 0, 0, 0.8);
}
.b--black-70 {
  border-color: rgba(0, 0, 0, 0.7);
}
.b--black-60 {
  border-color: rgba(0, 0, 0, 0.6);
}
.b--black-50 {
  border-color: rgba(0, 0, 0, 0.5);
}
.b--black-40 {
  border-color: rgba(0, 0, 0, 0.4);
}
.b--black-30 {
  border-color: rgba(0, 0, 0, 0.3);
}
.b--black-20 {
  border-color: rgba(0, 0, 0, 0.2);
}
.b--black-10 {
  border-color: rgba(0, 0, 0, 0.1);
}
.b--black-05 {
  border-color: rgba(0, 0, 0, 0.05);
}
.b--black-025 {
  border-color: rgba(0, 0, 0, 0.025);
}
.b--black-0125 {
  border-color: rgba(0, 0, 0, 0.0125);
}
.b--dark-red {
  border-color: #f00008;
}
.b--red {
  border-color: #ff3223;
}
.b--orange {
  border-color: #f3a801;
}
.b--gold {
  border-color: #f2c800;
}
.b--yellow {
  border-color: #ffde37;
}
.b--purple {
  border-color: #7d5da9;
}
.b--light-purple {
  border-color: #8d4f92;
}
.b--hot-pink {
  border-color: #d62288;
}
.b--dark-pink {
  border-color: #c64774;
}
.b--pink {
  border-color: #f49cc8;
}
.b--dark-green {
  border-color: #006C71;
}
.b--green {
  border-color: #41D69F;
}
.b--navy {
  border-color: #001b44;
}
.b--dark-blue {
  border-color: #00449e;
}
.b--blue {
  border-color: #357edd;
}
.b--light-blue {
  border-color: #96ccff;
}
.b--lightest-blue {
  border-color: #cdecff;
}
.b--washed-blue {
  border-color: #f6fffe;
}
.b--washed-green {
  border-color: #e8fdf5;
}
.b--washed-yellow {
  border-color: #fff8d5;
}
.b--light-pink {
  border-color: #efa4b8;
}
.b--light-yellow {
  border-color: #f3dd70;
}
.b--light-red {
  border-color: #ffd3c0;
}
.b--transparent {
  border-color: transparent;
}
/*

   BORDER RADIUS

   Base:
     br   = border-radius

   Modifiers:
     0    = 0/none
     1    = 1st step in scale
     2    = 2nd step in scale
     3    = 3rd step in scale
     4    = 4th step in scale
     -100 = 100%


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.br0 {
  border-radius: 0;
}
.br1 {
  border-radius: .125rem;
}
.br2 {
  border-radius: .25rem;
}
.br3 {
  border-radius: .5rem;
}
.br4 {
  border-radius: 1rem;
}
.br-100 {
  border-radius: 100%;
}
.br--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.br--top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.br--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.br--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/*

   BORDER STYLES

   Base:
     bs = border-style

   Modifiers:
     none   = none
     dotted = dotted
     dashed = dashed
     solid  = solid

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

 */
.b--dotted {
  border-style: dotted;
}
.b--dashed {
  border-style: dashed;
}
.b--solid {
  border-style: solid;
}
.b--none {
  border-style: none;
}
/*

   BORDER WIDTHS

   Base:
     bw = border-width

   Modifiers:
     0 = 0 width border
     1 = 1st step in border-width scale
     2 = 2nd step in border-width scale
     3 = 3rd step in border-width scale
     4 = 4th step in border-width scale
     5 = 5th step in border-width scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.bw0 {
  border-width: 0;
}
.bw1 {
  border-width: .125rem;
}
.bw2 {
  border-width: .25rem;
}
.bw3 {
  border-width: .5rem;
}
.bw4 {
  border-width: 1rem;
}
.bw5 {
  border-width: 2rem;
}
.bt-0 {
  border-top-width: 0;
}
.br-0 {
  border-right-width: 0;
}
.bb-0 {
  border-bottom-width: 0;
}
.bl-0 {
  border-left-width: 0;
}
/*

  BOX-SHADOW

  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large

 */
.shadow-1 {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}
.shadow-2 {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
}
.shadow-3 {
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
}
.shadow-4 {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.shadow-5 {
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
}
/*

   CODE

*/
.pre {
  overflow-x: auto;
  overflow-y: hidden;
  overflow: scroll;
}
/*

   COORDINATES

   Use in combination with the position module.

*/
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.top-1 {
  top: 1rem;
}
.right-1 {
  right: 1rem;
}
.bottom-1 {
  bottom: 1rem;
}
.left-1 {
  left: 1rem;
}
.top-2 {
  top: 2rem;
}
.right-2 {
  right: 2rem;
}
.bottom-2 {
  bottom: 2rem;
}
.left-2 {
  left: 2rem;
}
.top--1 {
  top: -1rem;
}
.right--1 {
  right: -1rem;
}
.bottom--1 {
  bottom: -1rem;
}
.left--1 {
  left: -1rem;
}
.top--2 {
  top: -2rem;
}
.right--2 {
  right: -2rem;
}
.bottom--2 {
  bottom: -2rem;
}
.left--2 {
  left: -2rem;
}
.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*

   CLEARFIX

*/
/* Nicolas Gallaghers Clearfix solution
   Ref: http://nicolasgallagher.com/micro-clearfix-hack/ */
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  *zoom: 1;
}
.cl {
  clear: left;
}
.cr {
  clear: right;
}
.cb {
  clear: both;
}
.cn {
  clear: none;
}
/*

   DISPLAY

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    tr    = table-row
    tcol  = table-column
    tcolg = table-column-group

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.dn {
  display: none;
}
.di {
  display: inline;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.dit {
  display: inline-table;
}
.dt {
  display: table;
}
.dtc {
  display: table-cell;
}
.dt-row {
  display: table-row;
}
.dt-row-group {
  display: table-row-group;
}
.dt-column {
  display: table-column;
}
.dt-column-group {
  display: table-column-group;
}
/*
  This will set table to full width and then
  all cells will be equal width
*/
.dt--fixed {
  table-layout: fixed;
  width: 100%;
}
/*

FLEXBOX

 */
.flex {
  display: flex;
}
/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  /* 1 */

}
.flex-none {
  flex: none;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.self-stretch {
  align-self: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-center {
  align-content: center;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
.content-stretch {
  align-content: stretch;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-last {
  order: 99999;
}
/* Media Query Variables */
/*

   FLOATS

   1. Floated elements are automatically rendered as block level elements.
      Setting floats to display inline will fix the double margin bug in
      ie6. You know... just in case.

   2. Don't forget to clearfix your floats with .cf

   Base:
     f = float

   Modifiers:
     l = left
     r = right
     n = none

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.fl {
  float: left;
  display: inline;
}
.fr {
  float: right;
  display: inline;
}
.fn {
  float: none;
}
/*

   FONT FAMILY GROUPS

*/
.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
}
.serif {
  font-family: georgia, times, serif;
}
.system-sans-serif {
  font-family: sans-serif;
}
.system-serif {
  font-family: serif;
}
/* Monospaced Typefaces (for code) */
/* From http://cssfontstack.com */
code,
.code {
  font-family: Consolas, monaco, monospace;
}
/* Sans-Serif Typefaces */
.helvetica {
  font-family: 'helvetica neue', helvetica, sans-serif;
}
.avenir {
  font-family: 'avenir next', avenir, sans-serif;
}
/* Serif Typefaces */
.georgia {
  font-family: georgia, serif;
}
.times {
  font-family: times, serif;
}
.bodoni {
  font-family: "Bodoni MT", serif;
}
.calisto {
  font-family: "Calisto MT", serif;
}
.garamond {
  font-family: garamond, serif;
}
.baskerville {
  font-family: garamond, serif;
}
/*

   FONT STYLE

*/
.i {
  font-style: italic;
}
.fs-normal {
  font-style: normal;
}
/*

   FONT WEIGHT

*/
.normal {
  font-weight: normal;
}
.b {
  font-weight: bold;
}
.fw1 {
  font-weight: 100;
}
.fw2 {
  font-weight: 200;
}
.fw3 {
  font-weight: 300;
}
.fw4 {
  font-weight: 400;
}
.fw5 {
  font-weight: 500;
}
.fw6 {
  font-weight: 600;
}
.fw7 {
  font-weight: 700;
}
.fw8 {
  font-weight: 800;
}
.fw9 {
  font-weight: 900;
}
/*

   FORMS

*/
.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}
/*

   HEIGHTS

*/
/* Height Scale */
.h1 {
  height: 1rem;
}
.h2 {
  height: 2rem;
}
.h3 {
  height: 4rem;
}
.h4 {
  height: 8rem;
}
.h5 {
  height: 16rem;
}
/* Height Percentages */
.h-25 {
  height: 25%;
}
.h-50 {
  height: 50%;
}
.h-75 {
  height: 75%;
}
.h-100 {
  height: 100%;
}
/* String Properties */
.h-auto {
  height: auto;
}
.h-inherit {
  height: inherit;
}
/*

   LETTER SPACING

*/
.tracked {
  letter-spacing: .16em;
}
.tracked-tight {
  letter-spacing: -0.05em;
}
.tracked-mega {
  letter-spacing: .32em;
}
/*

   LINE HEIGHT / LEADING

*/
.lh-solid {
  line-height: 1;
}
.lh-title {
  line-height: 1.3;
}
.lh-copy {
  line-height: 1.6;
}
/*

   LINKS

*/
.link {
  text-decoration: none;
  transition: color 0.15s ease-in;
}
.link:link,
.link:visited {
  transition: color 0.15s ease-in;
}
.link:hover {
  transition: color 0.15s ease-in;
}
.link:active {
  transition: color 0.15s ease-in;
}
.link:focus {
  transition: color 0.15s ease-in;
}
/*

   LISTS

*/
.list {
  list-style-type: none;
}
/*

   MAX WIDTHS

*/
/* Max Width Percentages */
.mw-100 {
  max-width: 100%;
}
/* Max Width Scale */
.mw1 {
  max-width: 1rem;
}
.mw2 {
  max-width: 2rem;
}
.mw3 {
  max-width: 4rem;
}
.mw4 {
  max-width: 8rem;
}
.mw5 {
  max-width: 16rem;
}
.mw6 {
  max-width: 32rem;
}
.mw7 {
  max-width: 48rem;
}
.mw8 {
  max-width: 64rem;
}
.mw9 {
  max-width: 96rem;
}
/* Max Width String Properties */
.mw-none {
  max-width: none;
}
/*

   WIDTHS

   Base:
     w = width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale

     -10  = literal value 10%
     -20  = literal value 20%
     -25  = literal value 25%
     -33  = literal value 33%
     -34  = literal value 34%
     -40  = literal value 40%
     -50  = literal value 50%
     -60  = literal value 60%
     -75  = literal value 75%
     -80  = literal value 80%
     -100 = literal value 100%

     -auto  = string value auto


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/* Width Scale */
.w1 {
  width: 1rem;
}
.w2 {
  width: 2rem;
}
.w3 {
  width: 4rem;
}
.w4 {
  width: 8rem;
}
.w5 {
  width: 16rem;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-33 {
  width: 33%;
}
.w-34 {
  width: 34%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.w-auto {
  width: auto;
}
/*

    OVERFLOW

 */
.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-auto {
  overflow: auto;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-y-auto {
  overflow-y: auto;
}
/*

    POSITIONING

 */
.static {
  position: static;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
/*

  Opacity

*/
.o-100 {
  opacity: 1;
}
.o-90 {
  opacity: .9;
}
.o-80 {
  opacity: .8;
}
.o-70 {
  opacity: .7;
}
.o-60 {
  opacity: .6;
}
.o-50 {
  opacity: .5;
}
.o-40 {
  opacity: .4;
}
.o-30 {
  opacity: .3;
}
.o-20 {
  opacity: .2;
}
.o-10 {
  opacity: .1;
}
.o-05 {
  opacity: .05;
}
.o-025 {
  opacity: .025;
}
.o-0 {
  opacity: 0;
}
/*

   COLOR VARIABLES

   Variables to set colors for
   color, background-color, and border-color

*/
/* variables */
/*

   SKINS

*/
/* Text colors */
.black-90 {
  color: rgba(0, 0, 0, 0.9);
}
.black-80 {
  color: rgba(0, 0, 0, 0.8);
}
.black-70 {
  color: rgba(0, 0, 0, 0.7);
}
.black-60 {
  color: rgba(0, 0, 0, 0.6);
}
.black-50 {
  color: rgba(0, 0, 0, 0.5);
}
.black-40 {
  color: rgba(0, 0, 0, 0.4);
}
.black-30 {
  color: rgba(0, 0, 0, 0.3);
}
.black-20 {
  color: rgba(0, 0, 0, 0.2);
}
.black-10 {
  color: rgba(0, 0, 0, 0.1);
}
.black-05 {
  color: rgba(0, 0, 0, 0.05);
}
.white-90 {
  color: rgba(255, 255, 255, 0.9);
}
.white-80 {
  color: rgba(255, 255, 255, 0.8);
}
.white-70 {
  color: rgba(255, 255, 255, 0.7);
}
.white-60 {
  color: rgba(255, 255, 255, 0.6);
}
.white-50 {
  color: rgba(255, 255, 255, 0.5);
}
.white-40 {
  color: rgba(255, 255, 255, 0.4);
}
.white-30 {
  color: rgba(255, 255, 255, 0.3);
}
.white-20 {
  color: rgba(255, 255, 255, 0.2);
}
.white-10 {
  color: rgba(255, 255, 255, 0.1);
}
.black {
  color: #000;
}
.near-black {
  color: #111;
}
.dark-gray {
  color: #333;
}
.mid-gray {
  color: #555;
}
.gray {
  color: #777;
}
.silver {
  color: #999;
}
.light-silver {
  color: #aaa;
}
.moon-gray {
  color: #ccc;
}
.light-gray {
  color: #eee;
}
.near-white {
  color: #f4f4f4;
}
.white {
  color: #fff;
}
.dark-red {
  color: #f00008;
}
.red {
  color: #ff3223;
}
.orange {
  color: #f3a801;
}
.gold {
  color: #f2c800;
}
.yellow {
  color: #ffde37;
}
.purple {
  color: #7d5da9;
}
.light-purple {
  color: #8d4f92;
}
.hot-pink {
  color: #d62288;
}
.dark-pink {
  color: #c64774;
}
.pink {
  color: #f49cc8;
}
.dark-green {
  color: #006C71;
}
.green {
  color: #41D69F;
}
.navy {
  color: #001b44;
}
.dark-blue {
  color: #00449e;
}
.blue {
  color: #357edd;
}
.light-blue {
  color: #96ccff;
}
.lightest-blue {
  color: #cdecff;
}
.washed-blue {
  color: #f6fffe;
}
.washed-green {
  color: #e8fdf5;
}
.washed-yellow {
  color: #fff8d5;
}
.light-pink {
  color: #efa4b8;
}
.light-yellow {
  color: #f3dd70;
}
.light-red {
  color: #ffd3c0;
}
.bg-black-90 {
  background-color: rgba(0, 0, 0, 0.9);
}
.bg-black-80 {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-black-70 {
  background-color: rgba(0, 0, 0, 0.7);
}
.bg-black-60 {
  background-color: rgba(0, 0, 0, 0.6);
}
.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-black-40 {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-black-30 {
  background-color: rgba(0, 0, 0, 0.3);
}
.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2);
}
.bg-black-10 {
  background-color: rgba(0, 0, 0, 0.1);
}
.bg-black-05 {
  background-color: rgba(0, 0, 0, 0.05);
}
.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9);
}
.bg-white-80 {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-white-70 {
  background-color: rgba(255, 255, 255, 0.7);
}
.bg-white-60 {
  background-color: rgba(255, 255, 255, 0.6);
}
.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-40 {
  background-color: rgba(255, 255, 255, 0.4);
}
.bg-white-30 {
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-white-20 {
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1);
}
/* Background colors */
.bg-black {
  background-color: #000;
}
.bg-near-black {
  background-color: #111;
}
.bg-dark-gray {
  background-color: #333;
}
.bg-mid-gray {
  background-color: #555;
}
.bg-gray {
  background-color: #777;
}
.bg-silver {
  background-color: #999;
}
.bg-light-silver {
  background-color: #aaa;
}
.bg-moon-gray {
  background-color: #ccc;
}
.bg-light-gray {
  background-color: #eee;
}
.bg-near-white {
  background-color: #f4f4f4;
}
.bg-white {
  background-color: #fff;
}
.bg-transparent {
  background-color: transparent;
}
.bg-dark-red {
  background-color: #f00008;
}
.bg-red {
  background-color: #ff3223;
}
.bg-orange {
  background-color: #f3a801;
}
.bg-gold {
  background-color: #f2c800;
}
.bg-yellow {
  background-color: #ffde37;
}
.bg-purple {
  background-color: #7d5da9;
}
.bg-light-purple {
  background-color: #8d4f92;
}
.bg-hot-pink {
  background-color: #d62288;
}
.bg-dark-pink {
  background-color: #c64774;
}
.bg-pink {
  background-color: #f49cc8;
}
.bg-dark-green {
  background-color: #006C71;
}
.bg-green {
  background-color: #41D69F;
}
.bg-navy {
  background-color: #001b44;
}
.bg-dark-blue {
  background-color: #00449e;
}
.bg-blue {
  background-color: #357edd;
}
.bg-light-blue {
  background-color: #96ccff;
}
.bg-lightest-blue {
  background-color: #cdecff;
}
.bg-washed-blue {
  background-color: #f6fffe;
}
.bg-washed-green {
  background-color: #e8fdf5;
}
.bg-washed-yellow {
  background-color: #fff8d5;
}
.bg-light-pink {
  background-color: #efa4b8;
}
.bg-light-yellow {
  background-color: #f3dd70;
}
.bg-light-red {
  background-color: #ffd3c0;
}
/* Skins for specific pseudoclasses */
.hover-black:hover {
  color: #000;
}
.hover-near-black:hover {
  color: #111;
}
.hover-dark-gray:hover {
  color: #333;
}
.hover-mid-gray:hover {
  color: #555;
}
.hover-gray:hover {
  color: #777;
}
.hover-silver:hover {
  color: #999;
}
.hover-light-silver:hover {
  color: #aaa;
}
.hover-moon-gray:hover {
  color: #ccc;
}
.hover-light-gray:hover {
  color: #eee;
}
.hover-near-white:hover {
  color: #f4f4f4;
}
.hover-white:hover {
  color: #fff;
}
.hover-bg-black:hover {
  background-color: #000;
}
.hover-bg-near-black:hover {
  background-color: #111;
}
.hover-bg-dark-gray:hover {
  background-color: #333;
}
.hover-bg-mid-gray:hover {
  background-color: #555;
}
.hover-bg-gray:hover {
  background-color: #777;
}
.hover-bg-silver:hover {
  background-color: #999;
}
.hover-bg-light-silver:hover {
  background-color: #aaa;
}
.hover-bg-moon-gray:hover {
  background-color: #ccc;
}
.hover-bg-light-gray:hover {
  background-color: #eee;
}
.hover-bg-near-white:hover {
  background-color: #f4f4f4;
}
.hover-bg-white:hover {
  background-color: #fff;
}
.hover-bg-transparent:hover {
  background-color: transparent;
}
.hover-bg-dark-red:hover {
  background-color: #f00008;
}
.hover-bg-red:hover {
  background-color: #ff3223;
}
.hover-bg-orange:hover {
  background-color: #f3a801;
}
.hover-bg-gold:hover {
  background-color: #f2c800;
}
.hover-bg-yellow:hover {
  background-color: #ffde37;
}
.hover-bg-purple:hover {
  background-color: #7d5da9;
}
.hover-bg-light-purple:hover {
  background-color: #8d4f92;
}
.hover-bg-hot-pink:hover {
  background-color: #d62288;
}
.hover-bg-dark-pink:hover {
  background-color: #c64774;
}
.hover-bg-pink:hover {
  background-color: #f49cc8;
}
.hover-bg-dark-green:hover {
  background-color: #006C71;
}
.hover-bg-green:hover {
  background-color: #41D69F;
}
.hover-bg-navy:hover {
  background-color: #001b44;
}
.hover-bg-dark-blue:hover {
  background-color: #00449e;
}
.hover-bg-blue:hover {
  background-color: #357edd;
}
.hover-bg-light-blue:hover {
  background-color: #96ccff;
}
.hover-bg-lightest-blue:hover {
  background-color: #cdecff;
}
.hover-bg-washed-blue:hover {
  background-color: #f6fffe;
}
.hover-bg-washed-green:hover {
  background-color: #e8fdf5;
}
.hover-bg-washed-yellow:hover {
  background-color: #fff8d5;
}
.hover-bg-light-pink:hover {
  background-color: #efa4b8;
}
.hover-bg-light-yellow:hover {
  background-color: #f3dd70;
}
.hover-bg-light-red:hover {
  background-color: #ffd3c0;
}
/* Variables */
/* Spacing Scale - based on a ratio of 1:2 */
/* Media Queries */
/*
   SPACING

   An eight step powers of two scale ranging from 0 to 16rem.
   Namespaces are composable and thus highly grockable - check the legend below

   Legend:

   p = padding
   m = margin

   a = all
   h = horizontal
   v = vertical
   t = top
   r = right
   b = bottom
   l = left

   0 = none
   1 = 1st step in spacing scale
   2 = 2nd step in spacing scale
   3 = 3rd step in spacing scale
   4 = 4th step in spacing scale
   5 = 5th step in spacing scale
   6 = 6th step in spacing scale
   7 = 7th step in spacing scale

*/
.pa0 {
  padding: 0;
}
.pa1 {
  padding: .25rem;
}
.pa2 {
  padding: .5rem;
}
.pa3 {
  padding: 1rem;
}
.pa4 {
  padding: 2rem;
}
.pa5 {
  padding: 4rem;
}
.pa6 {
  padding: 8rem;
}
.pa7 {
  padding: 16rem;
}
.pl0 {
  padding-left: 0;
}
.pl1 {
  padding-left: .25rem;
}
.pl2 {
  padding-left: .5rem;
}
.pl3 {
  padding-left: 1rem;
}
.pl4 {
  padding-left: 2rem;
}
.pl5 {
  padding-left: 4rem;
}
.pl6 {
  padding-left: 8rem;
}
.pl7 {
  padding-left: 16rem;
}
.pr0 {
  padding-right: 0;
}
.pr1 {
  padding-right: .25rem;
}
.pr2 {
  padding-right: .5rem;
}
.pr3 {
  padding-right: 1rem;
}
.pr4 {
  padding-right: 2rem;
}
.pr5 {
  padding-right: 4rem;
}
.pr6 {
  padding-right: 8rem;
}
.pr7 {
  padding-right: 16rem;
}
.pb0 {
  padding-bottom: 0;
}
.pb1 {
  padding-bottom: .25rem;
}
.pb2 {
  padding-bottom: .5rem;
}
.pb3 {
  padding-bottom: 1rem;
}
.pb4 {
  padding-bottom: 2rem;
}
.pb5 {
  padding-bottom: 4rem;
}
.pb6 {
  padding-bottom: 8rem;
}
.pb7 {
  padding-bottom: 16rem;
}
.pt0 {
  padding-top: 0;
}
.pt1 {
  padding-top: .25rem;
}
.pt2 {
  padding-top: .5rem;
}
.pt3 {
  padding-top: 1rem;
}
.pt4 {
  padding-top: 2rem;
}
.pt5 {
  padding-top: 4rem;
}
.pt6 {
  padding-top: 8rem;
}
.pt7 {
  padding-top: 16rem;
}
.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pv1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}
.pv2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pv4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pv5 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.pv6 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.pv7 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}
.ph0 {
  padding-left: 0;
  padding-right: 0;
}
.ph1 {
  padding-left: .25rem;
  padding-right: .25rem;
}
.ph2 {
  padding-left: .5rem;
  padding-right: .5rem;
}
.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.ph5 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.ph6 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.ph7 {
  padding-left: 16rem;
  padding-right: 16rem;
}
.ma0 {
  margin: 0;
}
.ma1 {
  margin: .25rem;
}
.ma2 {
  margin: .5rem;
}
.ma3 {
  margin: 1rem;
}
.ma4 {
  margin: 2rem;
}
.ma5 {
  margin: 4rem;
}
.ma6 {
  margin: 8rem;
}
.ma7 {
  margin: 16rem;
}
.ml0 {
  margin-left: 0;
}
.ml1 {
  margin-left: .25rem;
}
.ml2 {
  margin-left: .5rem;
}
.ml3 {
  margin-left: 1rem;
}
.ml4 {
  margin-left: 2rem;
}
.ml5 {
  margin-left: 4rem;
}
.ml6 {
  margin-left: 8rem;
}
.ml7 {
  margin-left: 16rem;
}
.mr0 {
  margin-right: 0;
}
.mr1 {
  margin-right: .25rem;
}
.mr2 {
  margin-right: .5rem;
}
.mr3 {
  margin-right: 1rem;
}
.mr4 {
  margin-right: 2rem;
}
.mr5 {
  margin-right: 4rem;
}
.mr6 {
  margin-right: 8rem;
}
.mr7 {
  margin-right: 16rem;
}
.mb0 {
  margin-bottom: 0;
}
.mb1 {
  margin-bottom: .25rem;
}
.mb2 {
  margin-bottom: .5rem;
}
.mb3 {
  margin-bottom: 1rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mb5 {
  margin-bottom: 4rem;
}
.mb6 {
  margin-bottom: 8rem;
}
.mb7 {
  margin-bottom: 16rem;
}
.mt0 {
  margin-top: 0;
}
.mt1 {
  margin-top: .25rem;
}
.mt2 {
  margin-top: .5rem;
}
.mt3 {
  margin-top: 1rem;
}
.mt4 {
  margin-top: 2rem;
}
.mt5 {
  margin-top: 4rem;
}
.mt6 {
  margin-top: 8rem;
}
.mt7 {
  margin-top: 16rem;
}
.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mv1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}
.mv2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.mv3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mv4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mv5 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.mv6 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.mv7 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}
.mh0 {
  margin-left: 0;
  margin-right: 0;
}
.mh1 {
  margin-left: .25rem;
  margin-right: .25rem;
}
.mh2 {
  margin-left: .5rem;
  margin-right: .5rem;
}
.mh3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mh4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mh5 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mh6 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.mh7 {
  margin-left: 16rem;
  margin-right: 16rem;
}
/*

  TABLES

*/
.collapse {
  border-collapse: collapse;
  border-spacing: 0;
}
.striped--moon-gray:nth-child(odd) {
  background-color: #aaa;
}
.striped--moon-gray:nth-child(odd) {
  background-color: #ccc;
}
.striped--light-gray:nth-child(odd) {
  background-color: #eee;
}
.striped--near-white:nth-child(odd) {
  background-color: #f4f4f4;
}
/*

   TEXT DECORATION

*/
.strike {
  text-decoration: line-through;
}
.underline {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none;
}
/*

  TEXT ALIGN

*/
.tl {
  text-align: left;
}
.tr {
  text-align: right;
}
.tc {
  text-align: center;
}
/*

   TEXT TRANSFORM

*/
.ttc {
  text-transform: capitalize;
}
.ttl {
  text-transform: lowercase;
}
.ttu {
  text-transform: uppercase;
}
.ttn {
  text-transform: none;
}
/*

   TYPE SCALE

*/
/* For Hero Titles */
.f-6,
.f-headline {
  font-size: 6rem;
}
.f-5,
.f-subheadline {
  font-size: 5rem;
}
/* Type Scale */
.f1 {
  font-size: 3rem;
}
.f2 {
  font-size: 2.25rem;
}
.f3 {
  font-size: 1.5rem;
}
.f4 {
  font-size: 1.25rem;
}
.f5 {
  font-size: 1rem;
}
.f6 {
  font-size: .875rem;
}
/*

   TYPOGRAPHY

*/
/* Measure is limited to ~66 characters */
.measure {
  max-width: 30em;
}
/* Measure is limited to ~80 characters */
.measure-wide {
  max-width: 34em;
}
/* Measure is limited to ~45 characters */
.measure-narrow {
  max-width: 20em;
}
/* Book paragraph style - paragraphs are indented with no vertical spacing. */
.indent {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
.small-caps {
  font-variant: small-caps;
}
/* Combine this class with a width to truncate text (or just leave as is to truncate at width of containing element. */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*

   UTILITIES

*/
/* This is for fluid media that is embedded from third party sites like youtube, vimeo etc.
 * Wrap the outer element in aspect-ratio and then extend it with the desired ratio i.e
 * Make sure there are no height and width attributes on the embedded media.
 * Adapted from: https://github.com/suitcss/components-flex-embed
 *
 * Example:
 *
 * <div class="aspect-ratio aspect-ratio--16x9">
 *  <iframe class="aspect-ratio--object"></iframe>
 * </div>
 *
 * */
.aspect-ratio {
  height: 0;
  position: relative;
}
.aspect-ratio--16x9 {
  padding-bottom: 56.25%;
}
.aspect-ratio--4x3 {
  padding-bottom: 75%;
}
.aspect-ratio--8x5 {
  padding-bottom: 62.5%;
}
.aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.overflow-container {
  overflow-y: scroll;
}
.center {
  margin-right: auto;
  margin-left: auto;
}
/*

   VISIBILITY

*/
/*
    Text that is hidden but accessible
    Ref: http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.clip {
  position: fixed !important;
  _position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */

  clip: rect(1px, 1px, 1px, 1px);
}
/*

   WHITE SPACE

*/
.ws-normal {
  white-space: normal;
}
.nowrap {
  white-space: nowrap;
}
.pre {
  white-space: pre;
}
/*

   VERTICAL ALIGN

*/
.v-base {
  vertical-align: baseline;
}
.v-mid {
  vertical-align: middle;
}
.v-top {
  vertical-align: top;
}
.v-btm {
  vertical-align: bottom;
}
/*

  HOVER EFFECTS


*/
/*

  Dim element on hover by adding the dim class.

*/
.dim {
  opacity: 1;
  transition: opacity 0.15s ease-in;
}
.dim:hover,
.dim:focus {
  opacity: .5;
  transition: opacity 0.15s ease-in;
}
.dim:active {
  opacity: .8;
  transition: opacity 0.15s ease-out;
}
/*

  Hide child & reveal on hover:

  Put the hide-child class on a parent element and any nested element with the
  child class will be hidden and displayed on hover or focus.

  <div class="hide-child">
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
  </div>
*/
.hide-child .child {
  opacity: 0;
  transition: opacity 0.15s ease-in;
}
.hide-child:hover .child,
.hide-child:focus .child,
.hide-child:active .child {
  opacity: 1;
  transition: opacity 0.15s ease-in;
}
.underline-hover:hover,
.underline-hover:focus {
  text-decoration: underline;
}
/* Can combine this with overflow-hidden to make background images grow on hover
 * even if you are using background-size: cover */
.grow {
  transition: transform .2s;
}
.grow:hover {
  transform: scale(1.05);
}
.grow-large {
  transition: transform .2s;
}
.grow-large:hover {
  transform: scale(1.2);
}
/* Add pointer on hover */
.pointer:hover {
  cursor: pointer;
}
/*

  STYLES

  Add custom styles here.

*/
/* Import media queries at end as this will allow you to customize them
 * and override what is currently declared in each module.
 */
/*

  CUSTOM MEDIA QUERIES

  Media query values can be changed to fit your own content.
  There are no magic bullets when it comes to media query width values.
  They should be declared in em units - and they should be set to meet
  the needs of your content.

  These media queries can be referenced like so:

  @media (--breakpoint-not-small) {
    .medium-and-larger-specific-style {
      background-color: red;
    }
  }

  @media (--breakpoint-medium) {
    .medium-screen-specific-style {
      background-color: red;
    }
  }

  @media (--breakpoint-large) {
    .large-screen-specific-style {
      background-color: red;
    }
  }

  @media (--breakpoint-extra-large) {
    .extra-large-screen-specific-style {
      background-color: red;
    }
  }

*/
/* DEBUGGING */
/*

  DEBUG CHILDREN

  Just add the debug class to any element to see outlines on its
  children.

*/
.debug * {
  outline: 1px solid gold;
}
/* Uncomment out this line and rebuild if you want to debug your layout.*/
/* @import './_debug'; */
@media screen and (min-width: 30em) {
  .cover-ns {
    background-size: cover;
  }
  .contain-ns {
    background-size: contain;
  }
  .ba-ns {
    border-style: solid;
    border-width: 1px;
  }
  .bt-ns {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-ns {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-ns {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-ns {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-ns {
    border-style: none;
    border-width: 0;
  }
  .br0-ns {
    border-radius: 0;
  }
  .br1-ns {
    border-radius: .125rem;
  }
  .br2-ns {
    border-radius: .25rem;
  }
  .br3-ns {
    border-radius: .5rem;
  }
  .br4-ns {
    border-radius: 1rem;
  }
  .br-100-ns {
    border-radius: 100%;
  }
  .br--bottom-ns {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .br--top-ns {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .br--right-ns {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .br--left-ns {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .b--dotted-ns {
    border-style: dotted;
  }
  .b--dashed-ns {
    border-style: dashed;
  }
  .b--solid-ns {
    border-style: solid;
  }
  .b--none-ns {
    border-style: none;
  }
  .bw0-ns {
    border-width: 0;
  }
  .bw1-ns {
    border-width: .125rem;
  }
  .bw2-ns {
    border-width: .25rem;
  }
  .bw3-ns {
    border-width: .5rem;
  }
  .bw4-ns {
    border-width: 1rem;
  }
  .bw5-ns {
    border-width: 2rem;
  }
  .bt-0-ns {
    border-top-width: 0;
  }
  .br-0-ns {
    border-right-width: 0;
  }
  .bb-0-ns {
    border-bottom-width: 0;
  }
  .bl-0-ns {
    border-left-width: 0;
  }
  .shadow-1-ns {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-2-ns {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-3-ns {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-4-ns {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .shadow-5-ns {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .top-0-ns {
    top: 0;
  }
  .left-0-ns {
    left: 0;
  }
  .right-0-ns {
    right: 0;
  }
  .bottom-0-ns {
    bottom: 0;
  }
  .top-1-ns {
    top: 1rem;
  }
  .left-1-ns {
    left: 1rem;
  }
  .right-1-ns {
    right: 1rem;
  }
  .bottom-1-ns {
    bottom: 1rem;
  }
  .top-2-ns {
    top: 2rem;
  }
  .left-2-ns {
    left: 2rem;
  }
  .right-2-ns {
    right: 2rem;
  }
  .bottom-2-ns {
    bottom: 2rem;
  }
  .top--1-ns {
    top: -1rem;
  }
  .right--1-ns {
    right: -1rem;
  }
  .bottom--1-ns {
    bottom: -1rem;
  }
  .left--1-ns {
    left: -1rem;
  }
  .top--2-ns {
    top: -2rem;
  }
  .right--2-ns {
    right: -2rem;
  }
  .bottom--2-ns {
    bottom: -2rem;
  }
  .left--2-ns {
    left: -2rem;
  }
  .absolute--fill-ns {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .cl-ns {
    clear: left;
  }
  .cr-ns {
    clear: right;
  }
  .cb-ns {
    clear: both;
  }
  .cn-ns {
    clear: none;
  }
  .dn-ns {
    display: none;
  }
  .di-ns {
    display: inline;
  }
  .db-ns {
    display: block;
  }
  .dib-ns {
    display: inline-block;
  }
  .dit-ns {
    display: inline-table;
  }
  .dt-ns {
    display: table;
  }
  .dtc-ns {
    display: table-cell;
  }
  .dt-row-ns {
    display: table-row;
  }
  .dt-row-group-ns {
    display: table-row-group;
  }
  .dt-column-ns {
    display: table-column;
  }
  .dt-column-group-ns {
    display: table-column-group;
  }
  .dt--fixed-ns {
    table-layout: fixed;
    width: 100%;
  }
  .flex-ns {
    display: flex;
  }
  .flex-auto-ns {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    /* 1 */

  }
  .flex-none-ns {
    flex: none;
  }
  .flex-column-ns {
    flex-direction: column;
  }
  .flex-wrap-ns {
    flex-wrap: wrap;
  }
  .items-start-ns {
    align-items: flex-start;
  }
  .items-end-ns {
    align-items: flex-end;
  }
  .items-center-ns {
    align-items: center;
  }
  .items-baseline-ns {
    align-items: baseline;
  }
  .items-stretch-ns {
    align-items: stretch;
  }
  .self-start-ns {
    align-self: flex-start;
  }
  .self-end-ns {
    align-self: flex-end;
  }
  .self-center-ns {
    align-self: center;
  }
  .self-baseline-ns {
    align-self: baseline;
  }
  .self-stretch-ns {
    align-self: stretch;
  }
  .justify-start-ns {
    justify-content: flex-start;
  }
  .justify-end-ns {
    justify-content: flex-end;
  }
  .justify-center-ns {
    justify-content: center;
  }
  .justify-between-ns {
    justify-content: space-between;
  }
  .justify-around-ns {
    justify-content: space-around;
  }
  .content-start-ns {
    align-content: flex-start;
  }
  .content-end-ns {
    align-content: flex-end;
  }
  .content-center-ns {
    align-content: center;
  }
  .content-between-ns {
    align-content: space-between;
  }
  .content-around-ns {
    align-content: space-around;
  }
  .content-stretch-ns {
    align-content: stretch;
  }
  .order-0-ns {
    order: 0;
  }
  .order-1-ns {
    order: 1;
  }
  .order-2-ns {
    order: 2;
  }
  .order-3-ns {
    order: 3;
  }
  .order-4-ns {
    order: 4;
  }
  .order-5-ns {
    order: 5;
  }
  .order-6-ns {
    order: 6;
  }
  .order-7-ns {
    order: 7;
  }
  .order-8-ns {
    order: 8;
  }
  .order-last-ns {
    order: 99999;
  }
  .fl-ns {
    float: left;
    display: inline;
  }
  .fr-ns {
    float: right;
    display: inline;
  }
  .fn-ns {
    float: none;
  }
  .i-ns {
    font-style: italic;
  }
  .fs-normal-ns {
    font-style: normal;
  }
  .normal-ns {
    font-weight: normal;
  }
  .b-ns {
    font-weight: bold;
  }
  .fw1-ns {
    font-weight: 100;
  }
  .fw2-ns {
    font-weight: 200;
  }
  .fw3-ns {
    font-weight: 300;
  }
  .fw4-ns {
    font-weight: 400;
  }
  .fw5-ns {
    font-weight: 500;
  }
  .fw6-ns {
    font-weight: 600;
  }
  .fw7-ns {
    font-weight: 700;
  }
  .fw8-ns {
    font-weight: 800;
  }
  .fw9-ns {
    font-weight: 900;
  }
  .h1-ns {
    height: 1rem;
  }
  .h2-ns {
    height: 2rem;
  }
  .h3-ns {
    height: 4rem;
  }
  .h4-ns {
    height: 8rem;
  }
  .h5-ns {
    height: 16rem;
  }
  .h-25-ns {
    height: 25%;
  }
  .h-50-ns {
    height: 50%;
  }
  .h-75-ns {
    height: 75%;
  }
  .h-100-ns {
    height: 100%;
  }
  .h-auto-ns {
    height: auto;
  }
  .h-inherit-ns {
    height: inherit;
  }
  .tracked-ns {
    letter-spacing: .16em;
  }
  .tracked-tight-ns {
    letter-spacing: -0.05em;
  }
  .tracked-mega-ns {
    letter-spacing: .32em;
  }
  .lh-solid-ns {
    line-height: 1;
  }
  .lh-title-ns {
    line-height: 1.3;
  }
  .lh-copy-ns {
    line-height: 1.6;
  }
  .mw-100-ns {
    max-width: 100%;
  }
  .mw1-ns {
    max-width: 1rem;
  }
  .mw2-ns {
    max-width: 2rem;
  }
  .mw3-ns {
    max-width: 4rem;
  }
  .mw4-ns {
    max-width: 8rem;
  }
  .mw5-ns {
    max-width: 16rem;
  }
  .mw6-ns {
    max-width: 32rem;
  }
  .mw7-ns {
    max-width: 48rem;
  }
  .mw8-ns {
    max-width: 64rem;
  }
  .mw9-ns {
    max-width: 96rem;
  }
  .mw-none-ns {
    max-width: none;
  }
  .w1-ns {
    width: 1rem;
  }
  .w2-ns {
    width: 2rem;
  }
  .w3-ns {
    width: 4rem;
  }
  .w4-ns {
    width: 8rem;
  }
  .w5-ns {
    width: 16rem;
  }
  .w-10-ns {
    width: 10%;
  }
  .w-20-ns {
    width: 20%;
  }
  .w-25-ns {
    width: 25%;
  }
  .w-33-ns {
    width: 33%;
  }
  .w-34-ns {
    width: 34%;
  }
  .w-40-ns {
    width: 40%;
  }
  .w-50-ns {
    width: 50%;
  }
  .w-60-ns {
    width: 60%;
  }
  .w-75-ns {
    width: 75%;
  }
  .w-80-ns {
    width: 80%;
  }
  .w-100-ns {
    width: 100%;
  }
  .w-auto-ns {
    width: auto;
  }
  .overflow-visible-ns {
    overflow: visible;
  }
  .overflow-hidden-ns {
    overflow: hidden;
  }
  .overflow-scroll-ns {
    overflow: scroll;
  }
  .overflow-auto-ns {
    overflow: auto;
  }
  .overflow-x-visible-ns {
    overflow-x: visible;
  }
  .overflow-x-hidden-ns {
    overflow-x: hidden;
  }
  .overflow-x-scroll-ns {
    overflow-x: scroll;
  }
  .overflow-x-auto-ns {
    overflow-x: auto;
  }
  .overflow-y-visible-ns {
    overflow-y: visible;
  }
  .overflow-y-hidden-ns {
    overflow-y: hidden;
  }
  .overflow-y-scroll-ns {
    overflow-y: scroll;
  }
  .overflow-y-auto-ns {
    overflow-y: auto;
  }
  .static-ns {
    position: static;
  }
  .relative-ns {
    position: relative;
  }
  .absolute-ns {
    position: absolute;
  }
  .fixed-ns {
    position: fixed;
  }
  .pa0-ns {
    padding: 0;
  }
  .pa1-ns {
    padding: .25rem;
  }
  .pa2-ns {
    padding: .5rem;
  }
  .pa3-ns {
    padding: 1rem;
  }
  .pa4-ns {
    padding: 2rem;
  }
  .pa5-ns {
    padding: 4rem;
  }
  .pa6-ns {
    padding: 8rem;
  }
  .pa7-ns {
    padding: 16rem;
  }
  .pl0-ns {
    padding-left: 0;
  }
  .pl1-ns {
    padding-left: .25rem;
  }
  .pl2-ns {
    padding-left: .5rem;
  }
  .pl3-ns {
    padding-left: 1rem;
  }
  .pl4-ns {
    padding-left: 2rem;
  }
  .pl5-ns {
    padding-left: 4rem;
  }
  .pl6-ns {
    padding-left: 8rem;
  }
  .pl7-ns {
    padding-left: 16rem;
  }
  .pr0-ns {
    padding-right: 0;
  }
  .pr1-ns {
    padding-right: .25rem;
  }
  .pr2-ns {
    padding-right: .5rem;
  }
  .pr3-ns {
    padding-right: 1rem;
  }
  .pr4-ns {
    padding-right: 2rem;
  }
  .pr5-ns {
    padding-right: 4rem;
  }
  .pr6-ns {
    padding-right: 8rem;
  }
  .pr7-ns {
    padding-right: 16rem;
  }
  .pb0-ns {
    padding-bottom: 0;
  }
  .pb1-ns {
    padding-bottom: .25rem;
  }
  .pb2-ns {
    padding-bottom: .5rem;
  }
  .pb3-ns {
    padding-bottom: 1rem;
  }
  .pb4-ns {
    padding-bottom: 2rem;
  }
  .pb5-ns {
    padding-bottom: 4rem;
  }
  .pb6-ns {
    padding-bottom: 8rem;
  }
  .pb7-ns {
    padding-bottom: 16rem;
  }
  .pt0-ns {
    padding-top: 0;
  }
  .pt1-ns {
    padding-top: .25rem;
  }
  .pt2-ns {
    padding-top: .5rem;
  }
  .pt3-ns {
    padding-top: 1rem;
  }
  .pt4-ns {
    padding-top: 2rem;
  }
  .pt5-ns {
    padding-top: 4rem;
  }
  .pt6-ns {
    padding-top: 8rem;
  }
  .pt7-ns {
    padding-top: 16rem;
  }
  .pv0-ns {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pv1-ns {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
  .pv2-ns {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .pv3-ns {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .pv4-ns {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .pv5-ns {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .pv6-ns {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .pv7-ns {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .ph0-ns {
    padding-left: 0;
    padding-right: 0;
  }
  .ph1-ns {
    padding-left: .25rem;
    padding-right: .25rem;
  }
  .ph2-ns {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .ph3-ns {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ph4-ns {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .ph5-ns {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .ph6-ns {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .ph7-ns {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .ma0-ns {
    margin: 0;
  }
  .ma1-ns {
    margin: .25rem;
  }
  .ma2-ns {
    margin: .5rem;
  }
  .ma3-ns {
    margin: 1rem;
  }
  .ma4-ns {
    margin: 2rem;
  }
  .ma5-ns {
    margin: 4rem;
  }
  .ma6-ns {
    margin: 8rem;
  }
  .ma7-ns {
    margin: 16rem;
  }
  .ml0-ns {
    margin-left: 0;
  }
  .ml1-ns {
    margin-left: .25rem;
  }
  .ml2-ns {
    margin-left: .5rem;
  }
  .ml3-ns {
    margin-left: 1rem;
  }
  .ml4-ns {
    margin-left: 2rem;
  }
  .ml5-ns {
    margin-left: 4rem;
  }
  .ml6-ns {
    margin-left: 8rem;
  }
  .ml7-ns {
    margin-left: 16rem;
  }
  .mr0-ns {
    margin-right: 0;
  }
  .mr1-ns {
    margin-right: .25rem;
  }
  .mr2-ns {
    margin-right: .5rem;
  }
  .mr3-ns {
    margin-right: 1rem;
  }
  .mr4-ns {
    margin-right: 2rem;
  }
  .mr5-ns {
    margin-right: 4rem;
  }
  .mr6-ns {
    margin-right: 8rem;
  }
  .mr7-ns {
    margin-right: 16rem;
  }
  .mb0-ns {
    margin-bottom: 0;
  }
  .mb1-ns {
    margin-bottom: .25rem;
  }
  .mb2-ns {
    margin-bottom: .5rem;
  }
  .mb3-ns {
    margin-bottom: 1rem;
  }
  .mb4-ns {
    margin-bottom: 2rem;
  }
  .mb5-ns {
    margin-bottom: 4rem;
  }
  .mb6-ns {
    margin-bottom: 8rem;
  }
  .mb7-ns {
    margin-bottom: 16rem;
  }
  .mt0-ns {
    margin-top: 0;
  }
  .mt1-ns {
    margin-top: .25rem;
  }
  .mt2-ns {
    margin-top: .5rem;
  }
  .mt3-ns {
    margin-top: 1rem;
  }
  .mt4-ns {
    margin-top: 2rem;
  }
  .mt5-ns {
    margin-top: 4rem;
  }
  .mt6-ns {
    margin-top: 8rem;
  }
  .mt7-ns {
    margin-top: 16rem;
  }
  .mv0-ns {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mv1-ns {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
  .mv2-ns {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
  .mv3-ns {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mv4-ns {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mv5-ns {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .mv6-ns {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .mv7-ns {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .mh0-ns {
    margin-left: 0;
    margin-right: 0;
  }
  .mh1-ns {
    margin-left: .25rem;
    margin-right: .25rem;
  }
  .mh2-ns {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .mh3-ns {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mh4-ns {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mh5-ns {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mh6-ns {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mh7-ns {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .strike-ns {
    text-decoration: line-through;
  }
  .underline-ns {
    text-decoration: underline;
  }
  .no-underline-ns {
    text-decoration: none;
  }
  .tl-ns {
    text-align: left;
  }
  .tr-ns {
    text-align: right;
  }
  .tc-ns {
    text-align: center;
  }
  .ttc-ns {
    text-transform: capitalize;
  }
  .ttl-ns {
    text-transform: lowercase;
  }
  .ttu-ns {
    text-transform: uppercase;
  }
  .ttn-ns {
    text-transform: none;
  }
  .f-6-ns,
  .f-headline-ns {
    font-size: 6rem;
  }
  .f-5-ns,
  .f-subheadline-ns {
    font-size: 5rem;
  }
  .f1-ns {
    font-size: 3rem;
  }
  .f2-ns {
    font-size: 2.25rem;
  }
  .f3-ns {
    font-size: 1.5rem;
  }
  .f4-ns {
    font-size: 1.25rem;
  }
  .f5-ns {
    font-size: 1rem;
  }
  .f6-ns {
    font-size: .875rem;
  }
  .measure-ns {
    max-width: 30em;
  }
  .measure-wide-ns {
    max-width: 34em;
  }
  .measure-narrow-ns {
    max-width: 20em;
  }
  .indent-ns {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-caps-ns {
    font-variant: small-caps;
  }
  .truncate-ns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .clip-ns {
    position: fixed !important;
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */

    clip: rect(1px, 1px, 1px, 1px);
  }
  .ws-normal-ns {
    white-space: normal;
  }
  .nowrap-ns {
    white-space: nowrap;
  }
  .pre-ns {
    white-space: pre;
  }
  .v-base-ns {
    vertical-align: baseline;
  }
  .v-mid-ns {
    vertical-align: middle;
  }
  .v-top-ns {
    vertical-align: top;
  }
  .v-btm-ns {
    vertical-align: bottom;
  }
}
@media screen and (min-width: 30em) and (max-width: 60em) {
  .cover-m {
    background-size: cover;
  }
  .contain-m {
    background-size: contain;
  }
  .ba-m {
    border-style: solid;
    border-width: 1px;
  }
  .bt-m {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-m {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-m {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-m {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-m {
    border-style: none;
    border-width: 0;
  }
  .br0-m {
    border-radius: 0;
  }
  .br1-m {
    border-radius: .125rem;
  }
  .br2-m {
    border-radius: .25rem;
  }
  .br3-m {
    border-radius: .5rem;
  }
  .br4-m {
    border-radius: 1rem;
  }
  .br-100-m {
    border-radius: 100%;
  }
  .br--bottom-m {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .br--top-m {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .br--right-m {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .br--left-m {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .b--dotted-m {
    border-style: dotted;
  }
  .b--dashed-m {
    border-style: dashed;
  }
  .b--solid-m {
    border-style: solid;
  }
  .b--none-m {
    border-style: none;
  }
  .bw0-m {
    border-width: 0;
  }
  .bw1-m {
    border-width: .125rem;
  }
  .bw2-m {
    border-width: .25rem;
  }
  .bw3-m {
    border-width: .5rem;
  }
  .bw4-m {
    border-width: 1rem;
  }
  .bw5-m {
    border-width: 2rem;
  }
  .bt-0-m {
    border-top-width: 0;
  }
  .br-0-m {
    border-right-width: 0;
  }
  .bb-0-m {
    border-bottom-width: 0;
  }
  .bl-0-m {
    border-left-width: 0;
  }
  .shadow-1-m {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-2-m {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-3-m {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-4-m {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .shadow-5-m {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .top-0-m {
    top: 0;
  }
  .left-0-m {
    left: 0;
  }
  .right-0-m {
    right: 0;
  }
  .bottom-0-m {
    bottom: 0;
  }
  .top-1-m {
    top: 1rem;
  }
  .left-1-m {
    left: 1rem;
  }
  .right-1-m {
    right: 1rem;
  }
  .bottom-1-m {
    bottom: 1rem;
  }
  .top-2-m {
    top: 2rem;
  }
  .left-2-m {
    left: 2rem;
  }
  .right-2-m {
    right: 2rem;
  }
  .bottom-2-m {
    bottom: 2rem;
  }
  .top--1-m {
    top: -1rem;
  }
  .right--1-m {
    right: -1rem;
  }
  .bottom--1-m {
    bottom: -1rem;
  }
  .left--1-m {
    left: -1rem;
  }
  .top--2-m {
    top: -2rem;
  }
  .right--2-m {
    right: -2rem;
  }
  .bottom--2-m {
    bottom: -2rem;
  }
  .left--2-m {
    left: -2rem;
  }
  .absolute--fill-m {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .cl-m {
    clear: left;
  }
  .cr-m {
    clear: right;
  }
  .cb-m {
    clear: both;
  }
  .cn-m {
    clear: none;
  }
  .dn-m {
    display: none;
  }
  .di-m {
    display: inline;
  }
  .db-m {
    display: block;
  }
  .dib-m {
    display: inline-block;
  }
  .dit-m {
    display: inline-table;
  }
  .dt-m {
    display: table;
  }
  .dtc-m {
    display: table-cell;
  }
  .dt-row-m {
    display: table-row;
  }
  .dt-row-group-m {
    display: table-row-group;
  }
  .dt-column-m {
    display: table-column;
  }
  .dt-column-group-m {
    display: table-column-group;
  }
  .dt--fixed-m {
    table-layout: fixed;
    width: 100%;
  }
  .flex-m {
    display: flex;
  }
  .flex-auto-m {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    /* 1 */

  }
  .flex-none-m {
    flex: none;
  }
  .flex-column-m {
    flex-direction: column;
  }
  .flex-wrap-m {
    flex-wrap: wrap;
  }
  .items-start-m {
    align-items: flex-start;
  }
  .items-end-m {
    align-items: flex-end;
  }
  .items-center-m {
    align-items: center;
  }
  .items-baseline-m {
    align-items: baseline;
  }
  .items-stretch-m {
    align-items: stretch;
  }
  .self-start-m {
    align-self: flex-start;
  }
  .self-end-m {
    align-self: flex-end;
  }
  .self-center-m {
    align-self: center;
  }
  .self-baseline-m {
    align-self: baseline;
  }
  .self-stretch-m {
    align-self: stretch;
  }
  .justify-start-m {
    justify-content: flex-start;
  }
  .justify-end-m {
    justify-content: flex-end;
  }
  .justify-center-m {
    justify-content: center;
  }
  .justify-between-m {
    justify-content: space-between;
  }
  .justify-around-m {
    justify-content: space-around;
  }
  .content-start-m {
    align-content: flex-start;
  }
  .content-end-m {
    align-content: flex-end;
  }
  .content-center-m {
    align-content: center;
  }
  .content-between-m {
    align-content: space-between;
  }
  .content-around-m {
    align-content: space-around;
  }
  .content-stretch-m {
    align-content: stretch;
  }
  .order-0-m {
    order: 0;
  }
  .order-1-m {
    order: 1;
  }
  .order-2-m {
    order: 2;
  }
  .order-3-m {
    order: 3;
  }
  .order-4-m {
    order: 4;
  }
  .order-5-m {
    order: 5;
  }
  .order-6-m {
    order: 6;
  }
  .order-7-m {
    order: 7;
  }
  .order-8-m {
    order: 8;
  }
  .order-last-m {
    order: 99999;
  }
  .fl-m {
    float: left;
    display: inline;
  }
  .fr-m {
    float: right;
    display: inline;
  }
  .fn-m {
    float: none;
  }
  .i-m {
    font-style: italic;
  }
  .fs-normal-m {
    font-style: normal;
  }
  .normal-m {
    font-weight: normal;
  }
  .b-m {
    font-weight: bold;
  }
  .fw1-m {
    font-weight: 100;
  }
  .fw2-m {
    font-weight: 200;
  }
  .fw3-m {
    font-weight: 300;
  }
  .fw4-m {
    font-weight: 400;
  }
  .fw5-m {
    font-weight: 500;
  }
  .fw6-m {
    font-weight: 600;
  }
  .fw7-m {
    font-weight: 700;
  }
  .fw8-m {
    font-weight: 800;
  }
  .fw9-m {
    font-weight: 900;
  }
  .h1-m {
    height: 1rem;
  }
  .h2-m {
    height: 2rem;
  }
  .h3-m {
    height: 4rem;
  }
  .h4-m {
    height: 8rem;
  }
  .h5-m {
    height: 16rem;
  }
  .h-25-m {
    height: 25%;
  }
  .h-50-m {
    height: 50%;
  }
  .h-75-m {
    height: 75%;
  }
  .h-100-m {
    height: 100%;
  }
  .h-auto-m {
    height: auto;
  }
  .h-inherit-m {
    height: inherit;
  }
  .tracked-m {
    letter-spacing: .16em;
  }
  .tracked-tight-m {
    letter-spacing: -0.05em;
  }
  .tracked-mega-m {
    letter-spacing: .32em;
  }
  .lh-solid-m {
    line-height: 1;
  }
  .lh-title-m {
    line-height: 1.3;
  }
  .lh-copy-m {
    line-height: 1.6;
  }
  .mw-100-m {
    max-width: 100%;
  }
  .mw1-m {
    max-width: 1rem;
  }
  .mw2-m {
    max-width: 2rem;
  }
  .mw3-m {
    max-width: 4rem;
  }
  .mw4-m {
    max-width: 8rem;
  }
  .mw5-m {
    max-width: 16rem;
  }
  .mw6-m {
    max-width: 32rem;
  }
  .mw7-m {
    max-width: 48rem;
  }
  .mw8-m {
    max-width: 64rem;
  }
  .mw9-m {
    max-width: 96rem;
  }
  .mw-none-m {
    max-width: none;
  }
  .w1-m {
    width: 1rem;
  }
  .w2-m {
    width: 2rem;
  }
  .w3-m {
    width: 4rem;
  }
  .w4-m {
    width: 8rem;
  }
  .w5-m {
    width: 16rem;
  }
  .w-10-m {
    width: 10%;
  }
  .w-20-m {
    width: 20%;
  }
  .w-25-m {
    width: 25%;
  }
  .w-33-m {
    width: 33%;
  }
  .w-34-m {
    width: 34%;
  }
  .w-40-m {
    width: 40%;
  }
  .w-50-m {
    width: 50%;
  }
  .w-60-m {
    width: 60%;
  }
  .w-75-m {
    width: 75%;
  }
  .w-80-m {
    width: 80%;
  }
  .w-100-m {
    width: 100%;
  }
  .w-auto-m {
    width: auto;
  }
  .overflow-visible-m {
    overflow: visible;
  }
  .overflow-hidden-m {
    overflow: hidden;
  }
  .overflow-scroll-m {
    overflow: scroll;
  }
  .overflow-auto-m {
    overflow: auto;
  }
  .overflow-x-visible-m {
    overflow-x: visible;
  }
  .overflow-x-hidden-m {
    overflow-x: hidden;
  }
  .overflow-x-scroll-m {
    overflow-x: scroll;
  }
  .overflow-x-auto-m {
    overflow-x: auto;
  }
  .overflow-y-visible-m {
    overflow-y: visible;
  }
  .overflow-y-hidden-m {
    overflow-y: hidden;
  }
  .overflow-y-scroll-m {
    overflow-y: scroll;
  }
  .overflow-y-auto-m {
    overflow-y: auto;
  }
  .static-m {
    position: static;
  }
  .relative-m {
    position: relative;
  }
  .absolute-m {
    position: absolute;
  }
  .fixed-m {
    position: fixed;
  }
  .pa0-m {
    padding: 0;
  }
  .pa1-m {
    padding: .25rem;
  }
  .pa2-m {
    padding: .5rem;
  }
  .pa3-m {
    padding: 1rem;
  }
  .pa4-m {
    padding: 2rem;
  }
  .pa5-m {
    padding: 4rem;
  }
  .pa6-m {
    padding: 8rem;
  }
  .pa7-m {
    padding: 16rem;
  }
  .pl0-m {
    padding-left: 0;
  }
  .pl1-m {
    padding-left: .25rem;
  }
  .pl2-m {
    padding-left: .5rem;
  }
  .pl3-m {
    padding-left: 1rem;
  }
  .pl4-m {
    padding-left: 2rem;
  }
  .pl5-m {
    padding-left: 4rem;
  }
  .pl6-m {
    padding-left: 8rem;
  }
  .pl7-m {
    padding-left: 16rem;
  }
  .pr0-m {
    padding-right: 0;
  }
  .pr1-m {
    padding-right: .25rem;
  }
  .pr2-m {
    padding-right: .5rem;
  }
  .pr3-m {
    padding-right: 1rem;
  }
  .pr4-m {
    padding-right: 2rem;
  }
  .pr5-m {
    padding-right: 4rem;
  }
  .pr6-m {
    padding-right: 8rem;
  }
  .pr7-m {
    padding-right: 16rem;
  }
  .pb0-m {
    padding-bottom: 0;
  }
  .pb1-m {
    padding-bottom: .25rem;
  }
  .pb2-m {
    padding-bottom: .5rem;
  }
  .pb3-m {
    padding-bottom: 1rem;
  }
  .pb4-m {
    padding-bottom: 2rem;
  }
  .pb5-m {
    padding-bottom: 4rem;
  }
  .pb6-m {
    padding-bottom: 8rem;
  }
  .pb7-m {
    padding-bottom: 16rem;
  }
  .pt0-m {
    padding-top: 0;
  }
  .pt1-m {
    padding-top: .25rem;
  }
  .pt2-m {
    padding-top: .5rem;
  }
  .pt3-m {
    padding-top: 1rem;
  }
  .pt4-m {
    padding-top: 2rem;
  }
  .pt5-m {
    padding-top: 4rem;
  }
  .pt6-m {
    padding-top: 8rem;
  }
  .pt7-m {
    padding-top: 16rem;
  }
  .pv0-m {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pv1-m {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
  .pv2-m {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .pv3-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .pv4-m {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .pv5-m {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .pv6-m {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .pv7-m {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .ph0-m {
    padding-left: 0;
    padding-right: 0;
  }
  .ph1-m {
    padding-left: .25rem;
    padding-right: .25rem;
  }
  .ph2-m {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .ph3-m {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ph4-m {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .ph5-m {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .ph6-m {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .ph7-m {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .ma0-m {
    margin: 0;
  }
  .ma1-m {
    margin: .25rem;
  }
  .ma2-m {
    margin: .5rem;
  }
  .ma3-m {
    margin: 1rem;
  }
  .ma4-m {
    margin: 2rem;
  }
  .ma5-m {
    margin: 4rem;
  }
  .ma6-m {
    margin: 8rem;
  }
  .ma7-m {
    margin: 16rem;
  }
  .ml0-m {
    margin-left: 0;
  }
  .ml1-m {
    margin-left: .25rem;
  }
  .ml2-m {
    margin-left: .5rem;
  }
  .ml3-m {
    margin-left: 1rem;
  }
  .ml4-m {
    margin-left: 2rem;
  }
  .ml5-m {
    margin-left: 4rem;
  }
  .ml6-m {
    margin-left: 8rem;
  }
  .ml7-m {
    margin-left: 16rem;
  }
  .mr0-m {
    margin-right: 0;
  }
  .mr1-m {
    margin-right: .25rem;
  }
  .mr2-m {
    margin-right: .5rem;
  }
  .mr3-m {
    margin-right: 1rem;
  }
  .mr4-m {
    margin-right: 2rem;
  }
  .mr5-m {
    margin-right: 4rem;
  }
  .mr6-m {
    margin-right: 8rem;
  }
  .mr7-m {
    margin-right: 16rem;
  }
  .mb0-m {
    margin-bottom: 0;
  }
  .mb1-m {
    margin-bottom: .25rem;
  }
  .mb2-m {
    margin-bottom: .5rem;
  }
  .mb3-m {
    margin-bottom: 1rem;
  }
  .mb4-m {
    margin-bottom: 2rem;
  }
  .mb5-m {
    margin-bottom: 4rem;
  }
  .mb6-m {
    margin-bottom: 8rem;
  }
  .mb7-m {
    margin-bottom: 16rem;
  }
  .mt0-m {
    margin-top: 0;
  }
  .mt1-m {
    margin-top: .25rem;
  }
  .mt2-m {
    margin-top: .5rem;
  }
  .mt3-m {
    margin-top: 1rem;
  }
  .mt4-m {
    margin-top: 2rem;
  }
  .mt5-m {
    margin-top: 4rem;
  }
  .mt6-m {
    margin-top: 8rem;
  }
  .mt7-m {
    margin-top: 16rem;
  }
  .mv0-m {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mv1-m {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
  .mv2-m {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
  .mv3-m {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mv4-m {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mv5-m {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .mv6-m {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .mv7-m {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .mh0-m {
    margin-left: 0;
    margin-right: 0;
  }
  .mh1-m {
    margin-left: .25rem;
    margin-right: .25rem;
  }
  .mh2-m {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .mh3-m {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mh4-m {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mh5-m {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mh6-m {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mh7-m {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .strike-m {
    text-decoration: line-through;
  }
  .underline-m {
    text-decoration: underline;
  }
  .no-underline-m {
    text-decoration: none;
  }
  .tl-m {
    text-align: left;
  }
  .tr-m {
    text-align: right;
  }
  .tc-m {
    text-align: center;
  }
  .ttc-m {
    text-transform: capitalize;
  }
  .ttl-m {
    text-transform: lowercase;
  }
  .ttu-m {
    text-transform: uppercase;
  }
  .ttn-m {
    text-transform: none;
  }
  .f-6-m,
  .f-headline-m {
    font-size: 6rem;
  }
  .f-5-m,
  .f-subheadline-m {
    font-size: 5rem;
  }
  .f1-m {
    font-size: 3rem;
  }
  .f2-m {
    font-size: 2.25rem;
  }
  .f3-m {
    font-size: 1.5rem;
  }
  .f4-m {
    font-size: 1.25rem;
  }
  .f5-m {
    font-size: 1rem;
  }
  .f6-m {
    font-size: .875rem;
  }
  .measure-m {
    max-width: 30em;
  }
  .measure-wide-m {
    max-width: 34em;
  }
  .measure-narrow-m {
    max-width: 20em;
  }
  .indent-m {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-caps-m {
    font-variant: small-caps;
  }
  .truncate-m {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .clip-m {
    position: fixed !important;
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */

    clip: rect(1px, 1px, 1px, 1px);
  }
  .ws-normal-m {
    white-space: normal;
  }
  .nowrap-m {
    white-space: nowrap;
  }
  .pre-m {
    white-space: pre;
  }
  .v-base-m {
    vertical-align: baseline;
  }
  .v-mid-m {
    vertical-align: middle;
  }
  .v-top-m {
    vertical-align: top;
  }
  .v-btm-m {
    vertical-align: bottom;
  }
}
@media screen and (min-width: 60em) {
  .cover-l {
    background-size: cover;
  }
  .contain-l {
    background-size: contain;
  }
  .ba-l {
    border-style: solid;
    border-width: 1px;
  }
  .bt-l {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-l {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-l {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-l {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-l {
    border-style: none;
    border-width: 0;
  }
  .br0-l {
    border-radius: 0;
  }
  .br1-l {
    border-radius: .125rem;
  }
  .br2-l {
    border-radius: .25rem;
  }
  .br3-l {
    border-radius: .5rem;
  }
  .br4-l {
    border-radius: 1rem;
  }
  .br-100-l {
    border-radius: 100%;
  }
  .br--bottom-l {
    border-radius-top-left: 0;
    border-radius-top-right: 0;
  }
  .br--top-l {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .br--right-l {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .br--left-l {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .b--dotted-l {
    border-style: dotted;
  }
  .b--dashed-l {
    border-style: dashed;
  }
  .b--solid-l {
    border-style: solid;
  }
  .b--none-l {
    border-style: none;
  }
  .bw0-l {
    border-width: 0;
  }
  .bw1-l {
    border-width: .125rem;
  }
  .bw2-l {
    border-width: .25rem;
  }
  .bw3-l {
    border-width: .5rem;
  }
  .bw4-l {
    border-width: 1rem;
  }
  .bw5-l {
    border-width: 2rem;
  }
  .bt-0-l {
    border-top-width: 0;
  }
  .br-0-l {
    border-right-width: 0;
  }
  .bb-0-l {
    border-bottom-width: 0;
  }
  .bl-0-l {
    border-left-width: 0;
  }
  .shadow-1-l {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-2-l {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-3-l {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  }
  .shadow-4-l {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .shadow-5-l {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .top-0-l {
    top: 0;
  }
  .left-0-l {
    left: 0;
  }
  .right-0-l {
    right: 0;
  }
  .bottom-0-l {
    bottom: 0;
  }
  .top-1-l {
    top: 1rem;
  }
  .left-1-l {
    left: 1rem;
  }
  .right-1-l {
    right: 1rem;
  }
  .bottom-1-l {
    bottom: 1rem;
  }
  .top-2-l {
    top: 2rem;
  }
  .left-2-l {
    left: 2rem;
  }
  .right-2-l {
    right: 2rem;
  }
  .bottom-2-l {
    bottom: 2rem;
  }
  .top--1-l {
    top: -1rem;
  }
  .right--1-l {
    right: -1rem;
  }
  .bottom--1-l {
    bottom: -1rem;
  }
  .left--1-l {
    left: -1rem;
  }
  .top--2-l {
    top: -2rem;
  }
  .right--2-l {
    right: -2rem;
  }
  .bottom--2-l {
    bottom: -2rem;
  }
  .left--2-l {
    left: -2rem;
  }
  .absolute--fill-l {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .cl-l {
    clear: left;
  }
  .cr-l {
    clear: right;
  }
  .cb-l {
    clear: both;
  }
  .cn-l {
    clear: none;
  }
  .dn-l {
    display: none;
  }
  .di-l {
    display: inline;
  }
  .db-l {
    display: block;
  }
  .dib-l {
    display: inline-block;
  }
  .dit-l {
    display: inline-table;
  }
  .dt-l {
    display: table;
  }
  .dtc-l {
    display: table-cell;
  }
  .dt-row-l {
    display: table-row;
  }
  .dt-row-group-l {
    display: table-row-group;
  }
  .dt-column-l {
    display: table-column;
  }
  .dt-column-group-l {
    display: table-column-group;
  }
  .dt--fixed-l {
    table-layout: fixed;
    width: 100%;
  }
  .flex-l {
    display: flex;
  }
  .flex-auto-l {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
    /* 1 */

  }
  .flex-none-l {
    flex: none;
  }
  .flex-column-l {
    flex-direction: column;
  }
  .flex-wrap-l {
    flex-wrap: wrap;
  }
  .items-start-l {
    align-items: flex-start;
  }
  .items-end-l {
    align-items: flex-end;
  }
  .items-center-l {
    align-items: center;
  }
  .items-baseline-l {
    align-items: baseline;
  }
  .items-stretch-l {
    align-items: stretch;
  }
  .self-start-l {
    align-self: flex-start;
  }
  .self-end-l {
    align-self: flex-end;
  }
  .self-center-l {
    align-self: center;
  }
  .self-baseline-l {
    align-self: baseline;
  }
  .self-stretch-l {
    align-self: stretch;
  }
  .justify-start-l {
    justify-content: flex-start;
  }
  .justify-end-l {
    justify-content: flex-end;
  }
  .justify-center-l {
    justify-content: center;
  }
  .justify-between-l {
    justify-content: space-between;
  }
  .justify-around-l {
    justify-content: space-around;
  }
  .content-start-l {
    align-content: flex-start;
  }
  .content-end-l {
    align-content: flex-end;
  }
  .content-center-l {
    align-content: center;
  }
  .content-between-l {
    align-content: space-between;
  }
  .content-around-l {
    align-content: space-around;
  }
  .content-stretch-l {
    align-content: stretch;
  }
  .order-0-l {
    order: 0;
  }
  .order-1-l {
    order: 1;
  }
  .order-2-l {
    order: 2;
  }
  .order-3-l {
    order: 3;
  }
  .order-4-l {
    order: 4;
  }
  .order-5-l {
    order: 5;
  }
  .order-6-l {
    order: 6;
  }
  .order-7-l {
    order: 7;
  }
  .order-8-l {
    order: 8;
  }
  .order-last-l {
    order: 99999;
  }
  .fl-l {
    float: left;
    display: inline;
  }
  .fr-l {
    float: right;
    display: inline;
  }
  .fn-l {
    float: none;
  }
  .i-l {
    font-style: italic;
  }
  .fs-normal-l {
    font-style: normal;
  }
  .normal-l {
    font-weight: normal;
  }
  .b-l {
    font-weight: bold;
  }
  .fw1-l {
    font-weight: 100;
  }
  .fw2-l {
    font-weight: 200;
  }
  .fw3-l {
    font-weight: 300;
  }
  .fw4-l {
    font-weight: 400;
  }
  .fw5-l {
    font-weight: 500;
  }
  .fw6-l {
    font-weight: 600;
  }
  .fw7-l {
    font-weight: 700;
  }
  .fw8-l {
    font-weight: 800;
  }
  .fw9-l {
    font-weight: 900;
  }
  .h1-l {
    height: 1rem;
  }
  .h2-l {
    height: 2rem;
  }
  .h3-l {
    height: 4rem;
  }
  .h4-l {
    height: 8rem;
  }
  .h5-l {
    height: 16rem;
  }
  .h-25-l {
    height: 25%;
  }
  .h-50-l {
    height: 50%;
  }
  .h-75-l {
    height: 75%;
  }
  .h-100-l {
    height: 100%;
  }
  .h-auto-l {
    height: auto;
  }
  .h-inherit-l {
    height: inherit;
  }
  .tracked-l {
    letter-spacing: .16em;
  }
  .tracked-tight-l {
    letter-spacing: -0.05em;
  }
  .tracked-mega-l {
    letter-spacing: .32em;
  }
  .lh-solid-l {
    line-height: 1;
  }
  .lh-title-l {
    line-height: 1.3;
  }
  .lh-copy-l {
    line-height: 1.6;
  }
  .mw-100-l {
    max-width: 100%;
  }
  .mw1-l {
    max-width: 1rem;
  }
  .mw2-l {
    max-width: 2rem;
  }
  .mw3-l {
    max-width: 4rem;
  }
  .mw4-l {
    max-width: 8rem;
  }
  .mw5-l {
    max-width: 16rem;
  }
  .mw6-l {
    max-width: 32rem;
  }
  .mw7-l {
    max-width: 48rem;
  }
  .mw8-l {
    max-width: 64rem;
  }
  .mw9-l {
    max-width: 96rem;
  }
  .mw-none-l {
    max-width: none;
  }
  .w1-l {
    width: 1rem;
  }
  .w2-l {
    width: 2rem;
  }
  .w3-l {
    width: 4rem;
  }
  .w4-l {
    width: 8rem;
  }
  .w5-l {
    width: 16rem;
  }
  .w-10-l {
    width: 10%;
  }
  .w-20-l {
    width: 20%;
  }
  .w-25-l {
    width: 25%;
  }
  .w-33-l {
    width: 33%;
  }
  .w-34-l {
    width: 34%;
  }
  .w-40-l {
    width: 40%;
  }
  .w-50-l {
    width: 50%;
  }
  .w-60-l {
    width: 60%;
  }
  .w-75-l {
    width: 75%;
  }
  .w-80-l {
    width: 80%;
  }
  .w-100-l {
    width: 100%;
  }
  .w-auto-l {
    width: auto;
  }
  .overflow-visible-l {
    overflow: visible;
  }
  .overflow-hidden-l {
    overflow: hidden;
  }
  .overflow-scroll-l {
    overflow: scroll;
  }
  .overflow-auto-l {
    overflow: auto;
  }
  .overflow-x-visible-l {
    overflow-x: visible;
  }
  .overflow-x-hidden-l {
    overflow-x: hidden;
  }
  .overflow-x-scroll-l {
    overflow-x: scroll;
  }
  .overflow-x-auto-l {
    overflow-x: auto;
  }
  .overflow-y-visible-l {
    overflow-y: visible;
  }
  .overflow-y-hidden-l {
    overflow-y: hidden;
  }
  .overflow-y-scroll-l {
    overflow-y: scroll;
  }
  .overflow-y-auto-l {
    overflow-y: auto;
  }
  .static-l {
    position: static;
  }
  .relative-l {
    position: relative;
  }
  .absolute-l {
    position: absolute;
  }
  .fixed-l {
    position: fixed;
  }
  .pa0-l {
    padding: 0;
  }
  .pa1-l {
    padding: .25rem;
  }
  .pa2-l {
    padding: .5rem;
  }
  .pa3-l {
    padding: 1rem;
  }
  .pa4-l {
    padding: 2rem;
  }
  .pa5-l {
    padding: 4rem;
  }
  .pa6-l {
    padding: 8rem;
  }
  .pa7-l {
    padding: 16rem;
  }
  .pl0-l {
    padding-left: 0;
  }
  .pl1-l {
    padding-left: .25rem;
  }
  .pl2-l {
    padding-left: .5rem;
  }
  .pl3-l {
    padding-left: 1rem;
  }
  .pl4-l {
    padding-left: 2rem;
  }
  .pl5-l {
    padding-left: 4rem;
  }
  .pl6-l {
    padding-left: 8rem;
  }
  .pl7-l {
    padding-left: 16rem;
  }
  .pr0-l {
    padding-right: 0;
  }
  .pr1-l {
    padding-right: .25rem;
  }
  .pr2-l {
    padding-right: .5rem;
  }
  .pr3-l {
    padding-right: 1rem;
  }
  .pr4-l {
    padding-right: 2rem;
  }
  .pr5-l {
    padding-right: 4rem;
  }
  .pr6-l {
    padding-right: 8rem;
  }
  .pr7-l {
    padding-right: 16rem;
  }
  .pb0-l {
    padding-bottom: 0;
  }
  .pb1-l {
    padding-bottom: .25rem;
  }
  .pb2-l {
    padding-bottom: .5rem;
  }
  .pb3-l {
    padding-bottom: 1rem;
  }
  .pb4-l {
    padding-bottom: 2rem;
  }
  .pb5-l {
    padding-bottom: 4rem;
  }
  .pb6-l {
    padding-bottom: 8rem;
  }
  .pb7-l {
    padding-bottom: 16rem;
  }
  .pt0-l {
    padding-top: 0;
  }
  .pt1-l {
    padding-top: .25rem;
  }
  .pt2-l {
    padding-top: .5rem;
  }
  .pt3-l {
    padding-top: 1rem;
  }
  .pt4-l {
    padding-top: 2rem;
  }
  .pt5-l {
    padding-top: 4rem;
  }
  .pt6-l {
    padding-top: 8rem;
  }
  .pt7-l {
    padding-top: 16rem;
  }
  .pv0-l {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pv1-l {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
  .pv2-l {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .pv3-l {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .pv4-l {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .pv5-l {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .pv6-l {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .pv7-l {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .ph0-l {
    padding-left: 0;
    padding-right: 0;
  }
  .ph1-l {
    padding-left: .25rem;
    padding-right: .25rem;
  }
  .ph2-l {
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .ph3-l {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ph4-l {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .ph5-l {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .ph6-l {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .ph7-l {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .ma0-l {
    margin: 0;
  }
  .ma1-l {
    margin: .25rem;
  }
  .ma2-l {
    margin: .5rem;
  }
  .ma3-l {
    margin: 1rem;
  }
  .ma4-l {
    margin: 2rem;
  }
  .ma5-l {
    margin: 4rem;
  }
  .ma6-l {
    margin: 8rem;
  }
  .ma7-l {
    margin: 16rem;
  }
  .ml0-l {
    margin-left: 0;
  }
  .ml1-l {
    margin-left: .25rem;
  }
  .ml2-l {
    margin-left: .5rem;
  }
  .ml3-l {
    margin-left: 1rem;
  }
  .ml4-l {
    margin-left: 2rem;
  }
  .ml5-l {
    margin-left: 4rem;
  }
  .ml6-l {
    margin-left: 8rem;
  }
  .ml7-l {
    margin-left: 16rem;
  }
  .mr0-l {
    margin-right: 0;
  }
  .mr1-l {
    margin-right: .25rem;
  }
  .mr2-l {
    margin-right: .5rem;
  }
  .mr3-l {
    margin-right: 1rem;
  }
  .mr4-l {
    margin-right: 2rem;
  }
  .mr5-l {
    margin-right: 4rem;
  }
  .mr6-l {
    margin-right: 8rem;
  }
  .mr7-l {
    margin-right: 16rem;
  }
  .mb0-l {
    margin-bottom: 0;
  }
  .mb1-l {
    margin-bottom: .25rem;
  }
  .mb2-l {
    margin-bottom: .5rem;
  }
  .mb3-l {
    margin-bottom: 1rem;
  }
  .mb4-l {
    margin-bottom: 2rem;
  }
  .mb5-l {
    margin-bottom: 4rem;
  }
  .mb6-l {
    margin-bottom: 8rem;
  }
  .mb7-l {
    margin-bottom: 16rem;
  }
  .mt0-l {
    margin-top: 0;
  }
  .mt1-l {
    margin-top: .25rem;
  }
  .mt2-l {
    margin-top: .5rem;
  }
  .mt3-l {
    margin-top: 1rem;
  }
  .mt4-l {
    margin-top: 2rem;
  }
  .mt5-l {
    margin-top: 4rem;
  }
  .mt6-l {
    margin-top: 8rem;
  }
  .mt7-l {
    margin-top: 16rem;
  }
  .mv0-l {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mv1-l {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
  .mv2-l {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
  .mv3-l {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mv4-l {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mv5-l {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .mv6-l {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .mv7-l {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .mh0-l {
    margin-left: 0;
    margin-right: 0;
  }
  .mh1-l {
    margin-left: .25rem;
    margin-right: .25rem;
  }
  .mh2-l {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .mh3-l {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mh4-l {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mh5-l {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mh6-l {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mh7-l {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .strike-l {
    text-decoration: line-through;
  }
  .underline-l {
    text-decoration: underline;
  }
  .no-underline-l {
    text-decoration: none;
  }
  .tl-l {
    text-align: left;
  }
  .tr-l {
    text-align: right;
  }
  .tc-l {
    text-align: center;
  }
  .ttc-l {
    text-transform: capitalize;
  }
  .ttl-l {
    text-transform: lowercase;
  }
  .ttu-l {
    text-transform: uppercase;
  }
  .ttn-l {
    text-transform: none;
  }
  .f-6-l,
  .f-headline-l {
    font-size: 6rem;
  }
  .f-5-l,
  .f-subheadline-l {
    font-size: 5rem;
  }
  .f1-l {
    font-size: 3rem;
  }
  .f2-l {
    font-size: 2.25rem;
  }
  .f3-l {
    font-size: 1.5rem;
  }
  .f4-l {
    font-size: 1.25rem;
  }
  .f5-l {
    font-size: 1rem;
  }
  .f6-l {
    font-size: .875rem;
  }
  .measure-l {
    max-width: 30em;
  }
  .measure-wide-l {
    max-width: 34em;
  }
  .measure-narrow-l {
    max-width: 20em;
  }
  .indent-l {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-caps-l {
    font-variant: small-caps;
  }
  .truncate-l {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .clip-l {
    position: fixed !important;
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */

    clip: rect(1px, 1px, 1px, 1px);
  }
  .ws-normal-l {
    white-space: normal;
  }
  .nowrap-l {
    white-space: nowrap;
  }
  .pre-l {
    white-space: pre;
  }
  .v-base-l {
    vertical-align: baseline;
  }
  .v-mid-l {
    vertical-align: middle;
  }
  .v-top-l {
    vertical-align: top;
  }
  .v-btm-l {
    vertical-align: bottom;
  }
}
/*
  INPUTS
*/
input[type="checkbox"].ma0,
input[type="radio"].ma0 {
  margin: 0px;
}
input[type="checkbox"].mr2,
input[type="radio"].mr2 {
  margin-right: .5rem;
}











.dashboard-container {
    position: absolute;
    top: 45px !important;
    left: 30px;
    right: 0px !important;
    bottom: 0px;
    overflow: auto;
    box-sizing: border-box;
    height: auto !important;
}
.dashboard-container .dashboard-inner {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    padding: 20px 20px 00px;
}
.dashboard-container .dashboard-inner .gridster {
    margin-bottom: 60px;
}
.gridster-preview-holder {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.15);
}

.widget.reporting-widget {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: move;
    overflow: hidden;
}
.widget.reporting-widget.disable-hover {
    pointer-events: none;
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-s {
    left: 50%;
    width: 40px;
    bottom: 0px;
    margin-left: -20px;
    height: 15px;
    -webkit-transform: translate(0px, 10px);
    -moz-transform: translate(0px, 10px);
    -ms-transform: translate(0px, 10px);
    -o-transform: translate(0px, 10px);
    transform: translate(0px, 10px);
    -webkit-transition: transform 0.12s ease-out;
    -moz-transition: transform 0.12s ease-out;
    -o-transition: transform 0.12s ease-out;
    transition: transform 0.12s ease-out;
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-s:after {
    position: absolute;
    content: ' ';
    top: 12px;
    height: 3px;
    width: 20px;
    left: 10px;
    background: rgba(0, 0, 0, 0.6);
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-e {
    right: 0px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
    width: 15px;
    -webkit-transform: translate(10px, 0px);
    -moz-transform: translate(10px, 0px);
    -ms-transform: translate(10px, 0px);
    -o-transform: translate(10px, 0px);
    transform: translate(10px, 0px);
    -webkit-transition: transform 0.12s ease-out;
    -moz-transition: transform 0.12s ease-out;
    -o-transition: transform 0.12s ease-out;
    transition: transform 0.12s ease-out;
}
.widget.reporting-widget .gridster-item-resizable-handler.handle-e:after {
    position: absolute;
    content: ' ';
    right: 0px;
    width: 3px;
    height: 20px;
    top: 10px;
    background: rgba(0, 0, 0, 0.6);
}
.widget.reporting-widget.without-s-resize .gridster-item-resizable-handler.handle-s {
    display: none;
}
.widget.reporting-widget.without-e-resize .gridster-item-resizable-handler.handle-e {
    display: none;
}
.widget.reporting-widget:hover .gridster-item-resizable-handler.handle-s,
.widget.reporting-widget:hover .gridster-item-resizable-handler.handle-e {
    -webkit-transform: translate(0px, 0px);
    -moz-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    -o-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
}
.widget.reporting-widget .widget-inner {
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.widget.reporting-widget .widget-inner .loaded-wrapper {
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.widget.reporting-widget .widget-inner .title {
    flex: 0 0 auto;
}
.widget.reporting-widget .widget-inner .content-wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.widget.reporting-widget .widget-inner .content-wrapper .flex-item {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.widget.reporting-widget .widget-inner .content-wrapper .content {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.widget.reporting-widget .widget-inner .content-wrapper .content.with-expanded-detail {
    flex: 0 0 auto;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item {
    position: relative;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item .graph-widget {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item .graph-widget .graph-inner {
    height: 100%;
    padding: 15px 15px 0px 0px;
    box-sizing: border-box;
}
.widget.reporting-widget .widget-inner .content-wrapper .content .flex-item .graph-widget .graph-inner .graph {
    height: 100%;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content {
    margin: 0 15px 15px 35px;
    box-sizing: border-box;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner {
    width: 100%;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail {
    margin: 30px 0px;
    width: 100%;
    color: #64767c;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h2,
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h3,
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h4 {
    margin: 0 10px 0 0;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h2 {
    font-size: 40px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h3 {
    font-size: 20px;
    margin-bottom: -5px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail h4 {
    font-size: 18px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .numbers {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    align-items: center;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change {
    cursor: help;
    margin-left: 10px;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change.good {
    color: #60a369;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change.bad {
    color: #d25656;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change .icon {
    font-size: 0.7em;
}
.widget.reporting-widget .widget-inner .content-wrapper .extra-content .extra-content-inner .content-detail .percentage-change p {
    margin-bottom: 0px;
    font-size: 14px;
}
.widget.reporting-widget .widget-inner .content-wrapper .show-large-width {
    display: none;
}
.widget.reporting-widget .widget-inner .content-wrapper .show-extra-large-width {
    display: none;
}
.widget.reporting-widget .widget-inner .content-wrapper .show-large-height {
    display: none;
    background: blue;
}
.widget.reporting-widget .widget-inner .content-wrapper.x-2 .show-large-width,
.widget.reporting-widget .widget-inner .content-wrapper.x-3 .show-large-width {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
}
.widget.reporting-widget .widget-inner .content-wrapper.x-3 .show-extra-large-width {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
}
.widget.reporting-widget .widget-inner .content-wrapper.y-2 .show-large-height {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
}
.dashboard-modal .widgets-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.dashboard-modal .widgets-container .widget-wrapper {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
}
.dashboard-modal .widgets-container .widget-wrapper .widget {
    margin: 1px;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.dashboard-modal .widgets-container .widget-wrapper .widget.highlight {
    -webkit-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
    -moz-box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
    box-shadow: 0 0 6px rgba(96, 80, 166, 0.9);
    border: 2px solid rgba(96, 80, 166, 0.5);
    margin: 0px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .title input {
    margin-top: -6px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content {
    padding: 10px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .left-side {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    align-items: center;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side {
    flex: 2;
    padding: 0px 10px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side .two-column {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    margin-top: 10px;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side .two-column .column {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.dashboard-modal .widgets-container .widget-wrapper .widget .content .right-side .two-column .column {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.change-dashboard-date .pretty-list {
    overflow: auto;
    height: 400px;
}
.cs-highcharts-tooltip {
    padding: 5px 10px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.27);
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.06) 3px -3px 3px);
}
.cs-highcharts-tooltip .value {
    display: block;
    margin-top: 5px;
}
.left-right-controls .icon {
    font-size: 9px !important;
    width: auto !important;
    height: auto;
    background: #f0f0f0;
    color: rgba(143, 146, 150, 0.9) !important;
    border: 1px solid #dadada;
    border-bottom-width: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 6px 8px 3px 7px;
    line-height: 13px;
    float: right !important;
    margin: 7px 0px 0px 5px !important;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}
.left-right-controls .icon:hover {
    color: rgba(138, 141, 145, 0.9);
    background: #ebebeb;
}
.left-right-controls .quick-links-btn {
    background: transparent !important;
    border: 0px !important;
}
.left-right-controls .quick-links-btn .caret {
    margin-left: 2px;
}
.left-right-controls .quick-links-wrap.open .quick-links-btn {
    background: transparent !important;
}
.report-leftnav-first-tire{
    top:120px;
}
.content-placeholder {
    width: 80px;
    height: 18px;
    background-color: #e0e2e1;
}
.content-placeholder.content-placeholder-medium {
    width: 220px;
}
@media print {
    .top_bar_wrapper {
        display: none;
    }
    .search-with-tabs {
        display: none;
    }
    .invoice-row {
        display: none;
    }
    .invoice-row.selected-to-print {
        display: table-row;
    }
}
.integrations-modal textarea {
    width: 100%;
}
.invoice-tooltip-content span{
    text-align: left;
    float: left;
    clear: both;
}

.customer-history-search.app-filter.search-with-tabs .filter-block a.tab .status-circle {
    overflow: hidden;
    top: 0px;
}

.filter-block .checkbox-container .faux-checkbox {
    margin-top: 12px;
}

.naturalforms-generated-url {
    word-wrap: break-word;
    margin: 5px 0px 5px 0px;
}

.naturalforms-text-box {
    margin: 5px 0px;
}

#naturalforms-copyable {
    position: absolute;
    z-index: -1;
    width: 0px;
    height: 0px;
}

#integrations-copyable {
    position: absolute;
    z-index: -1;
    width: 0px;
    height: 0px;
}
.no-top-margin {
    margin: 0 0 10px 0;
}

.no-bottom-margin {
    margin: 10px 0 0 0;
}

.with-top-margin {
    margin-top: 10px;
}

.with-bottom-margin {
    margin-bottom: 10px;
}

.label-popping {
  transition: transform .3s;
  will-change: transform;
  animation: bounce .3s;
}

@keyframes bounce{
  0%{
    transform: scale(0);
  }
  75%{
    transform: scale(1.3);
  }
  100%{
    transform: scale(1)
  }
}

.filter-block .checkbox-container .faux-checkbox {
  margin-top: 12px;
}
.item-inner.no-after{
    margin-left: 23px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.customer-history-search.app-filter.search-with-tabs .filter-block a.tab .status-circle {
  overflow: hidden;
  top: 0px;
}

.html-stats-grid-container {
  text-align: center;
}

.html-stats-box {
  background-color: #fff;
  height: auto;
  width: 33.33%;
  width: calc(100%/3);
  padding-top: 15px;
  padding-bottom: 15px;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #ddd;
}

.html-stats-box .placeholder {
    font-size: 93px;
    line-height: 1.11;
    visibility: hidden;
}

.html-stats-box .clickable {
  color: #3276ad;
  cursor: pointer;
}

.html-stats-box .clickable:hover {
  color: #265882;
}

.html-stats-box.full-width {
  width: 100%;
}

.html-stats-box.half-width {
  width: 50%;
}

.html-stats-box-heading {
  font-size: 15px;
  margin-bottom: 6px;
  color: #979797;
}

.html-stats-box-content {
    position: relative;
}

.html-stats-box-content .text {
    font-size: 95px;
    position: absolute;
    white-space: nowrap;
    top: 42px;
    left: 0px;
    right: 0px;
}


.html-stats-box-content .text.font-size-62 {
    font-size: 62px !important;
    white-space: normal;
    vertical-align: top;
    line-height: 0.4;
}

.html-stats-box-content .text.font-size-42 {
    font-size: 42px !important;
    white-space: normal;
    vertical-align: top;
    line-height: 0.6;
}

.html-stats-box-content .text.font-size-32 {
    font-size: 32px !important;
    white-space: normal;
    vertical-align: top;
    line-height: 0.9;
}

.html-stats-box-content .text.font-size-22 {
    font-size: 22px !important;
    white-space: normal;
    vertical-align: top;
    line-height: 0.9;
}

.html-stats-box-content .text.font-size-15 {
    font-size: 15px !important;
    white-space: normal;
    vertical-align: top;
    line-height: 1.4;
}

.html-widget-inner,
.html-stats-grid-outer,
.html-widget,
.html-stats-grid-container {
  width: 100%;
}

.cspagingoption-container {
  margin: 0px 9px;
  float: right;
}

.reporting-operational-table .faux-checkbox {
  margin: 0px 5px 0px 0px;
}

.reporting-operational-table .operational-column-labels {
  background: #f6f4f4 !important;
}

.reporting-operational-table .operational-header {
  background: #6393b5 !important;
  color: #fff !important;
}

.reporting-operational-table .operational-data-row {
  background: #fff !important;
}

.reporting-operational-table .faux-checkbox {
  background-color: #fff;
}

.item-inner.no-after{
    margin-left: 23px;
}

.item-inner.no-after:after{
    display: none !important;
}
.item-inner.no-after:before{
    display: none !important;
}

.tooltip-left-px .tooltip.top{
    transform: translateX(20px)
}

.tooltip-left-px .tooltip-arrow{
    transform: translateX(-20px)
}

.first-top-padding:first-of-type{
    padding-top: 15px;
}

.navigable-file-row {
    cursor: pointer;
}

.mass_send_statement{
    margin: 6px 0 0 0 !important;
}

/*
.overlay-text.upload-prompt p.upload-icon {
    font-size: 81px;
    margin-bottom: 35px;
    margin-top: 25px;
}

.docparser .overlay-text.upload-prompt p.upload-icon {
  margin-bottom: 25px;
}

.file-icon {
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
  font-size: 50px;
  transition:   top 0.3s ease-in-out,
		margin-left 0.3s ease-in-out,
		margin-right 0.3s ease-in-out;
}

.file-icon-container {
  height: 80px;
  padding-top: 14px;
}

.file-icon-left {
  transform: rotate(-20deg);
  top: 11px;
  margin-right: 8px;
}

.file-icon-middle {
  top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.file-icon-right {
  transform: rotate(20deg);
  top: 11px;
  margin-left: 5px;
}

.upload-prompt-activated .file-icon-left {
  top: 4px;
  margin-right: 16px;
}

.upload-prompt-activated .file-icon-middle {
  top: -11px;
  margin-left: 8px;
}
*/
/*
.upload-prompt-activated .file-icon-right {
  top: 4px;
  margin-left: 16px;
}

#upload-prompt-overlay {
  z-index: -1;
}

.dragover-enabled.visible-on-screen #upload-prompt-overlay {
  z-index: 1;
}

.upload-active {
  opacity: 0;
  height: 0px;
  transition: opacity 0.6s ease-out;
}

.showing-upload-bar .upload-active {
  opacity: 1;
  height: auto;
  transition: opacity 0.6s ease-out;
}

.processing-upload-in-background-container {
  opacity: 0;
  height: 0px;
  transition: opacity 0.6s ease-out;
}

.processing-upload-in-background-container .upload-progress-container {
  min-height: 197px;
}

.processing-upload-in-background .processing-upload-in-background-container {
  opacity: 1;
  height: auto;
}

form.contract_wrapper {
  margin: 35px;
}

.contract-push-left-top {
  margin-top: 20px !important;
  margin-left: 0px !important;
}

.file-preview {
  position: absolute;
  right: 40px;
  left: 0px;
  width: auto;
  height: 100%;
  top: 0px;
  bottom: 0px;
}
*/
.video-file-preview video {
  width: 100%;
}

#page-panel-main .flex-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dismiss-upload-btn {
    margin-top: 20px;
}

.estimate_price_links_table {
    display: table;
    float: right;
}

.section-title .estimate_price_links_table .btn {
    padding: 4px 8px;
}

.section-title .estimate_price_links_table > a {
    display: table-cell;
    float: none;
    padding: 0px 5px;
}

.section-title.with-inline-helper .estimate_price_links_table span {
    margin: 0px;
}
/*
.section-title .estimate_price_links_table > a:first-child {
    padding-left: 0px;
}

.section-title .estimate_price_links_table > a:last-child {
    padding-right: 0px;
}
*/

.automatic_post_sales_communication select.input-customer-type{
    width: 185px !important;
}
.automatic_post_sales_communication .select2-container .select2-choice{
    margin-left: 3px;
    width: 240px;
    height: 24px;
    padding-top: 0 !important;
}
.automatic_post_sales_communication.customers_notification_preferences .filter_links {
    padding-left: 10px;
}
.automatic_post_sales_communication .filter_links {
    padding-left:12px;
}
.automatic_post_sales_communication .filter_links.clear-filter {
    padding-right:12px;
    line-height: 26px;
}

.css-table {
    display: table !important;
}

.table-cell {
    display: table-cell !important;
}

.table-row {
    display: table-row !important;
}

.invalid-field {
    border-color: #e9322d !important;
    box-shadow: 0px 0px 6px #f8b9b7 !important;
    -webkit-box-shadow: 0px 0px 6px #f8b9b7 !important;
    -moz-box-shadow: 0px 0px 6px #f8b9b7 !important;
}

.invalid-field-error {
    color: #b94a48 !important;
    padding-top: 5px;
}

.available-part-status-sidepanel {
    vertical-align: top;
}

.loaded-img-invisible {
    opacity: 0;
    height: 0px;
}

.loaded-img-wrapper .spinner-wrapper {
    margin-left: 15px;
    margin-top: 25px;
    color: #ccc;
    font-size: 120px;
}

.direct-debit-mandate-fields {
    width: 50%;
}

.direct-debit-mandate-fields .table-cell {
    padding: 10px;
}
ul.uib-datepicker-popup:before, ul.uib-datepicker-popup:after {
    content: none;
}
ul.uib-datepicker-popup {
    display: block;
    width: 270px;
    border: none;
}
ul.uib-datepicker-popup li {
    padding: 0;
    background: transparent;
}
ul.uib-datepicker-popup td a {
    padding: 0;
}
#configure-job-parts.mass-update .options-container .stock-location-option .control-group.fulfill-from-stock .control-label {
    width: auto;
}

.formbuilder-datepicker .page-datepicker .custom-datepicker {
    left: 0px;
}

/*stock returns operational panels*/
.stock-panel.stock-returns-panel {}
.stock-returns-panel .pretty-list.pretty-list.without-absolute.below-filter {height: auto;}
#side-panel .stock-returns-panel #page-panel-main {
    padding-bottom:0;
}
.parts_operational_fulfill_without_stock #configure-job-parts .options-container .stock-location-option .control-group .control-label {
    vertical-align: top;
    top:0;
    padding-top:8px;
}
.parts_operational_fulfill_without_stock .control-error-message {
    margin-top: -5px;
}

.vat-div{
    text-align: right;
    margin-top: 10px !important;
}

.vat-Helptext{
    margin-top: -10px;
    margin-bottom: 12px;
}

.vat-Helptext-nobreakdown {
    margin-top: -5px;
    margin-bottom: 12px;
}
.add-tax-rate-spreadsheet {
    display: block; overflow: hidden;
}
.with-select2 .select2-container {
    margin-top: 8px;
}

/*free of charge modal box*/
.free-of-charge-wrapper .cs-event-header{
    position: unset !important;
    height: auto !important;
    min-height: 0px !important;
}
.free-of-charge-wrapper .free-of-charge-container{
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    display: inline-block;
    margin-left: 10px;
}
.final-invoice-radio{
    margin: 3px 5px 0 5px !important;
}
.free-of-charge-radio{
    position: absolute;
    top: 6px;
}
.free-of-charge-diary-status{
    top: 3px;
    left: 0px;
}

.labour-rate-container .datepicker-append  {
    width: 130px;
}

.labour-rate-container .datepicker-append > input  {
    width: 100px;
}
.labour-rate-container .timepicker-wrap {
    display:inline-block; vertical-align: middle; margin-left: 8px;
}
.labour-rate-container .add-on.ss-calendar {
    top:0;
}

.add_record .add-button {
    margin: 10px 0 0 192px !important;
    padding: 4px 17px;
}

.add_record .add-button.loading {
    padding-left: 32px;
}
.notification-icon{
    padding-right: 11px;
    padding-top: 3px;
    margin-left: -4px;
}
.notification-icon.new-screen-breamer-icon{
    margin: 0 !important;
    padding: 3px 0px 0px 0px !important;
}

.service_left{
    left : 140px !important;
}
/*diary Opportunity event notes words break*/
.event-notes-words-break{
    word-break: break-word;
}

/*prepery work address alignment*/

.property-customer-work-address{
    max-width: 325px;
}

.activity-icon{
    font-size: 20px;
    float: left;
    margin: 2px 0px 0 0;
    margin-left: 8px;
    color: #304f65;
}
.options a.activity-icon.white{
    color:#fff !important;
}
.options a.activity-icon.white:hover {
    color: #fff !important;
}
.accept-reject-opportunities-display-flex{
    display: flex;
    justify-content: space-between;
}
.opportunities-job-type,.opportunities-reject-type{
    margin-left: 10px;
}
.diary-event-title-alignment{
    margin-left: 40px;
}
.help-text-border{
    border-bottom: 1px solid #dadada !important;
}
.label-padding{
    display:flex;
}
.variation-padding{
   margin-left:auto !important;
}
.event-description-more-content {
    word-break: break-word;
}
.beamer_icon{
    top: -7px !important;
    right: 0px !important;
    background-color: #F03D25 !important;
}

.beamer-notifications > .notification-icon {
    opacity: 0.5;
}

.beamer-notifications:has(.active) > .notification-icon {
    opacity: 1 !important;
}
.opportunity-job-status{
    margin-left:52px;
}
.modal-footer-border{
    border-top: 1px solid #CDCDCD;
}
.branch-search-padding{
    margin-left: -9px !important;
}
/*sales logout dropdown width added*/
.app-container .added-schedule-activity{
    width: auto !important;
}
.top-right-menu-list .user-wrapper.open .small-menu{
    left : -172px !important;
}
.remove-questions-heading{
    margin-top:15px !important;
}
.send-to-customer-height {
    #side-panel .mce-tinymce .mce-edit-area {
        height: max-content !important;
    }
}
.edit-licence-column-left{
    float: left !important;
    width: 412px !important;
}

.title-border{
    border-top: 1px solid #dadada !important;
}

.branch-detail{
    margin: 5px 0px 0px !important;
}

.branch-row{
    display: flex;
}
.branch-column-title{
    width: 35%;
}
.branch-column-value{
    width: 65%;
}
.input-select{
    min-width: 155px;
}
.allocate-to-invoice-container{
    width: 91%;
}
.help-text-section{
    background: #ffffff;
    border-bottom: 1px solid #dadada;
    padding: 10px 10px 0px 21px !important;
    color: #60a369;
}
.engineer-list-container{
    padding: 5px 20px 0px 20px !important;
}
.user-img{
    width: 16px;
    height: 16px;
    border-radius: 23px;
    margin-right: 3px;
}
.select2-result-label img{
    width: 24px;
    height: 24px;
    border-radius: 23px;
    margin-right: 3px;
}
.round-initial
{
    background: #F2994A;
    margin-right: 4px;
    border-radius: 25px;
    font-size: 10px;
    padding: 2px 2px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}
    .select2-result-label .round-initial{
        background: #F2994A;
        margin-right: 5px;
        border-radius: 25px;
        font-size: 12px;
        padding: 5px 5px;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
    }
#message-email{
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}
#email-subject{
    overflow: hidden;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}
.customPopUpClass {
    width: 700px;
    height: 300px;
}
.second-line-restrict{
  .select2-container-multi .select2-choices li {
        float: unset !important;
        list-style: none;
        display: inline-block !important;
    }
}
.label.label-archived,
.just-added.label-info {
    margin-left: 10px;
    background: #a2a261;
}

.addedCustomerContractList .no-results .no-results-wrap{
    top: 75px !important;
}
.allocate-to-invoice-container{
    width: 91%;
}
.help-text-section{
    background: #ffffff;
    border-bottom: 1px solid #dadada;
    padding: 10px 10px 0px 21px !important;
    color: #60a369;
}
.engineer-list-container{
    padding: 5px 20px 0px 20px !important;
}
.center-both {
    position: absolute;
    left: 56%;
    transform: translate(-45%, -50%);
}
#confirmation-box{
    pointer-events: auto;
}
body.mobile-view{
    .modal, .full-screen-widget, #smart-panel-overlay, #update-column-panel, #confirmation-box, .modal-backdrop {
        display:none !important;
    }
}
.finance-reject{
    margin-left: 10px;
}
.question-status-info {
    padding: 2px ;
    cursor: text ;
    background-color: #A1A360 !important;
}
.cancel-status-info {
    padding: 2px ;
    cursor: text ;
    background-color: #bf6d6d !important;
}
#confirmation-box{
    pointer-events: auto;
}
body.mobile-view{
    .modal, .full-screen-widget, #smart-panel-overlay, #update-column-panel, #confirmation-box, .modal-backdrop {
        display:none !important;
    }
}
.pipeline-color-square{
    display: block;
    width:16px;
    height:16px;
    border-radius:2px;
}

.profile-container{
    margin-right: 10px;
    max-width: max-content;

    img.avatar-image {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin-left: -5px;
        max-width: initial;
    }

    span {
        white-space: nowrap;
        margin: 0;
    }
}


.profile-container + .profile-container.zero-margin {
    margin-left: 0;
}

.stage-data-view {
    width: fit-content;
    height: 22px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    border-radius: 2px;
    align-content: center;
    color: white;
    padding: 0 4px 0 4px;
    margin: 0;
}
.smart-table-container table .stage-badge {
    border-radius: 2px;
    color: #fff;
    padding: 4px;
    font-weight: 400;
    white-space: nowrap;
    line-height: 15px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.sla-pause-color {
    background-color: #A2A360;
}

.confirm-tick-icon {
    border: none !important;
    background: none !important;
    color: #3883C1 !important;
    margin-bottom: 10px !important;
    font-size: x-large !important;
}

.confirm-cancel-icon {
    border: none !important;
    background: none !important;
    margin-bottom: 10px !important;
    color: #8E8E8E !important;
    font-size: large !important;
}

.pipeline-container-align {
    display: contents;
}

.quicklink-edit {
    margin-left: 5px !important;
    color: #3883c1 !important;
    cursor: pointer;
}

#pipeValueSelector, #pipeSaveButton, #pipeCancelButton {
    display: none;
}

.pipeline-selector .select2-search {
    display: none;
}

#s2id_pipeValueSelector {
    display: none;
    margin-left: 20px;
}

#s2id_pipeValueSelector .select2-chosen {
    margin-left: 0px;
    text-overflow: initial;
}
.pipeline-selector {
    margin-top: -6px !important;
}
.notes-update p {
    margin-bottom: 0px !important;
}
.text-field p {
    margin-bottom: 0px !important;
}
.user-notes{
    height: unset !important;
    padding: unset !important;
}

/* ===============================================  smooth scroll bar common css    ================================================= */
.post-code-list,
#page-panel-main .slide {
    outline: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        border: #E1E1E1;
        background-color: #ddd;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #808080;
        border: 1px solid #c9c9c9;
        border-radius: 25px;
    }
}
