.dropzone,
.dropzone * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dropzone {
  /*#position: relative;*/
  border: 1px solid rgba(0,0,0,0.08);
  background: rgba(0,0,0,0.02);
  padding: 1em;
}
.dropzone.clickable {
  cursor: pointer;
}
.dropzone.clickable .message {
  cursor: pointer;
}
.dropzone.clickable * {
  cursor: default;
}
.dropzone .message {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.dropzone.drag-hover {
  border-color: rgba(0,0,0,0.15);
  background: rgba(0,0,0,0.04);
}
.dropzone.started .message {
  display: none;
}
.dropzone .preview {
  background: rgba(255,255,255,0.8);
  position: relative;
  display: inline-block;
  margin: 17px;
  vertical-align: top;
  border: 1px solid #acacac;
  padding: 6px 6px 28px 6px;
}
.dropzone .preview .details {
  width: 100px;
  height: 100px;
  position: relative;
  background: #ebebeb;
  padding: 5px;
}
.dropzone .preview .details .filename {
  overflow: hidden;
  height: 100%;
}
.dropzone .preview .details img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}
.dropzone .preview .details .size {
  position: absolute;
  bottom: -28px;
  left: 3px;
  height: 28px;
  line-height: 28px;
}
.dropzone .preview.error .error-mark {
  display: block;
}
.dropzone .preview.success .success-mark {
  display: block;
}
.dropzone .preview:hover .details img {
  display: none;
}
.dropzone .preview:hover .success-mark,
.dropzone .preview:hover .error-mark {
  display: none;
}
.dropzone .preview .success-mark,
.dropzone .preview .error-mark {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 30px;
  text-align: center;
  right: -10px;
  top: -10px;
}
.dropzone .preview .success-mark {
  color: #8cc657;
}
.dropzone .preview .error-mark {
  color: #ee162d;
}
.dropzone .preview .progress {
  position: absolute;
  top: 100px;
  left: 6px;
  right: 6px;
  height: 6px;
  background: #d7d7d7;
}
.dropzone .preview .progress .upload {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  background-color: #8cc657;
}
.dropzone .preview.success .progress {
  display: none;
}
.dropzone .preview .error-message {
  display: none;
  position: absolute;
  top: -5px;
  left: -20px;
  background: rgba(245,245,245,0.8);
  padding: 8px 10px;
  color: #800;
  min-width: 140px;
  max-width: 500px;
  z-index: 500;
}
.dropzone .preview:hover.error .error-message {
  display: block;
}
.dropzone {
  border: 1px solid rgba(0,0,0,0.03);
  /*#min-height: 360px;*/
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0,0,0,0.03);
  padding: 23px;
}
.dropzone .default.message {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  background-image:url("../images/spritemap.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  position: absolute;
  width: 428px;
  height: 123px;
  margin-left: -214px;
  margin-top: -61.5px;
  top: 50%;
  left: 50%;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  .dropzone .default.message {
    background-image:url("../images/spritemap@2x.png");
    -webkit-background-size: 428px 406px;
    -moz-background-size: 428px 406px;
    background-size: 428px 406px;
  }
}
.dropzone .default.message span {
  display: none;
}
.dropzone.square .default.message {
  background-position: 0 -123px;
  width: 268px;
  margin-left: -134px;
  height: 174px;
  margin-top: -87px;
}
.dropzone.drag-hover .message {
  opacity: 0.15;
  filter: alpha(opacity=15);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
}
.dropzone.started .message {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.dropzone .preview {
  -webkit-box-shadow: 1px 1px 4px rgba(0,0,0,0.16);
  box-shadow: 1px 1px 4px rgba(0,0,0,0.16);
  font-size: 14px;
}
.dropzone .preview .details img {
  width: 100px;
  height: 100px;
}
.dropzone .preview.image-preview:hover .details img {
  display: block;
  opacity: 0.1;
  filter: alpha(opacity=10);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}
.dropzone .preview.success .success-mark {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.dropzone .preview.error .error-mark {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.dropzone .preview.error .progress .upload {
  background: #ee1e2d;
}
.dropzone .preview .error-mark,
.dropzone .preview .success-mark {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  background-image:url("../images/spritemap.png");
  background-repeat: no-repeat;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  .dropzone .preview .error-mark,
  .dropzone .preview .success-mark {
    background-image:url("../images/spritemap@2x.png");
    -webkit-background-size: 428px 406px;
    -moz-background-size: 428px 406px;
    background-size: 428px 406px;
  }
}
.dropzone .preview .error-mark span,
.dropzone .preview .success-mark span {
  display: none;
}
.dropzone .preview .error-mark {
  background-position: -268px -123px;
}
.dropzone .preview .success-mark {
  background-position: -268px -163px;
}
.dropzone .preview .progress .upload {
  -webkit-animation: loading 0.4s linear infinite;
  -moz-animation: loading 0.4s linear infinite;
  -o-animation: loading 0.4s linear infinite;
  -ms-animation: loading 0.4s linear infinite;
  animation: loading 0.4s linear infinite;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-image:url("../images/spritemap.png");
  background-repeat: repeat-x;
  background-position: 0px -400px;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  .dropzone .preview .progress .upload {
    background-image:url("../images/spritemap@2x.png");
    -webkit-background-size: 428px 406px;
    -moz-background-size: 428px 406px;
    background-size: 428px 406px;
  }
}
.dropzone .preview.success .progress {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.dropzone .preview .error-message {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.dropzone .preview:hover.error .error-message {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
@-moz-keyframes loading {
  0% {
    background-position: 0 -400px;
  }

  100% {
    background-position: -7px -400px;
  }
}
@-webkit-keyframes loading {
  0% {
    background-position: 0 -400px;
  }

  100% {
    background-position: -7px -400px;
  }
}
@-o-keyframes loading {
  0% {
    background-position: 0 -400px;
  }

  100% {
    background-position: -7px -400px;
  }
}
@-ms-keyframes loading {
  0% {
    background-position: 0 -400px;
  }

  100% {
    background-position: -7px -400px;
  }
}
@keyframes loading {
  0% {
    background-position: 0 -400px;
  }

  100% {
    background-position: -7px -400px;
  }
}