.diary-in-map-view .fc-left {
    padding-left: 30px;
}

.diary-map-view {
    position: relative;
}

.diary-map-view .map {
    display: inline-block;
    height: 100vh;
    -webkit-transition: width 0.2s; /* Safari */
    transition: width 0.2s;
}

.diary-map-view .map {
    width: 100%;
}

.diary-map-view .user-agenda {
    display: none;
    position: relative;
    float: right;
    margin-top: 45px;
}

.diary-map-view.hybrid .map {
    width: 80%;
}

.diary-map-view.hybrid .user-agenda {
    display: inline-block;
    width: 20%;
}

.diary-map-view.hybrid .user-agenda .close-icon {
    position: absolute;
    top: 50%;
    z-index: 9;
    cursor: pointer;
    width: 5px;
    height: 10px;
    box-shadow: inset 0 -1px 0 1px rgba(0, 0, 0, 0.1), inset 0 -10px 20px rgba(0, 0, 0, 0.2);;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 4px;
    color: grey;
    background-color: #fff;
}

.diary-map-view.hybrid .user-agenda .close-icon > svg {
    top: -3px;
    position: relative;
}

#diary-right-bar.diaryMap-view .helper-for-event-from-previous-page {
    top: 285px;
}

#diary-right-bar.diaryMap-view .helper-for-event-from-previous-page.can-view-vechicles {
    top: 344px;
}

#diary-right-bar.diaryMap-view .diary-map-view-bar-sections.can-view-vechicles.compensate-for-event-from-previous-page {
    top: 475px;
}

.diary-map-view-bar-section .dropdown-menu.events-hidden .links li {
    border-top: none;
    background: none;
    float: left;
    padding: 4px;
}

.leaflet-touch .leaflet-bar, .leaflet-touch .leaflet-control-layers {
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.leaflet-touch .leaflet-bar a {
    font-size: 15px;
    line-height: 25px;
    width: 25px;
    height: 25px;
}

.leaflet-touch .leaflet-bar a > i {
    font-size: 12px;
    width: 100%;
    text-align: center;
    position: relative;
    left: -1px;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
    padding: 7px;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded .leaflet-control-layers-list {
    border-radius: 1px;
    padding: 13px;
    box-shadow: none;
    background-color: #d6d6d645;
    background-image: none;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded .leaflet-control-layers-list label span {
    top: 4px;
    position: relative;
    font-size: 13px;
    margin-left: 5px;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 36px;
    height: 36px;
    background-size: 22px 22px;
}

.popup-right {
    margin-bottom: 0;
}

.popup-right .leaflet-popup-tip-container {
    left: 100%;
    top: 50%;
}

/*User Agenda styles*/
#cs-diarymap-calendar {
    background: #ffffff;
}
#cs-diarymap-calendar .fc-time-grid-container {
    width: 100%;
}

#cs-diarymap-calendar .fc-day-grid {
    background-color: #ffffff;
}

#cs-diarymap-calendar th.fc-widget-header {
    height: 35px;
    vertical-align: middle;
}

#cs-diarymap-calendar th.fc-widget-header.fc-axis {
    width: 0 !important;
}

#cs-diarymap-calendar th.fc-widget-header.fc-day-header {
    text-align: center;
}

#cs-diarymap-calendar td.fc-axis.fc-widget-content span {
    width: 52px;
    display: block;
}

/**************************************
             Slim Scrollbar
***************************************/
#cs-diarymap-calendar .fc-scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

#cs-diarymap-calendar .fc-scroller::-webkit-scrollbar {
    width: 5px;
}

#cs-diarymap-calendar .fc-scroller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

}

/* Right bar options design */
#diary-right-bar .diary-map-view-bar-sections.can-view-vechicles {
    top: 348px;
}

.diary-map-rightbar-controls fieldset > div {
    margin-top: 10px;

}

.diary-map-rightbar-controls .time-inputs {
    margin-left: 20px;
    margin-top: 10px;

}

.diary-map-rightbar-controls input[readonly] {
    cursor: pointer;
    background: white;
}

.diary-map-rightbar-controls input:disabled {
    background-color: #eeeeee;
}

.diary-map-rightbar-controls .span12 label {
    display: inline !important;
    top: 3px;
    position: relative;
}

.diary-map-rightbar-controls input[type="text"] {
    width: 60px !important;
}

.tooltip.historical-time-error-message {
    opacity: 1;
    margin-left: -20px;
}

.tooltip.historical-time-error-message .tooltip-inner {
    background-color: #c75f06;
}

.tooltip.left.historical-time-error-message .tooltip-arrow {
    border-left-color: #c75f06;
}

.diary-bar-section#outstanding-jobs-wrap.full-height {
    -webkit-transform: translate(0, -150px);
    -moz-transform: translate(0, -150px);
    -ms-transform: translate(0, -150px);
    -o-transform: translate(0, -150px);
    transform: translate(0, -150px);
}

/* User filter panel status styles */
.user-gps-status .time {
    background-color: #60a369;
    padding: 5px;
    border-radius: 11px;
    overflow: hidden;
    display: inline;
    color: white;
}

.user-gps-status .time.green {
    background-color: #60a369;
}

.user-gps-status .time.yellow {
    background-color: #a1a360;
}

.user-gps-status .time.red {
    background-color: #a36060;
}

.diary-map-view .info-bar {
    position: absolute;
    top: 10px;
    left: 49px;
    z-index: 999;
    background: white;
    padding: 7px 12px;
    border-radius: 1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    opacity: 0;
    min-width: 360px;
    min-height: 20px;
}

.diary-map-view .info-bar .control-group, .diary-map-view .info-bar .help-block {
    margin: 0;
}

.diary-map-view .info-widget {
    left: auto;
    right: 55px;
    -webkit-transition: right 0.2s;
    transition: right 0.2s;
    font-size: 12px;
}
.diary-map-view .info-widget .flex-column{
    padding-top: 3px;
}
.diary-map-view .info-widget.adjusted-for-user-agenda {
    right: calc(20% + 55px);
}

.diary-map-view .info-widget .ticket-wrapper {
    border: none;
    box-shadow: none;
    width: 365px;
}
.diary-map-view .info-widget .corner-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #d1d0cf;
}
.outstanding-job.ui-draggable-dragging {
    background: white;
}

#info-widget-oj.ui-state-disabled {
    opacity: 1;
}

.outstanding-job.ui-draggable-dragging .hide-on-drag {
    display: none;
}

.diary-map-view .info-widget label {
    font-size: 12px;
    line-height: 15px;
}

.diary-map-view .info-widget button {
    position: absolute;
    right: 10px;
    bottom: 13px;
    height: 2em;
    box-sizing: border-box;
    margin: 0;
    padding: 0 .6em;
    font-size: 1em;
    white-space: nowrap;
    cursor: pointer;
}

.diary-map-view .info-widget .engineer-notes {
    margin-bottom: 10px;
}
.diary-map-view .info-widget .title {
    display: inline-block;
    width: auto;
}

.oj-info-tip {
    display: inline-block;
    top: 7px;
    width: 15px;
    cursor: pointer;
}
.oj-info-tip [class^="ss-"] {
    top: 2px;
    position: relative;
    margin-right: 5px;
    color: #bdc4c4;
}

.oj-info-tip .tooltip-content {
    display: none;
}
.oj-communication-notes {
    padding-top: 10px;
}
.oj-communication-notes ul {
    color: #505b65;
    list-style-type: none;
    width: 300px;
    padding: 0 15px;
    margin: 0;
    font-size: 1em;
}
.oj-communication-notes ul li {
    padding: 10px 0;
}
.oj-communication-notes ul li:not(:first-child){
    border-top: 1px dotted #bec4c9;
}
.oj-communication-notes ul li.no-list {
    padding: 0;
    border: none;
    text-align: center;
    color: grey;
}
.oj-communication-notes ul li.no-list .warning-icon-wrap {
    width: 50px;
    height: 50px;
}
.oj-communication-notes  .warning-icon-wrap.with-info-icon .icon.fix-left {
    margin: -2px 0 0 5px !important;
}
.diary-map-view .control-group .dropdown {
    display: inline;
}

.diary-map-view .control-group .dropdown:hover {
    text-decoration: underline;
}

.diary-map-view .control-group .dropdown > a {
    color: inherit;
}

.diary-map-view .quick-links > li:not(:first-child) {
    float: inherit;
    background: transparent;
    padding: 0;
}

.diary-map-view .quick-links .links {
    max-height: 60vh;
    overflow-y: auto;
    margin: 0;
    list-style-type: none;
    padding: 10px;
}

.diary-map-view .quick-links .links > li {
    float: left;
    border: none;
    background: transparent;
    display: block;
    padding: 0;
}

.diary-map-view .quick-links .links > li:not(:first-child) {
    padding-top: 10px;
}

.diary-map-view .quick-links .links .ticket-wrapper {
    width: 100%;
}

.diary-map-view .quick-links .links .ticket-header a {
    padding: 0;
}

.diary-map-view-content {
    padding: 0 !important;
    margin: 0 !important;
}

.diary-in-map-view {
    margin-top: 0 !important;
    background: transparent !important;
}

.diary-in-map-view .fc-view-container {
    margin-top: -19px !important;
}

.leaflet-div-icon.transparent {
    background: transparent;
    border: none;
}

/*********************************
        Marker styles
 *********************************/

/*User marker styles*/
.cs-marker-user {
    background-color: #3A6889;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 3px;
    cursor: -webkit-grab;
    cursor: grab;
}

.cs-marker-user:after {
    content: '';
    width: 0;
    height: 0;
    z-index: -1;
    position: absolute;
    top: 47px;
    left: 8px;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top: 24px solid #3a6789;
}

.cs-marker-user .shadow-img {
    position: absolute;
    top: 22px;
    left: 12px;
    z-index: -2;
    width: 50px;
    height: 50px;
}

.cs-marker-user .marker-body {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-color: transparent;
    text-align: center;
    color: white;
    cursor: pointer;
}

.cs-marker-user .marker-body .icon {
    display: block;
    font-size: 27px;
    padding: 8px;
}

.cs-marker-user .marker-body .icon.no-avatar {
    font-size: 30px;
    padding: 5px;
    color: #505b65;
    background-color: #f7f7f7;
}

.cs-marker-user .eta {
    position: absolute;
    top: -30px;
    left: -20px;
    width: 90px;
    height: 20px;
    border: 2px solid #3a6789;
    color: #3a6789;
    background-color: white;
    border-radius: 6px;
    padding: 0;
    line-height: 1em;
    text-align: left;
    font-size: 12px;
}

.cs-marker-user .eta > div {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    padding: 5px 5px;
    top: -1px;
}

.cs-marker-user .eta .lbl {
    color: white;
    background-color: #3a6789;
    font-weight: bold;
}

.cs-marker-user .clock {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    color: #60a369;
    font-size: 15px;
}

.cs-marker-user .clock::after {
    content: ' ';
    background: white;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: -1;
    margin: 1px;
}

.cs-marker-user .clock.green {
    color: #60a369;
}

.cs-marker-user .clock.red {
    color: #a36060;
}

/*User marker popup styles*/
.user-marker-popup {
    width: 278px;
    height: auto;
    border-radius: 4px;
    font-weight: bold;
    padding: 15px;
    color: #4e4e4e;
    position: fixed;
    top: 0;
    background: white;
    opacity: 0;
    z-index: -1;
    box-shadow: 0 0 20px #706c6c;
    border: 1px solid #c6c5c4;
}

.user-marker-popup.showing {
    opacity: 1;
    z-index: 10;
}

.user-marker-popup > div {
    max-width: 100%;
    display: block;
}

.user-marker-popup hr {
    margin: 5px 0 0 0;
    border-top: 1px solid #c7d1dc;
    float: none;
    width: auto;
}

.user-marker-popup .user-image {
    width: 60px;
    height: 60px;
    margin: 0 15px 0 5px;
    border-radius: 50%;
    border: 1px solid #8297ad;
    padding: 1px;
}

.user-marker-popup div.user-image {
    display: inline-block;
    vertical-align: middle;
}

.user-marker-popup div.user-image .no-avatar {
    font-size: 42px;
    background-color: rgb(230, 227, 227);
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 50%;
    padding-top: 0;
    position: relative;
    display: block;
}

.user-marker-popup div.user-image .no-avatar:before {
    position: relative;
    top: 21px;
}

.user-marker-popup .user-label {
    display: inline-block;
    width: 67%;
    height: 74px;
    vertical-align: middle;
}

.user-marker-popup .user-label > label {
    width: 100%;
    text-align: left;
    margin: 0;
}

.user-marker-popup .name {
    font-size: 18px;
    padding-top: 0.2em;
    line-height: 1.2em;
}

.user-marker-popup .last-update {
    font-size: 11px;
    color: #767676;
}

.user-marker-popup .status {
    font-size: 11px;
}

.user-marker-popup .status i {
    font-size: 14px;
    position: relative;
}

.user-marker-popup .status.red {
    color: #b44124;
}

.user-marker-popup .status.yellow {
    color: #B48B18;
}

.user-marker-popup .status.green {
    color: #57b424;
}

.user-marker-popup .status.blue {
    color: #5567f4;
}

.user-marker-popup .property-details {
    display: block;
    padding-top: 15px;
    font-size: 12px;
    color: #4e4e4e;
    font-weight: normal;
}

.user-marker-popup .property-details .title {
    font-size: 14px;
}

.user-marker-popup .row2 {
    padding: 0 20px;
}

.user-marker-popup .row2 > div {
    display: inline-block;
    width: 49%;
    padding: 20px 0 8px 0;
}

.user-marker-popup .info-text {
    font-size: 16px;
}

.user-marker-popup .info-label {
    font-size: 11px;
    color: #767676;
    letter-spacing: 0.5px;
}

.user-marker-popup .info-label i[class^="ss-"] {
    position: relative;
    top: 1px;
}

.user-marker-popup #cs-pop-up-arrow {
    position: fixed;
    width: 16px;
    height: 16px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.27);
}

.user-marker-popup #cs-pop-up-arrow.bottom {
    border-top: none;
    border-left: none;
}

.user-marker-popup #cs-pop-up-arrow.left {
    border-top: none;
    border-right: none;
    margin-left: 25px;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.06) -3px 3px 3px);
}

.user-marker-popup #cs-pop-up-arrow.right {
    border-bottom: none;
    border-left: none;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.06) 3px -3px 3px);
}

.leaflet-user-marker-icon .pulse {
    top: 52px;
    left: 33px;
}

.leaflet-user-marker-icon .pulse:after {
    height: 50px;
    width: 50px;
    margin: -18px 0 0 -18px;
}

.user-route-popup .ss-user {
    width: 50px;
    height: 50px;
}

.leaflet-popup .leaflet-popup-content {
    padding: 0;
}

.leaflet-popup .leaflet-popup-tip-container {
    z-index: -1;
}

/*User's historical popup styles*/
.leaflet-popup.user-route-popup .leaflet-popup-content-wrapper {
    border: 2px solid #9e9e9e;
    border-radius: 2px;
    width: 50px;
    height: 50px;
    background-color: white;
    padding: 0;
}

.leaflet-popup.user-route-popup .leaflet-popup-tip {
    background-color: #5a5b5c;
    width: 12px;
    height: 12px;
    margin: -7px auto 0;
}

.leaflet-popup.user-route-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
    width: 45px;
    height: 45px;
}

.leaflet-popup.user-route-popup .leaflet-popup-content-wrapper .leaflet-popup-content > img {
    width: 100%;
    height: 100%;
}

.leaflet-popup.user-route-popup .leaflet-popup-close-button {
    padding: 2px 0 0 0;
}

/*Navigation path popup styles*/
.cs-map-nav-path-popup > div {
    position: relative;
    top: 10px;
}

.cs-map-nav-path-popup .leaflet-popup-content-wrapper {
    background: transparent;
    border-radius: 0;
}

.cs-map-nav-path-popup .leaflet-popup-content {
    min-width: 78px;
    width: auto !important;
    display: flex;
    white-space: pre;
    color: #5d6063;
    padding: 3px 5px;
    text-align: left;
    font-size: 11px;
    line-height: 0.9rem;
    box-shadow: 1px 1px 10px rgba(100, 100, 100, 0.6);
    border: 2px solid #767779;
    border-radius: 3px;
    font-weight: bold;
    background-color: #f7f7f6;
}

.cs-map-nav-path-popup .leaflet-popup-content > div {
    display: inline-block;
    margin: 0 5px;
}

.cs-map-nav-path-popup .leaflet-popup-content span[class^="ss-"] {
    font-size: 11px;
    top: 1px;
    position: relative;
}

.cs-map-nav-path-popup .leaflet-popup-tip {
    background-color: #767779;
    margin: -5px auto 0;
}

/* Marker round styles */
/*
:root {
    --marker-round-color: #3A6889;
}
.cs-marker-round {
    background-color: var(--marker-round-color);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 2px;
}
.cs-marker-round:after {
    content: '';
    width: 0;
    height: 0;
    z-index: -1;
    position: absolute;
    top: 33px;
    left: 5px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: 19px solid var(--marker-round-color);
}
.cs-marker-round .shadow-img {
    position: absolute;
    top: 16px;
    left: 8px;
    z-index: -2;
    width: 36px;
    height: 36px;
}
.cs-marker-round .marker-body {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-color: transparent;
    text-align: center;
    color: white;
}
.cs-marker-round .marker-body .icon {
    font-size: 20px;
    padding: 7px;
}
.cs-marker-round .marker-body .text-icon {
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    padding: 7px;
}
.cs-marker-round.red {
    --marker-round-color: #a63a3a;
}
.cs-marker-round.green {
    --marker-round-color: #50a69a;
}
.cs-marker-round.blue {
    --marker-round-color: #0d11a6;
}
.cs-marker-round.brown {
    --marker-round-color: #b14b1a;
}
*/

.cs-marker-round .text {
    margin-left: -1px;
}

/* Job estimate marker styles  */
.cs-marker-job-estimate {
    cursor: -webkit-grab;
    cursor: grab;
}

.cs-marker-job-estimate:after {
    content: '';
    width: 0;
    height: 0;
    z-index: -1;
    position: absolute;
    top: 30px;
    left: 5px;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-top: 10px solid;
    border-top-color: inherit;
}

.cs-marker-job-estimate .shadow-img {
    position: absolute;
    top: 2px;
    left: -6px;
    z-index: -1;
    width: 260px;
    height: 40px;
}

.cs-marker-job-estimate.small .shadow-img {
    left: -4px;
    top: 2px;
    width: 255px;
    height: 40px;
}

.cs-marker-job-estimate .marker-body {
    display: flex;
    background: white;
    text-align: left;
    color: #615f5f;
    border: 2px solid;
    border-color: inherit;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.cs-marker-job-estimate .marker-body > div {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 4px 4px;
}

.cs-marker-job-estimate .marker-body .lbl {
    color: white;
    background-color: #3a6789;
    font-weight: bold;
    padding: 4px 8px;
}

.cs-marker-job-estimate .marker-body .user {
    padding: 0;
    width: 26px;
}

.cs-marker-job-estimate .marker-body .user img {
    width: 100%;
}

.cs-marker-job-estimate .marker-body .user .no-avatar {
    font-size: 14px;
    padding: 5px 0px 0 0;
    width: 100%;
    height: 21px;
    text-align: center;
    display: block;
    background-color: #e6e3e3;
}

.cs-marker-job-estimate .marker-body .txt {
    white-space: pre;
}

.inline-divider:after {
    content: '';
    border-right: 1px solid;
    position: relative;
    border-radius: 1px;
    left: 6px;
    margin-right: 5px;
}

.cs-marker-job-estimate .marker-body .diary-event-status {
    top: 2px;
    position: relative;
    padding-right: 3px;
}

.tooltip.map-jobestimatemarker-tooltip.left {
    margin-left: 12px !important;
}

.cs-marker-job-estimate.cs-event-colour-1 {
    border-color: rgb(90, 155, 216);
}

.cs-marker-job-estimate.cs-event-colour-2 {
    border-color: rgb(216, 151, 90);
}

.cs-marker-job-estimate.cs-event-colour-3 {
    border-color: rgb(216, 90, 155);
}

.cs-marker-job-estimate.cs-event-colour-4 {
    border-color: rgb(151, 90, 216);
}

.cs-marker-job-estimate.cs-event-colour-5 {
    border-color: rgb(155, 216, 90);
}

.cs-marker-job-estimate.cs-event-colour-6 {
    border-color: rgb(152, 194, 230);
}

.cs-marker-job-estimate.cs-event-colour-7 {
    border-color: rgb(230, 190, 152);
}

.cs-marker-job-estimate.cs-event-colour-8 {
    border-color: rgb(230, 152, 194);
}

.cs-marker-job-estimate.cs-event-colour-9 {
    border-color: rgb(190, 152, 230);
}

.cs-marker-job-estimate.cs-event-colour-10 {
    border-color: rgb(194, 230, 152);
}

.cs-marker-job-estimate.cs-event-colour-11 {
    border-color: rgb(130, 182, 227);
}

.cs-marker-job-estimate.cs-event-colour-12 {
    border-color: rgb(227, 175, 130);
}

.cs-marker-job-estimate.cs-event-colour-13 {
    border-color: rgb(227, 130, 182);
}

.cs-marker-job-estimate.cs-event-colour-14 {
    border-color: rgb(175, 130, 227);
}

.cs-marker-job-estimate.cs-event-colour-15 {
    border-color: rgb(182, 227, 130);
}

.cs-marker-job-estimate.cs-event-colour-16 {
    border-color: rgb(110, 168, 222);
}

.cs-marker-job-estimate.cs-event-colour-17 {
    border-color: rgb(222, 164, 110);
}

.cs-marker-job-estimate.cs-event-colour-18 {
    border-color: rgb(222, 110, 168);
}

.cs-marker-job-estimate.cs-event-colour-19 {
    border-color: rgb(164, 110, 222);
}

.cs-marker-job-estimate.cs-event-colour-20 {
    border-color: rgb(168, 222, 110);
}

.cs-marker-job-estimate.cs-event-colour-21 {
    border-color: rgb(172, 207, 236);
}

.cs-marker-job-estimate.cs-event-colour-22 {
    border-color: rgb(236, 203, 172);
}

.cs-marker-job-estimate.cs-event-colour-23 {
    border-color: rgb(236, 172, 207);
}

.cs-marker-job-estimate.cs-event-colour-24 {
    border-color: rgb(203, 172, 236);
}

.cs-marker-job-estimate.cs-event-colour-25 {
    border-color: rgb(207, 236, 172);
}

.cs-marker-job-estimate.cs-event-colour-26 {
    border-color: rgb(118, 149, 175);
}

.cs-marker-job-estimate.cs-event-colour-27 {
    border-color: rgb(180, 148, 121);
}

.cs-marker-job-estimate.cs-event-colour-28 {
    border-color: rgb(164, 112, 139);
}

.cs-marker-job-estimate.cs-event-colour-29 {
    border-color: rgb(154, 168, 181);
}

.cs-marker-job-estimate.cs-event-colour-30 {
    border-color: rgb(127, 105, 155);
}

.cs-marker-job-estimate.cs-event-colour-31 {
    border-color: rgb(85, 137, 182);
}

.cs-marker-job-estimate.cs-event-colour-32 {
    border-color: rgb(169, 101, 44);
}

.cs-marker-job-estimate.cs-event-colour-33 {
    border-color: rgb(189, 61, 128);
}

.cs-marker-job-estimate.cs-event-colour-34 {
    border-color: rgb(32, 95, 146);
}

.cs-marker-job-estimate.cs-event-colour-35 {
    border-color: rgb(103, 52, 167);
}

.cs-marker-job-estimate.cs-event-colour-36 {
    border-color: rgb(183, 191, 85);
}

.cs-marker-job-estimate.cs-event-colour-37 {
    border-color: rgb(95, 201, 150);
}

.cs-marker-job-estimate.cs-event-colour-38 {
    border-color: rgb(89, 188, 188);
}

.cs-marker-job-estimate.cs-event-colour-39 {
    border-color: rgb(40, 160, 187);
}

.cs-marker-job-estimate.cs-event-colour-40 {
    border-color: rgb(103, 186, 38);
}

.cs-marker-job-estimate.cs-event-colour-41 {
    border-color: rgb(203, 0, 0);
}

.cs-marker-job-estimate.cs-event-colour-42 {
    border-color: rgb(53, 169, 79);
}

.cs-marker-job-estimate.cs-event-colour-43 {
    border-color: rgb(145, 10, 139)
}

.cs-marker-job-estimate.cs-event-colour-44 {
    border-color: rgb(70, 71, 0);
}

.cs-marker-job-estimate.cs-event-colour-1 .marker-body .lbl {
    background-color: rgb(90, 155, 216);
}

.cs-marker-job-estimate.cs-event-colour-2 .marker-body .lbl {
    background-color: rgb(216, 151, 90);
}

.cs-marker-job-estimate.cs-event-colour-3 .marker-body .lbl {
    background-color: rgb(216, 90, 155);
}

.cs-marker-job-estimate.cs-event-colour-4 .marker-body .lbl {
    background-color: rgb(151, 90, 216);
}

.cs-marker-job-estimate.cs-event-colour-5 .marker-body .lbl {
    background-color: rgb(155, 216, 90);
}

.cs-marker-job-estimate.cs-event-colour-6 .marker-body .lbl {
    background-color: rgb(152, 194, 230);
}

.cs-marker-job-estimate.cs-event-colour-7 .marker-body .lbl {
    background-color: rgb(230, 190, 152);
}

.cs-marker-job-estimate.cs-event-colour-8 .marker-body .lbl {
    background-color: rgb(230, 152, 194);
}

.cs-marker-job-estimate.cs-event-colour-9 .marker-body .lbl {
    background-color: rgb(190, 152, 230);
}

.cs-marker-job-estimate.cs-event-colour-10 .marker-body .lbl {
    background-color: rgb(194, 230, 152);
}

.cs-marker-job-estimate.cs-event-colour-11 .marker-body .lbl {
    background-color: rgb(130, 182, 227);
}

.cs-marker-job-estimate.cs-event-colour-12 .marker-body .lbl {
    background-color: rgb(227, 175, 130);
}

.cs-marker-job-estimate.cs-event-colour-13 .marker-body .lbl {
    background-color: rgb(227, 130, 182);
}

.cs-marker-job-estimate.cs-event-colour-14 .marker-body .lbl {
    background-color: rgb(175, 130, 227);
}

.cs-marker-job-estimate.cs-event-colour-15 .marker-body .lbl {
    background-color: rgb(182, 227, 130);
}

.cs-marker-job-estimate.cs-event-colour-16 .marker-body .lbl {
    background-color: rgb(110, 168, 222);
}

.cs-marker-job-estimate.cs-event-colour-17 .marker-body .lbl {
    background-color: rgb(222, 164, 110);
}

.cs-marker-job-estimate.cs-event-colour-18 .marker-body .lbl {
    background-color: rgb(222, 110, 168);
}

.cs-marker-job-estimate.cs-event-colour-19 .marker-body .lbl {
    background-color: rgb(164, 110, 222);
}

.cs-marker-job-estimate.cs-event-colour-20 .marker-body .lbl {
    background-color: rgb(168, 222, 110);
}

.cs-marker-job-estimate.cs-event-colour-21 .marker-body .lbl {
    background-color: rgb(172, 207, 236);
}

.cs-marker-job-estimate.cs-event-colour-22 .marker-body .lbl {
    background-color: rgb(236, 203, 172);
}

.cs-marker-job-estimate.cs-event-colour-23 .marker-body .lbl {
    background-color: rgb(236, 172, 207);
}

.cs-marker-job-estimate.cs-event-colour-24 .marker-body .lbl {
    background-color: rgb(203, 172, 236);
}

.cs-marker-job-estimate.cs-event-colour-25 .marker-body .lbl {
    background-color: rgb(207, 236, 172);
}

.cs-marker-job-estimate.cs-event-colour-26 .marker-body .lbl {
    background-color: rgb(118, 149, 175);
}

.cs-marker-job-estimate.cs-event-colour-27 .marker-body .lbl {
    background-color: rgb(180, 148, 121);
}

.cs-marker-job-estimate.cs-event-colour-28 .marker-body .lbl {
    background-color: rgb(164, 112, 139);
}

.cs-marker-job-estimate.cs-event-colour-29 .marker-body .lbl {
    background-color: rgb(154, 168, 181);
}

.cs-marker-job-estimate.cs-event-colour-30 .marker-body .lbl {
    background-color: rgb(127, 105, 155);
}

.cs-marker-job-estimate.cs-event-colour-31 .marker-body .lbl {
    background-color: rgb(85, 137, 182);
}

.cs-marker-job-estimate.cs-event-colour-32 .marker-body .lbl {
    background-color: rgb(169, 101, 44);
}

.cs-marker-job-estimate.cs-event-colour-33 .marker-body .lbl {
    background-color: rgb(189, 61, 128);
}

.cs-marker-job-estimate.cs-event-colour-34 .marker-body .lbl {
    background-color: rgb(32, 95, 146);
}

.cs-marker-job-estimate.cs-event-colour-35 .marker-body .lbl {
    background-color: rgb(103, 52, 167);
}

.cs-marker-job-estimate.cs-event-colour-36 .marker-body .lbl {
    background-color: rgb(183, 191, 85);
}

.cs-marker-job-estimate.cs-event-colour-37 .marker-body .lbl {
    background-color: rgb(95, 201, 150);
}

.cs-marker-job-estimate.cs-event-colour-38 .marker-body .lbl {
    background-color: rgb(89, 188, 188);
}

.cs-marker-job-estimate.cs-event-colour-39 .marker-body .lbl {
    background-color: rgb(40, 160, 187);
}

.cs-marker-job-estimate.cs-event-colour-40 .marker-body .lbl {
    background-color: rgb(103, 186, 38);
}

.cs-marker-job-estimate.cs-event-colour-41 .marker-body .lbl {
    background-color: rgb(203, 0, 0);
}

.cs-marker-job-estimate.cs-event-colour-42 .marker-body .lbl {
    background-color: rgb(53, 169, 79);
}

.cs-marker-job-estimate.cs-event-colour-43 .marker-body .lbl {
    background-color: rgb(145, 10, 139);
}

.cs-marker-job-estimate.cs-event-colour-44 .marker-body .lbl {
    background-color: rgb(70, 71, 0);
}

/*Tooltip*/
.leaflet-tooltip .cs-event {
    position: relative !important;
    box-shadow: none !important;
    border: none;
}

.leaflet-tooltip .cs-event > span {
    display: none;
}

.map-usermarker-tooltip, .map-jobestimatemarker-tooltip {
    opacity: 1 !important;
}

.map-usermarker-tooltip .tooltip-inner, .map-jobestimatemarker-tooltip .tooltip-inner {
    display: inline-table;
    padding: 0;
    margin: 0;
    background-color: white;
    text-align: left;
    color: darkslategrey;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.map-jobestimatemarker-tooltip .tooltip-inner {
    margin-right: 13px;
}

.map-usermarker-tooltip .tooltip-inner {
    white-space: nowrap !important;
}

.map-usermarker-tooltip .tooltip-arrow, .map-jobestimatemarker-tooltip .tooltip-arrow {
    border-left-color: white !important;
}

.map-jobestimatemarker-tooltip .tooltip-arrow {
    margin-right: 13px;
}

.map-jobestimatemarker-tooltip .cs-event {
    opacity: 1 !important;
    display: inline-table !important;
    position: relative !important;
    box-shadow: none !important;
    border: none !important;
}

.map-jobestimatemarker-tooltip .cs-event #cs-pop-up-arrow {
    display: none;
}

.user-route-start-end-marker {
    background: transparent;
    border: none;
}

.user-route-start-end-marker > img {
    width: 100%;
    height: auto;
}

/*************************************
            Report styles
**************************************/
/* Report element styles */
.score-badge {
    display: inline-block;
    min-width: 10px;
    padding: 4px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

.badge-green {
    background-color: #50a69a;
}

.badge-yellow {
    background-color: #b3b156;
}

.badge-red {
    background-color: #b14b1a;
}

.category-reporting_users_driver_detail tr.main-header th {
    text-align: center;
}

.category-reporting_users_driver_detail tr.main-header th.border-right, .category-reporting_users_driver_detail tr.sub-header th.border-right {
    border-right: 1px solid #c2c2c2;
}

/* CS weekpicker styles */
.daterange-wrapper.week {
    width: 512px;
}

.daterange-wrapper.week .daterange-content {
    width: 311px;
}

.daterange-wrapper.week .calendar-wrapper .calendar-title {
    margin-bottom: 0;
}

.daterange-wrapper.week .calendar-wrapper .selected-date {
    margin: 0 0 11px 7px;
    width: 100%;
}

.cs-weekpicker .day-view table tr:hover td a {
    background-color: #f8f8f8;
    color: #265882;
}

.cs-weekpicker .day-view .selected-row td, .cs-weekpicker .day-view .selected-row:hover td {
    border-right: none;
}

.cs-weekpicker .day-view .selected-row td a, .cs-weekpicker .day-view .selected-row:hover td a {
    background: #3f6884;
    color: #ffffff;
}

/* Driver detail report styles */
.user-driving-profile-report {
}

.profile_image > div {
    display: inline-block;
}

.profile_image .image_wrapper {
    vertical-align: middle;
    overflow: hidden;
}

.profile_image .image_wrapper img {
    width: 100%;
}

.summary {
    font-weight: bold;
    padding: 30px 0;
}

.summary .row1 {
    margin-bottom: 20px;
}

.summary .txt {
    color: #63757c;
}

.summary .lbl {
    color: #a0a0a0;
    font-size: 13px;
}

.summary .row1 .txt {
    font-size: 31px;
    line-height: 34px;
}

.summary .row2 .txt {
    font-size: 25px;
    line-height: 28px;
}

.user-driving-profile-report .reporting-widget.medium {
    padding: 15px 30px;
}

.user-driving-profile-report .reporting-widget.medium .title h3 {
    text-align: center;
    font-weight: bold;
    color: rgba(100, 118, 124, 1);
}

.user-driving-profile-report .time-tracking-timeline {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.user-driving-profile-report .time-tracking-timeline .title, .dtt-timeline .title {
    min-height: 30px;
    width: 100%;
    text-align: center;
}

.user-driving-profile-report .time-tracking-timeline .title h3 {
    margin-bottom: 30px;
}

.dtt-timeline {
    margin-bottom: 50px;
}

.dtt-timeline .loader {
    background-color: rgba(100, 100, 100, 0.1);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    text-align: center;
}

.dtt-timeline .loader-icon {
    vertical-align: middle;
    font-size: 30px;
    position: relative;
    top: 40%;
}

.dtt-timeline .container {
    position: relative;
}

.dtt-timeline .jqTimespaceTableContainer {
    margin: 0 !important;
    max-width: 100% !important;
    padding: 0 37px;
}

.dtt-timeline .jqTimepsaceContainer table {
    background: transparent;
    border-collapse: collapse;
    empty-cells: show;
}

.dtt-timeline .jqTimepsaceContainer table tr {
    background: transparent;
    border: none;
}

.dtt-timeline .jqTimepsaceContainer tbody td {
    height: 70px;
    padding: 0;
    position: relative;
    border: none;
}

.dtt-timeline .jqTimepsaceContainer table tr:first-child td {
    padding-top: 70px;
}

.dtt-timeline .jqTimepsaceContainer tbody td:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #b3abab;
    width: 100%;
}

.dtt-timeline .jqTimepsaceContainer tbody tr:first-child td:before {
    top: 70px;
}

.dtt-timeline .jqTimespaceEvent {
    padding: 0 !important;
}

.dtt-timeline .jqTimespaceEvent .jqTimespaceEventMarker {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    top: -10px;
    padding: 3px;
    left: -12px;
    transition: unset;
}

.dtt-timeline .jqTimespaceEvent .jqTimespaceEventMarker:hover {
    width: 30px;
    height: 30px;
    left: -19px;
    top: -19px;
}

.dtt-timeline .jqTimespaceEvent .jqTimespaceEventBorder {
    height: 70px;
    top: -70px;
    display: none;
}

.dtt-timeline .jqTimespaceEvent .jqTimespaceEventMarker:hover + .jqTimespaceEventBorder {
    display: block;
}

.jqTimespaceTableContainer .diary-time-tracking-event {
    padding: 2px;
}

.jqTimespaceEvent .jqTimespaceEventMarker.no-class {
    background: #969393;
}

.jqTimespaceEvent .trip-start {
    background: #60a369;
}

.jqTimespaceEvent .trip-stop {
    background: #b95858;
}

.jqTimespaceEvent .icon {
    width: 100%;
    text-align: center;
    display: block;
}

.jqTimespaceEvent .trip-start .icon {
    margin-left: 1px;
}

.jqTimespaceEvent .diary-event-label.accept,
.jqTimespaceEvent .diary-event-label.arrive,
.jqTimespaceEvent .diary-event-label.leave {
    background: #60a369;
}

.jqTimespaceEvent .diary-event-label.reject,
.jqTimespaceEvent .diary-event-label.abort,
.jqTimespaceEvent .diary-event-label.cancel {
    background: #b95858;
}

.jqTimespaceEvent .diary-event-label.no-access {
    background: #d4a93c;
}

.jqTimespaceEvent .diary-event-label.travel {
    background: #6393b5;
}

.jqTimespaceEvent .fas {
    width: 100%;
    text-align: center;
    top: -2px;
    position: relative;
    /*transition: all 0.3s ease-in;*/
}

.jqTimespaceEvent [class^="ss"] {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 11px;
    display: block;
    padding: 0;
    top: -2px;
    position: relative;
    /*transition: all 0.3s ease-in;*/
}

.jqTimespaceEvent .ss-navigate {
    top: 0;
}

.jqTimespaceEvent .lbl {
    position: absolute;
    width: 45px;
    left: -21px;
    text-align: center;
    word-break: break-all;
    top: 22px;
    padding: 5px 8px;
    color: black;
    z-index: 100000;
    background: white;
    font-size: 11px;
    line-height: 1em;
}

.user-driving-profile-report .jqTimespaceEvent .jqTimespaceEventMarker:hover .icon {
    font-size: 18px;
    padding: 6px 0;
    display: block;
    width: 100%;
    text-align: center;
}

.user-driving-profile-report .jqTimespaceEvent .jqTimespaceEventMarker:hover [class^="ss"] {
    font-size: 18px;
    padding: 8px 0;
}

.jqTimespaceEvent .jqTimespaceEventMarker:hover .lbl {
    top: 38px;
    left: -13px;
}

/*Timespace tooltip styles*/
.tooltip.timespace .tooltip-inner, .tooltip.light .tooltip-inner {
    color: black;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0;
    /*-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 5px 10px rgba(0, 0, 0, .2);*/
    -webkit-box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.3);
    line-break: auto;
    padding: 0;
    min-width: 50px;

}

.tooltip.timespace.in, .tooltip.light.in {
    opacity: 1;
    display: flex !important;
}

.tooltip.timespace .tooltip-arrow, .tooltip.light .tooltip-arrow {
    z-index: 1;
}

.tooltip.timespace.top, .tooltip.light.top {
    margin-top: -4px;
}

.tooltip.timespace.top .tooltip-arrow, .tooltip.light.top .tooltip-arrow {
    bottom: -1px;
    margin-left: -7px;
    border-width: 7px 7px 0;
    border-top-color: white;
}

.tooltip.timespace.left, .tooltip.light.left {
    margin-left: -4px;
}

.tooltip.timespace.left .tooltip-arrow, .tooltip.light.left .tooltip-arrow {
    right: -1px;
    margin-top: -7px;
    border-width: 7px 0 7px 7px;
    border-left-color: white;
}

.tooltip.timespace.right, .tooltip.light.right {
    margin-left: 4px;
}

.tooltip.timespace.right .tooltip-arrow, .tooltip.light.right .tooltip-arrow {
    left: -1px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
}

.tooltip.timespace.bottom, .tooltip.light.bottom {
    margin-top: 4px;
}

.tooltip.timespace.bottom .tooltip-arrow, .tooltip.light.bottom .tooltip-arrow {
    top: -1px;
    margin-left: -7px;
    border-width: 0 7px 7px;
}

.tooltip.timespace .title, .tooltip.light .title {
    padding: 4px 8px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.tooltip.timespace .content, .tooltip.light .content {
    padding: 8px 15px 0 15px;
    margin: 0 0 20px 0;
    font-size: 13px;
    max-width: 330px;
}

.tooltip.timespace .content.with-top-border, .tooltip.light .tooltip-inner .with-top-border {
    margin-top: 5px;
    border-top: 1px solid #e5eceb;
}

.tooltip.timespace .content .field-label, .tooltip.light .content .field-label {
    margin: 0 0 0 6px;
    width: 110px;
    font-weight: normal;
    color: rgba(80, 91, 101, 0.76);
}

.tooltip.timespace .diary-event-jobestimate, .tooltip.timespace .diary-event-normal {
    width: 400px;
    min-height: 180px;
    border-radius: 0;
    padding: 0;
}

.tooltip.timespace .cs-event {
    width: 100%;
    height: 100%;
    position: relative;
    border: none;
}

.tooltip.timespace .cs-event-inner {
    padding: 8px 0;
}

.tooltip.timespace .diary-event-normal .cs-event-body-section {
    margin: 0;
}

.tooltip.timespace .diary-event-normal .cs-event-body-section-content {
    margin: 0;
}

/*Time picker styles*/
.time-inputs ul.dropdown-menu {
    width: 225px;
}

.time-inputs ul.dropdown-menu li {
    background: #fff;
    border: none;
    margin: 0 2px;
}

.time-inputs ul.dropdown-menu .hour-min {
    width: 56px;
}

.time-inputs ul.dropdown-menu .diary-time-meridian {
    width: 60px;
}

/**************************************
             Animations
***************************************/
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index: 100;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

@-webkit-keyframes flash {
    0%, 50%, 100% {
        opacity: 1;
    }

    25%, 75% {
        opacity: 0;
    }
}

@keyframes flash {
    0%, 50%, 100% {
        opacity: 1;
    }

    25%, 75% {
        opacity: 0;
    }
}

.flash {
    -webkit-animation-name: flash;
    animation-name: flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

@-webkit-keyframes rubberBand {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    30% {
        -webkit-transform: scaleX(1.25) scaleY(0.75);
        transform: scaleX(1.25) scaleY(0.75);
    }

    40% {
        -webkit-transform: scaleX(0.75) scaleY(1.25);
        transform: scaleX(0.75) scaleY(1.25);
    }

    60% {
        -webkit-transform: scaleX(1.15) scaleY(0.85);
        transform: scaleX(1.15) scaleY(0.85);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes rubberBand {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    30% {
        -webkit-transform: scaleX(1.25) scaleY(0.75);
        -ms-transform: scaleX(1.25) scaleY(0.75);
        transform: scaleX(1.25) scaleY(0.75);
    }

    40% {
        -webkit-transform: scaleX(0.75) scaleY(1.25);
        -ms-transform: scaleX(0.75) scaleY(1.25);
        transform: scaleX(0.75) scaleY(1.25);
    }

    60% {
        -webkit-transform: scaleX(1.15) scaleY(0.85);
        -ms-transform: scaleX(1.15) scaleY(0.85);
        transform: scaleX(1.15) scaleY(0.85);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
}

@-webkit-keyframes shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20%, 40%, 60%, 80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

@keyframes shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    20%, 40%, 60%, 80% {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
}

@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    40% {
        -webkit-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    60% {
        -webkit-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    80% {
        -webkit-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.swing {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    10%, 20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30%, 50%, 70%, 90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40%, 60%, 80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    10%, 20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
        -ms-transform: scale(0.9) rotate(-3deg);
        transform: scale(0.9) rotate(-3deg);
    }

    30%, 50%, 70%, 90% {
        -webkit-transform: scale(1.1) rotate(3deg);
        -ms-transform: scale(1.1) rotate(3deg);
        transform: scale(1.1) rotate(3deg);
    }

    40%, 60%, 80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
        -ms-transform: scale(1.1) rotate(-3deg);
        transform: scale(1.1) rotate(-3deg);
    }

    100% {
        -webkit-transform: scale(1) rotate(0);
        -ms-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }

    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
        -ms-transform: translateX(-25%) rotate(-5deg);
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
        -ms-transform: translateX(20%) rotate(3deg);
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
        -ms-transform: translateX(-15%) rotate(-3deg);
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
        -ms-transform: translateX(10%) rotate(2deg);
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
        -ms-transform: translateX(-5%) rotate(-1deg);
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}

@-webkit-keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3);
        transform: scale(.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    70% {
        -webkit-transform: scale(.9);
        transform: scale(.9);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        -webkit-transform: scale(.3);
        -ms-transform: scale(.3);
        transform: scale(.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
    }

    70% {
        -webkit-transform: scale(.9);
        -ms-transform: scale(.9);
        transform: scale(.9);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(30px);
        -ms-transform: translateY(30px);
        transform: translateY(30px);
    }

    80% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
        -ms-transform: translateX(30px);
        transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
        transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(.95);
        transform: scale(.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(.3);
        transform: scale(.3);
    }
}

@keyframes bounceOut {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(.95);
        -ms-transform: scale(.95);
        transform: scale(.95);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(.3);
        -ms-transform: scale(.3);
        transform: scale(.3);
    }
}

.bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@keyframes bounceOutDown {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

.bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@keyframes bounceOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

.bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@keyframes bounceOutRight {
    0% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

.bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@keyframes bounceOutUp {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDown10 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInDown10 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInDown10 {
    -webkit-animation-name: fadeInDown10;
    animation-name: fadeInDown10;
}

@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(40px);
        -ms-transform: translateX(40px);
        transform: translateX(40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp10 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInUp10 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUp10 {
    -webkit-animation-name: fadeInUp10;
    animation-name: fadeInUp10;
}

@-webkit-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

.fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

.fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
}

.fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

.fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUp10 {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes fadeOutUp10 {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.fadeOutUp10 {
    -webkit-animation-name: fadeOutUp10;
    animation-name: fadeOutUp10;
}

@-webkit-keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

.fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
    0% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

@keyframes flip {
    0% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    40% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    80% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    -ms-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
        -ms-transform: perspective(400px) rotateX(-10deg);
        transform: perspective(400px) rotateX(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
        -ms-transform: perspective(400px) rotateX(10deg);
        transform: perspective(400px) rotateX(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

.flipInX {
    -webkit-backface-visibility: visible !important;
    -ms-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
}

@-webkit-keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes flipInY {
    0% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        -webkit-transform: perspective(400px) rotateY(-10deg);
        -ms-transform: perspective(400px) rotateY(-10deg);
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        -webkit-transform: perspective(400px) rotateY(10deg);
        -ms-transform: perspective(400px) rotateY(10deg);
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}

.flipInY {
    -webkit-backface-visibility: visible !important;
    -ms-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
}

@keyframes flipOutX {
    0% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
}

.flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-backface-visibility: visible !important;
    -ms-backface-visibility: visible !important;
    backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }
}

@keyframes flipOutY {
    0% {
        -webkit-transform: perspective(400px) rotateY(0deg);
        -ms-transform: perspective(400px) rotateY(0deg);
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: perspective(400px) rotateY(90deg);
        -ms-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }
}

.flipOutY {
    -webkit-backface-visibility: visible !important;
    -ms-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }
}

@keyframes lightSpeedIn {
    0% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateX(-20%) skewX(30deg);
        -ms-transform: translateX(-20%) skewX(30deg);
        transform: translateX(-20%) skewX(30deg);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateX(0%) skewX(-15deg);
        -ms-transform: translateX(0%) skewX(-15deg);
        transform: translateX(0%) skewX(-15deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOut {
    0% {
        -webkit-transform: translateX(0%) skewX(0deg);
        -ms-transform: translateX(0%) skewX(0deg);
        transform: translateX(0%) skewX(0deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(100%) skewX(-30deg);
        -ms-transform: translateX(100%) skewX(-30deg);
        transform: translateX(100%) skewX(-30deg);
        opacity: 0;
    }
}

.lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateIn {
    0% {
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        transform: rotate(-200deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInDownRight {
    0% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotateInUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

.rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

@keyframes rotateOut {
    0% {
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: rotate(200deg);
        -ms-transform: rotate(200deg);
        transform: rotate(200deg);
        opacity: 0;
    }
}

.rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

.rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutDownRight {
    0% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

.rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpLeft {
    0% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: left bottom;
        -ms-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0;
    }
}

.rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

@keyframes rotateOutUpRight {
    0% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0;
    }
}

.rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight;
}

@-webkit-keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }

    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }

    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
}

@-webkit-keyframes slideOutLeft {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

@keyframes slideOutLeft {
    0% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px);
    }
}

.slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

@keyframes slideOutRight {
    0% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }
}

.slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

@keyframes slideOutUp {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-2000px);
        -ms-transform: translateY(-2000px);
        transform: translateY(-2000px);
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
}

@-webkit-keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

@keyframes slideOutDown {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        -ms-transform: translateY(2000px);
        transform: translateY(2000px);
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}

@-webkit-keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%, 60% {
        -webkit-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        -webkit-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(700px);
        transform: translateY(700px);
        opacity: 0;
    }
}

@keyframes hinge {
    0% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    20%, 60% {
        -webkit-transform: rotate(80deg);
        -ms-transform: rotate(80deg);
        transform: rotate(80deg);
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    40% {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    80% {
        -webkit-transform: rotate(60deg) translateY(0);
        -ms-transform: rotate(60deg) translateY(0);
        transform: rotate(60deg) translateY(0);
        -webkit-transform-origin: top left;
        -ms-transform-origin: top left;
        transform-origin: top left;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(700px);
        -ms-transform: translateY(700px);
        transform: translateY(700px);
        opacity: 0;
    }
}

.hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

@keyframes rollIn {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        -ms-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }
}

.rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollOut {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(120deg);
        transform: translateX(100%) rotate(120deg);
    }
}

@keyframes rollOut {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0px) rotate(0deg);
        -ms-transform: translateX(0px) rotate(0deg);
        transform: translateX(0px) rotate(0deg);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(120deg);
        -ms-transform: translateX(100%) rotate(120deg);
        transform: translateX(100%) rotate(120deg);
    }
}

.rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut;
}

/*Pulse animation*/
.pulse {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    -webkit-transform: rotateX(55deg);
    -moz-transform: rotateX(55deg);
    -o-transform: rotateX(55deg);
    -ms-transform: rotateX(55deg);
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    -webkit-animation: pulsate 1s ease-out;
    -moz-animation: pulsate 1s ease-out;
    -o-animation: pulsate 1s ease-out;
    -ms-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-box-shadow: 0 0 1px 2px #89849b;
    box-shadow: 0 0 1px 2px #89849b;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s;
    -o-animation-delay: 1.1s;
    -ms-animation-delay: 1.1s;
    animation-delay: 1.1s;
}

@-moz-keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        -moz-transform: scale(0.1, 0.1);
        -o-transform: scale(0.1, 0.1);
        -ms-transform: scale(0.1, 0.1);
        transform: scale(0.1, 0.1);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
    50% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        -o-transform: scale(1.2, 1.2);
        -ms-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
}

@-webkit-keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        -moz-transform: scale(0.1, 0.1);
        -o-transform: scale(0.1, 0.1);
        -ms-transform: scale(0.1, 0.1);
        transform: scale(0.1, 0.1);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
    50% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        -o-transform: scale(1.2, 1.2);
        -ms-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
}

@-o-keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        -moz-transform: scale(0.1, 0.1);
        -o-transform: scale(0.1, 0.1);
        -ms-transform: scale(0.1, 0.1);
        transform: scale(0.1, 0.1);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
    50% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        -o-transform: scale(1.2, 1.2);
        -ms-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
}

@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        -moz-transform: scale(0.1, 0.1);
        -o-transform: scale(0.1, 0.1);
        -ms-transform: scale(0.1, 0.1);
        transform: scale(0.1, 0.1);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
    50% {
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        -o-transform: scale(1.2, 1.2);
        -ms-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }
}

/*Bounce animation*/
.bounce {
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    -o-animation-name: bounce;
    -ms-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    -ms-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/*Circle bounce loader */

.circular-bounce-loader.loading-wrap span {
    width: 13px;
    height: 13px;
    display: inline-block;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-animation: circularBounce 1.2s infinite ease-in-out;
    animation: circularBounce 1.2s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.circular-bounce-loader.loading-wrap .circle1 {
    background: #93dbe9;
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
}

.circular-bounce-loader.loading-wrap .circle2 {
    background: #689cc5;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.circular-bounce-loader.loading-wrap .circle3 {
    background: #5e6fa3;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.circular-bounce-loader.loading-wrap .circle4 {
    background: #3b4368;
}

@-webkit-keyframes circularBounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes circularBounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    40% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.bar {
    position: absolute;
    top: -5px;
    left: -6px;
    width: 43px;
    height: 10px;
    border: 1px solid #3a6789;
    border-radius: 3px;
    background-image: repeating-linear-gradient(-45deg, #3a6789, #3a6789 11px, #eee 10px, #eee 20px /* determines size */
    );
    background-size: 28px 28px;
    animation: move .5s linear infinite;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 28px 0;
    }
}

/*User marker pulse styles*/
.cs-marker-user-svg.pulse {
    top: 73%;
    left: 56.5%;
}

.cs-marker-user-svg.pulse:after {
    height: 50px;
    width: 50px;
    margin: -18px 0 0 -18px;
}

.cs-user-marker-svg-eta.bar {
    top: 0;
    left: 0;
    width: 30px;
    height: 9px;
}

/**GPS View screen styles ------------------------------------------------*/
.tv-gps-view .select2-container {
    display: block;
}

.tv-gps-view #gps-view-edit-container {
    height: 300px;
}

/** Job deadlines and user activity -------------------------------------*/
#diary-right-bar .diary-bar-sections li.job-deadline-windows > a:hover {
    background: transparent;
}

.job-deadline-windows .indicators {
    height: auto;
    position: relative;
    float: left;
    padding: 0px 15px 9px 25px;
}

.job-deadline-windows .indicators .label {
    display: inline-block;
    position: relative;
    width: 20px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    margin-right: 3px;
}

/** Outstanding jobs booking and user activity in Diary screen --------------*/
.jobs-side-panel-on-diary  .app-filter {
    height: auto;
}
.jobs-side-panel-on-diary .additional-filters {
    margin: 40px 10px 10px;
    font-size: 13px;
}
.jobs-side-panel-on-diary .additional-filters .control-group {
    margin-bottom: 0;
}
.jobs-side-panel-on-diary .additional-filters .control-label,
.jobs-side-panel-on-diary .additional-filters .controls,
.jobs-side-panel-on-diary .additional-filters .dairy-sidepanel-hour-section {
    display: inline-block;
    font-size: 12px;
}

.jobs-side-panel-on-diary .additional-filters .control-label {
    width: 10%;
    line-height: 3;
}
#side-panel.jobs-side-panel-on-diary .additional-filters .controls {
    width: 35%;
    margin-bottom: 0;
}
.jobs-side-panel-on-diary .additional-filters .input-append {
    position: relative;
}
.jobs-side-panel-on-diary .additional-filters .dairy-sidepanel-hour-section {
    width: 40%;
    float: none;
}
.jobs-side-panel-on-diary .additional-filters .dairy-sidepanel-hour-section select {
    height: 27px;
    width: auto;
    font-size: 12px;
    margin-top: 3px;
    padding: 0.3em 0.3em;
}
.jobs-side-panel-on-diary .app-filter .search_box {
    margin: 5px;
    width: 95%;
    transition: none;
}
.jobs-side-panel-on-diary .app-filter .search_box.adjust-for-more-filters {
    margin: 5px;
    width: 70%;
}
.jobs-side-panel-on-diary .app-filter .filters-btn {
    float: right;
    padding-right: 15px;
    line-height: 27px;
    margin: 5px;
    cursor: pointer;
}
#side-panel.jobs-side-panel-on-diary .controls input {
    height: 25px !important;
    font-size: 12px;
    width: 100px;
}
.jobs-side-panel-on-diary .additional-filters .add-on {
    padding: 0.2em 0.5em;
    font-size: 12px;
}
.jobs-side-panel-on-diary .additional-filters .datepicker-control-group .ss-calendar {
    top: 0;
}
.jobs-side-panel-on-diary .page-datepicker .custom-datepicker {
    left: 15%;
}
.jobs-side-panel-on-diary #page-panel-main .custom-datepicker table {
    width: 100%;
}
.jobs-side-panel-on-diary .additional-filters .actions {
    width: 100%;
    height: 30px;
    display: block;
    text-align: right;
}
.jobs-side-panel-on-diary .additional-filters .btn {
    line-height: 1;
    top: 5px;
    padding: 6px 12px;
}
.jobs-side-panel-on-diary .additional-filters .btn i {
    position: relative;
    top: 2px;
    font-size: 11px;
}
.jobs-side-panel-on-diary .additional-filters .actions a {
    color: #999999;top: 348px;
    line-height: 1;
    margin-right: 10px;
    top: 5px;
    padding: 6px;
}
.jobs-side-panel-on-diary .additional-filters .control-error-message {
    padding-left: 20px;
}
.jobs-side-panel-on-diary .pretty-list-with-search.for-users .pretty-list li:last-of-type {
    border-bottom: none;
}
.jobs-side-panel-on-diary .pretty-list-with-search.for-users .pretty-list label {
    line-height: 20px;
}
.jobs-side-panel-on-diary .pretty-list .oj-info-tip {
    left: 64px;
    top: 9px;
}
.jobs-side-panel-on-diary .pretty-list .title {
    width: auto;
}

.jobs-side-panel-on-diary .pretty-list .title a {
    margin-right: 10px;
}

.jobs-side-panel-on-diary .pretty-list .title [class^="ss-"] {
    top: 2px;
    position: relative;
    margin-right: 5px;
}

.jobs-side-panel-on-diary .color-indicator {
    height: 10px;
    min-width: 10px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
}

.jobs-side-panel-on-diary .load-on-scroll-elm {
    width: auto;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px 50px;
}

.jobs-side-panel-on-diary .load-on-scroll-elm>div {
    padding-bottom: 120px;
}
.jobs-side-panel-on-diary .pretty-list .loading-wrap {
    margin: 70px auto 70px;
}
.users-activity-panel-on-diary .pretty-list .last-status {
    line-height: 20px;
    font-size: 12px;
}

/*used for alignment in list_job_duration_windows.html*/
.system_settings_sortable div .sortable_options_title{
    float: right;
    display: block;
    width: 100px;
}

