/*This file is for handsontable spreadsheet*/
.handsontable tr th {
    font-weight: bold;
    background: rgba(0, 0, 0, 0.02);
}
.handsontable thead th .relative{
    padding: 2px 0;
}
.handsontable tbody tr th:first-child{
    text-align: center !important;
}
.handsontable tr td{
    position: relative !important;
}
.ht_master tr:nth-of-type(even) > td{
    background: rgba(0, 0, 0, 0.02);
}
.ht_master tr:hover > td, .ht_master tbody tr:hover > th[class]{
    background: #fff6d9 !important;
}
.ht_master tr td, .ht_master tr th, .htCore tr td, .htCore tr th {

    vertical-align: middle;
    text-align: left;
    white-space: nowrap !important;
}
.ht_master tr td:not, .ht_master tr th {
    text-align: left;
}
.handsontable.overflow_handsontable thead tr th{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;}

.handsontable .wtBorder {
    background-color: #477596 !important;
}

/*pika-table is a pain to enforce styles on, so messy !important overide needed*/

.pika-single{
    position: absolute !important;
}
.pika-table thead th{
    background: white !important;
    color: #7d8a92 !important;
    border: none !important;
    font-weight: normal !important;
}
.pika-table tbody td{
    border: 1px solid #f2f2f2 !important;
    text-align: center;
    padding: 5px !important;
}
.pika-table abbr{
    text-decoration: none !important;
}
.pika-label{
    font-weight: normal !important;
    color: #477596;
}
.pika-title{
    border-bottom: 1px solid #f2f2f2 !important;
}
.pika-title:hover{
    border-bottom: 1px solid #477596 !important;
}
.pika-day{
    color: #477596 !important;
    background: transparent !important;
    text-align: center !important;
}
td[aria-selected = false]:hover{
    background: #f2f2f2 !important;
}
.pika-lendar .is-selected {
    background: #477596 !important;
}
.is-selected .pika-button{
    box-shadow: none !important;
}
.is-selected .pika-day {
    color: white !important;
    border-radius: 0 !important;
}
.handsontable .wtHider{
    width: 100% !important;
}
.handsontableInputHolder *{
    outline: none !important;
    box-shadow: none !important;
}
.handontableInputHolder input{
    background: transparent !important;
    height: 36px !important;
}
.handsontableInput{
    box-shadow: none !important;
    padding: 0 0 0 1px  !important;
    border-top: 2px solid #477596;
    border-left: 1.75px solid #477596 !important;
    border-right: 1px solid #477596 !important;
}
.handsontable .select2-container .select2-choice{
    border: none !important;
    background: transparent !important;
}
.handsontable .select2-container .select2-arrow{
    background: transparent !important;
}
.handsontable-select{
    transform: translate(.75px, -24.75px) !important;
    /*hacky fix for moving dropdown*/
}
.select2-container.handsontableInput.select2-dropdown-open.select2-container-active.select2-drop-above{
    height: 35px !important;
}
.select2-drop-above{
    transform: translateY(1px) !important;
}
.handsontableInputHolder textarea{
    height: 36px !important;
    border: 2px solid #477596 !important;
    outline: none !important;
    display:inline-block !important;
    vertical-align:middle !important;
    padding: 0 2px 0 5px !important;
}

@media screen and (max-width: 1400px){
    .handsontable thead tr th, .handsontable tbody tr td{
        letter-spacing: .5px;
    }
    .handsontable thead tr th{
        word-spacing: -1px;
    }
    .handsontable tbody tr td .relative{
        padding: 2px 0;
        display: inline-block;
    }
}
.handsontable td.htInvalid {
    background-color:transparent !important;
}
.overflow_handsontable{
    overflow: hidden !important;
    z-index: 0;
}
.page-datepicker .custom-datepicker{
    width: auto !important;
}
.handsontable .htDimmed {
    background-color: #eeeeee !important;
}
@-moz-document url-prefix() {

    .ht_master tr td{
        padding: 2px 10px !important;
        line-height: 18px;
        position: relative;
    }
    .ht_master tr th{
        line-height: 21px;
    }
    .htAutocompleteArrow {
        float: none;
        position: absolute;
        right: 5px;
        top: 10px;
    }
    .handsontable thead th .relative{
        padding: 5px 0;
    }
    .handsontable-select{
        transform: translate(.75px, -37.75px) !important;
    }
    .handsontableInput{
        box-shadow: none !important;
        padding: 0 0 0 1px  !important;
        transform: translateY(-1px) !important;
    }
}
.ht_master tr td, .ht_master tr th, .handsontable table.htCore tbody tr td, .handsontable table.htCore tbody tr th {
    padding: 8px 10px;
    vertical-align: middle;
}
.handsontable table.htCore tbody tr td, .handsontable table.htCore tbody tr th {
    height: auto !important;
}
.handsontable thead tr th, .handsontable td{
    padding: 5px 10px;
}
.handsontable.ht_master .wtHolder {
    min-height: 150px !important;
    max-height: 450px !important;
    height:auto !important;
}

app-job-description-pricing-items .handsontable.ht_master .wtHolder {
    min-height: auto !important;
}

.add-tax-rate-spreadsheet .handsontable.ht_master .wtHolder {
    min-height: 80px !important;
}
.errorRowList {
    background-color: #ff9785 !important;
}
.handsontable .cashAllocation {
    background-color: #d1e4d4 !important;
}
.total-value-color-pos {
    color: #60a369;
}
.total-value-color-neg {
    color: #b95858;
}
.col_header_bg .relative .colHeader:after {
    position: absolute;
    top: 0;
    right: 2px;
    content: ' *';
    color: #d53847;
    font-size: 16px;
}
.select_icon:after {
    content: ' ▼';
    position: absolute;
    right: 10px;
    font-size: 10px;
    color: #777;
    cursor: default;
    width: 16px;
    text-align: center;
}
.select_modified_icon {
    /* content: "\2304"; */
    position: absolute;
    right: 10px;
    font-size: 14px;
    color: #505B65;
    cursor: default;
    width: 16px;
    top: 8px;
    font-weight: 900;
    text-align: center;
}
.handsontable .htAutocompleteArrow {
    color: #777 !important;
}
.supplier_mark_up .percentage-input{
    width: 220px;
}
.htComments{
    position: fixed;
    height: 36px;
}
.htCommentTextArea{
    min-width: 400px;
    height: 40px;
}
.htCommentCell:after{
    border-color: transparent !important;}

.handsontable .hidden-column{
    color: #60a369;
    position: absolute;
    right: 2px;
    margin: 0px 14px 0px 0px;
    font-size: 14px;
}
.handsontable .disable-col-header{
    color: #888383;
}
.handleSpreadsheet {

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #ffffff;
    padding: 0 10px 10px;
    border: 1px solid #c9c9c9;
    margin-bottom: 10px;
}
.handleSpreadsheet .section-title.with-edit a, .title_bar.with-edit a {
    float: left;
    margin: -1px 5px 0px 0;
}
.handleSpreadsheet .section-title {
    margin: 0 0 10px 0;
}
.handleSpreadsheet .row-fluid.without-padding {
    margin-top: 10px;
}
.handleSpreadsheet .section-title {

    padding: 11px 10px 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-box-shadow: 0px 1px 0px #fdfdfd inset;
    -moz-box-shadow: 0px 1px 0px #fdfdfd inset;
    box-shadow: 0px 1px 0px #fdfdfd inset;
    background: #fcfcfc;
    border-bottom: 1px solid #dadada;
}
.handleSpreadsheet .section-title h3 {
    font-size: 18px;
    line-height: 15px;
}
.handleSpreadsheet .hotTableClass {
    padding: 67px 0 0 0;
}
.handleSpreadsheet .section-title .help-block {
    float: left;
    margin: -3px 0 0 10px;
    clear: none;
    display: inline-block;
}
.transparent-section.remove-float-none .section-title {
    float: none !important;
    margin-bottom: 10px;
}
.spreadsheet-help-text p.help-text {
    padding-top:5px !important;
}

.handleSpreadsheet .section-title .edit-buttons {
    float: right;
    margin: -3px 0 0 9px;
    border-left: 1px solid #c9c9c9;
}

.handleSpreadsheet .section-title .edit-buttons a {
    float: none;
    margin: 0 0 0 10px;
}

.handleSpreadsheet .section-title .edit-buttons #cancel-panel-btn {
    float: right;
}

.handleSpreadsheet .section-title .icon {
    float: left;
    margin: 0 0 0 15px;
}

.handleSpreadsheet .section-title strong {
    color: #677077;
}

.handleSpreadsheet .section-title .with-edit h3 {
    margin: 0 0 0 10px;
    line-height: 15px;
}

.handleSpreadsheet .section-title .with-edit a {
    margin: -2px 0 0 10px;
}

.handsontable tbody td.cell-without-border {
    background: rgba(0, 0, 0, 0.02) !important;
}


.pricing_col_header_bg{
    background-color: #6392B5 !important;
    color: #ffffff !important;
}
#pricing-section-table .handsontable.ht_master .wtHolder, #pricing-section-table .handsontable.ht_master .wtHolder {
    min-height: none !important;
    max-height: none !important;
    height: auto !important;
}
#pricing-section-table .wtHider {
    height: auto !important;
}
#pricing-section-table .pricing-section-margin {
    background-color: #d4f1d3 !important;
}
#pricing-section-table .pricing-section-margin-loss {
    background-color: #FFD2D2 !important;
}
#pricing-section-table .customer-pricing{
    border-left: none !important;
  }
  #pricing-section-table .customer-pricing-labour-border {
    border-right: none !important;
    border-bottom: none !important;
    background-color: #ffffff !important;
  }
  #pricing-section-table .calculate-class {
    background-color: white !important;
    border-bottom: none !important;
}
#pricing-section-table .calculate-class-margin {
    background-color: white !important;
    border-bottom: none !important;
    border-right: none !important;
}
#pricing-section-table .calculate-class-supplier {
    background-color: rgba(181, 99, 153, 0.1) !important;
}
#pricing-section-table .calculate-bg {
    background-color: white;
}
#pricing-section-table .pricing_supplier_bg{
    background-color: #B56399 !important;
    color: #ffffff !important;
}
#pricing-section-table .price-total-bg {
    background-color: #ffffff !important;
}
#pricing-section-table .ss-down-arrow::after {
    color: #505B65;
    font-family: "Font Awesome 6 Pro";
    content: "\f063";
    vertical-align: middle;
    font-size: .75em;
    float: right;
    font-weight: 900;
    margin-right: 5px;
}
#pricing-section-table .updated-supplier-status::after {
    font-family: "Font Awesome 6 Pro";
    content: "\f017";
    vertical-align: middle;
    font-size: .75em;
    float: right;
    font-weight: 900;
}
#pricing-section-table .price-selected::after {
    font-family: "Font Awesome 6 Pro";
    content: "\f00c";
    font-size: .75em;
    float: right;
    font-weight: 900;
    color: white;
    margin-left: 5px;
}
#pricing-section-table .latest-updated:after {
    color: #60a369 !important;;
}
#pricing-section-table .not-recent-updated:after {
    color: #E53939 !important;
}
#pricing-section-table .pricing-selected-bg {
    background: #b1d2b5 !important;
    border-bottom: 1px solid #adaaaa;
}
#pricing-section-table label {
    display: contents !important;
}
#pricing-section-table .checkbox-button__input {
	opacity: 0;
	position: absolute;
}
#pricing-section-table .checkbox-button__control {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-right: 5px;
	vertical-align: middle;
	color: #9D9D9D;
    background: #FFFFFF;
    border: 1px solid #A3A3A3;
    box-sizing: border-box;
}
#pricing-section-table .checkbox-button__input:checked+.checkbox-button__control:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border: 1px solid #C9C9CB;
    box-sizing: border-box;
    background-color: #868686;
}
#pricing-section-table .checkbox-button__input:checked+.checkbox-button__control {
	border-color: #9D9D9D;
}
#pricing-section-table .checkbox-button__control {
    transform: scale(0.75);
}
#pricing-section-table .htRight {
    text-align: left !important;
}
.highlight-upsell-row::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0;
    bottom: 0;
    border: 1px solid #ce800ccc;
    border-width: 0 0 0 3px;
}
.stop-page-actions .wtHider {
    pointer-events:none;
}
.handleSpreadsheet .section-title {
    height: 48px !important;
    padding-top: 18px !important;
}
#deposit-invoice-schedule .wrap-InvoiceDescription{
    overflow-wrap: break-word;
    white-space: pre-line !important;
}
#deposit-invoice-schedule .ht_clone_left {
    display: none !important;
}
.maintenance-col-header-bg{
    background: #6493B5 !important;
    color:#FFFFFF !important;
}

