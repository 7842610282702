/*!
 * froala_editor v3.2.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2020 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-element table td.fr-selected-cell, .fr-element table th.fr-selected-cell {
  border: 1px double #0098f7; }
.fr-element table tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
.fr-element table td, .fr-element table th {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text; }
.fr-element .fr-no-selection table td, .fr-element .fr-no-selection table th {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-table-resizer {
  cursor: col-resize;
  position: absolute;
  z-index: 3;
  display: none; }
  .fr-table-resizer.fr-moving {
    z-index: 2; }
  .fr-table-resizer div {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-right: 1px solid #0098f7; }

.fr-no-selection {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.fr-popup .fr-table-size {
  margin: 20px; }
  .fr-popup .fr-table-size .fr-table-size-info {
    text-align: center;
    font-size: 14px; }
  .fr-popup .fr-table-size .fr-select-table-size {
    line-height: 0;
    padding: 5px;
    white-space: nowrap; }
    .fr-popup .fr-table-size .fr-select-table-size > span {
      display: inline-block;
      padding: 0 4px 4px 0;
      background: transparent; }
      .fr-popup .fr-table-size .fr-select-table-size > span > span {
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid #DDD; }
      .fr-popup .fr-table-size .fr-select-table-size > span.hover {
        background: transparent; }
        .fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
          background: rgba(0, 152, 247, 0.3);
          border: solid 1px #0098f7; }
    .fr-popup .fr-table-size .fr-select-table-size .new-line::after {
      clear: both;
      display: block;
      content: "";
      height: 0; }
.fr-popup.fr-above .fr-table-size .fr-select-table-size > span {
  display: inline-block !important; }
.fr-popup .fr-table-colors {
  display: block;
  padding: 20px;
  padding-bottom: 0; }

.fr-popup.fr-desktop .fr-table-size .fr-select-table-size > span > span {
  width: 12px;
  height: 12px; }

.fr-insert-helper {
  position: absolute;
  z-index: 9999;
  white-space: nowrap; }
