@charset "UTF-8";
@font-face {
    font-family: SSStandard;
    src: url(./ss-standard.eot);
    src: url(./ss-standard.eot?#iefix) format('embedded-opentype'), url(./ss-standard.woff) format('woff'), url(./ss-standard.ttf) format('truetype'), url(./ss-standard.svg#SSStandard) format('svg');
    font-weight: 400;
    font-style: normal
}

html:hover [class^=ss-] {
    -ms-zoom: 1
}

.ss-icon,
.ss-icon.ss-standard,
[class*=" ss-"].right:after,
[class*=" ss-"].ss-standard.right:after,
[class*=" ss-"].ss-standard:before,
[class*=" ss-"]:before,
[class^=ss-].right:after,
[class^=ss-].ss-standard.right:after,
[class^=ss-].ss-standard:before,
[class^=ss-]:before {
    font-family: SSStandard;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    white-space: nowrap;
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga"1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased
}

[class*=" ss-"].right:before,
[class^=ss-].right:before {
    display: none;
    content: ''
}

.ss-cursor.right:after,
.ss-cursor:before {
    content: ''
}

.ss-crosshair.right:after,
.ss-crosshair:before {
    content: '⌖'
}

.ss-search.right:after,
.ss-search:before {
    content: '🔎'
}

.ss-zoomin.right:after,
.ss-zoomin:before {
    content: ''
}

.ss-zoomout.right:after,
.ss-zoomout:before {
    content: ''
}

.ss-view.right:after,
.ss-view:before {
    content: '👀'
}

.ss-attach.right:after,
.ss-attach:before {
    content: '📎'
}

.ss-link.right:after,
.ss-link:before {
    content: '🔗'
}

.ss-move.right:after,
.ss-move:before {
    content: ''
}

.ss-write.right:after,
.ss-write:before {
    content: '✎'
}

.ss-writingdisabled.right:after,
.ss-writingdisabled:before {
    content: ''
}

.ss-erase.right:after,
.ss-erase:before {
    content: '✐'
}

.ss-compose.right:after,
.ss-compose:before {
    content: '📝'
}

.ss-lock.right:after,
.ss-lock:before {
    content: '🔒'
}

.ss-unlock.right:after,
.ss-unlock:before {
    content: '🔓'
}

.ss-key.right:after,
.ss-key:before {
    content: '🔑'
}

.ss-backspace.right:after,
.ss-backspace:before {
    content: '⌫'
}

.ss-ban.right:after,
.ss-ban:before {
    content: '🚫'
}

.ss-trash.right:after,
.ss-trash:before {
    content: ''
}

.ss-target.right:after,
.ss-target:before {
    content: '◎'
}

.ss-tag.right:after,
.ss-tag:before {
    content: ''
}

.ss-bookmark.right:after,
.ss-bookmark:before {
    content: '🔖'
}

.ss-flag.right:after,
.ss-flag:before {
    content: '⚑'
}

.ss-like.right:after,
.ss-like:before {
    content: '👍'
}

.ss-dislike.right:after,
.ss-dislike:before {
    content: '👎'
}

.ss-heart.right:after,
.ss-heart:before {
    content: '♥'
}

.ss-halfheart.right:after,
.ss-halfheart:before {
    content: ''
}

.ss-star.right:after,
.ss-star:before {
    content: '⋆'
}

.ss-halfstar.right:after,
.ss-halfstar:before {
    content: ''
}

.ss-sample.right:after,
.ss-sample:before {
    content: ''
}

.ss-crop.right:after,
.ss-crop:before {
    content: ''
}

.ss-layers.right:after,
.ss-layers:before {
    content: ''
}

.ss-fill.right:after,
.ss-fill:before {
    content: ''
}

.ss-stroke.right:after,
.ss-stroke:before {
    content: ''
}

.ss-phone.right:after,
.ss-phone:before {
    content: '📞'
}

.ss-phonedisabled.right:after,
.ss-phonedisabled:before {
    content: ''
}

.ss-rss.right:after,
.ss-rss:before {
    content: ''
}

.ss-facetime.right:after,
.ss-facetime:before {
    content: ''
}

.ss-reply.right:after,
.ss-reply:before {
    content: '↩'
}

.ss-send.right:after,
.ss-send:before {
    content: ''
}

.ss-mail.right:after,
.ss-mail:before {
    content: '✉'
}

.ss-inbox.right:after,
.ss-inbox:before {
    content: '📥'
}

.ss-chat.right:after,
.ss-chat:before {
    content: '💬'
}

.ss-ellipsischat.right:after,
.ss-ellipsischat:before {
    content: ''
}

.ss-ellipsis.right:after,
.ss-ellipsis:before {
    content: '…'
}

.ss-user.right:after,
.ss-user:before {
    content: '👤'
}

.ss-femaleuser.right:after,
.ss-femaleuser:before {
    content: '👧'
}

.ss-users.right:after,
.ss-users:before {
    content: '👥'
}

.ss-cart.right:after,
.ss-cart:before {
    content: ''
}

.ss-creditcard.right:after,
.ss-creditcard:before {
    content: '💳'
}

.ss-dollarsign.right:after,
.ss-dollarsign:before {
    content: '💲'
}

.ss-barchart.right:after,
.ss-barchart:before {
    content: '📊'
}

.ss-piechart.right:after,
.ss-piechart:before {
    content: ''
}

.ss-box.right:after,
.ss-box:before {
    content: '📦'
}

.ss-home.right:after,
.ss-home:before {
    content: '⌂'
}

.ss-buildings.right:after,
.ss-buildings:before {
    content: '🏢'
}

.ss-warehouse.right:after,
.ss-warehouse:before {
    content: ''
}

.ss-globe.right:after,
.ss-globe:before {
    content: '🌎'
}

.ss-navigate.right:after,
.ss-navigate:before {
    content: ''
}

.ss-compass.right:after,
.ss-compass:before {
    content: ''
}

.ss-signpost.right:after,
.ss-signpost:before {
    content: ''
}

.ss-map.right:after,
.ss-map:before {
    content: ''
}

.ss-location.right:after,
.ss-location:before {
    content: ''
}

.ss-pin.right:after,
.ss-pin:before {
    content: '📍'
}

.ss-database.right:after,
.ss-database:before {
    content: ''
}

.ss-hdd.right:after,
.ss-hdd:before {
    content: ''
}

.ss-music.right:after,
.ss-music:before {
    content: '♫'
}

.ss-mic.right:after,
.ss-mic:before {
    content: '🎤'
}

.ss-volume.right:after,
.ss-volume:before {
    content: '🔈'
}

.ss-lowvolume.right:after,
.ss-lowvolume:before {
    content: '🔉'
}

.ss-highvolume.right:after,
.ss-highvolume:before {
    content: '🔊'
}

.ss-airplay.right:after,
.ss-airplay:before {
    content: ''
}

.ss-camera.right:after,
.ss-camera:before {
    content: '📷'
}

.ss-picture.right:after,
.ss-picture:before {
    content: '🌄'
}

.ss-video.right:after,
.ss-video:before {
    content: '📹'
}

.ss-play.right:after,
.ss-play:before {
    content: '▶'
}

.ss-pause.right:after,
.ss-pause:before {
    content: ''
}

.ss-stop.right:after,
.ss-stop:before {
    content: '■'
}

.ss-record.right:after,
.ss-record:before {
    content: '●'
}

.ss-rewind.right:after,
.ss-rewind:before {
    content: '⏪'
}

.ss-fastforward.right:after,
.ss-fastforward:before {
    content: '⏩'
}

.ss-skipback.right:after,
.ss-skipback:before {
    content: '⏮'
}

.ss-skipforward.right:after,
.ss-skipforward:before {
    content: '⏭'
}

.ss-eject.right:after,
.ss-eject:before {
    content: '⏏'
}

.ss-repeat.right:after,
.ss-repeat:before {
    content: '🔁'
}

.ss-replay.right:after,
.ss-replay:before {
    content: '↺'
}

.ss-shuffle.right:after,
.ss-shuffle:before {
    content: '🔀'
}

.ss-book.right:after,
.ss-book:before {
    content: '📕'
}

.ss-openbook.right:after,
.ss-openbook:before {
    content: '📖'
}

.ss-notebook.right:after,
.ss-notebook:before {
    content: '📓'
}

.ss-newspaper.right:after,
.ss-newspaper:before {
    content: '📰'
}

.ss-grid.right:after,
.ss-grid:before {
    content: ''
}

.ss-rows.right:after,
.ss-rows:before {
    content: ''
}

.ss-columns.right:after,
.ss-columns:before {
    content: ''
}

.ss-thumbnails.right:after,
.ss-thumbnails:before {
    content: ''
}

.ss-filter.right:after,
.ss-filter:before {
    content: ''
}

.ss-desktop.right:after,
.ss-desktop:before {
    content: '💻'
}

.ss-laptop.right:after,
.ss-laptop:before {
    content: ''
}

.ss-tablet.right:after,
.ss-tablet:before {
    content: ''
}

.ss-cell.right:after,
.ss-cell:before {
    content: '📱'
}

.ss-battery.right:after,
.ss-battery:before {
    content: '🔋'
}

.ss-highbattery.right:after,
.ss-highbattery:before {
    content: ''
}

.ss-mediumbattery.right:after,
.ss-mediumbattery:before {
    content: ''
}

.ss-lowbattery.right:after,
.ss-lowbattery:before {
    content: ''
}

.ss-emptybattery.right:after,
.ss-emptybattery:before {
    content: ''
}

.ss-lightbulb.right:after,
.ss-lightbulb:before {
    content: '💡'
}

.ss-downloadcloud.right:after,
.ss-downloadcloud:before {
    content: ''
}

.ss-download.right:after,
.ss-download:before {
    content: ''
}

.ss-uploadcloud.right:after,
.ss-uploadcloud:before {
    content: ''
}

.ss-upload.right:after,
.ss-upload:before {
    content: ''
}

.ss-fork.right:after,
.ss-fork:before {
    content: ''
}

.ss-merge.right:after,
.ss-merge:before {
    content: ''
}

.ss-transfer.right:after,
.ss-transfer:before {
    content: '⇆'
}

.ss-refresh.right:after,
.ss-refresh:before {
    content: '↻'
}

.ss-sync.right:after,
.ss-sync:before {
    content: ''
}

.ss-loading.right:after,
.ss-loading:before {
    content: ''
}

.ss-wifi.right:after,
.ss-wifi:before {
    content: ''
}

.ss-connection.right:after,
.ss-connection:before {
    content: ''
}

.ss-file.right:after,
.ss-file:before {
    content: '📄'
}

.ss-folder.right:after,
.ss-folder:before {
    content: '📁'
}

.ss-quote.right:after,
.ss-quote:before {
    content: '“'
}

.ss-text.right:after,
.ss-text:before {
    content: ''
}

.ss-font.right:after,
.ss-font:before {
    content: ''
}

.ss-print.right:after,
.ss-print:before {
    content: '⎙'
}

.ss-fax.right:after,
.ss-fax:before {
    content: '📠'
}

.ss-list.right:after,
.ss-list:before {
    content: ''
}

.ss-layout.right:after,
.ss-layout:before {
    content: ''
}

.ss-action.right:after,
.ss-action:before {
    content: ''
}

.ss-redirect.right:after,
.ss-redirect:before {
    content: '↪'
}

.ss-expand.right:after,
.ss-expand:before {
    content: '⤢'
}

.ss-contract.right:after,
.ss-contract:before {
    content: ''
}

.ss-help.right:after,
.ss-help:before {
    content: '❓'
}

.ss-info.right:after,
.ss-info:before {
    content: 'ℹ'
}

.ss-alert.right:after,
.ss-alert:before {
    content: '⚠'
}

.ss-caution.right:after,
.ss-caution:before {
    content: '⛔'
}

.ss-logout.right:after,
.ss-logout:before {
    content: ''
}

.ss-plus.right:after,
.ss-plus:before {
    content: '+'
}

.ss-hyphen.right:after,
.ss-hyphen:before {
    content: '-'
}

.ss-check.right:after,
.ss-check:before {
    content: '✓'
}

.ss-delete.right:after,
.ss-delete:before {
    content: '␡'
}

.ss-settings.right:after,
.ss-settings:before {
    content: '⚙'
}

.ss-dashboard.right:after,
.ss-dashboard:before {
    content: ''
}

.ss-notifications.right:after,
.ss-notifications:before {
    content: '🔔'
}

.ss-notificationsdisabled.right:after,
.ss-notificationsdisabled:before {
    content: '🔕'
}

.ss-clock.right:after,
.ss-clock:before {
    content: '⏲'
}

.ss-stopwatch.right:after,
.ss-stopwatch:before {
    content: '⏱'
}

.ss-calendar.right:after,
.ss-calendar:before {
    content: '📅'
}

.ss-addcalendar.right:after,
.ss-addcalendar:before {
    content: ''
}

.ss-removecalendar.right:after,
.ss-removecalendar:before {
    content: ''
}

.ss-checkcalendar.right:after,
.ss-checkcalendar:before {
    content: ''
}

.ss-deletecalendar.right:after,
.ss-deletecalendar:before {
    content: ''
}

.ss-plane.right:after,
.ss-plane:before {
    content: '✈'
}

.ss-briefcase.right:after,
.ss-briefcase:before {
    content: '💼'
}

.ss-cloud.right:after,
.ss-cloud:before {
    content: '☁'
}

.ss-droplet.right:after,
.ss-droplet:before {
    content: '💧'
}

.ss-flask.right:after,
.ss-flask:before {
    content: ''
}

.ss-up.right:after,
.ss-up:before {
    content: '⬆'
}

.ss-upright.right:after,
.ss-upright:before {
    content: '⬈'
}

.ss-right.right:after,
.ss-right:before {
    content: '➡'
}

.ss-downright.right:after,
.ss-downright:before {
    content: '⬊'
}

.ss-down.right:after,
.ss-down:before {
    content: '⬇'
}

.ss-downleft.right:after,
.ss-downleft:before {
    content: '⬋'
}

.ss-left.right:after,
.ss-left:before {
    content: '⬅'
}

.ss-upleft.right:after,
.ss-upleft:before {
    content: '⬉'
}

.ss-navigateup.right:after,
.ss-navigateup:before {
    content: ''
}

.ss-navigateright.right:after,
.ss-navigateright:before {
    content: '▻'
}

.ss-navigatedown.right:after,
.ss-navigatedown:before {
    content: ''
}

.ss-navigateleft.right:after,
.ss-navigateleft:before {
    content: '◅'
}

.ss-directup.right:after,
.ss-directup:before {
    content: '▴'
}

.ss-directright.right:after,
.ss-directright:before {
    content: '▹'
}

.ss-dropdown.right:after,
.ss-dropdown:before {
    content: '▾'
}

.ss-directleft.right:after,
.ss-directleft:before {
    content: '◃'
}

.ss-retweet.right:after,
.ss-retweet:before {
    content: ''
}

.ss-volumelow.right:after,
.ss-volumelow:before {
    content: '🔉'
}

.ss-volumehigh.right:after,
.ss-volumehigh:before {
    content: '🔊'
}

.ss-batteryhigh.right:after,
.ss-batteryhigh:before {
    content: ''
}

.ss-batterymedium.right:after,
.ss-batterymedium:before {
    content: ''
}

.ss-batterylow.right:after,
.ss-batterylow:before {
    content: ''
}

.ss-batteryempty.right:after,
.ss-batteryempty:before {
    content: ''
}

.ss-clouddownload.right:after,
.ss-clouddownload:before {
    content: ''
}

.ss-cloudupload.right:after,
.ss-cloudupload:before {
    content: ''
}

.ss-calendaradd.right:after,
.ss-calendaradd:before {
    content: ''
}

.ss-calendarremove.right:after,
.ss-calendarremove:before {
    content: ''
}

.ss-calendarcheck.right:after,
.ss-calendarcheck:before {
    content: ''
}

.ss-calendardelete.right:after,
.ss-calendardelete:before {
    content: ''
}