// Customized AG-GRID variables for Commusoft
$ag-header-background-color: #6393B5;
$ag-header-foreground-color: #FFFFFF;
$ag-foreground-color: #505B65;
$ag-font-size: 14px;
$ag-secondary-font-size: $ag-font-size;
$ag-secondary-font-weight: 700;
$ag-font-family: Arial, Helvetica, sans-serif;
$ag-secondary-font-family: $ag-font-family;
$ag-hover-color: #FEF7DC;
$ag-row-height: 40px;
$ag-border-color: #EEEEEE;

// Commusoft variables
$cs-line-height: 20px;
$cs-ag-detail-grid-background-color: #E7F0F8;
$cs-ag-cell-horizontal-padding: 12px;
$cs-ag-cell-vertical-padding: 8px;

// AG-GRID theme styles
@import "~ag-grid-community/src/styles/ag-grid";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham";

/*
Note: The class prefixes are,
  - 'cs' denotes 'Commusoft'
  - 'ag' denotes 'ag-grid'
*/

// CS Custom style properties
$cs-ag-flex-alignment: (
    start: start,
    end: end,
    center: center,
    between: space-between,
    around: space-around,
    evenly: space-evenly,
    stretch: stretch
);

// Here we only customizing the existing ag-grid icons (Ex: `asc`, `desc` icons)
$cs-ag-icons: (
    asc: 'assets/images/icon-list/asc.svg',
    desc: 'assets/images/icon-list/asc.svg'
);

@mixin cs-flex-align($params) {
    @each $classSuffix, $cssVal in $params {
        .cs-ag-justify-#{$classSuffix} {
            justify-content: $cssVal !important;
        }

        .cs-ag-align-#{$classSuffix} {
            align-items: $cssVal !important;
        }

        .cs-ag-justify-#{$classSuffix}-wrp {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                justify-content: $cssVal !important;
            }
        }

        .cs-ag-align-#{$classSuffix}-wrp {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                align-items: $cssVal !important;
            }
        }
    }
}

@mixin cs-span($cs-span-max-limit) {
    @for $val from 2 through $cs-span-max-limit {
        .cs-ag-header-spn-#{$val} {
            height: $ag-header-height * $val !important;

            &.pull-top {
                top: -(($ag-header-height * ($val - 1)) + 1) !important;
            }

            .ag-cell-label-container {
                align-self: stretch !important;
            }
        }

        &.max-header-spn-#{$val} {
            .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
                padding-top: $ag-header-height * $val;
            }
        }
    }
}

@mixin cs-icon($params) {
    @each $ag-icon-name, $icon-src in $params {
        .ag-icon-#{$ag-icon-name} {
            &::before {
                content: url($icon-src);
            }

            @if ($ag-icon-name == desc) {
                transform: rotate(180deg);
            }
        }
    }
}

@mixin cs-ag-utils() {
    @include cs-flex-align($cs-ag-flex-alignment);
    @include cs-span(10);
    @include cs-icon($cs-ag-icons);
}

@mixin cs-ag-override() {
    .ag-root-wrapper {
        .ag-status-bar,
        .ag-paging-panel {
            border: none;
        }
    }

    .ag-root {
        border: none;

        .ag-body-viewport {
            max-height: 100%;

            .ag-row {
                .ag-cell:first-child {
                    border-left: 1px solid $ag-border-color;
                }
            }
        }
    }

    .ag-header-row {
        overflow: visible;

        .ag-header-cell,
        .ag-header-group-cell {
            border-color: $ag-border-color;

            &::after {
                margin: 0;
                height: 100%;
                border-right-color: $ag-border-color;
                border-right-width: 0.5px;
            }

            //.ag-header-cell-text {
            //    white-space: normal !important;
            //}
        }

        .ag-icon {
            color: $ag-header-foreground-color;
        }
    }


    .ag-row {
        border-color: $ag-border-color;

        .ag-cell {
            display: flex;
            align-items: center;
            white-space: normal;
            line-height: $cs-line-height;
            border-right-color: $ag-border-color !important;
            padding: $cs-ag-cell-vertical-padding $cs-ag-cell-horizontal-padding;

            &.pinned-col-header {
                font-weight: 700;
            }

            .ag-cell-wrapper.ag-row-group {
                justify-content: space-between;
                align-items: center;

                .ag-group-contracted,
                .ag-group-expanded {
                    order: 1;
                }

                .ag-group-value {
                    order: -1;
                }
            }

            &.customCell {
                padding: 0;
                background-color: transparent;
                border: none;

                &.disabled {
                    cursor: not-allowed !important;
                    opacity: 0.5 !important;

                    .ag-cell-wrapper {
                        cursor: not-allowed !important;
                    }
                }

                .customGroupCell {
                    padding: $cs-ag-cell-vertical-padding $cs-ag-cell-horizontal-padding;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &.expanded {
                        background-color: $cs-ag-detail-grid-background-color;
                        border-bottom-color: $cs-ag-detail-grid-background-color;
                    }
                }
            }
        }
    }

    .ag-details-row {
        background-color: $cs-ag-detail-grid-background-color;
        padding: 1rem;

        .ag-root-wrapper {
            padding: 0;
        }

        .ag-header {
            background-color: #F8F9F9;
            color: $ag-foreground-color;
        }
    }

    .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
        padding-top: $ag-header-height;
    }

    .ag-floating-bottom-container {
        .ag-row {
            border-bottom-width: 0;
            border-top-width: 0;
        }
    }
}

// CS Custom-theme style Execution
.ag-theme-balham {
    height: 100%;
    width: 100%;

    @include cs-ag-utils();
    @include cs-ag-override();
}
