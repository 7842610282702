
/*
 Bulk_scheduling side panel css
  */
.bulk_scheduling .custom-datepicker {
    left: 8px;
    top: 53px;
}
.bulk_scheduling .custom-datepicker table{
    width: 100% !important;
}
.bulk_scheduling .custom-datepicker table a{
    font-size: 13px !important;
    color: #3883c1;
    -webkit-transition: color 0.1s ease-in;
    -moz-transition: color 0.1s ease-in;
    -o-transition: color 0.1s ease-in;
    transition: color 0.1s ease-in;
}
.bulk_scheduling .custom-datepicker table tbody td .muted {
    background: rgba(245,245,245,.4);
    color: #999;
}

/*.bulk_scheduling .select2-container .select2-choices .select2-search-choice a {
    top: 1px!important;
}*/
/*
Date Range picker related changes
 */
.daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #f8f8f8;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 83%;
    max-width: none;
    padding: 0 0 2% 2%;
    margin-top: 7px;
    top: 260px;
    left: 4%;
    z-index: 3001;
    display: block;
    font-family: arial;
    font-size: 15px;
    line-height: 1em;
}
.daterangepicker:before{
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}

.bulk_scheduling .input-append input{
    border: 1px solid #bbb;
}
.daterangepicker .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px 8px 2px 8px;
    display: block;
    line-height: 12px;
    vertical-align: middle;
}
.daterangepicker .drp-buttons a {
    font-size: 14px !important;
}