
.drag-n-drop-overlay,
.upload-in-progress-overlay {
  position: fixed;
  left: 0px;
  right: 0px;
  height: 1px;
  top: 0px;
  background-color: rgba(0,0,0,0.75);
}   
    
.activated.upload-in-progress-overlay {
  height: 100%;
}

.upload-in-progress-overlay {
  opacity: 1; 
  transition: opacity 0.5s ease-out 1s;
}   
    
.upload-in-progress-activated {
  opacity: 1;
}   

.dragndrop-dialog .divider {
  width: 100%;
  height: 1px;
  transition: width 0.8s ease-out 0.6s;
  background-color: #dedada;
  margin-top: 20px;
}   

.upload-in-progress-activated .divider {
  width: 100%;
}

.dragndrop-dialog .upload-progress-container-padding {
  padding: 0px;

  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.dragndrop-dialog .upper-section {
  height: 45px;
  margin-bottom: 25px;
}

.dragndrop-dialog .darkened-background {
  border: 1px dashed #b9b9b7;
  color: #b5b3b3;
  background-color: #fff;
}

.dragndrop-dialog .spinner {
  font-size: 28px;
  margin-bottom: 10px;
  animation: spin 1.5s linear infinite;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
}

.dragndrop-dialog .upload-in-progress-title {
  color: #4a4a4a; /* lighter gray */
  text-align: left;
  margin-bottom: 25px;
}

.dragndrop-dialog .upload-bar {
  background-color: transparent; 
  border: 0px;
  margin-top: 18px;
  margin-left: 17px;
  height: 4px;
}

.upload-in-progress-activated .row-file-icon {
  color: rgba(116, 108, 166, 0.7);
  margin-left: 5px;
  margin-right: 21px;
  float: left;
  font-size: 38px;
}

.upload-in-progress-overlay .file-name {
  color: #636363;
  margin-top: 8px;
}

.upload-in-progress-overlay .file-error {
  text-align: left;
}

.upload-in-progress-overlay .upload-progress-container-row {
  transition: padding-top 0.8s linear 0.6s;
}

.upload-in-progress-overlay .upload-progress-container-row.activated {
  padding-top: 0px;
}

.drag-n-drop-overlay {
  z-index: -1;
}

.upload-in-progress-overlay {
  z-index: 3;
}

.drag-n-drop-overlay.clear {
  background-color: transparent;
}

.overlay-text {
  margin: auto;
  left: 0px;
  right: 0px;
  top: 0px;
}

.overlay-text-small {
  font-size: 18px;
}

.drag-n-drop-overlay-visible {
  bottom: 0px !important;
  height: auto !important;
}

.upload-percentage {
  font-size: 14px;
  float: left;
  width: 35px;
  text-align: center;
}

.upload-bar {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  height: 10px;
  float: left;
  overflow: hidden;
  margin-top: 15px;
  margin-left: 30px;
  width: 455px;
  background-color: rgba(0,0,0,0.24);
  border: 1px solid #7283aa;
}

.upload-bar-inner {
    background-color: rgba(116, 108, 166, 1);
    height: 100%;
    width: 100px;
}

.upload-progress-container {
  width: 100%;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;

  background-color: transparent;
  border: 0px;
  height: 1px;
  min-height: 180px;
  margin-top: 0px;
}

.upload-progress-container.large {
  height: auto;
}

.upload-progress-container-inner {
  height: 100%;
  overflow-y: scroll;                                                                                                                                                                                                                               
  overflow-x: hidden;
  max-height: 275px;
  font-size: 14px;
  clear: both;
  float: none;

  position: relative;
  padding-top: 30px;
}



ad-progress-container-inner .btn {
  margin-top: 15px;
}

.upload-progress-container-row {
  display: block;
  margin: 7px 0px;
}

.darkened-background {
  padding: 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: rgba(0,0,0,0.24);
}

.file-name {
    margin: 0px;
    font-size: 13px;
    text-align: left;
}

.file-status {
    border-radius: 25px;
    background-color: green;
    margin: 10px;
    color: #fff;
    float: right;
    padding: 3px;
}

.upload-prompt {
  margin-top: 8px;
  margin-bottom: 13px;
}

.upload-in-progress {
  opacity: 0;
  text-align: center;
  font-size: 23px;
  color: #4a4a4a;
  position: absolute;
}

.upload-in-progress-active .upload-in-progress {
  opacity: 1;
}

.overlay-text-inner,
.upload-prompt-fading-out .dragndrop-dialog .overlay-text-inner {
  opacity: 0;
  transition: opacity 0.2s linear 0.4s;
}

.upload-prompt-fading-out .dragndrop-dialog .overlay-text {
  top: 0px;
}

.upload-in-progress-activated .upload-in-progress {
  opacity: 1;
  transition: opacity 1s ease-out; 
  position: relative;
}

.upload-in-progress-activated .upload-prompt {
  position: absolute;
}

.upload-prompt-morphing .dragndrop-dialog .overlay-text-inner {
  opacity: 0;
  transition: opacity 0.2s linear;
}

.upload-prompt-expanding-into-view .dragndrop-dialog .overlay-text-inner,
.upload-prompt-activated .overlay-text-inner {
  opacity: 1;
}

.dragndrop-dialog {
  top:-1000px;
  position: absolute;
  right: 0px;
  left: 0px;

  padding-bottom: 15px;
  padding: 0px;
  /* Play around with these values to add an "expanding-in" animation */
  width: 558px;
  min-height: 225px;
  margin: auto;
  margin-top: -134.5px;
  padding: 20px;

  text-align: center;

  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border: 2px dashed #bfbfbf;
  font-size: 22px;
  color: #b7b7b7;
  text-shadow: 1px 1px 0px #2b2b2b;
  transition:   border 0.3s ease-in-out,
                color 0.3s ease-in-out,
                background-color 0.3s linear,
                margin-top 0.2s ease-in-out,
                padding 0.2s ease-in-out,
                width 0.2s ease-in-out,
                height 0.2s ease-in-out;
}
.upload-prompt-activated .dragndrop-dialog {
  border: 2px solid #4797fd;
  color: #4797fd;
  opacity: 1;
}

.upload-prompt-morphing {
  opacity: 1;
  padding: 0px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition:
                width 0.8s ease-in-out,
                margin-top 0.8s ease-in-out,
                height 0.8s ease-in-out,
                border 0.8s ease-in-out,        
                background-color 1.1s ease-out,
                color 1.1s ease-out,
                padding 0.8s ease-in-out;
}

.overlay-text.upload-prompt.pulse {
  background-color: #f7f7c3;
  opacity: 1;
}

.upload-prompt-fading-out .dragndrop-dialog {
  opacity: 0;
}

.upload-prompt-fading-out .dragndrop-dialog .overlay-text-inner {
  opacity: 0;
}

.upload-prompt-expanding-into-view .upload-in-progress,
.upload-prompt-expanding-into-view .upload-in-progress {
  opacity: 0;
  transition: opacity 0s linear;
}

.upload-prompt-expanded-into-view .dragndrop-dialog {
  opacity: 1;
  padding: 20px;
  width: 558px;
  top: 50%;
  height: auto;

  text-shadow: 0px 0px 0px #2b2b2b;

  background-color: #fff;
  border: 2px solid #fff;
  color: #fff;

  transition:
                text-shadow 0.1s ease-out,
                border 0.2s ease-in-out,        
                background-color 0.3s ease-out,
                color 0.1s ease-out,
                opacity 0.1s linear;
}

.upload-prompt-expanded-into-view .dragndrop-dialog .upload-icon {
  opacity: 0;
  transition: opacity 0s linear;
}

.overlay-text.upload-prompt p {
  transition: opacity 0.3s ease 0.3s;
  opacity: 0;
}

.upload-prompt-expanding-into-view .dragndrop-dialog p {
  opacity: 1;
}

.dragover .overlay-text-container {
  top: 50%;
  position: absolute;
  right: 0px;
  left: 0px;
}


.activated .dragndrop-dialog,
.upload-in-progress-activated .dragndrop-dialog,
.upload-in-progress-error .dragndrop-dialog {
  top: 50%;
  position: relative;
}

#attach-file-dragover {
  position: relative;
}

#attach-file-dragover.dragover-enabled.dragover .drag-n-drop-overlay,
#attach-file-dragover.dragover-enabled.visible-on-screen .drag-n-drop-overlay,
#attach-file-dragover.dragover-enabled.on-screen .drag-n-drop-overlay {
  position: fixed;
  left: 0px;
  z-index: 2;
  height: 100%;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.dragover-enabled.visible-on-screen .drag-n-drop-overlay {
  height: 100%;
}

#attach-file-dragover.dragover-enabled.dragover .drag-n-drop-overlay-visible.clear {
  z-index: 2;
}

.overlay-text.upload-prompt p.upload-icon {
    font-size: 81px;
    margin-bottom: 35px;
    margin-top: 25px;
}

.docparser .overlay-text.upload-prompt p.upload-icon {
  margin-bottom: 25px;
}

.file-icon {
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
  font-size: 50px;
  transition:   top 0.3s ease-in-out,
                margin-left 0.3s ease-in-out,
                margin-right 0.3s ease-in-out;
}

.file-icon-container {
  height: 80px;
  padding-top: 14px;
}

.file-icon-left {
  transform: rotate(-20deg);
  top: 11px;
  margin-right: 8px;
}

.file-icon-middle {
  top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.file-icon-right {
  transform: rotate(20deg);
  top: 11px;
  margin-left: 5px;
}

.upload-prompt-activated .file-icon-left {
  top: 4px;
  margin-right: 16px;
}

.upload-prompt-activated .file-icon-middle {
  top: -11px;
  margin-left: 8px;
}

.upload-prompt-activated .file-icon-right {
  top: 4px;
  margin-left: 16px;
}

#upload-prompt-overlay {
  z-index: -1;
}

.dragover-enabled.visible-on-screen #upload-prompt-overlay {
  z-index: 1;
}

.upload-active {
  opacity: 0;
  height: 0px;
  transition: opacity 0.6s ease-out;
}

.upload-in-progress-activated .upload-active {
  opacity: 1;
  height: auto;
  transition: opacity 0.6s ease-out;
}

.processing-upload-in-background-container {
  opacity: 0;
  height: 0px;
  transition: opacity 0.6s ease-out;
}

.processing-upload-in-background-container .upload-progress-container {
  min-height: 197px;
}

.processing-upload-in-background .processing-upload-in-background-container {
  opacity: 1;
  height: auto;
}

form.contract_wrapper {
  margin: 35px;
}

.contract-push-left-top {
  margin-top: 20px !important;
  margin-left: 0px !important;
}

.file-preview {
  position: absolute;
  right: 40px;
  left: 0px;
  width: auto;
  height: 100%;
  top: 0px;
  bottom: 0px;
}

.upload-completed-button {
  margin-top: 20px;
}

.docparser .select2-container .select2-choice > .select2-chosen {
    text-align: left !important;
    font-size: 15px !important;
    display: block;
    overflow: hidden;
    color: #666;
    white-space: nowrap;

    text-overflow: ellipsis;
}

.docparser .upload-inv-help-text {
    font-size: 15px !important;
}
