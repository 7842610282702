tag-input {
    vertical-align: top;
    padding-top: 7px;
    display: table-cell;
    width: auto;
    padding-right: 18px;
}

tag-input.filter-overflow {
    padding-right: 10px;
}

.tag {
    color: #505b65;
    border: 1px solid #bcbcbc;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 13px;
    text-shadow: 0px -1px 1px rgba(255, 255, 255, 0.4);
    background: #f0f0f0;
}

.reporting-filter-bar-wrapper {
    margin: 0px 10px 0px 10px;
    display: block;
    width: auto;
    min-height: 54px;
}

.reporting-filter-bar {
    display: table;
    margin-left: 0px;
    width: 100%;
    /*   padding-top: 4px;
   */
    padding-bottom: 0px;
    min-height: 50px;
}

.reporting-filter-bar .loading-filters {
    padding-top: 9px;
}

.reporting-filter-bar .date_range_include {
    display: table-cell;
    float: none;
}

.reporting-filter-bar .daterange_filter .filter-label {
    display: none;
}

.search_box_report_filters_inner {
    display: table;
    width: 100%;
}

.filter-overflow .tag-input-ctn {
    padding: 2px 0px 2px 9px;
    margin-bottom: 0px;
}

.filter-overflow .filter-element-conditional {
    display: block;
    width: 100%;
}

.filter-overflow .full-width-search .search_box.search_box_report_filters {
    display: block;
}


.filter-overflow .input-tag.preceding-search {
    display: table-cell;
}

.filter-overflow .search_box.search_box_report_filters {
    display: table-cell;
    height: 23px;
    margin: 0px 0px 7px 0px;
    width: auto !important;
    padding: 3px;
}

.filter-overflow .filter-element-search {
    display: block;
    height: auto;
    padding: 0px 0px 0px 0px;
}

.filter-overflow .apply-filters {
    vertical-align: bottom;
}

.filter-overflow .apply-filters a {
    margin-bottom: 7px;
}

.filter-overflow .tag-input-ctn .input-tag-wrapper {
    /*    display: inline-block;
    */
    /*    display: inline;
    */
    display: block;
    padding-bottom: 7px;
    float: left;
    height: auto;
    clear: both;

    padding-top: 0px;
    padding-right: 3px;
}

.filter-overflow .tag-input-active.tag-input-ctn .input-tag-wrapper {
    padding-top: 5px;
}

.filter-overflow .tag-input-ctn .input-tag {
    float: left;
    display: inline-block;
    margin-bottom: 2px;
}

.filter-overflow .filter-element-tags {
    /*    display: block;
    */
    display: inline;
    padding: 0px;
}
.tag-key, .tag-separator{
    font-weight: bold;
}

.tag-value {
    color: #b5dbf6;
    font-weight: normal;
}

.ui-corner-all.ui-autocomplete {
    margin-top: 5px !important;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.filter-element {
    display: table-cell;
    vertical-align: top;
    padding: 0px 4px;
}

.filter-element:last-child {
    padding: 0px 0px 0px 2px !important;
}

.filter-element:first-child {
    padding: 0px 2px 0px 0px !important;
}

.filter-overflow .filter-element:last-child,
.filter-overflow .filter-element:first-child {
    padding: 0px !important;
}

.filter-element.filter-element-search {
    width: 100%;
}

.filter-overflow .filter-element.filter-element-search.regular-width {
    padding: 0px;
}

.filter-element-conditional {
    display: table;
    width: 100%;
}

.input-tag.preceding-search {
    display: table-cell;
}

.reporting-filter-bar-wrapper .reporting-filter-bar .date_range_include {
    display: table-cell;
    width: auto;
}

.reporting-filter-bar-wrapper .app-filter .filter-block {
    white-space: nowrap;
    width: auto;
}

.reporting-filter-bar-wrapper .app-filter .clear-filter {
    float: none;
    display: table-cell;
    margin: 0px;
    vertical-align: middle;
}

.reporting-filter-bar-wrapper .reporting-filter-bar .daterange_filter .filter-label {
    display: block;
}

.reporting-filter-bar-wrapper .app-filter .daterange_filter.with-clear .filter-label {
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.reporting-filter-bar-wrapper .daterange_filter > div {
    display: table;
    vertical-align: middle;
    height: 22px;
}

.reporting-filter-bar-wrapper .app-filter .date_range_include {
    border: 0px;
}

.reporting-filter-bar-wrapper .app-filter .daterange_filter.with-clear .filter-label .date-filter-view {
    width: auto;
}

.reporting-filter-bar-wrapper .app-filter .daterange_filter.with-clear .ss-delete {
    padding: 0px;
}

.reporting-filter-bar-wrapper .app-filter .daterange_filter .input-append {
    margin: 0px;
}

.reporting-filter-bar-wrapper .input-append .add-on {
    height: 22px;
}

.reporting-filter-bar-wrapper #datepicker-input {
    min-height: 32px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    margin: 0px;
    border: 1px solid #cccccc;
    padding: 2px 10px 2px 10px;
}

.reporting-filter-bar-wrapper .date-filter-mode .filter-element-daterange,
.reporting-filter-bar-wrapper .date-filter-mode .filter-element-daterange.ng-hide {
    display: table-cell !important;
}

.reporting-filter-bar-wrapper .date-filter-mode .daterange-container,
.reporting-filter-bar-wrapper .date-filter-mode .daterange-container.ng-hide {
    display: block !important;
}


.reporting-filter-bar-wrapper .date-filter-mode .filter-element-search .search_box {
    display: none;
}

.reporting-filter-bar-wrapper .daterange-wrapper {
    top: 0px !important;
}

.loading-filters {
    padding: 5px 5px 5px 15px;
}

.loading-filters .content-placeholder {
    display: inline-block;
    margin: 0px 2px;
}

.tag-input-ctn .filter-element.filter-element-daterange {
    padding: 0px !important;
}

.input-tag.no-padding {
    padding: 0px;
}

.tag-input-ctn {
    padding: 2px 9px 1px 9px;
    margin-bottom: 9px;
    display: table;
    width: 100%;
    /*
      border-radius: 3px;
      border: 1px solid #ccc;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
    */
}
.tag-input-ctn .tag-main-container {
    display: inline-block;
}

.tag-input-ctn .tag-text {
    color: #fff;
    padding: 0px 6px 0px 0px;
    display: table-cell;
    white-space: nowrap;
    vertical-align: middle;
}

.tag-input-ctn input {
    display: table-cell;
    height: 20px;
    width: 100% !important;
    padding: 0px;
    font-size: 13px;
    line-height: 18px;
    vertical-align: top;
    color: black;
    border: 0px;
    margin: 2px;
}

.tag-input-active.tag-input-ctn input {
    height: 19px;
}


.tag-input-ctn input:focus {
    outline: 0;
    box-shadow: 0px;
}

.tag-input-ctn .input-tag {
    display: table-cell;
    vertical-align: top;
    padding-right: 2px;
}

.tag-input-ctn .input-tag.unapplied {
    opacity: 0.74;
    -moz-opacity: 0.74;
    -webkit-opacity: 0.74;
}

.tag-input-ctn .input-tag.no-padding {
    padding: 0px;
}

.tag-input-ctn .input-tag:first-child {
    padding-left: 0px !important;
}

.tag-input-ctn .input-tag:last-child {
    padding-right: 0px !important;
}

.input-tag-wrapper {
    display: table-cell;
    width: auto;
    padding-right: 0px;
}

.filter-overflow .tag-input-active .search_box {
    width: 100% !important;
}

.filter-overflow .tag-input-active .filter-element-tags {
    display: inline;
    padding: 0px;
}

.input-tag-wrapper.no-padding {
    padding-right: 0px;
}

.tag-input-ctn .input-tag .input-tag-inner {
    display: table-cell;
    padding: 1px 0px 0px 8px;
    height: 29px;
    line-height: 12px;
    font-size: 12px;
    background-color: rgba(83,137,176,1);
    vertical-align: middle;
    border-radius: 2px;
    border: 1px solid #447497;
}

.tag-input-ctn .input-tag .input-tag-inner.filter-value-select {
    padding: 0px 0px 0px 8px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    -moz-border-bottom-right-radius: 0px;
    -moz-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-top-right-radius: 0px;
}

.tag-input-ctn .delete-tag {
    display: table-cell;
    font-size: 15px;
    cursor: pointer;
    padding: 0px 6px 0px 5px;
    color: #fff;
    height: 29px;
    vertical-align: middle;
    font-weight: bold;
    /*
        background-color: rgba(153,184,209,1);
    */
}

.tag-input-ctn .delete-tag:hover {
    background-color: #96b4d2;
}

.apply-filters {
    vertical-align: top;
    width: 107px;
}

.apply-filters-btn {
    white-space: nowrap;
    padding: 5px 12px;
    height: 22px;
    margin: 0px 7px;
}

.apply-filters-btn.disabled:hover {
    color: #fff;
}

.search_box .ss-loading {
    /*
        display: inline-block;
        position: relative;
        width: 15px;
        height: 15px;
    */
    color: #9a9a9a !important;
    animation: spinner 0.6s linear infinite;
}

.search_box.search_box_report_filters {
    border: 1px solid #cccccc;
    line-height: 1.3;
    width: 100%;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    display: table-cell;
    border-radius: 3px;
    float: none;
    height: 25px;
    margin: 0px;
    vertical-align: top;
    padding: 2px 3px;
}

.search_box.search_box_report_filters.disabled {
    background-color: #f1f1f1;
}

.search_box.search_box_report_filters.disabled input {
    height: 0px;
}

.search_box.search_box_report_filters input.left-padding {
    padding-left: 28px;
}

.search_box.search_box_report_filters.filter-value-select {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-border-bottom-left-radius: 0px;
    -moz-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-top-left-radius: 0px;
}

.search_box .search-icon {
    /*    display: table-cell;
    */
    position: absolute;
    padding: 6px 0px 0px 6px;
    color: #ccc;
    float: none;
    width: 20px;
    vertical-align: top;
    margin: 0px 6px 0px 3px;
}

.search_box.search_box_report_filters {
    border: 1px solid #cccccc;
    line-height: 1.3;
    width: 100%;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    display: table-cell;
    border-radius: 3px;
    float: none;
    height: 25px;
    margin: 0px;
    vertical-align: top;
    padding: 2px 3px;
}

.search_box.search_box_report_filters.disabled {
    background-color: #f1f1f1;
}

.search_box.search_box_report_filters.disabled input {
    height: 0px;
}

.search_box.search_box_report_filters input.left-padding {
    padding-left: 28px;
}

.search_box.search_box_report_filters.filter-value-select {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-border-bottom-left-radius: 0px;
    -moz-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-top-left-radius: 0px;
}

.search_box .search-icon {
    /*    display: table-cell;
    */
    position: absolute;
    padding: 6px 0px 0px 6px;
    color: #ccc;
    float: none;
    width: 20px;
    vertical-align: top;
    margin: 0px 6px 0px 3px;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.job-costings-scrollwrapper_reporting {
    padding: 0px;
}

.reporting_wrapper.sticky .reporting-filter-bar-wrapper .reporting-filter-bar {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    box-shadow: 1px 1px 18px #9a9a9a;
    -moz-box-shadow: 1px 1px 18px #9a9a9a;
    -webkit-box-shadow: 1px 1px 18px #9a9a9a;
    border-bottom: 1px solid #c9c9c9;
    position: fixed;
    top: 114px;
    z-index: 2;
    left: 34px;
    /*
      right: 17px;
    */
    right: 0px;
    display: block;
    height: 50px;
    width: auto;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs .active {
    border-bottom: 1px solid #ddd;
}

.reporting_wrapper .content-under-header {
    padding-top: 35px;
}

.report-leftnav-all-tire.open.sticky h4 {
    height: auto;
}

.report-leftnav-all-tire.sticky.open h4 {
    padding: 13px 40px 3px 70px;
}

.reporting_main_wrapper .ui-autocomplete {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index:1000 !important;
}

.reporting_main_wrapper.reporting_mode_sticky .ui-corner-all.ui-autocomplete {
    margin-top: 1px !important;
}
.ui-autocomplete.ui-menu .ui-menu-item a .faux-checkbox {
    color: #808080 !important;
}
.ui-autocomplete.ui-menu {
    min-width: 120px;
}
.reporting_main_wrapper .checkbox-container.cc-con {
    margin-top:0;
    margin-bottom: 0;
}
.reporting_main_wrapper .report_users_driver_trips_wrapper .daterange-wrapper.week,
.reporting_main_wrapper .report_users_driver_detail_wrapper .daterange-wrapper.week {
    width: 552px;
}
.reporting_main_wrapper .report_users_driver_trips_wrapper .daterange-wrapper.week .daterange-content,
.reporting_main_wrapper .report_users_driver_detail_wrapper .daterange-wrapper.week .daterange-content {
    width: 351px;
}
.reporting_main_wrapper .report_users_driver_trips_wrapper .calendar-wrapper,
.reporting_main_wrapper .report_users_driver_detail_wrapper .calendar-wrapper {
    width: 320px;
}
.reporting_main_wrapper .report_users_driver_trips_wrapper .custom-datepicker,
.reporting_main_wrapper .report_users_driver_detail_wrapper .custom-datepicker {
    width: 310px;
}
/*
.reporting_main_wrapper .reporting-operational-list .quick-links-wrap {
    display: block;
}*/

