ul.wysihtml5-toolbar {
	margin: 0;
	padding: 0;
	display: block;
}

ul.wysihtml5-toolbar::after {
	clear: both;
	display: table;
	content: "";
}

ul.wysihtml5-toolbar > li {
	float: left;
	display: list-item;
	list-style: none;
	margin: 0 5px 10px 0;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=bold] {
	font-weight: bold;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=italic] {
	font-style: italic;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=underline] {
	text-decoration: underline;
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
	background-image: none;
	-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
	background-color: #E6E6E6;
	background-color: #D9D9D9 9;
	outline: 0;
}

ul.wysihtml5-commands-disabled .dropdown-menu {
	display: none !important;
}
