#dropzone {
	margin:0 0 12px 0;
}

.dropzone:hover {
	background:rgba(0,0,0,0.05);  
}	

.dropzone .default.message {
	background:none;
	width: 386px;  
  height: 34px;  
  margin-left: -193px;  
	margin-top: -61.5px;  
	top:64%;
	left:50%;
}

.dropzone .default.message:after {
	font-size:34px;
	content:'Click here to upload a file';
}

/*.dropzone img {
	width:200px !important;
	height:200px !important;
}*/