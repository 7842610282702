        .slide-right {
            -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
            /* easeOutQuad */
            -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
            /* easeOutQuad */
        }
            /* line 165, ../sass/ng-animation.scss */
        .slide-right.ng-enter {
            transform: translateX(700px);
            -ms-transform: translateX(700px);
            -webkit-transform: translateX(700px);
            transition-duration: 250ms;
            -webkit-transition-duration: 250ms;
            opacity: 0;
        }
            /* line 171, ../sass/ng-animation.scss */
        .slide-right.ng-enter-active {
            transform: translateX(0px);
            -ms-transform: translateX(0px);
            -webkit-transform: translateX(0px);
            opacity: 1;
        }
            /* line 176, ../sass/ng-animation.scss */
        .slide-right.ng-leave {
            transform: translateX(0px);
            -ms-transform: translateX(0px);
            -webkit-transform: translateX(0px);
            transition-duration: 250ms;
            -webkit-transition-duration: 250ms;
            opacity: 1;
        }
            /* line 182, ../sass/ng-animation.scss */
        .slide-right.ng-leave-active {
            transform: translateX(700px);
            -ms-transform: translateX(700px);
            -webkit-transform: translateX(700px);
            opacity: 0;
        }

        .animate-enter, .animate-leave
        {
            -webkit-transition: all cubic-bezier(0.250, 0.250, 0.750, 0.750) 0.3s;
            -moz-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            -ms-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            -o-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
        }

        .animate-enter {
            opacity: 0;
        }
        .animate-enter.animate-enter-active {
            opacity: 1;
        }

        .animate-leave {
            opacity: 1;
        }
        .animate-leave.animate-leave-active{
            opacity: 0;
        }
        /* AngularJS 1.2 Animation changes */
        .ltor.ng-enter, .ltor.ng-leave
        {
            position: absolute;
            -webkit-transition: 5000ms linear all;
            -moz-transition: 300ms ease-in all;
            -ms-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            -o-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            transition: 100ms linear all;
        }
        .ltor.ng-enter {
            position: absolute;
            left: 100%;
        }

        .ltor.ng-enter.ng-enter-active {
            position: absolute;
            left: 0;
        }
        .ltor.ng-leave {
            position: absolute;
            left: 5;
        }
        .ltor.ng-leave.ng-leave-active {
            position: absolute;
            left: -100%;
        }
        .animate.ng-enter, .animate.ng-leave
        {
            -webkit-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            -moz-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            -ms-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            -o-transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
            transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
        }

        .animate.ng-enter {
            opacity: 0;
        }
        .animate.ng-enter.ng-enter-active {
            opacity: 1;
        }

        .animate.ng-leave {
            opacity: 0;
        }
        .animate.ng-leave.ng-leave-active{
            opacity: 0;
        }
