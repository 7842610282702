div[section-element] .field span {
    word-break: break-word;
}

.dynamic-assets-container,
.dynamic-assets-container .import_options_wrapper {
    min-height: calc(100vh - 320px);
}
.dynamic-assets-container .no-data-found {
    min-height: calc(100vh - 570px);
}
.dynamic-field-list-container form > fieldset {
    padding-bottom: 0;
}

.dynamic-assets-list .import-option > span {
    line-height: 20px;
}
.import_options_wrapper .dynamic-assets-list ul li a {
    margin-top: 0;
    margin-bottom: 0;
}

.dynamic-view-container ul.dropdown-menu {
    width: 270px;
}
.dynamic-view-container ul.dropdown-menu,
.dynamic-view-container ul.dropdown-menu li {
    padding: 0;
    border: none;
}
.dynamic-view-container ul.dropdown-menu li a {
    padding:0 !important;
}
.dynamic-view-container .custom-datepicker table thead th {
    color: #7d8a92;
    font-size: 12px;
}
.dynamic-view-container .custom-datepicker table thead th > a {
    line-height: 32px;
}
.dynamic-view-container .custom-datepicker .btn {
    background-color: #fff;
    color: #3f6884;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.dynamic-view-container .custom-datepicker .btn.btn-info {
    background-color: #49afcd;
    color: #fff;
}
.dynamic-view-container .custom-datepicker table tbody td .muted {
    color: #999999;
}
.dynamic-view-container .input-append.datepicker-append {
    width: 62%;
}

.dynamic-view-container .input-append.datepicker-append > div {
    display: inline-block;;
    width: 100%;
}

.dynamic-view-container .debug-mode .control-label {
    position: relative;
}
.dynamic-view-container .debug-mode .debug {
    position: absolute;
    top: -12px;
    right: 0;
    font-size: 10px;
    white-space: nowrap;
}
.dynamic-view-container .label.label-gray {
    background: #f8f8f8!important;
    border: 1px solid #c9c9c9;
    margin-right: 3px;
    margin-top: 3px;
    color: #3883c1;
}
.dynamic-view-container .label.label-gray:hover {
    opacity: .65;
}

.d-form-view.d-form-view-section {
    margin-bottom: 20px;
}
.input_number_element_control .input_number > input{
    width: 50%;
}
.input_number_element_control .input_number > .select2-container {
    width: 49%;
    min-width: 49%;
    height: 30px;
}
.d-form-view.d-form-view-section .help-block{
    margin-top: 5px !important;
}

.asset_tree_table_view table.asset_tree_table tbody tr {
    border-bottom-width: 0;
}
.asset_tree_table_view table tbody tr:hover {
    background: none;
}

.asset_tree_table_view table.asset_tree_table tbody tr td {
    /*border-top: 1px solid #ddd;*/
    border-bottom: 1px solid #ddd;
}
.asset_tree_table_view table.asset_tree_table tbody tr.child-view-tr td {
    border-top-width: 0;
}
.asset_tree_table_view table.asset_tree_table tbody tr.level-2 td.add-action-row {
    border-top: 1px solid #ddd;
}

.asset_tree_table_view table.asset_tree_table tbody tr:last-child td,
.asset_tree_table_view table.asset_tree_table tbody tr.last td{
    border-bottom: 1px solid #ddd;
}
.asset_tree_table_view table.asset_tree_table th {
    white-space: nowrap;
}
.asset_tree_table_view table.asset_tree_table th:first-child {
    padding-left: 45px;
}
.asset_tree_table_view table.asset_tree_table th.actions-column {
    width: 80px;
    min-width: 80px;
}
.asset_tree_table_view table.asset_tree_table th.actions-column,
.asset_tree_table_view table.asset_tree_table .actions-column-content {
    text-align: right;
}
.asset_tree_table_view table.asset_tree_table .actions-column-content > a {
    padding: 3px;
}
.asset_tree_table_view table.asset_tree_table th.toggle-column {
    width: 28px;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 28px;
}
.asset_tree_table_view table.asset_tree_table tbody td.toggle-column {
    border-right: 1px solid #dddddd;
    cursor: pointer;
    text-align: center;
    border-left: 1px solid #dddddd;
    padding-right: 5px;
    padding-left: 5px;
}
.asset_tree_table_view table.asset_tree_table tbody td.toggle-column.expanded,
.asset_tree_table_view table.asset_tree_table tbody td.toggle-column.collapsed{
    padding-bottom: 0;
}
.asset_tree_table_view table.asset_tree_table td.toggle-column {
    position: relative;
}
.asset_tree_table_view table.asset_tree_table tbody tr.child-level td.toggle-column{
    border-right-width: 0;
    border-top-width: 0;
    padding: 0;
}
.asset_tree_table_view .responsive-table {
    min-height: .01%;
    overflow-x: auto;
}
.asset_tree_table_view table.asset_tree_table .tree-dot {
    display:block;
    height: 17px;
    border-left: 2px solid #ddd;
    margin-left: 46%;
    position: absolute;
    top:0;
    height: 100%
}
.asset_tree_table_view table.asset_tree_table .tree-dot.circle {
    margin-top: -2px;
}
.asset_tree_table_view table.asset_tree_table .tree-dot.first {
    border-bottom: 2px solid #ddd;
    height:21px;
    width:19px;
    position: absolute;
    top:0;
}
.asset_tree_table_view table.asset_tree_table .tree-dot.last {
    border-left-width: 0;
}
.asset_tree_table_view table.asset_tree_table .edge-ball {
    margin-left: -10px;
}
.asset_tree_table_view table.asset_tree_table .edge-ball + span {
    padding-left: 10px;
}
.asset_tree_table_view table.asset_tree_table .fa-xs {
    font-size: 0.6em;
    /*vertical-align: top;*/
}

.asset_tree_table_view table.asset_tree_table .iconic-con {
    height: 100%;
    position: relative;
}
.asset_tree_table_view table.asset_tree_table .no-border {
    border-bottom-width: 0 !important;
}
/*.asset_tree_table_view table.asset_tree_table .iconic-con::after {*/
    /*content: " ";*/
    /*display: block;*/
    /*position: absolute;*/
    /*height: 100%;*/
    /*background: #ddd;*/
    /*width: 2px;*/
    /*left: calc(50% - 2px);*/
    /*top: calc(50% - 2px);*/
/*}*/
.dynamic-view .field-title,
.dynamic-view .field-value {
    line-height: 24px;
}
.dynamic-view .field-title {
    width: 180px;
    text-align: right;
    padding-right: 10px;
}
.dynamic-view .field-value {
    width: calc(100% - 190px);
}

.tree-grid-reponsive {
    min-height: .01%;
    overflow-x: auto;
}

.tree-grid [class*=" icon_"], .tree-grid [class^="icon_"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    line-height: 14px;
    vertical-align: top;
    margin-top: 1px;
}

.tree-grid .icon_wrap {
    color: #505b65;
}

/*
.tree-grid-animate-enter,
.tree-grid-row.ng-enter {
    transition: 200ms linear all;
    position: relative;
    display: block;
    opacity: 0;
    max-height:0px;
}
.tree-grid-animate-enter.tree-grid-animate-enter-active,
.tree-grid-row.ng-enter-active{
    opacity: 1;
    max-height:37px;
}

.tree-grid-animate-leave,
.tree-grid-row.ng-leave {
    transition: 200ms linear all;
    position: relative;
    display: block;
    height:37px;
    max-height: 37px;
    opacity: 1;
}
.tree-grid-animate-leave.tree-grid-animate-leave-active,
.tree-grid-row.ng-leave-active {
    height: 0px;
    max-height:0px;
    opacity: 0;
}*/

.tree-grid .tree-grid-row {
    padding: 0px;
    margin:0px;
}

.tree-grid .first-col {
    /*padding-left: 38px;*/
}

.tree-grid .tree-grid-row .text-wrap,
.tree-grid .tree-grid-row a {
    padding: 3px 10px;
}
.tree-grid .tree-grid-row .node-view a.icon_wrap {
    position: absolute;
    left:0;
}
.tree-grid .tree-grid-row .node-view a {
    padding: 3px 0;
}
.tree-grid .tree-grid-row .node-view span.node-title {
    padding: 0 8px;
}
.tree-grid .tree-grid-row a.action-add-link {
    padding: 3px;
}
.tree-grid .tree-grid-row a.action-add-link:first-child {
    padding-left: 6px;
}
.tree-grid .tree-grid-row a.action-add-link:after {
    content: ","
}
.tree-grid .tree-grid-row a.action-add-link:last-child:after {
    content: "";
}

.tree-grid i.indented {
    padding: 2px;
}
table.tree-grid.default-style tbody td.is-dummy {
    /*padding: 5px 10px;*/
    padding: 0;
}
.tree-grid {
    cursor: default;
}
.tree-grid td[class^="td-level-"] {
    position: relative;
}
.tree-grid .pos-abt.vbar,
.tree-grid .pos-abt.hbar {
    background: #ccc;
}
.tree-grid .pos-abt.vbar {
    width: 1px;
    height: 100%;
    top: 0;
    bottom: 0;
}

.tree-grid .lastrow .pos-abt.vbar.vbar-last {
    height: 50%;
}
.tree-grid .pos-abt.hbar {
    width: 15px;
    height: 1px;
    top: calc(50% - 1px);
}
.tree-grid .pos-abt.vbar.vbar-2,
.tree-grid .pos-abt.hbar.hbar-2 {
    left: 24px;
}
.tree-grid .pos-abt.vbar.vbar-3,
.tree-grid .pos-abt.hbar.hbar-3 {
    left: 48px;
}
.tree-grid .pos-abt.vbar.vbar-4,
.tree-grid .pos-abt.hbar.hbar-4 {
    left: 72px;
}
.tree-grid .pos-abt.vbar.vbar-5,
.tree-grid .pos-abt.hbar.hbar-5 {
    left: 96px;
}
.tree-grid .pos-abt.maskbar.maskbar-4,
.tree-grid .pos-abt.maskbar.maskbar-3,
.tree-grid .pos-abt.maskbar.maskbar-2 {
    bottom:-1px;
    left:0;
    height:1px;
    background: #fff;
}
.tree-grid .pos-abt.maskbar.maskbar-2 {
    width:24px;
}
.tree-grid .lastrow.lastrow-2 .pos-abt.maskbar.maskbar-2 {
    width: 0;
}

.tree-grid .pos-abt.maskbar.maskbar-3 {
    width:48px;
}
.tree-grid .lastrow.lastrow-3 .pos-abt.maskbar.maskbar-3 {
    width: 24px;
}
.tree-grid .pos-abt.maskbar.maskbar-4 {
    width:72px;
}
.tree-grid .lastrow.lastrow-4 .pos-abt.maskbar.maskbar-4 {
    width: 48px;
}
.tree-grid tr.child-view-tr,
.tree-grid tr.tree-grid-row.level-2 {
    background: #fff !important;
}
.tree-grid tr.child-first-row > td {
    border-top: 1px solid #ddd !important;
}
.tree-grid.default-style .icon_content {
    position: relative;
    z-index: 1;
    top: -2px;
}
.tree-grid.default-style .root-no-child + .indented {
    /*left: -24px;*/
}

.tree-grid .level-1 .indented {
    position: relative;
    left: 0px;
}
.tree-grid .level-2 .indented {
    position: relative;
    left: 20px;
}
.tree-grid.default-style .level-2 .indented {
    left:0;
}
.tree-grid.default-style .level-2 .icon_content {
    left:15px;
}
.tree-grid .level-3 .indented {
    position: relative;
    left: 40px;
}
.tree-grid.default-style .level-3 .indented {
    left:15px;
}
.tree-grid.default-style .level-3 .icon_content {
    left:20px;
}
.tree-grid .level-4 .indented {
    position: relative;
    left: 60px;
}
.tree-grid.default-style .level-4 .indented {
    left:40px;
}
.tree-grid.default-style .level-4 .icon_content {
    left:20px;
}
.tree-grid .level-5 .indented {
    position: relative;
    left: 80px;
}
.tree-grid .level-6 .indented {
    position: relative;
    left: 100px;
}
.tree-grid .level-7 .indented {
    position: relative;
    left: 120px;
}
.tree-grid .level-8 .indented {
    position: relative;
    left: 140px;
}
.tree-grid .level-9 .indented {
    position: relative;
    left: 160px;
}
.font-size-normal {
    font-size: 14px;
}
.displayEditLink {
    position: absolute;
    right: 15px;
    top: 10px;
}