.placehold-loader .animated-background {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.placehold-loader .animated-background.text-layout {
    height: 12px;
}
.placehold-loader .animated-background.col-width-1 {
    width: 20%;
}
.placehold-loader .animated-background.col-width-2 {
    width: 40%;
}
.placehold-loader .animated-background.col-width-3 {
    width: 60%;
}
.placehold-loader .animated-background.col-width-4 {
    width: 80%;
}
.placehold-loader .animated-background.col-width-5 {
    width: 90%;
}

.text-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;
}
.styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0!important;
}
.styled-checkbox + label:before {
    content: '';
    /*margin-right: 10px;*/
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: white;
    border: 1px solid #ccc;
}
.styled-checkbox:checked + label:before {
    background: #fff;
}
.styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto;
    margin-bottom: 0;
}
.styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd;
}
.styled-checkbox:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 9px;
    background: #333;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #333, 4px 0 0 #333, 4px -2px 0 #333, 4px -4px 0 #333, 4px -6px 0 #333, 4px -8px 0 #333;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.no-top-padding {
    padding-top: 0 !important;
}

.font-sm {
    font-size: 8px !important;
}
.inline-block {
    display: inline-block !important;
}
.text-align-right {
    text-align: right !important;
}
.text-align-center {
    text-align: center !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.cursor-not-allowed{
    cursor: not-allowed !important;
}
.block-disable{
    pointer-events: none;
}
.pad10{
    padding-left: 10px;
}
.margin-top-10 {
    margin-top: 10px;
}
.m-b-0 {
    margin-bottom:0!important;
}
.m-t-0 {
    margin-top: 0 !important;
}
.m-b-sm {
    margin-bottom: 10px !important;
}
.m-b-md {
    margin-bottom: 15px !important;
}
.p-t-md {
    padding-top: 15px !important;
}
.no-padding {
    padding:0 !important;
}
.no-wrap {
    white-space: nowrap !important;
}
.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}
.control-input-full-width {
    width: 100%;
}
.control-input-full-width.box {
    box-sizing: border-box;
    height: 30px;
    line-height: 30px;
}
.cs-dynamic-form-container .no-event {
    pointer-events: none;
}
.cs-dynamic-form-container .open-controls {
    font-size: 12px;
    margin-right: 5px;
}
.cs-dynamic-form-container .data-section.with-small-title h3,
.cs-dynamic-form-container .data-section.with-small-title .section-title {
    float: unset;
}
.cs-dynamic-form-container .data-section.with-small-title h3{
    display:inline-block;
}
.cs-dynamic-form-container .data-section.with-small-title .section-title .label.label-default {
    background-color: #999999;
    color: #fff;
}
.cs-dynamic-form-container .el-mask.el-mask-light {
    background-color: rgba(255,255,255,.4);
    cursor: not-allowed;
}
.cs-dynamic-form-container .el-mask.el-mask-dark {
    background-color: rgba(0, 0, 0, 0.5);
}

.cs-dynamic-form-container .el-mask > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.label.label-default.light-gray {
    background-color: #f1f1f1 !important;
    color: #999 !important;
}
.commusoft_scroll_wrapper.dynamic-form-wrapper {
    padding-bottom: 20px !important;
}
fieldset.df-section-container {
    padding-bottom: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-bottom: 10px!important;
}
fieldset.df-section-container:first-child {
    border-top-width: 0;
}
fieldset.df-section-container.assets {
    border: 1px solid #dadada !important;
    background: #f1f1f1;
}
fieldset.df-section-container.assets section.data-section {
    background: #f7f7f7;
}
fieldset.df-section-container .section-header {
    margin-bottom: 0;
}
fieldset.df-section-container .section-header.has-deleted-asset {

    background: repeating-linear-gradient(-45deg, rgba(210, 86, 86, 0.04), rgba(210, 86, 86, 0.04) 10px, transparent 10px, transparent 20px);
}

fieldset.df-section-container .section-body {
    padding:12px;
}
fieldset.df-section-container .section-body.dnd-dropover-parent {
    height: 200px
}
fieldset.df-section-container .section-body .draggable-element-item-container {
    min-height: 90px;
}
fieldset.df-section-container .section-body .draggable-element-item-container.no-children {
    min-height: 200px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
fieldset.df-section-container .section-header-title {
    display: inline-block;
    width: 70%;
}
.draggable-element-item-container .item-element-column {
    border-style: dashed;
    background: #f1f1f1;
}
.draggable-element-item-container .item-element-column .btn.btn-small {
    padding: 4px 2px;
}
.draggable-element-item-container .item-element-column .col-field {
    border: 1px dotted #999;
    padding: 3px;
}
.draggable-element-item-container .item-element-column .data-section:last-child {
    margin-bottom: 0 !important;
}

.draggable-element-item-container .item-element-column .row-fluid {
    padding:0;
}
.df-section-container .section-header-title.input > * {
    float: left;
    margin-right: 5px;
}
.df-section-container .element-update-action {
    font-size: 14px;
    text-decoration: underline;
    font-weight: bold;
}
.df-section-container .element-update-action.section {
    font-size: 16px;
}
.df-section-container .section-header-title.input > input {
    width: 78%;
}
.df-section-container .section-header-title.text span {
    cursor: pointer;
}
.df-section-container .section-header-title.text span.disabled {
    cursor: default;
    text-decoration: none;
    opacity: .65
}
.df-section-container .section-header-title input {
    margin: 0;
    font-size: 18px;
}
.df-section-container .section-header-title.pos-abt {
    top:8px;
}

.cs-dynamic-form-container > fieldset {
    padding-bottom: 0;
}
.cs-dynamic-form-container .section-header .pos-abt.draggable-items-container.section-btns {
    top: 7px;
    right: 12px;
}
.cs-dynamic-form-container .customise_container.row-fluid {
    padding: 0;
}

.cs-dynamic-form-container .search_box{
    float: none;
}
.cs-dynamic-form-container .elements-filters {
    background-color: #eeeeee;
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
}
.cs-dynamic-form-container .ma3.no-field-wrapper {
    /*margin: 50px 0;*/
    margin-top: 0px;
}
.cs-dynamic-form-container .ma3.pos-abt {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 0;
}

.cs-dynamic-form-modal .select2-container .select2-choices > .select2-search-field > input {
    margin-top: -8px;
}

.cs-dynamic-form-modal .no-field-wrapper .cs-no-field-content,
.cs-dynamic-form-container .no-field-wrapper .cs-no-field-content {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 0px;
}
.cs-dynamic-form-modal .no-field-wrapper .cs-no-field-content > .info,
.cs-dynamic-form-container .no-field-wrapper .cs-no-field-content > .info {
    border: 7px solid #bccfd6;
    border-radius: 55px;
    padding: 20px;
    font-size: 36px;
    margin: 0 auto 10px;
    width: 30px;
    height: 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: content-box;
    color: #bccfd6;
}
.cs-dynamic-form-add_items-modal .modal-body > .row-fluid {
    padding: 0 0 0 14px;
}
.cs-dynamic-form-modal hr {
    margin: 10px 0;
}
.cs-dynamic-form-add_items-modal .modal-body {
    height: 250px;
    overflow-y: auto;
}
.cs-dynamic-form-modal .no-field-wrapper .cs-no-field-content > .info > span,
.cs-dynamic-form-container .no-field-wrapper .cs-no-field-content > .info > span {
    margin: 0 auto;
    position: relative;
    top: 5px;
    left: -3px;
    color: #bccfd6;
}
.cs-dynamic-form-modal.cs-dynamic-form-add_default-modal .text-default .control-input {
    width: 305px;
}
.cs-dynamic-form-modal.cs-dynamic-form-add_default-modal .text-default-nocontent,
.cs-dynamic-form-modal.cs-dynamic-form-add_default-modal .text-default-content
{
    margin-left: 5px;
}
.cs-dynamic-form-modal .control-group .control-label {
    margin-top: 4px;
    margin-right: 10px;
    width: 140px;
}
.cs-dynamic-form-modal.cs-dynamic-form-add_items-modal .control-group .control-label {
    width: 30px;
}

.cs-dynamic-form-modal .control-group .controls .control-input {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 30px;
}
.cs-dynamic-form-modal .control-group .controls textarea.control-input {
    height: 90px;
}

.cs-dynamic-form-modal.cs-dynamic-form-add_items-modal .control-group .controls .first{
    margin-left: 5px;
}
.cs-dynamic-form-modal .no-field-wrapper .cs-no-field-content p,
.cs-dynamic-form-container .no-field-wrapper .cs-no-field-content p {
    color: #bccfd6;
}

.elements-filters .search_box {
    margin: 0;
    width: 100%;
}
.elements-filters .search_box input {
    width: 84%;
}
.pos-rlt {
    position: relative !important;
}
.ps__rail-y{
    margin-bottom: 2px !important;
}
.ps__rail-x{
    margin-right: 2px !important;
}
.pos-abt {
    position: absolute;
}
.pos-abt-full {
    position: absolute;
    left:0; right:0; bottom:0; top:0
}
.customise_container {

}
.question-margin-top{
    margin-top:58px;
}
.draggable-container {
    background-color: #fff;
}
.asset-selected-listview .asset-item-body.pos-abt,
.job-asset-content.pos-abt,
.side-el-container.pos-abt,
.draggable-container.pos-abt{
    left:0;
    right: 0;
    top: 0;
    bottom: 0;

}

.row-fluid [class*="span"].side-el-container {
    /*min-height: 500px;*/
    background-color: #fff;
}
.cs-dynamic-form-container .row-fluid .span9 {
    width: 76.595744681%;
    margin-left: 0!important;
}
.side-el-container {
    border-right: 1px solid #d1d9dd;

}
.side-el-container.pos-abt {
    width: 270px;
    background-color: #fff;


}
.draggable-container.pos-abt {
    left:270px;
    width: auto;
    margin-left: 0;
}
.cs-dynamic-form-container .elements-list-view > div {
    margin: 0;
    padding-left:0;
}
.cs-dynamic-form-container .elements-list-view .elem-item-header > .title {
    margin-bottom: 0;
    font-size: 16px;
}
.cs-dynamic-form-container .elements-list-view .elem-item-header {
    background: #efefef;
}
.cs-dynamic-form-container .elements-list-view > div .elem-item-container,
.cs-dynamic-form-container .data-section.with-small-title {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: move;
}
.cs-dynamic-form-container .elements-list-view > div .elem-item-container {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    display: block;
    padding: 0px;
}
.cs-dynamic-form-container .elements-list-view .elem-item {
    padding: 10px 15px;
}
.elem-item .elem-title {
    margin-left: 3px;
}
.elem-title.small {
    font-size: 14px;
}
.draggable-items-container {
    padding:0px;
    min-height: 90px;
    background-color: white;
    margin-bottom: 15px;
}
.cs-dynamic-form-container .data-section {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.cs-dynamic-form-container .data-section.with-small-title .section-title {
    height: 62px;
    margin-bottom: 0;
    border-bottom-width: 0;
    background-color: transparent;
}
.cs-dynamic-form-container .item-element-column.with-small-title .section-title {
    /*height: 35px;*/
    height: auto;
}
.cs-dynamic-form-container .data-section.with-small-title .section-title h3 {
    margin-top: 8px;
}
.cs-dynamic-form-container .section-title > .elem-options,
.cs-dynamic-form-container .section-header > .section-options{
    float: right;
    margin: 0;
}
.cs-dynamic-form-container .item-element-column .section-body .section-title > .elem-options {
    float: unset;
}
.cs-dynamic-form-container .data-section.with-small-title .section-title .label {
    float:none;
    margin: 0;
    background-color: #3A6889;
    color: #fff;
}
.cs-dynamic-form-container .data-section.with-small-title .section-title .label.label-warning {
    background-color: #ffebb0;
    color: #505b65;
    font-weight: bold;
    margin-left: 5px;
}
.cs-dynamic-form-container .label.label-danger {
    background: #bd362f;
    opacity: .65;
}
.cs-dynamic-form-container .data-section.with-small-title .section-title .btn-small,
.cs-dynamic-form-container .section-header > .section-options .btn-small{
    background: transparent;
    color: #505b65;
}
.cs-dynamic-form-container .data-section .data-dependencies {
    padding-left: 50px;
    padding-right: 10px;
    position: relative;
}
.cs-dynamic-form-container .data-section .data-dependencies.no-content:after {
    content: "Drop dependencies, if you need";
    text-align: center;
    color:#bccfd6;
    position: absolute;
    top: -15px;
    left: 37%;

}
.cs-dynamic-form-container .data-section.with-small-title .section-title .btn-small:hover {
    opacity: 0.6;
}
.dndDraggingSource {
    opacity: 0.5;
    display:none;
}
ul[dnd-list] .dndDraggingSource {
    display: none;
}
div[dnd-list] .dndPlaceholder {
    display: block;
    background-color: #e2e2e2;
    padding: 10px 15px;
    min-height: 62px;
    margin-bottom: 10px;
    /*-webkit-border-radius: 6px;*/
    /*-moz-border-radius: 6px;*/
    /*border-radius: 6px;*/
    position: relative;
}
div[dnd-list] .dndPlaceholder:after {
    content: "Drop here!";
    text-align: center;
    color:#999;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -50%);
}
div[dnd-list].draggable-section > .dndPlaceholder:after {
    content: "Drop your section / asset here!" !important;
}
.dnd-dropover-parent div[dnd-list] .dndPlaceholder {
    background-color: transparent;
}

.cs-dynamic-form-modal .input_checkboxlist .span6,
.cs-dynamic-form-modal .input_dropdownlist .span6,
.cs-dynamic-form-modal .input_validatation_list .span6 {
    margin-left: 0;
    width: 50%;
}
.cs-dynamic-form-modal .input_checkboxlist .checkbox,
.cs-dynamic-form-modal .input_dropdownlist .checkbox,
.cs-dynamic-form-modal .input_validatation_list .checkbox{
    margin-top: 0;
}
.cs-dynamic-form-modal .input_checkboxlist .checkbox-label {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
}
.draggable-element-item-container.dndDragover + .ma3{
    visibility: hidden;
}
.draggable-element-item-container.col-item-container {
    /*border: 1px dotted #ccc;*/
    /*padding:2px;*/
}
.df-action-modal.sidepanel {
    left: unset;
    right: 0px;
    opacity: 1;
    -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform:translate3d(100%, 0, 0);
    visibility: hidden;
    top:0;
    margin: 0 -50px 0 0!important;
    overflow: hidden;
    border: none;
    height: 100%;
    width: 610px !important;
    background: #fff;
}
.df-action-modal.asset-widget-sidepanel.sidepanel {
    width: 980px !important;
}

.df-action-modal.sidepanel .no-left-margin {
    margin-left: 0 !important;
}
.df-action-modal.sidepanel ul.dropdown-menu {
    width: 270px;
}
.df-action-modal.sidepanel ul.dropdown-menu,
.df-action-modal.sidepanel ul.dropdown-menu li {
    padding: 0;
    border: none;
}
.df-action-modal.sidepanel ul.dropdown-menu li a {
    padding:0 !important;
}
.df-action-modal.sidepanel .custom-datepicker table thead th {
    color: #7d8a92;
    font-size: 12px;
}
.df-action-modal.sidepanel .custom-datepicker table thead th > a {
    line-height: 32px;
}
.df-action-modal.sidepanel .custom-datepicker .btn {
    background-color: #fff;
    color: #3f6884;
}
.df-action-modal.sidepanel .custom-datepicker table tbody td .muted {
    color: #999999;
}
.df-action-modal.sidepanel.in {
    visibility: visible;
    transform:translate3d(0, 0, 0);
    top:0;
}
.df-action-modal.sidepanel,
.df-action-modal.sidepanel .modal-header,
.df-action-modal.sidepanel .modal-footer{
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.df-action-modal.sidepanel .modal-header,
.df-action-modal.sidepanel .modal-body,
.df-action-modal.sidepanel .modal-footer {
    position: absolute;
    left:0; right:0;
    /*display: flex;*/
    /*flex-wrap: nowrap;*/
}
.df-action-modal.sidepanel .modal-body {
    top:43px; bottom: 58px;
    overflow: auto;
    height: auto;
    background: #f9f9f9;
    /*padding: 20px;*/
}
.select2-results .select2-disabled > .select2-result-label {
    color: #ccc;
}
.df-action-modal.sidepanel .modal-body .row-fluid {
    padding: 0;
}
.df-action-modal.sidepanel .modal-footer {
    bottom:0;
    top: unset;
}
.df-action-modal.sidepanel .modal-header,
.df-action-modal.sidepanel .modal-footer {
    background: #fff;
}
.df-action-modal.sidepanel .modal-header {
    border-bottom: 1px solid #dadada;
}
.df-action-modal.sidepanel .modal-header h3 {
    color: #505b65;
}
.df-action-modal.sidepanel .cs-dynamic-form-modal {
    position: absolute;
    top:0; bottom:0; left:0; right:0;
    width: 560px;
}
.df-action-modal.asset-widget-sidepanel.sidepanel .cs-dynamic-form-modal {
    width: 930px;
}
.df-action-modal.sidepanel .modal-header .modal-title > span {
    display: inline-block;
    vertical-align: top;
}
.df-action-modal.sidepanel .modal-header .modal-title > span.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
}

/*add option modal*/
.cs-dynamic-form-add_formulas-modal .modal-body,
.cs-dynamic-form-add_items-modal .modal-body,
.cs-dynamic-form-add_validations-modal .modal-body,
.cs-dynamic-form-dependencies-modal .modal-body,
.cs-dynamic-form-properties-modal .modal-body{
    padding:0;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input,
.cs-dynamic-form-add_formulas-modal .search-input,
.cs-dynamic-form-add_items-modal .add-input,
.cs-dynamic-form-add_items-modal .search-input,
.cs-dynamic-form-properties-modal .add-input,
.job-asset-widget-modal .search-input,
.cs-dynamic-form-properties-modal .search-input{
    background-color: #eeeeee;
    padding: 8px 10px;
    border-bottom: 1px solid #dadada;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input {
    border-bottom-width: 0;
    background-color: #fff;
    padding: 15px 20px;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input +  .add-input {
    /*padding-top: 0;*/
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies.df-options .add-input,
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input:last-child {
    border-bottom: 1px solid #dadada;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .help_section {
    margin-bottom: 0;
    border-bottom: none;
}

.cs-dynamic-form-add_formulas-modal .add-input,
.cs-dynamic-form-add_formulas-modal .search-input,
.cs-dynamic-form-add_items-modal .search-input,
.cs-dynamic-form-properties-modal .search-input{
    background-color: #f9f9f9;
    padding: 3px 10px;
}
.cs-dynamic-form-add_formulas-modal .add-item-form.extra-padd {
    padding: 10px 15px 0 0
}
.cs-dynamic-form-properties-modal .search-input {
    padding: 8px 10px;
}
.job-asset-widget-modal .search-input .ss-search,
.cs-dynamic-form-add_formulas-modal .search-input .ss-search,
.cs-dynamic-form-add_items-modal .search-input .ss-search {
    margin:0;
    float: none;
    display: inline-block;
}
.cs-dynamic-form-add_formulas-modal .search-input .ss-search:before,
.cs-dynamic-form-add_items-modal .search-input .ss-search:before {
    padding-top: 10px;
    display: inline-block;
    line-height: 16px;
}
.job-asset-widget-modal .search-input input[type="text"],
.cs-dynamic-form-add_formulas-modal .search-input input[type="text"],
.cs-dynamic-form-add_items-modal .search-input input[type="text"] {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    width: 93%;
    margin-bottom: 0;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input .select2-container,
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input select,
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input input[type="text"],
.cs-dynamic-form-add_formulas-modal .add-input select,
.cs-dynamic-form-add_items-modal .add-input input[type="text"],
.cs-dynamic-form-add_items-modal .add-input select {
    margin-bottom: 0;
    box-sizing: border-box;
    height: 30px;
    width: 100%;
}
.cs-dynamic-form-add_items-modal .add-input input[type="text"].with-add-action {
    width: calc(100% - 58px);
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies:not(.cs-quick-filters) .add-input input[type="text"] {
    width: 89%;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies.df-options .add-input input[type="text"] {
    width: 100%;
}
.cs-dynamic-form-properties-modal ul.list-item-view.elem-type-image select,
.cs-dynamic-form-properties-modal ul.list-item-view.elem-type-image input[type="text"] {
    width: 80px;
}
.cs-dynamic-form-properties-modal ul.list-item-view.elem-type-image select {
    margin-left: 5px;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies.cs-quick-filters .input-item-inline {
    padding: 4px 0;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies .add-input .input-item-inline > input[type="text"],
.cs-dynamic-form-dependencies-modal.cs-value-dependencies.cs-quick-filters .input-item-inline > * {
    display: inline-block;
    width: auto;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies.cs-quick-filters .input-item-inline > a {
    padding: 0 8px;
}
.cs-dynamic-form-dependencies-modal.cs-value-dependencies.cs-quick-filters .input-item-inline > a + a {
    padding-left: 0;
}

.asset-selected-listview ul.list-item-view,
.cs-dynamic-form-add_formulas-modal ul.list-item-view,
.cs-dynamic-form-add_items-modal ul.list-item-view,
.cs-dynamic-form-properties-modal ul.list-item-view {
    margin: 0;
    padding: 0;
    list-style: none;
}
.cs-dynamic-form-add_formulas-modal ul.list-item-view li,
.cs-dynamic-form-add_items-modal ul.list-item-view li,
.cs-dynamic-form-properties-modal ul.list-item-view li {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}
.asset-selected-listview ul.list-item-view li,
.cs-dynamic-form-add_formulas-modal ul.list-item-view li,
.cs-dynamic-form-add_items-modal ul.list-item-view li,
.cs-dynamic-form-properties-modal ul.list-item-view li {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    display: block;
    padding: 0px;
}
/*.cs-dynamic-form-add_formulas-modal ul.list-item-view li {*/
/*margin-bottom: 10px;*/
/*border-top: 1px solid #ddd;*/
/*}*/
/*.cs-dynamic-form-add_formulas-modal ul.list-item-view li:first-child {*/
/*border-top-width: 0;*/
/*}*/
.asset-selected-listview .no-item,
.job-asset-content .no-item,
.cs-dynamic-form-add_formulas-modal ul.list-item-view li.no-item,
.cs-dynamic-form-add_items-modal ul.list-item-view li.no-item,
.cs-dynamic-form-properties-modal ul.list-item-view li.on-item {
    background-color: transparent;
    border: none;
    margin-top:50px;
}
.asset-selected-listview ul.list-item-view li .item,
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .item,
.cs-dynamic-form-add_items-modal ul.list-item-view li .item,
.cs-dynamic-form-properties-modal ul.list-item-view li .item{
    padding: 7px 15px;
}

.cs-dynamic-form-add_formulas-modal ul.list-item-view li input[type="text"],
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .control-group select,
.cs-dynamic-form-add_items-modal ul.list-item-view li .item input[type="text"],
.cs-dynamic-form-properties-modal ul.list-item-view li .item input[type="text"],
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .item .control-group,
.cs-dynamic-form-properties-modal ul.list-item-view li .item .control-group,
.cs-dynamic-form-properties-modal ul.list-item-view li .item .control-group select{
    margin-bottom: 0;
}
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .control-group {
    padding: 0 10px 10px 10px;
    background: #f1f1f1;
    margin-bottom: 0;
}
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .btn {
    height: 30px;
}
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .control-group.first {
    border-top: 1px solid #ddd;
    padding-top:10px !important;
}
.cs-dynamic-form-add_formulas-modal ul.list-item-view li .first-input {
    margin-right: 5px;
}
.cs-dynamic-form-add_formulas-modal .control-input-custom-value{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
}

.cs-dynamic-form-add_items-modal ul.list-item-view li .item .item-actions.action-save {
    margin-top: 5px;
}
.cs-dynamic-form-add_items-modal ul.list-item-view li .item .item-actions a {
    margin-right: 10px;
}
.cs-dynamic-form-add_items-modal ul.list-item-view li .item .item-actions a:last-child {
    margin-right: 0;
}
.cs-dynamic-form-dependencies-modal .help-text-container.row-fluid,
.cs-dynamic-form-add_validations-modal .help-text-container.row-fluid {
    padding: 0 15px;
}
.cs-dynamic-form-dependencies-modal .help-text-container .help-text,
.cs-dynamic-form-add_validations-modal .help-text-container .help-text {
    padding-top: 10px;
}
.cs-dynamic-form-dependencies-modal .help-text-container,
.cs-dynamic-form-dependencies-modal .validation_list .control,
.cs-dynamic-form-add_validations-modal .help-text-container,
.cs-dynamic-form-add_validations-modal .validation_list .control{
    border-bottom: 1px solid #dadada;
}
.cs-dynamic-form-dependencies-modal .validation_list > .item,
.cs-dynamic-form-add_validations-modal .validation_list > .item {
    position: relative;
}
.cs-dynamic-form-dependencies-modal .item .item-line,
.cs-dynamic-form-add_validations-modal .item .item-line {
    text-align: center;
}
.cs-dynamic-form-add_formulas-modal .item-line {
    background: #F9F9F9;
}
.cs-dynamic-form-dependencies-modal .item .item-line > .bar,
.cs-dynamic-form-dependencies-modal .validation_list .btn-add-section .item-line > .bar,
.cs-dynamic-form-add_validations-modal .item .item-line > .bar,
.cs-dynamic-form-add_validations-modal .validation_list .btn-add-section .item-line > .bar {
    width: 10px; height: 16px;
    background-color: #E2E2E2;
    display: block;
    margin: 0 auto;
    position: static;
    border-color: transparent;
    background-image: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.cs-dynamic-form-dependencies-modal .validation_list .control,
.cs-dynamic-form-add_validations-modal .validation_list .control{
    background-color: #fff;
    padding: 15px 20px;
    border-top: 1px solid #dadada;
}
.cs-dynamic-form-dependencies-modal .validation_list .control.first,
.cs-dynamic-form-add_validations-modal .validation_list .control.first {
    border-top-width: 0;
}
.cs-dynamic-form-dependencies-modal .validation_list .btn-add-section,
.cs-dynamic-form-add_validations-modal .validation_list .btn-add-section {
    display: block;
    margin-bottom: 15px;
}
.cs-dynamic-form-dependencies-modal .validation_list .btn-add-section .item-line > .bar,
.cs-dynamic-form-add_validations-modal .validation_list .btn-add-section .item-line > .bar{
    height: 4px;
}
.cs-dynamic-form-dependencies-modal .validation_list .btn-add-section .item-line > .plus_circle,
.cs-dynamic-form-add_validations-modal .validation_list .btn-add-section .item-line > .plus_circle {
    width: 32px; height: 32px;
    margin: 0 auto;
    background-color: #DBDBDB;
    border: 1px solid #C8C8C8;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    line-height: 36px;
    color: #C8C8C8;
    text-align: center;
    cursor: pointer;
}
.cs-dynamic-form-dependencies-modal .validation_list .btn-add-section .item-line > .plus_circle:hover,
.cs-dynamic-form-add_validations-modal .validation_list .btn-add-section .item-line > .plus_circle:hover {
    color: #b2b2b2;
}

.cs-dynamic-form-add_validations-modal .validation_list .control select {
    width: 100%;
}
.cs-dynamic-form-dependencies-modal .validation_list .control .question-value-container {
    margin-top: 8px;
}
.cs-dynamic-form-dependencies-modal .validation_list .control select,
.cs-dynamic-form-dependencies-modal .validation_list .control .select2-container,
.cs-dynamic-form-dependencies-modal .validation_list .control .question-value-container > .select2-container,
.cs-dynamic-form-dependencies-modal .validation_list .control > .select2-container {
    display: block;

}
/*.cs-dynamic-form-dependencies-modal.cs-value-dependencies .validation_list .control .select2-container {*/
/*display: inline-block;*/
/*width: 85%;*/
/*}*/

.df-pane-drag {
    position: absolute;
    transform: translate3d(-100%, 0, 0);

}
.df-pane-drag .drag-item-element {
    /*transform: translate3d(0, 0, 0);*/
    background-color: #477596;
    padding: 10px;
    color: white;
}

/*computation ruler*/
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group.ruler-group-field {
    margin: 0 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group.ruler-group-field:first-child {
    margin-top:10px;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group.ruler-group-field.last {
    margin-bottom: 20px;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group li {
    background: transparent;
}

.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group li select,
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group.ruler-group-operator select{
    margin-bottom: 0;
    font-size: 20px;
    width: 50px;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group li select.field-question-select,
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group.ruler-group-operator select.field-question-select{
    width:100%;
    font-size: 14px;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .field-question-operator,
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group.ruler-group-operator{
    text-align: center;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group li:last-child {
    border-bottom-width: 0 !important;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .item-line {
    text-align: center;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .item-line {
    background: #F9F9F9;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .item-line > .bar,
.cs-dynamic-form-add_formulas-modal .list-item-view-container .item-line > .bar{
    width: 10px; height: 10px;
    background-color: #E2E2E2;
    display: block;
    margin: 0 auto;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group > .group-actions {
    padding: 6px 15px 6px 0;
    border-bottom: 1px solid #ddd;
}
.cs-dynamic-form-add_formulas-modal .list-item-view-container .ruler-group > .group-actions .btn-xs {
    font-size: 12px !important;
    padding: 0 5px !important;
}
.cs-dynamic-form-add_formulas-modal ul.list-item-view li.field-question.last {
    border-bottom-width: 0 !important;
}

.df-action-add_items-modal.add_group_items .group-title {
    font-weight: bold;
}
.df-action-add_items-modal.add_group_items .item.item-nest {
    padding:0 !important;
}
.df-action-add_items-modal.add_group_items .item.item-nest .add-input > input {
    width: 88%;
}
.df-action-add_items-modal.add_group_items .item.item-nest .add-input {
    background: #f9f9f9;
    border-top: 1px solid #dadada;
}
.df-action-add_items-modal.add_group_items .list-item-sub-view .sub-item {
    padding-left: 20px;
}
.df-action-add_items-modal.add_group_items .list-item-sub-view .sub-item.odd {
    background: #f5f5f5;
}

.cs-dynamic-form-add_default-modal .modal-body,
.cs-dynamic-form-set_formatter-modal .modal-body,
.cs-dynamic-form-element-add-modal .modal-body{
    padding: 10px 20px 30px 20px !important;
}

.dynamic-field-list-container .sec-title {
    margin-bottom: 0;
}

.custom-fields-list-view .breadcrumbs{
    --cssuiBreadcrumbsTextColor: var(--breadcrumbsTextColor);
    --cssuiBreadcrumbsTextColorActive: var(--breadcrumbsTextColorActive);
    --cssuiBreadcrumbsDivider: var(--breadcrumbsDivider);
    --cssuiBreadcrumbsDividerColor: var(--breadcrumbsDividerColor, inherit);
    --cssuiBreadcrumbsDividerSize: var(--breadcrumbsDividerSize, 16px);
    --cssuiBreadcrumbsIndent:  var(--breadcrumbsIndent, 8px);
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.custom-fields-list-view .breadcrumbs__item{
    display: inline-block;
    position: relative;
    padding-right: calc(var(--cssuiBreadcrumbsDividerSize) + var(--cssuiBreadcrumbsIndent));
    margin-right: var(--cssuiBreadcrumbsIndent);
    /*color: var(--cssuiBreadcrumbsTextColor);*/
}

.custom-fields-list-view .breadcrumbs__item:after{
    content: var(--cssuiBreadcrumbsDivider);
    width: var(--cssuiBreadcrumbsDividerSize);
    height: var(--cssuiBreadcrumbsDividerSize);
    line-height: 1;
    text-align: center;
    font-size: var(--cssuiBreadcrumbsDividerSize);
    color: var(--cssuiBreadcrumbsDividerColor);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.custom-fields-list-view .breadcrumbs__item_active{
    margin-right: 0;
    padding-right: 0;
    /*color: var(--cssuiBreadcrumbsTextColorActive);*/
}

.custom-fields-list-view .breadcrumbs__item_active:after{
    display: none;
}
.custom-fields-list-view .breadcrumbs__element{
    color: inherit;
}


.custom-fields-list-view .breadcrumbs{
    --breadcrumbsTextColor: #D1C4E9;
    --breadcrumbsTextColorActive: #fff;
}

.custom-fields-list-view .breadcrumbs_type1{
    --breadcrumbsDivider: "/";
}

.custom-fields-list-view .breadcrumbs_type1 .breadcrumbs__item:after{
    margin-top: 2px;
}

.custom-fields-list-view .breadcrumbs_type2{
    --breadcrumbsDivider: "→";
    --breadcrumbsDividerSize: 20px;
}
.custom-fields-list-view .breadcrumbs_type3{
    --breadcrumbsDivider: "•";
    --breadcrumbsDividerSize: 2rem;
}
.custom-fields-list-view .breadcrumbs_type3 .breadcrumbs__item:after{
    margin-top: 2px;
}
.custom-fields-list-view .breadcrumbs_type4{
    --breadcrumbsDivider: "|";
    --breadcrumbsDividerSize: 14px;
}
.custom-fields-list-view .breadcrumbs_type5{
    --breadcrumbsDivider: "›";
    --breadcrumbsDividerSize: 20px;
}
.custom-fields-list-view .breadcrumbs_type6{
    --breadcrumbsDivider: "—";
    --breadcrumbsDividerSize: 14px;
}

.custom-fields-list-view .input_box-form {
    width: calc(100% - 65px);
}
.custom-fields-list-view .input_box-form > * {
    width: 32% !important;
    margin-bottom: 5px;
}
.custom-fields-list-view .form-content {
    padding: 10px 10px 5px 10px;
}
.custom-fields-list-view .app-filter {
    height: auto;
}
.custom-fields-list-view .pretty-list-with-search .app-filter .pretty-list-actions .btn {
    margin: 0;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.custom-fields-list-view .pretty-list-with-search .app-filter .pretty-list-actions {
    float: none;
    position: absolute;
    right: 10px;
    top: 10px;
}


/*asset widget modal*/
.df-action-modal.default.job-asset-widget-modal {
    width: 1024px;
    margin:0;
    transform: translate(-50%, -50%);
    height: 80%;
}
.df-action-modal.default .cs-dynamic-form-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
.df-action-modal.default .modal-header,
.df-action-modal.default .modal-body,
.df-action-modal.default .modal-footer {
    position: absolute; left:0; right:0;
}
.df-action-modal.default .modal-footer {
    bottom: 0;
}
.df-action-modal.default .modal-footer.loader {
    line-height: 2;
}
.df-action-modal.default .modal-header {
    top: 0;
}
.df-action-modal.default .modal-body {
    top: 42px;
    bottom: 58px;
    padding:0;
    background: #f9f9f9;
    overflow:hidden;
}
.df-action-modal.default .screen-type-view .modal-body {
    bottom: 0;
}
.df-action-modal.default .wrap-job-asset {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.df-action-modal.default .wrap-job-asset > .asset-widget-col-left {
    width: 75%;
}
.df-action-modal.default .wrap-job-asset > .asset-widget-col-right {
    width: 25%;
    border-left: 1px solid #ddd;
}
.df-action-modal.default .screen-type-view .wrap-job-asset > .asset-widget-col-left {
    width: 100%;
}
.df-action-modal.default .screen-type-view .wrap-job-asset > .asset-widget-col-right {
    display: none;
}

.df-action-modal.default .wrap-job-asset > div[class^="asset-widget-col-"] {
    padding: 0;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    position: relative;
}
.job-asset-widget-modal .asset-selected-listview {

}
.asset-selected-listview ul.list-item-view li.with-actions {
    position: relative;
}
.asset-selected-listview ul.list-item-view li.with-actions .item {
    padding: 7px 30px 7px 15px;
}
.asset-selected-listview ul.list-item-view li.with-actions .btn-remove {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.asset-selected-listview .asset-item-header {
    padding: 11px 15px;
    background: #efefef;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}
.wrap-job-asset .iconic-tab-view {
    padding: 10px 10px;
    border-bottom: 1px solid #ddd;
}
.job-asset-widget-modal .job-asset-content {
    padding: 0 ;
}
.job-asset-widget-modal .search-input{
    background-color: #f9f9f9;
    padding: 6px 10px;
}
.job-asset-widget-modal .search-input input[type="text"] {
    border: 1px solid #ccc;
    width: calc(100% - 78px);
    box-sizing: border-box;
    height: 30px;
    background-color: #fff;
}
.job-asset-widget-modal .search-input.loading-search input[type="text"] {
    width: calc(100% - 113px);
}
.iconic-tab-view .fa-ul {
    margin-bottom: 0;
    margin-left: 0;
}
.iconic-tab-view .fa-ul > li {
    display: inline-block;
    font-size: 18px;
    padding: 0px 6px;
    vertical-align: middle;
    line-height: 20px;
}
.iconic-tab-view .fa-ul > li:first-child {
    padding-left: 0;
}
.iconic-tab-view  .tab-li-item {
    cursor: pointer;
    color:#ddd;
}
.iconic-tab-view  .tab-li-item.active {
    cursor: default;
    color: #333;
}
.job-asset-widget-modal table thead th:last-child,
.job-asset-widget-modal table thead th:first-child {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.job-asset-content .actions-column {
    min-width: 20px !important;
}
.job-asset-content.pos-abt {
    top: 85px;
    overflow:auto;
}
.job-asset-content.pos-abt.tree-grid-fixed-container {
    overflow: hidden !important;
}
.job-asset-content.pos-abt.tree-grid-fixed-container .content-grid {
    overflow: auto;
    top: 33px;
}
.asset-selected-listview .asset-item-body.pos-abt {
    top: 42px;
    overflow:auto;
}
/*table.affix-header {
    table-layout:fixed;
    background-color: #6393b5;
}
table.affix-header thead, tfoot {
    display:table;
    width: calc(100% - 15px);
}
table.affix-header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    top: 32px;
}
table.affix-header tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}*/
.job-asset-widget-modal .col-w-20 {
    width:20%;
}
.job-asset-widget-modal .col-w-30 {
    width:30%;
}
.job-asset-widget-modal .col-w-2 {
    width:24px;
}
.job-asset-widget-modal table tbody tr.selected {
    background: #fff6d9 !important;
}
.job-asset-widget-modal table tbody tr:hover {
    background: none;
}
.job-asset-widget-modal table.tree-grid-fixed-header {
    position: fixed;
    top: 127px;
    width: 75%;
    background: #6393b5;
}
.job-asset-widget-modal .screen-type-view table.tree-grid-fixed-header {
    width: 100%;
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .df-action-modal.default.job-asset-widget-modal {
        width: 1024px;
    }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
    .df-action-modal.default.job-asset-widget-modal {
        width: 80%;
    }
}


@media screen and (min-width: 1400px) {
    .df-action-modal.default.job-asset-widget-modal {
        width: 65%; height: 70%;
    }
}
@media screen and (min-width: 1600px) {

}
@media screen and (min-width: 1900px) {

}

.cs-job-asset-widget.screen-type-view table.tree-grid-fixed-header {
    top: 84px;
}
.cs-job-asset-widget.screen-type-view .job-asset-content.pos-abt {
    top: 39px;
}
