
div[cstreemodel] ul {
    margin: 0;
    list-style: none;
    border: none;
    overflow: hidden;
    position: relative;
    list-style: none;
    padding-left: 32px;
}
.inner-cstreemodel div[cstreemodel] ul {
    padding-left: 12px;
}

div[cstreemodel] li {
    position: relative;
    padding: 8px 10px;
    /*line-height: 20px;*/
    position: relative;
}

div[cstreemodel] li.first-row {
    padding-top: 18px;
}

div[cstreemodel] li .expanded {
    padding: 1px 10px;
    background-image: url("http://cfile23.uf.tistory.com/image/205B973A50C13F4B19D9BD");
    background-repeat: no-repeat;
}

div[cstreemodel] li .collapsed {
    padding: 1px 10px;
    background-image: url("http://cfile23.uf.tistory.com/image/1459193A50C13F4B1B05FB");
    background-repeat: no-repeat;
}

div[cstreemodel] li .normal {
    padding: 1px 10px;
    background-image: url("http://cfile23.uf.tistory.com/image/165B663A50C13F4B196CCA");
    background-repeat: no-repeat;
}

div[cstreemodel] li i, div[cstreemodel] li span {
    cursor: pointer;
}

div[cstreemodel] li .selected {
    background-color: #aaddff;
    font-weight: bold;
    padding: 1px 5px;
}

div[cstreemodel] li::before, div[cstreemodel] li::after {
    content: "";
    position: absolute;
    left: -12px;
}

div[cstreemodel] li::before {
    border-top: 1px solid #ddd;
    top: 18px;
    width: 12px;
    height: 0;
    padding-left: 4px;
    margin-left: 5px;
}
div[cstreemodel] li.first-row::before {
    top: 26px;
}

div[cstreemodel] li.action-node-li::before {
    width: 0;
    display: none;
}
div[cstreemodel] li.action-node-li.single-item::before {
    width: 12px;
    display: block;
}

div[cstreemodel] li::after {
    border-left: 1px solid #ddd;
    height: 100%;
    width: 0px;
    top: 0px;
    margin-left: 5px;
}

div[cstreemodel] ul > li:last-child::after {
    height: 18px;
}

div[cstreemodel] li:first-child::after {
    /*height: 0px;*/
}

div[cstreemodel] .node-input-checkbox {
    float:right;
    margin-right: 15px;
}

div[cstreemodel] .template-2 li {
    padding-right: 0;
}