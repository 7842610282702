    @font-face {
    font-family: 'Trebuchet MS';
    font-style: normal;
    font-weight: normal;
    src: local('Trebuchet MS'), url('/assets/fonts/trebuc.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trebuchet MS Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trebuchet MS Italic'), url('/assets/fonts/Trebuchet-MS-Italic.woff') format('woff');
    }

    @font-face {
        font-family: 'Arial Black';
        font-style: normal;
        font-weight: 700;
        src: local('Arial Black'), url('/assets/fonts/arial-black-font.woff') format('woff');
    }

    @font-face {
        font-family: 'Tahoma';
        font-style: normal;
        font-weight: normal;
        src: local('Tahoma'), url('/assets/fonts/TAHOMA_0.woff') format('woff');
    }

    @font-face {
        font-family: 'Verdana';
        font-style: normal;
        font-weight: normal;
        src: local('Verdana'), url('/assets/fonts/verdana.woff') format('woff');
    }

    @font-face {
        font-family: 'Georgia';
        font-style: normal;
        font-weight: normal;
        src: local('Georgia'), url('/assets/fonts/Georgia.woff') format('woff');
    }
