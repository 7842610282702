/*This file is for developers, We should move these styles to app.css*/
.appliance-smart-filter{
    margin-top:6.5px;
}
.appliance-merge-modal-input {
    margin-left:35%;
}
.appliance-merge-modal-input-padding-zero {
    margin-left: 0px !important;
}
.profile_image.extra_small .image_wrapper_2 {
    width: 50px;
    height: 50px;
    float:left;
}
input[type="datetime-local"] {
    height: 30px !important;
    padding: 0 8px !important;
}
.estimate-status.invoiced {
    background: #7b5aa4;
}
.customers-invoices-list-screen .actions-column {
    width: 90px;
    min-width:90px;
}
.customers-invoices-list-screen .small-amount-column {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}
.vertical-grand-totals-invoicea-list {
    float: right;
    list-style: none;
    width: 520px;
}
.vertical-grand-totals-invoicea-list.vat-hide {
    width: 390px;
}
.vertical-grand-totals-invoicea-list .excvat-value {
    float: left;
    width: 130px;
    font-weight: bold;
    display: inline-block;
    padding: 10px 14px;
    border-right: 1px solid #e1e1e1;
}
.vertical-grand-totals-invoicea-list .incvat-value {
    float: left;
    width: 130px;
    font-weight: bold;
    display: inline-block;
    padding: 10px 14px;
}

.dairy-sidepanel-hour-section {
    float: left;
}
.dairy-sidepanel-hour-section.to {
    float: left;
    margin: 5px 18px 5px 8px;
}
.dairy-sidepanel-hour-section .hour-min {
    width: 52px;
    float: left;
}
.dairy-sidepanel-hour-section .hour-colon {
    margin: 4px;
    float: left;
}
.dairy-sidepanel-hour-section .hour-ampm {
    margin-bottom: 11px;
}
.dairy-sidepanel-hour-section .diary-time-meridian {
    margin-left: 6px;
    float: left;
    width: 57px;
}
#diary_date_time_inputs .custom-datepicker table {
    width: 100%;
}
#diary_date_time_inputs .date-picker-edit {
    padding: 5px 10px;
}
.cs-event-header-lower .status-updated {
    float: right;
    margin: 2px 5px 0 0;
    font-size: 10px;
}
#diary_date_time_inputs .page-datepicker .custom-datepicker {
    top: 53px;
}
.show-all-option-total {
    text-align: right;
    margin: 10px 0px;
}
.show-all-option-total input[type="checkbox"] {
    margin:0 6px 0 0px;
}
.report-leftnav-all-tire {
    top:115px;
}
.move-left{
    left:25px;
}
.app-filter .appliancemodelmerge {
    width: 200px;
}
form .input-medium, .form_class .input-medium_appliancemodelmerge-group {
    width: 233px !important;
}
form .input-medium, .form_class .input-medium_appliancemodelmerge-fueltype {
    width: 150px !important;
}
form .input-medium, .form_class .input-medium_appliancemodelmerge-make {
    width: 233px !important;
}
.app-filter .appliancemodelmerge input {
    width: 150px;
}
.dashboard-inner-margin-padding{
    padding-left: 70px;
    maring-right:15px;
}
.no_data_for_widget{
    margin-top:80px;
}
.graph-parent-fullwidth{
    width:100%;
}

.pat-side-panel #page-panel-main {
    bottom: 0px;
}
#page-panel-main .slide.pat_slide {
    width: 91%;
}

#breadcrumb-title-bar .right-side{
    float: right;
    margin: 0 20px 0 0;
}

#breadcrumb-title-bar .right-side a{
    font-size: 16px;
    color: #797979;
    float: right;
    margin: 2px 0 0 10px !important;
}

.vertical-grand-totals .total-label_reporting {
    width: 200px;
    height: 50px;
}
.vertical-grand-totals_reporting {
    width: auto !important;
}
.job-costings-scrollwrapper_reporting {
    padding: 0px;
}
.export_report_help_section {
    padding:20px 30px 10px !important;
}
.export_report_page_panel_main {
    padding:0px !important;
}
.export_report_cancel_button {
    margin:0px 20px 0 0 !important;
}
.export_report_select {
    width:300px !important;
}

.diary-striped-background-donot-work {
    background: repeating-linear-gradient(-45deg, #fdf8f8, #fdf8f8 10px, #ffffff 10px, #ffffff 20px);
    border-color: #edbbbb;
}

.diary-property-item .ss-flag {
    float: left;
    margin: 10px 14px 0 0;
    color: #d25656;
}

.add_part_kit_margin {
    margin:10px !important;
}.vatType_float {
     float:right;
 }

.section-title.with-edit a.vattype-with-margin{
    margin: 5px 0 0 0;
    margin-left: 20px;
}

.disabledlink {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}
.account_settings_wrapper input[type="radio"] {
    float: left !important;
    margin: 3px 5px 0 5px;
}
.account_settings_wrapper input[type="radio"]+label {
    margin-bottom: 15px;
}
.account_settings_wrapper input[type="radio"]+label:first-child {
    margin-top: 7px;
}
.service_window_time_inputs input[type="text"] {
    width: 43% !important;
}
.service_window_time_inputs  ul.dropdown-menu{
    width: 50% !important;
    height: 10% !important;
}
.side-panel-add-service-window{
    width: 37% !important;
}
.service_window_top{
    margin-top:14%
}
.service_window_sidepanel_label{
    width: 36% !important;
}
.service_window_main_content {
    /*margin-top: 50% !important;
    margin-bottom: -60% !important;
    top: -235px !important;
    position: relative !important;*/
    margin-bottom: 0;
    padding-bottom: 0 !important;
}
.service_window_check_box{
    display: inline-flex !important;
}
.service_window_form{
    padding: 0;
    color: #505b65;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius:0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color:unset;
    background-repeat: no-repeat;
    background-image:none;
}
.service_window_form_inputs{
    width: 100% !important;
}
.service_window_main{
    width: 100%;
}
.service_window_bottom_content{
    float: right;
    margin-right: 2%;
    margin-top: 2%;
}
.service_window_bottom_info{
    text-align: center;
}
.service_window_help_text{
    margin-left: -20%;
    margin-right: 5%;
}

.history-section .ss-like{
    color: #60a369;
}

.history-section .ss-dislike{
    color: #d25656;
}
.service_window_side_panel_help_text{
    /*padding-right: 0px !important;
    padding-left: 20px !important;
    padding-top: -10px !important;
    margin-top: 0;
    padding-bottom: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 104%;*/
    margin: 0% 0% 0% -5% !important;
    padding: 2% 4px 1% 5% !important;
    width: 104%;

}
.service_windows_form_elements{
    margin-top: 2%;
}
.service_window_settings_help_text{
    margin: 0 0 10px 0 !important;
    padding: 20px 29px 10px 29px !important;
}
.gps-device-configuration-form .control-label {
    width: 170px;
}
.gps-device-configuration-form .controls {
    margin-left: 190px;
}
.gps-device-configuration-form .select2-chosen {
    font-size: 14px;
}
.input-wrapper .add-on-btn {
    cursor: pointer;
}
/*Dropdown menu styles*/
ul.dropdown-menu.bottom-right::after, ul.dropdown-menu.bottom-right::before {
    left: auto;
    right: 10px;
}

ul.dropdown-menu.top-left::before, ul.dropdown-menu.top-right::before {
    content: ' ';
    display: block;
    position: absolute;
    top: auto;
    bottom: -16px;
    left: 10px;
    border-color: transparent transparent transparent #cad5e0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #253f50;
}
ul.dropdown-menu.top-left::after, ul.dropdown-menu.top-right::after {
    content: ' ';
    display: block;
    position: absolute;
    top: auto;
    bottom: -15px;
    left: 10px;
    border-color: transparent transparent transparent #cad5e0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #ffffff;
}
ul.dropdown-menu.top-right::after, ul.dropdown-menu.top-right::before  {
    left: auto;
    right: 10px;
}

.appointment {
    float: left;
    border: 1px solid #d1d9dd;
    background-color: white;
}

.suggested-appointment {
    height: 100%;
    margin: 12px;
    border: 1px solid #ccc;
}

#appointment_map { height: 100%; }

.suggested-appointment .diary-suggestion-left {
    width:21%;
    float: left;
    padding: 0px 0px 15px 0px;
    background: #ffffff;
}
.length-event-main {
    width: 92%;
    float: left;
    margin-bottom: 2px;
    padding: 6px 8px 0px 8px;
}
.length-event-main label {
    margin-top: 0px;
}
.length-event-main .hour-min-test {
    width: 20%;
    float: left;
}
.length-event-main .minutes {
    width: 24% !important;
    float: left;
}
.length-event-main .container label {
    width: 20%;
    float: left;
    margin: 4px 0 0 5px;
}
.filters-main {
    width: 92%;
    float: left;
    margin-bottom: 0px;
    padding: 0px 8px;
}
.diary-filter-border-bottom {
    margin: 15px 0px 5px 0px;
    width: 95%;
}
.filters-main h4 {
    margin-top: -2px;
    margin-bottom: 4px;
}
.filters-main .skills-input {
    width: 94%;
}
.filters-main .more-buton {
    float: right;
    width: 100%;
    text-align: right;
}
.filters-main .more-buton a {
    color: #505b65;
}
.days-list-main-scroll {
    width: 100%;
    height: 80%;
    float: left;
    padding-right: 4px;
}

.days-list-main {
    width: 100%;
    float: left;
    margin-bottom: 12px;
}

.engineer-name-list {
    width: 94%;
    float: left;
    margin-bottom: 12px;
    padding: 8px;
}

.engineer-name-list:hover {
    background: #f7f7f7;
    cursor: pointer;
}

.engineer-name-list.active {
    background: #f7f7f7;
}

.engineer-name-main {
    width: 65%;
    float: left;
}

.days-list-main .engineer-name {
    margin-bottom: 0px;
}

.days-list-main .title_style {
    padding: 0px 8px;
}

.days-list-main .minutes {
    float: left;
    font-size: 11px;
}

.days-list-main .margin_right_1 {
    margin-right: 10px;
}

.engineer-name-button {
    width: 35%;
    float: right;
}

.engineer-button {
    float: right;
    width: 100%;
    text-align: right;
}

.engineer-name-button .btn {
    padding: 3px 8px;
}

.engineer-name-button .engineer-time {
    font-size: 10px;
    text-align: right;
    float: right;
    width: 100%;
}

.suggested-appointment .diary-suggestion-map {
    height: 100%;
    width: 79%;
    float: left;
}

.suggested-appointment .diary-suggestion-right {
    height: 100%;
    width: 210px;
    padding: 20px;
    float: right;
    position: absolute;
    z-index: 111111111;
    background: #fff;
    opacity: 0.9;
    right: 0px;
}

.engineer-select-list-right {
    width: 100%;
    margin-rihgt: 10px;
}

.engineer-select-list-right ul {
    margin: 0px;
    padding: 0px;
}

.engineer-select-list-right ul li {
    width: 100%;
    margin: 0 0 5px 0;
    padding: 0px;
    list-style: none;
}

.engineer-select-list-right ul li label {
    width: 20%;
    float: left;
    font-weight: 500;
}

.engineer-select-list-right ul li span {
    padding: 1px 6px 0px 6px;
    background: lightgreen;
    border-radius: 4px;
    margin: 0px 5px 5px 0px;
    float: left;
}

.right_line_address_main {
    width: 100%;
    height: auto;
    float: left;
    padding-top: 4px;
}

.left_line_main {
    width: 6px;
    height: 270px;
    background: #234e76;
    float: left;
}

.info_address_main {
    width: 100%;
    height: auto;
    float: left;
}

.info_address_list {
    width: 87%;
    height: auto;
    float: left;
    margin-left: 9%;
    margin-bottom: 15px;
}

.info_address_list ui {
    margin: 0px;
    padding: 0px;
}

.info_address_list ui li {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: auto;
    float: left;
    list-style: none;
    font-size: 13px;
}

.info_address_list ui li.style_bold {
    font-weight: bold;
    font-size: 14px;
}

.info_address_list ui li.style_color {
    color: #6e68a0;
    font-weight: bold;
    font-size: 14px;
}

.info_address_list ui li.margin_top_1 {
    margin-top: 10px;
}

.info_address_list ui li.margin_top_2 {
    margin-top: 20px;
}

.home_icon {
    margin: 0 0px 0 -36px;
    color: #ffffff;
    background: #234e76;
    padding: 4px;
    border-radius: 16px;
    border: 2px solid #fff;
}

.home_icon_2 {
    margin: 0 0px 0 -36px;
    color: #ffffff;
    background: #6e68a0;
    padding: 5px 4px;
    border-radius: 16px;
    border: 2px solid #fff;
}

.travel_required_icon {
    margin: 0 0px 0 -34px;
    color: #ffffff;
    background: #234e76;
    padding: 4px;
    border-radius: 16px;
}

.suggested-appointment-loader {
    width: 200px;
    margin: 0 auto;
    text-align: center;
}

.suggested-appointment-no-result {
    margin-top: 15%;
}

.side-panel-date-picker-disable{
    pointer-events:none;
}
.Proceed_button {
    margin-top: 300px;
    text-align: right;
    width: 60%;
}
#left_bar_last_home_icon {
    margin: 0 0px 0 -28px !important;
}
.filter-block> .select2options{
    padding-top: 5px;
}
.diary-lock-icon{
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    font-weight: bold;
    color: rgba(79, 91, 100, 0.86);
    float: right;
    padding-right: 2px;
}
.diary-lock-icon-show{
    display: none;
}
.diary-popup-with-text{
    right: 10% !important;
}
.client-id::-webkit-inner-spin-button,.client-id::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.suggested-appointment-scroll{
    overflow-y: scroll;
}
.gray_link {
    color: #999999;
    float: right;
}

.gray_link a:hover{
    color: #999999;
}

.reorder-list-full-width-smart-filter{
    width: 60%;
    min-width: 220px;
}

.information-bar .close-icon {
    position: absolute;
    right: 15px;
    color: #d7a69a;
    padding: 13px 0;
    z-index: 1;
    cursor: pointer;
}

.reorder-list-bottom-margin{
    margin-bottom:20px;
}


/* User Driver Trips view designs*/
.driver_trip_container {
    position: relative;
    overflow: auto;
    height: 74vh;
    border-radius: 8px;
}

.driver_trip_container .pagination {
    margin: 10px 0 0 0;
    height: 30px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.with-contextual-menu .driver_trip_sortable {
    margin-top: 2px;
}

.driver_trip_sortable {
    -webkit-box-shadow: 0px 1px 2px #a5a4a6;
    -moz-box-shadow: 0px 1px 2px #a5a4a6;
    box-shadow: 0px 1px 2px #a5a4a6;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    margin: 0;
    overflow: hidden;
    position: absolute;
    left: 10px;
    right: 10px;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
}

.driver_trip_sortable .record {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
}

.driver_trip_sortable .active {
    background-color: #d3e6ed;
}
.driver_trip_sortable .highlight_date {
    border-bottom: 6px double #446985 !important;
}
.driver_trip_sortable .highlight_user{
    border-bottom: 3px solid #446985;
}
.driver_trip_sortable .hideStartingDate{
    display: none;
}
.driver_trip_sortable .showStartingDate{
    display: block;
    width: 100%;
    padding: 5px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 35px;
    color: #477596;
    border: 0;
    border-bottom: 3px solid #e5e5e5;
    text-align: center;
}

.driver_trip_sortable li {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #ffffff;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #dadada;
    line-height: 13px;
    font-size: 13px;
    min-height: 90px;
}
.driver_trip_sortable .record .user-name{
    font-size: 16px;
}
.driver_trip_sortable .record .trip-label{
    position: absolute;
    top: 10px;
    right: 0;
    padding: 10px;
    display: block;
    width: 200px;
    text-align: right;
}
.driver_trip_sortable .record .trip-label-top{
    top: 59px !important;
}
.driver_trip_sortable .record .date-margin-top{
    margin-top: 25px;
}
.driver_trip_sortable .record .trip-label .label {
    font-size: 9px;
    font-weight: bold;
}

.driver_trip_sortable li .trip_entity{
    float: left;
    margin: 0 10px 0 0;
}
.driver_trip_sortable li a {
    color: #505b65;
    display: block;
    width: 100%;
    padding: 15px 14px ;
}

.driver-trip-list .small-padding{
    padding: 0 !important;
}

.driver-trip-list .row-fluid .span8{
    margin-left: 0 !important;
    width:67.957447% !important;
}
/*Trip Map view */
.trip-map-view{
    width:100%;
    height:74vh;
}
.trip-map-view .leaflet-pane {
    z-index: 0;
}
.trip-map-view  .leaflet-top, .leaflet-bottom {
    z-index: 0;
}
.trip-map-view .leaflet-div-icon {
    background: none;
    border: none;
    color: #0078A8;
}
.trip-map-view .trip-marker-icon .marker {
    height: 10px;
    width: 10px;
    background-color: #5dd337;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid white;
    box-shadow: 0px 0px 8px #5dd337;
}
.trip-map-view .user-route-start-end-marker .text-icon {
    width: 10px;
    text-align: center;
}

    /*Trip Popup */
.driver-trip-popup .user-marker-popup{
    width: 360px
}

.driver-trip-popup .user-marker-popup.showing {
    opacity: 1;
    z-index: 99999;
}
.driver-trip-popup .user-marker-popup .row1{
    height: 150px;
}

.driver-trip-popup .user-marker-popup .row1 span{
    font-size: 13px;
    font-weight: 100;
}
.driver-trip-popup .row1 .span12:first-child {
    margin-left: 2.127659574468085% ;
    *margin-left: 2.074468085106383%;
}


/***********************************/
/**      SLIM SCROLLBAR           **/
/***********************************/

/* Let's get this party started */
.slim-scroll ::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.slim-scroll ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
.slim-scroll ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255,255,255,0.4);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.slim-scroll ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,255,255,0.4);
}

form .actions .btn.float-right {
    margin-right: 10px;
}
.control-label-hour {
    width: 108px;
}

/* Transfer GPS device form styles */
.transfer-gps-device-form .field .field-label {
    width: 200px;
}

.transfer-gps-device-form .field .profile_image {
    margin-left: 20px;
}
.transfer-gps-device-form .controls .profile_image {
    padding: 0;
}
.transfer-gps-device-form .select2-chosen {
    font-size: 14px;
}
.diary-suggestion-map .info-bar{
    position: absolute;
    top: 9%;
    left: 26%;
    z-index: 555555555555;
    background: white;
    padding: 7px 7px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    min-width: 300px;
    height: 3%;
}
.icon-color{
    color: #d25656;
}

.suggested_apt_right_distance_span {
    margin-left: -1%;
}

.suggested_apt_right__span {
    padding-left: 3px !important;
}

.suggested_apt_event_marker.small .shadow-img {
    width: 145px !important;
}

.normalshift{
    background: #ffffff;
}

.overtimeshift{
    background: #fffdd9;
}

.emptyshift{
    background: #dcd9d9;
}

/* System settings Job durations */

.job-duration-settings .form-horizontal .control-label {
    width: 210px;
}

.job-duration-settings .form-horizontal .controls {
    margin-left: 230px;
}

.job-duration-settings form .group-header {
    margin-bottom: 20px;
    display: block;
}

.job-duration-settings form input[type="color"] {
    padding: 0 2px;
    width: 40px;
    height: 40px;
}

.job-duration-settings form select {
    width: 100%;
}

.job-duration-settings form .controls.margin-bottom {
    margin-bottom: 10px;
}

.job-duration-settings form .control-inline-text {
    display: inline-block;
    margin: 0 2px;
}

.job-duration-settings form .control-inline-text.fixed-width {
    width: 55px;
}

.job-duration-settings form .field.interval-minute {
    display: inline-block;
    width: 70px;
}

.job-duration-settings form .actions .error-message {
    color: #b94a48;
    float: right;
    line-height: 2;
    margin-right: 20px;
}

.job-duration-settings .jobs-duration-windows-section {
    padding-top: 20px;
}

.job-duration-settings .with-contextual-menu {
    height: 295px;
    padding: 0px;
}

.job-duration-settings .existing_customer_records.empty strong,
.job-duration-settings .existing_customer_records.empty p {
    display: block;
}

.job-duration-settings .with-contextual-menu .system_settings_sortable,
.job-duration-settings .with-contextual-menu .system_settings_sortable.manualheight {
    height: auto;
    min-height: 235px;
    top: -30px;
    bottom: 10px;
}

.job-duration-settings .system_settings_sortable .sortable_entity.window-color {
    flex: none;
    width: 50px;
}

.job-duration-settings .existing_customer_records.empty ul {
    background-position: 67px 40px;
}

.job-duration-settings .existing_customer_records.empty ul li:first-of-type {
    margin: 120px 0 0 0;
}

.job-duration-settings .existing_customer_records .spillover {
    background-color: #fceeee;
}
.job-duration-settings .existing_customer_records .titlebar {
    font-weight: bold;
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #ffffff;
    list-style: none;
    padding: 10px 14px;
    border-bottom: 1px solid #dadada;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    /*-khtml-user-select: none;*/
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;

}
.job-duration-settings .edit-duration-window .sortable_wrapper {
    height: 295px;
    margin-top: 100px;
}
.job-duration-settings .existing_customer_records .sortable_wrapper {
    -webkit-box-shadow: none;
}
.job-duration-settings .existing_customer_records #deadline-windows-title-container{
    height: 52px;
    /*top:1px;*/
    border-radius: 8px 8px 0px 0px;
}
.job-duration-settings .existing_customer_records #deadline-windows-list-container{
    border-radius: 0px 0px 8px 8px;
}
.job-duration-settings  .existing_customer_records #duration-deadline-windows-sortable-title{
    top: -15px;
}

.job-duration-settings .edit-duration-window .edit_view_wrapper .edit_title_content {
    height: 250px;
}

.job-duration-settings .text-green {
    color: #60a369;
}

.job-duration-settings hr.form-divider {
    border-top: 1px solid #d3d0d0;
}

.add-job-form #diary_date_time_inputs .date-picker-edit {
    padding: 4px 10px;
}
#diary_date_time_inputs .job-duration-date .date-picker-edit {
    padding: 4px 9px;
}

.custom-datepicker table tr td a.today:not(.selected-day) {
    background-color: #ffdb99;
}
.custom-datepicker table tr td a.today:not(.selected-day):hover {
    background-color: #ffb733;
}

#side-panel .supplier-msg .tiny-mce-single-line-input-wrapper .mce-tinymce .mce-edit-area {
    height: 30px !important;
}

.stock-reserve-detail-job-part .field-value{
    margin-left : 0px !important;
}

/**
 show record history UI style
 */
.showrecordhistory-title-section {
    padding: 0 20px;
    margin-left: 10px;
}
.showrecordhistory-activity-section {
    padding: 0 20px;
    padding-right: 0 20px;
    margin-right: 15px;
}
.showrecordhistory-date {
    width: 138px;
}
.showrecordhistory-heading {
    font-size:16px;
    margin-bottom: 8px;
}

.show_cancel_button {
    display: inline !important;
}
.payfort_active {
    border: 3px solid #505b65 !important;
}
.sha_type_select{
    width: 100%;
}
#setup-salesforce .choose-accounting-package .accounting-package .salesforce {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    cursor: pointer;
    margin-top:30px;
}
#setup-salesforce .choose-accounting-package .accounting-package .salesforce .content {
    padding: 10px 20px;
    text-align: center;
}
.salesforce-title  {
    padding: 10px 20px;
    color: #5e6d7f;
    display: inline;
    font-size: 15px;
}
#setup-salesforce .setup-steps li {
    clear: both;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
}

#setup-salesforce .setup-steps li .small-count {
    margin-right: 10px;
}

#setup-salesforce .setup-steps li .step-text {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

#setup-salesforce .setup-steps li label {
    margin-left: 38px;
}
.suggested-appointment .engineer-name-list.over_time {
    background-color: #f8f16f82!important;
}

#service-windows-cs-calendar a.fc-time-grid-event {
    right: -3% !important;
}


/**
 show record history UI style
 */
.showrecordhistory-title-section {
    padding: 0 20px;
    margin-left: 10px;
}
.showrecordhistory-activity-section {
    padding: 0 20px;
    padding-right: 0px 20px;
    margin-right: 15px;
}
.showrecordhistory-date {
    width: 138px;
}
.showrecordhistory-heading {
    font-size:16px;
    margin-bottom: 8px;
}
/**
   Mass scheduling UI
 */
.schedule_layout {
    padding-bottom: 0px;
}
.scheduling-view-results .trip-map-view {
    height: 66vh !important;
}
.scheduling-view-results .info-bar{
    position: absolute;
    top: 1%;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px 7px 7px 7px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0,0,0,.2);
    min-width: 30%;
    height: 4%;
}
.scheduling-view-results .border-bottom {
    border-bottom: 1px solid #dadada;
}
.scheduling-view-results .active_row {
    background: #fff6d9;
}
.scheduling-view-results .suggestion-section {
    max-height: 66vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.scheduling-view-results .result_view{
    margin-top: -10px;
}
.scheduling-view-results .section-results{
    margin-left: 1px;
}
.scheduling-view-results .map-view {
    margin-left: 1px;max-height: 66vh;width: 67.8%;
}
.scheduling-view-results .bottom-section {
    border-top: 2px solid #eee;margin-left: 0px;margin-top: 2px; padding: 5px;
}
.scheduling-view-results .action-btn{
    margin-right: 8px;
}
.scheduling-view-results .padding-zero {
    padding: 0px;
}
.scheduling-view-results .warning-section {
    color: #0c0c0c
}

.show_cancel_button {
    display: inline !important;
}
.payfort_active {
    border: 3px solid #505b65 !important;
}
.sha_type_select{
    width: 100%;
}
.suggestion-section table thead th:last-child {
    border-radius: inherit !important;
}
.suggestion-section table thead th:first-child {
    border-radius: inherit !important;
}
.mass-scheduling-travel-time {
    padding-left: 4px;padding-top: 4px;
}
.suggestion-section table tbody td {
    padding: 6px 10px 10px 10px !important;
}
.suggestion-section h5{
    margin: 6px 0 !important;
}
.deleted_asset {
    opacity: 0.75;
}
.scheduling-feedback-main-box{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 20px;
    padding-bottom: 20px;
}
.scheduling-feedback-main-box .pretty-list li strong {
    display: inline-block !important;
    width: 100% !important;
    float: left !important;
    margin-left: 0 !important;
}
.side-panel-add-service-window #page-panel-title-bar{
    margin-top: -4%;
}
.manage-break-sidepanel{
    background-color: #fff;
}
.shift-break-margin{
    margin-left: 2.127659574468085% !important;
}
.manage-break-section-border{
    padding: 15px 20px;
    border-bottom: 1px solid #dadada;
}
.manage-break-section-border-item {
    border-top: 1px solid #dadada;
}
.manage_breaks .btn.loading.btn-primary {
    background-color: #a19cc3 !important;
    background-image: url("../../../images/loading/purple-loader.gif") !important;
    background-repeat: no-repeat !important;
    background-position: 8px 6px;
    padding: 4px 14px 4px 32px;
}

.sidepanel-on-right {
    float:right;
    font-size: 14px;
}
.client_import_data_wrapper {
    position: unset !important;
}

.no-margin {
    margin: 0;
}
.title-line-height {
    line-height: 2;
}
.title-section-right {
    float: right;
    font-size: 14px;
    margin-top:2px;
}
.title-margin-right {
    margin-right: 10px;
}
.div-opacity {
    opacity: 0.5;
}

.asset_details_box {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 2px 4px;
    margin: 0 5px 4px 5px;
    background: #f8f8f8 !important;
    border-color: #c9c9c9 !important;
    display: inline-block;
    white-space: nowrap;
}
.field span.asset_details_box_container {
    margin-left: 15px;
}

.dp_label-lg {
    width: 100%;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}
.dp_label-lg-width {
    width: 65% !important;
}
.dp_label-lg .label-lg-inner {
    display: inline-block;
    padding: 5px 10px;
    line-height:20px;
}
.label-lg-inner.label-lg-title {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    color : white;
}
.label-lg-inner.label-lg-title.span5.green{
    background-color: #60a369;
}
.label-lg-inner.label-lg-title.span12.green{
    background-color: #60a369;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
}
.label-lg-inner.label-lg-title.red{
    background-color: #b95858;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
}
.label-lg-inner.label-lg-status {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin-left:0;
}
.label-lg-icon {
    position: absolute;
    top:0;
    bottom: 0;
    right: -1px;
    width: 38px;
    text-align: center;
    border-radius: 15px;
    padding: 6px 0;
}
.label-lg-icon > i {
    line-height: 1;
    margin-top:10px;
}
.dp_label-lg.label-lg-default {
    border: 2px solid #efefef;
    font-size: 14px;
}
.dp_label-lg.label-lg-default .label-lg-inner.label-lg-status,
.dp_label-lg.label-lg-default .label-lg-icon{
    background-color: #efefef;
    font-size: 14px;
    color : white;
}

.dp_label-lg.label-lg-red {
    border: 2px solid #b95858;
    font-size: 14px;
}
.dp_label-lg.label-lg-red .label-lg-inner.label-lg-status,
.dp_label-lg.label-lg-red .label-lg-icon{
    background-color: white;
    font-size: 14px;
    color : white;
}
.dp_label-lg.label-lg-success {
    border: 2px solid #60a369;
    font-size: 14px;
}
.dp_label-lg.label-lg-success .label-lg-inner.label-lg-status,
.dp_label-lg.label-lg-success .label-lg-icon{
    background-color: white;
    font-size: 14px;
    color : #505b65;
}

.dp_label-lg.label-lg-pause {
    border: 2px solid #A2A360;
    font-size: 14px;
}

a.text-hover:hover + div.test-hover {
    display: block;
}
div.test-hover {
    display: none;
}

a.text-hover.label.green{
    background-color: #60a369;
    font-weight: bold;
    color:white;
}
a.text-hover.label.red{
    background-color: #b95858;
    font-weight: bold;
    color:white;
}

#service-windows-cs-calendar a.fc-time-grid-event {
    right: -3% !important;
}
.side-panel-add-service-window #page-panel-title-bar{
    margin-top: -4%;
}
.manage-break-sidepanel{
    background-color: #fff;
}
.shift-break-margin{
    margin-left: 2.127659574468085% !important;
}
.manage-break-section-border{
    padding: 15px 20px;
    border-bottom: 1px solid #dadada;
}
.manage-break-section-border-item {
    border-top: 1px solid #dadada;
}
.manage_breaks .btn.loading.btn-primary {
    background-color: #a19cc3 !important;
    background-image: url("../../../images/loading/purple-loader.gif") !important;
    background-repeat: no-repeat !important;
    background-position: 8px 6px;
    padding: 4px 14px 4px 32px;
}
.customer-with-line-height{
    line-height: 27px !important;
}
.signin-button-width-height {
    height:46px;
    width:191px;
    padding:5px;
}

/*line items break up*/
.item-inner .with-br{
    position: absolute;
    left: 4%;
    width: fit-content;
    height: 30%;
    border-right: solid 1px #dadada;
    padding-right: 16px !important;
}
.item-inner .with-rel-pos{
    position: relative;
    top: 0px;
    right: 11px;
    padding-top: 27px;
    border-left: unset !important;
}
.backup-row .backup-option.google-calendar {
    background-image: url("../../../images/google_calendar.png");
    background-size: 60px;
}
.backup-row .backup-option.outlook-calendar {
    background-image: url("../../../images/outlook_calendar.png");
    background-size: 60px;
}

/*user voip settings*/
.voip-settings-container{
    padding-top: 20px;
}
.voip-call-icon{
    margin-right: 10px !important;
}
.voip-call-button-disabled{
    color: lightslategrey !important;
    cursor: not-allowed !important;
}

.voip-settings-container .control-group .controls
.sip-password-input{
    width: 224px !important;
}
.asset-widget-text-decoration {
    text-decoration: line-through;
}
.item-inner .content .phone-recording{
    float: right;
}
.timeline .item-inner .title-bar .left-side .with-lm{
    margin-left: 10px;
}
.timeline .item .item-inner .right-side .phone-rec-download-link,
.data-section .responsive-table td .phone-rec-download-link{
    position: relative;
    bottom: 14px;
    padding: 9px 9px 6px 9px;
    border-radius: 25px;
    color: #797979;
    background: #ebeef2;
    cursor: pointer;
}
.timeline .item .item-inner .right-side .phone-recording,
.data-section .responsive-table td .phone-recording{
    height: 40px
}
.asset-widget-text-decoration {
    text-decoration: line-through;
}

.gmail-logo-width-height {
    height:20px;
    width:20px;
}

.pac-container {
    z-index: 9999;
}

.normal-event-map {
    margin-left: 20px;
    margin-top: 5px;
    height: 30vh;
    width: 60vh;
}

.connect-gmail-section {
    padding-bottom: 20px;
}

.chat-box {
  background-color: #fff;
  height: 500px;
  width: 33.33%;
  width: calc(100%/3);
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid #ddd;
}

.chatsub {
  vertical-align: middle;
  font-size: 16px;
}

.chat-box-title {
    text-align: left;
    font-size: 24px;
    line-height: 25px;
    padding-bottom: 20px;
}

.chat-feature-box {
    height: 350px;
}

.chat-feature-text {
    font-size: 14px;
    font-weight: 500;
    /*color: black;*/
}
.profile-cell-chat .profile_image {
    float: left;
    margin-right: 20px !important;
}

.profile-cell-chat img {
    margin: 0 20px 0 4px;
    width: 35px;
    height: 35px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #c9c9c9;
    padding: 1px;
}

.profile-cell-chat div {
    overflow: hidden;
    padding: 5px 0 0 5px;
}

.profile-cell-chat div span {
    display: block;
    font-size: 13px;
}

.profile-cell-chat div:first-of-type {
    margin: 0 0 10px 0;
}
.chat-body {
    height: 100px !important;
    overflow-y: scroll;
    padding-bottom: 45px;
}
.chat-description {
    width: 1000px; 
    word-wrap: break-word;
    padding: 5px 0 0 5px;
}
.chat-info {
    padding: 2px 0 0 15px;
}
.chat_settings_wrapper input[type="checkbox"]+label {
    margin-bottom: 15px;
}
.chat_settings_wrapper input[type="checkbox"] {
    float: left !important;
    margin: 3px 5px 0 5px;
}
.chat_settings_wrapper input[type="radio"] {
    float: left !important;
    margin: 3px 5px 0 5px;
}
.chat_settings_wrapper input[type="radio"]+label {
    margin-bottom: 15px;
}

.chat_settings_wrapper input[type="radio"]+label:first-child {
    margin-top: 7px;
}

.item-note .chat-content {
    max-height: 200px;
    min-height: 80px;
    overflow: auto;
    float: left;
    box-sizing: border-box;
    width: 100%;
    margin: 7px 0 0 0;
    padding: 5px 0 0 0;
}

.sign-in-button-width-height {
    float: left;
    height: 51px;
    margin: 0 0 5px 0;
}

.driver_detail_header_border {
    border-right: 1px solid #c2c2c2;
}

.reporting_users_driver_detail_pdf_header {
    font-size: 10px;
    font-weight: normal;
    color:#FFFFFF;
    padding:5pt 8pt;
}

.reporting_users_driver_detail_pdf_header_bg_color {
    background-color: #6593b4;
}

.reporting_users_driver_detail_pdf_body {
    padding:10pt 8pt;
}

.labour-travel-cost-time {
    height: 30px;
    padding: 5px 8px 0px;
    display: block;
}

#wolseley-po-pagination a {
    margin-left: 0px !important;
}

.d-inline-block {
    display: inline-block !important;
}
.popover.bottom-left .arrow {
    top: -10px;
    left: 40px;
    margin-left: -10px;
    border-width: 0 10px 10px;
    border-bottom-color: #ffffff;
}
.popover.bottom-left .arrow:after {
    border-width: 0 11px 11px;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -1px;
    left: -11px;
}
.popover.bottom-left {
    margin-top: 10px;
}

.supplier-price-popover-content table thead,
.supplier-price-popover-content table tbody tr {
    border-bottom: none;
}
.supplier-price-popover-content table thead th {
    letter-spacing: 0;
}
.supplier-price-popover-content table thead th {
    background: transparent;
    color: inherit;
    font-weight: 600
}
.supplier-price-popover-content table tbody tr:nth-child(odd) {
    background: #fff;
}
.cs-text-muted {
    color: #B3B3B3;
}
.handsontable-icon-wrap {
    float: right;
    margin-right: -3px;
    /*font-size: 16px;*/
    color: #989898;
    padding: 0px 3px;
}
.cs-text-default {
    color: #989898;
}
.cs-text-green {
    color: #53BB01;
}
.cs-text-orange {
    color: #E67B2E;
}
.cs-text-red {
    color: #BB0101;
}

.import-email-header{
    padding: 15px 20px 30px 20px
}

.trimmed-email-content{
    max-height: 176px;
    float: left;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    margin: 7px 0 0 0;
    padding: 5px 0 0 0;
    border-top: 1px solid #eeeeee;
}

.supplier-price-popover-content .min-mid {
    color: #00BC4E;
}
.supplier-price-popover-content .max {
    color: #F63131;
}


/* ********************* Timecard Approval ****************************** */


/*Timecard Map view */

.timecard-map-view {
    width: 100%;
    height: 100vh;
}

.timecard-map-view .leaflet-pane {
    z-index: 0;
}

.timecard-map-view .leaflet-top,
.leaflet-bottom {
    z-index: 0;
}

.timecard-map-view .leaflet-div-icon {
    background: none;
    border: none;
    color: #0078A8;
}

.timecard-map-view .trip-marker-icon .marker {
    height: 10px;
    width: 10px;
    background-color: #5dd337;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid white;
    box-shadow: 0px 0px 8px #5dd337;
}

.timecard-map-view .user-route-start-end-marker .text-icon {
    width: 10px;
    text-align: center;
}

.timecard-map-list .small-padding {
    padding: 0 !important;
}

.timecard-map-list .row-fluid .span8 {
    margin-left: 0 !important;
    width: 67.957447% !important;
}

.timecard-suggestion-top-right {
    height: 75px;
    width: 26%;
    padding: 20px;
    position: absolute;
    z-index: 111111111;
    background: #ffffff;
    /*opacity: 0.9;*/
    right: 220px;
    top:100px;
    z-index:1
}


.timecard-suggestion-top-right-distance {
    height: 75px;
    width: 34%;
    padding: 15px;
    position: absolute;
    z-index: 111111111;
    background: #ffffff;
    /*opacity: 0.9;*/
    right: 165px;
    top:100px;
    z-index:1
}

.right-border-left-widget{
    border-right: 1px solid #e2e2e2;
    height:16vh;
}

.right-border{
    border-right: 1px solid #e2e2e2;
}

.top-border{
    border-top: 1px solid #e2e2e2;
}


.timecard-suggestion-top-left {
    height: 19vh;
    width: 43%;
    padding: 20px;
    position: absolute;
    z-index: 111111111;
    background: #ffffff;
    /*opacity: 0.9;*/
    left: 100px;
    top:100px;
    z-index:1
}

.timecard-approved {
    background-color: #AFF7AE;
}

.timecard-map-title-ucfirst{
    text-transform: capitalize;

}

.timecard-fullscreen-close{

    float: right;
    font-size: 14px;
    padding-top: 0px !important;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    line-height: 25px;
    margin-left: 10px;
    font-family: sans-serif;
}

.timecard-fullscreen-close-font{
    color: #CECECE;
    font-weight: normal;
}

.timecard-fullscreen-delete-font{
    color: #505B65;
    font-weight: normal;
}

.timecard-fullscreen-approve-close{

    float: right;
    font-size: 14px;
    font-weight: normal;
    padding-top: 4px !important;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    line-height: 20px !important;
    font-family: sans-serif;
}

.add-new-icon{
    font-size:18px;
}

.no-break-section{
    border:1px solid #e2e2e2;
    margin-bottom:0px;
}

.no-break-section-margin{
    margin: 0px 10px 0px 10px;
}

.no-break-section-padding{
    padding: 14px 10px 7px 12px;
    color:#505B65;
    font-size: 14px;
}

.timecard-exlamation{
    color: #968947;
    width: 24px !important;
    padding-left: 5px;
}

.timecard-widget-break{
    width:100px;
}

.timecard-break-toolpic{
    position:absolute;
}

.timecard-break-toolpic-inner{
    margin-top:10px;
    min-width:170px;
    padding:0px !important;
}

.timecard-break-toolpic-border{
    border-bottom:1px solid #d2d2d2;
}

.timecard-break-toolpic-link{
    color:#3883c1;
}

.timecard-datetimepicker-width{
    width:71%;
    margin-left: -7px;
}

.total-label-timeSheet {
    width: 155px;
    height: 50px;
}
ul li .total-label-timesheet {
    border-bottom: solid 1px #e1e1e1;
    border-right: 1px solid #e1e1e1;
    background: #fbfbfb;
}

.total-label-timesheet strong, .total-label-timesheet span {
    float: left;
    padding: 10px;
}

.timesheet-fa-size{
    font-family: Arial,Helvetica,sans-serif;
    font-size: 25px;
}

.timesheet-table-td{
    white-space: nowrap;
}

.timesheet-table-limit{
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
}

.timesheet-table-limit-show{
    display:inline-block;
    margin-bottom: 0;
}

.timesheet-table-limit-select{
    width:60px;
    margin-bottom: 0;
}

.timesheet-table-limit-entries{
    display:inline-block;
    margin-bottom: 0;
    margin-right: 10px;
}

.timesheet-map-widget-header-fa{
    font-size:14px;
    font-weigth:bold !important;
}

.timesheet-fa-10{
    font-size:10px;
    line-height: 12px;
}

.timesheet-fa-14{
    font-size:14px;
    line-height: 21px;
}

.timesheet-fa-18{
    font-size:18px;
}

.timesheet-fa-24{
    font-size:24px;
    line-height: 28px;
}

.timesheet-fa-36{
    font-size:36px;
    line-height: 42px;
}
.timeCard .input-append {
    width:auto;
}
.timeCard span {
    margin-right:7px;
}
.itemFirst {
    margin-top: 20px;
    border-top: 1px solid #ccc;
}
.timeCardBreak {
    width:auto;
    display: inline-block;
}

.timecard-td-color{
    color: #505B65;
    font-size: 14px;
}

.timecard-marker{
    padding-left:33px;
}

.timecard-add-button{
    font-size: 14px;
    line-height: 16px;
}

.timecard-break-exlamation {
    color: #968947;
    width: 14px !important;
    vertical-align: top;
}

.add-break-icon{
    background: #6E66A2;
    color: #fff;
}

.add-timecard-error{
    float:left;
}

.timesheetHeader{
    min-width:10%;
}

.timecard-break-align{
    margin-left: -7px;
}

.timecard-td-width{
    width:85px;
}

.timecard-map-sameday-startend{
    font-size: 14px;
    color: #A7A7A7;
}


.timecard-map-sameday-break{
    font-size: 10px;
    color: #A7A7A7;
}

.top_bar_wrapper .new-top-menu {
    float:right;
}

.new-options-li {
    padding: 1px 9px 1px 13px;
}

.new-top-menu {
    margin: 0 0 5px 25px !important;
}

.new-top-menu.options a.ss-globe-new {
    margin: 5px 0px 0 0px;
}

.dropdown-menu.small-menu.new-top-menu {
    width: 340px;
    left: -296px;
}

.dropdown-menu.small-menu.new-top-menu-arrow:after {
    left: 317px;
}

.dropdown-menu.small-menu.new-top-menu-arrow:before {
    left: 317px;
}

#userInfo a:hover {
    background: none;
}



/* added for new menu UI changes*/

#topDiv {
    position: relative;
    height: 133px;
    padding-top: 10px;
}

#mainDiv img {
    position:relative;
    margin-left: 15px;
}

#rightBlock {
    padding: 0;
    position: relative;
    bottom: 0px;
    display: inline-block;
    left: 41%;
    bottom: 68%;
}

#mainDiv a {
    margin-left: 45px;
    padding: 0;
    line-height: 16px;
    color: #505B65;
    position: relative;
    font-size: 14px;
}

#bottomDiv img {
    margin-left: 20px;
    position: relative;
    z-index: 1;
    top: 12px;
}

#phone {
    border-left: 1px solid #dedede;
    height: 50px;
    width: 40px;
}

#chatLabel, #phoneLabel {
    margin-left: 36px;
    position: relative;
    bottom: 13px;
    font-size: 14px;
}

#chatLabel:hover, #phoneLabel:hover {
    background-color: white;
    z-index: inherit;
}

#calendarImage {
    float: right;
    position: relative;
    /*right: 20px;*/
    bottom: 70%;
    cursor: pointer;
}

.greyOut{
    filter: grayscale(100%);
}

#bottomDiv {
    position: relative;
    height: 45px;
    border-top: 1px solid #dedede;
}

#chat, #phone {
    display: inline-block;
    height: 45px;
    cursor: pointer;
  }

#chat {
      width: 162px;
}

#chat img {
    width: 20px;
}

#topDiv .profile_picture {
    display: inline-block;
    position: relative;
    top: -74px;
    bottom: 0px;
}

#userNamePic {
    background-color: #4EA8FB;
     color: white;
     border-radius: 5px;
     height: 100px;
     width: 100px;
    padding: 40px 14px;
    font-size: 48px;
}
#userProfilePic {
    border-radius: 5px;
    height: 100px;
    width: 100px;
    font-size: 48px;
    overflow: hidden;
}
#usrpicofcorner{
    overflow:hidden;
    height:30px;
}
#initials {
     color: white;
     font-size: xxx-large;
     font-weight: 400;
     padding: 37px 12px;
     position: relative;

    }

#helpLabel {
    height: 25px;
    background: none !important;
    border: none !important;
    font-size: 14px !important;
    padding: 4px 10px 0px 10px !important;
    color: #60A369 !important;
    margin-top: -1px;
}


#logoutLabel {
    color: #CF2A2A !important;
}

#chat a, #phone a {
    color: #505B65;
}

#dividerLine {
    margin:0;
    border-top: 1px solid #dedede;
}

#profileMenuBottomLine {
    margin-top: 20px;
    border-top: 1px solid #dedede;
}

#userName {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    margin-bottom: 0px;
}

#userInfo p {
    padding:0;
    position: relative;
    font-size: 14px;
    line-height: 16.41px;
    margin: 0;
}

#userInfo a {
    padding:0;
    position: relative;
    font-size: 14px;
    line-height: 16.41px;
}

#editProfile {
    margin-top: 9px;
}

#new-profile-menu {
    margin-left:0px !important;
    padding-left: 0px;
    padding-top: 0px;
    margin-bottom: 7px;
    cursor: initial;
}

ul.dropdown-menu.new-top-menu-arrow li {
    height: 20px;
    background: none !important;
    border: none !important;
    font-size: 14px !important;
}

.new-profile-menu-actions a {
    font-size: 14px;
    padding: 0px !important;
    color: #505B65;
}
.row-fluid .new-profile-menu {
    padding: 0px !important;
}

ul.dropdown-menu.new-top-menu-arrow {
    border: 1px solid #C4C4C4 !important;
}

#new-profile-menu a:hover {
    background: none;
    cursor: pointer;
}

#new-profile-menu a {
    padding: 0px !important;
    width: min-content;
}
#new-profile-menu li {
    padding: 0px 16px 10px 16px !important;
    cursor: initial;
}

.new-menu-link {
    font-size: 14px;
    color: #505B65 !important;
}

#new-profile-menu-div {
 border-top: 1px solid #dedede;
 position: relative;
}

.new-ui-change {
    display: contents !important;
    padding: 0px  !important;
    color: rgb(56 131 193) !important;
}
.new-profile-icon {
    padding: 16px;
}

.new-profile-details {
  padding-left: 14px;
}

.job-template-config-disabled{
    /*background-color: rgba(255, 255, 255, .4) !important;*/
    display: block;
    pointer-events: none;
    cursor: not-allowed !important;
}

.page-selection{
    pointer-events: auto !important;
}

.diary-answer-type-inputs{
    width: 100% !important;
}

/* Toggle button class for customer view screen*/
.user-preference-toggle{
    float: right;
    padding: 8px;
    background-color: #4A7695;
    border-radius: 6px;
    bottom: 0px;
    left: auto;
    position: fixed;
    right: 30px;
    top: auto;
}
.user-preference-toggle .btn-switch {
    position: relative;
    display: block;
    width: 50px;
    height: 25px;
    cursor: pointer;
    background-color: #E18181;
    border: 2px solid #E18181;
    border-radius: 40px;
}
.user-preference-toggle .field-label {
    width: 250px !important;
    color: #FFFFFF;
    padding: 5px;
    font-weight: 600;
}
.user-preference-toggle .toggle-btn-sec {
    padding-left: 2px !important;
}
.user-preference-toggle .btn-switch .btn-switch-circle {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 25px;
    width: 25px;
    background-color: #4A7695;
    border-radius: 40px;
}
.user-preference-toggle .btn-switch--on {
    background-color: #60a369;
    border: 2px solid #60a369;
}
.user-preference-toggle .btn-switch--on .btn-switch-circle--on {
    left: auto !important;
    right: 0;
}

.diary-answer-type-inputs{
    width: 100% !important;
}

.sla_breach_with_segmentation{
    width: 50%; float: left;overflow:hidden;height:100%;padding:30px
}

.sla_segmentaion_panel_with_segmentation{
    width:40%;height: 100%;float:left;overflow:hidden;padding-left: 0px
}

.sla_seg_panel_without_segmentation{
    width:0%;height: 0%;float:left;overflow:hidden;padding-left: 0px
}

.sla_seg_panel_with_one_segmentation{
    width:20%;height: 100%;float:left;overflow:hidden;padding-left: 0px
}

.sla_metric_menu_bar{
    line-height: 17px !important;padding-top: 15px; padding-bottom:15px; padding-left: 20px; padding-right: 20px;
}

.sla_clip_board{
    margin-right: 10px;
}

.sla_check_circle{
    float:right;margin-right: 10px;margin-top:5px;color:#008000;
}

.sla_configure_panel{
    flex:1; width:70%; box-sizing: border-box
}

.sla_setup_segment_helptext{
    text-align: center; padding: 50px
}

.sla_setup_form_Without_Segment{
    width: 97%
}

.sla_setup_form_With_Segment{
    width: 56%
}

.sla_setup_form_With_one_Segment{
    width: 80%
}

.sla_setup_form_control_Without_Segment{
    width: 48%
}

.sla_setup_form_control_With_Segment{
    width: 65%
}

.sla_dashboard_report_countdown{
    padding:6px 8px;
    border-radius: 10px;
    color: white
}

.countdown_bg_red{
    background-color:#b95858
}

.countdown_bg_green{
    background-color:#60a369
}

#new-top-menu {
    z-index: 1030px;
    /* margin-right: 30px !important; */
    text-decoration: none;
    margin: -2px 0px 0px 5px;
}

ul.dropdown-menu::before {
    border-bottom: 8px solid #c4c4c4;
}

ul.dropdown-menu.small-menu {
    left: -173px;
}
.address_line1_color{
    border: 1px solid #ccc;
    height: 30px;
    border-radius: 3px;
    font-weight:normal;
    color: #555555;
    padding: 0 8px;
}

.cloning-link{
    font-size: 12px;
    color: #848d92;
    position: absolute;
    right: 10px;
    margin-top: 2px;
}

.cloning-link :hover {
    color: #505c63;
}

.partsandprices_fslayouts .sla-config-menu {
    z-index: 2
}

.opportunity-stage-color-box {
    width: 14px;
    height: 14px;
    border: 1px solid rgb(227, 227, 227);
    float: left;
}

.opportunity-sale-person-profile {
    float: left;
    max-width: 20px;
    max-height: 18px;
}

.newTopMenu {
    margin-left:-6px
}

.newTopMenuMarginRight {
    margin-right: -4px !important;
}

.newUITopMenu {
    width:340px !important;
    right: 43px !important;
    left: auto !important;
    position: fixed !important;
    margin-top: 11px !important;
}

.newUILinks {
    background-color: #ffffff;
}

.newTopMenuMarginLeftRight {
    margin-right:2px;
    margin-left: -12px;
}

.newTopMenuLinks:hover {
    background-color: #eceef4;
    cursor: pointer;
}

.sla-businessHour {
    width: 70px; margin-left: 6px
}

.sla-businessHour-hyphen {
    margin:8px
}

#billingicon {
    width: 15.75px !important;
    height: 18px !important;
}

#new-profile-menu-div div ul li span span img {

    width: initial !important;
    max-width: initial !important;
    vertical-align: initial !important;
    border: none !important;
    height: initial !important;
}

#billingLabel {
    margin-top: -3px;
}

#logoutLabel {
    margin-top: -4px;
}

#settingsLabel {
    margin-top: -1px;
}

#integrationsLabel {
    margin-top: -2px;
}

#helpLabel {
    margin-top: -1px;
}
.countNotifyspacing{
    margin-left: 5px !important;
}
.newtopmenu-permission {
    padding-right: 15px !important;
}

.newtopmenu {
    padding-right: 29px !important;
}

.fixed-noborder-popover {
    position: relative;
    border: none;
    background: transparent;
    vertical-align: middle;
}

.wrap-notes {
    white-space: pre-wrap;
}

.job-report-customisation-label{
    width: 180px !important;
}

.job-report-customisation-radio-option{
    padding-right: 50px !important;
}

.tooltip-image-sidepanel {
    .tooltip-inner{
        white-space: normal !important;
        padding:5px 5px !important;
        max-width: 400px !important;
        word-break: break-word !important;
    }

}

.disconnectStopIcon {
    padding: 4px 9.5px 4px 6.5px !important;
    line-height: 18px !important;
}

.stopIconWidth{
    max-width: max-content !important;
}

.piped-list {
    padding: 0 3px;  /* Add padding on each side */
}

.piped-list::after {
    content: "|"; /* Add pipe symbol after each span */
    margin: 0 3px; /* Add spacing after the pipe */
}

.mindee-upload {
    position: absolute;
    top: 0px;
    bottom: 135px;
    left: 0px;
    right: 0px;
    padding: 20px 45px 20px 30px;
}

.menuCard{
    display: flex;
    padding: 16px 16px 24px 16px;
    gap: 24px;
}

.margin-left-0 {
    margin-left:0px !important
}

.job-costing-bottom{
    bottom: 16px !important;
}

.job-costing-wrapper-height{
    height: 23px !important;
    box-shadow: none !important;
}
.merge-left{
    padding: 20px 30px;
}
.supportRequestIcon{
    display: flex;
    width: 118px;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    height: 54px;
    background: #f9f9f9;
    border-radius: 2px;
    cursor: pointer;
}

.menuCard{
    display: flex;
    padding: 16px 16px 24px 16px;
    gap: 24px;
}

.menuCard.menuPadding {
    padding: 0px 16px 16px 16px;
}

.cardWidth{
    width: 100% !important;
}


.margin-left-0 {
    margin-left:0px !important
}


.job-costing-bottom{
    bottom: 16px !important;
}

.job-costing-wrapper-height{
    height: 23px !important;
    box-shadow: none !important;
}
.job-costing-bottom{
    bottom: 16px !important;
}

.job-costing-wrapper-height{
    height: 23px !important;
    box-shadow: none !important;
}

.beamerPositionNotLMS{
    left: 174px;
}

.beamerPosition{
    left: 91px;
}
