/* Reporting operational list */

.reporting-operational-table,
.incomplete-parts-reporting-operational-table{
    padding-top: 20px;
}

.reporting-operational-table .help_section,
.incomplete-parts-reporting-operational-table .help_section {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 15px;
}

.reporting-operational-table .faux-checkbox,
.incomplete-parts-reporting-operational-table .faux-checkbox {
    margin: 0px 5px 0px 0px;
}

.reporting-operational-table .job-details-link,
.incomplete-parts-reporting-operational-table .job-details-link{
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
}

.reporting-operational-table .operational-column-labels,
.incomplete-parts-reporting-operational-table .operational-column-labels{
    background: #f6f4f4 !important;
}

.reporting-operational-table .operational-header,
.incomplete-parts-reporting-operational-table .operational-header {
    background: #6393b5 !important;
    color: #fff !important;
}

.reporting-operational .operational-header,
.incomplete-parts-reporting-operational-table .operational-header{
    background: #6393b5 !important;
    color: #fff !important;
}

.reporting-operational-table .operational-header .faux-checkbox,
.incomplete-parts-reporting-operational-table .operational-header .faux-checkbox{
    color: #000 !important;
}

/*.reporting-operational-table .operational-header.todays-date {*/
/*background: #54c6cc !important;*/
/*border-bottom: 1px solid #4fb6bb !important;*/
/*}*/

.reporting-operational-table .operational-header.todays-date .job-date,
.incomplete-parts-reporting-operational-table .operational-header.todays-date .job-date{
    font-weight: bold;
}

.reporting-operational-table .operational-data-row,
.incomplete-parts-reporting-operational-table .operational-data-row {
    background: #fff !important;
}

.reporting-operational-table .faux-checkbox,
.incomplete-parts-reporting-operational-table .faux-checkbox{
    background-color: #fff;
}

.reporting-operational-list .download_options {
    display: none;
}

.reporting-operational-list .quick-links-wrap {
    display: none;
}

.reporting_wrapper.sticky .reporting-filter-bar-wrapper .operational-reporting-filter-bar.reporting-filter-bar {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.reporting_wrapper.sticky .reporting-filter-bar-wrapper .reporting-filter-bar .reporting-filter-bar-input {
    display: block;
}

.reporting_wrapper .reporting-operational-table .nav-tabs.sticky-header-nav-tabs,
.reporting_wrapper .reporting-operational .nav-tabs.sticky-header-nav-tabs,
.reporting_wrapper .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs {
    display: none;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs,
.reporting_wrapper.sticky .reporting-operational .nav-tabs,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs {
    display: none;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs.sticky-header-nav-tabs,
.reporting_wrapper.sticky .reporting-operational .nav-tabs.sticky-header-nav-tabs,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs{
    padding-top: 5px;
    padding-bottom: 4px;
    display: block;
    position: fixed;
    left: 34px;
    /*
      right: 17px;
    */
    right: 0px;
    border: 1px solid #c9c9c9;
    border-top: 0px;
    background-color: #fff;
    top: 166px;
    padding-right: 10px;
    padding-left: 15px;
    -webkit-box-shadow: -7px 6px 11px rgba(0, 0, 0, 0.25);
    box-shadow: -7px 6px 11px rgba(0, 0, 0, 0.25);
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs.sticky-header-nav-tabs .btn,
.reporting_wrapper.sticky .reporting-operational .nav-tabs.sticky-header-nav-tabs .btn,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs .btn {
    margin-top: 3px;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs.sticky-header-nav-tabs .btn.btn-small,
.reporting_wrapper.sticky .reporting-operational .nav-tabs.sticky-header-nav-tabs .btn.btn-small,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs .btn.btn-small {
    margin-top: 4px;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs.sticky-header-nav-tabs .btn.active-tab,
.reporting_wrapper.sticky .reporting-operational .nav-tabs.sticky-header-nav-tabs .btn.active-tab,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs .btn.active-tab {
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs.sticky-header-nav-tabs a,
.reporting_wrapper.sticky .reporting-operational .nav-tabs.sticky-header-nav-tabs a,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs a {
    padding-top: 4px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-bottom: 4px;
    margin-left: 5px;
    margin-right: 5px;
    border: 0px solid transparent;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.reporting_wrapper.sticky .reporting-operational-table .nav-tabs.sticky-header-nav-tabs a:hover,
.reporting_wrapper.sticky .reporting-operational .nav-tabs.sticky-header-nav-tabs a:hover,
.reporting_wrapper.sticky .incomplete-parts-reporting-operational-table .nav-tabs.sticky-header-nav-tabs a:hover {
    border: 0px solid transparent;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.reporting_wrapper.operational.sticky {
    top: 115px;
}

.reporting_wrapper #diary-wrapper {
    position: fixed;
    right: 0px;
    left: 0px;
    top: 73px;
    z-index: 100;
}

.operational-sidepanel .u-shaped-padding {
    padding: 0px 87px 5px 20px;
}

.operational-sidepanel .ng-invalid {
    color: #b94a48;
    border-color: #ee5f5b;
}

#transfer-stock-panel.operational-sidepanel .data-section.stock-edit-option {
    padding: 15px 0px 4px 0px;
    margin: 0px 20px 5px !important;
}

#transfer-stock-panel.operational-sidepanel .data-section.stock-edit-option .field .stock-location-validation-message {
    /*    margin-left: 35px;
    */
    margin-left: 198px;
    color: #b94a48;
    border-top: 1px solid #ccc;
    max-width: 190px;
}

.destination-dropdown {
    width: 470px;
}

#transfer-stock-panel.operational-sidepanel .data-section.stock-edit-option .field-label {
    top: 0px;
}

.operational-sidepanel.reminder-panel select {
    top: -1px;
}

.operational-sidepanel.reminder-panel .quantity {
    top: 5px;
    width: 40px;
    left: 0px;
    margin-left: 5px;
}

.operational-sidepanel.reminder-panel .quantity-label {
    margin-top: 10px;
}

.operational-sidepanel.batch-authorise-work-reminders .single-reminder li .ss-check.processed {
    margin-right: 6px;
    margin-top: 10px;
}

.operational-load-more-results-prompt {
    text-align: center;
    padding: 15px 0px 5px 0px;
}

.reporting-operational-table tbody.disabled .operational-header,
.incomplete-parts-reporting-operational-table tbody.disabled .operational-header {
    background: #b6c8d6 !important;
    border-bottom: 1px solid #b6c8d6 !important;
}

.reporting-operational-table tbody.disabled .operational-column-labels,
.incomplete-parts-reporting-operational-table tbody.disabled .operational-column-labels,
.reporting-operational-table tbody.disabled .operational-data-row,
.incomplete-parts-reporting-operational-table tbody.disabled .operational-data-row {
    color: #ababab !important;
}

.reporting-operational-table tbody.disabled .operational-data-row,
.incomplete-parts-reporting-operational-table tbody.disabled .operational-data-row {
    background: #fbfafa !important;
}

.reporting-operational-table tbody .operational-data-row.disabled,
.incomplete-parts-reporting-operational-table tbody .operational-data-row.disabled {
    background: #fbfafa !important;
    color: #ababab !important;
}

.reporting-operational-table tbody.disabled .faux-checkbox,
.incomplete-parts-reporting-operational-table tbody.disabled .faux-checkbox {
    cursor: default;
}

.reporting-new-operational-table tbody.disabled .operational-header {
    background: #b6c8d6 !important;
    border-bottom: 1px solid #b6c8d6 !important;
}

.stock-panel .stock-panel-body {
    margin-right: 70px;
    margin-bottom: 15px;
}

.stock-panel .inner-data-section {
    background: #ffffff;
    padding: 0 10px 10px 10px;
    border: 1px solid #c9c9c9;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    margin-bottom: 10px;
}
.stock-panel .stock-location-text {
    margin:10px 0;
}
.stock-panel .pretty-list > li {
    padding-right: 60px
}
.stock-panel .full-width {
    width: 100% !important; float:none !important;
}
.no-right-pad {
    padding-right: 0 !important;
}
.stock-panel span.icon {
    margin-right: 30px;
}
.stock-panel span.icon.ss-check {
    color: #60a369
}
.stock-panel span.icon.ss-delete {
    color: #da4f49
}


/* End reporting operational list specific CSS */
