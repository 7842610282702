
.diary-in-map-view .fc-toolbar.fc-header-toolbar{
    margin-bottom: 0 !important;
}
.fc-event, .fc-event:hover {
    color: #505b65 !important;
}


/* double border in timeline view */
.PM_Double{
    border-right: double !important;
}
/* event rendering styles in agenda view am/pm cells*/
.fc-day-grid-event {
    position: relative !important;
    margin: 1px 5px 0 2px !important;

}
.cs-event.with-static-height {
    min-height: 19px !important;
}
.all-day-grid-container .cs-event {
    min-height: 41px;
}
.all-day-grid-container {
    position: relative;
    margin: 0 2.5% 0 2px;
}
.fc-cell-content{
    text-align: left;
}
.fc-timeline .fc-cell-text {
    font-weight: bold;
}
.fc td, .fc th {
    border-width: 2px;
}
.cs-event .cs-special-event-spacer:nth-child(1) {
    /* border-right: none; */
    border-bottom: 1px ;
}
.fc-unthemed td.fc-today {
    background: #fff;
}
.fc-unthemed td.fc-today {
    background: #ffffff !important;
}
.cs_event_position {
    position: absolute !important;
}
#service-windows-cs-calendar,#shift-cs-calendar hr {
    padding: 0;
    border-bottom-width: 0;
}

#service-windows-cs-calendar,#shift-cs-calendar .fc-widget-header {
    border: 0;
}

#service-windows-cs-calendar,#shift-cs-calendar .fc-time-grid-container {
    float: left;
    max-height: 1058px;
}

element.style {
    background-color: cs-event-colour-4;
}
tr:first-child>td>.fc-day-grid-event {
    margin-top: 2px;
}
.fc-event.fc-draggable, .fc-event[href], .fc-popover .fc-header .fc-close, a[data-goto] {
    cursor: pointer;
}
.fc-day-grid-event {
    position: relative!important;
    margin: 1px 5px 0 2px!important;
}
.fc-event, .fc-event:hover {
    color: #505b65!important;
}
.fc-view-container *, .fc-view-container :after, .fc-view-container :before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.fc-day-grid-event {
    margin: 1px 2px 0;
    padding: 0 1px;
}
.fc-event, .fc-event:hover {
    color: #fff;
    text-decoration: none;
}
.fc-event, .fc-event-dot {
    background-color: #3a87ad;
}
.fc-event {
    border: none ;
}
.fc-view-container th.fc-widget-header.fc-today,.fc-view-container.fc-day-top.fc-today {
    background: #ea9b70 !important;
    color: #fafafa;
    font-weight: 100;
}
td.fc-day-top{
    text-align: center;
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    float: none !important;
    display: inline-block;
    text-align: center;
}
.fc-agendaWeek-view .fc-widget-header .fc-cell-content, .fc-timelineWeek-view .fc-widget-header .fc-cell-content{
    text-align: center !important;
}
.fc-nonbusiness.fc-bgevent.over_time_slots{
    background: #f8f16fde !important;
}
.fc-timelineWeek-view .fc-resource-area .fc-widget-header .fc-cell-content {
    text-align: left!important;
    float: left;
}
.cs-event.normal_event_overflow {
    overflow: hidden !important;
}
.fc-view.fc-timelineDay-view .cs-event,.fc-view.fc-timelineWeek-view .cs-event{
    max-height: 86px;
}
.fc-event {
    line-height: 1.6 !important;
}
.timeline-normal-event-body{
    max-height: 48px;
}
.fc-view.fc-timelineDay-view .cs-event-header.cs-event-header-scheduler,.fc-view.fc-timelineWeek-view .cs-event-header.cs-event-header-scheduler {
    height: 20px !important;
    position: inherit;
}
.fc-agendaDay-view tr,.fc-agendaWeek-view tr {
    /*height: 30px;*/
}